import { ResolvedPos } from "prosemirror-model";
import { Plugin, PluginKey, TextSelection } from "prosemirror-state";
import { GapCursor } from "../../../editor/plugins/gap-cursor";
import { CustomGridSchema } from "../schema";

// A plugin that makes sure that the selecion is not put into a node that has been
// hidden.

function isHidden(
  $pos: ResolvedPos<CustomGridSchema>,
  schema: CustomGridSchema
): boolean {
  let hidden = false;
  for (let i = $pos.depth; i > 0; i--) {
    const node = $pos.node(i);
    const child = $pos.node(i + 1);
    if (
      node.type === schema.nodes.table &&
      node.attrs.showHeader === false &&
      child?.type === schema.nodes.tableHeaderRow
    ) {
      hidden = true;
    } else if (
      node.type === schema.nodes.table &&
      node.attrs.showFooter === false &&
      child?.type === schema.nodes.tableFooterRow
    ) {
      hidden = true;
    }
  }

  return hidden;
}

const key = new PluginKey("jump-hidden-rows");

export function jumpHiddenRows() {
  return new Plugin({
    key,
    appendTransaction: (transactions, oldState, state) => {
      const { schema } = state;
      if (state.selection.from !== state.selection.to) {
        // Only applies to collapsed selection
        return undefined;
      }

      const selectionSet = transactions.find((tr) => tr.selectionSet);

      if (selectionSet && isHidden(state.selection.$from, schema)) {
        const dir = state.selection.from > oldState.selection.from ? 1 : -1;
        let newPos = state.selection.from,
          hidden = true,
          validTextSelection = false,
          validGapCursor = null,
          $pos;
        while (hidden || (!validGapCursor && !validTextSelection)) {
          newPos += dir;
          if (newPos === 0 || newPos === state.doc.nodeSize) {
            // Could not find any valid position
            return;
          }
          $pos = state.doc.resolve(newPos);
          validTextSelection = $pos.parent.inlineContent;
          validGapCursor = GapCursor.findFrom($pos, dir);
          hidden = isHidden($pos, schema);
        }

        if ($pos == null) {
          return undefined;
        }

        if (validGapCursor != null) {
          return state.tr.setSelection(validGapCursor);
        } else if (validTextSelection) {
          return state.tr.setSelection(new TextSelection($pos));
        } else {
          return undefined;
        }
      }

      return undefined;
    }
  });
}
