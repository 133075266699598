import { Fragment, Node, ResolvedPos, Schema } from "prosemirror-model";
import {
  EditorState,
  NodeSelection,
  Plugin,
  TextSelection,
  Transaction
} from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import {
  CommandConfiguration,
  CommandConfigurations,
  CommandFn,
  Extension,
  KeyMap,
  NodeConfig
} from "../../editor";
import {
  dropInsertPlugin,
  executeAtPos,
  isEnableAtPos
} from "../../editor/plugins/drop-insert-plugin";
import { emitNotification } from "../../editor/plugins/notification";
import {
  applyQuestionTitle,
  questionTitleCacheKey,
  questionTitleCachePlugin,
  questionTitlePredicate
} from "../../editor/plugins/question-title-cache";
import {
  getTranslation,
  GetTranslationFn
} from "../../extensions/localization";
import { getIdGenerator } from "../../extensions/node-identifier";
import {
  getQuestionTitleActiveValue,
  QuestionTitleActiveValue,
  updateQuestionTitleText
} from "../../extensions/question-title";
import { isQuestionTitleAutoCreation } from "../../extensions/question-title/plugins/question-title-autocreation-flag";
import {
  canInsert,
  canInsertAtPos,
  insertBlock,
  isEmptySelectionAtEnd,
  isEmptySelectionAtStart,
  isFirstChildOfParent,
  isInsideGrid,
  isLastChildOfParent,
  isMac,
  NodeDTO
} from "../../util";
import { inputChoiceNodeViewPlugin } from "./node-views/plugin";
import {
  InputChoiceAllOfTheAboveValueNode,
  InputChoiceNode,
  InputChoiceNoneOfTheAboveValueNode,
  InputChoiceOtherSpecifyValueNode,
  InputChoiceValueNode
} from "./nodes";
import { pastePlugin } from "./plugins";
import {
  InputChoiceControlType,
  InputChoiceLabelPosition,
  InputChoiceRows,
  InputChoiceSchema,
  InputChoiceType,
  InputChoiceValue,
  InputChoiceWidth
} from "./schema";
import {
  createInputChoice,
  createInputChoiceOtherSpecifyValue,
  createInputChoiceValue,
  focusedInputChoice,
  isSpecialChoice
} from "./util";

export interface InsertInputChoiceProps {
  controlType: InputChoiceControlType;
}
export interface UpdateInputChoiceProps {
  controlType?: InputChoiceControlType;
  labelPosition?: InputChoiceLabelPosition;
  rows?: number;
  width?: number;
  questionTitleText?: string;
  description?: string;
  coding?: string;
  watermark?: string;
  randomize?: boolean;
  allOfTheAbove?: boolean;
  noneOfTheAbove?: boolean;
  otherSpecify?: boolean;
  respondentsMustSpecify?: boolean;
  choiceSelectionRangeMin?: number;
  choiceSelectionRangeMax?: number;
  required?: boolean;
  responseIds?: Pick<InputChoiceValue, "id" | "coding">[];
  defaultValueId?: string;
}

export interface InputChoiceActiveValue {
  controlType: InputChoiceControlType;
  labelPosition: InputChoiceLabelPosition;
  rows: number;
  width: number;
  questionTitle: QuestionTitleActiveValue;
  description: string;
  coding: string;
  watermark: string | null;
  randomize: boolean;
  allOfTheAbove: boolean;
  noneOfTheAbove: boolean;
  otherSpecify: boolean;
  respondentsMustSpecify: boolean;
  choiceSelectionRangeMin: number;
  choiceSelectionRangeMax: number;
  required: boolean;
  values: InputChoiceValue[];
  defaultValueId: string;
  widthDisabled: boolean;
}

const questionTitleKey = questionTitleCacheKey();
export class InputChoice implements Extension<InputChoiceSchema> {
  get name(): string {
    return "inputChoice";
  }

  get nodes(): NodeConfig[] {
    return [
      new InputChoiceNode(),
      new InputChoiceValueNode(),
      new InputChoiceAllOfTheAboveValueNode(),
      new InputChoiceNoneOfTheAboveValueNode(),
      new InputChoiceOtherSpecifyValueNode()
    ];
  }

  plugins(schema: InputChoiceSchema): Plugin[] {
    return [
      inputChoiceNodeViewPlugin(),
      pastePlugin(schema),
      dropInsertPlugin((view, data, posAtCoords) => {
        if (data.type !== "inputChoice") {
          return false;
        }

        let controlType: InputChoiceControlType;
        switch (data.subType) {
          case InputChoiceControlType.singleVertical:
            controlType = InputChoiceControlType.singleVertical;
            break;

          case InputChoiceControlType.singleHorizontal:
            controlType = InputChoiceControlType.singleHorizontal;
            break;

          case InputChoiceControlType.multipleVertical:
            controlType = InputChoiceControlType.multipleVertical;
            break;

          case InputChoiceControlType.multipleHorizontal:
            controlType = InputChoiceControlType.multipleHorizontal;
            break;

          case InputChoiceControlType.dropdown:
            controlType = InputChoiceControlType.dropdown;
            break;

          case InputChoiceControlType.listbox:
            controlType = InputChoiceControlType.listbox;
            break;

          default:
            controlType = InputChoiceControlType.singleVertical;
            break;
        }

        const command = this.insertInputChoiceCommand(schema);

        const isEnabled = isEnableAtPos(command, view, posAtCoords);
        if (
          !isEnabled({
            controlType: controlType
          })
        ) {
          emitNotification(view.state, {
            type: "warning",
            message: "drop.insert.invalid-location"
          });
        } else {
          executeAtPos(
            command.execute({
              controlType: controlType
            }),
            view,
            posAtCoords
          );
        }

        return true;
      }),
      questionTitleCachePlugin(
        schema.nodes.inputChoice,
        "INPUT_CHOICE.DEFAULT_QUESTION_TITLE",
        questionTitlePredicate,
        applyQuestionTitle,
        questionTitleKey
      )
    ];
  }

  commands(
    schema: InputChoiceSchema
  ): CommandConfigurations<InputChoiceSchema> {
    return {
      insertInputChoice: this.insertInputChoiceCommand(schema),
      updateInputChoice: this.updateInputChoiceCommand(schema)
    };
  }

  keymaps(_schema: InputChoiceSchema): KeyMap<InputChoiceSchema> {
    return {
      "Shift-Enter": preventShiftEnter,
      Enter: enter,
      Backspace: exitInputChoice(true, false),
      Delete: exitInputChoice(false, true)
    };
  }

  private insertInputChoiceCommand(
    schema: InputChoiceSchema
  ): CommandConfiguration<
    InputChoiceSchema,
    InsertInputChoiceProps,
    undefined
  > {
    return {
      isActive: () => {
        return false;
      },
      isEnabled: () => {
        return insertInputChoice(schema, InputChoiceControlType.singleVertical);
      },
      execute: (props) => {
        if (props == null) {
          throw new Error(
            `To insert an input choice, InsertChoiceProps needs to be provided.`
          );
        }

        const { controlType } = props;

        if (controlType == null) {
          throw new Error(
            `To insert an input choice, control type need to be provided for InsertChoiceProps.`
          );
        }

        return insertInputChoice(schema, controlType);
      },
      shortcuts: {
        [isMac() ? "Ctrl-c" : "Alt-c"]: {
          controlType: InputChoiceControlType.singleVertical
        }
      }
    };
  }

  private updateInputChoiceCommand(
    schema: InputChoiceSchema
  ): CommandConfiguration<
    InputChoiceSchema,
    UpdateInputChoiceProps,
    InputChoiceActiveValue | undefined
  > {
    return {
      isActive: () => {
        return (state) => {
          return focusedInputChoice(state) != null;
        };
      },
      isEnabled: () => {
        return (state) => {
          return focusedInputChoice(state) != null;
        };
      },
      execute: (props) => {
        return (state, dispatch) => {
          const focused = focusedInputChoice(state);

          if (!focused) {
            return false;
          }

          if (props == null) {
            throw new Error(
              `To update an input choice, UpdateInputChoiceProps needs to be provided.`
            );
          }

          if (dispatch) {
            const { pos, node } = focused;

            const from = focused.pos;

            let updatedAttrs = { ...focused.node.attrs };

            let tr = state.tr;

            if (props.labelPosition !== undefined) {
              const controlType = node.attrs.controlType;

              if (
                ((controlType === InputChoiceControlType.singleVertical ||
                  controlType === InputChoiceControlType.multipleVertical) &&
                  (props.labelPosition === InputChoiceLabelPosition.right ||
                    props.labelPosition === InputChoiceLabelPosition.boxed)) ||
                ((controlType === InputChoiceControlType.singleHorizontal ||
                  controlType === InputChoiceControlType.multipleHorizontal) &&
                  props.labelPosition !== InputChoiceLabelPosition.right)
              ) {
                updatedAttrs = {
                  ...updatedAttrs,
                  labelPosition: props.labelPosition
                };
              }
            }

            if (props.rows !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                rows: Math.min(
                  Math.max(props.rows, InputChoiceRows.minimum),
                  InputChoiceRows.maximum
                )
              };
            }

            if (props.controlType !== undefined) {
              const type = props.controlType;
              const currentlabelPosition = updatedAttrs.labelPosition;
              updatedAttrs = {
                ...updatedAttrs,
                controlType: type
              };

              if (
                (type === InputChoiceControlType.singleVertical ||
                  type === InputChoiceControlType.multipleVertical) &&
                (currentlabelPosition === InputChoiceLabelPosition.top ||
                  currentlabelPosition === InputChoiceLabelPosition.bottom)
              ) {
                updatedAttrs.labelPosition = InputChoiceLabelPosition.right;
              } else if (
                (type === InputChoiceControlType.singleHorizontal ||
                  type === InputChoiceControlType.multipleHorizontal) &&
                currentlabelPosition === InputChoiceLabelPosition.right
              ) {
                updatedAttrs.labelPosition = InputChoiceLabelPosition.top;
              }

              const updatedRows = updateRows(props.controlType);
              updatedAttrs = { ...updatedAttrs, rows: updatedRows };

              if (
                type === InputChoiceControlType.singleVertical ||
                type === InputChoiceControlType.singleHorizontal
              ) {
                updatedAttrs = {
                  ...updatedAttrs,
                  choiceSelectionRangeMin: 0,
                  choiceSelectionRangeMax: 1
                };
              } else if (
                (type === InputChoiceControlType.multipleVertical ||
                  type === InputChoiceControlType.multipleHorizontal) &&
                (node.attrs.controlType ===
                  InputChoiceControlType.singleVertical ||
                  node.attrs.controlType ===
                    InputChoiceControlType.singleHorizontal)
              ) {
                updatedAttrs = {
                  ...updatedAttrs,
                  choiceSelectionRangeMin: 0,
                  choiceSelectionRangeMax: 0
                };
              }
            }

            if (props.width != null && !isNaN(props.width)) {
              updatedAttrs = {
                ...updatedAttrs,
                width: Math.min(
                  Math.max(props.width, InputChoiceWidth.minimum),
                  InputChoiceWidth.maximum
                )
              };
            }

            if (props.questionTitleText !== undefined) {
              updatedAttrs = updateQuestionTitleText(
                updatedAttrs,
                props.questionTitleText
              );
            }

            if (props.description !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                description: props.description
              };
            }

            if (props.responseIds !== undefined) {
              const responseIds = props.responseIds;
              const getResponseId = (id: string) => {
                return responseIds.find((responseId) => responseId.id === id);
              };

              const startPos = pos + 1;

              node.descendants((child, childPos) => {
                const id = child.attrs.id as string;
                const responseId = getResponseId(id);
                if (responseId != null) {
                  const coding = responseId.coding;
                  const hasCoding = coding != null && coding.length !== 0;

                  tr = tr.setNodeMarkup(startPos + childPos, undefined, {
                    ...child.attrs,
                    coding: hasCoding ? coding : null
                  });
                }
                return false;
              });
            }

            if (props.coding !== undefined) {
              updatedAttrs = { ...updatedAttrs, coding: props.coding };
            }

            if (props.watermark !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                watermark: props.watermark
              };
            }

            if (props.randomize !== undefined) {
              updatedAttrs = { ...updatedAttrs, randomize: props.randomize };
            }

            if (props.respondentsMustSpecify !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                respondentsMustSpecify: props.respondentsMustSpecify
              };
            }

            if (props.choiceSelectionRangeMin !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                choiceSelectionRangeMin: props.choiceSelectionRangeMin
              };
            }

            if (props.choiceSelectionRangeMax !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                choiceSelectionRangeMax: props.choiceSelectionRangeMax
              };
            }

            if (props.required !== undefined) {
              updatedAttrs = { ...updatedAttrs, required: props.required };
            }

            if (props.otherSpecify !== undefined) {
              const shouldRemoveChoice =
                props.otherSpecify === false &&
                node.childCount === 1 &&
                node.lastChild?.type ===
                  schema.nodes.inputChoiceOtherSpecifyValue;

              if (shouldRemoveChoice) {
                let tr = state.tr;
                tr = tr.replaceRangeWith(
                  pos + 1,
                  pos + node.nodeSize - 2,
                  schema.nodes.inputChoiceValue.create()
                );
                tr = tr.setSelection(new NodeSelection(tr.doc.resolve(pos)));

                dispatch(tr);

                return true;
              }

              tr = toggleOtherSpecify(
                tr,
                schema,
                pos,
                props.otherSpecify,
                getTranslation(state)
              );
            }

            if (props.allOfTheAbove !== undefined) {
              tr = toggleAboveChoiceValue(
                tr,
                schema,
                pos,
                props.allOfTheAbove,
                InputChoiceType.allOfTheAbove,
                getTranslation(state)
              );
            }

            if (props.noneOfTheAbove !== undefined) {
              tr = toggleAboveChoiceValue(
                tr,
                schema,
                pos,
                props.noneOfTheAbove,
                InputChoiceType.noneOfTheAbove,
                getTranslation(state)
              );
            }

            if (props.defaultValueId !== undefined) {
              const startPos = pos + 1;

              node.descendants((child, childPos) => {
                const id = child.attrs.id as string;
                if (id === props.defaultValueId) {
                  tr = tr.setNodeMarkup(startPos + childPos, undefined, {
                    ...child.attrs,
                    default: true
                  });
                } else if (id !== props.defaultValueId && child.attrs.default) {
                  tr = tr.setNodeMarkup(startPos + childPos, undefined, {
                    ...child.attrs,
                    default: false
                  });
                }
                return false;
              });
            }

            tr = tr.setNodeMarkup(from, undefined, updatedAttrs);
            tr = tr.setSelection(new NodeSelection(tr.doc.resolve(from)));
            dispatch(tr);
          }
          return true;
        };
      },
      activeValue: () => {
        return (state) => {
          const focused = focusedInputChoice(state);
          if (!focused) {
            return undefined;
          }

          const { node } = focused;

          const otherSpecify = otherSpecifyNode(node, schema) != null;
          const allOfTheAbove =
            aboveNode(node, schema, InputChoiceType.allOfTheAbove) != null;
          const noneOfTheAbove =
            aboveNode(node, schema, InputChoiceType.noneOfTheAbove) != null;

          const values = new Array<InputChoiceValue>();
          let defaultValueId: string | undefined;

          node.content.forEach((child) => {
            values.push({
              id: child.attrs.id,
              content: (child.content.toJSON() as NodeDTO[] | null) ?? [],
              coding: child.attrs.coding,
              default: child.attrs.default
            });

            if (child.attrs.default === true) {
              defaultValueId = child.attrs.id;
            }
          });

          const widthDisabled = isInsideGrid(state, focused);

          return {
            questionTitle: getQuestionTitleActiveValue(state, node),
            controlType: node.attrs.controlType,
            labelPosition: node.attrs.labelPosition,
            rows: node.attrs.rows,
            width: widthDisabled ? 100 : node.attrs.width,
            description: node.attrs.description,
            coding: node.attrs.coding,
            watermark: node.attrs.watermark,
            randomize: node.attrs.randomize,
            allOfTheAbove: allOfTheAbove,
            noneOfTheAbove: noneOfTheAbove,
            otherSpecify: otherSpecify,
            respondentsMustSpecify: node.attrs.respondentsMustSpecify,
            choiceSelectionRangeMin: node.attrs.choiceSelectionRangeMin,
            choiceSelectionRangeMax: node.attrs.choiceSelectionRangeMax,
            required: node.attrs.required,
            values: values,
            defaultValueId: defaultValueId != null ? defaultValueId : "",
            widthDisabled: widthDisabled
          };
        };
      }
    };
  }
}

const enter: CommandFn<InputChoiceSchema> = (
  state: EditorState<InputChoiceSchema>,
  dispatch?: (tr: Transaction<InputChoiceSchema>) => void,
  _view?: EditorView<InputChoiceSchema>
) => {
  const { selection, schema } = state;
  if (!(selection instanceof TextSelection)) {
    return false;
  }

  const { $from, $to } = selection;

  const atStart = isEmptySelectionAtStart(state);
  const atEnd = isEmptySelectionAtEnd(state);
  if (!atStart && !atEnd) {
    return false;
  }

  const fromParent = $from.node($from.depth - 1);
  const toParent = $to.node($to.depth - 1);

  if (
    fromParent.type !== schema.nodes.inputChoice ||
    toParent.type !== schema.nodes.inputChoice
  ) {
    return false;
  }

  if (fromParent !== toParent) {
    return false;
  }

  if (atStart && atEnd && dispatch) {
    const isFirstChild = isFirstChildOfParent(state);
    const isLastChild = isLastChildOfParent(state);

    if (isFirstChild) {
      let tr = state.tr;
      tr = tr.deleteSelection();

      const toInsert = schema.nodes.paragraph.create();
      tr = deleteNode(tr, $from, $from.depth - 1);
      tr = insertNode(tr, toInsert, "before", $from, $from.depth - 2, true);

      dispatch(tr);
      return true;
    } else if (isLastChild) {
      let tr = state.tr;
      tr = tr.deleteSelection();

      const toInsert = schema.nodes.paragraph.create();
      tr = deleteNode(tr, $to, $to.depth - 1);
      tr = insertNode(tr, toInsert, "after", $to, $to.depth - 2, true);

      dispatch(tr);
      return true;
    }
  } else if (atStart && dispatch) {
    let tr = state.tr;

    const { $from } = tr.selection;
    const node = $from.node();

    if (!node) {
      return false;
    }

    const before = $from.before();
    const $before = tr.doc.resolve(before);

    if (isSpecialChoice(node, schema)) {
      if (
        $before.nodeBefore != null &&
        isSpecialChoice($before.nodeBefore, schema)
      ) {
        return false;
      }

      const toInsert = schema.nodes.inputChoiceValue.create();
      tr = insertNode(tr, toInsert, "before", $from, $from.depth - 1, false);
      dispatch(tr);
      return true;
    }
  }

  return false;
};

function exitInputChoice(
  fromStart: boolean,
  fromEnd: boolean
): CommandFn<InputChoiceSchema> {
  return (state, dispatch) => {
    const { selection, schema } = state;
    if (!(selection instanceof TextSelection)) {
      return false;
    }

    const { $from, $to } = selection;

    const atStart = isEmptySelectionAtStart(state);
    const atEnd = isEmptySelectionAtEnd(state);
    if (!atStart && !atEnd) {
      return false;
    }

    const fromParent = $from.node($from.depth - 1);
    const toParent = $to.node($to.depth - 1);

    if (
      fromParent.type !== schema.nodes.inputChoice ||
      toParent.type !== schema.nodes.inputChoice
    ) {
      return false;
    }

    if (fromParent !== toParent) {
      return false;
    }

    if (atStart && atEnd && dispatch) {
      const isFirstChild = isFirstChildOfParent(state);
      const isLastChild = isLastChildOfParent(state);

      if (isFirstChild && fromStart) {
        let tr = state.tr;
        tr = tr.deleteSelection();

        const toInsert = schema.nodes.paragraph.create();
        tr = deleteNode(tr, $from, $from.depth - 1);
        tr = insertNode(tr, toInsert, "before", $from, $from.depth - 2, true);

        dispatch(tr);
        return true;
      } else if (isLastChild && fromEnd) {
        let tr = state.tr;
        tr = tr.deleteSelection();

        const toInsert = schema.nodes.paragraph.create();
        tr = deleteNode(tr, $to, $to.depth - 1);
        tr = insertNode(tr, toInsert, "after", $to, $to.depth - 2, true);

        dispatch(tr);
        return true;
      }
    }

    return false;
  };
}

const preventShiftEnter: CommandFn<InputChoiceSchema> = (
  state: EditorState<InputChoiceSchema>,
  _dispatch?: (tr: Transaction<InputChoiceSchema>) => void,
  _view?: EditorView<InputChoiceSchema>
) => {
  const { selection, schema } = state;
  if (!(selection instanceof TextSelection)) {
    return false;
  }

  const { $from, $to } = selection;

  const fromParent = $from.node($from.depth - 1);
  const toParent = $to.node($to.depth - 1);

  if (
    fromParent.type !== schema.nodes.inputChoice ||
    toParent.type !== schema.nodes.inputChoice
  ) {
    return false;
  }

  if (fromParent !== toParent) {
    return false;
  } else {
    let tr = state.tr;

    const { $from, $to } = tr.selection;

    const fromNode = $from.node();
    const toNode = $to.node();

    if (
      fromNode != null &&
      fromNode.type === schema.nodes.inputChoiceOtherSpecifyValue
    ) {
      return true;
    }

    if (
      toNode != null &&
      toNode.type === schema.nodes.inputChoiceOtherSpecifyValue
    ) {
      return true;
    }
  }

  return false;
};

function deleteNode<S extends Schema>(
  tr: Transaction<S>,
  $pos: ResolvedPos<S>,
  depth: number
): Transaction<S> {
  const toRemoveIndex = $pos.index(depth);
  const parentIndex = $pos.index(depth - 1);
  const parentPos = tr.mapping.map($pos.posAtIndex(parentIndex, depth - 1));
  const parent = $pos.node(depth);

  if (parent.childCount === 1) {
    tr = tr.delete(parentPos, parentPos + parent.nodeSize);
  } else {
    let children = new Array<Node<S>>();
    parent.forEach((child, _offset, index) => {
      if (index !== toRemoveIndex) {
        children.push(child);
      }
    });

    tr = tr.replaceRangeWith(
      parentPos,
      parentPos + parent.nodeSize,
      parent.copy(Fragment.from(children))
    );
  }

  return tr;
}

function insertNode<S extends Schema>(
  tr: Transaction<S>,
  toInsert: Node<S>,
  position: "before" | "after",
  $pos: ResolvedPos<S>,
  depth: number,
  shouldSelect: boolean
): Transaction<S> {
  const index = $pos.index(depth);
  const offset = position === "after" ? 1 : 0;
  const pos = tr.mapping.map($pos.posAtIndex(index + offset, depth));

  if (canInsertAtPos(toInsert, tr.doc.resolve(pos))) {
    tr = tr.insert(pos, toInsert);
    if (shouldSelect) {
      tr = tr.setSelection(TextSelection.near(tr.doc.resolve(pos)));
    }
  }

  return tr;
}

function insertInputChoice(
  schema: InputChoiceSchema,
  type: InputChoiceControlType
): CommandFn<InputChoiceSchema> {
  return (state, dispatch) => {
    if (!canInsert(schema.nodes.inputChoice)(state)) {
      return false;
    } else {
      if (dispatch) {
        const choice = createInputChoice(schema, type, getTranslation(state));

        if (choice == null) {
          return false;
        }

        let tr = state.tr;
        tr = insertBlock(
          tr,
          state.schema,
          choice,
          true,
          getIdGenerator(state),
          isQuestionTitleAutoCreation(state)
        );

        dispatch(tr);
      }
      return true;
    }
  };
}

function toggleAboveChoiceValue<S extends Schema>(
  tr: Transaction<S>,
  schema: S,
  pos: number,
  above: boolean,
  type: InputChoiceType,
  getTranslation: GetTranslationFn
): Transaction<S> {
  const node = tr.doc.nodeAt(pos);
  if (node == null) {
    return tr;
  }

  if (above === true) {
    const above = createInputChoiceValue(
      schema,
      type,
      getTranslation
    ) as Node<S>;

    let children = new Array<Node<S>>();
    let hasAlreadyAbove = false;
    let hasAlreadyAdd = false;

    node.forEach((child, _offset, index) => {
      if (
        (type === InputChoiceType.allOfTheAbove &&
          child.type === schema.nodes.inputChoiceAllOfTheAboveValue) ||
        (type === InputChoiceType.noneOfTheAbove &&
          child.type === schema.nodes.inputChoiceNoneOfTheAboveValue)
      ) {
        hasAlreadyAbove = true;
        return;
      }

      const isLastChild = index === node.childCount - 1;
      const isChildEmpty = child.content.size === 0;

      if (child.type === schema.nodes.inputChoiceValue) {
        if (isLastChild) {
          if (isChildEmpty) {
            children.push(above);
          } else {
            children.push(child);
            children.push(above);
          }
        } else {
          children.push(child);
        }
      } else if (child.type === schema.nodes.inputChoiceNoneOfTheAboveValue) {
        if (type === InputChoiceType.allOfTheAbove) {
          children.push(above);
          children.push(child);
          hasAlreadyAdd = true;
        } else {
          children.push(child);
        }
      } else if (child.type === schema.nodes.inputChoiceAllOfTheAboveValue) {
        if (type === InputChoiceType.noneOfTheAbove) {
          children.push(child);
          children.push(above);
          hasAlreadyAdd = true;
        } else {
          children.push(child);
        }
      } else if (child.type === schema.nodes.inputChoiceOtherSpecifyValue) {
        if (hasAlreadyAdd) {
          children.push(child);
        } else {
          children.push(above);
          children.push(child);
        }
      }
    });

    if (hasAlreadyAbove) {
      return tr;
    }

    tr = tr.replaceRangeWith(
      pos,
      pos + node.nodeSize,
      node.copy(Fragment.from(children))
    );
  } else {
    let above: { node: Node<InputChoiceSchema>; pos: number } | undefined;

    node.forEach((node, offset) => {
      if (
        (type === InputChoiceType.allOfTheAbove &&
          node.type === schema.nodes.inputChoiceAllOfTheAboveValue) ||
        (type === InputChoiceType.noneOfTheAbove &&
          node.type === schema.nodes.inputChoiceNoneOfTheAboveValue)
      ) {
        above = { node: node, pos: pos + offset };
      }
    });

    if (above != null) {
      const shouldRemove =
        node.childCount === 1 &&
        node.lastChild?.type !== schema.nodes.inputChoiceValue;

      if (shouldRemove) {
        tr = tr.replaceRangeWith(
          pos + 1,
          pos + node.nodeSize - 2,
          schema.nodes.inputChoiceValue.create() as Node<S>
        );
      } else {
        tr = tr.delete(above.pos, above.pos + above.node.nodeSize + 1);
      }
    }
  }

  return tr;
}

function toggleOtherSpecify<S extends Schema>(
  tr: Transaction<S>,
  schema: S,
  pos: number,
  otherSpecify: boolean,
  getTranslation: GetTranslationFn
): Transaction<S> {
  const node = tr.doc.nodeAt(pos);
  if (node == null) {
    return tr;
  }

  if (otherSpecify === true) {
    if (node.lastChild?.type === schema.nodes.inputChoiceOtherSpecifyValue) {
      return tr;
    }

    const otherSpecify = createInputChoiceOtherSpecifyValue(
      schema,
      getTranslation
    ) as Node<S>;

    let children = new Array<Node<S>>();
    node.forEach((child, _offset, index) => {
      const isLastChild = index === node.childCount - 1;
      const isChildEmpty = child.content.size === 0;
      if (isLastChild) {
        if (!isChildEmpty) {
          children.push(child);
        }
      } else {
        children.push(child);
      }
    });

    children.push(otherSpecify);

    tr = tr.replaceRangeWith(
      pos,
      pos + node.nodeSize,
      node.copy(Fragment.from(children))
    );
  } else {
    let otherSpecify:
      | { node: Node<InputChoiceSchema>; pos: number }
      | undefined;

    node.forEach((node, offset) => {
      if (node.type === schema.nodes.inputChoiceOtherSpecifyValue) {
        otherSpecify = { node: node, pos: pos + offset };
      }
    });

    if (otherSpecify != null) {
      const shouldRemove =
        node.childCount === 1 &&
        node.lastChild?.type !== schema.nodes.inputChoiceValue;

      if (shouldRemove) {
        tr = tr.replaceRangeWith(
          pos + 1,
          pos + node.nodeSize - 2,
          schema.nodes.inputChoiceValue.create() as Node<S>
        );
      } else {
        tr = tr.delete(
          otherSpecify.pos,
          otherSpecify.pos + otherSpecify.node.nodeSize
        );
      }
    }
  }

  return tr;
}

function otherSpecifyNode(
  node: Node<InputChoiceSchema>,
  schema: InputChoiceSchema
): Node<InputChoiceSchema> | undefined {
  let otherSpecifyNode: Node<InputChoiceSchema> | undefined = undefined;
  for (let i = 0; i < node.childCount; ++i) {
    let child = node.maybeChild(i);
    if (
      child != null &&
      child.type === schema.nodes.inputChoiceOtherSpecifyValue
    ) {
      otherSpecifyNode = child;
      break;
    }
  }

  return otherSpecifyNode;
}

function aboveNode(
  node: Node<InputChoiceSchema>,
  schema: InputChoiceSchema,
  type: InputChoiceType
): Node<InputChoiceSchema> | undefined {
  let aboveNode: Node<InputChoiceSchema> | undefined = undefined;
  for (let i = 0; i < node.childCount; ++i) {
    let child = node.maybeChild(i);
    if (
      child != null &&
      type === InputChoiceType.allOfTheAbove &&
      child.type === schema.nodes.inputChoiceAllOfTheAboveValue
    ) {
      aboveNode = child;
      break;
    }

    if (
      child != null &&
      type === InputChoiceType.noneOfTheAbove &&
      child.type === schema.nodes.inputChoiceNoneOfTheAboveValue
    ) {
      aboveNode = child;
      break;
    }
  }

  return aboveNode;
}

function updateRows(type: InputChoiceControlType): number {
  let rows: number = 1;
  if (type === InputChoiceControlType.listbox) {
    rows = InputChoiceRows.defaultListBox;
  } else {
    rows = InputChoiceRows.defaultStandard;
  }
  return rows;
}
