import { Node, NodeSpec, Schema } from "prosemirror-model";
import { BASE_PRIORITY, DocumentBuilders, NodeConfig } from "../../../editor";
import { AlignmentType, getAlignment } from "../../alignment";
import { InputScaleControlType } from "../schema";

const BaseSpec: NodeSpec = {
  content: "(inline|hardBreak)*",
  draggable: false,
  selectable: false,
  focusable: false,
  allowGapCursor: false,
  allowIndentation: false,
  defaultAlignment: (_node: Node<Schema>, parent: Node<Schema>) => {
    const controlType = parent.attrs.controlType as InputScaleControlType;
    switch (controlType) {
      case InputScaleControlType.dropdown:
        return "left";
      case InputScaleControlType.labelsInRow:
        return "center";
      case InputScaleControlType.listbox:
        return "left";
      default:
        return "left";
    }
  },
  attrs: {
    id: { default: null },
    coding: { default: null },
    alignment: { default: null }
  },
  applyBlueprint(attrs: Record<string, any>, blueprint: Record<string, any>) {
    const updated = { ...attrs };

    updated.coding = blueprint.coding;
    updated.default = blueprint.default;

    return updated;
  }
};

export class InputScaleValueNode implements NodeConfig {
  get name(): string {
    return "inputScaleValue";
  }

  get spec(): NodeSpec {
    return {
      ...BaseSpec,
      parseDOM: [
        {
          tag: "input-scale-value",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getAttrs(node as HTMLElement);
          }
        },
        {
          tag: "[data-id][data-type='standard']",
          context: "inputScale/",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getAttrs(node as HTMLElement);
          }
        },
        {
          tag: "p",
          context: "inputScaleValue/",
          priority: BASE_PRIORITY + 10
        },
        {
          tag: "ul",
          context: "inputScaleValue/",
          priority: BASE_PRIORITY + 10,
          skip: true
        },
        {
          tag: "ol",
          context: "inputScaleValue/",
          priority: BASE_PRIORITY + 10,
          skip: true
        },
        {
          tag: "li",
          context: "inputScaleValue/",
          priority: BASE_PRIORITY + 10
        },
        {
          tag: "table",
          context: "inputScaleValue/",
          priority: BASE_PRIORITY + 10,
          skip: true
        },
        {
          tag: "tr",
          context: "inputScaleValue/",
          priority: BASE_PRIORITY + 10,
          getAttrs(node) {
            if (typeof node === "string") {
              return false;
            }

            const row = node as HTMLTableRowElement;
            const cells = row.cells;

            const hasMultipleCells = cells.length > 1;
            if (!hasMultipleCells) {
              return;
            }

            const cell = cells.item(0);
            if (cell == null) {
              return;
            }

            const cellText = cell.textContent;
            if (cellText == null) {
              return;
            }

            const coding = cellText
              .replace(/\r/g, "")
              .replace(/\n/g, "")
              .trim();

            return { coding: coding };
          },
          contentElement(node) {
            const row = node as HTMLTableRowElement;
            const cells = row.cells;
            const label = cells.length > 1 ? cells.item(1) : cells.item(0);
            if (label == null) {
              return node;
            }

            return label;
          }
        }
      ],
      toDOM(node) {
        return ["input-scale-value", setAttrs(node), 0];
      }
    };
  }

  get builders(): DocumentBuilders {
    return { inputScaleValue: { nodeType: "inputScaleValue", id: null } };
  }
}

export class InputScaleNotApplicableNode implements NodeConfig {
  get name(): string {
    return "inputScaleNotApplicable";
  }

  get spec(): NodeSpec {
    return {
      // setting no default for id means that this node wont be used for node splitting.
      ...{ ...BaseSpec, attrs: { ...BaseSpec.attrs, id: {} } },
      isolating: true,
      parseDOM: [
        {
          tag: "input-scale-not-applicable",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getAttrs(node as HTMLElement);
          }
        },
        {
          tag: "[data-id][data-type='not-applicable']",
          context: "inputScale/",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getAttrs(node as HTMLElement);
          }
        }
      ],
      toDOM(node) {
        return ["input-scale-not-applicable", setAttrs(node), 0];
      }
    };
  }

  get builders(): DocumentBuilders {
    return {
      inputScaleNotApplicable: { nodeType: "inputScaleNotApplicable", id: null }
    };
  }
}

function getAttrs(element: HTMLElement): { [key: string]: any } {
  const attrs: {
    id?: string;
    coding?: string | null;
    alignment?: AlignmentType;
  } = {};

  const id = element.getAttribute("id");
  if (id != null) {
    attrs.id = id;
  }

  const legacyId = element.getAttribute("data-id");
  if (legacyId != null) {
    attrs.id = legacyId;
  }

  const coding = element.getAttribute("data-coding");
  if (coding != null) {
    attrs.coding = coding;
  }

  const alignment = getAlignment(element.style.textAlign);
  if (alignment != null) {
    attrs.alignment = alignment;
  }

  return attrs;
}

function setAttrs(node: Node): { [key: string]: any } {
  const id = node.attrs.id as string;
  const coding = node.attrs.coding as string | null;

  let styles: string[] = [];
  if (node.attrs.alignment != null) {
    styles = styles.concat(`text-align: ${node.attrs.alignment}`);
  }
  const style = styles.length > 0 ? { style: styles.join("; ") } : {};

  return {
    id: id,
    "data-coding": coding,
    ...style
  };
}
