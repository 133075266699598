import { Node, Schema } from "prosemirror-model";
import { CommandFn } from "../../editor/extension";
import { insertBlock, UnreachableCaseError } from "../../util";
import { canInsert } from "../../util/selection";
import { getTranslation, GetTranslationFn } from "../localization";
import { getIdGenerator } from "../node-identifier";
import {
  ConstantSumLogicDirection,
  ConstantSumSchema,
  ConstantSumType
} from "./schema";
import { createConstantSum } from "./util";

export function createConstantSumMatrix(
  schema: ConstantSumSchema,
  direction: ConstantSumLogicDirection,
  getTranslation: GetTranslationFn
): Node<ConstantSumSchema> {
  switch (direction) {
    case ConstantSumLogicDirection.horizontal:
      return createHorizontalConstantSumMatrix(schema, getTranslation);

    case ConstantSumLogicDirection.vertical:
      return createVerticalConstantSumMatrix(schema, getTranslation);

    default:
      throw new UnreachableCaseError(direction);
  }
}

function createHorizontalConstantSumMatrix(
  schema: ConstantSumSchema,
  getTranslation: GetTranslationFn
): Node<ConstantSumSchema> {
  const {
    table,
    tableRow,
    tableHeaderRow,
    tableFooterRow,
    tableHeader,
    tableCell,
    tableInputCell,
    tableFooter,
    paragraph
  } = schema.nodes;

  const rowsCount = 4;
  const columnsCount = 3;

  const rows: Node<ConstantSumSchema>[] = [];

  const width = 100 / columnsCount;

  for (let row = 1; row <= rowsCount; row++) {
    const cells = new Array<Node<ConstantSumSchema>>();

    if (row === 1) {
      for (let column = 1; column <= columnsCount; column++) {
        if (column === columnsCount) {
          const headerCell = tableHeader.createAndFill({ colwidth: [width] })!;
          cells.push(headerCell);
        } else {
          const untitledItem = paragraph.createAndFill(
            null,
            schema.text(getTranslation("CONSTANT_SUM.MATRIX.ITEM"))
          )!;
          const headerCell = tableHeader.createAndFill({ colwidth: [width] }, [
            untitledItem
          ])!;
          cells.push(headerCell);
        }
      }

      rows.push(tableHeaderRow.createChecked(null, cells));
    } else if (row === rowsCount) {
      for (let column = 1; column <= columnsCount; column++) {
        const footerCell = tableFooter.createAndFill({ colwidth: [width] })!;
        cells.push(footerCell);
      }

      rows.push(tableFooterRow.createChecked(null, cells));
    } else {
      for (let column = 1; column <= columnsCount; column++) {
        if (column === columnsCount) {
          const functionComponent = createConstantSum(
            schema,
            ConstantSumLogicDirection.horizontal,
            ConstantSumType.sum,
            getTranslation
          );
          const cell = tableCell.createAndFill({ colwidth: [width] }, [
            functionComponent
          ])!;
          cells.push(cell);
        } else {
          const inputNumber = createInputNumber(schema);
          const cell = tableInputCell.createAndFill({ colwidth: [width] }, [
            inputNumber
          ])!;
          cells.push(cell);
        }
      }

      rows.push(tableRow.createChecked(null, cells));
    }
  }

  return table.createChecked({ showFooter: false }, rows);
}

function createVerticalConstantSumMatrix(
  schema: ConstantSumSchema,
  getTranslation: GetTranslationFn
): Node<ConstantSumSchema> {
  const {
    table,
    tableRow,
    tableHeaderRow,
    tableFooterRow,
    tableHeader,
    tableCell,
    tableInputCell,
    tableFooter,
    paragraph
  } = schema.nodes;

  const rowsCount = 4;
  const columnsCount = 3;

  const rows: Node<ConstantSumSchema>[] = [];

  const width = 100 / columnsCount;

  for (let row = 1; row <= rowsCount; row++) {
    const cells = new Array<Node<ConstantSumSchema>>();

    if (row === 1) {
      for (let column = 1; column <= columnsCount; column++) {
        const headerCell = tableHeader.createAndFill({ colwidth: [width] })!;
        cells.push(headerCell);
      }

      rows.push(tableHeaderRow.createChecked(null, cells));
    } else if (row === rowsCount) {
      for (let column = 1; column <= columnsCount; column++) {
        if (column === 1) {
          const footerCell = tableFooter.createAndFill({ colwidth: [width] })!;
          cells.push(footerCell);
        } else {
          const functionComponent = createConstantSum(
            schema,
            ConstantSumLogicDirection.vertical,
            ConstantSumType.sum,
            getTranslation
          );
          const footerCell = tableFooter.createAndFill({ colwidth: [width] }, [
            functionComponent
          ])!;
          cells.push(footerCell);
        }
      }

      rows.push(tableFooterRow.createChecked(null, cells));
    } else {
      for (let column = 1; column <= columnsCount; column++) {
        if (column === 1) {
          const untitledItem = paragraph.createAndFill(
            null,
            schema.text(getTranslation("CONSTANT_SUM.MATRIX.ITEM"))
          )!;
          const cell = tableCell.createAndFill({ colwidth: [width] }, [
            untitledItem
          ])!;
          cells.push(cell);
        } else {
          const inputNumber = createInputNumber(schema);
          const cell = tableInputCell.createAndFill({ colwidth: [width] }, [
            inputNumber
          ])!;
          cells.push(cell);
        }
      }

      rows.push(tableRow.createChecked(null, cells));
    }
  }

  return table.createChecked(null, rows);
}

function createInputNumber(schema: ConstantSumSchema): Node<ConstantSumSchema> {
  return schema.nodes.inputNumber.create({
    minValue: 0,
    maxValue: 100,
    width: 100
  });
}

export function insertConstantSumMatrix<S extends Schema>(
  direction: ConstantSumLogicDirection
): CommandFn<S> {
  return (state, dispatch) => {
    const { schema } = state;

    if (!canInsert(schema.nodes.table)(state)) {
      return false;
    }

    if (dispatch) {
      const translation = getTranslation(state);

      const constantSumMatrix = createConstantSumMatrix(
        schema,
        direction,
        translation
      );

      let tr = state.tr;
      tr = insertBlock(
        tr,
        state.schema,
        constantSumMatrix as Node<S>,
        true,
        getIdGenerator(state)
      );

      dispatch(tr);
    }

    return true;
  };
}
