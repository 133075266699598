export type ColorPaletteId =
  | "black"
  | "brown"
  | "blue"
  | "green"
  | "sunshine"
  | "dream"
  | "ice-cream"
  | "vintage"
  | "dark-black"
  | "dark-brown"
  | "dark-blue"
  | "dark-green"
  | "dark-dream"
  | "dark-vintage"
  | "dark-ice-cream"
  | "dark-sunshine";

export type ColorPaletteType = "light" | "dark";

export class Color {
  constructor(
    private readonly _red: number,
    private readonly _green: number,
    private readonly _blue: number,
    private _alpha: number = 1
  ) {}

  get red(): number {
    return this._red;
  }

  get green(): number {
    return this._green;
  }

  get blue(): number {
    return this._blue;
  }

  get alpha(): number {
    return this._alpha;
  }

  get rgbaCode(): string {
    return `rgba(${this.red}, ${this.green}, ${this.blue}, ${this.alpha})`;
  }
}

interface Colors {
  "1": Color;
  "2": Color;
  "3": Color;
  "4": Color;
  "5": Color;
  "6"?: Color;
}

export class ColorSet {
  constructor(private colors: Colors) {}

  get one(): Color {
    return this.colors["1"];
  }

  get two(): Color {
    return this.colors["2"];
  }

  get three(): Color {
    return this.colors["3"];
  }

  get four(): Color {
    return this.colors["4"];
  }

  get five(): Color {
    return this.colors["5"];
  }

  get six(): Color | undefined {
    return this.colors["6"];
  }
}

export interface ColorScheme {
  primary: ColorSet;
  theme: ColorSet;
}

interface ColorPaletteBuilder {
  id: ColorPaletteId;
  name: string;
  type: ColorPaletteType;
  colorScheme: ColorScheme;
}

export class ColorPalette {
  private readonly _id: ColorPaletteId;
  private readonly _name: string;
  private readonly _type: ColorPaletteType;
  private readonly _colorScheme: ColorScheme;

  constructor(builder: ColorPaletteBuilder) {
    this._id = builder.id;
    this._name = builder.name;
    this._type = builder.type ? builder.type : "light";
    this._colorScheme = builder.colorScheme;
  }

  get id(): ColorPaletteId {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  get type(): ColorPaletteType {
    return this._type;
  }

  get colorScheme(): ColorScheme {
    return this._colorScheme;
  }
}
