import { Schema } from "prosemirror-model";
import { NodeDTO } from "../../util";

export enum InputChoiceControlType {
  singleVertical = "single-vertical",
  singleHorizontal = "single-horizontal",
  multipleVertical = "multiple-vertical",
  multipleHorizontal = "multiple-horizontal",
  dropdown = "dropdown-list",
  listbox = "list-box"
}

export enum InputChoiceLabelPosition {
  top = "top",
  bottom = "bottom",
  right = "right",
  boxed = "boxed"
}

export enum InputChoiceType {
  standard = "standard",
  allOfTheAbove = "all-of-the-above",
  noneOfTheAbove = "none-of-the-above"
}

export enum InputChoiceRows {
  defaultStandard = 1,
  defaultListBox = 5,
  minimum = 1,
  maximum = 10
}

export enum InputChoiceWidth {
  default = 100,
  minimum = 25,
  maximum = 100
}

export enum InputChoiceSelectionRangeMin {
  default = 0,
  minimum = 0,
  maximum = 20
}

export enum InputChoiceSelectionRangeMax {
  default = 1,
  minimum = 1,
  maximum = 20
}

export type InputChoiceSchema = Schema<
  | "inputChoice"
  | "inputChoiceValue"
  | "inputChoiceAllOfTheAboveValue"
  | "inputChoiceNoneOfTheAboveValue"
  | "inputChoiceOtherSpecifyValue",
  any
>;

export interface InputChoiceValue {
  id: string;
  content: NodeDTO[];
  coding: string | null;
  default: boolean;
}
