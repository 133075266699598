import { NodeSpec, Schema } from "prosemirror-model";
import { EditorState, NodeSelection, Plugin } from "prosemirror-state";
import {
  CommandConfiguration,
  CommandConfigurations,
  DocumentBuilders,
  Extension,
  NodeConfig
} from "../../editor";
import { emitNotification } from "../../editor/plugins/notification";
import { selectionFocusKey } from "../../editor/plugins/selection-focus";
import { canInsert } from "../../util/selection";
import { VideoNodeView } from "./video-node-view";

export enum VideoErrors {
  InsertInvalid = "video.insert.invalid-video-type"
}

const MIN_WIDTH = 5;
const MAX_WIDTH = 100;

interface VideoProvider {
  name: "youtube" | "vimeo" | "dailymotion";
  testRegex: RegExp;
  urlRegex: RegExp;
  urlPattern: string;
}

class VideotNode implements NodeConfig {
  get name(): string {
    return "video";
  }

  get spec(): NodeSpec {
    return {
      inline: true,
      attrs: {
        src: {},
        width: { default: 50 }
      },
      group: "inline",
      draggable: true,
      selectable: true,
      focusable: true,
      parseDOM: [
        {
          tag: "iframe[data-video]",
          getAttrs: (node) => {
            const element = node as HTMLIFrameElement;

            const src = element.getAttribute("src");
            const width = element.style.width.includes("%")
              ? parseInt(element.style.width)
              : undefined;

            return {
              src: src,
              width: width
            };
          }
        },
        {
          tag: ".fr-video iframe",
          getAttrs: (node) => {
            const element = node as HTMLIFrameElement;

            const src = element.getAttribute("src");
            const width = element.style.width.includes("%")
              ? parseInt(element.style.width)
              : undefined;

            return {
              src: src,
              width: width
            };
          }
        }
      ],
      toDOM(node) {
        return [
          "iframe",
          {
            "data-video": "",
            src: node.attrs.src,
            style: `width: ${node.attrs.width}%`
          }
        ];
      }
    };
  }

  get builders(): DocumentBuilders {
    return {
      video: {
        nodeType: "video",
        src: "https://www.youtube.com/embed/dQw4w9WgXcQ"
      }
    };
  }
}

type VideoSchema = Schema<"video", any>;

class VideoPlugin extends Plugin<VideoSchema> {
  constructor() {
    super({
      props: {
        nodeViews: {
          video: (node, view, getPos) => {
            return new VideoNodeView(
              node,
              view,
              getPos as () => number,
              MIN_WIDTH,
              MAX_WIDTH
            );
          }
        }
      }
    });
  }
}

export interface InsertVideoProps {
  url: string;
}

export interface VideoActiveValue {
  src: string;
  width: number;
}

export interface UpdateVideoCommandProps {
  width?: number;
}

export class Video implements Extension<VideoSchema> {
  private providers: VideoProvider[] = [
    {
      name: "youtube",
      testRegex: /^.*((youtu.be)|(youtube.com))\/((v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))?\??v?=?([^#&?]*).*/,
      urlRegex: /(?:https?:\/\/)?(?:www\.)?(?:m\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=|embed\/)?([0-9a-zA-Z_-]+)(.+)?/g,
      urlPattern: "https://www.youtube.com/embed/$1"
    },
    {
      name: "vimeo",
      testRegex: /^.*(?:vimeo.com)\/(?:channels(\/\w+\/)?|groups\/*\/videos\/​\d+\/|video\/|)(\d+)(?:$|\/|\?)/,
      urlRegex: /(?:https?:\/\/)?(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_-]+)?/i,
      urlPattern: "https://player.vimeo.com/video/$1"
    },
    {
      name: "dailymotion",
      testRegex: /^.+(dailymotion.com|dai.ly)\/(video|hub|embed\/video)?\/?([^_]+)[^#]*(#video=([^_&]+))?/,
      urlRegex: /(?:https?:\/\/)?(?:www\.)?(?:dailymotion\.com|dai\.ly)\/(?:video|hub|embed\/video)?\/?(.+)/g,
      urlPattern: "https://www.dailymotion.com/embed/video/$1"
    }
  ];

  get name(): string {
    return "video";
  }

  get nodes(): NodeConfig[] {
    return [new VideotNode()];
  }

  plugins(): Plugin[] {
    return [new VideoPlugin()];
  }

  commands(schema: VideoSchema): CommandConfigurations<VideoSchema> {
    return {
      insertVideo: this.insertVideoCommand(schema),
      updateVideo: this.updateVideoCommand(schema)
    };
  }

  private insertVideoCommand(
    schema: VideoSchema
  ): CommandConfiguration<VideoSchema, InsertVideoProps, undefined> {
    return {
      isActive: () => false,
      isEnabled: () => {
        return (state) => {
          const focused = focusedInlineNode(state);
          if (focused != null) {
            return true;
          } else {
            return canInsert(schema.nodes.video)(state);
          }
        };
      },
      execute: (props) => {
        return (state, dispatch) => {
          if (props == null) {
            throw new Error(
              `To insert a video, InsertVideoProps needs to be provided.`
            );
          }

          const { url } = props;

          if (url == null) {
            throw new Error(
              `To insert a video, file needs to be provided for InsertVideoProps.`
            );
          }

          const provider = findVideoProvider(this.providers, url);
          if (provider == null) {
            emitNotification(state, {
              type: "error",
              message: VideoErrors.InsertInvalid
            });
            return false;
          }

          if (!canInsert(state.schema.nodes.video)(state)) {
            return false;
          }

          if (dispatch) {
            const src = url.replace(provider.urlRegex, provider.urlPattern);
            const node = state.schema.nodes.video.create({
              src: src
            });

            const { from, to } = state.selection;
            let tr = state.tr;

            tr = tr.replaceWith(from, to, node);
            tr = tr.setSelection(new NodeSelection(tr.doc.resolve(from)));

            dispatch(tr);
          }

          return true;
        };
      }
    };
  }

  private updateVideoCommand(
    _schema: VideoSchema
  ): CommandConfiguration<
    VideoSchema,
    UpdateVideoCommandProps,
    VideoActiveValue | undefined
  > {
    return {
      isActive: () => {
        return (state) => {
          return focusedVideo(state) != null;
        };
      },
      isEnabled: () => {
        return (state) => {
          return focusedVideo(state) != null;
        };
      },
      execute: (props) => {
        return (state, dispatch) => {
          if (props == null) {
            throw new Error(
              `To update a video, UpdateVideoCommandProps needs to be provided.`
            );
          }

          const focused = focusedVideo(state);
          if (!focused) {
            return false;
          }

          if (dispatch) {
            const { node, pos } = focused;
            const updatedAttrs = { ...node.attrs };

            if (props?.width != null && !isNaN(props?.width)) {
              updatedAttrs.width = props.width;
            }

            let tr = state.tr;
            tr = tr.setNodeMarkup(pos, undefined, updatedAttrs);
            tr = tr.setSelection(new NodeSelection(tr.doc.resolve(pos)));

            dispatch(tr);
          }

          return true;
        };
      },
      activeValue: () => {
        return (state) => {
          const focused = focusedVideo(state);
          if (!focused) {
            return undefined;
          }

          const { node } = focused;

          return {
            src: node.attrs.src,
            width: node.attrs.width
          };
        };
      }
    };
  }
}

function findVideoProvider(
  providers: VideoProvider[],
  url: string
): VideoProvider | undefined {
  const provider = providers.find((p) => {
    if (p.testRegex.test(url)) {
      return p;
    } else {
      return null;
    }
  });

  return provider;
}

function focusedInlineNode(state: EditorState<VideoSchema>) {
  const { schema } = state;

  const focused = selectionFocusKey.getState(state);
  if (
    focused != null &&
    focused.node.isInline &&
    focused.node.type !== schema.nodes.video &&
    focused.node.type !== schema.nodes.image
  ) {
    return focused;
  } else {
    return undefined;
  }
}

function focusedVideo(state: EditorState<VideoSchema>) {
  const { schema } = state;

  const focused = selectionFocusKey.getState(state);
  if (focused != null && focused.node.type === schema.nodes.video) {
    return focused;
  } else {
    return undefined;
  }
}
