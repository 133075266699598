import { Node, Schema } from "prosemirror-model";
import { EditorState, NodeSelection } from "prosemirror-state";
import { Md5 } from "ts-md5/dist/md5";
import { selectionFocusKey, Focus } from "../../editor/plugins/selection-focus";
import { findParent } from "../../util";
import { GetTranslationFn } from "../localization";
import { InputScaleControlType, InputScaleSchema, Scale } from "./schema";

export function createInputScaleNotApplicable(
  schema: InputScaleSchema,
  getTranslation: GetTranslationFn
): Node<InputScaleSchema> {
  const { inputScaleNotApplicable } = schema.nodes;

  return inputScaleNotApplicable.createChecked(
    { id: null },
    schema.text(getTranslation("INPUT_LIKERT.NOT_APPLICABLE"))
  );
}

export function createInputScaleValues(
  schema: InputScaleSchema,
  scale: Scale,
  notApplicableNode: Node<InputScaleSchema> | undefined,
  getTranslation: GetTranslationFn
): Node<InputScaleSchema>[] {
  const { inputScaleValue } = schema.nodes;

  const options = scale.options.map((option) => {
    return inputScaleValue.createChecked(
      undefined,
      schema.text(getTranslation(option.label))
    );
  });

  if (notApplicableNode != null) {
    return options.concat(notApplicableNode);
  } else {
    return options;
  }
}

export function createInputScale(
  schema: InputScaleSchema,
  controlType: InputScaleControlType,
  scale: Scale,
  notApplicableNode: Node<InputScaleSchema> | undefined,
  getTranslation: GetTranslationFn
): Node<InputScaleSchema> {
  const { inputScale } = schema.nodes;

  const values = createInputScaleValues(
    schema,
    scale,
    notApplicableNode,
    getTranslation
  );

  return inputScale.createChecked(
    {
      controlType: controlType,
      scale: scale.id
    },
    values
  );
}

export function hashForScale(options: string[]): string {
  return Md5.hashStr(options.join("|")) as string;
}

export function focusedInputScale(
  state: EditorState<InputScaleSchema>
): Focus<InputScaleSchema> | undefined {
  const { schema } = state;

  const focused = selectionFocusKey.getState(state);
  if (focused != null && focused.node.type === schema.nodes.inputScale) {
    return focused;
  } else {
    return undefined;
  }
}

export function focusedInputScaleLabels(
  state: EditorState<InputScaleSchema>
): Focus<InputScaleSchema> | undefined {
  const { schema } = state;
  const { selection } = state;

  const isNodeAnInputScaleLabels = (node: Node<Schema>) => {
    return node.type === schema.nodes.inputScaleLabels;
  };

  if (selection instanceof NodeSelection) {
    const { node, from } = selection;
    if (isNodeAnInputScaleLabels(node)) {
      return { node: node, pos: from };
    } else {
      return undefined;
    }
  } else {
    const { $from, $to, from, to } = selection;

    const fromParent = findParent($from, isNodeAnInputScaleLabels);
    const toParent = findParent($to, isNodeAnInputScaleLabels);

    if (fromParent != null && toParent != null) {
      const { node: fromNode, pos } = fromParent;
      const toNode = toParent.node;
      if (fromNode === toNode) {
        const nodeFrom = pos;
        const nodeTo = pos + fromNode.nodeSize;
        const range = { from: nodeFrom, to: nodeTo };

        if (from > range.from && to < range.to) {
          return { node: fromNode, pos: pos };
        }
      }
    }
    return undefined;
  }
}
