import { NodeSelection, Plugin } from "prosemirror-state";
import {
  CommandConfiguration,
  CommandConfigurations,
  CommandFn,
  Extension,
  NodeConfig
} from "../../editor";
import {
  dropInsertPlugin,
  executeAtPos,
  isEnableAtPos
} from "../../editor/plugins/drop-insert-plugin";
import { emitNotification } from "../../editor/plugins/notification";
import {
  applyQuestionTitle,
  questionTitleCacheKey,
  questionTitleCachePlugin,
  questionTitlePredicate
} from "../../editor/plugins/question-title-cache";
import { getIdGenerator } from "../../extensions/node-identifier";
import { isQuestionTitleAutoCreation } from "../../extensions/question-title/plugins/question-title-autocreation-flag";
import { isInsideGrid, isMac } from "../../util";
import { canInsert } from "../../util/selection";
import { insertBlock } from "../../util/transforms";
import {
  getQuestionTitleActiveValue,
  QuestionTitleActiveValue,
  updateQuestionTitleText
} from "../question-title";
import { InputNumberNode } from "./input-number-node";
import { nodeViewPlugin } from "./input-number-node-view";
import {
  InputNumberControlType,
  InputNumberLabelPosition,
  InputNumberOfDecimals,
  InputNumberSchema,
  InputNumberWidth
} from "./schema";
import { createInputNumber, focusedInputNumber } from "./util";

export interface InputNumberActiveValue {
  controlType: InputNumberControlType;
  labelPosition: InputNumberLabelPosition;
  width: number;
  numberOfDecimals: number;
  required: boolean;
  questionTitle: QuestionTitleActiveValue;
  description: string;
  coding: string;
  defaultValue: number | null;
  step: number | null;
  increment: number | null;
  prefix: string | null;
  suffix: string | null;
  minValue: number | null;
  maxValue: number | null;
  widthDisabled: boolean;
}

interface InsertInputNumberCommandProps {
  controlType: InputNumberControlType;
}

export interface UpdateInputNumberCommandProps {
  controlType?: InputNumberControlType;
  labelPosition?: InputNumberLabelPosition;
  width?: number;
  questionTitleText?: string;
  description?: string;
  coding?: string;
  defaultValue?: number;
  step?: number;
  prefix?: string;
  suffix?: string;
  numberOfDecimals?: number;
  minValue?: number;
  maxValue?: number;
  required?: boolean;
}

const questionTitleKey = questionTitleCacheKey();

export class InputNumber implements Extension<InputNumberSchema> {
  get name(): string {
    return "inputNumber";
  }

  get nodes(): NodeConfig[] {
    return [new InputNumberNode()];
  }

  plugins(schema: InputNumberSchema): Plugin[] {
    return [
      nodeViewPlugin(),
      dropInsertPlugin((view, data, posAtCoords) => {
        if (data.type !== "inputNumber") {
          return false;
        }

        let controlType: InputNumberControlType;
        switch (data.subType) {
          case InputNumberControlType.spinbox:
            controlType = InputNumberControlType.spinbox;
            break;

          case InputNumberControlType.slider:
            controlType = InputNumberControlType.slider;
            break;

          default:
            controlType = InputNumberControlType.spinbox;
            break;
        }

        const command = this.insertInputNumberCommand();

        const isEnabled = isEnableAtPos(command, view, posAtCoords);
        if (
          !isEnabled({
            controlType: controlType
          })
        ) {
          emitNotification(view.state, {
            type: "warning",
            message: "drop.insert.invalid-location"
          });
        } else {
          executeAtPos(
            command.execute({
              controlType: controlType
            }),
            view,
            posAtCoords
          );
        }

        return true;
      }),
      questionTitleCachePlugin(
        schema.nodes.inputNumber,
        "INPUT_NUMBER.DEFAULT_QUESTION_TITLE",
        questionTitlePredicate,
        applyQuestionTitle,
        questionTitleKey
      )
    ];
  }

  commands(): CommandConfigurations<InputNumberSchema> {
    return {
      insertInputNumber: this.insertInputNumberCommand(),
      updateInputNumber: this.updateInputNumberCommand()
    };
  }

  private insertInputNumberCommand(): CommandConfiguration<
    InputNumberSchema,
    InsertInputNumberCommandProps,
    undefined
  > {
    return {
      isActive: () => {
        return false;
      },
      isEnabled: () => {
        return (state) => {
          const { schema } = state;
          return canInsert(schema.nodes.inputNumber)(state);
        };
      },
      execute: (props) => {
        if (props?.controlType == null) {
          throw new Error(
            `To insert an input number, control type need to be provided.`
          );
        }

        return insertInputNumber(props);
      },
      shortcuts: {
        [isMac() ? "Ctrl-n" : "Alt-n"]: {
          controlType: InputNumberControlType.spinbox
        }
      }
    };
  }

  private updateInputNumberCommand(): CommandConfiguration<
    InputNumberSchema,
    UpdateInputNumberCommandProps,
    InputNumberActiveValue | undefined
  > {
    return {
      isActive: () => {
        return (state) => {
          return focusedInputNumber(state) != null;
        };
      },
      isEnabled: () => {
        return (state) => {
          return focusedInputNumber(state) != null;
        };
      },
      execute: (props) => {
        return (state, dispatch) => {
          if (props == null) {
            throw new Error(
              `To update a input-number, UpdateInputNumberCommandProps needs to be provided.`
            );
          }

          const focused = focusedInputNumber(state);
          if (!focused) {
            return false;
          }

          if (dispatch) {
            const { node, pos } = focused;
            let updatedAttrs = { ...node.attrs };

            let tr = state.tr;

            if (props.coding !== undefined) {
              updatedAttrs = { ...updatedAttrs, coding: props.coding };
            }

            if (props.controlType !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                controlType: props.controlType
              };
            }

            if (props.defaultValue !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                defaultValue: props.defaultValue
              };
            }

            if (props.description !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                description: props.description
              };
            }

            if (props.labelPosition !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                labelPosition: props.labelPosition
              };
            }

            if (props.maxValue !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                maxValue: props.maxValue
              };
            }

            if (props.minValue !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                minValue: props.minValue
              };
            }

            if (props.questionTitleText !== undefined) {
              updatedAttrs = updateQuestionTitleText(
                updatedAttrs,
                props.questionTitleText
              );
            }

            if (props.required !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                required: props.required
              };
            }

            if (props.step != null && !isNaN(props.step)) {
              updatedAttrs = {
                ...updatedAttrs,
                step: props.step
              };
            }

            if (props.prefix !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                prefix: props.prefix
              };
            }

            if (props.suffix !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                suffix: props.suffix
              };
            }

            if (props.numberOfDecimals !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                numberOfDecimals: Math.min(
                  Math.max(
                    props.numberOfDecimals,
                    InputNumberOfDecimals.minimum
                  ),
                  InputNumberOfDecimals.maximum
                )
              };

              if (updatedAttrs.step != null) {
                const value = parseFloat(
                  updatedAttrs.step.toFixed(updatedAttrs.numberOfDecimals)
                );
                updatedAttrs = {
                  ...updatedAttrs,
                  step: value
                };
              }
            }

            if (props.width != null && !isNaN(props.width)) {
              updatedAttrs = {
                ...updatedAttrs,
                width: Math.min(
                  Math.max(props.width, InputNumberWidth.minimum),
                  InputNumberWidth.maximum
                )
              };
            }

            if (
              (updatedAttrs.defaultValue != null &&
                updatedAttrs.defaultValue < updatedAttrs.minValue) ||
              updatedAttrs.defaultValue > updatedAttrs.maxValue
            ) {
              updatedAttrs = {
                ...updatedAttrs,
                defaultValue: null
              };
            }

            tr = tr.setNodeMarkup(pos, undefined, updatedAttrs);
            tr = tr.setSelection(new NodeSelection(tr.doc.resolve(pos)));

            dispatch(tr);
          }

          return true;
        };
      },
      activeValue: () => {
        return (state) => {
          const focused = focusedInputNumber(state);
          if (!focused) {
            return undefined;
          }

          const { node } = focused;

          const widthDisabled = isInsideGrid(state, focused);

          return {
            controlType: node.attrs.controlType,
            labelPosition: node.attrs.labelPosition,
            width: widthDisabled ? 100 : node.attrs.width,
            questionTitle: getQuestionTitleActiveValue(state, node),
            description: node.attrs.description,
            coding: node.attrs.coding,
            defaultValue: node.attrs.defaultValue,
            step: node.attrs.step,
            increment: 1 / Math.pow(10, node.attrs.numberOfDecimals),
            prefix: node.attrs.prefix,
            suffix: node.attrs.suffix,
            numberOfDecimals: node.attrs.numberOfDecimals,
            minValue: node.attrs.minValue,
            maxValue: node.attrs.maxValue,
            required: node.attrs.required,
            widthDisabled: widthDisabled
          };
        };
      }
    };
  }
}

function insertInputNumber(
  props: InsertInputNumberCommandProps
): CommandFn<InputNumberSchema> {
  return (state, dispatch) => {
    if (props == null) {
      return false;
    }

    const { schema } = state;

    if (!canInsert(schema.nodes.inputNumber)(state)) {
      return false;
    }

    if (dispatch) {
      const inputNumber = createInputNumber(schema, props.controlType);

      let tr = state.tr;
      tr = insertBlock(
        tr,
        state.schema,
        inputNumber,
        false,
        getIdGenerator(state),
        isQuestionTitleAutoCreation(state)
      );
      dispatch(tr);
    }

    return true;
  };
}
