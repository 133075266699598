import { MarkSpec, Schema } from "prosemirror-model";
import {
  BASE_PRIORITY,
  CommandConfiguration,
  CommandConfigurations,
  DocumentBuilders,
  Extension,
  MarkConfig
} from "../../editor";
import {
  activeTextMark,
  getHexColor,
  isHexString,
  removeMark,
  textMarkIsActive,
  textMarkIsEnabled,
  updateMark
} from "../../util";

class TextBackgroundColorMark implements MarkConfig {
  get name(): string {
    return "textBackgroundColor";
  }

  get spec(): MarkSpec {
    return {
      group: "text-style",
      attrs: { color: {} },
      parseDOM: [
        {
          style: "background",
          getAttrs: (value) => {
            if (typeof value !== "string") {
              return false;
            }

            if (value == null) {
              return false;
            }

            const color = getHexColor("background", value);

            return color ? { color: color } : false;
          }
        },
        {
          style: "background-color",
          getAttrs: (value) => {
            if (typeof value !== "string") {
              return false;
            }

            if (value == null) {
              return false;
            }

            const color = getHexColor("background", value);

            return color ? { color: color } : false;
          }
        }
      ],
      toDOM(node) {
        return [
          "span",
          {
            style: `background: ${node.attrs.color}`
          },
          0
        ];
      }
    };
  }

  get priority(): number {
    return BASE_PRIORITY - 10;
  }

  get builders(): DocumentBuilders {
    return {
      redTextBackgroundColor: {
        markType: "textBackgroundColor",
        color: "#FF0000"
      },
      greenTextBackgroundColor: {
        markType: "textBackgroundColor",
        color: "#00FF00"
      },
      blueTextBackgroundColor: {
        markType: "textBackgroundColor",
        color: "#0000FF"
      }
    };
  }
}

type TextBackgroundColorSchema = Schema<any, "textBackgroundColor">;

interface TextBackgroundColorCommandProps {
  color: string;
}

export class TextBackgroundColor
  implements Extension<TextBackgroundColorSchema> {
  private defaultColor: string | null = null;

  get name(): string {
    return "textBackgroundColor";
  }

  get marks(): MarkConfig[] {
    return [new TextBackgroundColorMark()];
  }

  commands(
    schema: TextBackgroundColorSchema
  ): CommandConfigurations<TextBackgroundColorSchema> {
    return {
      textBackgroundColor: this.textBackgroundColorCommand(schema)
    };
  }

  private textBackgroundColorCommand(
    schema: TextBackgroundColorSchema
  ): CommandConfiguration<
    TextBackgroundColorSchema,
    TextBackgroundColorCommandProps,
    string
  > {
    return {
      isActive: (props) =>
        textMarkIsActive(schema.marks.textBackgroundColor, props, {
          color: this.defaultColor
        }),
      isEnabled: () => textMarkIsEnabled(schema.marks.textBackgroundColor),
      execute: (props) => {
        const color = props?.color;
        if (
          color != null &&
          color !== this.defaultColor &&
          isHexString(color)
        ) {
          return updateMark(schema.marks.textBackgroundColor, {
            color: color.toUpperCase()
          });
        } else {
          return removeMark(schema.marks.textBackgroundColor);
        }
      },
      activeValue: () => {
        return (state) => {
          const mark = activeTextMark(
            schema.marks.textBackgroundColor,
            undefined,
            {
              color: this.defaultColor
            }
          )(state);

          if (mark) {
            return mark.attrs.color;
          } else {
            return undefined;
          }
        };
      }
    };
  }
}
