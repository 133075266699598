export function isEqual(
  a: Record<string, any> | null | undefined,
  b: Record<string, any> | null | undefined
): boolean {
  if (a == null && b != null) {
    return false;
  } else if (a != null && b == null) {
    return false;
  } else if (a != null && b != null) {
    const aPropsTemp = Object.getOwnPropertyNames(a);
    const bPropsTemp = Object.getOwnPropertyNames(b);

    const aProps = aPropsTemp.filter((p) => bPropsTemp.includes(p));
    const bProps = bPropsTemp.filter((p) => aPropsTemp.includes(p));

    if (aProps.length !== bProps.length) {
      return false;
    }

    for (let i = 0; i < aProps.length; i++) {
      const propName = aProps[i];

      if (a[propName] !== b[propName]) {
        return false;
      }
    }
    return true;
  } else {
    return true;
  }
}
