import {
  Editor as BlueXEditor,
  getTranslation,
  inputDateAvailableFormats,
  InputDateTimeActiveValue,
  InputDateTimeControlType,
  InputDateTimeWidth,
  inputTimeAvailableFormats,
  Schema
} from "@blue-x/editor";
import React, { useCallback, useMemo } from "react";
import {
  CheckboxFormControl,
  NumberFormControl,
  QuestionTitleFormControl,
  SelectFormControl,
  TextFormControl
} from "../ProportiesPanelControls";
import "./InputDateTime.css";

interface InputDateTimePropertiesPanelProps {
  editor: BlueXEditor<Schema>;
}

export const InputDateTimePropertiesPanel: React.FC<InputDateTimePropertiesPanelProps> = (
  props
) => {
  const { editor } = props;
  const { commands, state } = editor;

  const update = useCallback(
    (value) => {
      commands.updateInputDateTime.execute(value, false);
    },
    [commands]
  );

  const activeValue: InputDateTimeActiveValue = useMemo(() => {
    return commands.updateInputDateTime.activeValue();
  }, [commands]);

  const dateFormats: {
    id: string;
    text: string;
  }[] = useMemo(() => {
    const translate = getTranslation(state);
    return inputDateAvailableFormats.map((x) => {
      const text = typeof x.text === "string" ? x.text : translate(x.text.key);
      return { id: x.id, text: text };
    });
  }, [state]);

  return (
    <div className="InputDateTimePropertiesPanel">
      <SelectFormControl
        label={"Type"}
        value={activeValue.controlType || ""}
        onUpdate={(value) => {
          update({ controlType: value });
        }}
      >
        <option value="date">Date</option>
        <option value="time">Time</option>
      </SelectFormControl>

      <NumberFormControl
        label={"Width"}
        value={activeValue.width}
        min={InputDateTimeWidth.minimum}
        max={InputDateTimeWidth.maximum}
        step={1}
        onUpdate={(value) => {
          update({ width: value });
        }}
      />

      <QuestionTitleFormControl
        editor={editor}
        label={"Question title*"}
        value={activeValue.questionTitle}
      />

      <TextFormControl
        label={"Description"}
        value={activeValue.description || ""}
        onUpdate={(value) => {
          update({ description: value });
        }}
      />

      <TextFormControl
        label={"Question ID"}
        value={activeValue.coding || ""}
        onUpdate={(value) => {
          update({ coding: value });
        }}
      />

      <TextFormControl
        label={"Placeholder"}
        value={activeValue.watermark || ""}
        onUpdate={(value) => {
          update({ watermark: value });
        }}
      />

      {activeValue.controlType === InputDateTimeControlType.date &&
        !activeValue.isDefaultNow && (
          <TextFormControl
            label={"Default Value"}
            value={activeValue.defaultDate || ""}
            onUpdate={(value) => {
              update({ defaultDate: value });
            }}
          />
        )}

      {activeValue.controlType === InputDateTimeControlType.time &&
        !activeValue.isDefaultNow && (
          <TextFormControl
            label={"Default Value"}
            value={activeValue.defaultTime || ""}
            onUpdate={(value) => {
              update({ defaultTime: value });
            }}
          />
        )}

      <CheckboxFormControl
        label={"Now(relative to the respondent)"}
        value={activeValue.isDefaultNow}
        onUpdate={(value) => {
          update({ isDefaultNow: value });
        }}
      />

      {activeValue.controlType === InputDateTimeControlType.date && (
        <>
          <SelectFormControl
            label={"Date/time format"}
            value={activeValue.dateFormat || ""}
            onUpdate={(value) => {
              update({ dateFormat: value });
            }}
          >
            {dateFormats.map((format) => (
              <option key={format.id} value={format.id}>
                {format.text}
              </option>
            ))}
          </SelectFormControl>

          <TextFormControl
            label={"Minimum Date"}
            value={activeValue.minimumDate || ""}
            onUpdate={(value) => {
              update({ minimumDate: value });
            }}
          />

          <TextFormControl
            label={"Maximum Date"}
            value={activeValue.maximumDate || ""}
            onUpdate={(value) => {
              update({ maximumDate: value });
            }}
          />
        </>
      )}

      {activeValue.controlType === InputDateTimeControlType.time && (
        <>
          <SelectFormControl
            label={"Date/time format"}
            value={activeValue.timeFormat || ""}
            onUpdate={(value) => {
              update({ timeFormat: value });
            }}
          >
            {inputTimeAvailableFormats.map((format) => (
              <option key={format.id} value={format.id}>
                {format.text}
              </option>
            ))}
          </SelectFormControl>

          <TextFormControl
            label={"Minimum Time"}
            value={activeValue.minimumTime || ""}
            onUpdate={(value) => {
              update({ minimumTime: value });
            }}
          />

          <TextFormControl
            label={"Maximum Time"}
            value={activeValue.maximumTime || ""}
            onUpdate={(value) => {
              update({ maximumTime: value });
            }}
          />
        </>
      )}

      <CheckboxFormControl
        label={"Required"}
        value={activeValue.required}
        onUpdate={(value) => {
          update({ required: value });
        }}
      />
    </div>
  );
};
