import { EditorState, Plugin } from "prosemirror-state";
import {
  CommandConfiguration,
  CommandConfigurations,
  Extension,
  KeyMap,
  NodeConfig
} from "../../editor/extension";
import {
  dropInsertPlugin,
  executeAtPos,
  isEnableAtPos
} from "../../editor/plugins/drop-insert-plugin";
import { emitNotification } from "../../editor/plugins/notification";
import {
  applyName,
  namePredicate,
  questionTitleCacheKey,
  questionTitleCachePlugin
} from "../../editor/plugins/question-title-cache";
import { Focus } from "../../editor/plugins/selection-focus";
import { findChildren, focusedQuestionTitle, isMac } from "../../util";
import { AlignmentType } from "../alignment";
import { ConstantSumLogicDirection } from "../constant-sum";
import { updateName } from "../question-title";
import { deleteCellSelection, goToCell, insert } from "./commands";
import { TableNodeView } from "./node-views";
import {
  TableCellNode,
  TableCellWithoutInputsNode,
  TableFooterNode,
  TableFooterRowNode,
  TableHeaderNode,
  TableHeaderRowNode,
  TableInputCellNode,
  TableInputFooterNode,
  TableInputHeaderNode,
  TableNode,
  TableRowNode
} from "./nodes";
import {
  columnResizing,
  jumpHiddenRows,
  pastePlugin,
  repeatGrid,
  structurePlugin,
  tableControls
} from "./plugins";
import { cellSelection } from "./plugins/cell-selection";
import { CustomGridBorders, CustomGridSchema } from "./schema";
import { focusedCustomGrid } from "./util";

interface InsertGridProps {
  rows: number;
  columns: number;
}

export enum CellMoveDirection {
  NEXT = 1,
  PREVIOUS = -1
}

interface UpdateGridCommandProps {
  width?: number;
  description?: string;
  showHeader?: boolean;
  showFooter?: boolean;
  randomization?: boolean;
  repeatGrid?: boolean;
  responsive?: boolean;
  lastRowManualRepeat?: boolean;
  borders?: CustomGridBorders;
  stickyHeader?: boolean;
  alignment?: AlignmentType;
  name?: string;
}

export interface GridActiveValue {
  width: number | null;
  description?: string;
  showHeader?: boolean;
  showFooter?: boolean;
  randomization?: boolean;
  repeatGrid?: boolean;
  responsive?: boolean;
  lastRowManualRepeat?: boolean;
  borders?: CustomGridBorders;
  stickyHeader?: boolean;
  alignment?: AlignmentType;
  name?: string;
  hasRepeatErrors?: boolean;
}

const questionTitleKey = questionTitleCacheKey();

export class CustomGrid implements Extension<CustomGridSchema> {
  constructor(private supportsInputs: boolean) {}

  get name(): string {
    return "customGrid";
  }

  get nodes(): NodeConfig[] {
    const nodes = [
      new TableNode(this.supportsInputs),
      new TableHeaderRowNode(),
      new TableRowNode(),
      new TableFooterRowNode(),
      new TableHeaderNode(this.supportsInputs),
      new TableCellNode(this.supportsInputs),
      new TableCellWithoutInputsNode(this.supportsInputs),
      new TableFooterNode(this.supportsInputs)
    ];

    if (this.supportsInputs) {
      nodes.push(new TableInputHeaderNode());
      nodes.push(new TableInputCellNode());
      nodes.push(new TableInputFooterNode());
    }

    return nodes;
  }

  plugins(schema: CustomGridSchema): Plugin[] {
    return [
      columnResizing(),
      tableControls(),
      cellSelection(),
      questionTitleCachePlugin(
        schema.nodes.table,
        "CUSTOM_GRID.DEFAULT_NAME",
        namePredicate,
        applyName,
        questionTitleKey
      ),
      structurePlugin(schema),
      repeatGrid(schema),
      pastePlugin(schema),
      dropInsertPlugin((view, data, posAtCoords) => {
        if (data.type !== "customGrid") {
          return false;
        }

        const command = this.insertGridCommand(schema);

        const isEnabled = isEnableAtPos(command, view, posAtCoords);
        if (
          !isEnabled({
            rows: 2,
            columns: 2
          })
        ) {
          emitNotification(view.state, {
            type: "warning",
            message: "drop.insert.invalid-location"
          });
        } else {
          executeAtPos(
            command.execute({
              rows: 2,
              columns: 2
            }),
            view,
            posAtCoords
          );
        }

        return true;
      }),
      jumpHiddenRows(),
      new Plugin({
        props: {
          nodeViews: {
            table: (node, view, getPos) => {
              return new TableNodeView(node, view, getPos as () => number);
            }
          }
        }
      })
    ];
  }

  commands(schema: CustomGridSchema): CommandConfigurations<CustomGridSchema> {
    return {
      insertGrid: this.insertGridCommand(schema),
      updateGrid: this.updateGridCommand(schema)
    };
  }

  keymaps(schema: CustomGridSchema): KeyMap<CustomGridSchema> {
    return {
      Backspace: deleteCellSelection(schema),
      "Mod-Backspace": deleteCellSelection(schema),
      Delete: deleteCellSelection(schema),
      "Mod-Delete": deleteCellSelection(schema),
      Tab: goToCell(CellMoveDirection.NEXT),
      "Shift-Tab": goToCell(CellMoveDirection.PREVIOUS)
    };
  }

  private insertGridCommand(
    _schema: CustomGridSchema
  ): CommandConfiguration<
    CustomGridSchema,
    InsertGridProps,
    GridActiveValue | undefined
  > {
    return {
      isActive: () => false,
      isEnabled: () => {
        return insert(1, 1);
      },
      execute: (props) => {
        if (props == null) {
          throw new Error(
            `To insert a grid, InsertGridProps needs to be provided.`
          );
        }

        const { rows, columns } = props;

        if (rows == null || columns == null) {
          throw new Error(
            `To insert a grid, rows and columns needs to be provided for InsertGridProps.`
          );
        }

        return insert(rows, columns);
      },
      shortcuts: {
        [isMac() ? "Ctrl-s" : "Alt-s"]: { rows: 2, columns: 2 }
      }
    };
  }

  private updateGridCommand(
    _schema: CustomGridSchema
  ): CommandConfiguration<
    CustomGridSchema,
    UpdateGridCommandProps,
    GridActiveValue | undefined
  > {
    return {
      isActive: () => {
        return (state) => {
          const isFocused = focusedCustomGrid(state) != null;
          if (isFocused) {
            const focused = focusedQuestionTitle(state);
            return focused != null ? false : true;
          } else {
            return false;
          }
        };
      },
      isEnabled: () => {
        return (state) => {
          return focusedCustomGrid(state) != null;
        };
      },
      execute: (props) => {
        return (state, dispatch) => {
          if (props == null) {
            throw new Error(
              `To update a grid, UpdateGridCommandProps needs to be provided.`
            );
          }

          const grid = focusedCustomGrid(state);
          if (grid == null) {
            return false;
          }

          if (dispatch) {
            const { pos, node } = grid;
            let updatedAttrs = { ...node.attrs };

            if (props?.width != null && !isNaN(props?.width)) {
              updatedAttrs.width = props.width;
            }

            if (props?.name != null) {
              updatedAttrs = updateName(updatedAttrs, props.name);
            }

            if (props?.showFooter != null) {
              updatedAttrs.showFooter = props.showFooter;
            }

            if (props?.showHeader != null) {
              updatedAttrs.showHeader = props.showHeader;
            }

            if (props?.description != null) {
              updatedAttrs.description = props.description;
            }

            if (props?.borders != null) {
              updatedAttrs.borders = props.borders;
            }

            if (props?.repeatGrid != null) {
              updatedAttrs.repeatGrid = this.supportsInputs
                ? props.repeatGrid
                : false;
            }

            if (props?.stickyHeader != null) {
              updatedAttrs.stickyHeader = props.stickyHeader;
            }

            if (props?.responsive != null) {
              updatedAttrs.responsive = props.responsive;
            }

            if (props?.lastRowManualRepeat != null) {
              updatedAttrs.lastRowManualRepeat = this.supportsInputs
                ? props.lastRowManualRepeat
                : false;

              if (updatedAttrs.lastRowManualRepeat) {
                updatedAttrs.randomization = false;
              }
            }

            if (props?.randomization != null) {
              updatedAttrs.randomization =
                this.supportsInputs &&
                (!props?.lastRowManualRepeat ||
                  (props?.lastRowManualRepeat &&
                    node.attrs.lastRowManualRepeat))
                  ? props.randomization
                  : false;

              if (updatedAttrs.randomization) {
                updatedAttrs.lastRowManualRepeat = false;
              }
            }

            let tr = state.tr;
            tr = tr.setNodeMarkup(pos, undefined, updatedAttrs);

            dispatch(tr);
          }

          return true;
        };
      },
      activeValue: () => {
        return (state) => {
          const grid = focusedCustomGrid(state);
          if (grid == null) {
            return undefined;
          }

          const { node } = grid;
          const lastRowManualRepeat = node.attrs.lastRowManualRepeat;

          return {
            width: node.attrs.width,
            description: node.attrs.description,
            showHeader: node.attrs.showHeader,
            showFooter: node.attrs.showFooter,
            randomization: node.attrs.randomization,
            repeatGrid: node.attrs.repeatGrid,
            responsive: node.attrs.responsive,
            lastRowManualRepeat,
            borders: node.attrs.borders,
            stickyHeader: node.attrs.stickyHeader,
            alignment: node.attrs.alignment,
            name: node.attrs.name,
            hasRepeatErrors: getRepeatErrors(state, grid, lastRowManualRepeat)
          };
        };
      }
    };
  }
}

function getRepeatErrors(
  state: EditorState<CustomGridSchema>,
  grid: Focus,
  lastRowManualRepeat: boolean
): boolean {
  const { schema } = state;

  const tableRows = findChildren(
    grid.node,
    (n) => n.type === schema.nodes.tableRow
  );

  if (tableRows.length !== 0) {
    const lastRow = tableRows[tableRows.length - 1];

    const constantSum = findChildren(
      lastRow.node,
      (n) => n.type === schema.nodes.constantSum
    );

    if (constantSum.length !== 0) {
      const constantNode = constantSum[constantSum.length - 1];

      return (
        lastRowManualRepeat &&
        constantNode.node.attrs.direction === ConstantSumLogicDirection.vertical
      );
    }
  }
  return false;
}
