import { InputRule, inputRules } from "prosemirror-inputrules";
import { Schema } from "prosemirror-model";
import { Plugin } from "prosemirror-state";

export interface ExtensionInputRules<S extends Schema> {
  inputRules?: (schema: S) => InputRule<S>[];
}

export function extensionInputRules<S extends Schema>(
  extensions: ExtensionInputRules<S>[],
  schema: S
): Plugin[] {
  return extensions.reduce((acc, elem) => {
    const extension = elem.inputRules;
    if (extension) {
      const rules = extension.bind(elem)(schema);
      const plugin = inputRules({ rules: rules });
      return acc.concat(plugin);
    } else {
      return acc;
    }
  }, new Array<Plugin>());
}
