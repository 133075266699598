import { DOMParser, Fragment, Node, NodeSpec } from "prosemirror-model";
import { DocumentBuilders, NodeConfig } from "../../../editor";
import {
  getQuestionTitleAttrs,
  setQuestionTitleAttrs
} from "../../../extensions/question-title";
import { toNumberValue } from "../../../util";
import { AlignmentType } from "../../alignment";
import {
  InputChoiceControlType,
  InputChoiceLabelPosition,
  InputChoiceRows,
  InputChoiceSchema,
  InputChoiceSelectionRangeMax,
  InputChoiceSelectionRangeMin,
  InputChoiceWidth
} from "../schema";

function setInputChoiceAttrs(node: Node): { [key: string]: string } {
  return {
    id: node.attrs.id,
    ...setQuestionTitleAttrs(node),
    "data-description": node.attrs.description,
    "data-required": node.attrs.required === true ? "true" : "false",
    "data-coding": node.attrs.coding,
    "data-control-type": node.attrs.controlType,
    "data-watermark": node.attrs.watermark,
    "data-label-position": node.attrs.labelPosition,
    "data-width": node.attrs.width,
    "data-rows": node.attrs.rows,
    "data-choice-selection-min": node.attrs.choiceSelectionRangeMin,
    "data-choice-selection-max": node.attrs.choiceSelectionRangeMax,
    "data-randomize-options": node.attrs.randomize === true ? "true" : "false",
    "data-respondents-must-specify":
      node.attrs.respondentsMustSpecify === true ? "true" : "false",
    "data-alignment": node.attrs.alignment
  };
}

function getInputChoiceAttrs(element: Element): { [key: string]: any } {
  const attrs: {
    id?: string;
    description?: string;
    questionTitleText?: string;
    questionTitleRefElementId?: string[];
    controlType?: InputChoiceControlType;
    labelPosition?: InputChoiceLabelPosition;
    width?: number;
    watermark?: string;
    required?: boolean;
    coding?: string;
    rows?: number;
    choiceSelectionRangeMin?: number;
    choiceSelectionRangeMax?: number;
    randomize?: boolean;
    respondentsMustSpecify?: boolean;
    alignment?: AlignmentType;
  } = { ...getQuestionTitleAttrs(element) };

  const id = element.getAttribute("id");
  if (id != null) {
    attrs.id = id;
  }

  const description = element.getAttribute("data-description");
  if (description != null) {
    attrs.description = description;
  }

  const coding = element.getAttribute("data-coding");
  if (coding != null) {
    attrs.coding = coding;
  }

  const watermark = element.getAttribute("data-watermark");
  if (watermark != null) {
    attrs.watermark = watermark;
  }

  const controlType = element.getAttribute(
    "data-control-type"
  ) as InputChoiceControlType;
  if (controlType != null) {
    switch (controlType) {
      case InputChoiceControlType.singleVertical:
      case InputChoiceControlType.singleHorizontal:
      case InputChoiceControlType.multipleVertical:
      case InputChoiceControlType.multipleHorizontal:
      case InputChoiceControlType.listbox:
      case InputChoiceControlType.dropdown:
        attrs.controlType = controlType;
        break;
      default:
        break;
    }
  }

  const labelPosition = element.getAttribute(
    "data-label-position"
  ) as InputChoiceLabelPosition;
  if (labelPosition != null) {
    switch (labelPosition) {
      case InputChoiceLabelPosition.bottom:
      case InputChoiceLabelPosition.boxed:
      case InputChoiceLabelPosition.right:
      case InputChoiceLabelPosition.top:
        attrs.labelPosition = labelPosition;
        break;
      default:
        break;
    }
  }

  const width = toNumberValue(element.getAttribute("data-width"), 0);
  if (width != null) {
    attrs.width = width;
  }

  const required = element.getAttribute("data-required");
  if (required != null) {
    attrs.required = required.toLowerCase() === "true";
  }

  const rows = toNumberValue(element.getAttribute("data-rows"), 0);
  if (rows != null) {
    attrs.rows = rows;
  }

  const choiceSelectionRangeMin = toNumberValue(
    element.getAttribute("data-choice-selection-min"),
    0
  );
  if (choiceSelectionRangeMin != null) {
    attrs.choiceSelectionRangeMin = choiceSelectionRangeMin;
  }

  const choiceSelectionRangeMax = toNumberValue(
    element.getAttribute("data-choice-selection-max"),
    0
  );
  if (choiceSelectionRangeMax != null) {
    attrs.choiceSelectionRangeMax = choiceSelectionRangeMax;
  } else {
    switch (controlType) {
      case InputChoiceControlType.multipleVertical:
      case InputChoiceControlType.multipleHorizontal:
        attrs.choiceSelectionRangeMax = 0;
        break;
      case InputChoiceControlType.singleVertical:
      case InputChoiceControlType.singleHorizontal:
      case InputChoiceControlType.listbox:
      case InputChoiceControlType.dropdown:
        break;
      default:
        break;
    }
  }

  const randomize = element.getAttribute("data-randomize-options");
  if (randomize != null) {
    attrs.randomize = randomize.toLowerCase() === "true";
  }

  const respondentsMustSpecify = element.getAttribute(
    "data-respondents-must-specify"
  );
  if (respondentsMustSpecify != null) {
    attrs.respondentsMustSpecify =
      respondentsMustSpecify.toLowerCase() === "true";
  }

  const alignment = element.getAttribute("data-alignment") as AlignmentType;
  if (alignment != null) {
    switch (alignment) {
      case "center":
      case "left":
      case "right":
        attrs.alignment = alignment;
        break;
      default:
        break;
    }
  }

  return attrs;
}

export class InputChoiceNode implements NodeConfig {
  get name(): string {
    return "inputChoice";
  }

  get spec(): NodeSpec {
    return {
      group: "block input gridInput",
      content:
        "inputChoiceValue* inputChoiceAllOfTheAboveValue{0,1} inputChoiceNoneOfTheAboveValue{0,1} inputChoiceOtherSpecifyValue{0,1}",
      draggable: true,
      selectable: true,
      focusable: true,
      allowGapCursor: true,
      allowIndentation: false,
      allowAlignment: ["left", "center", "right"],
      blockAlignment: true,
      isolating: true,
      attrs: {
        id: {
          default: null
        },
        questionTitleText: { default: null },
        questionTitleRefElementId: { default: new Array<string>() },
        description: { default: "" },
        coding: { default: "" },
        watermark: { default: "" },
        controlType: {
          default: InputChoiceControlType.singleVertical
        },
        randomize: {
          default: false
        },
        respondentsMustSpecify: {
          default: false
        },
        labelPosition: {
          default: InputChoiceLabelPosition.right
        },
        width: {
          default: InputChoiceWidth.default
        },
        required: {
          default: false
        },
        rows: {
          default: InputChoiceRows.defaultStandard
        },
        choiceSelectionRangeMin: {
          default: InputChoiceSelectionRangeMin.default
        },
        choiceSelectionRangeMax: {
          default: InputChoiceSelectionRangeMax.default
        },
        alignment: {
          default: null
        }
      },
      parseDOM: [
        {
          tag: "input-choice",
          getAttrs(node) {
            if (typeof node === "string") {
              return false;
            }
            return getInputChoiceAttrs(node as Element);
          }
        },
        {
          tag: `[data-input="choice"]`,
          getAttrs(node) {
            if (typeof node === "string") {
              return false;
            }
            return getInputChoiceAttrs(node as Element);
          },
          getContent: (node, schema) => {
            return legacyChoices(node as Element, schema);
          }
        }
      ],
      toDOM(node) {
        return ["input-choice", setInputChoiceAttrs(node), 0];
      },
      applyBlueprint(
        attrs: Record<string, any>,
        blueprint: Record<string, any>
      ) {
        const updated = { ...attrs };

        updated.required = blueprint.required;
        updated.choiceSelectionRangeMin = blueprint.choiceSelectionRangeMin;
        updated.choiceSelectionRangeMax = blueprint.choiceSelectionRangeMax;
        updated.controlType = blueprint.controlType;
        updated.labelPosition = blueprint.labelPosition;
        updated.randomize = blueprint.randomize;
        updated.watermark = blueprint.watermark;
        updated.rows = blueprint.rows;
        updated.respondentsMustSpecify = blueprint.respondentsMustSpecify;

        return updated;
      }
    };
  }

  get builders(): DocumentBuilders {
    return {
      inputChoice: { nodeType: "inputChoice" }
    };
  }
}

function legacyChoices(element: Element, schema: InputChoiceSchema): Fragment {
  const parser = DOMParser.fromSchema(schema);
  const options = { topNode: schema.nodes.inputChoice.create() };
  const node = parser.parse(element, options);
  const fragment = node.content;

  let updated = Fragment.from(fragment);

  const parentId = element.getAttribute("id");
  if (parentId != null) {
    fragment.forEach((node, _offset, index) => {
      if (
        [
          schema.nodes.inputChoiceValue,
          schema.nodes.inputChoiceAllOfTheAboveValue,
          schema.nodes.inputChoiceNoneOfTheAboveValue,
          schema.nodes.inputChoiceOtherSpecifyValue
        ].includes(node.type)
      ) {
        updated = updated.replaceChild(
          index,
          node.type.create(
            { ...node.attrs, id: `${parentId}-${node.attrs.id}` },
            node.content,
            node.marks
          )
        );
      }
    });
  }

  return updated;
}
