import { InputRule } from "prosemirror-inputrules";
import { NodeSpec, Schema } from "prosemirror-model";
import { Plugin } from "prosemirror-state";
import {
  CommandConfiguration,
  CommandConfigurations,
  DocumentBuilders,
  Extension,
  KeyMap,
  NodeConfig
} from "../../editor";
import {
  backspaceCommand,
  deleteCommand,
  enterCommand,
  toggleList,
  wrappingListInputRule
} from "./commands";
import { ListItemNode, ListItemPlugin } from "./list-item";
import { isListActive, isListEnabled } from "./util";

function getAttrs(
  value: Node | string
): { [key: string]: any } | false | null | undefined {
  if (typeof value === "string") {
    return false;
  }

  const element = value as HTMLElement;
  const attrs: {
    id?: string;
  } = {};

  const id = element.getAttribute("id");
  if (id != null) {
    attrs.id = id;
  }

  return attrs;
}

class BulletListNode implements NodeConfig {
  get name(): string {
    return "bulletList";
  }

  get spec(): NodeSpec {
    return {
      group: "block rootBlock",
      content: "listItem+",
      selectable: false,
      attrs: {
        id: { default: null }
      },
      parseDOM: [
        {
          tag: "ul",
          getAttrs: getAttrs
        }
      ],
      toDOM(node) {
        return ["ul", { id: node.attrs.id }, 0];
      }
    };
  }

  get builders(): DocumentBuilders {
    return {
      ul: { nodeType: "bulletList" }
    };
  }
}

type BulletListSchema = Schema<"bulletList" | "listItem", any>;

export class BulletList implements Extension<BulletListSchema> {
  get name(): string {
    return "bulletList";
  }

  get nodes(): NodeConfig[] {
    return [new BulletListNode(), new ListItemNode()];
  }

  commands(schema: BulletListSchema): CommandConfigurations<BulletListSchema> {
    return {
      bulletList: this.bulletListCommand(schema)
    };
  }

  keymaps(_schema: BulletListSchema): KeyMap<BulletListSchema> {
    return {
      Enter: enterCommand,
      Backspace: backspaceCommand,
      Delete: deleteCommand
    };
  }

  inputRules(schema: BulletListSchema): InputRule<BulletListSchema>[] {
    return [wrappingListInputRule(/^\s*([*-])\s$/, schema.nodes.bulletList)];
  }

  plugins(): Plugin<BulletListSchema>[] {
    return [new ListItemPlugin()];
  }

  private bulletListCommand(
    schema: BulletListSchema
  ): CommandConfiguration<BulletListSchema, {}, undefined> {
    return {
      isActive: () => isListActive(schema.nodes.bulletList),
      isEnabled: () => isListEnabled(schema.nodes.bulletList),
      execute: () => toggleList(schema.nodes.bulletList)
    };
  }
}
