import { Node, NodeSpec } from "prosemirror-model";
import { DocumentBuilders, NodeConfig, BASE_PRIORITY } from "../../../editor";
import {
  getVerticalAlignment,
  VerticalAlignmentType
} from "../../vertical-alignment";

export interface CellAttributes {
  id: string;
  colspan: number;
  rowspan: number;
  colwidth: number[];
}

const cellAttrs = {
  id: { default: null },
  colspan: { default: 1 },
  rowspan: { default: 1 },
  colwidth: { default: [] },
  verticalAlignment: { default: null }
};

export class TableCellNode implements NodeConfig {
  constructor(private supportsInputs: boolean) {}

  get name(): string {
    return "tableCell";
  }

  get spec(): NodeSpec {
    return {
      content:
        this.supportsInputs === true
          ? "(paragraph | gridInput | gridFunction)+"
          : "paragraph+",
      group: "cell",
      tableRole: "cell",
      isolating: true,
      attrs: cellAttrs,
      parseDOM: [
        {
          tag: "td",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getAttrs(node as HTMLElement);
          },
          priority: BASE_PRIORITY - 1
        },
        {
          tag: "td",
          context: "table/tableRow/",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getAttrs(node as HTMLElement);
          }
        },
        {
          tag: "div",
          context: "table/tableRow/",
          getAttrs(node) {
            if (typeof node === "string") {
              return false;
            }

            return getAttrs(node as HTMLElement);
          }
        }
      ],
      toDOM(node) {
        return ["td", setAttrs(node, false), 0];
      }
    };
  }

  get builders(): DocumentBuilders {
    return { td: { nodeType: "tableCell" } };
  }
}

export class TableCellWithoutInputsNode implements NodeConfig {
  constructor(private supportsInputs: boolean) {}

  get name(): string {
    return "tableCellWithoutInputs";
  }

  get spec(): NodeSpec {
    return {
      content: this.supportsInputs
        ? "(paragraph | gridFunction)+"
        : "paragraph+",
      tableRole: "cell",
      isolating: true,
      attrs: cellAttrs,
      parseDOM: [
        {
          tag: "td",
          context: "table/tableRow/",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getAttrs(node as HTMLElement);
          }
        },
        {
          tag: "div",
          context: "table/tableRow/",
          getAttrs(node) {
            if (typeof node === "string") {
              return false;
            }

            return getAttrs(node as HTMLElement);
          }
        }
      ],
      toDOM(node) {
        return ["td", setAttrs(node, false), 0];
      }
    };
  }
  get builders(): DocumentBuilders {
    return { tableCellWithoutInputs: { nodeType: "tableCellWithoutInputs" } };
  }
}

export class TableInputCellNode implements NodeConfig {
  get name(): string {
    return "tableInputCell";
  }

  get spec(): NodeSpec {
    return {
      content: "gridInput{0,1}",
      group: "cell",
      tableRole: "cell",
      isolating: true,
      attrs: cellAttrs,
      parseDOM: [
        {
          tag: "td.ProseMirror-input-cell",
          context: "table/tableRow/",
          priority: BASE_PRIORITY + 1,
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getAttrs(node as HTMLElement);
          }
        },
        {
          tag: "div.ProseMirror-input-cell",
          context: "table/tableRow/",
          priority: BASE_PRIORITY + 1,
          getAttrs(node) {
            if (typeof node === "string") {
              return false;
            }

            return getAttrs(node as HTMLElement);
          }
        }
      ],
      toDOM(node) {
        return ["td", setAttrs(node, true), 0];
      }
    };
  }

  get builders(): DocumentBuilders {
    return { tableInputCell: { nodeType: "tableInputCell" } };
  }
}

export class TableHeaderNode implements NodeConfig {
  constructor(private supportsInputs: boolean) {}
  get name(): string {
    return "tableHeader";
  }

  get spec(): NodeSpec {
    return {
      group: "headerCell",
      content:
        this.supportsInputs === true
          ? "(paragraph | gridFunction)+"
          : "paragraph+",
      tableRole: "header_cell",
      isolating: true,
      attrs: cellAttrs,
      parseDOM: [
        {
          tag: "th",
          context: "table/tableHeaderRow/",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getAttrs(node as HTMLElement);
          }
        },
        {
          tag: "div",
          context: "table/tableHeaderRow/",
          getAttrs(node) {
            if (typeof node === "string") {
              return false;
            }

            return getAttrs(node as HTMLElement);
          }
        }
      ],
      toDOM(node) {
        return ["th", setAttrs(node, false), 0];
      }
    };
  }

  get builders(): DocumentBuilders {
    return { th: { nodeType: "tableHeader" } };
  }
}

export class TableInputHeaderNode implements NodeConfig {
  get name(): string {
    return "tableInputHeader";
  }

  get spec(): NodeSpec {
    return {
      group: "headerCell",
      content: "gridHeaderInput{0,1}",
      tableRole: "header_cell",
      isolating: true,
      attrs: cellAttrs,
      parseDOM: [
        {
          tag: "th.ProseMirror-input-cell",
          context: "table/tableHeaderRow/",
          priority: BASE_PRIORITY + 1,
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getAttrs(node as HTMLElement);
          }
        },
        {
          tag: "div.ProseMirror-input-cell",
          context: "table/tableHeaderRow/",
          priority: BASE_PRIORITY + 1,
          getAttrs(node) {
            if (typeof node === "string") {
              return false;
            }

            return getAttrs(node as HTMLElement);
          }
        }
      ],
      toDOM(node) {
        return ["th", setAttrs(node, true), 0];
      }
    };
  }

  get builders(): DocumentBuilders {
    return { tableInputHeader: { nodeType: "tableInputHeader" } };
  }
}

export class TableFooterNode implements NodeConfig {
  constructor(private supportsInputs: boolean) {}
  get name(): string {
    return "tableFooter";
  }

  get spec(): NodeSpec {
    return {
      group: "footerCell",
      content:
        this.supportsInputs === true
          ? "(paragraph | gridFunction)+"
          : "paragraph+",
      tableRole: "header_cell",
      isolating: true,
      attrs: cellAttrs,
      parseDOM: [
        {
          tag: "th",
          context: "table/tableFooterRow/",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getAttrs(node as HTMLElement);
          }
        },
        {
          tag: "div",
          context: "table/tableFooterRow/",
          getAttrs(node) {
            if (typeof node === "string") {
              return false;
            }

            return getAttrs(node as HTMLElement);
          }
        }
      ],
      toDOM(node) {
        return ["th", setAttrs(node, false), 0];
      }
    };
  }

  get builders(): DocumentBuilders {
    return { tf: { nodeType: "tableFooter" } };
  }
}

export class TableInputFooterNode implements NodeConfig {
  get name(): string {
    return "tableInputFooter";
  }

  get spec(): NodeSpec {
    return {
      group: "footerCell",
      content: "gridHeaderInput{0,1}",
      tableRole: "header_cell",
      isolating: true,
      attrs: cellAttrs,
      parseDOM: [
        {
          tag: "th.ProseMirror-input-cell",
          context: "table/tableFooterRow/",
          priority: BASE_PRIORITY + 1,
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getAttrs(node as HTMLElement);
          }
        },
        {
          tag: "div.ProseMirror-input-cell",
          context: "table/tableFooterRow/",
          priority: BASE_PRIORITY + 1,
          getAttrs(node) {
            if (typeof node === "string") {
              return false;
            }

            return getAttrs(node as HTMLElement);
          }
        }
      ],
      toDOM(node) {
        return ["th", setAttrs(node, true), 0];
      }
    };
  }

  get builders(): DocumentBuilders {
    return { tableInputFooter: { nodeType: "tableInputFooter" } };
  }
}

function getAttrs(element: HTMLElement): { [key: string]: any } {
  const attrs: {
    id?: string;
    colwidth?: number[];
    verticalAlignment?: VerticalAlignmentType;
  } = {};
  const colspan = 1;

  const id = element.getAttribute("id");
  if (id != null) {
    attrs.id = id;
  }

  const widthAttr = element.getAttribute("data-colwidth");
  const widths =
    widthAttr == null ? null : widthAttr.split(",").map((s) => Number(s));
  if (widths != null && widths.length === colspan) {
    attrs.colwidth = widths;
  }

  const verticalAlignment = getVerticalAlignment(element.style.verticalAlign);
  if (verticalAlignment != null) {
    attrs.verticalAlignment = verticalAlignment;
  }

  const legacyWidth = element.style.width.includes("%")
    ? parseFloat(element.style.width)
    : undefined;
  if (legacyWidth != null) {
    attrs.colwidth = [legacyWidth];
  }

  return attrs;
}

function setAttrs(node: Node, isInputCell: boolean): { [key: string]: any } {
  const id = node.attrs.id as string;
  const colwidth = node.attrs.colwidth as number[];
  const verticalAlignment = node.attrs
    .verticalAlignment as VerticalAlignmentType | null;

  let styles: string[] = [];
  if (verticalAlignment != null) {
    styles = styles.concat(`vertical-align: ${verticalAlignment}`);
  }

  const baseAttrs = !isInputCell
    ? {
        id: id,
        "data-colwidth": colwidth.join(",")
      }
    : {
        id: id,
        "data-colwidth": colwidth.join(","),
        class: "ProseMirror-input-cell"
      };
  const attrs =
    styles.length > 0
      ? {
          ...baseAttrs,
          style: styles.join("; ")
        }
      : baseAttrs;

  return attrs;
}
