import { Plugin } from "prosemirror-state";
import { TableMap } from "prosemirror-tables";
import { CustomGridSchema } from "../schema";
import { findGrids } from "../util";

interface OverlongRowspanTableProblem {
  type: "overlong_rowspan";
  pos: number;
  n: number;
}

interface CollisionTableProblem {
  type: "collision";
  row: number;
  pos: number;
  n: number;
}

interface MissingTableProblem {
  type: "missing";
  row: number;
  n: number;
}

interface ColWidthMismatchTableProblem {
  type: "colwidth mismatch";
  pos: number;
  colwidth: number[];
}

type TableProblem =
  | OverlongRowspanTableProblem
  | CollisionTableProblem
  | MissingTableProblem
  | ColWidthMismatchTableProblem;

export function structurePlugin(schema: CustomGridSchema) {
  return new Plugin<null, CustomGridSchema>({
    filterTransaction(tr, _state): boolean {
      if (!tr.docChanged) {
        return true;
      }

      if (tr.getMeta("history$") != null) {
        return true;
      }

      const grids = findGrids(tr.doc, schema);
      for (let i = 0; i < grids.length; ++i) {
        const grid = grids[i];
        const map = TableMap.get(grid.node);
        const problems = map.problems as TableProblem[] | undefined;
        if (problems != null) {
          const missing = problems.filter(
            (problem) => problem.type === "missing"
          );
          if (missing.length > 0) {
            return false;
          }
        }
      }

      return true;
    }
  });
}
