export type InputTextFormat = {
  id: string;
  label: string;
  regex: RegExp | null;
};

export type Meaning = {
  value: string;
  label: string;
};

export const textFormats: InputTextFormat[] = [
  { id: "none", label: "None", regex: null },
  {
    id: "email",
    label: "Email",
    // eslint-disable-next-line no-useless-escape
    regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  },
  {
    id: "postal_code_canada",
    label: "Postal code (Canada)",
    regex: /^[ABCEGHJKLMNPRSTVXYabceghjklmnprstvxy][0-9][ABCEGHJKLMNPRSTVWXYZabceghjklmnprstvwxyz][ -]?[0-9][ABCEGHJKLMNPRSTVWXYZabceghjklmnprstvwxyz][0-9]$/
  },
  {
    id: "postal_code_usa",
    label: "Zip code (USA)",
    regex: /^(?!0{3})[0-9]{3,5}$/
  },
  {
    id: "phone_number_north_america",
    label: "Phone number (North America)",
    regex: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/
  },
  { id: "custom", label: "Custom", regex: null }
];

export const Meanings: Meaning[] = [
  {
    value: "honorific-prefix",
    label: `Prefix or title (e.g., "Mr.", "Ms.", "Dr.", "Mlle")`
  },
  { value: "name", label: `Full name` },
  { value: "given-name", label: `Given name (also known as the first name)` },
  {
    value: "family-name",
    label: `Family name (also known as the last name or surname)`
  },
  {
    value: "additional-name",
    label: `Additional names also known as middle names`
  },
  {
    value: "honorific-suffix",
    label: `Suffix (e.g., "Jr.", "B.Sc.", "MBASW", "II")`
  },
  { value: "username", label: `Username` },
  {
    value: "organization",
    label: `Company name corresponding to the person, address, or contact information in the other fields associated with this field`
  },
  {
    value: "organization-title",
    label: `Job title (e.g., "Software Engineer", "Senior Vice President")`
  },
  {
    value: "street-address",
    label: `Street address (multiple lines, newlines preserved)`
  },
  {
    value: "address-line1",
    label: `Street address line 1 (one line per field)`
  },
  {
    value: "address-line2",
    label: `Street address line 2 (one line per field)`
  },
  {
    value: "address-line3",
    label: `Street address line 3 (one line per field)`
  },
  { value: "postal-code", label: `Postal code, post code, ZIP code` },
  { value: "country-name", label: `Country name` },
  { value: "tel", label: `Full telephone number, including country code` },
  {
    value: "tel-country-code",
    label: `Country code component of the telephone number`
  },
  {
    value: "tel-national",
    label: `Telephone number without the country code component`
  },
  {
    value: "tel-area-code",
    label: `Area code component of the telephone number`
  },
  {
    value: "tel-local",
    label: `Telephone number without the country code and area code components`
  },
  { value: "tel-extension", label: `Telephone number internal extension code` },
  { value: "email", label: `E-mail address` },
  { value: "language", label: `Preferred language` },
  { value: "sex", label: `Gender identity` },
  { value: "bday", label: `Birthday` },
  { value: "bday-day", label: `Day component of birthday` },
  { value: "bday-month", label: `Month component of birthday` },
  { value: "bday-year", label: `Year component of birthday` }
];
