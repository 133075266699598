import {
  AttributeSpec,
  Mark,
  MarkType,
  Node,
  NodeSpec,
  Schema
} from "prosemirror-model";
import { Plugin } from "prosemirror-state";
import {
  CommandConfiguration,
  CommandConfigurations,
  CommandFn,
  DocumentBuilders,
  Extension,
  NodeConfig
} from "../../editor";
import {
  activeBlock,
  blockIsActive,
  executeSequence,
  setBlockType
} from "../../util";
import { AlignmentType, getAlignment } from "../alignment";
import { getIndentation, getIndentationPX } from "../indentation";
import { Font } from "../text-font";
import { headingsNodeViewPlugin } from "./headings-node-view";

export interface Heading {
  level: number;
  properties: {
    textSize?: number;
    textFont?: Font;
  };
}

class HeadingsNode implements NodeConfig {
  constructor(private headings: Map<number, Heading>) {}

  get name(): string {
    return "headings";
  }

  get spec(): NodeSpec {
    const headings = this.headings;
    const levels = Array.from(headings.values());

    return {
      content: "(inline|hardBreak)*",
      group: "block rootBlock",
      defining: true,
      selectable: false,
      supportsQuestionTitle: true,
      modifyMarks: (node: Node, type: MarkType) => {
        const schema = node.type.schema;
        const style = headings.get(node.attrs.level);
        if (!style) {
          return [];
        }

        const marks = new Array<Mark<Schema>>();
        if (type === schema.marks.textFont) {
          const textFont = style.properties.textFont;
          if (textFont != null) {
            const mark = schema.marks.textFont.create({
              family: textFont.name
            });
            marks.push(mark);
          }
        }

        if (type === schema.marks.textSize) {
          const textSize = style.properties.textSize;
          if (textSize != null) {
            const mark = schema.marks.textSize.create({
              size: textSize
            });
            marks.push(mark);
          }
        }

        return marks;
      },
      attrs: {
        id: { default: null },
        level: { default: 1, keepOnSplit: true } as AttributeSpec & {
          keepOnSplit?: boolean;
        },
        alignment: { default: null, keepOnSplit: true } as AttributeSpec & {
          keepOnSplit?: boolean;
        },
        indentation: { default: null, keepOnSplit: true } as AttributeSpec & {
          keepOnSplit?: boolean;
        }
      },
      parseDOM: levels.map((heading) => {
        return {
          tag: `h${heading.level}`,
          getAttrs: (value) => {
            if (typeof value === "string") {
              return false;
            }

            const element = value as HTMLElement;
            const attrs: {
              level: number;
              id?: string;
              alignment?: AlignmentType;
              indentation?: number;
            } = { level: heading.level };

            const id = element.getAttribute("id");
            if (id != null) {
              attrs.id = id;
            }

            const alignment = getAlignment(element.style.textAlign);
            if (alignment != null) {
              attrs.alignment = alignment;
            }

            const indentation = getIndentation(element.style.marginLeft);
            if (indentation != null) {
              attrs.indentation = indentation;
            }

            return attrs;
          }
        };
      }),
      toDOM(node) {
        let styles: string[] = [];
        if (node.attrs.alignment != null) {
          styles = styles.concat(`text-align: ${node.attrs.alignment}`);
        }
        if (node.attrs.indentation != null) {
          styles = styles.concat(
            `margin-left: ${getIndentationPX(node.attrs.indentation)}px`
          );
        }

        const attrs =
          styles.length > 0
            ? { id: node.attrs.id, style: styles.join("; ") }
            : { id: node.attrs.id };

        return [`h${node.attrs.level}`, attrs, 0];
      }
    };
  }

  get builders(): DocumentBuilders {
    const levels = Array.from(this.headings.values());
    return levels.reduce((acc, elem) => {
      acc[`h${elem.level}`] = { nodeType: "headings", level: elem.level };
      return acc;
    }, {} as { [key: string]: any });
  }
}

type HeadingsSchema = Schema<"headings" | "paragraph", "textFont" | "textSize">;

interface HeadingsCommandProps {
  level: number;
}

export class Headings implements Extension<HeadingsSchema> {
  private headings: Map<number, Heading>;

  constructor(headings: Heading[]) {
    this.headings = headings.reduce((acc, elem) => {
      return acc.set(elem.level, elem);
    }, new Map<number, Heading>());
  }

  get name(): string {
    return "headings";
  }

  get nodes(): NodeConfig[] {
    return [new HeadingsNode(this.headings)];
  }

  plugins(): Plugin[] {
    return [headingsNodeViewPlugin(this.headings)];
  }

  commands(schema: HeadingsSchema): CommandConfigurations<HeadingsSchema> {
    return {
      headings: this.headingsCommand(schema)
    };
  }

  private headingsCommand(
    schema: HeadingsSchema
  ): CommandConfiguration<HeadingsSchema, HeadingsCommandProps, number | null> {
    return {
      isActive: (props) => {
        const level = props?.level;
        if (level == null) {
          return false;
        }

        return blockIsActive(
          schema.nodes.headings,
          (node) => node.attrs.level === level
        );
      },
      isEnabled: (props) => {
        const level = props?.level;
        if (level != null) {
          return setHeadings(schema, level);
        } else {
          return setParagraph(schema);
        }
      },
      execute: (props) => {
        const level = props?.level;
        if (level != null) {
          return executeSequence(
            removeTextStyles(),
            setHeadings(schema, level)
          );
        } else {
          return executeSequence(removeTextStyles(), setParagraph(schema));
        }
      },
      activeValue: () => {
        return (state) => {
          const active = activeBlock(schema.nodes.headings)(state);
          if (active != null) {
            return active.attrs.level;
          } else {
            return null;
          }
        };
      }
    };
  }
}

function removeTextStyles<S extends Schema>(): CommandFn<S> {
  return (state, dispatch) => {
    if (dispatch) {
      const { selection, schema } = state;
      const { $from, $to } = selection;

      const start = $from.start();
      const end = $to.end();

      let tr = state.tr;

      const textStyles = Object.entries(schema.marks)
        .filter(([, type]) => {
          const group = type.spec.group;
          const isTextStyle =
            group == null ? false : group.split(" ").includes("text-style");
          const isTextBackground = type === schema.marks.textBackgroundColor;

          return isTextStyle && !isTextBackground;
        })
        .map(([_, type]) => {
          return type as MarkType<S>;
        });

      textStyles.forEach((type) => {
        tr = tr.removeMark(start, end, type);
      });

      dispatch(tr);
    }

    return true;
  };
}

function setHeadings(schema: Schema, level: number): CommandFn<Schema> {
  return setBlockType(
    schema.nodes.headings,
    { level: level },
    (node, attrs) => {
      return {
        ...attrs,
        id: node.attrs.id,
        alignment: node.attrs.alignment,
        indentation: node.attrs.indentation
      };
    }
  );
}

function setParagraph(schema: Schema): CommandFn<Schema> {
  return setBlockType(schema.nodes.paragraph, undefined, (node, attrs) => {
    return {
      ...attrs,
      id: node.attrs.id,
      alignment: node.attrs.alignment,
      indentation: node.attrs.indentation
    };
  });
}
