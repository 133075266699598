import { EditorState, NodeSelection, Plugin } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";
import { editableKey } from "../../../editor/plugins/editable";
import {
  Focus,
  selectionFocusKey
} from "../../../editor/plugins/selection-focus";
import { findParentNodeOfType } from "../../../util/selection";
import { InputChoiceControlType } from "../schema";
import { InputChoiceNodeView } from "./input-choice-node-view";
import {
  InputChoiceOtherSpecifyValueNodeView,
  InputChoiceValueNodeView
} from "./input-choice-value-node-view";
import { inputChoiceNodeViewKey, PluginState } from "./types";

function setValue(
  state: EditorState,
  value: (focused: Focus) => PluginState
): PluginState | null {
  const focused = selectionFocusKey.getState(state);

  if (!focused) {
    return null;
  } else {
    const { schema } = state;

    if (focused.node.type === schema.nodes.inputChoice) {
      const { node } = focused;
      const controlType = node.attrs.controlType as InputChoiceControlType;
      if (controlType === InputChoiceControlType.dropdown) {
        return value(focused);
      } else {
        return null;
      }
    } else {
      const parent = findParentNodeOfType(schema.nodes.inputChoice)(
        state.selection
      );

      if (parent) {
        const expectedFocused = {
          node: parent.node,
          pos: parent.pos
        };

        return value(expectedFocused);
      } else {
        return null;
      }
    }
  }
}

export function inputChoiceNodeViewPlugin() {
  return new Plugin({
    key: inputChoiceNodeViewKey,
    state: {
      init(_config, state) {
        return setValue(state, (focused) => {
          return { opened: true, focused: focused };
        });
      },
      apply(_tr, value, _oldState, newState) {
        return setValue(newState, (focused) => {
          if (value != null && focused.node === value.focused.node) {
            const updatedValue =
              focused.pos === value.focused.pos
                ? { ...value }
                : { ...value, focused: focused };

            return updatedValue;
          } else {
            return { opened: true, focused: focused };
          }
        });
      }
    },
    props: {
      decorations: (state) => {
        const { doc } = state;

        const dropdownState = inputChoiceNodeViewKey.getState(state);
        if (dropdownState == null) {
          return DecorationSet.empty;
        }

        const { focused, opened } = dropdownState;

        if (!opened) {
          return DecorationSet.empty;
        }

        const decorations = [
          Decoration.node(focused.pos, focused.pos + focused.node.nodeSize, {
            "data-dropdown-opened": ""
          })
        ];

        return DecorationSet.create(doc, decorations);
      },
      nodeViews: {
        inputChoice: (node, view, getPos, decorations) => {
          return new InputChoiceNodeView(
            node,
            view,
            getPos as () => number,
            decorations
          );
        },
        inputChoiceValue: (node, _view, _getPos) => {
          return new InputChoiceValueNodeView(node);
        },
        inputChoiceAllOfTheAboveValue: (node, _view, _getPos) => {
          return new InputChoiceValueNodeView(node);
        },
        inputChoiceNoneOfTheAboveValue: (node, _view, _getPos) => {
          return new InputChoiceValueNodeView(node);
        },
        inputChoiceOtherSpecifyValue: (node, _view, _getPos) => {
          return new InputChoiceOtherSpecifyValueNodeView(node);
        }
      },
      handleClickOn(view, _pos, node, nodePos, event, direct) {
        const { state } = view;

        const editable = editableKey.getState(state);
        if (editable && editable.focusable === false) {
          return false;
        }

        const { schema } = state;
        const target = event.target as Element;
        const isInContent = target.closest(".content") != null;
        const isSelectable =
          node.type.spec.selectable && node.type === schema.nodes.inputChoice;

        if (
          (isSelectable && direct) ||
          (isSelectable && !direct && !isInContent)
        ) {
          const tr = state.tr.setSelection(
            new NodeSelection(state.doc.resolve(nodePos))
          );
          view.dispatch(tr);
          return true;
        } else {
          return false;
        }
      }
    }
  });
}
