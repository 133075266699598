import { Schema } from "prosemirror-model";
import { Transaction } from "prosemirror-state";
import { CommandFn } from "../../editor";
import { getIndentationNodes, hasIndentation } from "./util";

export function setIndentation<S extends Schema>(
  getIndentation: (previous?: number) => number | undefined | false
): CommandFn<S> {
  return (state, dispatch) => {
    const { nodes, count } = getIndentationNodes()(state);

    let tr = state.tr;
    let cannotIndent = 0;

    nodes.forEach(({ node, pos }) => {
      const indentation = getIndentation(node.attrs.indentation);
      if (indentation === false) {
        cannotIndent = cannotIndent + 1;
        return;
      }

      tr = tr.setNodeMarkup(
        pos,
        node.type,
        {
          ...node.attrs,
          indentation: indentation
        },
        node.marks
      );
    });

    if (cannotIndent === count) {
      return false;
    }

    if (dispatch) {
      dispatch(tr);
    }

    return true;
  };
}

export function removeIndentation<S extends Schema>(): CommandFn<S> {
  return (state, dispatch) => {
    if (dispatch) {
      const { from, to } = state.selection;
      let tr = removeIndentationTr(state.tr, from, to);

      dispatch(tr);
    }
    return true;
  };
}

export function removeIndentationTr<S extends Schema>(
  tr: Transaction<S>,
  from: number,
  to: number
) {
  tr.doc.nodesBetween(from, to, (node, pos) => {
    if (hasIndentation(node)) {
      tr = tr.setNodeMarkup(
        pos,
        node.type,
        {
          ...node.attrs,
          indentation: undefined
        },
        node.marks
      );
    }
  });

  return tr;
}
