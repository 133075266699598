import { NodeSpec } from "prosemirror-model";
import { DocumentBuilders, NodeConfig } from "../../../editor";
import { VariableSource } from "../schema";

export class QuestionVariableNode implements NodeConfig {
  get name(): string {
    return "questionVariable";
  }

  get spec(): NodeSpec {
    return {
      group: "rootBlock",
      draggable: true,
      selectable: true,
      focusable: true,
      allowGapCursor: true,
      attrs: {
        id: { default: null },
        source: {},
        code: {}
      },
      parseDOM: [
        {
          tag: "question-variable",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return;
            }
            const element = node as HTMLElement;

            const id = element.getAttribute("id");
            const source = element.getAttribute(
              "data-source"
            ) as VariableSource;
            const code = element.getAttribute("data-code");

            return {
              id: id,
              source: source,
              code: code
            };
          }
        },
        {
          tag: "[data-widget='variable'][data-type='question']",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return;
            }
            const element = node as HTMLElement;

            const id = element.getAttribute("id");
            const source = element.getAttribute(
              "data-source"
            ) as VariableSource;
            const code = element.getAttribute("data-code");

            return {
              id: id,
              source: source,
              code: code
            };
          }
        }
      ],
      toDOM(node) {
        let attrs: Record<string, string> = {
          id: node.attrs.id,
          "data-source": node.attrs.source,
          "data-code": node.attrs.code
        };

        return ["question-variable", attrs];
      }
    };
  }

  get builders(): DocumentBuilders {
    return {
      questionVariable: {
        nodeType: "questionVariable",
        source: "survey",
        code: "test-code"
      }
    };
  }
}
