import { Node, ResolvedPos, Schema } from "prosemirror-model";
import {
  EditorState,
  NodeSelection,
  Selection,
  TextSelection
} from "prosemirror-state";
import {
  atTheBeginningOfDoc,
  atTheEndOfDoc,
  canReplaceWithNode,
  removeNodeBefore,
  findPositionOfNodeBefore
} from "../../../util";
import { CommandFn } from "../../extension/command";
import { Direction, isBackward, isForward } from "./direction";
import { GapCursor } from "./gap-cursor";
import { Side } from "./side";
import { isTextBlockNearPos, isValidTargetNode } from "./util";

export function setGapCursorAtPos<S extends Schema>(
  position: number,
  side: Side = Side.LEFT
): CommandFn<S> {
  return (state, dispatch) => {
    if (position > state.doc.content.size) {
      return false;
    }

    const $pos = state.doc.resolve(position);

    if (GapCursor.valid<S>($pos, side)) {
      if (dispatch) {
        dispatch(state.tr.setSelection(new GapCursor<S>($pos, side)));
      }
      return true;
    }

    return false;
  };
}

type DirectionString =
  | "up"
  | "down"
  | "left"
  | "right"
  | "forward"
  | "backward";

export function arrow<S extends Schema>(
  dir: Direction,
  endOfTextblock?: (dir: DirectionString, state?: EditorState<S>) => boolean
): CommandFn<S> {
  return (state, dispatch) => {
    const { doc, schema, selection, tr } = state;

    let $pos = isBackward(dir) ? selection.$from : selection.$to;
    let mustMove = selection.empty;
    let gapDirection = isBackward(dir) ? -1 : 1;

    // start from text selection
    if (selection instanceof TextSelection) {
      // if cursor is in the middle of a text node, do nothing
      if (
        !endOfTextblock ||
        !endOfTextblock(dir.toString() as DirectionString)
      ) {
        return false;
      }

      // UP/DOWN jumps to the nearest texblock skipping gapcursor whenever possible
      if (
        (dir === Direction.UP &&
          !atTheBeginningOfDoc(state) &&
          isTextBlockNearPos(doc, schema, $pos, -1)) ||
        (dir === Direction.DOWN &&
          !atTheEndOfDoc(state) &&
          isTextBlockNearPos(doc, schema, $pos, 1))
      ) {
        return false;
      }
      // otherwise resolve previous/next position
      $pos = doc.resolve(isBackward(dir) ? $pos.before() : $pos.after());
      mustMove = false;

      const nodeType = $pos.node().type;
      const mustNavigateUp = [
        schema.nodes.listItem,
        schema.nodes.bulletList,
        schema.nodes.orderedList
      ].includes(nodeType);

      if (dir === Direction.RIGHT) {
        let $checkPos = $pos;
        const isNodeAfter = ($pos: ResolvedPos<S>) => {
          return $pos.nodeAfter !== null && isValidTargetNode($pos.nodeAfter);
        };

        if (mustNavigateUp) {
          while ($checkPos.depth > 0 && !isNodeAfter($checkPos)) {
            $checkPos = doc.resolve($checkPos.after());
          }
        }

        if (isNodeAfter($checkPos)) {
          gapDirection = -1;
          $pos = $checkPos;
        }
      }

      if (dir === Direction.LEFT) {
        let $checkPos = $pos;
        const isNodeBefore = ($pos: ResolvedPos<S>) => {
          return $pos.nodeBefore !== null && isValidTargetNode($pos.nodeBefore);
        };

        if (mustNavigateUp) {
          while ($checkPos.depth > 0 && !isNodeBefore($checkPos)) {
            $checkPos = doc.resolve($checkPos.before());
          }
        }

        if (isNodeBefore($checkPos)) {
          gapDirection = 1;
          $pos = $checkPos;
        }
      }
    }

    if (selection instanceof NodeSelection) {
      if (dir === Direction.UP || dir === Direction.DOWN) {
        // We dont add gap cursor on node selections going up and down
        return false;
      }
    }

    // when jumping between block nodes at the same depth, we need to reverse cursor without changing ProseMirror position
    if (
      selection instanceof GapCursor &&
      // next node allow gap cursor position
      isValidTargetNode(isBackward(dir) ? $pos.nodeBefore : $pos.nodeAfter) &&
      // gap cursor changes block node
      ((isBackward(dir) && selection.side === Side.LEFT) ||
        (isForward(dir) && selection.side === Side.RIGHT))
    ) {
      // reverse cursor position
      if (dispatch) {
        dispatch(
          tr
            .setSelection(
              new GapCursor<S>(
                $pos,
                selection.side === Side.RIGHT ? Side.LEFT : Side.RIGHT
              )
            )
            .scrollIntoView()
        );
      }
      return true;
    }

    const nextSelection = GapCursor.findFrom<S>($pos, gapDirection, mustMove);

    if (!nextSelection) {
      return false;
    }

    if (
      !isValidTargetNode(
        isForward(dir)
          ? nextSelection.$from.nodeBefore
          : nextSelection.$from.nodeAfter
      )
    ) {
      // reverse cursor position
      if (dispatch) {
        dispatch(
          tr
            .setSelection(
              new GapCursor<S>(
                nextSelection.$from,
                isForward(dir) ? Side.LEFT : Side.RIGHT
              )
            )
            .scrollIntoView()
        );
      }
      return true;
    }

    if (dispatch) {
      dispatch(tr.setSelection(nextSelection).scrollIntoView());
    }
    return true;
  };
}

function canDeleteNode<S extends Schema>(node: Node<S>): boolean {
  return node.isTextblock && node.content.size === 0;
}

export function deleteNode<S extends Schema>(dir: Direction): CommandFn<S> {
  return (state, dispatch) => {
    if (state.selection instanceof GapCursor) {
      const { selection, schema } = state;
      const { $from, side } = selection;

      if (isBackward(dir)) {
        if (side === Side.LEFT) {
          if ($from.nodeBefore && !atTheBeginningOfDoc(state)) {
            if (dispatch) {
              let tr = state.tr;
              if (canDeleteNode($from.nodeBefore)) {
                const position = findPositionOfNodeBefore(tr.selection);
                if (position != null) {
                  tr = tr.delete(
                    position,
                    position + $from.nodeBefore.nodeSize
                  );
                  tr = tr.scrollIntoView();
                }
              } else {
                tr = tr.setSelection(
                  Selection.near(tr.doc.resolve(tr.mapping.map($from.pos - 1)))
                );
                tr = tr.scrollIntoView();
              }
              dispatch(tr);
            }
          }

          return true;
        } else if (side === Side.RIGHT) {
          if ($from.nodeBefore) {
            if (dispatch) {
              let tr = state.tr;
              tr = removeNodeBefore(state.tr, schema);
              dispatch(tr);
            }
          }
          return true;
        } else {
          throw new Error(`Unsupported side ${side}`);
        }
      } else if (isForward(dir)) {
        if (side === Side.LEFT) {
          if ($from.nodeAfter) {
            if (dispatch) {
              let tr = state.tr;
              if (
                $from.nodeAfter.isBlock &&
                canReplaceWithNode($from, schema.nodes.paragraph)
              ) {
                tr = tr.replaceWith(
                  $from.pos,
                  $from.pos + $from.nodeAfter.nodeSize,
                  schema.nodes.paragraph.create() as Node<S>
                );
              } else {
                tr = tr.delete($from.pos, $from.pos + $from.nodeAfter.nodeSize);
              }
              tr = tr.setSelection(
                Selection.near(
                  tr.doc.resolve(tr.mapping.map(state.selection.$from.pos))
                )
              );
              tr = tr.scrollIntoView();
              dispatch(tr);
            }
          }
          return true;
        } else if (side === Side.RIGHT) {
          if ($from.nodeAfter && !atTheEndOfDoc(state)) {
            if (dispatch) {
              let tr = state.tr;
              if (canDeleteNode($from.nodeAfter)) {
                tr = tr.delete($from.pos, $from.pos + $from.nodeAfter.nodeSize);
                tr = tr.scrollIntoView();
              } else {
                tr = tr.setSelection(
                  Selection.near(
                    tr.doc.resolve(tr.mapping.map(state.selection.$from.pos))
                  )
                );
                tr = tr.scrollIntoView();
              }
              dispatch(tr);
            }
          }
          return true;
        } else {
          throw new Error(`Unsupported side ${side}`);
        }
      } else {
        throw new Error(`Unsupported direction ${dir}`);
      }
    }
    /*else if (state.selection instanceof TextSelection) {
      // if cursor is in the middle of a text node, do nothing
      if (
        !endOfTextblock ||
        !endOfTextblock(dir.toString() as DirectionString) ||
        !state.selection.empty
      ) {
        return false;
      }

      return arrow(dir, endOfTextblock)(state, dispatch, view);
    }*/

    return false;
  };
}
