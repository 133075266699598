import { Node, Schema } from "prosemirror-model";
import {
  Plugin,
  PluginKey,
  Selection,
  TextSelection,
  Transaction
} from "prosemirror-state";
import { Mapping } from "prosemirror-transform";
import { Decoration, DecorationSet, EditorView } from "prosemirror-view";
import { emitNotification } from "../../../editor/plugins/notification";
import {
  canInsertAtPos,
  IdGenerator,
  NodeWithPos,
  UnreachableCaseError
} from "../../../util";
import { isSelectionRemoveButton } from "../selection-overlay";
import { RangeSelection } from "../types";
import { InlineRange } from "./inline-range";
import { InlineSelectorValidationErrors } from "./types";

export interface StartSelectionAction {
  type: "start";
}

export interface ReplaceSelectionAction {
  type: "replace";
  selection: InlineRange[];
  emit: boolean;
}

export interface UpdateSequenceAction {
  type: "sequence";
  action: "start" | "end";
  selection?: { start: NodeWithPos<Schema>; end: NodeWithPos<Schema> };
  emit: boolean;
}

export interface RemoveRangeAction {
  type: "remove";
  id: string;
  emit: boolean;
}

export interface EndSelectionAction {
  type: "end";
}

export interface RemapSelectionAction {
  type: "remap";
}

const CLASS_NAMES = {
  Selector: "ProseMirror-inline-selector",
  SelectorLabel: "ProseMirror-inline-selector-label",
  SelectorOutline: "ProseMirror-inline-selector-outline",
  SelectorButton: "ProseMirror-inline-selector-button"
};

interface InlineSelectorState {
  selection: InlineRange[];
  selected: DecorationSet;
  isCreatingSequence: boolean;
  idGenerator: IdGenerator;
}

export const inlineSelectorKey = new PluginKey<
  InlineSelectorState | null,
  Schema
>("inlineSelector");

export function inlineSelector(
  onChange: (ranges: RangeSelection[]) => void,
  idGenerator: IdGenerator
) {
  return new Plugin({
    key: inlineSelectorKey,
    state: {
      init() {
        return null;
      },
      apply(tr, value, _oldState, _newState) {
        const meta = tr.getMeta(inlineSelectorKey) as
          | StartSelectionAction
          | UpdateSequenceAction
          | RemoveRangeAction
          | ReplaceSelectionAction
          | EndSelectionAction
          | RemapSelectionAction
          | undefined;

        if (meta == null) {
          return value;
        }

        switch (meta.type) {
          case "start":
            return {
              selection: [],
              selected: DecorationSet.empty,
              isCreatingSequence: false,
              idGenerator: idGenerator
            };

          case "sequence":
            if (value != null) {
              switch (meta.action) {
                case "start": {
                  return {
                    ...value,
                    isCreatingSequence: true
                  };
                }

                case "end": {
                  const addedRange = meta.selection;
                  if (addedRange != null) {
                    const { selection } = value;

                    const updatedSelection = selection
                      .map((r) => adjustRange(r, tr.mapping))
                      .concat(
                        new InlineRange({
                          id: idGenerator.generateId(),
                          start: addedRange.start,
                          end: addedRange.end
                        })
                      );

                    const updatedSelected = decorationsForSelectionRanges(
                      tr.doc,
                      updatedSelection
                    );

                    if (meta.emit) {
                      onChange(updatedSelection.map((r) => r.toJSON()));
                    }

                    return {
                      ...value,
                      selection: updatedSelection,
                      selected: updatedSelected,
                      isCreatingSequence: false
                    };
                  } else {
                    return { ...value, isCreatingSequence: false };
                  }
                }

                default:
                  throw new UnreachableCaseError(meta.action);
              }
            } else {
              return null;
            }

          case "remove":
            if (value != null) {
              const { selection } = value;

              const updatedSelection = selection
                .map((r) => adjustRange(r, tr.mapping))
                .filter((r) => r.id !== meta.id);

              const updatedSelected = decorationsForSelectionRanges(
                tr.doc,
                updatedSelection
              );

              if (meta.emit) {
                onChange(updatedSelection.map((r) => r.toJSON()));
              }

              return {
                ...value,
                selection: updatedSelection,
                selected: updatedSelected
              };
            } else {
              return null;
            }

          case "replace":
            if (value != null) {
              const updatedSelection = meta.selection;

              const updatedSelected = decorationsForSelectionRanges(
                tr.doc,
                updatedSelection
              );

              if (meta.emit) {
                onChange(updatedSelection.map((r) => r.toJSON()));
              }

              return {
                ...value,
                selection: updatedSelection,
                selected: updatedSelected
              };
            } else {
              return null;
            }

          case "end":
            return null;

          case "remap":
            if (value != null) {
              const { selection } = value;

              const updatedSelection = selection.map((r) =>
                adjustRange(r, tr.mapping)
              );

              const updatedSelected = decorationsForSelectionRanges(
                tr.doc,
                updatedSelection
              );

              return {
                ...value,
                selection: updatedSelection,
                selected: updatedSelected
              };
            } else {
              return null;
            }

          default:
            return value;
        }
      }
    },
    props: {
      decorations(state) {
        const value = this.getState(state);
        if (value == null) {
          return null;
        }

        const { selected } = value;

        return selected;
      },
      attributes(state) {
        const value = this.getState(state);
        if (value == null) {
          return null;
        }

        let attributes: Record<string, string> = { "data-selector": "inline" };

        return attributes;
      },
      handleDOMEvents: {
        mousedown(view, event) {
          const state = this.getState(view.state);
          if (state == null) {
            return false;
          }

          if (isSelectionRemoveButton(event.target as HTMLElement)) {
            return false;
          }

          return handleMouseDown(view, event as MouseEvent, state, idGenerator);
        }
      }
    }
  });
}

function inRange(number: number, start: number, end: number): boolean {
  return number >= start && number <= end;
}

function overlaysRange(
  aStart: number,
  aEnd: number,
  bStart: number,
  bEnd: number
): boolean {
  const isStartInRange = inRange(aStart, bStart, bEnd);
  const isEndInRange = inRange(aEnd, bStart, bEnd);
  return isStartInRange || isEndInRange;
}

function includesRange(
  aStart: number,
  aEnd: number,
  bStart: number,
  bEnd: number
): boolean {
  return aStart <= bStart && aEnd >= bEnd;
}

function validateSelection(
  ranges: InlineRange[],
  start: NodeWithPos<Schema>,
  end: NodeWithPos<Schema>
): { valid: boolean; message?: InlineSelectorValidationErrors } {
  if (start.pos === end.pos) {
    return { valid: false };
  }

  const startPos = start.pos;
  const endPos = end.pos;
  const hasOverlay = ranges.some((r) => {
    if (r.start != null && r.end != null) {
      const rangeStartPos = r.start.pos;
      const rangeEndPos = r.end.pos;

      return (
        overlaysRange(startPos, endPos, rangeStartPos, rangeEndPos) ||
        includesRange(startPos, endPos, rangeStartPos, rangeEndPos)
      );
    } else {
      return false;
    }
  });

  if (hasOverlay) {
    return {
      valid: false,
      message: InlineSelectorValidationErrors.SelectionsCannotOverlap
    };
  }

  if (start.node !== end.node) {
    return {
      valid: false,
      message: InlineSelectorValidationErrors.RangeMustBeInSameNode
    };
  }

  return { valid: true };
}

const LEFT_BUTTON = 0;

function handleMouseDown(
  view: EditorView<Schema>,
  event: MouseEvent,
  value: InlineSelectorState,
  idGenerator: IdGenerator
): boolean {
  if (value == null) {
    return false;
  }

  if (event.button !== LEFT_BUTTON) {
    return false;
  }

  const start: UpdateSequenceAction = {
    type: "sequence",
    action: "start",
    emit: false
  };
  view.dispatch(view.state.tr.setMeta(inlineSelectorKey, start));

  function finish(_event: MouseEvent) {
    window.removeEventListener("mouseup", finish);

    const selection = view.state.selection;
    let update:
      | { start: NodeWithPos<Schema>; end: NodeWithPos<Schema> }
      | undefined;

    if (selection instanceof TextSelection) {
      const schema = view.state.schema;

      const { $from, $to } = selection as TextSelection;
      const start: NodeWithPos<Schema> = {
        node: $from.node(),
        pos: $from.pos
      };
      const end: NodeWithPos<Schema> = {
        node: $to.node(),
        pos: $to.pos
      };

      const { valid, message } = validateSelection(value.selection, start, end);
      if (valid) {
        const contentTest = schema.nodes.rangeMarker.create({ id: null });
        if (
          canInsertAtPos(contentTest, view.state.doc.resolve(start.pos)) &&
          canInsertAtPos(contentTest, view.state.doc.resolve(end.pos))
        ) {
          let insertTr = view.state.tr;
          const startMarker = schema.nodes.rangeMarker.create({
            id: idGenerator.generateId()
          });
          const startMarkerPos = start.pos;
          insertTr = insertTr.insert(startMarkerPos, startMarker);

          const endMarker = schema.nodes.rangeMarker.create({
            id: idGenerator.generateId()
          });
          const endMarkerPos = insertTr.mapping.map(end.pos);
          insertTr = insertTr.insert(endMarkerPos, endMarker);

          const remap: RemapSelectionAction = {
            type: "remap"
          };

          insertTr = insertTr.setMeta(inlineSelectorKey, remap);

          insertTr.setMeta("addToHistory", false);

          view.dispatch(insertTr);

          update = {
            start: { node: startMarker, pos: startMarkerPos },
            end: { node: endMarker, pos: endMarkerPos }
          };
        } else {
          emitNotification(view.state, {
            type: "warning",
            message: InlineSelectorValidationErrors.NodeDoesNotSupportPiping
          });
        }
      } else {
        if (message != null) {
          emitNotification(view.state, {
            type: "warning",
            message: message
          });
        }
      }
    }

    let tr = view.state.tr;

    const end: UpdateSequenceAction = {
      type: "sequence",
      action: "end",
      selection: update,
      emit: true
    };

    tr = tr.setSelection(Selection.near(tr.doc.resolve(0)));
    tr = tr.setMeta(inlineSelectorKey, end);

    view.dispatch(tr);
  }

  window.addEventListener("mouseup", finish);

  return false;
}

function decorationsForSelectionRanges(
  doc: Node<Schema>,
  selection: InlineRange[]
): DecorationSet {
  const decorations = selection.reduce((acc, r, index) => {
    const decoration = decorationForSelectionRange(r, `${index + 1}`);
    return acc.concat(decoration);
  }, new Array<Decoration>());

  return DecorationSet.create(doc, decorations);
}

function decorationForSelectionRange(
  range: InlineRange,
  label: string
): Decoration[] {
  const { start, end } = range;
  if (start == null || end == null) {
    return [];
  }

  return [
    Decoration.widget(start.pos, (view, getPos) => {
      const pos = getPos();
      const element = view.nodeDOM(pos)! as HTMLElement;

      const span = document.createElement("span");
      span.classList.add(CLASS_NAMES.Selector);
      span.classList.add(CLASS_NAMES.SelectorLabel);

      const top = element.offsetTop;
      const left = element.offsetLeft;
      const height = element.offsetHeight;
      const width = 20;

      span.style.width = `${width}px`;
      span.style.height = `${height}px`;
      span.style.top = `${top}px`;
      span.style.left = `${left - width}px`;

      span.innerText = label;

      return span;
    }),
    Decoration.widget(end.pos, (view, getPos) => {
      const pos = getPos();
      const element = view.nodeDOM(pos)! as HTMLElement;

      const span = document.createElement("span");
      span.classList.add(CLASS_NAMES.Selector);
      span.classList.add(CLASS_NAMES.SelectorButton);

      const top = element.offsetTop;
      const height = element.offsetHeight;
      const width = 20;

      span.style.width = `${width}px`;
      span.style.height = `${height}px`;
      span.style.top = `${top}px`;

      const onClick = () => {
        const action: RemoveRangeAction = {
          type: "remove",
          id: range.id,
          emit: true
        };

        let tr = view.state.tr;
        tr = removeRange(tr, range);
        tr = tr.setMeta(inlineSelectorKey, action);

        view.dispatch(tr);
      };

      const removeButton = document.createElement("button");
      removeButton.classList.add("bx", "bx-action-close");
      removeButton.onmousedown = (event) => {
        event.preventDefault();
      };
      removeButton.onclick = () => onClick();

      span.appendChild(removeButton);

      return span;
    }),
    Decoration.inline(start.pos, end.pos, {
      class: `${CLASS_NAMES.Selector} ${CLASS_NAMES.SelectorOutline}`,
      "data-range-id": range.id,
      "data-range-label": label
    })
  ];
}

function removeRange<S extends Schema>(
  tr: Transaction<S>,
  range: InlineRange
): Transaction<S> {
  let removeTr = tr;

  if (range.end != null) {
    removeTr = removeTr.delete(
      range.end.pos,
      range.end.pos + range.end.node.nodeSize
    );
  }

  if (range.start != null) {
    removeTr = removeTr.delete(
      range.start.pos,
      range.start.pos + range.start.node.nodeSize
    );
  }

  removeTr.setMeta("addToHistory", false);

  return removeTr;
}

function adjustRange(r: InlineRange, mapping: Mapping): InlineRange {
  const start =
    r.start != null
      ? { node: r.start.node, pos: mapping.map(r.start.pos) }
      : null;
  const end =
    r.end != null ? { node: r.end.node, pos: mapping.map(r.end.pos) } : null;

  return new InlineRange({ id: r.id, start: start, end: end });
}
