import { Node, NodeSpec } from "prosemirror-model";
import { DocumentBuilders, NodeConfig } from "../../../editor/extension/schema";

function setContentVariantAttrs(node: Node): { [key: string]: string | null } {
  return {
    id: node.attrs.id,
    "data-active": node.attrs.active ? "" : null,
    "data-default": node.attrs.default ? "" : null
  };
}

function getContentVariantAttrs(element: Element): { [key: string]: any } {
  const attrs: {
    id?: string;
    active?: boolean;
    default?: boolean;
  } = {};

  const id = element.getAttribute("id");
  if (id != null) {
    attrs.id = id;
  }

  const isActive = element.hasAttribute("data-active");
  attrs.active = isActive;

  const isDefault = element.hasAttribute("data-default");
  attrs.default = isDefault;

  return attrs;
}

export class CustomAreaContentNode implements NodeConfig {
  get name(): string {
    return "contentVariant";
  }

  get spec(): NodeSpec {
    return {
      content: "block+",
      draggable: false,
      focusable: false,
      isolating: true,
      selectable: false,
      constrainSelection: true,
      attrs: {
        id: { default: null },
        active: { default: false },
        default: { default: false }
      },
      parseDOM: [
        {
          tag: "content-variant",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getContentVariantAttrs(node as Element);
          }
        },
        {
          tag: "[data-content-variant]",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getContentVariantAttrs(node as Element);
          }
        }
      ],
      toDOM(node) {
        return ["content-variant", setContentVariantAttrs(node), 0];
      }
    };
  }

  get builders(): DocumentBuilders {
    return {
      variantActive: {
        nodeType: "contentVariant",
        active: true
      },
      variantUnactive: {
        nodeType: "contentVariant",
        active: false
      }
    };
  }
}
