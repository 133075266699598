import { MarkSpec, Schema } from "prosemirror-model";
import {
  BASE_PRIORITY,
  CommandConfiguration,
  CommandConfigurations,
  DocumentBuilders,
  Extension,
  MarkConfig
} from "../../editor";
import { textMarkIsActive, textMarkIsEnabled, toggleMark } from "../../util";

class SuperscriptMark implements MarkConfig {
  get name(): string {
    return "superscript";
  }

  get spec(): MarkSpec {
    return {
      group: "text-style",
      excludes: "subscript",
      parseDOM: [
        {
          tag: "sup"
        },
        {
          // Special case for pasting from Google Docs
          // Google Docs uses vertical align to denote subscript and super script
          tag: "span",
          getAttrs: (node) => {
            const element = node as HTMLElement;
            return element.style.verticalAlign === "super" && null;
          }
        }
      ],
      toDOM() {
        return ["sup", 0];
      }
    };
  }

  get priority(): number {
    return BASE_PRIORITY - 1;
  }

  get builders(): DocumentBuilders {
    return { superscript: { markType: "superscript" } };
  }
}

type SuperscriptSchema = Schema<any, "superscript">;

export class Superscript implements Extension<SuperscriptSchema> {
  get name(): string {
    return "superscript";
  }

  get marks(): MarkConfig[] {
    return [new SuperscriptMark()];
  }

  commands(
    schema: SuperscriptSchema
  ): CommandConfigurations<SuperscriptSchema> {
    return {
      superscript: this.superscriptCommand(schema)
    };
  }

  private superscriptCommand(
    schema: SuperscriptSchema
  ): CommandConfiguration<SuperscriptSchema, {}, undefined> {
    return {
      isActive: () => textMarkIsActive(schema.marks.superscript),
      isEnabled: () => textMarkIsEnabled(schema.marks.superscript),
      execute: () => toggleMark(schema.marks.superscript)
    };
  }
}
