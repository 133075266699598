import { Schema } from "prosemirror-model";
export type InputRankSchema = Schema<
  | "inputRank"
  | "inputRankSections"
  | "inputRankOptions"
  | "inputRankSection"
  | "inputRankSectionEmpty"
  | "inputRankOption"
  | "inputRankOptionSelectAll"
  | "inputRankOptionOtherSpecify",
  any
>;

export enum InputRankControlType {
  dragDrop = "drag-and-drop",
  dropdown = "dropdown-list"
}

export enum InputRankSectionPosition {
  before = "before",
  after = "after",
  delete = "delete"
}

export enum InputRankWidth {
  default = 50,
  minimum = 25,
  maximum = 100
}

export interface InputRankSectionOption {
  label: string;
  position: InputRankSectionPosition;
}
