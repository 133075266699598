import { NodeType, Schema } from "prosemirror-model";
import { UnreachableCaseError } from "../../util";

export interface RangeSelection {
  id: string;
  startElementId: string;
  endElementId: string;
}

export type InputType =
  | "text"
  | "number"
  | "choice"
  | "datetime"
  | "file"
  | "likert_item"
  | "add_one_to_n"
  | "ranking";

export function mapInputTypeToNodeType(
  types: InputType[],
  schema: Schema
): NodeType[] {
  return types
    .map((type) => {
      switch (type) {
        case "add_one_to_n":
          return schema.nodes.constantSum;

        case "choice":
          return schema.nodes.inputChoice;

        case "datetime":
          return schema.nodes.inputDateTime;

        case "file":
          return schema.nodes.inputFile;

        case "likert_item":
          return schema.nodes.inputScale;

        case "number":
          return schema.nodes.inputNumber;

        case "ranking":
          return schema.nodes.inputRank;

        case "text":
          return schema.nodes.inputText;

        default:
          throw new UnreachableCaseError(type);
      }
    })
    .filter((t) => t != null);
}
