import { Fragment, NodeSpec } from "prosemirror-model";
import { DocumentBuilders, NodeConfig } from "../../../editor";
import { VariableSchema, VariableSource } from "../schema";

export class LinkVariableNode implements NodeConfig {
  get name(): string {
    return "linkVariable";
  }

  get spec(): NodeSpec {
    return {
      group: "inline variable",
      marks: "text-style",
      inline: true,
      selectable: true,
      atom: true,
      draggable: true,
      focusable: true,
      disableImageRedirect: true,
      content: "(text | image)*",
      attrs: {
        id: { default: null },
        source: {},
        code: {},
        defaultValue: {}
      },
      parseDOM: [
        {
          tag: "link-variable",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return;
            }
            const element = node as HTMLElement;

            const id = element.getAttribute("id");
            const source = element.getAttribute(
              "data-source"
            ) as VariableSource;
            const code = element.getAttribute("data-code");
            const defaultValue = element.getAttribute("data-default-value");

            return {
              id: id,
              source: source,
              code: code,
              defaultValue: defaultValue
            };
          }
        },
        {
          tag: "[data-widget='variable'][data-type='link']",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return;
            }
            const element = node as HTMLElement;

            const id = element.getAttribute("id");
            const source = element.getAttribute(
              "data-source"
            ) as VariableSource;
            const code = element.getAttribute("data-code");
            const displayedValueElement = element.querySelector<HTMLElement>(
              "[data-displayed-value]"
            );
            const defaultValue =
              displayedValueElement?.getAttribute(
                "data-default-displayed-value"
              ) ?? "";

            return {
              id: id,
              source: source,
              code: code,
              defaultValue: defaultValue
            };
          },
          getContent: (node, schema) => {
            return legacyContent(node as HTMLElement, schema);
          }
        }
      ],
      toDOM(node) {
        let attrs: Record<string, string> = {
          id: node.attrs.id,
          "data-source": node.attrs.source,
          "data-code": node.attrs.code,
          "data-default-value": node.attrs.defaultValue
        };

        return ["link-variable", attrs, 0];
      }
    };
  }

  get builders(): DocumentBuilders {
    return {
      linkVariable: {
        nodeType: "linkVariable",
        source: "survey",
        code: "test-code",
        defaultValue: "https://test-link.com"
      }
    };
  }
}

function legacyContent(element: Element, schema: VariableSchema): Fragment {
  const displayedValueElement = element.querySelector<HTMLElement>(
    "[data-displayed-value]"
  );
  const displayedValue = displayedValueElement?.textContent?.trim() ?? "";
  const defaultDisplayedValue =
    displayedValueElement?.getAttribute("data-default-displayed-value") ?? "";

  const text = schema.text(
    displayedValue.length > 0 ? displayedValue : defaultDisplayedValue
  );

  return Fragment.from(text);
}
