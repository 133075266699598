import { Node, Schema, Slice } from "prosemirror-model";
import {
  AllSelection,
  NodeSelection,
  Plugin,
  PluginKey
} from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { isList } from "../../../extensions/list/util";
import { canInsertSlice } from "../../../util/selection";
import { selectionToInsertionEnd } from "../../../util/transforms";
import { GapCursor } from "../gap-cursor";
import { emitNotification } from "../notification";

export enum EditorPasteNotification {
  PasteNotAllowed = "paste.not-allowed"
}

const pastePluginKey = new PluginKey("pastePlugin");

export function pastePlugin<S extends Schema>() {
  return new Plugin<void, S>({
    key: pastePluginKey,
    props: {
      handlePaste(view, _event, slice) {
        const { state } = view;
        const { selection } = state;

        if (selection instanceof NodeSelection) {
          emitNotification(view.state, {
            type: "warning",
            message: EditorPasteNotification.PasteNotAllowed
          });
          return true;
        }

        if (selection instanceof AllSelection) {
          return false;
        }

        if (selection instanceof GapCursor) {
          return false;
        }

        const { $from } = selection;
        const isValid = canInsertSlice($from, $from, slice);

        if (isValid) {
          if (handlePasteList(slice, view)) {
            return true;
          }

          if (handlePasteChoice(slice, view)) {
            return true;
          }

          if (handlePasteScale(slice, view)) {
            return true;
          }

          if (handlePasteParagragh(slice, view)) {
            return true;
          }
        }

        const showNotification = isValid === false;

        if (showNotification) {
          emitNotification(view.state, {
            type: "warning",
            message: EditorPasteNotification.PasteNotAllowed
          });
          return true;
        }

        return false;
      }
    }
  });
}

function transformParagraphSlice<S extends Schema>(
  slice: Slice<S>,
  filter: (node: Node<S>) => boolean
): Slice<S> | null | undefined {
  if (slice.openStart === slice.openEnd && slice.content.childCount > 0) {
    const firstChild = slice.content.firstChild;
    const lastChild = slice.content.lastChild;
    if (firstChild == null || lastChild == null) {
      return null;
    }

    const openStart =
      filter(firstChild) === true && firstChild.childCount > 0
        ? slice.openStart + 1
        : slice.openStart;

    const openEnd =
      filter(lastChild) === true && lastChild.childCount > 0
        ? slice.openEnd + 1
        : slice.openEnd;

    return new Slice(slice.content, openStart, openEnd);
  } else {
    return null;
  }
}

function handlePasteParagragh<S extends Schema>(
  slice: Slice<S>,
  view: EditorView<S>
): boolean {
  const { state } = view;
  const { selection, schema } = state;
  const { $from, $to } = selection;

  const filter = (node: Node<S>) => {
    return node.type.spec.defining === true;
  };

  const updatedSlice = transformParagraphSlice(slice, filter);
  const fromNode = $from.node();
  if (
    updatedSlice != null &&
    fromNode != null &&
    filter(fromNode) === true &&
    (updatedSlice.openStart !== slice.openStart ||
      updatedSlice.openEnd !== slice.openEnd)
  ) {
    let tr = view.state.tr;

    const { firstChild } = getFirstAndLastNodeAtDepth(slice);
    if (firstChild == null) {
      return true;
    }

    const newID =
      updatedSlice.content.childCount > 1 ? firstChild.attrs.id : null;
    if (newID != null) {
      tr = tr.setNodeMarkup(
        $from.before(),
        fromNode.type,
        {
          ...fromNode.attrs,
          id: newID
        },
        fromNode.marks
      );
    }

    if (
      firstChild.type === schema.nodes.headings &&
      $from.start() === $from.pos &&
      $to.end() === $to.pos
    ) {
      let attrs: Record<string, any> = {
        ...fromNode.attrs,
        level: firstChild.attrs.level
      };
      if (newID != null) {
        attrs = { ...attrs, id: newID };
      }
      tr = tr.setBlockType($from.pos, $from.pos, firstChild.type, attrs);
    }

    tr = tr.replaceSelection(updatedSlice);
    tr = tr.scrollIntoView();

    view.dispatch(tr);

    return true;
  }

  return false;
}

function handlePasteList<S extends Schema>(
  slice: Slice<S>,
  view: EditorView<S>
): boolean {
  const { state } = view;
  const { selection, schema } = state;
  const { $from, $to } = selection;

  if (
    (slice.content.firstChild && isList(slice.content.firstChild, schema)) ||
    (slice.content.lastChild && isList(slice.content.lastChild, schema))
  ) {
    const fromNode = $from.node($from.depth - 1);
    const toNode = $to.node($to.depth - 1);

    let openStart = slice.openStart;
    let openEnd = slice.openEnd;

    if (
      slice.content.firstChild &&
      isList(slice.content.firstChild, schema) &&
      fromNode.type !== schema.nodes.listItem
    ) {
      openStart = openStart - 2;
    }

    if (
      slice.content.lastChild &&
      isList(slice.content.lastChild, schema) &&
      toNode.type !== schema.nodes.listItem
    ) {
      openEnd = openEnd - 2;
    }

    if (openStart !== slice.openStart || openEnd !== slice.openEnd) {
      let tr = view.state.tr;
      tr = tr.replaceSelection(
        new Slice(
          slice.content,
          openStart > 0 ? openStart : slice.openStart,
          openEnd > 0 ? openEnd : slice.openEnd
        )
      );
      tr = tr.scrollIntoView();

      view.dispatch(tr);

      return true;
    }
  }

  return false;
}

function handlePasteChoice<S extends Schema>(
  slice: Slice<S>,
  view: EditorView<S>
): boolean {
  const { state } = view;
  const { selection, schema } = state;
  const { $from, $to } = selection;

  const isChoice = (node: Node<S>, schema: S) => {
    return (
      node.type === schema.nodes.inputChoice || isChoiceValue(node, schema)
    );
  };

  const isChoiceValue = (node: Node<S>, schema: S) => {
    return [
      schema.nodes.inputChoiceValue,
      schema.nodes.inputChoiceAllOfTheAboveValue,
      schema.nodes.inputChoiceNoneOfTheAboveValue,
      schema.nodes.inputChoiceOtherSpecifyValue
    ].includes(node.type);
  };

  const { firstChild, lastChild } = getFirstAndLastNodeAtDepth(slice);

  if (
    (firstChild && isChoice(firstChild, schema)) ||
    (lastChild && isChoice(lastChild, schema))
  ) {
    const fromNode = $from.node($from.depth);
    const toNode = $to.node($to.depth);

    let openStart = slice.openStart;
    let openEnd = slice.openEnd;

    if (
      firstChild &&
      isChoice(firstChild, schema) &&
      !isChoiceValue(fromNode, schema)
    ) {
      openStart = openStart - 1;
    }

    if (
      lastChild &&
      isChoice(lastChild, schema) &&
      !isChoiceValue(toNode, schema)
    ) {
      openEnd = openEnd - 1;
    }

    if (openStart !== slice.openStart || openEnd !== slice.openEnd) {
      const content = new Slice(
        slice.content,
        openStart > 0 ? openStart : slice.openStart,
        openEnd > 0 ? openEnd : slice.openEnd
      );

      let tr = view.state.tr;
      tr = tr.replaceSelection(content);
      tr = selectionToInsertionEnd(tr, 0, -1);
      tr = tr.scrollIntoView();

      view.dispatch(tr);

      return true;
    }
  }

  return false;
}

function handlePasteScale<S extends Schema>(
  slice: Slice<S>,
  view: EditorView<S>
): boolean {
  const { state } = view;
  const { selection, schema } = state;
  const { $from, $to } = selection;

  const isScale = (node: Node<S>, schema: S) => {
    return node.type === schema.nodes.inputScale || isScaleValue(node, schema);
  };

  const isScaleValue = (node: Node<S>, schema: S) => {
    return [
      schema.nodes.inputScaleValue,
      schema.nodes.inputScaleNotApplicable
    ].includes(node.type);
  };

  const { firstChild, lastChild } = getFirstAndLastNodeAtDepth(slice);

  if (
    (firstChild && isScale(firstChild, schema)) ||
    (lastChild && isScale(lastChild, schema))
  ) {
    const fromNode = $from.node($from.depth);
    const toNode = $to.node($to.depth);

    let openStart = slice.openStart;
    let openEnd = slice.openEnd;

    if (
      firstChild &&
      isScale(firstChild, schema) &&
      !isScaleValue(fromNode, schema)
    ) {
      openStart = openStart - 1;
    }

    if (
      lastChild &&
      isScale(lastChild, schema) &&
      !isScaleValue(toNode, schema)
    ) {
      openEnd = openEnd - 1;
    }

    if (openStart !== slice.openStart || openEnd !== slice.openEnd) {
      const content = new Slice(
        slice.content,
        openStart > 0 ? openStart : slice.openStart,
        openEnd > 0 ? openEnd : slice.openEnd
      );

      let tr = view.state.tr;
      tr = tr.replaceSelection(content);
      tr = selectionToInsertionEnd(tr, 0, -1);
      tr = tr.scrollIntoView();

      view.dispatch(tr);

      return true;
    }
  }

  return false;
}

export function getFirstAndLastNodeAtDepth<S extends Schema>(
  slice: Slice<S>
): {
  firstChild: Node<S> | null | undefined;
  lastChild: Node<S> | null | undefined;
} {
  let start = slice.openStart;
  let startNode = slice.content.firstChild;

  for (let i = 1; i < start; ++i) {
    startNode = startNode?.firstChild;
  }

  let end = slice.openEnd;
  let endNode = slice.content.lastChild;

  for (let i = 1; i < end; ++i) {
    endNode = endNode?.lastChild;
  }

  return { firstChild: startNode, lastChild: endNode };
}
