import {
  Alignment,
  BarChart,
  Bold,
  BulletList,
  ColorScheme,
  ConstantSum,
  CustomArea,
  CustomGrid,
  Doc,
  DocumentSkin,
  documentSkins,
  Editor as BlueXEditor,
  EditorNotification,
  en,
  fr,
  HardBreak,
  Headings,
  History,
  Image,
  Import,
  Indentation,
  InputChoice,
  InputDateTime,
  InputFile,
  InputNumber,
  InputRank,
  InputScale,
  InputText,
  Italic,
  Link,
  Localization,
  Logic,
  NodeIdentifier,
  OrderedList,
  PageBreak,
  palettes,
  Paragraph,
  Placeholder,
  QuestionTitle,
  QuestionVariableRenderingDTO,
  Schema,
  Strikethrough,
  Subscript,
  Superscript,
  NodeDTO,
  Text,
  TextBackgroundColor,
  TextColor,
  TextFont,
  TextSize,
  Underline,
  Variable,
  VerticalAlignment,
  Video
} from "@blue-x/editor";
import applyDevTools from "prosemirror-dev-tools";
import { EditorView } from "prosemirror-view";
import React, { useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import Content from "./Content";
import "./Editor.css";
import { VariablePicker } from "./extensions";
import PropertiesPanel from "./PropertiesPanel";
import Toolbar from "./Toolbar";

async function uploadHandler(file: File): Promise<string> {
  const formData = new FormData();
  formData.set("file", file);

  const response = await fetch(
    "https://api.gateway.dev.bluexsurveydev.com/qdef/v1/Questionnaires/Shared/Files",
    { method: "POST", body: formData }
  );

  if (!response.ok) {
    throw response;
  }

  const json = await response.json();
  return json.link;
}

function notificationHandler(notification: EditorNotification) {
  if (notification.params != null) {
    alert(
      `${notification.type}-${notification.message}-${JSON.stringify(
        notification.params
      )}`
    );
  } else {
    alert(`${notification.type}-${notification.message}`);
  }
}

const Editor: React.FC = () => {
  const [editor, setEditor] = useState<{ editor: BlueXEditor<Schema> }>();

  const initialEditor = useMemo(() => {
    const questionForCode: (
      code: string
    ) => QuestionVariableRenderingDTO | null = (code: string) => {
      if (code === "question-variable-without-question-title") {
        const input: NodeDTO = {
          type: "inputScale",
          attrs: {
            id: "055161B9012C42E3BA73DFECCED577CE",
            questionTitleText: "Scale question 1",
            questionTitleRefElementId: [],
            description: "",
            required: false,
            coding: "",
            controlType: "labelRow",
            orientation: "top",
            rows: 5,
            width: 100,
            scale: "level_of_agreement",
            alignment: null
          },
          content: [
            {
              type: "inputScaleValue",
              attrs: {
                id: "12FEDEACDB2D4216A3A6A732072E41C3",
                coding: null,
                alignment: null
              },
              content: [
                {
                  type: "textVariable",
                  attrs: {
                    id: "34A0DD351B6D442C91E6477AD4FF3947",
                    displayedValue: "LastName",
                    source: "demographics",
                    code: "last_name",
                    defaultValue: null
                  }
                },
                { type: "text", text: "Strongly disagree" }
              ]
            },
            {
              type: "inputScaleValue",
              attrs: {
                id: "587B21C11AA2467EA148FF973D59A5D2",
                coding: null,
                alignment: null
              },
              content: [{ type: "text", text: "Disagree" }]
            },
            {
              type: "inputScaleValue",
              attrs: {
                id: "2E4310C628434500856C855E94BE45EC",
                coding: null,
                alignment: null
              },
              content: [{ type: "text", text: "Somewhat disagree" }]
            },
            {
              type: "inputScaleValue",
              attrs: {
                id: "E4F033EE64464E88A983972E49048C2F",
                coding: null,
                alignment: null
              },
              content: [{ type: "text", text: "Neither agree nor disagree" }]
            },
            {
              type: "inputScaleValue",
              attrs: {
                id: "6F39C858405842D6B70C35BD4CFE7BB1",
                coding: null,
                alignment: null
              },
              content: [{ type: "text", text: "Somewhat agree" }]
            },
            {
              type: "inputScaleValue",
              attrs: {
                id: "42A97825C4F047A1A2EA9A753876C6FC",
                coding: null,
                alignment: null
              },
              content: [{ type: "text", text: "Agree" }]
            },
            {
              type: "inputScaleValue",
              attrs: {
                id: "91C905FCA3B442D89DA90F1ECA0E2D85",
                coding: null,
                alignment: null
              },
              content: [{ type: "text", text: "Strongly agree" }]
            }
          ]
        };
        return {
          input: input,
          questionTitle: undefined
        };
      } else if (code === "question-variable-with-question-title") {
        const input: NodeDTO = {
          type: "inputChoice",
          attrs: {
            id: "360D8C6444C94874BB56BEEB67D0BDFF",
            questionTitleText: "Choice question 1",
            questionTitleRefElementId: [],
            description: "",
            coding: "",
            watermark: "",
            controlType: "single-vertical",
            randomize: false,
            respondentsMustSpecify: false,
            labelPosition: "right",
            width: 100,
            required: false,
            rows: 1,
            choiceSelectionRangeMin: 0,
            choiceSelectionRangeMax: 1,
            alignment: null
          },
          content: [
            {
              type: "inputChoiceValue",
              attrs: {
                id: "9BCAC29D53514A55A8420AA607318962",
                alignment: null,
                coding: null,
                default: false
              },
              content: [
                {
                  type: "textVariable",
                  attrs: {
                    id: "34A0DD351B6D442C91E6477AD4FF3947",
                    displayedValue: "LastName",
                    source: "demographics",
                    code: "last_name",
                    defaultValue: null
                  }
                },
                { type: "text", text: "1" }
              ]
            },
            {
              type: "inputChoiceValue",
              attrs: {
                id: "E76D5B95213543798DAF7BE653756F9D",
                alignment: null,
                coding: null,
                default: false
              },
              content: [{ type: "text", text: "2" }]
            },
            {
              type: "inputChoiceValue",
              attrs: {
                id: "01DD829B4B5246B2BD0FEEE5C030EBFF",
                alignment: null,
                coding: null,
                default: false
              },
              content: [{ type: "text", text: "3" }]
            },
            {
              type: "inputChoiceValue",
              attrs: {
                id: "F6FCDECBF40F4F689FD74CF77A55BFB4",
                alignment: null,
                coding: null,
                default: false
              },
              content: [{ type: "text", text: "4" }]
            },
            {
              type: "inputChoiceValue",
              attrs: {
                id: "5ED809B64F794034BF7D1F4EFDAAE2C1",
                alignment: null,
                coding: null,
                default: false
              },
              content: [{ type: "text", text: "5" }]
            }
          ]
        };

        const questionTitle: NodeDTO = {
          type: "headings",
          attrs: {
            id: "34297FB59E5F4F4891DD53630EDA3F5F",
            level: 3,
            alignment: null,
            indentation: null
          },
          content: [
            {
              type: "textVariable",
              attrs: {
                id: "34A0DD351B6D442C91E6477AD4FF3947",
                displayedValue: "LastName",
                source: "demographics",
                code: "last_name",
                defaultValue: null
              }
            },
            {
              type: "text",
              marks: [
                { type: "textColor", attrs: { color: "#00FF00" } },
                { type: "bold" },
                { type: "underline" },
                { type: "italic" },
                { type: "textSize", attrs: { size: 24 } },
                { type: "textFont", attrs: { family: "Verdana" } },
                {
                  type: "textBackgroundColor",
                  attrs: { color: "#FF0000" }
                }
              ],
              text: "This is a test test test"
            }
          ]
        };

        return {
          input: input,
          questionTitle: questionTitle
        };
      } else {
        return null;
      }
    };

    const extensions = [
      new Doc(),
      new Paragraph(),
      new Text(),
      new Placeholder((language) => {
        switch (language) {
          case "fr":
            return "Veuillez saisir du texte <strong>ici</strong>!";
          case "en":
          default:
            return "Please enter some text <strong>here</strong>!";
        }
      }),
      new NodeIdentifier(),
      new Localization([
        {
          code: "en",
          translations: en,
          default: true
        },
        {
          code: "fr",
          translations: fr
        }
      ]),
      new ColorScheme(palettes, "black"),
      new Bold(),
      new Subscript(),
      new Superscript(),
      new Underline(),
      new Strikethrough(),
      new Italic(),
      new TextSize(11),
      new TextColor(),
      new TextBackgroundColor(),
      new TextFont([
        { name: "Headings", css: `Headings, serif` },
        { name: "Body", css: `Body, sans-serif`, default: true },
        { name: "Arial", css: `Arial, Helvetica, sans-serif` },
        { name: "Arial Black", css: `"Arial Black", Gadget, sans-serif` },
        { name: "Georgia", css: `Georgia, serif` },
        { name: "Impact", css: `Impact, Charcoal, sans-serif` },
        { name: "Tahoma", css: `Tahoma, Geneva, sans-serif` },
        { name: "Times New Roman", css: `"Times New Roman", Times, serif` },
        { name: "Verdana", css: `Verdana, Geneva, sans-serif` },
        { name: "Courier New", css: `"Courier New", Courier, monospace` }
      ]),
      new Headings([
        {
          level: 1,
          properties: {
            textFont: { name: "Headings", css: `Headings, serif` },
            textSize: 24
          }
        },
        {
          level: 2,
          properties: {
            textFont: { name: "Headings", css: `Headings, serif` },
            textSize: 20
          }
        },
        {
          level: 3,
          properties: {
            textFont: { name: "Headings", css: `Headings, serif` },
            textSize: 18
          }
        },
        {
          level: 4,
          properties: {
            textFont: { name: "Headings", css: `Headings, serif` },
            textSize: 16
          }
        },
        {
          level: 5,
          properties: {
            textFont: { name: "Headings", css: `Headings, serif` },
            textSize: 14
          }
        },
        {
          level: 6,
          properties: {
            textFont: { name: "Headings", css: `Headings, serif` },
            textSize: 12
          }
        }
      ]),
      new BulletList(),
      new OrderedList(),
      new History(),
      new InputChoice(),
      new InputRank(),
      new HardBreak(),
      new Alignment("left"),
      new VerticalAlignment(),
      new Indentation(),
      new Link(),
      new Image(uploadHandler),
      new Video(),
      new CustomGrid(true),
      new PageBreak(),
      new Variable(
        ["text", "link", "question"],
        [
          {
            name: "FirstName",
            definition: {
              type: "text",
              source: "demographics",
              code: "first_name"
            },
            disabled: false
          },
          {
            name: "LastName",
            definition: {
              type: "text",
              source: "demographics",
              code: "last_name"
            },
            disabled: false
          },
          {
            name: "Email",
            definition: {
              type: "text",
              source: "demographics",
              code: "email"
            },
            disabled: false
          },
          {
            name: "Input w/o QT",
            definition: {
              type: "question",
              source: "survey",
              code: "question-variable-without-question-title"
            },
            disabled: false
          },
          {
            name: "Input w/ QT",
            definition: {
              type: "question",
              source: "survey",
              code: "question-variable-with-question-title"
            },
            disabled: false
          },
          {
            name: "SurveyLink",
            definition: {
              type: "link",
              defaultDisplayedValue:
                "https://www.fillout.bluexsurvey.com/#/p/{participationId}",
              source: "survey",
              code: "survey_link"
            },
            disabled: false
          },
          {
            name: "OptoutLink",
            definition: {
              type: "link",
              defaultDisplayedValue:
                "https://www.fillout.bluexsurvey.com/#/p/{participationId}/consent",
              source: "survey",
              code: "optout_link"
            },
            disabled: false
          }
        ],
        questionForCode,
        (variables, onSelect) => {
          const root = document.createElement("div");
          ReactDOM.render(
            <VariablePicker
              variables={variables}
              onSelect={onSelect}
            ></VariablePicker>,
            root
          );

          return root;
        }
      ),
      new CustomArea(),
      new InputText(),
      new InputNumber(),
      new InputScale([
        {
          id: "level_of_agreement",
          label: "Level of Agreement",
          options: [
            { label: "INPUT_LIKERT.LEVEL_OF_AGREEMENT.0" },
            { label: "INPUT_LIKERT.LEVEL_OF_AGREEMENT.1" },
            { label: "INPUT_LIKERT.LEVEL_OF_AGREEMENT.2" },
            { label: "INPUT_LIKERT.LEVEL_OF_AGREEMENT.3" },
            { label: "INPUT_LIKERT.LEVEL_OF_AGREEMENT.4" },
            { label: "INPUT_LIKERT.LEVEL_OF_AGREEMENT.5" },
            { label: "INPUT_LIKERT.LEVEL_OF_AGREEMENT.6" }
          ],
          default: true
        },
        {
          id: "good_bad",
          label: "Good / Bad",
          options: [
            { label: "INPUT_LIKERT.GOOD_BAD.0" },
            { label: "INPUT_LIKERT.GOOD_BAD.1" },
            { label: "INPUT_LIKERT.GOOD_BAD.2" }
          ]
        },
        {
          id: "level_of_probability",
          label: "Level of Probability",
          options: [
            { label: "INPUT_LIKERT.LEVEL_OF_PROBABILITY.0" },
            { label: "INPUT_LIKERT.LEVEL_OF_PROBABILITY.1" },
            { label: "INPUT_LIKERT.LEVEL_OF_PROBABILITY.2" },
            { label: "INPUT_LIKERT.LEVEL_OF_PROBABILITY.3" },
            { label: "INPUT_LIKERT.LEVEL_OF_PROBABILITY.4" }
          ]
        }
      ]),
      new InputFile(),
      new InputDateTime(),
      new QuestionTitle(true),
      new DocumentSkin(documentSkins, "default"),
      new ConstantSum(),
      new Import(),
      new Logic(
        (input) => console.log("input", input),
        (ranges) => console.log("block", ranges),
        (ranges) => console.log("inline", ranges),
        (items) => console.log("fork", items)
      ),
      new BarChart()
    ];

    const editor = new BlueXEditor<Schema>(
      extensions,
      (updated) => setEditor({ editor: updated }),
      notificationHandler
    );

    return { editor: editor };
  }, [setEditor]);

  useEffect(() => {
    applyDevTools(initialEditor.editor as EditorView);
  }, [initialEditor]);

  const current = useMemo(() => {
    return editor ? editor : initialEditor;
  }, [editor, initialEditor]);

  return (
    <>
      <Toolbar editor={current.editor} />
      <div className="sections">
        <div className="left-side">
          <Content editor={current.editor} />
        </div>
        <div className="right-side">
          <PropertiesPanel editor={current.editor}></PropertiesPanel>
        </div>
      </div>
    </>
  );
};

export default Editor;
