import { Node, Schema } from "prosemirror-model";
import { Plugin } from "prosemirror-state";
import { Decoration, EditorView, NodeView } from "prosemirror-view";
import { Editor } from "../../editor";
import {
  ActionControlButton,
  ActionControls
} from "../../editor/plugins/action-controls";
import { selectionFocusKey } from "../../editor/plugins/selection-focus";
import { ValidationMessagesNodeView } from "../../editor/plugins/validation-messages";
import { WidthResizerNodeView } from "../../editor/plugins/width-resizer";
import { replaceAll, ZeroWidthSpace } from "../../util";
import { AlignmentType } from "../alignment";
import { GetTranslationFn, LanguageObserver } from "../localization";
import { QuestionTitleBindingButton } from "../question-title";
import { InputNumberControlType, InputNumberLabelPosition } from "./schema";

export function nodeViewPlugin() {
  return new Plugin({
    props: {
      nodeViews: {
        inputNumber: (node, view, getPos, decorations) => {
          return new InputNumberNodeView(
            node,
            view,
            getPos as () => number,
            decorations
          );
        }
      }
    }
  });
}

class InputNumberNodeView<S extends Schema> implements NodeView<S> {
  dom: HTMLElement;

  private languageObserver: LanguageObserver<S>;

  private inputContainer: HTMLElement;
  private resizer: WidthResizerNodeView<S>;
  private validationMessages: ValidationMessagesNodeView;
  private actionControls: ActionControls;
  private requiredButton: ActionControlButton;
  private questionTitleBindingButton: QuestionTitleBindingButton<S>;

  constructor(
    private node: Node<S>,
    view: EditorView<S>,
    getPos: () => number,
    decorations: Decoration[]
  ) {
    this.languageObserver = new LanguageObserver(view, (getTranslation) => {
      this.updateControlType(this.node, getTranslation);
      this.updateRequired(
        this.node.attrs.required,
        this.node.attrs.defaultValue,
        getTranslation
      );
    });

    const container = document.createElement("input-number");

    this.resizer = new WidthResizerNodeView(
      container,
      node,
      view,
      getPos,
      25,
      100,
      (width) => {
        const editor = view as Editor<S>;
        editor.commands.updateInputNumber.execute({
          width: width
        });
      }
    );

    this.validationMessages = new ValidationMessagesNodeView();

    const inputContainer = document.createElement("div");

    const requiredButton = new ActionControlButton(
      view,
      { icon: "asterisk", title: "ACTION_BUTTONS.REQUIRED.TITLE" },
      false,
      () => {
        const editor = view as Editor<S>;
        const focused = selectionFocusKey.getState(editor.state);

        if (focused != null && focused.node.type === this.node.type) {
          const { node } = focused;
          const required = !node.attrs.required;
          editor.commands.updateInputNumber.execute({ required: required });
        }
      }
    );

    const questionTitleBindingButton = new QuestionTitleBindingButton(
      view,
      node,
      decorations
    );

    const actionControls = new ActionControls([
      requiredButton,
      questionTitleBindingButton
    ]);

    container.appendChild(this.validationMessages.dom);
    container.appendChild(inputContainer);
    container.appendChild(actionControls.dom);

    this.dom = container;
    this.inputContainer = inputContainer;
    this.actionControls = actionControls;
    this.requiredButton = requiredButton;
    this.questionTitleBindingButton = questionTitleBindingButton;

    const getTranslation = this.languageObserver.getTranslation;

    this.updateId(node);
    this.updateRequired(
      node.attrs.required,
      node.attrs.defaultValue,
      getTranslation
    );
    this.updateControlType(node, getTranslation);
    this.updateAlignment(node.attrs.alignment);
    this.updateQuestionTitle(node, decorations);
    this.updateDefaultValue(node);
  }

  ignoreMutation(
    mutation:
      | MutationRecord
      | {
          type: "selection";
          target: Element;
        }
  ): boolean {
    const resizeIgnoreMutation = this.resizer.ignoreMutation(mutation);
    const validationMessagesIgnoreMutation = this.validationMessages.ignoreMutation(
      mutation
    );
    const requiredButtonIgnoreMutation = this.requiredButton.ignoreMutation(
      mutation
    );
    const questionTitleBindingButtonIgnoreMutation = this.questionTitleBindingButton.ignoreMutation(
      mutation
    );

    return [
      resizeIgnoreMutation,
      validationMessagesIgnoreMutation,
      requiredButtonIgnoreMutation,
      questionTitleBindingButtonIgnoreMutation
    ].some((x) => x);
  }

  update(node: Node<S>, decorations: Decoration[]): boolean {
    if (node.type !== this.node.type) {
      return false;
    }

    this.node = node;

    const getTranslation = this.languageObserver.getTranslation;

    this.resizer.update(node);

    this.updateId(node);
    this.updateRequired(
      node.attrs.required,
      node.attrs.defaultValue,
      getTranslation
    );
    this.updateControlType(node, getTranslation);
    this.updateAlignment(node.attrs.alignment);
    this.updateQuestionTitle(node, decorations);
    this.updateDefaultValue(node);

    return true;
  }

  destroy() {
    this.languageObserver.destroy();
    this.actionControls.destroy();
  }

  private updateId(node: Node<S>): void {
    this.dom.id = node.attrs.id;
  }

  private updateRequired(
    required: boolean,
    defaultValue: string,
    getTranslation: GetTranslationFn
  ): void {
    const done = defaultValue != null && defaultValue !== "";
    this.validationMessages.setRequired(required, done, getTranslation);
    this.requiredButton.setActive(required);
  }

  private updateControlType(
    node: Node<S>,
    getTranslation: GetTranslationFn
  ): void {
    const newContainer = this.generateContainer(node, getTranslation);

    this.dom.replaceChild(newContainer, this.inputContainer);
    this.inputContainer = newContainer;
  }

  private updateAlignment(alignment: AlignmentType): void {
    if (alignment != null) {
      this.dom.setAttribute("data-alignment", alignment);
    } else {
      this.dom.removeAttribute("data-alignment");
    }
  }

  private generateContainer(
    node: Node<S>,
    getTranslation: GetTranslationFn
  ): HTMLElement {
    const controlType = node.attrs.controlType as InputNumberControlType;
    const defaultValue = node.attrs.defaultValue;
    const minValue = node.attrs.minValue;
    const maxValue = node.attrs.maxValue;
    const labelPosition = node.attrs.labelPosition;
    const suffix = node.attrs.suffix;
    const prefix = node.attrs.prefix;
    const numberOfDecimals = node.attrs.numberOfDecimals;

    const contentContainer =
      controlType === InputNumberControlType.spinbox
        ? this.numberboxTemplate(defaultValue, prefix, suffix, numberOfDecimals)
        : this.numberboxSliderTemplate(
            defaultValue,
            minValue,
            maxValue,
            labelPosition,
            prefix,
            suffix,
            numberOfDecimals,
            getTranslation
          );

    contentContainer.classList.add(node.attrs.controlType);

    return contentContainer;
  }
  private numberboxTemplate(
    value: number | null,
    prefix: string | null,
    suffix: string | null,
    numberOfDecimals: number | null
  ): HTMLDivElement {
    const container = document.createElement("div");
    container.className = "input-container";

    const valueContainer = document.createElement("div");
    const controlsContainer = document.createElement("div");

    if (prefix) {
      valueContainer.appendChild(this.prefixSuffixElt(prefix));
    }

    valueContainer.classList.add("value");
    if (value != null) {
      const numberElt = document.createElement("span");
      numberElt.classList.add("value-number");

      if (numberOfDecimals) {
        numberElt.innerText = value.toFixed(numberOfDecimals);
      } else {
        numberElt.innerText = value.toString();
      }
      valueContainer.classList.add("default-value");
      valueContainer.appendChild(numberElt);
    } else {
      const numberElt = document.createElement("span");
      numberElt.classList.add("value-number");
      numberElt.innerText = ZeroWidthSpace;

      valueContainer.classList.add("watermark");
      valueContainer.appendChild(numberElt);
    }

    if (suffix) {
      valueContainer.appendChild(this.prefixSuffixElt(suffix));
    }

    container.appendChild(valueContainer);

    controlsContainer.classList.add("controls");
    controlsContainer.appendChild(this.controlElt("bx-minus"));
    controlsContainer.appendChild(this.controlElt("bx-plus"));

    container.appendChild(controlsContainer);
    return container;
  }

  private prefixSuffixElt(text: string): HTMLElement {
    const container = document.createElement("div");
    container.classList.add("prefix-suffix");

    const truncate = document.createElement("div");
    truncate.classList.add("truncate");

    truncate.appendChild(document.createTextNode(text.trim()));
    container.appendChild(truncate);

    return container;
  }

  private controlElt(icon: string): HTMLElement {
    const container = document.createElement("div");
    container.classList.add("bx", icon);

    return container;
  }

  private numberboxSliderTemplate(
    value: number,
    minValue: number,
    maxValue: number,
    labelPosition: InputNumberLabelPosition,
    prefix: string | null,
    suffix: string | null,
    numberOfDecimals: number | null,
    getTranslation: GetTranslationFn
  ): HTMLDivElement {
    const container = document.createElement("div");
    container.className = "input-container";

    const barContainerElt = document.createElement("div");
    const barElt = document.createElement("div");
    const progressBar = document.createElement("div");
    const limitContainer = document.createElement("div");
    const thresholdBar = document.createElement("div");
    const defaultValueContainer = document.createElement("div");
    const defaultValueText = document.createElement("p");
    const handlebarElt = document.createElement("div");

    limitContainer.innerHTML = `<div class="minimum">${
      minValue !== null ? minValue.toString() : "?"
    }</div><div class="maximum">${
      maxValue !== null ? maxValue.toString() : "?"
    }</div>`;

    defaultValueText.innerHTML =
      value != null
        ? numberOfDecimals
          ? (prefix ? prefix : "") +
            value.toFixed(numberOfDecimals) +
            (suffix ? suffix : "")
          : (prefix ? prefix : "") + value.toString() + (suffix ? suffix : "")
        : notSelectedText(getTranslation);
    thresholdBar.style.display = value != null ? "block" : "none";

    barContainerElt.classList.add(...["bar-container"], labelPosition);
    limitContainer.classList.add("limit-container");
    handlebarElt.classList.add("handlebar");
    thresholdBar.classList.add("threshold-bar");
    progressBar.classList.add("numberbox-slider-progress");
    barElt.classList.add(...["bar", ...(value ? [] : ["not-selected"])]);
    defaultValueContainer.classList.add("default-value-container");

    const percentage = progressBarPercentage(value, minValue, maxValue);
    if (percentage == null) {
      progressBar.style.width = "0%";
    } else {
      progressBar.style.width = `${percentage}%`;
      progressBar.classList.add("non-null-default");
    }

    defaultValueContainer.appendChild(defaultValueText);
    progressBar.appendChild(handlebarElt);
    barElt.appendChild(thresholdBar);
    barElt.appendChild(progressBar);
    barContainerElt.appendChild(defaultValueContainer);
    barContainerElt.appendChild(barElt);
    barContainerElt.appendChild(limitContainer);
    container.appendChild(barContainerElt);

    return container;
  }

  private updateQuestionTitle(node: Node<S>, decorations: Decoration[]): void {
    this.questionTitleBindingButton.update(node, decorations);
  }

  private updateDefaultValue(node: Node<S>): void {
    if (node.attrs.defaultValue != null) {
      let defaultValueContainer = this.dom.querySelector(
        ".default-value-container"
      ) as HTMLElement;
      let defaultValue = this.dom.querySelector(
        ".default-value-container p"
      ) as HTMLElement;

      const percentage = progressBarPercentage(
        node.attrs.defaultValue,
        node.attrs.minValue,
        node.attrs.maxValue
      );

      if (defaultValueContainer) {
        if (percentage == null) {
          defaultValueContainer.style.paddingLeft = `calc(20px + 0%)`;
        } else {
          if (percentage < 60) {
            defaultValueContainer.style.paddingLeft = `calc(20px + ${percentage}%)`;
          } else {
            defaultValueContainer.style.flexDirection = "row-reverse";
            defaultValueContainer.style.paddingRight = `calc(${
              100 - percentage
            }% - 37px)`;

            defaultValue.style.textAlign = "right";
          }
        }
      }
    }
  }
}

function notSelectedText(getTranslation: GetTranslationFn): string {
  const translation = getTranslation("INPUT_NUMBER.NOT_SELECTED");
  const htmlValue = [
    ["&lt;", "<"],
    ["&gt;", ">"]
  ].reduce((acc, [from, to]) => {
    return replaceAll(acc, from, to);
  }, translation);

  return htmlValue;
}

function progressBarPercentage(
  value: number | null,
  minValue: number | null,
  maxValue: number | null
): number | null {
  if (value == null || minValue == null || maxValue == null) {
    return null;
  } else {
    const range: number = maxValue - minValue;
    const position: number = value - minValue;
    const ratio = Math.max(
      Math.min(Math.floor((position / range) * 100), 100),
      0
    );
    return ratio;
  }
}
