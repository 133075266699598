import { UnreachableCaseError } from "../../util";
import {
  Color,
  ColorPalette,
  ColorPaletteId,
  ColorScheme,
  ColorSet
} from "./types";

function getPrimaryColorSet(paletteId: ColorPaletteId): ColorSet {
  switch (paletteId) {
    case "brown":
    case "blue":
    case "green":
    case "vintage":
    case "sunshine":
    case "ice-cream":
    case "dream":
    case "black":
      return new ColorSet({
        "1": new Color(255, 255, 255),
        "2": new Color(173, 0, 0),
        "3": new Color(19, 160, 113),
        "4": new Color(11, 55, 170),
        "5": new Color(51, 51, 51)
      });
    case "dark-brown":
    case "dark-blue":
    case "dark-green":
    case "dark-vintage":
    case "dark-sunshine":
    case "dark-ice-cream":
    case "dark-dream":
    case "dark-black":
      return new ColorSet({
        "1": new Color(28, 28, 30),
        "2": new Color(255, 69, 58),
        "3": new Color(48, 209, 88),
        "4": new Color(250, 203, 51),
        "5": new Color(255, 255, 255),
        "6": new Color(0, 0, 0)
      });
    default:
      throw new UnreachableCaseError(paletteId);
  }
}

function getThemeColorSet(paletteId: ColorPaletteId): ColorSet {
  switch (paletteId) {
    case "brown":
      return new ColorSet({
        "1": new Color(248, 242, 235),
        "2": new Color(237, 226, 213),
        "3": new Color(219, 205, 189),
        "4": new Color(159, 139, 114),
        "5": new Color(131, 93, 52)
      });

    case "blue":
      return new ColorSet({
        "1": new Color(222, 239, 249),
        "2": new Color(196, 232, 248),
        "3": new Color(159, 214, 238),
        "4": new Color(6, 152, 190),
        "5": new Color(0, 109, 143)
      });

    case "green":
      return new ColorSet({
        "1": new Color(220, 239, 237),
        "2": new Color(202, 227, 224),
        "3": new Color(158, 208, 207),
        "4": new Color(0, 161, 158),
        "5": new Color(0, 112, 111)
      });

    case "vintage":
      return new ColorSet({
        "1": new Color(239, 247, 245),
        "2": new Color(226, 245, 240),
        "3": new Color(229, 235, 237),
        "4": new Color(201, 115, 115),
        "5": new Color(185, 90, 78)
      });

    case "sunshine":
      return new ColorSet({
        "1": new Color(255, 243, 237),
        "2": new Color(255, 226, 211),
        "3": new Color(242, 151, 109),
        "4": new Color(219, 49, 86),
        "5": new Color(133, 29, 65)
      });

    case "ice-cream":
      return new ColorSet({
        "1": new Color(232, 241, 255),
        "2": new Color(247, 250, 255),
        "3": new Color(203, 222, 252),
        "4": new Color(195, 190, 240),
        "5": new Color(139, 101, 170)
      });

    case "dream":
      return new ColorSet({
        "1": new Color(255, 239, 239),
        "2": new Color(253, 221, 221),
        "3": new Color(230, 179, 179),
        "4": new Color(136, 134, 162),
        "5": new Color(95, 93, 111)
      });

    case "black":
      return new ColorSet({
        "1": new Color(240, 240, 240),
        "2": new Color(227, 227, 227),
        "3": new Color(204, 204, 204),
        "4": new Color(112, 112, 112),
        "5": new Color(85, 85, 85)
      });

    case "dark-brown":
      return new ColorSet({
        "1": new Color(248, 242, 235),
        "2": new Color(43, 37, 30),
        "3": new Color(116, 92, 63),
        "4": new Color(159, 139, 114),
        "5": new Color(131, 93, 52)
      });

    case "dark-blue":
      return new ColorSet({
        "1": new Color(222, 239, 249),
        "2": new Color(25, 36, 52),
        "3": new Color(127, 127, 127),
        "4": new Color(26, 113, 201),
        "5": new Color(10, 131, 254)
      });

    case "dark-green":
      return new ColorSet({
        "1": new Color(220, 239, 237),
        "2": new Color(49, 59, 59),
        "3": new Color(110, 116, 116),
        "4": new Color(0, 161, 158),
        "5": new Color(0, 121, 88)
      });

    case "dark-vintage":
      return new ColorSet({
        "1": new Color(236, 229, 216),
        "2": new Color(63, 52, 67),
        "3": new Color(136, 128, 108),
        "4": new Color(70, 157, 131),
        "5": new Color(185, 90, 78)
      });

    case "dark-sunshine":
      return new ColorSet({
        "1": new Color(255, 243, 237),
        "2": new Color(49, 42, 39),
        "3": new Color(217, 181, 173),
        "4": new Color(219, 49, 86),
        "5": new Color(133, 29, 65)
      });

    case "dark-ice-cream":
      return new ColorSet({
        "1": new Color(232, 241, 255),
        "2": new Color(24, 31, 52),
        "3": new Color(116, 117, 160),
        "4": new Color(255, 151, 162),
        "5": new Color(126, 13, 175)
      });

    case "dark-dream":
      return new ColorSet({
        "1": new Color(255, 239, 239),
        "2": new Color(44, 41, 59),
        "3": new Color(160, 160, 186),
        "4": new Color(95, 93, 111),
        "5": new Color(71, 60, 227)
      });

    case "dark-black":
      return new ColorSet({
        "1": new Color(240, 240, 240),
        "2": new Color(45, 45, 47),
        "3": new Color(144, 144, 144),
        "4": new Color(112, 112, 112),
        "5": new Color(85, 85, 85)
      });

    default:
      throw new UnreachableCaseError(paletteId);
  }
}

function colorSchemeForPalette(paletteId: ColorPaletteId): ColorScheme {
  return {
    primary: getPrimaryColorSet(paletteId),
    theme: getThemeColorSet(paletteId)
  };
}

function blackColorPalette(): ColorPalette {
  return new ColorPalette({
    id: "black",
    name: "Classic",
    type: "light",
    colorScheme: colorSchemeForPalette("black")
  });
}

function brownColorPalette(): ColorPalette {
  return new ColorPalette({
    id: "brown",
    name: "Coffee",
    type: "light",
    colorScheme: colorSchemeForPalette("brown")
  });
}

function blueColorPalette(): ColorPalette {
  return new ColorPalette({
    id: "blue",
    name: "Ocean",
    type: "light",
    colorScheme: colorSchemeForPalette("blue")
  });
}

function greenColorPalette(): ColorPalette {
  return new ColorPalette({
    id: "green",
    name: "Forrest",
    type: "light",
    colorScheme: colorSchemeForPalette("green")
  });
}

function dreamColorPalette(): ColorPalette {
  return new ColorPalette({
    id: "dream",
    name: "Dream",
    type: "light",
    colorScheme: colorSchemeForPalette("dream")
  });
}

function vintageColorPalette(): ColorPalette {
  return new ColorPalette({
    id: "vintage",
    name: "Vintage",
    type: "light",
    colorScheme: colorSchemeForPalette("vintage")
  });
}

function iceCreamColorPalette(): ColorPalette {
  return new ColorPalette({
    id: "ice-cream",
    name: "Ice cream",
    type: "light",
    colorScheme: colorSchemeForPalette("ice-cream")
  });
}

function sunshineColorPalette(): ColorPalette {
  return new ColorPalette({
    id: "sunshine",
    name: "Sunshine",
    type: "light",
    colorScheme: colorSchemeForPalette("sunshine")
  });
}

/* Dark palettes */

function darkBlackColorPalette(): ColorPalette {
  return new ColorPalette({
    id: "dark-black",
    name: "Classic",
    type: "dark",
    colorScheme: colorSchemeForPalette("dark-black")
  });
}

function darkBrownColorPalette(): ColorPalette {
  return new ColorPalette({
    id: "dark-brown",
    name: "Coffee",
    type: "dark",
    colorScheme: colorSchemeForPalette("dark-brown")
  });
}

function darkBlueColorPalette(): ColorPalette {
  return new ColorPalette({
    id: "dark-blue",
    name: "Ocean",
    type: "dark",
    colorScheme: colorSchemeForPalette("dark-blue")
  });
}

function darkGreenColorPalette(): ColorPalette {
  return new ColorPalette({
    id: "dark-green",
    name: "Forrest",
    type: "dark",
    colorScheme: colorSchemeForPalette("dark-green")
  });
}

function darkDreamColorPalette(): ColorPalette {
  return new ColorPalette({
    id: "dark-dream",
    name: "Dream",
    type: "dark",
    colorScheme: colorSchemeForPalette("dark-dream")
  });
}

function darkVintageColorPalette(): ColorPalette {
  return new ColorPalette({
    id: "dark-vintage",
    name: "Vintage",
    type: "dark",
    colorScheme: colorSchemeForPalette("dark-vintage")
  });
}

function darkIceCreamColorPalette(): ColorPalette {
  return new ColorPalette({
    id: "dark-ice-cream",
    name: "Ice cream",
    type: "dark",
    colorScheme: colorSchemeForPalette("dark-ice-cream")
  });
}

function darkSunshineColorPalette(): ColorPalette {
  return new ColorPalette({
    id: "dark-sunshine",
    name: "Sunshine",
    type: "dark",
    colorScheme: colorSchemeForPalette("dark-sunshine")
  });
}

export const palettes = [
  blackColorPalette(),
  brownColorPalette(),
  blueColorPalette(),
  greenColorPalette(),
  sunshineColorPalette(),
  dreamColorPalette(),
  iceCreamColorPalette(),
  vintageColorPalette(),
  darkBlackColorPalette(),
  darkBrownColorPalette(),
  darkBlueColorPalette(),
  darkGreenColorPalette(),
  darkSunshineColorPalette(),
  darkDreamColorPalette(),
  darkIceCreamColorPalette(),
  darkVintageColorPalette()
];
