import { Schema } from "prosemirror-model";
import { PluginKey, Plugin, TextSelection } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";
import { Editor } from "../../../editor";
import { scrollTrackingKey } from "../../../editor/plugins/scroll-tracking";
import {
  Focus,
  selectionFocusKey
} from "../../../editor/plugins/selection-focus";
import { findParentNodeOfType, scrollParent } from "../../../util";
import { getTranslation } from "../../localization";
import { InputRankSectionOption, InputRankSectionPosition } from "../schema";
import { focusedInputRank } from "../util";
import { inputRankViewKey } from "./input-rank-view-plugin";

export type InputRankDropdownRenderer = (
  options: InputRankSectionOption[],
  onSelect: (position: InputRankSectionPosition) => void
) => HTMLElement;

interface PluginState {
  opened: boolean;
  sections: PluginStateRankDropdown[];
}

interface PluginStateRankDropdown {
  opened: boolean;
  focused: Focus;
}

export const inputRankDropdownPluginKey = new PluginKey<
  PluginState | null,
  Schema
>("inputRankDropdownPlugin");

const DROPDOWN_SECTION_CLASS = "ProseMirror-rank-section-dropdown";
const DROPDOWN_TOGGLE_CLASS = "ProseMirror-dropdown-toggle";
const DROPDOWN_CONTENT_CLASS = "ProseMirror-dropdown-content";
const DROPDOWN_ITEM_CLASS = "ProseMirror-dropdown-item";

export function inputRankDropdownPlugin() {
  return new Plugin<PluginState | null, Schema>({
    key: inputRankDropdownPluginKey,
    state: {
      init(_config, _state) {
        return null;
      },
      apply(tr, value, _oldState, newState) {
        const newFocused = selectionFocusKey.getState(newState);
        if (!newFocused) {
          return null;
        }

        const { schema } = newState;

        if (newFocused.node.type === schema.nodes.inputRank) {
          const sections = newFocused.node.firstChild;

          if (sections == null) {
            return null;
          }

          const action = tr.getMeta(inputRankDropdownPluginKey);
          if (action != null && action.setOpened != null) {
            if (value == null) {
              return null;
            }

            const { opened } = action.setOpened as { opened: boolean };
            return {
              ...value,
              opened: opened
            };
          } else {
            let sectionsPluginState: PluginStateRankDropdown[] = [];
            let sectionPos = newFocused.pos + 2;
            sections.content.forEach((section) => {
              sectionsPluginState.push({
                opened: false,
                focused: {
                  node: section,
                  pos: sectionPos
                }
              });
              sectionPos += section.nodeSize;
            });

            return {
              opened: true,
              sections: sectionsPluginState
            };
          }
        }

        return null;
      }
    },
    view(view) {
      const updateDropdownPosition = () => {
        const content = view.dom.querySelector<HTMLElement>(
          `.${DROPDOWN_CONTENT_CLASS}:not([style])`
        );

        if (content == null) {
          return;
        }

        const dropdown = content.parentElement;
        if (dropdown == null) {
          return;
        }

        const toggle = dropdown.querySelector<HTMLElement>(
          `.${DROPDOWN_TOGGLE_CLASS}`
        );

        if (toggle == null || content == null) {
          return;
        }

        const { vertical, horizontal } = positionDropdown(toggle, content);
        dropdown.setAttribute(
          "data-dropdown-position",
          `${vertical}-${horizontal}`
        );
      };

      const scrollTracking = scrollTrackingKey.getState(view.state);
      scrollTracking?.subscribe(updateDropdownPosition);

      return {
        update: (_view) => {
          updateDropdownPosition();
        },
        destroy: () => {
          scrollTracking?.unsubscribe(updateDropdownPosition);
        }
      };
    },
    props: {
      decorations(state) {
        const currentState = this.getState(state);
        const translation = getTranslation(state);

        if (currentState == null) {
          return null;
        }

        const decorations: Decoration[] = [];

        if (!currentState.opened) {
          const focused = selectionFocusKey.getState(state);
          const { schema } = state;

          if (focused && focused.node.type === schema.nodes.inputRank) {
            decorations.push(
              Decoration.node(
                focused.pos,
                focused.pos + focused.node.nodeSize,
                { class: "options-hide" }
              )
            );
          }
        }

        currentState.sections.forEach((section) => {
          decorations.push(
            Decoration.widget(
              section.focused.pos + section.focused.node.nodeSize,
              () => {
                const dropdown = document.createElement("div");
                dropdown.className = DROPDOWN_SECTION_CLASS;
                dropdown.setAttribute(
                  "section-id",
                  section.focused.node.attrs.id
                );

                const dropdownToggle = document.createElement("button");
                dropdownToggle.className = DROPDOWN_TOGGLE_CLASS;

                const triangle = document.createElement("span");
                triangle.className = "triangle";

                dropdownToggle.appendChild(triangle);

                const dropdownContent = document.createElement("div");
                dropdownContent.className = DROPDOWN_CONTENT_CLASS;

                if (section.opened === false) {
                  dropdownContent.style.display = "none";
                }

                const defaultSectionActions = [
                  {
                    label: translation(
                      "INPUT_RANK.SECTION_DROPDOWN.ADD_BEFORE"
                    ),
                    action: "addSectionBefore"
                  },
                  {
                    label: translation("INPUT_RANK.SECTION_DROPDOWN.ADD_AFTER"),
                    action: "addSectionAfter"
                  }
                ];

                let expectedActions = defaultSectionActions;

                if (currentState.sections.length > 1) {
                  expectedActions.push({
                    label: translation("INPUT_RANK.SECTION_DROPDOWN.DELETE"),
                    action: "deleteSection"
                  });
                }

                expectedActions.forEach(({ label, action }) => {
                  const dropdownItem = document.createElement("button");
                  dropdownItem.className = DROPDOWN_ITEM_CLASS;
                  dropdownItem.textContent = label;
                  dropdownItem.setAttribute("data-action", action);
                  dropdownContent.appendChild(dropdownItem);
                });

                dropdown.appendChild(dropdownToggle);
                dropdown.appendChild(dropdownContent);

                return dropdown;
              }
            )
          );
        });

        const { doc } = state;
        return DecorationSet.create(doc, decorations);
      },
      handleDOMEvents: {
        mousedown(view, event) {
          const pluginState = inputRankDropdownPluginKey.getState(view.state);
          if (pluginState == null) {
            return false;
          }
          const target = event.target as HTMLElement;
          const dropdownTarget = target.closest(`.${DROPDOWN_SECTION_CLASS}`);

          const { sections } = pluginState;

          if (!sections) {
            return false;
          }

          if (dropdownTarget) {
            const targetSection = sections.find(
              (section) =>
                section.focused.node.attrs.id ===
                dropdownTarget.getAttribute("section-id")
            );

            if (targetSection == null) {
              return false;
            }

            const sectionOpen = targetSection.opened;
            const toggle = target.closest(`.${DROPDOWN_TOGGLE_CLASS}`);

            if (toggle !== null) {
              const expectedSections = sections.map((section) => {
                section.opened =
                  targetSection.focused.pos === section.focused.pos
                    ? !section.opened
                    : false;
                return section;
              });
              view.dispatch(
                view.state.tr.setMeta(inputRankDropdownPluginKey, {
                  setOpened: { opened: sectionOpen, sections: expectedSections }
                })
              );
              event.preventDefault();
              return true;
            }

            const dropdownContent = target.closest(
              `.${DROPDOWN_CONTENT_CLASS}`
            );

            if (dropdownContent !== null) {
              event.preventDefault();
              return true;
            }

            return false;
          }

          return false;
        },
        click(view, event) {
          const pluginState = inputRankDropdownPluginKey.getState(view.state);
          if (pluginState == null) {
            return false;
          }

          const { sections } = pluginState;
          const target = event.target as HTMLElement;

          if (sections == null) {
            return false;
          }

          const dropdownItem = target.closest(`.${DROPDOWN_ITEM_CLASS}`);

          if (dropdownItem) {
            const dropdownContent = dropdownItem.parentElement;

            if (dropdownContent == null) {
              return false;
            }

            const dropdownTarget = dropdownContent.parentElement;

            if (dropdownTarget == null) {
              return false;
            }

            const targetSection = sections.find(
              (section) =>
                section.focused.node.attrs.id ===
                dropdownTarget.getAttribute("section-id")
            );

            if (targetSection == null) {
              return false;
            }

            const action = dropdownItem.getAttribute("data-action");
            const editor = view as Editor<Schema>;

            switch (action) {
              case "addSectionBefore":
                editor.commands.updateInputRankSection.execute({
                  position: "before",
                  focusedSectionNode: targetSection.focused
                });
                break;

              case "addSectionAfter":
                editor.commands.updateInputRankSection.execute({
                  position: "after",
                  focusedSectionNode: targetSection.focused
                });
                break;

              case "deleteSection":
                editor.commands.updateInputRankSection.execute({
                  position: "delete",
                  focusedSectionNode: targetSection.focused
                });
                break;
            }

            event.preventDefault();
            return true;
          }

          const targetSection = sections.find((section) =>
            view.nodeDOM(section.focused.pos)?.contains(target)
          );

          if (targetSection) {
            const parent = findParentNodeOfType(
              view.state.schema.nodes.inputRank
            )(view.state.selection);

            view.dispatch(
              view.state.tr.setMeta(inputRankViewKey, {
                setActive: {
                  focused: parent,
                  sectionId: targetSection.focused.node.attrs.id
                }
              })
            );
          } else {
            const { state } = view;

            const rankState = inputRankViewKey.getState(state);

            if (rankState == null) {
              return false;
            }

            if (rankState.sectionId == null) {
              const sections = rankState.focused.node.firstChild;

              if (sections == null) {
                return false;
              }

              const firstSection = sections.firstChild;

              if (firstSection == null) {
                return false;
              }

              view.dispatch(
                view.state.tr.setMeta(inputRankViewKey, {
                  setActive: {
                    sectionId: firstSection.attrs.id
                  }
                })
              );
            }
          }
          return false;
        },
        keyup(view, _event) {
          const { state } = view;

          const focused = focusedInputRank(state);

          if (focused == null) {
            return false;
          }

          const { selection } = state;
          if (!(selection instanceof TextSelection)) {
            return false;
          }

          let tr = state.tr;

          const { $to } = tr.selection;
          const node = $to.node();

          const { schema } = state;

          if (node.type !== schema.nodes.inputRankSection) {
            return false;
          }

          const rankState = inputRankViewKey.getState(state);

          if (rankState == null) {
            return false;
          }

          if (node.attrs.id !== rankState.sectionId) {
            view.dispatch(
              view.state.tr.setMeta(inputRankViewKey, {
                setActive: {
                  sectionId: node.attrs.id
                }
              })
            );
          }

          return false;
        }
      }
    }
  });
}

function positionDropdown(
  dom: HTMLElement,
  contentDOM: HTMLElement
): { vertical: "top" | "bottom"; horizontal: "left" | "right" } {
  contentDOM.style.zIndex = "-1";

  const scrollingContainer = scrollParent(dom);

  const scrollingContainerRect = scrollingContainer.getBoundingClientRect();
  const domToggleRect = dom.getBoundingClientRect();
  const dropdownContentRect = contentDOM.getBoundingClientRect();

  const vertical = calculateVerticalPosition(
    scrollingContainerRect,
    domToggleRect,
    dropdownContentRect
  );

  contentDOM.style.zIndex = "";

  return { vertical: vertical, horizontal: "right" };
}

function calculateVerticalPosition(
  scrollingContainerRect: DOMRect,
  domToggleRect: DOMRect,
  dropdownContentRect: DOMRect
): "top" | "bottom" {
  const scrollingContainerHeight = scrollingContainerRect.height;

  const dropdownOffset = 4;

  const scrollOffset =
    domToggleRect.bottom +
    dropdownOffset +
    dropdownContentRect.height -
    scrollingContainerRect.top;

  if (scrollOffset >= scrollingContainerHeight) {
    return "top";
  } else {
    return "bottom";
  }
}
