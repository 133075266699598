import { Editor as BlueXEditor, Schema } from "@blue-x/editor";
import React, { useEffect, useRef } from "react";
import "./Content.css";

interface Props {
  editor: BlueXEditor<Schema>;
}

const Content: React.FC<Props> = (props) => {
  const { editor } = props;
  const editorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const { current } = editorRef;
    if (current) {
      current.appendChild(editor.dom);
      editor.dom.classList.add("Content");
    }
  }, [editor]);

  return <div className="Editor" ref={editorRef}></div>;
};

export default Content;
