import { Schema } from "prosemirror-model";

export type InputNumberSchema = Schema<"inputNumber", any>;

export enum InputNumberWidth {
  default = 25,
  minimum = 25,
  maximum = 100
}

export enum InputNumberControlType {
  spinbox = "numberbox",
  slider = "numberbox-slider"
}

export enum InputNumberLabelPosition {
  top = "top",
  bottom = "bottom",
  none = "none"
}
export enum InputNumberOfDecimals {
  minimum = 0,
  maximum = 5
}
