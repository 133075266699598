import { NodeSpec, Schema } from "prosemirror-model";
import {
  DocumentBuilders,
  Extension,
  NodeConfig
} from "../../editor/extension";

class DocNode implements NodeConfig {
  get name(): string {
    return "doc";
  }

  get spec(): NodeSpec {
    return {
      content: "(block|rootBlock)+"
    };
  }

  get builders(): DocumentBuilders {
    return { doc: { nodeType: "doc" } };
  }
}

type DocSchema = Schema<"doc", any>;

export class Doc implements Extension<DocSchema> {
  get name(): string {
    return "doc";
  }

  get nodes(): NodeConfig[] {
    return [new DocNode()];
  }
}
