import {
  EditorState,
  Plugin,
  PluginKey,
  TextSelection
} from "prosemirror-state";
import { Node as ProsemirrorNode } from "prosemirror-model";
import { GapCursor, Side } from "../../../editor/plugins/gap-cursor";
import {
  Focus,
  selectionFocusKey
} from "../../../editor/plugins/selection-focus";
import { findChildrenByType } from "../../../util";
import { PageBreakSchema } from "../schema";

const skipHiddenPageBreakButtonsKey = new PluginKey<boolean, PageBreakSchema>(
  "skipHiddenPageBreakButtons"
);

export function skipHiddenPageBreakButtons() {
  return new Plugin<boolean, PageBreakSchema>({
    key: skipHiddenPageBreakButtonsKey,
    state: {
      init() {
        return false;
      },
      apply(_tr, _value, oldState, newState) {
        if (oldState.selection !== newState.selection) {
          const focusedState =
            focusedPageBreak(newState) ?? focusedPageBreak(oldState);

          if (focusedState) {
            return (
              focusedState.pos + focusedState.node.nodeSize <
                newState.selection.from ||
              focusedState.pos > newState.selection.from
            );
          }
          return false;
        }
        return false;
      }
    },
    appendTransaction(_transactions, oldState, newState) {
      const { schema, doc } = newState;
      const pageBreak = findChildrenByType(doc, schema.nodes.pageBreak);
      const focused = skipHiddenPageBreakButtonsKey.getState(newState);
      let pageBreakCount = "";

      const insidePagebreak = pageBreak.find((pbNode, index) => {
        if (pageBreak.length === 1) {
          pageBreakCount = "only";
        } else if (index === 0) {
          pageBreakCount = "first";
        } else if (index + 1 === pageBreak.length) {
          pageBreakCount = "last";
        } else {
          pageBreakCount = "";
        }
        return (
          pbNode.pos + pbNode.node.nodeSize > newState.selection.from &&
          newState.selection.from > pbNode.pos
        );
      });

      if (
        insidePagebreak &&
        isHidden(pageBreakCount, newState.selection.$from.parent, schema)
      ) {
        const direction =
          newState.selection.from > oldState.selection.from ? 1 : -1;
        let newPos = newState.selection.from,
          validTextSelection = true,
          $pos = newState.doc.resolve(newPos);
        while (
          isHidden(pageBreakCount, $pos.parent, schema) ||
          ($pos.parent.type === schema.nodes.pageBreak && !validTextSelection)
        ) {
          newPos += direction;
          $pos = newState.doc.resolve(newPos);
          validTextSelection = $pos.parent.inlineContent;
        }

        if ($pos == null) {
          return undefined;
        }

        const selection = validTextSelection
          ? new TextSelection($pos)
          : new GapCursor($pos, direction ? Side.LEFT : Side.RIGHT);
        return newState.tr.setSelection(selection);
      }

      if (focused) {
        const direction =
          newState.selection.from > oldState.selection.from ? -1 : 1;
        let newPos = newState.selection.from,
          validTextSelection = false,
          validGapCursor = false,
          $pos;
        while (!validGapCursor && !validTextSelection) {
          newPos += direction;
          $pos = newState.doc.resolve(newPos);
          validTextSelection = $pos.parent.inlineContent;
          validGapCursor = GapCursor.valid(
            $pos,
            newState.selection.from > oldState.selection.from
              ? Side.RIGHT
              : Side.LEFT
          );
        }

        if ($pos == null) {
          return undefined;
        }

        const selection = validTextSelection
          ? new TextSelection($pos)
          : new GapCursor(
              $pos,
              newState.selection.from > oldState.selection.from
                ? Side.RIGHT
                : Side.LEFT
            );
        return newState.tr.setSelection(selection);
      }
      return undefined;
    }
  });
}

function focusedPageBreak(
  state: EditorState<PageBreakSchema>
): Focus | undefined {
  const { schema } = state;
  const focused = selectionFocusKey.getState(state);

  if (focused != null && focused.node.type === schema.nodes.pageBreak) {
    return focused;
  } else {
    return undefined;
  }
}

function isHidden(
  styleRule: string,
  node: ProsemirrorNode,
  schema: PageBreakSchema
): boolean {
  if (node) {
    if (styleRule === "only") {
      return node.type === schema.nodes.previousPageBreakButton;
    } else if (styleRule === "first") {
      return (
        node.type === schema.nodes.previousPageBreakButton ||
        node.type === schema.nodes.submitPageBreakButton
      );
    } else if (styleRule === "last") {
      return node.type === schema.nodes.nextPageBreakButton;
    } else {
      return node.type === schema.nodes.submitPageBreakButton;
    }
  }
  return false;
}
