import { Node, Schema } from "prosemirror-model";
import { EditorState, NodeSelection } from "prosemirror-state";
import { CellSelection } from "prosemirror-tables";
import { GapCursor } from "../../editor/plugins/gap-cursor/gap-cursor";
import { AlignmentType, availableAlignment } from "./values";

export function getAlignment(value: string | null): AlignmentType | null {
  if (value != null && availableAlignment.includes(value as AlignmentType)) {
    return value as AlignmentType;
  } else {
    return null;
  }
}

export function hasAlignment<S extends Schema>(node: Node<S>): boolean {
  return node.type.spec.attrs?.alignment != null;
}

function alignmentIsEqual<S extends Schema>(
  node: Node<S>,
  parent: Node<S>,
  alignment: AlignmentType,
  defaultAlignment: AlignmentType
): boolean {
  if (node.attrs.alignment === alignment) {
    return true;
  } else if (node.attrs.alignment === null) {
    const nodeDefaultAlignment = node.type.spec.defaultAlignment as
      | ((node: Node<Schema>, parent: Node<Schema>) => AlignmentType)
      | undefined;

    if (nodeDefaultAlignment == null) {
      return alignment === defaultAlignment;
    } else {
      return alignment === nodeDefaultAlignment(node, parent);
    }
  } else {
    return false;
  }
}

function getNodesInRange<S extends Schema>(
  doc: Node<S>,
  from: number,
  to: number,
  alignment: AlignmentType,
  defaultAlignment: AlignmentType
): {
  nodes: { node: Node<S>; pos: number; parent: Node<S> }[];
  count: number;
} {
  let nodes = new Array<{ node: Node<S>; pos: number; parent: Node<S> }>();
  let count = 0;
  doc.nodesBetween(from, to, (node, pos, parent) => {
    const blockAlignment = node.type.spec.blockAlignment === true;
    if (hasAlignment(node) && !blockAlignment) {
      nodes.push({ node, pos, parent });
      if (alignmentIsEqual(node, parent, alignment, defaultAlignment)) {
        count = count + 1;
      }
      return false;
    }
    return;
  });

  return { nodes, count };
}

export function getAlignmentNodes<S extends Schema>(
  alignment: AlignmentType,
  defaultAlignment: AlignmentType
): (
  state: EditorState<S>
) => {
  nodes: { node: Node<S>; pos: number; parent: Node<S> }[];
  count: number;
} {
  return (state) => {
    const { selection, doc } = state;
    if (selection instanceof GapCursor) {
      const { node, nodePos } = selection;

      if (node != null && nodePos != null && hasAlignment(node)) {
        const parent = doc.resolve(nodePos).parent;
        return {
          nodes: [{ node, pos: nodePos, parent }],
          count: alignmentIsEqual(node, parent, alignment, defaultAlignment)
            ? 1
            : 0
        };
      } else {
        return { nodes: [], count: 0 };
      }
    } else {
      if (selection instanceof NodeSelection) {
        const { node } = selection;
        if (hasAlignment(node)) {
          const nodePos = selection.from;
          const parent = doc.resolve(nodePos).parent;
          return {
            nodes: [{ node, pos: nodePos, parent }],
            count: alignmentIsEqual(node, parent, alignment, defaultAlignment)
              ? 1
              : 0
          };
        }
      } else if (selection instanceof CellSelection) {
        const ranges = selection.ranges;
        return ranges.reduce(
          (acc, elem) => {
            const { $from, $to } = elem;
            const { nodes, count } = getNodesInRange(
              doc,
              $from.pos,
              $to.pos,
              alignment,
              defaultAlignment
            );
            return {
              nodes: acc.nodes.concat(nodes),
              count: acc.count + count
            };
          },
          {
            nodes: new Array<{ node: Node<S>; pos: number; parent: Node<S> }>(),
            count: 0
          }
        );
      }

      const { from, to } = selection;
      return getNodesInRange(doc, from, to, alignment, defaultAlignment);
    }
  };
}

export function isAlignmentActive<S extends Schema>(
  alignment: AlignmentType,
  defaultAlignment: AlignmentType
): (state: EditorState<S>) => boolean {
  return (state) => {
    const { nodes, count } = getAlignmentNodes(
      alignment,
      defaultAlignment
    )(state);
    return count > 0 && nodes.length === count;
  };
}

export function isAlignmentEnabled<S extends Schema>(
  alignment: AlignmentType
): (state: EditorState<S>) => boolean {
  const supportsAlignments = (node: Node<S>) => {
    const supportedAlignments = node.type.spec
      .allowAlignment as AlignmentType[];
    if (supportedAlignments != null) {
      return supportedAlignments.includes(alignment);
    } else {
      return true;
    }
  };

  return (state) => {
    const { doc, selection } = state;
    if (selection instanceof GapCursor) {
      const { node } = selection;

      if (node != null && hasAlignment(node)) {
        return supportsAlignments(node);
      } else {
        return false;
      }
    } else {
      if (selection instanceof NodeSelection) {
        const { node } = selection;

        if (node != null && hasAlignment(node)) {
          return supportsAlignments(node);
        }
      }

      const { from, to } = selection;

      let supported: boolean[] = [];

      doc.nodesBetween(from, to, (node) => {
        const blockAlignment = node.type.spec.blockAlignment === true;
        if (hasAlignment(node) && !blockAlignment) {
          supported = supported.concat(supportsAlignments(node));
          return false;
        }

        return;
      });

      return supported.every((x) => x === true);
    }
  };
}
