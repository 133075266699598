import { MarkSpec, Schema } from "prosemirror-model";
import {
  BASE_PRIORITY,
  CommandConfiguration,
  CommandConfigurations,
  DocumentBuilders,
  Extension,
  MarkConfig
} from "../../editor";
import {
  activeTextMark,
  getHexColor,
  isHexString,
  removeMark,
  textMarkIsActive,
  textMarkIsEnabled,
  updateMark
} from "../../util";

class TextColorMark implements MarkConfig {
  get name(): string {
    return "textColor";
  }

  get spec(): MarkSpec {
    return {
      group: "text-style",
      attrs: { color: {} },
      parseDOM: [
        {
          style: "color",
          getAttrs: (value) => {
            if (typeof value !== "string") {
              return false;
            }

            if (value == null) {
              return false;
            }

            const color = getHexColor("color", value);

            return color ? { color: color } : false;
          }
        }
      ],
      toDOM(node) {
        return [
          "span",
          {
            style: `color: ${node.attrs.color}`
          },
          0
        ];
      }
    };
  }

  get priority(): number {
    return BASE_PRIORITY + 1;
  }

  get builders(): DocumentBuilders {
    return {
      redTextColor: { markType: "textColor", color: "#FF0000" },
      greenTextColor: { markType: "textColor", color: "#00FF00" },
      blueTextColor: { markType: "textColor", color: "#0000FF" }
    };
  }
}

type TextColorSchema = Schema<any, "textColor">;

interface TextColorCommandProps {
  color: string;
}

export class TextColor implements Extension<TextColorSchema> {
  private defaultColor: string | null = null;

  get name(): string {
    return "textColor";
  }

  get marks(): MarkConfig[] {
    return [new TextColorMark()];
  }

  commands(schema: TextColorSchema): CommandConfigurations<TextColorSchema> {
    return {
      textColor: this.textColorCommand(schema)
    };
  }

  private textColorCommand(
    schema: TextColorSchema
  ): CommandConfiguration<TextColorSchema, TextColorCommandProps, string> {
    return {
      isActive: (props) =>
        textMarkIsActive(schema.marks.textColor, props, {
          color: this.defaultColor
        }),
      isEnabled: () => textMarkIsEnabled(schema.marks.textColor),
      execute: (props) => {
        const color = props?.color;
        if (
          color != null &&
          color !== this.defaultColor &&
          isHexString(color)
        ) {
          return updateMark(schema.marks.textColor, {
            color: color.toUpperCase()
          });
        } else {
          return removeMark(schema.marks.textColor);
        }
      },
      activeValue: () => {
        return (state) => {
          const mark = activeTextMark(schema.marks.textColor, undefined, {
            color: this.defaultColor
          })(state);

          if (mark) {
            return mark.attrs.color;
          } else {
            return undefined;
          }
        };
      }
    };
  }
}
