import { Schema } from "prosemirror-model";
import { Plugin } from "prosemirror-state";

export interface ExtensionPlugins<S extends Schema> {
  plugins?: (schema: S) => Plugin[];
}

export function extensionPlugins<S extends Schema>(
  extensions: ExtensionPlugins<S>[],
  schema: S
): Plugin[] {
  return extensions.reduce((acc, elem) => {
    const extension = elem.plugins;
    if (extension) {
      const plugins = extension.bind(elem)(schema);
      return acc.concat(plugins);
    } else {
      return acc;
    }
  }, new Array<Plugin>());
}
