import { Schema } from "prosemirror-model";
import { Plugin } from "prosemirror-state";
import {
  CommandConfigurations,
  Extension,
  NodeConfig
} from "../../editor/extension";
import { IdGenerator, UniqueIdGenerator } from "../../util/id-generator";
import {
  blockSelector,
  commands as blockSelectorCommands
} from "./block-selector";
import {
  commands as forkSelectorCommands,
  ConfigurationItem,
  forkSelector
} from "./fork-selector";
import {
  commands as inlineSelectorCommands,
  inlineSelector,
  RangeMarkerNode
} from "./inline-selector";
import {
  commands as inputSelectorCommands,
  inputSelector
} from "./input-selector";
import { RangeSelection } from "./types";

export class Logic implements Extension<Schema> {
  constructor(
    private onInputSelectorChange: (id: string | null) => void,
    private onBlockSelectorChange: (ranges: RangeSelection[]) => void,
    private onInlineSelectorChange: (ranges: RangeSelection[]) => void,
    private onForkSelectorChange: (items: ConfigurationItem[]) => void,
    private idGenerator: IdGenerator = new UniqueIdGenerator()
  ) {}

  get name(): string {
    return "logic";
  }

  get nodes(): NodeConfig[] {
    return [new RangeMarkerNode()];
  }

  plugins(): Plugin[] {
    return [
      inputSelector(this.onInputSelectorChange),
      blockSelector(this.onBlockSelectorChange, this.idGenerator),
      inlineSelector(this.onInlineSelectorChange, this.idGenerator),
      forkSelector(this.onForkSelectorChange)
    ];
  }

  commands(schema: Schema): CommandConfigurations<Schema> {
    return {
      ...inputSelectorCommands(schema),
      ...blockSelectorCommands(schema),
      ...inlineSelectorCommands(schema),
      ...forkSelectorCommands(schema)
    };
  }
}
