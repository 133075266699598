import { Editor } from "editor";
import { Node, Schema } from "prosemirror-model";
import { NodeSelection, Plugin } from "prosemirror-state";
import { EditorView, NodeView } from "prosemirror-view";
import {
  ActionControlButton,
  ActionControls
} from "../../../editor/plugins/action-controls";
import { editableKey } from "../../../editor/plugins/editable";
import { selectionFocusKey } from "../../../editor/plugins/selection-focus";
import { ValidationMessagesNodeView } from "../../../editor/plugins/validation-messages";
import {
  GetTranslationFn,
  LanguageObserver
} from "../../../extensions/localization/util";
import { ConstantSumType } from "../schema";

export function nodeViewPlugin() {
  return new Plugin({
    props: {
      nodeViews: {
        constantSum: (node, view, _getPos) => {
          return new ConstantSumNodeView(node, view);
        }
      },
      handleClickOn(view, _pos, node, nodePos, event, direct) {
        const { state } = view;

        const editable = editableKey.getState(state);
        if (editable && editable.focusable === false) {
          return false;
        }

        const { schema } = state;
        const target = event.target as Element;
        const isInContent = target.closest("constant-sum-content") != null;
        const isSelectable =
          node.type.spec.selectable && node.type === schema.nodes.constantSum;

        if (isSelectable && direct && !isInContent) {
          const tr = state.tr.setSelection(
            new NodeSelection(state.doc.resolve(nodePos))
          );
          view.dispatch(tr);
          return true;
        } else {
          return false;
        }
      }
    }
  });
}

export class ConstantSumNodeView<S extends Schema> implements NodeView<S> {
  dom: HTMLElement;
  contentDOM: HTMLElement;

  private languageObserver: LanguageObserver<S>;

  private validationMessages: ValidationMessagesNodeView;
  private prefixDom: HTMLElement;
  private valueDom: HTMLElement;
  private suffixDom: HTMLElement;
  private actionControls: ActionControls;
  private requiredButton: ActionControlButton;

  constructor(private node: Node<S>, private view: EditorView<S>) {
    this.languageObserver = new LanguageObserver(view, (getTranslation) => {
      this.updateRequired(this.node.attrs.required, getTranslation);
    });

    const container = document.createElement("constant-sum");
    this.validationMessages = new ValidationMessagesNodeView(false);

    const constantSumContainer = this.createConstantSum();

    const requiredButton = new ActionControlButton(
      view,
      { icon: "asterisk", title: "ACTION_BUTTONS.REQUIRED.TITLE" },
      false,
      () => {
        const editor = this.view as Editor<S>;
        const focused = selectionFocusKey.getState(editor.state);

        if (focused != null && focused.node.type === this.node.type) {
          const { node } = focused;
          const required = !node.attrs.required;
          editor.commands.updatedConstantSum.execute({ required: required });
          editor.commands.insertConstantSum.execute();
        }
      }
    );

    const actionControls = new ActionControls([requiredButton]);

    container.appendChild(this.validationMessages.dom);
    container.appendChild(constantSumContainer.dom);
    container.appendChild(actionControls.dom);

    this.dom = container;
    this.contentDOM = constantSumContainer.contentDom;
    this.prefixDom = constantSumContainer.prefixDom;
    this.valueDom = constantSumContainer.valueDom;
    this.suffixDom = constantSumContainer.suffixDom;
    this.actionControls = actionControls;
    this.requiredButton = requiredButton;

    const getTranslation = this.languageObserver.getTranslation;

    this.updateId(node);
    this.updateRequired(node.attrs.required, getTranslation);
    this.updatePrefix(node.attrs.prefix);
    this.updateValue(node);
    this.updateSuffix(node.attrs.suffix);
  }

  update(node: Node<S>): boolean {
    if (node.type !== this.node.type) {
      return false;
    }

    this.node = node;

    const getTranslation = this.languageObserver.getTranslation;

    this.updateId(node);
    this.updateRequired(node.attrs.required, getTranslation);
    this.updatePrefix(node.attrs.prefix);
    this.updateValue(node);
    this.updateSuffix(node.attrs.suffix);

    return true;
  }

  ignoreMutation(
    mutation:
      | MutationRecord
      | {
          type: "selection";
          target: Element;
        }
  ): boolean {
    const validationMessagesIgnoreMutation = this.validationMessages.ignoreMutation(
      mutation
    );
    const requiredButtonIgnoreMutation = this.requiredButton.ignoreMutation(
      mutation
    );

    return [
      validationMessagesIgnoreMutation,
      requiredButtonIgnoreMutation
    ].some((x) => x);
  }

  destroy() {
    this.languageObserver.destroy();
    this.actionControls.destroy();
  }

  private updateId(node: Node<S>): void {
    this.dom.id = node.attrs.id;
  }

  private updateRequired(
    required: boolean,
    getTranslation: GetTranslationFn
  ): void {
    this.validationMessages.setRequired(required, false, getTranslation);
    this.requiredButton.setActive(required);
  }

  private updatePrefix(prefix: string | null): void {
    this.prefixDom.innerText = prefix ?? "";
    if (prefix == null || prefix.length === 0) {
      this.prefixDom.style.display = "none";
    } else {
      this.prefixDom.style.display = "";
    }
  }

  private updateValue(node: Node<S>): void {
    const value = node.attrs.maxValue
      ? node.attrs.type === ConstantSumType.sum
        ? 0
        : node.attrs.maxValue
      : 0;
    this.valueDom.innerText = value;
  }

  private updateSuffix(suffix: string | null): void {
    this.suffixDom.innerText = suffix ?? "";
    if (suffix == null || suffix.length === 0) {
      this.suffixDom.style.display = "none";
    } else {
      this.suffixDom.style.display = "";
    }
  }

  private createConstantSum(): {
    dom: HTMLElement;
    contentDom: HTMLElement;
    prefixDom: HTMLElement;
    valueDom: HTMLElement;
    suffixDom: HTMLElement;
  } {
    const container = document.createElement("div");
    container.className = "constant-sum-container";

    const valueContainer = document.createElement("div");
    valueContainer.className = "constant-sum-value-container";

    const sumLabel = document.createElement("constant-sum-content");
    container.appendChild(sumLabel);

    const prefix = document.createElement("div");
    prefix.className = "constant-sum-decorator";
    valueContainer.appendChild(prefix);

    const value = document.createElement("div");
    value.className = "sum-value";
    valueContainer.appendChild(value);

    const suffix = document.createElement("div");
    suffix.className = "constant-sum-decorator";
    valueContainer.appendChild(suffix);

    container.appendChild(valueContainer);

    return {
      dom: container,
      contentDom: sumLabel,
      prefixDom: prefix,
      valueDom: value,
      suffixDom: suffix
    };
  }
}
