import { Schema } from "prosemirror-model";
import { Plugin, PluginKey } from "prosemirror-state";
import { TranslateParser } from "../parser";
import { Translations } from "../types";

interface LocalizationState {
  language: string;
  translations: Translations | undefined;
  parser: TranslateParser;
}

export const languagePickerKey = new PluginKey<LocalizationState, Schema>();

export function languagePicker(
  defaultLanguage: string,
  translations: Map<string, Translations>,
  parser: TranslateParser
) {
  return new Plugin<LocalizationState, Schema>({
    key: languagePickerKey,
    state: {
      init() {
        return {
          language: defaultLanguage,
          translations: translations.get(defaultLanguage),
          parser: parser
        };
      },
      apply(tr, value) {
        const meta = tr.getMeta(languagePickerKey) as
          | { language: string }
          | undefined;

        if (meta == null) {
          return value;
        }

        const { language } = meta;
        if (language == null) {
          return value;
        }

        return {
          ...value,
          language: language,
          translations: translations.get(language)
        };
      }
    },
    props: {
      attributes(state) {
        const localization = this.getState(state);
        return { lang: localization.language };
      }
    }
  });
}
