import { Node, NodeSpec } from "prosemirror-model";
import { DocumentBuilders, NodeConfig } from "../../../editor/extension/schema";
import { AlignmentType, getAlignment } from "../../alignment";

export type EnableNextButton =
  | "always"
  | "required-answered"
  | "all-answered"
  | "minimum-answered";

export type EnablePreviousButton = "always" | "disabled" | "hidden";

function setPageBreakAttrs(node: Node): { [key: string]: string } {
  return {
    id: node.attrs.id,
    "data-enable-next": node.attrs.enableNext,
    "data-enable-next-minimum": node.attrs.enableNextMinimum,
    "data-enable-previous": node.attrs.enablePrevious,
    "data-alignment": node.attrs.alignment
  };
}

function getPageBreakAttrs(
  element: Element,
  legacyAlignmentAttribute?: string
): { [key: string]: any } {
  const attrs: {
    id?: string;
    enableNext?: EnableNextButton;
    enableNextMinimum?: number;
    enablePrevious?: EnablePreviousButton;
    alignment?: AlignmentType;
  } = {};

  const id = element.getAttribute("id");
  if (id != null) {
    attrs.id = id;
  }

  const enableNext = element.getAttribute(
    "data-enable-next"
  ) as EnableNextButton;

  switch (enableNext) {
    case "all-answered":
    case "always":
    case "required-answered":
    case "minimum-answered":
      attrs.enableNext = enableNext;
      break;
    default:
      break;
  }

  const enableNextMinimumAttr = element.getAttribute(
    "data-enable-next-minimum"
  );
  if (enableNextMinimumAttr != null) {
    const enableNextMinimum = parseInt(enableNextMinimumAttr, 10);
    if (enableNextMinimum != null) {
      attrs.enableNextMinimum = enableNextMinimum;
    }
  }

  const enablePrevious = element.getAttribute(
    "data-enable-previous"
  ) as EnablePreviousButton;

  switch (enablePrevious) {
    case "always":
    case "disabled":
    case "hidden":
      attrs.enablePrevious = enablePrevious;
      break;
    default:
      break;
  }

  const alignment = getAlignment(
    element.getAttribute(
      legacyAlignmentAttribute != null
        ? legacyAlignmentAttribute
        : "data-alignment"
    )
  );
  if (alignment != null) {
    attrs.alignment = alignment;
  }

  return attrs;
}

export class PageBreakNode implements NodeConfig {
  get name(): string {
    return "pageBreak";
  }

  get spec(): NodeSpec {
    return {
      group: "rootBlock",
      content:
        "previousPageBreakButton{1} nextPageBreakButton{1} submitPageBreakButton{1}",
      draggable: true,
      selectable: true,
      focusable: true,
      isolating: true,
      allowGapCursor: true,
      allowIndentation: false,
      allowAlignment: ["left", "center", "right"],
      defaultAlignment: () => "right",
      blockSelection: true,
      attrs: {
        id: { default: null },
        enableNext: { default: "always" },
        enableNextMinimum: { default: null },
        enablePrevious: { default: "always" },
        alignment: { default: null }
      },
      parseDOM: [
        {
          tag: "page-break",
          getAttrs(node) {
            if (typeof node === "string") {
              return false;
            }
            return getPageBreakAttrs(node as Element);
          }
        },
        {
          tag: "[data-page-break]",
          getAttrs(node) {
            if (typeof node === "string") {
              return false;
            }
            return getPageBreakAttrs(node as Element, "data-align");
          }
        }
      ],
      toDOM(node) {
        return ["page-break", setPageBreakAttrs(node), 0];
      }
    };
  }

  get builders(): DocumentBuilders {
    return { pageBreak: { nodeType: "pageBreak" } };
  }
}
