import { Node, Schema } from "prosemirror-model";
import { Decoration, NodeView } from "prosemirror-view";

export class InputRankOptionsNodeView<S extends Schema> implements NodeView<S> {
  dom: HTMLElement;
  contentDOM: HTMLElement;

  constructor(private node: Node<S>) {
    const dropdownOptions = document.createElement(
      "input-rank-options-dropdown"
    );
    const options = document.createElement("input-rank-options");

    dropdownOptions.appendChild(options);

    this.dom = options;
    this.contentDOM = options;
  }

  ignoreMutation(
    mutation:
      | MutationRecord
      | {
          type: "selection";
          target: Element;
        }
  ): boolean {
    const ignoreStyle =
      mutation.type === "attributes" &&
      mutation.attributeName === "style" &&
      mutation.target === this.dom;

    return ignoreStyle;
  }

  update(node: Node<S>, _decorations: Decoration[]): boolean {
    if (node.type !== this.node.type) {
      return false;
    }

    return true;
  }
}

export interface InputRankOptionsProperties {
  width: number | null;
  left: number;
  top: number;
}
