import { Slice } from "prosemirror-model";
import { Plugin } from "prosemirror-state";
import { InputScaleSchema } from "../schema";
import { focusedInputScale } from "../util";

export function pastePlugin(schema: InputScaleSchema) {
  return new Plugin<null, InputScaleSchema>({
    props: {
      handlePaste(view, _event, slice) {
        const { state, dispatch } = view;
        const focused = focusedInputScale(state);
        if (focused == null) {
          return false;
        }

        if (!isPastingValues(slice, schema)) {
          return false;
        }

        if (dispatch) {
          let tr = state.tr;
          tr = tr.deleteSelection();

          const { selection } = tr;
          const { empty, $from } = selection;
          const isAtStart = empty && $from.parentOffset === 0;

          if (isAtStart) {
            const pos = $from.before($from.depth);
            tr = tr.insert(pos, slice.content);
          } else {
            tr = tr.insert($from.pos, slice.content);
          }

          dispatch(tr);
        }
        return true;
      }
    }
  });
}

function isPastingValues(
  slice: Slice<InputScaleSchema>,
  schema: InputScaleSchema
): boolean {
  const firstChild = slice.content.firstChild;
  const lastChild = slice.content.lastChild;

  if (firstChild != null && lastChild != null) {
    return (
      firstChild.type === schema.nodes.inputScaleValue &&
      lastChild.type === schema.nodes.inputScaleValue
    );
  } else {
    return false;
  }
}
