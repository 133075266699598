function uuid() {
  // do not include hyphens because of Application Insights restriction
  return "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    /* tslint:disable:no-bitwise */
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;

    return v.toString(16).toUpperCase();
  });
}

export interface IdGenerator {
  generateId(): string;
}

export class UniqueIdGenerator implements IdGenerator {
  generateId(): string {
    return uuid();
  }
}

export class SequenceIdGenerator implements IdGenerator {
  private _id: number | null = null;

  generateId(): string {
    if (this._id == null) {
      this._id = 0;
      return `${this._id}`;
    }
    this._id = this._id + 1;
    return `${this._id}`;
  }
}
