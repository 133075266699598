import { Fragment, Node, ResolvedPos, Schema } from "prosemirror-model";
import {
  NodeSelection,
  Selection,
  TextSelection,
  Transaction
} from "prosemirror-state";
import { TableMap } from "prosemirror-tables";
import { ReplaceAroundStep, ReplaceStep } from "prosemirror-transform";
import { IdGenerator } from "./id-generator";
import { ContentNodeWithPos, findParent, NodeWithPos } from "./nodes";
import { canReplaceWithNode, findPositionOfNodeBefore } from "./selection";

export function findNodePos<S extends Schema>(
  doc: Node<S>,
  pos: number,
  node: Node<S>
): ResolvedPos<S> | undefined {
  const $pos = doc.resolve(pos);

  if (doc.nodeAt($pos.pos) === node) {
    return $pos;
  } else {
    const start = $pos.start(0);
    const end = $pos.end(0);

    let offset = 1;

    while (pos - offset >= start || pos + offset <= end) {
      const before = pos - offset;
      if (before >= start) {
        const $before = doc.resolve(before);
        if (doc.nodeAt($before.pos) === node) {
          return $before;
        }
      }
      const after = pos + offset;
      if (after <= end) {
        const $after = doc.resolve(after);
        if (doc.nodeAt($after.pos) === node) {
          return $after;
        }
      }

      offset = offset + 1;
    }
  }

  return undefined;
}

export function insertBlock<S extends Schema>(
  tr: Transaction<S>,
  schema: S,
  node: Node<S>,
  cursorInside: boolean,
  idGenerator: IdGenerator,
  addQuestionTitle: boolean = false,
  doNodeSelection: boolean = false
): Transaction<S> {
  tr = tr.deleteSelection();
  tr = tr.replaceSelectionWith(node, false);

  const $pos = findNodePos(tr.doc, tr.selection.from, node);

  if ($pos) {
    const repeatedGrid = findParent($pos, (n) => {
      return n.type === schema.nodes.table && n.attrs.repeatGrid === true;
    });

    const depth = $pos.depth;
    const parentNode = $pos.node(depth);
    const index = $pos.index(depth);
    const nextIndex = index + 1;

    const nextChild = parentNode.maybeChild(nextIndex);
    const supportedNodes = [schema.nodes.paragraph, schema.nodes.headings];
    const shouldAddParagraphAfter =
      !nextChild || !supportedNodes.includes(nextChild.type);

    const posNode = $pos.posAtIndex(index, depth);
    const pos = $pos.posAtIndex(nextIndex, depth);
    if (shouldAddParagraphAfter && repeatedGrid == null) {
      const paragraph = schema.nodes.paragraph.create() as Node<S>;
      tr = tr.insert(pos, paragraph);
    }

    if (cursorInside) {
      tr = tr.setSelection(
        doNodeSelection
          ? new NodeSelection(tr.doc.resolve(posNode))
          : TextSelection.near(tr.doc.resolve(posNode))
      );
    } else {
      tr = tr.setSelection(TextSelection.near(tr.doc.resolve(pos)));
    }

    if (addQuestionTitle) {
      if (repeatedGrid != null) {
        tr = addQuestionTitleBindingsInGrid(tr, schema, repeatedGrid, {
          node: node,
          pos: $pos.pos
        });
      } else {
        tr = insertQuestion(tr, node, schema, idGenerator);
      }
    }
  }

  tr = tr.scrollIntoView();

  return tr;
}

export function addQuestionTitleBindings<S extends Schema>(
  tr: Transaction<S>,
  input: NodeWithPos<S>,
  questionTitles: Node<S>[]
): Transaction<S> {
  const questionTitleRefElementId = questionTitles.map(
    (questionTitle) => questionTitle.attrs.id
  );

  tr = tr.setNodeMarkup(input.pos, undefined, {
    ...input.node.attrs,
    questionTitleRefElementId: questionTitleRefElementId
  });

  return tr;
}

export function addQuestionTitleBindingsInGrid<S extends Schema>(
  tr: Transaction<S>,
  schema: S,
  table: ContentNodeWithPos<S>,
  input: NodeWithPos<S>
): Transaction<S> {
  const { doc } = tr;

  const { node, start } = table;
  const $pos = doc.resolve(input.pos);

  const map = TableMap.get(node);
  const cellPos = $pos.before() - start;
  const cell = map.findCell(cellPos);

  const titles = new Array<Node<S>>();

  const rowTitleCell = map.positionAt(cell.top, 0, node);
  const rowNode = node.nodeAt(rowTitleCell);
  if (rowNode != null && rowNode.type === schema.nodes.tableCell) {
    titles.push(rowNode);
  }

  const columnTitleCell = map.positionAt(0, cell.left, node);
  const columnNode = node.nodeAt(columnTitleCell);
  if (columnNode != null) {
    titles.push(columnNode);
  }

  tr = addQuestionTitleBindings(tr, input, titles);

  return tr;
}

function insertQuestion<S extends Schema>(
  tr: Transaction<S>,
  node: Node<S>,
  schema: S,
  idGenerator: IdGenerator
): Transaction<S> {
  const $pos = findNodePos(tr.doc, tr.selection.from, node);
  if ($pos) {
    const id = idGenerator.generateId();
    let questionTitle = schema.nodes.headings.create({
      id,
      level: 6
    }) as Node<S>;

    const depth = $pos.depth;
    const index = $pos.index(depth);
    const posNode = $pos.posAtIndex(index, depth);
    const target = $pos.node(depth);

    if (
      target.canReplace(index, index, Fragment.from(questionTitle)) !== true
    ) {
      questionTitle = schema.nodes.paragraph.create({
        id
      }) as Node<S>;
    }

    tr.insert(posNode, questionTitle);
    tr = addQuestionTitleBindings(
      tr,
      { pos: posNode + questionTitle.nodeSize, node },
      [questionTitle]
    );

    tr = tr.setSelection(TextSelection.near(tr.doc.resolve(posNode)));
  }

  return tr;
}

function removeNodeAtPos<S extends Schema>(position: number, schema: S) {
  return (tr: Transaction<S>) => {
    const node = tr.doc.nodeAt(position);
    if (node == null) {
      return tr;
    } else {
      const $pos = tr.doc.resolve(position);
      if (node.isBlock && canReplaceWithNode($pos, schema.nodes.paragraph)) {
        tr = tr.replaceWith(
          position,
          position + node.nodeSize,
          schema.nodes.paragraph.create() as Node<S>
        );
      } else {
        tr = tr.delete(position, position + node.nodeSize);
      }

      tr = tr.setSelection(
        Selection.near(tr.doc.resolve(tr.mapping.map(position)))
      );
      tr = tr.scrollIntoView();
      return tr;
    }
  };
}

export function removeNodeBefore<S extends Schema>(
  tr: Transaction<S>,
  schema: S
): Transaction<S> {
  const position = findPositionOfNodeBefore(tr.selection);
  if (typeof position === "number") {
    return removeNodeAtPos<S>(position, schema)(tr);
  }
  return tr;
}

export function selectionToInsertionEnd<S extends Schema>(
  tr: Transaction<S>,
  startLen: number,
  bias: -1 | 1
): Transaction<S> {
  let last = tr.steps.length - 1;
  if (last < startLen) {
    return tr;
  }

  let step = tr.steps[last];
  if (!(step instanceof ReplaceStep || step instanceof ReplaceAroundStep)) {
    return tr;
  }

  let map = tr.mapping.maps[last];
  let end: number | undefined = undefined;

  map.forEach((_from, _to, _newFrom, newTo) => {
    if (end == null) {
      end = newTo;
    }
  });

  if (end != null) {
    tr = tr.setSelection(Selection.near(tr.doc.resolve(end), bias));
  }

  return tr;
}
