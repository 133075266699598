import {
  Editor as BlueXEditor,
  InputTextActiveValue,
  InputTextControlType,
  InputTextWidth,
  Meanings,
  Schema,
  textFormats
} from "@blue-x/editor";
import React, { useCallback, useMemo } from "react";
import {
  CheckboxFormControl,
  NumberFormControl,
  QuestionTitleFormControl,
  SelectFormControl,
  TextFormControl
} from "../ProportiesPanelControls";
import "./InputText.css";

interface InputTextPropertiesPanelProps {
  editor: BlueXEditor<Schema>;
}

export const InputTextPropertiesPanel: React.FC<InputTextPropertiesPanelProps> = (
  props
) => {
  const { editor } = props;
  const { commands } = editor;

  const update = useCallback(
    (value) => {
      commands.updateInputText.execute(value, false);
    },
    [commands]
  );

  const activeValue: InputTextActiveValue = useMemo(() => {
    return commands.updateInputText.activeValue();
  }, [commands]);

  return (
    <div className="InputTextPropertiesPanel">
      <SelectFormControl
        label={"Type"}
        value={activeValue.controlType}
        onUpdate={(value) => {
          update({ controlType: value });
        }}
      >
        <option value={InputTextControlType.textbox}>
          Single Line Response
        </option>
        <option value={InputTextControlType.textarea}>
          Multiple Lines Response
        </option>
      </SelectFormControl>

      <NumberFormControl
        label={"Number of Lines"}
        value={activeValue.rows}
        min={1}
        step={1}
        onUpdate={(value) => {
          update({ rows: value });
        }}
      />

      <NumberFormControl
        label={"Width"}
        value={activeValue.width}
        min={InputTextWidth.minimum}
        max={InputTextWidth.maximum}
        step={1}
        onUpdate={(value) => {
          update({ width: value });
        }}
      />

      <QuestionTitleFormControl
        editor={editor}
        label={"Question title*"}
        value={activeValue.questionTitle}
      />

      <TextFormControl
        label={"Description"}
        value={activeValue.description}
        onUpdate={(value) => {
          update({ description: value });
        }}
      />

      <TextFormControl
        label={"Coding"}
        value={activeValue.coding}
        onUpdate={(value) => {
          update({ coding: value });
        }}
      />

      <TextFormControl
        label={"Default Value"}
        value={activeValue.defaultValue}
        onUpdate={(value) => {
          update({ defaultValue: value });
        }}
      />

      <TextFormControl
        label={"Placeholder"}
        value={activeValue.watermark}
        onUpdate={(value) => {
          update({ watermark: value });
        }}
      />

      <SelectFormControl
        label={"Meaning"}
        value={activeValue.meaning}
        onUpdate={(value) => {
          update({ meaning: value });
        }}
      >
        <option value={""}>-</option>
        {Meanings.map((meaning) => (
          <option key={meaning.value} value={meaning.value}>
            {meaning.label}
          </option>
        ))}
      </SelectFormControl>

      <NumberFormControl
        label={"Max Characters"}
        value={activeValue.textLength}
        min={1}
        step={1}
        onUpdate={(value) => {
          update({ textLength: value });
        }}
      />

      <SelectFormControl
        label={"Text Format"}
        value={activeValue.format}
        onUpdate={(value) => {
          update({ format: value });
        }}
      >
        {textFormats.map((format) => (
          <option key={format.id} value={format.id}>
            {format.label}
          </option>
        ))}
      </SelectFormControl>

      <TextFormControl
        label={"Regexp"}
        value={activeValue.regexp}
        disabled={activeValue.format === "none"}
        onUpdate={(value) => {
          update({ regexp: value });
        }}
      />

      <CheckboxFormControl
        label={"Required"}
        value={activeValue.required}
        onUpdate={(value) => {
          update({ required: value });
        }}
      />
    </div>
  );
};
