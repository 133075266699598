import { Schema } from "prosemirror-model";
import { NodeSelection } from "prosemirror-state";
import {
  CommandConfiguration,
  CommandConfigurations,
  Extension
} from "../../editor";
import {
  getVerticalAlignmentNodes,
  isVerticalAlignmentActive,
  isVerticalAlignmentEnabled
} from "./util";
import { availableVerticalAlignment, VerticalAlignmentType } from "./values";

interface VerticalAlignmentCommandProps {
  alignment: VerticalAlignmentType;
}

export class VerticalAlignment implements Extension<Schema> {
  constructor(private defaultAlignment: VerticalAlignmentType = "top") {}

  get name(): string {
    return "verticalAlignment";
  }

  commands(schema: Schema): CommandConfigurations<Schema> {
    return {
      verticalAlignment: this.verticalAlignmentCommand(schema)
    };
  }

  private verticalAlignmentCommand(
    _schema: Schema
  ): CommandConfiguration<
    Schema,
    VerticalAlignmentCommandProps,
    VerticalAlignmentType
  > {
    return {
      isActive: (props) => {
        const alignment = props?.alignment;
        if (alignment == null) {
          return false;
        }

        return isVerticalAlignmentActive(alignment, this.defaultAlignment);
      },
      isEnabled: (props) => {
        const alignment = props?.alignment;
        if (alignment == null) {
          return (state) => {
            return availableVerticalAlignment.every((alignment) =>
              isVerticalAlignmentEnabled(alignment)(state)
            );
          };
        }

        return isVerticalAlignmentEnabled(alignment);
      },
      execute: (props) => {
        const alignment = props?.alignment;

        if (
          alignment != null &&
          availableVerticalAlignment.includes(alignment)
        ) {
          return (state, dispatch) => {
            const { nodes, count } = getVerticalAlignmentNodes(
              alignment,
              this.defaultAlignment
            )(state);

            const shouldRemove = count === nodes.length;
            let tr = state.tr;

            nodes.forEach(({ node, pos }) => {
              let newAlignment: VerticalAlignmentType | null;
              if (shouldRemove) {
                newAlignment = null;
              } else {
                if (alignment === this.defaultAlignment) {
                  newAlignment = null;
                } else {
                  newAlignment = alignment;
                }
              }

              tr = tr.setNodeMarkup(
                pos,
                node.type,
                {
                  ...node.attrs,
                  verticalAlignment: newAlignment
                },
                node.marks
              );
            });

            const { selection } = state;
            if (selection instanceof NodeSelection) {
              tr = tr.setSelection(
                new NodeSelection(tr.doc.resolve(selection.anchor))
              );
            }

            if (dispatch) {
              dispatch(tr);
            }

            return true;
          };
        } else {
          throw new Error(
            `To set vertical alignment, VerticalAlignmentCommandProps need to be provided.`
          );
        }
      }
    };
  }
}
