import { Editor as BlueXEditor, Schema } from "@blue-x/editor";
import React, { useCallback, useMemo, useState } from "react";
import ToolbarDropdown, {
  ToolbarDropdownToggle,
  useToolbarDropdown
} from "../ToolbarDropdown";
import ToolbarIcon from "../ToolbarIcon";
import ToolbarTooltip from "../ToolbarTooltip";
import "./Grid.css";

interface ToolbarGridButtonProps {
  editor: BlueXEditor<Schema>;
}

const ToolbarGridDropdown: React.FC<ToolbarGridButtonProps> = (props) => {
  const { editor } = props;
  const { commands } = editor;
  const dropdown = useToolbarDropdown();

  const [rows, setRows] = useState(3);
  const [columns, setColumns] = useState(3);

  const updateRow = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.valueAsNumber;

      if (!isNaN(value)) {
        setRows(value);
      }
    },
    [setRows]
  );

  const updateColumn = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.valueAsNumber;

      if (!isNaN(value)) {
        setColumns(value);
      }
    },
    [setColumns]
  );

  const insertGrid = useCallback(() => {
    commands.insertGrid.execute({ rows: rows, columns: columns });
    const { opened, setOpened } = dropdown;
    setOpened(!opened);
  }, [commands, rows, columns, dropdown]);

  const insertGridDisabled = useMemo(() => {
    if (rows <= 0) {
      return true;
    } else if (columns <= 0) {
      return true;
    } else {
      return !commands.insertGrid?.isEnabled();
    }
  }, [commands, rows, columns]);

  return (
    <div className="ToolbarGridSizePicker">
      <input
        type="number"
        placeholder="row"
        value={rows}
        onChange={updateRow}
      />
      <input
        type="number"
        placeholder="column"
        value={columns}
        onChange={updateColumn}
      />
      <button onClick={insertGrid} disabled={insertGridDisabled}>
        Insert
      </button>
    </div>
  );
};

export const ToolbarGridButton: React.FC<ToolbarGridButtonProps> = (props) => {
  const { editor } = props;
  const { commands } = editor;

  return (
    <ToolbarDropdown
      toggle={
        <ToolbarTooltip
          title="Grid"
          subtitle="Insert a grid."
          shortcut={commands.insertGrid?.shortcut({ rows: 2, columns: 2 })}
        >
          <ToolbarDropdownToggle
            active={commands.insertGrid?.isActive()}
            disabled={!commands.insertGrid?.isEnabled()}
            drag={{
              event: "insert",
              type: "customGrid"
            }}
          >
            <ToolbarIcon icon="smart-table" />
          </ToolbarDropdownToggle>
        </ToolbarTooltip>
      }
      content={<ToolbarGridDropdown editor={editor} />}
    />
  );
};
