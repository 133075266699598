import { Command } from "prosemirror-commands";
import { Transaction } from "prosemirror-state";
import { editableKey } from "./editable";
import { Editable, Uneditable } from "./types";

export function editableTransaction(
  tr: Transaction,
  value: Editable | Uneditable
): Transaction {
  tr.setMeta(editableKey, value);

  return tr;
}

export function setEditable(value: Editable | Uneditable): Command {
  return (state, dispatch) => {
    if (dispatch) {
      dispatch(editableTransaction(state.tr, value));
    }

    return true;
  };
}
