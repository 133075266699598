import { Node, Schema } from "prosemirror-model";
import { EditorState } from "prosemirror-state";
import {
  BarChartIndexAxis,
  BarChartQuestionType,
  BarChartSchema,
  BarChartStyle,
  BarChartSegment,
  BarChartRankConfigurationSegmentType,
  SegmentColor,
  BarChartItem,
  BarChartSegmentType
} from "./schema";
import { selectionFocusKey } from "../../editor/plugins/selection-focus";
import {
  findChildrenByType,
  IdGenerator,
  UnreachableCaseError
} from "../../util";
import { SkinId } from "../document-skin";
import { ChartOptions } from "chart.js/auto";
import { SeededRandom } from "../../util/randomizer";
import { ColorPaletteType, Color } from "../color-scheme/types";

export const BAR_WIDTH_XL = 48;
export const IMAGE_SIZE_HORIZONTAL_MODE = 100;
export const SPACE_BETWEEN_TICKS_HORIZONTAL_MODE_WITH_IMG = 140;
export const DEFAULT_SPACE_BETWEEN_TICKS_HORIZONTAL_MODE = 70;
export const SPACE_BETWEEN_TICKS_VERTICAL_MODE = 100;
export const INPUT_MAX_WIDTH = 888;
export const MAX_BARS_BEFORE_SCROLL = 8;
export const MAX_COUNT_MARGIN_WEIGHT = 1.1;
export const COUNT_MAX_RANGE = [10, 20, 50, 100, 200, 500, 1000];
export const PERCENTAGE_MAX_RANGE = [10, 20, 50, 100];

export type ImageSize = {
  naturalWidth: number;
  naturalHeight: number;
};

export type LinearAxisConstraints = {
  max: number;
  stepSize: number;
};

export const commonOptions: ChartOptions<"bar"> = {
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      enabled: true,
      callbacks: {
        label: function (context: any) {
          const value = context.dataset.data[context.dataIndex];
          const dataLabel = context.label;
          return `${value} of the respondents selected ${dataLabel}.`;
        }
      }
    }
  }
};

export const optionsVertical: ChartOptions<"bar"> = {
  ...commonOptions,
  layout: {
    padding: {
      top: 20,
      bottom: 0,
      left: 0,
      right: 0
    }
  },
  scales: {
    x: {
      type: "category",
      grid: {
        display: false
      },
      ticks: {
        display: true,
        padding: 0,
        callback: () => ""
      }
    },
    y: {
      type: "linear",
      grid: {
        display: true,
        drawBorder: false
      },
      ticks: {
        display: true
      },
      position: "left",
      offset: false,
      min: 0
    }
  }
};

export const optionsHorizontal: ChartOptions<"bar"> = {
  ...commonOptions,
  indexAxis: "y",
  layout: {
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 50
    }
  },
  scales: {
    y: {
      type: "category",
      grid: {
        display: false,
        drawBorder: false
      },
      ticks: {
        display: false
      }
    },
    x: {
      type: "linear",
      grid: {
        display: true
      },
      ticks: {
        display: true
      },
      min: 0
    }
  }
};

//TO-DO: Connect with current color set
export interface reportColorSet {
  id: string;
  color: SegmentColor;
}

export const colorSet: reportColorSet[] = [
  {
    id: "cyan",
    color: {
      red: 0,
      green: 187,
      blue: 230,
      alpha: 1
    }
  },
  {
    id: "navy",
    color: {
      red: 27,
      green: 44,
      blue: 143,
      alpha: 1
    }
  },
  {
    id: "red",
    color: {
      red: 216,
      green: 94,
      blue: 94,
      alpha: 1
    }
  },
  {
    id: "yellow",
    color: {
      red: 222,
      green: 180,
      blue: 84,
      alpha: 1
    }
  },
  {
    id: "forest",
    color: {
      red: 71,
      green: 150,
      blue: 34,
      alpha: 1
    }
  },
  {
    id: "pink",
    color: {
      red: 226,
      green: 149,
      blue: 217,
      alpha: 1
    }
  },
  {
    id: "burgundy",
    color: {
      red: 143,
      green: 0,
      blue: 64,
      alpha: 1
    }
  },
  {
    id: "khaki",
    color: {
      red: 103,
      green: 118,
      blue: 76,
      alpha: 1
    }
  },
  {
    id: "orange",
    color: {
      red: 250,
      green: 130,
      blue: 95,
      alpha: 1
    }
  },
  {
    id: "sage",
    color: {
      red: 178,
      green: 216,
      blue: 173,
      alpha: 1
    }
  },
  {
    id: "emerald",
    color: {
      red: 0,
      green: 162,
      blue: 132,
      alpha: 1
    }
  },
  {
    id: "purple",
    color: {
      red: 199,
      green: 201,
      blue: 230,
      alpha: 1
    }
  },
  {
    id: "iron",
    color: {
      red: 138,
      green: 160,
      blue: 163,
      alpha: 1
    }
  },
  {
    id: "shadow",
    color: {
      red: 85,
      green: 107,
      blue: 110,
      alpha: 1
    }
  }
];

export function getColorSet(start: number, to: number): reportColorSet[] {
  return colorSet.filter((set, index) => {
    return index >= start && index <= start + to ? set : null;
  });
}

export function capitalizeFirstLetter(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function createBarChart(
  schema: BarChartSchema,
  questionType: BarChartQuestionType | null,
  type: BarChartIndexAxis
): Node<BarChartSchema> {
  const { barChart } = schema.nodes;

  return barChart.createChecked(
    {
      questionType,
      type
    },
    []
  );
}

export function focusedBarChart(state: EditorState<BarChartSchema>) {
  const { schema } = state;

  const focused = selectionFocusKey.getState(state);
  if (focused != null && focused.node.type === schema.nodes.barChart) {
    return focused;
  } else {
    return undefined;
  }
}

export function mapBarStyleToPX(style: BarChartStyle): number {
  switch (style) {
    case BarChartStyle.small:
      return 12;
    case BarChartStyle.medium:
      return 24;
    case BarChartStyle.large:
      return 48;
    default:
      throw new UnreachableCaseError(style);
  }
}

export function mapDomStyleToPX(style: SkinId): number {
  switch (style) {
    case "default":
      return 0;

    case "rounded":
      return 12;

    case "simple":
      return 5;

    default:
      throw new UnreachableCaseError(style);
  }
}

export function getRandomValueFromId(barIdWithCount: string): number {
  const seededRandom = new SeededRandom(barIdWithCount);
  const number = seededRandom.next(1, 100);
  return Math.trunc(number);
}

export function getRandomValue(multiplier: number): number {
  return Math.max(Math.floor(Math.random() * multiplier + 1));
}

export function mapNodeToAutomaticBarCharSegments(
  idGenerator: IdGenerator,
  node: Node,
  schema: Schema
): BarChartSegment[] {
  let segments: BarChartSegment[] = [];
  switch (node.type) {
    case schema.nodes.inputChoice:
    case schema.nodes.inputScale:
      node.content.forEach((choice) => {
        const images = findChildrenByType(choice, schema.nodes.image);
        segments.push({
          id: idGenerator.generateId(),
          type: BarChartSegmentType.items,
          items: [choice.attrs.id],
          range: null,
          color: null,
          label: choice.textContent,
          imageUrl: images.length > 0 ? images[0].node.attrs.src : ""
        });
      });
      break;

    case schema.nodes.inputNumber:
    case schema.nodes.inputDateTime:
    case schema.nodes.constantSum:
      for (let index = 0; index < 5; index++) {
        segments.push({
          id: idGenerator.generateId(),
          type: BarChartSegmentType.range,
          items: null,
          range: null,
          color: null
        });
      }
      break;

    case schema.nodes.inputRank:
      const options = node.lastChild;

      if (options) {
        options.content.forEach((option, _offset, index) => {
          const images = findChildrenByType(option, schema.nodes.image);
          segments.push({
            id: idGenerator.generateId(),
            type: BarChartSegmentType.items,
            items: [option.attrs.id],
            range: null,
            color: null,
            label: `R${index + 1}`,
            imageUrl: images.length > 0 ? images[0].node.attrs.src : ""
          });
        });
      }
      break;
    default:
      segments = [];
  }

  return segments;
}

export function newEmptySegment(idGenerator: IdGenerator): BarChartSegment {
  return {
    id: idGenerator.generateId(),
    type: BarChartSegmentType.items,
    items: null,
    range: null,
    label: "",
    color: null
  };
}

export function mapColorPaletteTypeToLabelColor(type: ColorPaletteType): Color {
  switch (type) {
    case "light":
      return new Color(85, 85, 85);

    case "dark":
      return new Color(240, 240, 240);

    default:
      throw new UnreachableCaseError(type);
  }
}

export function mapColorPaletteTypeToLineColor(type: ColorPaletteType): Color {
  switch (type) {
    case "light":
      return new Color(85, 85, 85, 0.3);

    case "dark":
      return new Color(240, 240, 240, 0.3);

    default:
      throw new UnreachableCaseError(type);
  }
}

export function updateSegments(
  segmentType: BarChartRankConfigurationSegmentType,
  options: BarChartItem[],
  segments: BarChartSegment[]
): BarChartSegment[] {
  const expectedSegments = segments;

  expectedSegments.forEach((segment, index) => {
    if (segmentType === BarChartRankConfigurationSegmentType.item) {
      segment.label = `R${index + 1}`;
    } else {
      segment.label = options[index].label;
    }
  });

  return expectedSegments;
}

export function mapColorToRgba(color: SegmentColor): string {
  return `rgba(${color.red},${color.green},${color.blue},${color.alpha})`;
}

export function setMinForSegment(
  id: string,
  segments: BarChartSegment[],
  min: number | string | null
): BarChartSegment[] {
  const expectedSegments = segments;

  expectedSegments.forEach((s) => {
    if (s.id === id && s.range) {
      s.range.min = min;
    } else if (s.id === id && s.range == null) {
      s.range = {
        min: min,
        max: null
      };
    }
  });

  return expectedSegments;
}

export function setMaxForSegment(
  id: string,
  segments: BarChartSegment[],
  max: number | string | null
): BarChartSegment[] {
  const expectedSegments = segments;

  expectedSegments.forEach((s) => {
    if (s.id === id && s.range) {
      s.range.max = max;
    } else if (s.id === id && s.range == null) {
      s.range = {
        min: null,
        max: max
      };
    }
  });

  return expectedSegments;
}
