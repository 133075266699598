import { toggleMark as prosemirrorToggleMark } from "prosemirror-commands";
import {
  Fragment,
  MarkType,
  Node,
  NodeType,
  ResolvedPos,
  Schema,
  Slice
} from "prosemirror-model";
import {
  EditorState,
  Selection,
  SelectionRange,
  TextSelection,
  Transaction
} from "prosemirror-state";
import { ReplaceAroundStep, Step } from "prosemirror-transform";
import { EditorView } from "prosemirror-view";
import { CommandFn } from "../editor/extension/command";
import { Focus } from "../editor/plugins/selection-focus";
import { removeAlignment } from "../extensions/alignment";
import { removeIndentation } from "../extensions/indentation";
import { textMarkIsActive } from "./marks";
import { findParent } from "./nodes";
import { findParentNodeOfType } from "./selection";
import { UnreachableCaseError } from "./unreachable-error";

export type ApplyAround = "word" | "paragraph";

const delimeters: RegExp[] = [new RegExp(/\W/)];

function findWordRange<S extends Schema>(
  $cursor: ResolvedPos<S>
): { from: number; to: number } {
  const doc = $cursor.doc;

  const start = $cursor.start();
  let from = $cursor.pos;

  while (start !== from) {
    const character = doc.textBetween(from - 1, from);
    if (delimeters.some((exp) => exp.exec(character))) {
      break;
    }
    from = from - 1;
  }

  const end = $cursor.end();
  let to = $cursor.pos;

  while (end !== to) {
    const character = doc.textBetween(to, to + 1);
    if (delimeters.some((exp) => exp.exec(character))) {
      break;
    }
    to = to + 1;
  }

  return { from: from, to: to };
}

function markApplies<S extends Schema>(
  doc: Node<S>,
  ranges: SelectionRange<S>[],
  type: MarkType<S>
) {
  for (let i = 0; i < ranges.length; i++) {
    let { $from, $to } = ranges[i];
    let can = $from.depth === 0 ? doc.type.allowsMarkType(type) : false;
    doc.nodesBetween($from.pos, $to.pos, (node) => {
      if (can) {
        return false;
      }
      can = node.inlineContent && node.type.allowsMarkType(type);
      return;
    });
    if (can) return true;
  }
  return false;
}

export function updateMark<S extends Schema>(
  markType: MarkType<S>,
  attrs?: { [key: string]: any },
  around: ApplyAround = "word"
): CommandFn<S> {
  return (
    state: EditorState<S>,
    dispatch?: (tr: Transaction<S>) => void
  ): boolean => {
    const { empty, $cursor, ranges } = state.selection as TextSelection<S>;
    if ((empty && !$cursor) || !markApplies(state.doc, ranges, markType)) {
      return false;
    }

    if (dispatch) {
      if ($cursor) {
        switch (around) {
          case "word":
            {
              if ($cursor.start() === $cursor.pos) {
                dispatch(state.tr.addStoredMark(markType.create(attrs)));
                return true;
              }

              if ($cursor.end() === $cursor.pos) {
                dispatch(state.tr.addStoredMark(markType.create(attrs)));
                return true;
              }

              const doc = state.doc;

              const characterBeforeCursor = doc.textBetween(
                $cursor.pos - 1,
                $cursor.pos
              );
              const characterAfterCursor = doc.textBetween(
                $cursor.pos,
                $cursor.pos + 1
              );

              if (
                delimeters.some((exp) => exp.exec(characterBeforeCursor)) ||
                delimeters.some((exp) => exp.exec(characterAfterCursor))
              ) {
                dispatch(state.tr.addStoredMark(markType.create(attrs)));
                return true;
              }

              const { from, to } = findWordRange($cursor);
              dispatch(state.tr.addMark(from, to, markType.create(attrs)));
            }
            break;
          case "paragraph":
            {
              const paragraph = findParentNodeOfType(
                state.schema.nodes.paragraph as NodeType<S>
              )(state.selection);
              if (!paragraph) {
                return false;
              }

              const doc = state.doc;
              const { from, to, empty } = new TextSelection(
                doc.resolve(
                  Selection.atStart(paragraph.node).from + paragraph.start
                ),
                doc.resolve(
                  Selection.atEnd(paragraph.node).to + paragraph.start
                )
              );

              if (empty) {
                dispatch(state.tr.addStoredMark(markType.create(attrs)));
              } else {
                dispatch(state.tr.addMark(from, to, markType.create(attrs)));
              }
            }
            break;
          default:
            throw new UnreachableCaseError(around);
        }
      } else {
        let tr = state.tr;
        const { ranges } = state.selection;

        ranges.forEach((range) => {
          const { $from, $to } = range;
          const from = $from.pos;
          const to = $to.pos;
          tr = tr.addMark(from, to, markType.create(attrs));
        });

        dispatch(tr);
      }
    }

    return true;
  };
}

export function removeMark<S extends Schema>(
  markType: MarkType<S>,
  around: ApplyAround = "word"
): CommandFn<S> {
  return (
    state: EditorState<S>,
    dispatch?: (tr: Transaction<S>) => void
  ): boolean => {
    const { empty, $cursor, ranges } = state.selection as TextSelection<S>;
    if ((empty && !$cursor) || !markApplies(state.doc, ranges, markType)) {
      return false;
    }

    if (dispatch) {
      if ($cursor) {
        switch (around) {
          case "word":
            {
              if ($cursor.start() === $cursor.pos) {
                dispatch(state.tr.removeStoredMark(markType));
                return true;
              }

              if ($cursor.end() === $cursor.pos) {
                dispatch(state.tr.removeStoredMark(markType));
                return true;
              }

              const doc = state.doc;

              const characterBeforeCursor = doc.textBetween(
                $cursor.pos - 1,
                $cursor.pos
              );
              const characterAfterCursor = doc.textBetween(
                $cursor.pos,
                $cursor.pos + 1
              );

              if (
                delimeters.some((exp) => exp.exec(characterBeforeCursor)) ||
                delimeters.some((exp) => exp.exec(characterAfterCursor))
              ) {
                dispatch(state.tr.removeStoredMark(markType));
                return true;
              }

              const { from, to } = findWordRange($cursor);
              dispatch(state.tr.removeMark(from, to, markType));
            }
            break;
          case "paragraph":
            {
              const paragraph = findParentNodeOfType(
                state.schema.nodes.paragraph as NodeType<S>
              )(state.selection);
              if (!paragraph) {
                return false;
              }

              const doc = state.doc;
              const { from, to, empty } = new TextSelection(
                doc.resolve(
                  Selection.atStart(paragraph.node).from + paragraph.start
                ),
                doc.resolve(
                  Selection.atEnd(paragraph.node).to + paragraph.start
                )
              );

              if (empty) {
                dispatch(state.tr.removeStoredMark(markType));
              } else {
                dispatch(state.tr.removeMark(from, to, markType));
              }
            }
            break;
          default:
            throw new UnreachableCaseError(around);
        }
      } else {
        let tr = state.tr;
        const { ranges } = state.selection;

        ranges.forEach((range) => {
          const { $from, $to } = range;
          const from = $from.pos;
          const to = $to.pos;
          tr = tr.removeMark(from, to, markType);
        });

        dispatch(tr);
      }
    }

    return true;
  };
}

export function removeMarks<S extends Schema>(
  marks: Array<MarkType<S>>,
  around: ApplyAround = "word"
): CommandFn<S> {
  return executeSequence(...marks.map((mark) => removeMark(mark, around)));
}

export function toggleMark<S extends Schema>(
  markType: MarkType<S>,
  attrs?: { [key: string]: any }
): CommandFn<S> {
  return (
    state: EditorState<S>,
    dispatch?: (tr: Transaction<S>) => void
  ): boolean => {
    const { empty, $cursor, ranges } = state.selection as TextSelection<S>;
    if ((empty && !$cursor) || !markApplies(state.doc, ranges, markType)) {
      return false;
    }

    if (dispatch) {
      if ($cursor) {
        if ($cursor.start() === $cursor.pos) {
          return prosemirrorToggleMark(markType, attrs)(state, dispatch);
        }

        if ($cursor.end() === $cursor.pos) {
          return prosemirrorToggleMark(markType, attrs)(state, dispatch);
        }

        const doc = state.doc;

        const characterBeforeCursor = doc.textBetween(
          $cursor.pos - 1,
          $cursor.pos
        );
        const characterAfterCursor = doc.textBetween(
          $cursor.pos,
          $cursor.pos + 1
        );

        if (
          delimeters.some((exp) => exp.exec(characterBeforeCursor)) ||
          delimeters.some((exp) => exp.exec(characterAfterCursor))
        ) {
          return prosemirrorToggleMark(markType, attrs)(state, dispatch);
        }

        const tr = state.tr;
        const { from, to } = findWordRange($cursor);
        const has = doc.rangeHasMark(from, to, markType);

        if (has) {
          tr.removeMark(from, to, markType);
        } else {
          tr.addMark(from, to, markType.create(attrs));
        }

        dispatch(tr.scrollIntoView());
      } else {
        let tr = state.tr;
        const { ranges } = state.selection;
        const has = textMarkIsActive(markType)(state);

        ranges.forEach((range) => {
          const { $from, $to } = range;
          const from = $from.pos;
          const to = $to.pos;
          if (has) {
            tr = tr.removeMark(from, to, markType);
          } else {
            tr = tr.addMark(from, to, markType.create(attrs));
          }
        });

        dispatch(tr.scrollIntoView());
      }
    }

    return true;
  };
}

export function executeSequence<S extends Schema>(
  ...commands: Array<CommandFn<S>>
): CommandFn<S> {
  return (state: EditorState<S>, dispatch?: (tr: Transaction<S>) => void) => {
    if (commands.length === 0) {
      return false;
    }

    if (dispatch) {
      const steps: Step<S>[] = [];
      let updatedState = state;

      const updateState = (tr: Transaction<S>) => {
        steps.push(...tr.steps);
        updatedState = updatedState.apply(tr);
      };

      commands.forEach((command) => {
        command(updatedState, updateState);
      });

      let tr = state.tr;
      steps.forEach((step) => {
        tr = tr.step(step);
      });

      if (updatedState.storedMarks != null) {
        tr.setStoredMarks(updatedState.storedMarks);
      }

      dispatch(tr);
    }

    return true;
  };
}

export type Predicate<S extends Schema> = (
  state: EditorState,
  view?: EditorView<S>
) => boolean;

export function filter<S extends Schema>(
  predicates: Predicate<S>[] | Predicate<S>,
  cmd: CommandFn<S>
): CommandFn<S> {
  return function (state, dispatch, view): boolean {
    if (!Array.isArray(predicates)) {
      predicates = [predicates];
    }

    if (predicates.some((pred) => !pred(state, view))) {
      return false;
    }

    return cmd(state, dispatch, view) || false;
  };
}

export function removeBlockStyles<S extends Schema>(): CommandFn<S> {
  return executeSequence(removeAlignment(), removeIndentation());
}

export function setBlockType<S extends Schema>(
  nodeType: NodeType<S>,
  attrs?: Record<string, any>,
  mergeAttrs?: (
    node: Node<S>,
    attrs?: Record<string, any>
  ) => Record<string, any>
): CommandFn<S> {
  return function (state, dispatch) {
    const { from, to } = state.selection;
    let applicable = false;
    state.doc.nodesBetween(from, to, (node, pos) => {
      if (applicable) {
        return false;
      }

      if (!node.isTextblock || node.hasMarkup(nodeType, attrs)) {
        return;
      }

      if (node.type === nodeType) {
        applicable = true;
      } else {
        const $pos = state.doc.resolve(pos);
        const index = $pos.index();
        applicable = $pos.parent.canReplaceWith(index, index + 1, nodeType);
      }

      return;
    });

    if (!applicable) {
      return false;
    }

    if (dispatch) {
      let tr = state.tr;
      tr = changeBlockType(tr, from, to, nodeType, attrs, mergeAttrs);
      tr = tr.scrollIntoView();

      dispatch(tr);
    }

    return true;
  };
}

function changeBlockType<S extends Schema>(
  tr: Transaction<S>,
  from: number,
  to: number = from,
  type: NodeType<S>,
  attrs?: Record<string, any>,
  mergeAttrs?: (
    node: Node<S>,
    attrs?: Record<string, any>
  ) => Record<string, any>
): Transaction<S> {
  if (!type.isTextblock) {
    throw new RangeError("Type given to setBlockType should be a textblock");
  }

  const mapFrom = tr.steps.length;
  tr.doc.nodesBetween(from, to, (node, pos) => {
    if (
      node.isTextblock &&
      !node.hasMarkup(type, attrs) &&
      canChangeType(tr.doc, tr.mapping.slice(mapFrom).map(pos), type)
    ) {
      // Ensure all markup that isn't allowed in the new node type is cleared
      tr.clearIncompatible(tr.mapping.slice(mapFrom).map(pos, 1), type);
      const mapping = tr.mapping.slice(mapFrom);
      const startM = mapping.map(pos, 1);
      const endM = mapping.map(pos + node.nodeSize, 1);
      const mergedAttrs = mergeAttrs != null ? mergeAttrs(node, attrs) : attrs;
      tr = tr.step(
        new ReplaceAroundStep(
          startM,
          endM,
          startM + 1,
          endM - 1,
          new Slice(
            Fragment.from(type.create(mergedAttrs, undefined, node.marks)),
            0,
            0
          ),
          1,
          true
        )
      );
      return false;
    }

    return;
  });

  return tr;
}

function canChangeType<S extends Schema>(
  doc: Node<S>,
  pos: number,
  type: NodeType<S>
): boolean {
  const $pos = doc.resolve(pos);
  const index = $pos.index();
  return $pos.parent.canReplaceWith(index, index + 1, type);
}

export function isInsideGrid(state: EditorState, focused: Focus): boolean {
  const { doc, schema } = state;
  const { pos } = focused;
  const $pos = doc.resolve(pos);

  const parent = findParent($pos, (n) => n.type === schema.nodes.table);

  return parent !== undefined;
}
