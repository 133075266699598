import { MarkSpec, Schema } from "prosemirror-model";
import {
  CommandConfiguration,
  CommandConfigurations,
  DocumentBuilders,
  Extension,
  MarkConfig
} from "../../editor";
import {
  activeTextMark,
  ApplyAround,
  pixelToPoints,
  pointsToPixels,
  removeMark,
  round,
  textMarkIsActive,
  textMarkIsEnabled,
  updateMark
} from "../../util";

class TextSizeMark implements MarkConfig {
  get name(): string {
    return "textSize";
  }

  get spec(): MarkSpec {
    return {
      group: "text-style",
      attrs: { size: {} },
      parseDOM: [
        {
          style: "font-size",
          getAttrs: (value) => {
            if (typeof value !== "string") {
              return false;
            }

            if (value == null) {
              return false;
            }

            if (value.includes("pt")) {
              const pt = round(parseFloat(value), 0.5);
              return { size: pt };
            } else if (value.includes("px")) {
              const px = parseFloat(value);
              const pt = pixelToPoints(px);
              return { size: pt };
            } else {
              return false;
            }
          }
        }
      ],
      toDOM(node) {
        const px = pointsToPixels(node.attrs.size);
        return [
          "span",
          {
            style: `font-size: ${px}px`
          },
          0
        ];
      }
    };
  }

  get builders(): DocumentBuilders {
    return {
      smallTextSize: { markType: "textSize", size: 8 },
      largeTextSize: { markType: "textSize", size: 72 }
    };
  }
}

type TextSizeSchema = Schema<any, "textSize" | "styles">;

interface TextSizeCommandProps {
  size: number;
  around?: ApplyAround;
}

export class TextSize implements Extension<TextSizeSchema> {
  constructor(private defaultTextSize: number) {}

  get name(): string {
    return "textSize";
  }

  get marks(): MarkConfig[] {
    return [new TextSizeMark()];
  }

  get documentStyles(): { [key: string]: string } {
    return { "text-size": `${pointsToPixels(this.defaultTextSize)}px` };
  }

  commands(schema: TextSizeSchema): CommandConfigurations<TextSizeSchema> {
    return {
      textSize: this.textSizeCommand(schema)
    };
  }

  private textSizeCommand(
    schema: TextSizeSchema
  ): CommandConfiguration<TextSizeSchema, TextSizeCommandProps, number> {
    return {
      isActive: (props) => {
        return (state) => {
          const markIsActiveFn = textMarkIsActive(
            schema.marks.textSize,
            props,
            {
              size: this.defaultTextSize
            }
          );

          return markIsActiveFn(state);
        };
      },
      isEnabled: () => textMarkIsEnabled(schema.marks.textSize),
      execute: (props) => {
        return props?.size
          ? updateMark(
              schema.marks.textSize,
              props,
              props.around == null ? "word" : props.around
            )
          : removeMark(schema.marks.textSize);
      },
      activeValue: () => {
        return (state) => {
          const activeMark = activeTextMark(schema.marks.textSize)(state);

          if (activeMark != null) {
            return activeMark.attrs.size;
          }

          const acitveMarkFn = activeTextMark(
            schema.marks.textSize,
            undefined,
            {
              size: this.defaultTextSize
            }
          );

          const mark = acitveMarkFn(state);

          if (mark) {
            return mark.attrs.size;
          } else {
            return undefined;
          }
        };
      }
    };
  }
}
