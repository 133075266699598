import { Node, Schema } from "prosemirror-model";
import { EditorState, NodeSelection } from "prosemirror-state";
import { CellSelection } from "prosemirror-tables";
import { GapCursor } from "../../editor/plugins/gap-cursor/gap-cursor";
import { availableVerticalAlignment, VerticalAlignmentType } from "./values";
import { findParent } from "../../util";

export function getVerticalAlignment(
  value: string | null
): VerticalAlignmentType | null {
  if (
    value != null &&
    availableVerticalAlignment.includes(value as VerticalAlignmentType)
  ) {
    return value as VerticalAlignmentType;
  } else {
    return null;
  }
}

export function hasVerticalAlignment<S extends Schema>(node: Node<S>): boolean {
  return node.type.spec.attrs?.verticalAlignment != null;
}

function verticalAlignmentIsEqual<S extends Schema>(
  node: Node<S>,
  alignment: VerticalAlignmentType,
  defaultAlignment: VerticalAlignmentType
): boolean {
  if (node.attrs.verticalAlignment === alignment) {
    return true;
  } else if (node.attrs.verticalAlignment === null) {
    return alignment === defaultAlignment;
  } else {
    return false;
  }
}

function getNodesInRange<S extends Schema>(
  doc: Node<S>,
  from: number,
  to: number,
  alignment: VerticalAlignmentType,
  defaultAlignment: VerticalAlignmentType
): {
  nodes: { node: Node<S>; pos: number; parent: Node<S> }[];
  count: number;
} {
  let nodes = new Array<{ node: Node<S>; pos: number; parent: Node<S> }>();
  let count = 0;
  doc.nodesBetween(from, to, (node, pos, parent) => {
    if (hasVerticalAlignment(node)) {
      nodes.push({ node, pos, parent });
      if (verticalAlignmentIsEqual(node, alignment, defaultAlignment)) {
        count = count + 1;
      }
      return false;
    }
    return;
  });

  return { nodes, count };
}

export function getVerticalAlignmentNodes<S extends Schema>(
  alignment: VerticalAlignmentType,
  defaultAlignment: VerticalAlignmentType
): (
  state: EditorState<S>
) => {
  nodes: { node: Node<S>; pos: number }[];
  count: number;
} {
  return (state) => {
    const { selection, doc, schema } = state;
    if (selection instanceof CellSelection) {
      const nodes = new Array<{
        node: Node<S>;
        pos: number;
      }>();
      let count = 0;

      selection.forEachCell((node, pos) => {
        if (hasVerticalAlignment(node)) {
          nodes.push({ node: node, pos: pos });

          if (verticalAlignmentIsEqual(node, alignment, defaultAlignment)) {
            count = count + 1;
          }
        }
      });
      return { nodes: nodes, count: count };
    } else if (selection instanceof NodeSelection) {
      const { node } = selection;
      if (node != null && node.type === schema.nodes.table) {
        const { from, to } = selection;
        return getNodesInRange(doc, from, to, alignment, defaultAlignment);
      } else {
        return { nodes: [], count: 0 };
      }
    } else if (selection instanceof GapCursor) {
      const { nodePos } = selection;
      if (nodePos != null) {
        const $pos = doc.resolve(nodePos);
        const parent = findParent($pos, () => true);
        if (parent != null) {
          return {
            nodes: [{ node: parent.node, pos: parent.pos }],
            count: verticalAlignmentIsEqual(
              parent.node,
              alignment,
              defaultAlignment
            )
              ? 1
              : 0
          };
        } else {
          return { nodes: [], count: 0 };
        }
      } else {
        return { nodes: [], count: 0 };
      }
    } else {
      const { from, to } = selection;
      return getNodesInRange(doc, from, to, alignment, defaultAlignment);
    }
  };
}

export function isVerticalAlignmentActive<S extends Schema>(
  alignment: VerticalAlignmentType,
  defaultAlignment: VerticalAlignmentType
): (state: EditorState<S>) => boolean {
  return (state) => {
    const { nodes, count } = getVerticalAlignmentNodes(
      alignment,
      defaultAlignment
    )(state);
    return count > 0 && nodes.length === count;
  };
}

export function isVerticalAlignmentEnabled<S extends Schema>(
  _alignment: VerticalAlignmentType
): (state: EditorState<S>) => boolean {
  return (state) => {
    const { doc, selection, schema } = state;
    if (selection instanceof CellSelection) {
      return true;
    } else if (selection instanceof NodeSelection) {
      const { node } = selection;
      if (node != null && node.type === schema.nodes.table) {
        return true;
      } else {
        return false;
      }
    } else if (selection instanceof GapCursor) {
      const { nodePos } = selection;
      if (nodePos != null) {
        const $pos = doc.resolve(nodePos);
        return hasVerticalAlignment($pos.parent);
      } else {
        return false;
      }
    } else {
      const { from, to } = selection;

      let supported: boolean[] = [];

      doc.nodesBetween(from, to, (node) => {
        if (hasVerticalAlignment(node)) {
          supported = supported.concat(true);
          return false;
        }

        return;
      });

      return supported.length === 0
        ? false
        : supported.every((x) => x === true);
    }
  };
}
