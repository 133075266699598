import { Schema } from "prosemirror-model";
import { Selection } from "prosemirror-state";
import {
  CommandConfiguration,
  CommandConfigurations
} from "../../../editor/extension";
import { editableTransaction } from "../../../editor/plugins/editable";
import { findNode } from "../../../util";
import { RangeSelection } from "../types";
import { BlockRange } from "./block-range";
import { BlockSelection } from "./block-selection";
import {
  blockSelectorKey,
  EndSelectionAction,
  ReplaceSelectionAction,
  StartSelectionAction
} from "./plugin";
import { TemplateRangeNamingStrategy } from "./types";

export interface StartSelectionCommandProps {
  rangeNamingStrategy: TemplateRangeNamingStrategy;
  includeChoices: boolean;
  includeHeaderFooterRow: boolean;
}

export interface UpdateSelectionCommandProps {
  ranges: RangeSelection[];
}

export function commands(schema: Schema): CommandConfigurations<Schema> {
  return {
    startBlockSelector: startBlockSelectorCommand(schema),
    updateBlockSelector: updateBlockSelectorCommand(schema),
    endBlockSelector: endBlockSelectorCommand(schema)
  };
}

function startBlockSelectorCommand(
  _schema: Schema
): CommandConfiguration<Schema, StartSelectionCommandProps, null> {
  return {
    isActive: () => {
      return false;
    },
    isEnabled: () => {
      return true;
    },
    execute: (props) => {
      if (props == null) {
        throw new Error(
          "To start an input selector `StartSelectionCommandProps` must be passed."
        );
      }

      return (state, dispatch) => {
        if (dispatch) {
          let tr = state.tr;

          const action: StartSelectionAction = {
            type: "start",
            rangeNamingStrategy: props.rangeNamingStrategy,
            includeChoices: props.includeChoices,
            includeHeaderFooterRow: props.includeHeaderFooterRow
          };
          tr = tr.setSelection(Selection.near(tr.doc.resolve(0)));
          tr = editableTransaction(tr, { editable: false, focusable: false });
          tr = tr.setMeta(blockSelectorKey, action);

          dispatch(tr);
        }
        return true;
      };
    },
    requiresEditable: false
  };
}

function updateBlockSelectorCommand(
  _schema: Schema
): CommandConfiguration<Schema, UpdateSelectionCommandProps, null> {
  return {
    isActive: () => {
      return false;
    },
    isEnabled: () => {
      return true;
    },
    execute: (props) => {
      if (props == null) {
        throw new Error(
          "To start an input selector `UpdateSelectionCommandProps` must be passed."
        );
      }

      return (state, dispatch, _view) => {
        if (dispatch) {
          const { doc } = state;
          const { ranges } = props;

          let tr = state.tr;

          const selection = new BlockSelection(
            ranges.map((r) => {
              const start = findNode(
                doc,
                (n) => n.attrs?.id === r.startElementId
              );
              const end = findNode(doc, (n) => n.attrs?.id === r.endElementId);
              return new BlockRange({
                id: r.id,
                start: start == null ? null : start,
                end: end == null ? null : end
              });
            })
          );

          const action: ReplaceSelectionAction = {
            type: "replace",
            selection: selection,
            emit: false
          };

          tr = tr.setMeta(blockSelectorKey, action);

          dispatch(tr);
        }
        return true;
      };
    },
    requiresEditable: false
  };
}

function endBlockSelectorCommand(
  _schema: Schema
): CommandConfiguration<Schema, StartSelectionCommandProps, null> {
  return {
    isActive: () => {
      return false;
    },
    isEnabled: () => {
      return true;
    },
    execute: () => {
      return (state, dispatch) => {
        if (dispatch) {
          let tr = state.tr;

          const action: EndSelectionAction = { type: "end" };
          tr = tr.setMeta(blockSelectorKey, action);
          tr = editableTransaction(tr, { editable: true });

          dispatch(tr);
        }
        return true;
      };
    },
    requiresEditable: false
  };
}
