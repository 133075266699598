import { EditorView } from "prosemirror-view";
import { getParentDomNode, limit, widthAsPercent } from "../../util";

export function imageWidth(src: string): Promise<number> {
  return new Promise((resolve, reject) => {
    const image = new Image();

    image.onload = () => {
      resolve(image.naturalWidth);
    };

    image.onerror = (error) => {
      reject(error);
    };

    image.src = src;
  });
}

export function imageDataUrl(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const src = event?.target?.result;
      if (src == null || typeof src !== "string") {
        reject("Could not genreate a dataUrl");
      } else {
        resolve(src);
      }
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
}

export function isImage(file: Blob): boolean {
  return /image/i.test(file.type);
}

export async function imageWidthInView(
  view: EditorView,
  src: string,
  minWidth: number,
  maxWidth: number
): Promise<number> {
  const parent = getParentDomNode<Element>(view);
  if (parent == null) {
    throw new Error("Image must by placed inside a parent");
  }
  const parentWidth = parseFloat(window.getComputedStyle(parent).width);
  const width = await imageWidth(src);
  const percentWidth = widthAsPercent(width, parentWidth);

  return limit(percentWidth, minWidth, maxWidth);
}
