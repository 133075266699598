import {
  DOMParser,
  Fragment,
  Node as ProseMirrorNode,
  NodeSpec
} from "prosemirror-model";
import { BASE_PRIORITY, DocumentBuilders, NodeConfig } from "../../../editor";
import { toNumberValue } from "../../../util";
import { AlignmentType } from "../../alignment";
import {
  CustomGridBorders,
  CustomGridSchema,
  CustomGridWidth
} from "../schema";

export class TableNode implements NodeConfig {
  constructor(private supportsInputs: boolean) {}

  get name(): string {
    return "table";
  }

  get spec(): NodeSpec {
    const supportsInputs = this.supportsInputs;
    return {
      group: "block rootBlock",
      content: "tableHeaderRow tableRow* tableFooterRow",
      tableRole: "table",
      isolating: true,
      selectable: true,
      focusable: true,
      draggable: true,
      allowGapCursor: true,
      allowAlignment: ["left", "center", "right"],
      blockAlignment: true,
      attrs: {
        id: { default: null },
        name: { default: null },
        description: { default: "" },
        width: { default: CustomGridWidth.default },
        showHeader: { default: true },
        showFooter: { default: true },
        randomization: { default: false },
        repeatGrid: { default: supportsInputs ? true : false },
        responsive: { default: true },
        lastRowManualRepeat: { default: false },
        borders: {
          default: {
            top: true,
            bottom: true,
            left: true,
            right: true,
            "inside-horizontal": true,
            "inside-vertical": true
          }
        },
        stickyHeader: {
          default: false
        },
        alignment: { default: null }
      },
      parseDOM: [
        {
          tag: "table[data-pm-slice]",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getAttrs(node as Element, supportsInputs);
          }
        },
        {
          tag: "table",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getAttrs(node as Element, supportsInputs);
          },
          getContent: (node, schema) => {
            return rows(node as HTMLTableElement, schema, supportsInputs);
          },
          priority: BASE_PRIORITY - 1
        },
        {
          tag: "table",
          context: "table//",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getAttrs(node as Element, supportsInputs);
          }
        },
        {
          tag: "[data-smart-table]",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getAttrs(node as Element, supportsInputs);
          },
          getContent: (node, schema) => {
            return legacyRows(node as Element, schema, supportsInputs);
          }
        }
      ],
      toDOM(node) {
        return ["table", setAttrs(node, supportsInputs), ["tbody", 0]];
      }
    };
  }

  get builders(): DocumentBuilders {
    return { table: { nodeType: "table" } };
  }
}

function getAttrs(
  element: Element,
  supportsInputs: boolean
): { [key: string]: any } {
  const attrs: {
    id?: string;
    name?: string;
    description?: string;
    showHeader?: boolean;
    showFooter?: boolean;
    randomization?: boolean;
    repeatGrid?: boolean;
    responsive?: boolean;
    lastRowManualRepeat?: boolean;
    borders?: CustomGridBorders;
    stickyHeader?: boolean;
    width?: number;
    alignment?: AlignmentType;
  } = {};

  const id = element.getAttribute("id");
  if (id != null) {
    attrs.id = id;
  }

  const questionTitleText = element.getAttribute("data-question-title-text");
  if (questionTitleText != null && questionTitleText.length > 0) {
    attrs.name = questionTitleText;
  }

  const name = element.getAttribute("data-name");
  if (name != null && name.length > 0) {
    attrs.name = name;
  }

  const description = element.getAttribute("data-description");
  if (description != null) {
    attrs.description = description;
  }

  const showHeader = element.getAttribute("data-show-header");
  if (showHeader != null) {
    attrs.showHeader = showHeader.toLowerCase() === "true";
  }

  const showFooter = element.getAttribute("data-show-footer");
  if (showFooter != null) {
    attrs.showFooter = showFooter.toLowerCase() === "true";
  }

  const randomization = element.getAttribute("data-randomization");
  if (randomization != null && supportsInputs) {
    attrs.randomization = randomization.toLowerCase() === "true";
  }

  const repeatGrid = element.getAttribute("data-repeat-grid");
  if (repeatGrid != null && supportsInputs) {
    attrs.repeatGrid = repeatGrid.toLowerCase() === "true";
  }

  const responsive = element.getAttribute("data-responsive");
  if (responsive != null) {
    attrs.responsive = responsive.toLowerCase() === "true";
  }

  const lastRowManualRepeat = element.getAttribute(
    "data-last-row-manual-repeat"
  );
  if (lastRowManualRepeat != null && supportsInputs) {
    attrs.lastRowManualRepeat = lastRowManualRepeat.toLowerCase() === "true";
  }

  const borderTop = element.getAttribute("data-border-top");
  const borderBottom = element.getAttribute("data-border-bottom");
  const borderLeft = element.getAttribute("data-border-left");
  const borderRight = element.getAttribute("data-border-right");
  const borderInsideHorizontal = element.getAttribute(
    "data-border-inside-horizontal"
  );
  const borderInsideVertical = element.getAttribute(
    "data-border-inside-vertical"
  );

  attrs.borders = {
    top: borderTop == null ? true : borderTop.toLowerCase() === "true",
    bottom: borderBottom == null ? true : borderBottom.toLowerCase() === "true",
    left: borderLeft == null ? true : borderLeft.toLowerCase() === "true",
    right: borderRight == null ? true : borderRight.toLowerCase() === "true",
    "inside-horizontal":
      borderInsideHorizontal == null
        ? true
        : borderInsideHorizontal.toLowerCase() === "true",
    "inside-vertical":
      borderInsideVertical == null
        ? true
        : borderInsideVertical.toLowerCase() === "true"
  };

  const stickyHeader = element.getAttribute("data-sticky-header");
  if (stickyHeader != null) {
    attrs.stickyHeader = stickyHeader.toLowerCase() === "true";
  }

  const width = toNumberValue(element.getAttribute("data-width"), 0);
  if (width != null) {
    attrs.width = width;
  }

  //name for width in old editor
  const size = toNumberValue(element.getAttribute("data-size"), 0);
  if (size != null) {
    attrs.width = size;
  }

  const alignment = element.getAttribute("data-alignment") as AlignmentType;
  if (alignment != null) {
    switch (alignment) {
      case "center":
      case "left":
      case "right":
        attrs.alignment = alignment;
        break;
    }
  }

  return attrs;
}

function setAttrs(
  node: ProseMirrorNode,
  supportsInputs: boolean
): { [key: string]: any } {
  const id = node.attrs.id as string;
  const name = node.attrs.name as string;
  const description = node.attrs.description as string;
  const showHeader = node.attrs.showHeader as boolean;
  const showFooter = node.attrs.showFooter as boolean;
  const randomization = node.attrs.randomization as boolean;
  const repeatGrid = node.attrs.repeatGrid as boolean;
  const responsive = node.attrs.responsive as boolean;
  const lastRowManualRepeat = node.attrs.lastRowManualRepeat as boolean;
  const borders = node.attrs.borders as CustomGridBorders;
  const stickyHeader = node.attrs.stickyHeader as boolean;
  const width = node.attrs.width as number;
  const alignment = node.attrs.alignment as AlignmentType;

  return {
    id: id,
    "data-name": name == null ? "" : name,
    "data-description": description,
    "data-show-header": showHeader === true ? "true" : "false",
    "data-show-footer": showFooter === true ? "true" : "false",
    "data-randomization":
      randomization === true && supportsInputs ? "true" : "false",
    "data-repeat-grid":
      repeatGrid === true && supportsInputs ? "true" : "false",
    "data-responsive": responsive === true ? "true" : "false",
    "data-last-row-manual-repeat":
      lastRowManualRepeat === true && supportsInputs ? "true" : "false",
    "data-border-top": borders.top,
    "data-border-bottom": borders.bottom,
    "data-border-left": borders.left,
    "data-border-right": borders.right,
    "data-border-inside-horizontal": borders["inside-horizontal"],
    "data-border-inside-vertical": borders["inside-vertical"],
    "data-sticky-header": stickyHeader ? "true" : "false",
    "data-width": width,
    "data-alignment": alignment
  };
}

function legacyRows(
  element: Element,
  schema: CustomGridSchema,
  supportsInputs: boolean
): Fragment {
  const parser = DOMParser.fromSchema(schema);

  const attrs: {
    repeatGrid: boolean;
  } = { repeatGrid: false };

  const repeatGrid = element.getAttribute("data-repeat-grid");
  if (repeatGrid != null && supportsInputs) {
    attrs.repeatGrid = repeatGrid.toLowerCase() === "true";
  }

  if (attrs.repeatGrid) {
    const scaleHeaders = Array.from(
      element.querySelectorAll("[data-smart-table-likert-labels]")
    );

    const inputs = Array.from(element.querySelectorAll("[data-input]"));

    [...scaleHeaders, ...inputs].forEach((content) => {
      if (content.parentNode != null) {
        (content.parentNode as Element).classList.add("ProseMirror-input-cell");
      }
    });
  }

  const itemRows = Array.from(
    element.querySelectorAll("div[data-section='items'] > div")
  );

  let widths: string[] | undefined = undefined;
  if (itemRows.length > 0) {
    const row = itemRows[0];
    widths = getWidths(row);
  } else {
    const headerRows = Array.from(
      element.querySelectorAll("div[data-section='headers'] > div")
    );
    if (headerRows.length > 0) {
      const row = headerRows[0];
      widths = getWidths(row);
    } else {
      const footerRows = Array.from(
        element.querySelectorAll("div[data-section='footers'] > div")
      );
      if (footerRows.length > 0) {
        const row = footerRows[0];
        widths = getWidths(row);
      }
    }
  }

  if (widths != null) {
    const rows = Array.from(
      element.querySelectorAll(
        "div[data-section='headers'] > div, div[data-section='items'] > div, div[data-section='footers'] > div"
      )
    );

    rows.forEach((row) => {
      Array.from(row.childNodes as NodeListOf<HTMLElement>)
        .filter((n) => n.nodeType === Node.ELEMENT_NODE)
        .forEach((cell, index) => {
          if (widths != null) {
            cell.style.width = widths[index];
          }
        });
    });
  }

  const options = { topNode: schema.nodes.table.create() };
  const node = parser.parse(element, options);
  const fragment = node.content;

  return fragment;
}

function getWidths(row: Element): string[] {
  return Array.from(row.childNodes as NodeListOf<HTMLElement>)
    .filter((n) => n.nodeType === Node.ELEMENT_NODE)
    .map((cell) => cell.style.width);
}

function rows(
  element: HTMLTableElement,
  schema: CustomGridSchema,
  _supportsInputs: boolean
): Fragment {
  const parser = DOMParser.fromSchema(schema);
  const options = { topNode: schema.nodes.table.create() };
  const expectedElement = addHeaderAndFooter(element);
  const node = parser.parse(expectedElement, options);
  const fragment = node.content;
  return fragment;
}

export function addHeaderAndFooter(
  element: HTMLTableElement
): HTMLTableElement {
  const lastChild = element.lastElementChild;

  if (!lastChild) {
    return element;
  }

  if (lastChild.nodeName === "TBODY") {
    const firstRow = lastChild.firstElementChild as HTMLTableRowElement;
    if (firstRow) {
      const cellsNb = firstRow.cells.length;

      let hasHeader = false;
      let hasFooter = false;

      const elementHeader = lastChild.firstElementChild;

      if (elementHeader) {
        const dataSectionAttr = elementHeader.getAttribute("data-section");
        hasHeader = dataSectionAttr === "headers";
      }

      const footerElement = lastChild.lastElementChild;

      if (footerElement) {
        const dataSectionAttr = footerElement.getAttribute("data-section");
        hasFooter = dataSectionAttr === "footers";
      }

      if (!hasHeader) {
        const header = document.createElement("tr");
        header.setAttribute("data-section", "headers");

        for (let index = 1; index <= cellsNb; index++) {
          const headerRow = document.createElement("td");
          header.appendChild(headerRow);
        }
        lastChild.insertBefore(header, lastChild.firstChild);
      }

      if (!hasFooter) {
        const footer = document.createElement("tr");
        footer.setAttribute("data-section", "footers");

        for (let index = 1; index <= cellsNb; index++) {
          const footerRow = document.createElement("td");
          footer.appendChild(footerRow);
        }

        lastChild.appendChild(footer);
      }
    }
  }

  return element;
}
