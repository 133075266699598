import { chainCommands } from "prosemirror-commands";
import { Fragment, Node, ResolvedPos, Schema } from "prosemirror-model";
import {
  EditorState,
  NodeSelection,
  Plugin,
  PluginKey,
  TextSelection,
  Transaction
} from "prosemirror-state";
import {
  CommandConfiguration,
  CommandConfigurations,
  CommandFn,
  Extension,
  KeyMap
} from "../../editor";
import {
  dropInsertPlugin,
  executeAtPos,
  isEnableAtPos
} from "../../editor/plugins/drop-insert-plugin";
import { emitNotification } from "../../editor/plugins/notification";
import {
  applyQuestionTitle,
  questionTitleCacheKey,
  questionTitleCachePlugin,
  questionTitlePredicate
} from "../../editor/plugins/question-title-cache";
import { Focus } from "../../editor/plugins/selection-focus";
import { getIdGenerator } from "../../extensions/node-identifier";
import { isQuestionTitleAutoCreation } from "../../extensions/question-title/plugins/question-title-autocreation-flag";
import {
  canInsert,
  canInsertAtPos,
  findParent,
  isEmptySelectionAtEnd,
  isEmptySelectionAtStart,
  isFirstChildOfParent,
  isInsideGrid,
  isLastChildOfParent,
  isMac,
  NodeDTO,
  UnreachableCaseError
} from "../../util";
import { insertBlock } from "../../util/transforms";
import { getTranslation, GetTranslationFn } from "../localization";
import {
  getQuestionTitleActiveValue,
  QuestionTitleActiveValue,
  updateQuestionTitleText
} from "../question-title";
import { nodeViewPlugin } from "./node-views";
import {
  InputScaleLabelNode,
  InputScaleLabelNotApplicableNode,
  InputScaleLabelsNode,
  InputScaleNode,
  InputScaleNotApplicableNode,
  InputScaleValueNode
} from "./nodes";
import {
  getCachedScaleId,
  pastePlugin,
  scaleCachePlugin,
  scaleLabelControls,
  setCachedScaleId
} from "./plugins";
import {
  InputScaleControlType,
  InputScaleOrientation,
  InputScaleRows,
  InputScaleSchema,
  InputScaleValue,
  InputScaleWidth,
  Scale,
  userDefinedScale
} from "./schema";
import {
  createInputScale,
  createInputScaleNotApplicable,
  createInputScaleValues,
  focusedInputScale,
  hashForScale
} from "./util";

export interface InsertInputScaleCommandProps {
  controlType: InputScaleControlType;
}

export interface UpdateInputScaleCommandProps {
  questionTitleText?: string;
  description?: string;
  required?: boolean;
  coding?: string;
  controlType?: InputScaleControlType;
  orientation?: InputScaleOrientation;
  rows?: number;
  width?: number;
  notApplicable?: boolean;
  scale?: string;
  responseIds?: Pick<InputScaleValue, "id" | "coding">[];
}

export interface InputScaleActiveValue {
  questionTitle: QuestionTitleActiveValue;
  description: string;
  required: boolean;
  coding: string;
  controlType: InputScaleControlType;
  orientation: InputScaleOrientation;
  rows: number;
  width: number;
  notApplicable: boolean;
  scale: string;
  values: InputScaleValue[];
  canChangeOrientation: boolean;
  widthDisabled: boolean;
}

export const scalesKey = new PluginKey<{ scales: Scale[] }, InputScaleSchema>(
  "scalesPlugin"
);

const scalesPlugin = (scales: Scale[]) => {
  return new Plugin({
    key: scalesKey,
    state: {
      init() {
        return { scales: scales };
      },
      apply() {
        return { scales: scales };
      }
    }
  });
};

const questionTitleKey = questionTitleCacheKey();

export class InputScale implements Extension<InputScaleSchema> {
  private defaultScale: Scale;

  constructor(private scales: Scale[]) {
    const defaultScale = scales.find((x) => x.default === true);

    if (defaultScale == null) {
      throw new Error("Must define a default scale in scales.");
    }

    this.defaultScale = defaultScale;
  }

  get name() {
    return "inputScale";
  }

  get nodes() {
    return [
      new InputScaleNode(),
      new InputScaleValueNode(),
      new InputScaleNotApplicableNode(),
      new InputScaleLabelsNode(),
      new InputScaleLabelNode(),
      new InputScaleLabelNotApplicableNode()
    ];
  }

  plugins(schema: InputScaleSchema): Plugin[] {
    return [
      nodeViewPlugin(),
      scalesPlugin(this.scales),
      dropInsertPlugin((view, data, posAtCoords) => {
        if (data.type !== "inputScale") {
          return false;
        }

        let controlType: InputScaleControlType;
        switch (data.subType) {
          case InputScaleControlType.labelsInRow:
            controlType = InputScaleControlType.labelsInRow;
            break;

          case InputScaleControlType.dropdown:
            controlType = InputScaleControlType.dropdown;
            break;

          case InputScaleControlType.listbox:
            controlType = InputScaleControlType.listbox;
            break;

          default:
            controlType = InputScaleControlType.labelsInRow;
            break;
        }

        const command = this.insertInputScaleCommand(schema);

        const isEnabled = isEnableAtPos(command, view, posAtCoords);
        if (
          !isEnabled({
            controlType: controlType
          })
        ) {
          emitNotification(view.state, {
            type: "warning",
            message: "drop.insert.invalid-location"
          });
        } else {
          executeAtPos(
            command.execute({
              controlType: controlType
            }),
            view,
            posAtCoords
          );
        }

        return true;
      }),
      pastePlugin(schema),
      scaleCachePlugin(),
      questionTitleCachePlugin(
        schema.nodes.inputScale,
        "INPUT_LIKERT.DEFAULT_QUESTION_TITLE",
        questionTitlePredicate,
        applyQuestionTitle,
        questionTitleKey
      ),
      scaleLabelControls()
    ];
  }

  commands(schema: InputScaleSchema): CommandConfigurations<InputScaleSchema> {
    return {
      insertInputScale: this.insertInputScaleCommand(schema),
      updateInputScale: this.updateInputScaleCommand(schema)
    };
  }

  keymaps(_schema: InputScaleSchema): KeyMap<InputScaleSchema> {
    return {
      Enter: chainCommands(
        exitInputScale(true, true),
        addItemBeforeNotApplicable()
      ),
      Backspace: exitInputScale(true, false),
      Delete: exitInputScale(false, true)
    };
  }

  private insertInputScaleCommand(
    schema: InputScaleSchema
  ): CommandConfiguration<
    InputScaleSchema,
    InsertInputScaleCommandProps,
    undefined
  > {
    return {
      isActive: () => {
        return false;
      },
      isEnabled: () => {
        return insertInputScale(
          schema,
          InputScaleControlType.labelsInRow,
          this.defaultScale
        );
      },
      execute: (props) => {
        if (props == null) {
          throw new Error(
            `To insert an input scale, InsertInputScaleCommandProps needs to be provided.`
          );
        }

        const { controlType } = props;
        if (controlType == null) {
          throw new Error(
            `To insert an input scale, control type need to be provided.`
          );
        }

        return (state, dispatch) => {
          const cachedScaleId = getCachedScaleId(state);
          const cachedScale =
            cachedScaleId == null
              ? undefined
              : this.scales.find((s) => s.id === cachedScaleId);

          return insertInputScale(
            schema,
            controlType,
            cachedScale == null ? this.defaultScale : cachedScale
          )(state, dispatch);
        };
      },
      shortcuts: {
        [isMac() ? "Ctrl-l" : "Alt-l"]: {
          controlType: InputScaleControlType.labelsInRow
        }
      }
    };
  }

  private updateInputScaleCommand(
    schema: InputScaleSchema
  ): CommandConfiguration<
    InputScaleSchema,
    UpdateInputScaleCommandProps,
    InputScaleActiveValue | undefined
  > {
    return {
      isActive: () => {
        return (state) => {
          return focusedInputScale(state) != null;
        };
      },
      isEnabled: () => {
        return (state) => {
          return focusedInputScale(state) != null;
        };
      },
      execute: (props) => {
        if (props == null) {
          throw new Error(
            `To update an input scale, UpdateInputScaleCommandProps needs to be provided.`
          );
        }

        return (state, dispatch) => {
          const focused = focusedInputScale(state);
          return updateInputScale(
            schema,
            props,
            focused,
            this.scales
          )(state, dispatch);
        };
      },
      activeValue: () => {
        return (state) => {
          const focused = focusedInputScale(state);
          if (!focused) {
            return undefined;
          }

          const { schema } = state;
          const { node } = focused;

          const notApplicable = notApplicableNode(node, schema) != null;
          const scale = currentScale(
            node,
            this.scales,
            notApplicable,
            getTranslation(state)
          );

          const values = new Array<InputScaleValue>();
          node.content.forEach((child) => {
            values.push({
              id: child.attrs.id,
              content: (child.content.toJSON() as NodeDTO[] | null) ?? [],
              coding: child.attrs.coding
            });
          });

          const widthDisabled = isInsideGrid(state, focused);

          return {
            questionTitle: getQuestionTitleActiveValue(state, node),
            description: node.attrs.description,
            required: node.attrs.required,
            coding: node.attrs.coding,
            controlType: node.attrs.controlType,
            orientation: node.attrs.orientation,
            rows: node.attrs.rows,
            width: widthDisabled ? 100 : node.attrs.width,
            notApplicable: notApplicable,
            scale: scale,
            values: values,
            canChangeOrientation: canChangeOrientation(state, focused),
            widthDisabled: widthDisabled
          };
        };
      }
    };
  }
}

function insertInputScale(
  schema: InputScaleSchema,
  controlType: InputScaleControlType,
  scale: Scale
): CommandFn<InputScaleSchema> {
  return (state, dispatch) => {
    if (!canInsert(schema.nodes.inputScale)(state)) {
      return false;
    } else {
      if (dispatch) {
        const inputScale = createInputScale(
          schema,
          controlType,
          scale,
          undefined,
          getTranslation(state)
        );
        if (inputScale == null) {
          return false;
        }

        let tr = state.tr;
        tr = insertBlock(
          tr,
          state.schema,
          inputScale,
          false,
          getIdGenerator(state),
          isQuestionTitleAutoCreation(state)
        );

        dispatch(tr);
      }
      return true;
    }
  };
}

function updateInputScale(
  schema: InputScaleSchema,
  props: UpdateInputScaleCommandProps,
  inputScale: { node: Node<InputScaleSchema>; pos: number } | undefined,
  scales: Scale[]
): CommandFn<InputScaleSchema> {
  return (state, dispatch) => {
    if (inputScale == null) {
      return false;
    }

    if (dispatch) {
      const { node, pos } = inputScale;

      if (props.notApplicable !== undefined) {
        const shouldRemoveScale =
          props.notApplicable === false &&
          node.childCount === 1 &&
          node.lastChild?.type === schema.nodes.inputScaleNotApplicable;

        if (shouldRemoveScale) {
          let tr = state.tr;
          tr = tr.replaceRangeWith(
            pos + 1,
            pos + node.nodeSize - 2,
            schema.nodes.inputScaleValue.create()
          );
          tr = tr.setSelection(new NodeSelection(tr.doc.resolve(pos)));

          dispatch(tr);

          return true;
        }
      }

      let updatedAttrs = { ...node.attrs };

      let tr = state.tr;

      if (props.description !== undefined) {
        updatedAttrs = { ...updatedAttrs, description: props.description };
      }

      if (props.required !== undefined) {
        updatedAttrs = { ...updatedAttrs, required: props.required };
      }

      if (props.coding !== undefined) {
        updatedAttrs = { ...updatedAttrs, coding: props.coding };
      }

      if (props.controlType !== undefined) {
        updatedAttrs = { ...updatedAttrs, controlType: props.controlType };
      }

      if (props.orientation !== undefined) {
        updatedAttrs = { ...updatedAttrs, orientation: props.orientation };
      }

      if (props.questionTitleText !== undefined) {
        updatedAttrs = updateQuestionTitleText(
          updatedAttrs,
          props.questionTitleText
        );
      }

      if (props.rows !== undefined) {
        updatedAttrs = {
          ...updatedAttrs,
          rows: Math.min(
            Math.max(props.rows, InputScaleRows.minimum),
            InputScaleRows.maximum
          )
        };
      }

      if (props.width != null && !isNaN(props.width)) {
        updatedAttrs = {
          ...updatedAttrs,
          width: Math.min(
            Math.max(props.width, InputScaleWidth.minimum),
            InputScaleWidth.maximum
          )
        };
      }

      if (props.responseIds !== undefined) {
        const responseIds = props.responseIds;
        const getResponseId = (id: string) => {
          return responseIds.find((responseId) => responseId.id === id);
        };

        const startPos = pos + 1;

        node.descendants((child, childPos) => {
          const id = child.attrs.id as string;
          const responseId = getResponseId(id);
          if (responseId != null) {
            const coding = responseId.coding;
            const hasCoding = coding != null && coding.length !== 0;

            tr = tr.setNodeMarkup(startPos + childPos, undefined, {
              ...child.attrs,
              coding: hasCoding ? coding : null
            });
          }
          return false;
        });
      }

      if (props.scale !== undefined) {
        const scale = scales.find((scale) => scale.id === props.scale);
        if (scale != null && scale.id !== node.attrs.scale) {
          updatedAttrs = {
            ...updatedAttrs,
            scale: props.scale
          };

          const values = createInputScaleValues(
            schema,
            scale,
            notApplicableNode(node, schema),
            getTranslation(state)
          );
          const from = pos + 1;
          const to = pos + node.nodeSize - 1;

          tr = tr.replaceWith(from, to, values);
          tr = setCachedScaleId(tr, scale.id);
        }
      }

      if (props.notApplicable !== undefined) {
        tr = toggleNotApplicable(
          tr,
          schema,
          pos,
          props.notApplicable,
          getTranslation(state)
        );
      }

      tr = tr.setNodeMarkup(pos, undefined, updatedAttrs);
      tr = tr.setSelection(new NodeSelection(tr.doc.resolve(pos)));

      dispatch(tr);
    }

    return true;
  };
}

function notApplicableNode(
  node: Node<InputScaleSchema>,
  schema: InputScaleSchema
): Node<InputScaleSchema> | undefined {
  let notApplicableNode: Node<InputScaleSchema> | undefined = undefined;
  for (let i = 0; i < node.childCount; ++i) {
    let child = node.maybeChild(i);
    if (child != null && child.type === schema.nodes.inputScaleNotApplicable) {
      notApplicableNode = child;
      break;
    }
  }

  return notApplicableNode;
}

function currentScale(
  node: Node<InputScaleSchema>,
  scales: Scale[],
  notApplicable: boolean,
  getTranslation: GetTranslationFn
): string {
  const scaleId = node.attrs.scale;
  const scale = scales.find((s) => s.id === scaleId);
  if (scale == null) {
    return userDefinedScale.id;
  }

  const labels = scale.options.map((o) => getTranslation(o.label));
  if (notApplicable) {
    labels.push(getTranslation("INPUT_LIKERT.NOT_APPLICABLE"));
  }

  const originalValueHash = hashForScale(labels);

  const options = new Array<string>();
  node.content.forEach((child) => {
    options.push(child.textContent);
  });
  const currentValueHash = hashForScale(options);

  return originalValueHash === currentValueHash
    ? node.attrs.scale
    : userDefinedScale.id;
}

function exitInputScale(
  fromStart: boolean,
  fromEnd: boolean
): CommandFn<InputScaleSchema> {
  return (state, dispatch) => {
    const { selection, schema } = state;
    if (!(selection instanceof TextSelection)) {
      return false;
    }

    const { $from, $to } = selection;

    const atStart = isEmptySelectionAtStart(state);
    const atEnd = isEmptySelectionAtEnd(state);
    if (!atStart && !atEnd) {
      return false;
    }

    const fromParent = $from.node($from.depth - 1);
    const toParent = $to.node($to.depth - 1);

    if (
      fromParent.type !== schema.nodes.inputScale ||
      toParent.type !== schema.nodes.inputScale
    ) {
      return false;
    }

    if (fromParent !== toParent) {
      return false;
    }

    if (atStart && atEnd && dispatch) {
      const isFirstChild = isFirstChildOfParent(state);
      const isLastChild = isLastChildOfParent(state);

      if (isFirstChild && fromStart) {
        let tr = state.tr;
        tr = tr.deleteSelection();

        const toInsert = schema.nodes.paragraph.create();
        tr = deleteNode(tr, $from, $from.depth - 1);
        tr = insertNode(tr, toInsert, "before", $from, $from.depth - 2);

        dispatch(tr);
        return true;
      } else if (isLastChild && fromEnd) {
        let tr = state.tr;
        tr = tr.deleteSelection();

        const toInsert = schema.nodes.paragraph.create();
        tr = deleteNode(tr, $to, $to.depth - 1);
        tr = insertNode(tr, toInsert, "after", $to, $to.depth - 2);

        dispatch(tr);
        return true;
      }
    }

    return false;
  };
}

function addItemBeforeNotApplicable(): CommandFn<InputScaleSchema> {
  return (state, dispatch) => {
    const { selection, schema } = state;
    if (!(selection instanceof TextSelection)) {
      return false;
    }

    const atStart = isEmptySelectionAtStart(state);
    if (atStart && dispatch) {
      const { $from } = selection;
      const node = $from.node();

      if (node == null) {
        return false;
      }

      if (node.type === schema.nodes.inputScaleNotApplicable) {
        let tr = state.tr;

        const toInsert = schema.nodes.inputScaleValue.create();
        tr = insertNode(tr, toInsert, "before", $from, $from.depth - 1);

        dispatch(tr);
        return true;
      }
    }

    return false;
  };
}

function insertNode<S extends Schema>(
  tr: Transaction<S>,
  toInsert: Node<S>,
  position: "before" | "after",
  $pos: ResolvedPos<S>,
  depth: number
): Transaction<S> {
  const index = $pos.index(depth);
  const offset = position === "after" ? 1 : 0;
  const pos = tr.mapping.map($pos.posAtIndex(index + offset, depth));

  if (canInsertAtPos(toInsert, tr.doc.resolve(pos))) {
    tr = tr.insert(pos, toInsert);
    tr = tr.setSelection(TextSelection.near(tr.doc.resolve(pos)));
  }

  return tr;
}

function deleteNode<S extends Schema>(
  tr: Transaction<S>,
  $pos: ResolvedPos<S>,
  depth: number
): Transaction<S> {
  const toRemoveIndex = $pos.index(depth);
  const parentIndex = $pos.index(depth - 1);
  const parentPos = tr.mapping.map($pos.posAtIndex(parentIndex, depth - 1));
  const parent = $pos.node(depth);

  if (parent.childCount === 1) {
    tr = tr.delete(parentPos, parentPos + parent.nodeSize);
  } else {
    let children = new Array<Node<S>>();
    parent.forEach((child, _offset, index) => {
      if (index !== toRemoveIndex) {
        children.push(child);
      }
    });

    tr = tr.replaceRangeWith(
      parentPos,
      parentPos + parent.nodeSize,
      parent.copy(Fragment.from(children))
    );
  }

  return tr;
}

function toggleNotApplicable<S extends Schema>(
  tr: Transaction<S>,
  schema: S,
  pos: number,
  notApplicable: boolean,
  getTranslation: GetTranslationFn
): Transaction<S> {
  const node = tr.doc.nodeAt(pos);
  if (node == null) {
    return tr;
  }

  if (notApplicable === true) {
    if (node.lastChild?.type === schema.nodes.inputScaleNotApplicable) {
      return tr;
    }

    const notApplicable = createInputScaleNotApplicable(
      schema,
      getTranslation
    ) as Node<S>;

    let children = new Array<Node<S>>();
    node.forEach((child, _offset, index) => {
      const isLastChild = index === node.childCount - 1;
      const isChildEmpty = child.content.size === 0;
      if (isLastChild) {
        if (!isChildEmpty) {
          children.push(child);
        }
      } else {
        children.push(child);
      }
    });

    children.push(notApplicable);

    tr = tr.replaceRangeWith(
      pos,
      pos + node.nodeSize,
      node.copy(Fragment.from(children))
    );
  } else {
    let notApplicable:
      | { node: Node<InputScaleSchema>; pos: number }
      | undefined;

    node.forEach((node, offset) => {
      if (node.type === schema.nodes.inputScaleNotApplicable) {
        notApplicable = { node: node, pos: pos + offset };
      }
    });

    if (notApplicable != null) {
      tr = tr.delete(
        notApplicable.pos,
        notApplicable.pos + notApplicable.node.nodeSize
      );
    }
  }

  return tr;
}

function canChangeOrientation(state: EditorState, focused: Focus): boolean {
  const { doc, schema } = state;
  const { node, pos } = focused;

  const controlType = node.attrs.controlType as InputScaleControlType;
  switch (controlType) {
    case InputScaleControlType.labelsInRow: {
      const repeatGrid = findParent(
        doc.resolve(pos),
        (n) => n.type === schema.nodes.table && n.attrs.repeatGrid === true
      );
      return repeatGrid == null ? true : false;
    }
    case InputScaleControlType.dropdown: {
      return false;
    }
    case InputScaleControlType.listbox: {
      return false;
    }
    default:
      throw new UnreachableCaseError(controlType);
  }
}
