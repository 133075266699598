import { Node as PMNode, Schema } from "prosemirror-model";
import { EditorState, Plugin, PluginKey, Transaction } from "prosemirror-state";
import { cellAround, TableMap } from "prosemirror-tables";
import { Decoration, DecorationSet, EditorView } from "prosemirror-view";
import { editableKey } from "../../../../editor/plugins/editable";
import { findParent, NodeWithPos } from "../../../../util";
import { CellAttributes } from "../../nodes";
import { CELL_MIN_WIDTH, pointsAtCell, setAttr, zeroes } from "../../util";

const HANDLE_WIDTH = 3;
const LAST_COLUMN_RESIZABLE = false;

export const key = new PluginKey<ResizeState>("tableColumnResizing");

export function columnResizing() {
  return new Plugin<ResizeState>({
    key,
    state: {
      init() {
        return new ResizeState(-1, null);
      },
      apply(tr, prev, _oldState, newState) {
        const modeState = editableKey.getState(newState);
        if (modeState != null && !modeState.editable) {
          return new ResizeState(-1, null);
        }

        return prev.apply(tr);
      }
    },
    props: {
      attributes(state) {
        let pluginState = key.getState(state);
        if (pluginState != null) {
          return pluginState.activeHandle > -1
            ? { class: "resize-cursor" }
            : null;
        } else {
          return null;
        }
      },
      decorations(state) {
        const modeState = editableKey.getState(state);
        if (modeState != null && !modeState.editable) {
          return DecorationSet.empty;
        }

        let pluginState = key.getState(state);
        if (pluginState != null && pluginState.activeHandle > -1) {
          return handleDecorations(state, pluginState.activeHandle);
        } else {
          return DecorationSet.empty;
        }
      },
      handleDOMEvents: {
        mousemove(view, event) {
          handleMouseMove(
            view,
            event as MouseEvent,
            HANDLE_WIDTH,
            LAST_COLUMN_RESIZABLE
          );
          return false;
        },
        mouseleave(view) {
          handleMouseLeave(view);
          return false;
        },
        mousedown(view, event) {
          handleMouseDown(view, event as MouseEvent, CELL_MIN_WIDTH);
          return false;
        }
      }
    }
  });
}

class ResizeState {
  constructor(
    readonly activeHandle: number,
    readonly dragging: {
      startX: number;
      startWidth: ColumnWidth[];
    } | null
  ) {}

  apply(tr: Transaction): ResizeState {
    const state = this;
    const action = tr.getMeta(key);

    if (action && action.setHandle != null) {
      return new ResizeState(action.setHandle, null);
    }

    if (action && action.setDragging !== undefined) {
      return new ResizeState(state.activeHandle, action.setDragging);
    }

    if (state.activeHandle > -1 && tr.docChanged) {
      let handle = tr.mapping.map(state.activeHandle, -1);
      if (!pointsAtCell(tr.doc.resolve(handle))) {
        handle = -1;
      }
      return new ResizeState(handle, state.dragging);
    }

    return state;
  }
}

function getTableWidth<S extends Schema>(
  view: EditorView<S>,
  cell: number
): number {
  const $cell = view.state.doc.resolve(cell);

  let dom = view.domAtPos($cell.start(-1)).node;
  while (dom.nodeName !== "TABLE") {
    if (dom.parentNode == null) {
      break;
    }
    dom = dom.parentNode;
  }

  const table = dom as HTMLElement;
  return table.offsetWidth;
}

function handleMouseMove(
  view: EditorView,
  event: MouseEvent,
  handleWidth: number,
  lastColumnResizable: boolean
) {
  const pluginState = key.getState(view.state);

  if (pluginState != null && !pluginState.dragging) {
    const target = domCellAround(event.target as Element);
    let cell = -1;

    if (target) {
      let { left, right } = target.getBoundingClientRect();
      if (event.clientX - left <= handleWidth) {
        cell = edgeCell(view, event, "left", handleWidth);
      } else if (right - event.clientX <= handleWidth) {
        cell = edgeCell(view, event, "right", handleWidth);
      }
    }

    if (cell !== pluginState.activeHandle) {
      if (!lastColumnResizable && cell !== -1) {
        const $cell = view.state.doc.resolve(cell);
        const table = $cell.node(-1);
        const map = TableMap.get(table);
        const start = $cell.start(-1);
        const col =
          map.colCount($cell.pos - start) + $cell.nodeAfter!.attrs.colspan - 1;

        if (col === map.width - 1) {
          return;
        }
      }

      updateHandle(view, cell);
    }
  }
}

function handleMouseLeave(view: EditorView) {
  const pluginState = key.getState(view.state);

  if (
    pluginState != null &&
    pluginState.activeHandle > -1 &&
    !pluginState.dragging
  ) {
    updateHandle(view, -1);
  }
}

function handleMouseDown(
  view: EditorView,
  event: MouseEvent,
  minCellWidth: number
) {
  const pluginState = key.getState(view.state);
  if (pluginState == null) {
    return false;
  }

  if (pluginState.activeHandle === -1 || pluginState.dragging) {
    return false;
  }

  const width = getColumnWidths(view, pluginState.activeHandle);

  view.dispatch(
    view.state.tr.setMeta(key, {
      setDragging: { startX: event.clientX, startWidth: width }
    })
  );

  function finish(event: MouseEvent) {
    window.removeEventListener("mouseup", finish);
    window.removeEventListener("mousemove", move);

    const pluginState = key.getState(view.state);
    if (pluginState != null && pluginState.dragging) {
      const tableWidth = getTableWidth(view, pluginState.activeHandle);
      const dragged = draggedWidth(
        pluginState.dragging,
        event,
        tableWidth,
        minCellWidth,
        pluginState.activeHandle
      );
      updateLeftAndRightColumnWidth(view, dragged);
      view.dispatch(view.state.tr.setMeta(key, { setDragging: null }));
    }
  }

  function move(event: MouseEvent) {
    if (!event.which) {
      return finish(event);
    }

    const pluginState = key.getState(view.state);
    if (pluginState != null && pluginState.dragging) {
      const tableWidth = getTableWidth(view, pluginState.activeHandle);
      const dragged = draggedWidth(
        pluginState.dragging,
        event,
        tableWidth,
        minCellWidth,
        pluginState.activeHandle
      );
      displayColumnWidth(view, pluginState.activeHandle, dragged, minCellWidth);
    }
  }

  window.addEventListener("mouseup", finish);
  window.addEventListener("mousemove", move);
  event.preventDefault();

  return true;
}

interface ColumnWidth extends NodeWithPos<Schema> {
  width: number;
  col: number;
}

function getColumnWidths<S extends Schema>(
  view: EditorView<S>,
  handle: number
): ColumnWidth[] {
  const { state } = view;
  const { doc } = state;

  const row = findParent(
    doc.resolve(handle),
    (n) => n.type.spec.tableRole === "row"
  );

  const table = findParent(
    doc.resolve(handle),
    (n) => n.type.spec.tableRole === "table"
  );

  if (row == null || table == null) {
    return [];
  }

  const { node, start } = row;

  const cells = new Array<ColumnWidth>();

  node.descendants((child, childPos) => {
    const cellPos = start + childPos;
    const width = currentColWidth(view, cellPos, child.attrs as CellAttributes);
    cells.push({
      node: child,
      pos: cellPos,
      width: width,
      col: getColForPos(table, { pos: cellPos, node: child })
    });
    return false;
  });

  return cells;
}

function currentColWidth(
  view: EditorView,
  cellPos: number,
  { colspan, colwidth }: CellAttributes
): number {
  const width = colwidth && colwidth[colwidth.length - 1];
  if (width) {
    return width;
  }

  const dom = view.domAtPos(cellPos);
  const tableWidth = getTableWidth(view, cellPos);
  const node = dom.node.childNodes[dom.offset] as HTMLElement;
  let domWidth = (node.offsetWidth / tableWidth) * 100;
  let parts = colspan;

  if (colwidth) {
    for (let i = 0; i < colspan; i++) {
      if (colwidth[i]) {
        domWidth -= colwidth[i];
        parts--;
      }
    }
  }

  return domWidth / parts;
}

function domCellAround(target: Element | null) {
  while (target && target.nodeName !== "TD" && target.nodeName !== "TH")
    target = target.classList.contains("ProseMirror")
      ? null
      : (target.parentNode as Element);
  return target;
}

function edgeCell(
  view: EditorView,
  event: MouseEvent,
  side: "left" | "right",
  handleWidth: number
) {
  // posAtCoords returns inconsistent positions when cursor is moving
  // across a collapsed table border. Use an offset to adjust the
  // target viewport coordinates away from the table border.
  const offset = side === "right" ? -handleWidth : handleWidth;
  const found = view.posAtCoords({
    left: event.clientX + offset,
    top: event.clientY
  });
  if (!found) {
    return -1;
  }

  const { pos } = found;
  const $cell = cellAround(view.state.doc.resolve(pos));
  if (!$cell) {
    return -1;
  }

  if (side === "right") {
    return $cell.pos;
  }

  const map = TableMap.get($cell.node(-1));
  const start = $cell.start(-1);
  const index = map.map.indexOf($cell.pos - start);

  return index % map.width === 0 ? -1 : start + map.map[index - 1];
}

function draggedWidth(
  dragging: { startX: number; startWidth: ColumnWidth[] },
  event: MouseEvent,
  tableWidth: number,
  minCellWidth: number,
  handle: number
): ColumnWidth[] {
  const offset = ((event.clientX - dragging.startX) / tableWidth) * 100;

  const { startWidth } = dragging;
  const widths = startWidth.slice();

  const leftIndex = widths.findIndex((x) => x.pos === handle);
  const rightIndex = leftIndex + 1;
  const left = widths[leftIndex];
  const right = widths[rightIndex];
  const total = left.width + right.width;

  widths[leftIndex] = {
    ...left,
    width: Math.max(
      minCellWidth,
      Math.min(left.width + offset, total - minCellWidth)
    )
  };

  widths[rightIndex] = {
    ...right,
    width: Math.max(
      minCellWidth,
      Math.min(right.width - offset, total - minCellWidth)
    )
  };

  return widths;
}

function updateHandle(view: EditorView, value: number) {
  view.dispatch(view.state.tr.setMeta(key, { setHandle: value }));
}

function updateLeftAndRightColumnWidth(
  view: EditorView,
  widths: ColumnWidth[]
): void {
  widths.forEach(({ pos, width }) => {
    updateColumnWidth(view, pos, width);
  });
}

function updateColumnWidth(view: EditorView, cell: number, width: number) {
  const $cell = view.state.doc.resolve(cell);
  const table = $cell.node(-1);
  const map = TableMap.get(table);
  const start = $cell.start(-1);

  const nodeAfter = $cell.nodeAfter!;
  const nodeAfterAttrs = nodeAfter.attrs as CellAttributes;

  const col = map.colCount($cell.pos - start) + nodeAfterAttrs.colspan - 1;
  let tr = view.state.tr;

  for (let row = 0; row < map.height; row++) {
    const mapIndex = row * map.width + col;
    // Rowspanning cell that has already been handled
    if (row && map.map[mapIndex] === map.map[mapIndex - map.width]) {
      continue;
    }

    const pos = map.map[mapIndex];
    const { attrs } = table.nodeAt(pos)!;
    const index = attrs.colspan === 1 ? 0 : col - map.colCount(pos);

    if (attrs.colwidth && attrs.colwidth[index] === width) {
      continue;
    }

    const colwidth = attrs.colwidth
      ? attrs.colwidth.slice()
      : zeroes(attrs.colspan);
    colwidth[index] = width;

    tr = tr.setNodeMarkup(
      start + pos,
      undefined,
      setAttr(attrs, "colwidth", colwidth)
    );
  }

  if (tr.docChanged) {
    view.dispatch(tr);
  }
}

function getTableForHandle<S extends Schema>(
  view: EditorView<S>,
  handle: number
): {
  node: PMNode<S>;
  start: number;
  element: HTMLTableElement;
  colgroup: HTMLElement;
} {
  const $cell = view.state.doc.resolve(handle);
  const table = $cell.node(-1);
  const start = $cell.start(-1);

  let dom = view.domAtPos($cell.start(-1)).node;
  while (dom.nodeName !== "TABLE") {
    if (dom.parentNode == null) {
      break;
    }
    dom = dom.parentNode;
  }

  return {
    node: table,
    start: start,
    element: dom as HTMLTableElement,
    colgroup: dom.firstChild as HTMLElement
  };
}

function getColForPos<S extends Schema>(
  table: { start: number; node: PMNode<S> },
  cell: { pos: number; node: PMNode<S> }
): number {
  const { pos, node } = cell;
  const cellAttrs = node.attrs as CellAttributes;

  return (
    TableMap.get(table.node).colCount(pos - table.start) + cellAttrs.colspan - 1
  );
}

function displayColumnWidth(
  view: EditorView,
  handle: number,
  widths: ColumnWidth[],
  cellMinWidth: number
): void {
  const { node, element, colgroup } = getTableForHandle(view, handle);

  updateColumns(node, colgroup, element, cellMinWidth, widths);
}

function handleDecorations(state: EditorState, cell: number) {
  const decorations = [];
  const $cell = state.doc.resolve(cell);
  const table = $cell.node(-1);
  const map = TableMap.get(table);
  const start = $cell.start(-1);

  const nodeAfter = $cell.nodeAfter!;
  const nodeAfterAttrs = nodeAfter.attrs as CellAttributes;

  const col = map.colCount($cell.pos - start) + nodeAfterAttrs.colspan;

  for (let row = 0; row < map.height; row++) {
    const index = col + row * map.width - 1;
    // For positions that are have either a different cell or the end
    // of the table to their right, and either the top of the table or
    // a different cell above them, add a decoration
    if (
      (col === map.width || map.map[index] !== map.map[index + 1]) &&
      (row === 0 || map.map[index - 1] !== map.map[index - 1 - map.width])
    ) {
      const cellPos = map.map[index];
      const pos = start + cellPos + table.nodeAt(cellPos)!.nodeSize - 1;
      const dom = document.createElement("div");
      dom.className = "column-resize-handle";
      decorations.push(Decoration.widget(pos, dom));
    }
  }

  return DecorationSet.create(state.doc, decorations);
}

export function updateColumns(
  node: PMNode,
  colgroup: HTMLElement,
  _table: HTMLTableElement,
  _cellMinWidth: number = CELL_MIN_WIDTH,
  override?: ColumnWidth[]
) {
  let nextDOM = colgroup.firstChild;
  const row = node.firstChild!;

  for (let i = 0, col = 0; i < row.childCount; i++) {
    const { colspan, colwidth } = row.child(i).attrs as CellAttributes;
    const cell = override != null ? override[i] : null;

    for (let j = 0; j < colspan; j++, col++) {
      let hasWidth: number | null;
      if (cell != null && cell.col === col) {
        hasWidth = cell.width;
      } else {
        hasWidth = colwidth && colwidth[j];
      }

      const cssWidth = hasWidth ? hasWidth + "%" : "";

      if (!nextDOM) {
        colgroup.appendChild(
          document.createElement("col")
        ).style.width = cssWidth;
      } else {
        const nextDOMElement = nextDOM as HTMLElement;
        if (nextDOMElement.style.width !== cssWidth) {
          nextDOMElement.style.width = cssWidth;
        }
        nextDOM = nextDOM.nextSibling;
      }
    }
  }

  while (nextDOM) {
    const after = nextDOM.nextSibling;
    nextDOM.parentNode!.removeChild(nextDOM);
    nextDOM = after;
  }

  if (override) {
    resizeBarChartCanvasInGrid(_table, override);
  }
}

function resizeBarChartCanvasInGrid(
  table: HTMLTableElement,
  override: ColumnWidth[]
) {
  let customGridWidth = 0;
  if (!table.parentElement) return;
  else {
    customGridWidth = table.parentElement.clientWidth;
  }
  const tableBody = table.children[1];
  const tableRows = tableBody.children;
  const tableRowTd = Array.from(tableRows).slice(1, tableRows.length - 1);
  let padding = undefined;
  for (let i = 0; i < override.length; i++) {
    for (let j = 0; j < tableRowTd.length; j++) {
      const td = tableRowTd[j].children[i] as HTMLElement;
      if (td == null) continue;
      if (padding == null) {
        padding = +window
          .getComputedStyle(td)
          .getPropertyValue("padding-left")
          .replaceAll("px", "");
      }
      // For repeat and not repeated grids
      const width = Math.trunc(override[i].width);
      const widthPX = +(
        (customGridWidth * width) / 100 -
        padding * 2 -
        1 * 2
      ).toFixed(2);
      const barChart = Array.from(td.children).find(
        (el) => el.tagName === "BAR-CHART"
      );
      if (td.classList.contains("ProseMirror-input-cell") && barChart != null) {
        const canvasParent = td.querySelector(
          ".bar-chart-canvas-container"
        ) as HTMLElement;
        if (barChart.querySelector(".bar-chart-container.horizontal")) {
          if (canvasParent != null) {
            canvasParent.style.width = `${+((widthPX * 80) / 100).toFixed(
              2
            )}px`;
          }
          const yAxisContainer = barChart.querySelector(
            ".bar-chart-yaxis-container"
          ) as HTMLElement;
          if (yAxisContainer != null) {
            yAxisContainer.style.width = `${+((widthPX * 20) / 100).toFixed(
              2
            )}px`;
          }
        } else {
          if (canvasParent != null) {
            canvasParent.style.width = `${widthPX}px`;
          }
        }
      }
    }
  }
}
