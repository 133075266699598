import {
  Command,
  Editor as BlueXEditor,
  LinkActiveValue,
  LinkKey,
  Schema
} from "@blue-x/editor";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ToolbarDropdown, {
  ToolbarDropdownToggle,
  useToolbarDropdown
} from "../ToolbarDropdown";
import ToolbarIcon from "../ToolbarIcon";
import ToolbarTooltip from "../ToolbarTooltip";
import "./Link.css";

interface LinkDefinitionProps {
  command: Command;
  display?: string;
  displayDisabled: boolean;
}

const LinkDefinition: React.FC<LinkDefinitionProps> = (props) => {
  const { command, displayDisabled, display: displayInput } = props;
  const dropdown = useToolbarDropdown();

  const [display, setDisplay] = useState("");
  const [url, setUrl] = useState("");

  useEffect(() => {
    if (displayInput != null) {
      setDisplay(displayInput);
    }
  }, [displayInput, setDisplay]);

  const updateDisplay = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDisplay(event.target.value);
    },
    [setDisplay]
  );

  const updateUrl = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUrl(event.target.value);
    },
    [setUrl]
  );

  const executeCommand = useCallback(() => {
    if (display.length === 0) {
      command.execute({ url: url, text: url });
    } else {
      command.execute({ url: url, text: display });
    }
    const { opened, setOpened } = dropdown;
    setOpened(!opened);
  }, [url, display, command, dropdown]);

  const insertDisabled = useMemo(() => {
    return url.length === 0;
  }, [url]);

  return (
    <div className="LinkDefinition">
      <label>Display</label>
      <input
        type="text"
        placeholder="display"
        value={display}
        disabled={displayDisabled}
        onChange={updateDisplay}
      />
      <label>Url</label>
      <input type="text" placeholder="url" value={url} onChange={updateUrl} />

      <button onClick={executeCommand} disabled={insertDisabled}>
        Insert
      </button>
    </div>
  );
};

interface ToolbarLinkButtonProps {
  editor: BlueXEditor<Schema>;
}

export const ToolbarLinkButton: React.FC<ToolbarLinkButtonProps> = (props) => {
  const { editor } = props;
  const { commands, state } = editor;

  const linkState = useMemo(() => {
    const rawLinkState = LinkKey.getState(state);
    return rawLinkState != null
      ? rawLinkState
      : { textToDisplay: undefined, textToDisplayDisabled: false };
  }, [state]);

  const textToDisplay = useMemo(() => {
    return linkState.textToDisplay;
  }, [linkState]);

  const textToDisplayDisabled = useMemo(() => {
    return linkState.textToDisplayDisabled;
  }, [linkState]);

  return (
    <ToolbarDropdown
      toggle={
        <ToolbarTooltip title="Link" subtitle="Add a link.">
          <ToolbarDropdownToggle
            active={commands.insertLink?.isActive()}
            disabled={!commands.insertLink?.isEnabled()}
          >
            <ToolbarIcon icon="link" />
          </ToolbarDropdownToggle>
        </ToolbarTooltip>
      }
      content={
        <LinkDefinition
          command={commands.insertLink}
          display={textToDisplay}
          displayDisabled={textToDisplayDisabled}
        />
      }
    />
  );
};

interface LinkPropertiesPanelProps {
  editor: BlueXEditor<Schema>;
}

export const LinkPropertiesPanel: React.FC<LinkPropertiesPanelProps> = (
  props
) => {
  const { editor } = props;
  const { commands } = editor;

  const activeValue: LinkActiveValue = useMemo(() => {
    return commands.updateLink.activeValue();
  }, [commands]);

  const [url, setUrl] = useState("");

  useEffect(() => {
    setUrl(activeValue.url);
  }, [activeValue, setUrl]);

  const updateUrl = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUrl(event.target.value);
    },
    [setUrl]
  );

  const updateUrlCommand = useCallback(() => {
    commands.updateLink.execute({ url: url }, false);
  }, [commands, url]);

  const removeLink = useCallback(() => {
    commands.removeLink.execute();
  }, [commands]);

  return (
    <div className="LinkPropertiesPanel">
      <label>Url</label>
      <input
        type="text"
        placeholder="url"
        value={url}
        onChange={updateUrl}
        onBlur={updateUrlCommand}
      />
      <button onClick={removeLink}>Remove link</button>
    </div>
  );
};
