import { Node, NodeType, ResolvedPos, Schema } from "prosemirror-model";
import { EditorState, NodeSelection } from "prosemirror-state";
import { findWrapping } from "prosemirror-transform";
import { GapCursor } from "../../editor/plugins/gap-cursor";
import { getResolvedSelection } from "../../util";

export function isList<S extends Schema>(node: Node<S>, schema: S) {
  const listTypes = [schema.nodes.bulletList, schema.nodes.orderedList];
  return listTypes.includes(node.type);
}

export function isListActive<S extends Schema>(
  type: NodeType<S>
): (state: EditorState<S>) => boolean {
  return (state: EditorState<S>) => {
    const { schema, selection } = state;
    const { from, to } = selection;

    if (selection instanceof GapCursor) {
      return false;
    }

    let nodes: Node<S>[] = [];
    state.doc.nodesBetween(from, to, (node) => {
      nodes.push(node);
      if (isList(node, schema)) {
        return false;
      }

      return;
    });

    const filteredNodes = nodes.filter((node) => node.type === type);
    return nodes.length === filteredNodes.length;
  };
}

function isWrappingPossible<S extends Schema>(
  nodeType: NodeType<S>,
  $from: ResolvedPos<S>,
  $to: ResolvedPos<S>
): boolean {
  const range = $from.blockRange($to);
  if (!range) {
    return false;
  }

  const wrap = findWrapping(range, nodeType);
  if (!wrap) {
    return false;
  }

  return true;
}

export function isListEnabled<S extends Schema>(
  type: NodeType<S>
): (state: EditorState<S>) => boolean {
  return (state) => {
    if (state.selection instanceof GapCursor) {
      return false;
    }

    if (
      state.selection instanceof NodeSelection &&
      !state.selection.node.isInline
    ) {
      return false;
    }

    const { schema } = state;
    const { bulletList, orderedList } = schema.nodes;

    const { $from, $to } = getResolvedSelection(state);

    const fromNode = $from.node($from.depth - 2);
    const toNode = $to.node($to.depth - 2);
    const selectionContainsList =
      !fromNode ||
      [bulletList, orderedList].includes(fromNode.type) ||
      !toNode ||
      [bulletList, orderedList].includes(toNode.type);

    const canWrapList = isWrappingPossible(type, $from, $to);

    return selectionContainsList || canWrapList;
  };
}

// This will return (depth - 1) for root list parent of a list.
export function getListLiftTarget<S extends Schema>(
  schema: S,
  resPos: ResolvedPos<S>
): number {
  let target = resPos.depth;
  const { bulletList, orderedList, listItem } = schema.nodes;
  for (let i = resPos.depth; i > 0; i--) {
    const node = resPos.node(i);
    if (node.type === bulletList || node.type === orderedList) {
      target = i;
    }
    if (
      node.type !== bulletList &&
      node.type !== orderedList &&
      node.type !== listItem
    ) {
      break;
    }
  }
  return target - 1;
}
