import {
  Editor as BlueXEditor,
  InputFileActiveValue,
  InputFileDefaults,
  InputFileFormat,
  InputFileWidth,
  Schema
} from "@blue-x/editor";
import React, { useCallback, useMemo } from "react";
import {
  CheckboxFormControl,
  NumberFormControl,
  QuestionTitleFormControl,
  SelectFormControl,
  TextFormControl
} from "../ProportiesPanelControls";
import "./InputFile.css";

interface InputFilePropertiesPanelProps {
  editor: BlueXEditor<Schema>;
}

export const InputFilePropertiesPanel: React.FC<InputFilePropertiesPanelProps> = (
  props
) => {
  const { editor } = props;
  const { commands } = editor;

  const update = useCallback(
    (value) => {
      commands.updateInputFile.execute(value, false);
    },
    [commands]
  );

  const activeValue: InputFileActiveValue = useMemo(() => {
    return commands.updateInputFile.activeValue();
  }, [commands]);

  return (
    <div className="InputFilePropertiesPanel">
      <SelectFormControl
        label={"Format"}
        value={activeValue.format}
        onUpdate={(value) => {
          update({ format: value });
        }}
      >
        <option value={InputFileFormat.document}>Document</option>
        <option value={InputFileFormat.image}>Image</option>
        <option value={InputFileFormat.sound}>Sound</option>
        <option value={InputFileFormat.video}>Video</option>
      </SelectFormControl>

      <NumberFormControl
        label={"Width"}
        value={activeValue.width}
        min={InputFileWidth.minimum}
        max={InputFileWidth.maximum}
        onUpdate={(value) => {
          update({ width: value });
        }}
      />

      <QuestionTitleFormControl
        editor={editor}
        label={"Question title*"}
        value={activeValue.questionTitle}
      />

      <TextFormControl
        label={"Description"}
        value={activeValue.description}
        onUpdate={(value) => {
          update({ description: value });
        }}
      />

      <TextFormControl
        label={"Coding"}
        value={activeValue.coding}
        onUpdate={(value) => {
          update({ coding: value });
        }}
      />

      <NumberFormControl
        label={"Max Size"}
        value={activeValue.maxSize}
        max={InputFileDefaults.FileMaxSizeSupported}
        onUpdate={(value) => {
          update({ maxSize: value });
        }}
      />

      <NumberFormControl
        label={"Minimum number of files"}
        value={activeValue.minFiles}
        min={InputFileDefaults.MinFiles}
        max={activeValue.maxFiles}
        onUpdate={(value) => {
          update({ minFiles: value });
        }}
      />

      <NumberFormControl
        label={"Maximum number of files"}
        value={activeValue.maxFiles}
        min={activeValue.minFiles}
        onUpdate={(value) => {
          update({ maxFiles: value });
        }}
      />

      {activeValue.format != null &&
      activeValue.format === InputFileFormat.image ? (
        <div>
          <NumberFormControl
            label={"Image Min Width (px)"}
            value={activeValue.imageMinWidth}
            min={InputFileDefaults.ImageMinWidth}
            onUpdate={(value) => {
              update({ imageMinWidth: value });
            }}
          />

          <NumberFormControl
            label={"Image Min Height (px)"}
            value={activeValue.imageMinHeight}
            min={InputFileDefaults.ImageMinHeight}
            onUpdate={(value) => {
              update({ imageMinHeight: value });
            }}
          />
        </div>
      ) : null}

      <CheckboxFormControl
        label={"Required"}
        value={activeValue.required}
        onUpdate={(value) => {
          update({ required: value });
        }}
      />
    </div>
  );
};
