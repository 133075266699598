import { Schema } from "prosemirror-model";
import { Plugin, PluginKey } from "prosemirror-state";
import { Color, ColorPalette, ColorPaletteId } from "../types";

export interface ColorSchemeState {
  palette: ColorPalette;
}

export const colorPaletteKey = new PluginKey<ColorSchemeState, Schema>();

export function colorSchemePicker(
  colorPalettes: ColorPalette[],
  defaultColorPaletteId: ColorPaletteId
) {
  const getPaletteForId = (id: string) => {
    return colorPalettes.find((palette) => palette.id === id);
  };

  const defaultColorPalette = getPaletteForId(defaultColorPaletteId);
  if (defaultColorPalette == null) {
    throw new Error(
      `Default color palette must be provided to ${colorSchemePicker.name}`
    );
  }

  return new Plugin<ColorSchemeState, Schema>({
    key: colorPaletteKey,
    state: {
      init() {
        return {
          palette: defaultColorPalette
        };
      },
      apply(tr, value) {
        const meta = tr.getMeta(colorPaletteKey) as
          | { paletteId: ColorPaletteId }
          | undefined;

        if (meta == null) {
          return value;
        }

        const { paletteId } = meta;
        if (paletteId == null) {
          return value;
        }

        const colorPalette = getPaletteForId(paletteId);

        return {
          ...value,
          palette: colorPalette == null ? defaultColorPalette : colorPalette
        };
      }
    },
    props: {
      attributes(state) {
        const { palette } = this.getState(state);

        const paletteType: string =
          palette && palette.type ? palette.type : "light";

        const colorVariable = (
          type: "primary" | "theme",
          index: number,
          color?: Color
        ) => {
          const variableName = `--color-scheme-${type}-${index}`;
          if (color != null) {
            return `${variableName}: ${color.rgbaCode}`;
          }
          return null;
        };

        const primary = palette.colorScheme.primary;
        const theme = palette.colorScheme.theme;

        const colors = [
          colorVariable("primary", 1, primary.one),
          colorVariable("primary", 2, primary.two),
          colorVariable("primary", 3, primary.three),
          colorVariable("primary", 4, primary.four),
          colorVariable("primary", 5, primary.five),
          colorVariable("primary", 6, primary.six),
          colorVariable("theme", 1, theme.one),
          colorVariable("theme", 2, theme.two),
          colorVariable("theme", 3, theme.three),
          colorVariable("theme", 4, theme.four),
          colorVariable("theme", 5, theme.five)
        ];

        return {
          "data-palette-type": paletteType,
          style: colors.filter((color) => color != null).join(";")
        };
      }
    }
  });
}
