import { Node, Schema } from "prosemirror-model";
import { AllSelection } from "prosemirror-state";
import {
  CommandConfiguration,
  CommandConfigurations,
  Extension
} from "../../editor";
import { NodeDTO } from "../../util";

export interface ImportProps {
  nodes: NodeDTO[];
}

export class Import implements Extension<Schema> {
  get name(): string {
    return "import";
  }

  commands(schema: Schema): CommandConfigurations<Schema> {
    return {
      import: this.importCommand(schema)
    };
  }

  private importCommand(
    _schema: Schema
  ): CommandConfiguration<Schema, ImportProps, void> {
    return {
      isActive: () => false,
      isEnabled: () => true,
      execute: (props) => {
        if (props == null) {
          throw new Error(`To import, ImportProps needs to be provided.`);
        }

        const { nodes } = props;
        if (nodes == null) {
          throw new Error(`To import, ImportProps needs to be provided.`);
        }

        return (state, dispatch) => {
          const { schema, doc } = state;

          const converted = nodes.reduce((acc, n) => {
            try {
              return acc.concat(schema.nodeFromJSON(n));
            } catch {
              return acc;
            }
          }, new Array<Node<Schema>>());

          if (dispatch) {
            const tr = state.tr;
            const selection = new AllSelection(doc);
            tr.insert(
              selection.to,
              converted.concat(schema.nodes.paragraph.create())
            );
            dispatch(tr);
          }
          return true;
        };
      },
      requiresEditable: false
    };
  }
}
