import { Node, NodeType, Schema } from "prosemirror-model";
import { Plugin, PluginKey } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";
import { NodeWithPos } from "../../../util";
import {
  isSelectionRemoveButton,
  selectionOverlay
} from "../selection-overlay";
import {
  decorationForTarget,
  getNodeForTarget,
  isSelectionInTargetOverlay
} from "../selection-target";

export interface StartSelectionAction {
  type: "start";
  allowedTypes: NodeType[];
}

export interface UpdateSelectionAction {
  type: "update";
  node: NodeWithPos<Schema>;
  emit: boolean;
}

export interface RemoveSelectionAction {
  type: "remove";
  emit: boolean;
}

export interface EndSelectionAction {
  type: "end";
}

const CLASS_NAMES = {
  Selector: "ProseMirror-input-selector"
};

interface InputSelectorState {
  selection: string | null;
  allowedTypes: NodeType[];
  targets: Decoration[];
  selected: Decoration[];
}

export const inputSelectorKey = new PluginKey<
  InputSelectorState | null,
  Schema
>("inputSelector");

export function inputSelector(onChange: (node: string | null) => void) {
  return new Plugin({
    key: inputSelectorKey,
    state: {
      init() {
        return null;
      },
      apply(tr, value, _oldState, _newState) {
        const meta = tr.getMeta(inputSelectorKey) as
          | StartSelectionAction
          | UpdateSelectionAction
          | RemoveSelectionAction
          | EndSelectionAction
          | undefined;

        if (meta == null) {
          return value;
        }

        switch (meta.type) {
          case "start":
            const allowedTypes = meta.allowedTypes;
            const targets = decorationsForStart(tr.doc, allowedTypes);

            return {
              selection: null,
              allowedTypes: allowedTypes,
              targets: targets,
              selected: []
            };

          case "update":
            if (value != null) {
              const { node, pos } = meta.node;
              const { selection, selected } = value;
              const id = node.attrs.id as string;

              const updatedSelection = id;
              const selectionHasBeenUpdated = selection !== updatedSelection;
              const updatedSelected = selectionHasBeenUpdated
                ? decorationsForSelectedNode(pos, pos + node.nodeSize)
                : selected;

              if (selectionHasBeenUpdated && meta.emit) {
                onChange(updatedSelection);
              }

              return {
                ...value,
                selection: updatedSelection,
                selected: updatedSelected
              };
            } else {
              return null;
            }

          case "remove":
            if (value != null) {
              const { selection } = value;

              const updatedSelection = null;
              const selectionHasBeenUpdated = selection !== updatedSelection;

              if (selectionHasBeenUpdated && meta.emit) {
                onChange(updatedSelection);
              }

              return {
                ...value,
                selection: updatedSelection,
                selected: []
              };
            } else {
              return null;
            }

          case "end":
            return null;

          default:
            return value;
        }
      }
    },
    props: {
      decorations(state) {
        const value = this.getState(state);
        if (value == null) {
          return null;
        }

        const { doc } = state;
        const { targets, selected } = value;

        return DecorationSet.create(doc, targets.concat(selected));
      },
      attributes(state) {
        const value = this.getState(state);
        if (value == null) {
          return null;
        }

        let attributes: Record<string, string> = { "data-selector": "input" };

        return attributes;
      },
      handleDOMEvents: {
        click(view, event) {
          const state = this.getState(view.state);
          if (state == null) {
            return false;
          }

          if (isSelectionRemoveButton(event.target as HTMLElement)) {
            return false;
          }

          if (!isSelectionInTargetOverlay(event.target as HTMLElement)) {
            return false;
          }

          const target = getNodeForTarget(view, event.target as HTMLElement);
          if (target != null && state.allowedTypes.includes(target.node.type)) {
            const action: UpdateSelectionAction = {
              type: "update",
              node: target,
              emit: true
            };
            view.dispatch(view.state.tr.setMeta(inputSelectorKey, action));
            return true;
          }

          return false;
        }
      }
    }
  });
}

function decorationForNode(from: number, to: number): Decoration {
  return decorationForTarget(from, to, CLASS_NAMES.Selector);
}

function decorationsForSelectedNode(from: number, _to: number): Decoration[] {
  return [
    Decoration.widget(from, (view, getPos) => {
      const pos = getPos();
      const node = view.nodeDOM(pos)! as HTMLElement;

      const box = node.getBoundingClientRect();
      const width = box.width;
      const height = node.offsetHeight;
      const top = node.offsetTop;
      const left = node.offsetLeft;

      const onClick = () => {
        const action: RemoveSelectionAction = {
          type: "remove",
          emit: true
        };
        view.dispatch(view.state.tr.setMeta(inputSelectorKey, action));
      };

      return selectionOverlay(
        CLASS_NAMES.Selector,
        { id: "", label: "1" },
        width,
        height,
        top,
        left,
        onClick
      );
    })
  ];
}

function decorationsForStart(
  doc: Node,
  allowedTypes: NodeType[]
): Decoration[] {
  const targets = new Array<Decoration>();

  doc.descendants((node, pos) => {
    if (allowedTypes.includes(node.type)) {
      const from = pos;
      const to = from + node.nodeSize;

      targets.push(decorationForNode(from, to));
    }
  });

  return targets;
}
