import { DOMParser, Fragment, Node, NodeSpec } from "prosemirror-model";
import { DocumentBuilders, NodeConfig } from "../../../editor/extension/schema";
import { CustomAreaSchema } from "../schema";

function setCustomAreaAttrs(node: Node): { [key: string]: string } {
  return {
    id: node.attrs.id,
    "data-description": node.attrs.description,
    "data-name": node.attrs.name
  };
}

function getCustomAreaAttrs(element: Element): { [key: string]: any } {
  const attrs: {
    id?: string;
    description?: string;
    name?: string;
  } = {};

  const id = element.getAttribute("id");
  if (id != null) {
    attrs.id = id;
  }

  const description = element.getAttribute("data-description");
  if (description != null) {
    attrs.description = description;
  }

  const name = element.getAttribute("data-name");
  if (name != null) {
    attrs.name = name;
  }

  return attrs;
}

export class CustomAreaNode implements NodeConfig {
  get name(): string {
    return "customArea";
  }

  get spec(): NodeSpec {
    return {
      group: "rootBlock",
      content: "contentVariant+",
      draggable: true,
      selectable: true,
      focusable: true,
      allowGapCursor: true,
      allowIndentation: false,
      allowAlignment: false,
      isolating: true,
      blockSelection: true,
      attrs: {
        id: {
          default: null
        },
        name: {
          default: null
        },
        description: {
          default: null
        }
      },
      parseDOM: [
        {
          tag: "custom-area",
          getAttrs(node) {
            if (typeof node === "string") {
              return false;
            }
            return getCustomAreaAttrs(node as Element);
          }
        },
        {
          tag: "[data-content-area]",
          getAttrs(node) {
            if (typeof node === "string") {
              return false;
            }
            return getCustomAreaAttrs(node as Element);
          },
          getContent: (node, schema) => {
            return legacyContentVariant(node as Element, schema);
          }
        }
      ],
      toDOM(node) {
        return ["custom-area", setCustomAreaAttrs(node), 0];
      }
    };
  }

  get builders(): DocumentBuilders {
    return {
      customArea: { nodeType: "customArea" }
    };
  }
}

function legacyContentVariant(
  element: Element,
  schema: CustomAreaSchema
): Fragment {
  const defaultVariantId = element.getAttribute("data-default-variant-id");

  const parser = DOMParser.fromSchema(schema);
  const slice = parser.parseSlice(element);
  const fragment = slice.content;

  let updated = Fragment.from(fragment);

  fragment.forEach((node, _offset, index) => {
    if (
      node.type === schema.nodes.contentVariant &&
      node.attrs.id === defaultVariantId
    ) {
      updated = updated.replaceChild(
        index,
        node.type.create(
          { ...node.attrs, default: true, active: true },
          node.content,
          node.marks
        )
      );
    }
  });

  return updated;
}
