import { Schema } from "prosemirror-model";
import { EditorState } from "prosemirror-state";
import { selectionFocusKey } from "../../editor/plugins/selection-focus";

export function focusedReport(state: EditorState<Schema>) {
  const focused = selectionFocusKey.getState(state);
  if (
    focused != null &&
    focused.node.type.spec.group &&
    focused.node.type.spec.group.split(" ").includes("report")
  ) {
    return focused;
  } else {
    return undefined;
  }
}
