import { Node, NodeSpec } from "prosemirror-model";
import { DocumentBuilders, NodeConfig } from "../../editor/extension/schema";
import { toNumberValue } from "../../util";
import { AlignmentType } from "../alignment";
import {
  getQuestionTitleAttrs,
  setQuestionTitleAttrs
} from "../question-title";
import { InputTextControlType, InputTextWidth } from "./schema";

function getInputTextAttrs(element: Element): { [key: string]: any } {
  const attrs: {
    id?: string;
    controlType?: InputTextControlType;
    rows?: number;
    width?: number;
    questionTitleText?: string;
    questionTitleRefElementId?: string[];
    description?: string;
    coding?: string;
    defaultValue?: string;
    watermark?: string;
    meaning?: string | null;
    textLength?: number;
    format?: string;
    required?: boolean;
    regexp?: string | null;
    alignment?: AlignmentType;
  } = { ...getQuestionTitleAttrs(element) };

  const id = element.getAttribute("id");
  if (id != null) {
    attrs.id = id;
  }

  const controlType = element.getAttribute(
    "data-control-type"
  ) as InputTextControlType;
  if (controlType != null) {
    switch (controlType) {
      case InputTextControlType.textarea:
      case InputTextControlType.textbox:
        attrs.controlType = controlType;
        break;
      default:
        break;
    }
  }

  const rows = toNumberValue(element.getAttribute("data-rows"), 0);
  if (rows != null) {
    attrs.rows = rows;
  }

  const width = toNumberValue(element.getAttribute("data-width"), 0);
  if (width != null) {
    attrs.width = width;
  }

  const description = element.getAttribute("data-description");
  if (description != null) {
    attrs.description = description;
  }

  const coding = element.getAttribute("data-coding");
  if (coding != null) {
    attrs.coding = coding;
  }

  const defaultValue = element.getAttribute("data-default-value");
  if (defaultValue != null) {
    attrs.defaultValue = defaultValue;
  }

  const watermark = element.getAttribute("data-watermark");
  if (watermark != null) {
    attrs.watermark = watermark;
  }

  const meaning = element.getAttribute("data-meaning");
  if (meaning != null) {
    attrs.meaning = meaning.length === 0 ? null : meaning;
  }

  const textLength = toNumberValue(element.getAttribute("data-text-length"), 0);
  if (textLength != null) {
    attrs.textLength = textLength;
  }

  const required = element.getAttribute("data-required");
  if (required != null) {
    attrs.required = required.toLowerCase() === "true";
  }

  const format = element.getAttribute("data-format");
  if (format != null) {
    attrs.format = format;
  }

  const regexp = element.getAttribute("data-regexp");
  if (regexp != null) {
    attrs.regexp = attrs.format === "none" ? null : decodeURI(regexp);
  }

  const alignment = element.getAttribute("data-alignment") as AlignmentType;
  if (alignment != null) {
    switch (alignment) {
      case "center":
      case "left":
      case "right":
        attrs.alignment = alignment;
        break;
      default:
        break;
    }
  }

  return attrs;
}

function setInputTextAttrs(node: Node): { [key: string]: string } {
  return {
    id: node.attrs.id,
    ...setQuestionTitleAttrs(node),
    "data-control-type": node.attrs.controlType,
    "data-rows": node.attrs.rows,
    "data-width": node.attrs.width,
    "data-description": node.attrs.description,
    "data-coding": node.attrs.coding,
    "data-default-value": node.attrs.defaultValue,
    "data-watermark": node.attrs.watermark,
    "data-meaning": node.attrs.meaning,
    "data-text-length": node.attrs.textLength,
    "data-format": node.attrs.format,
    "data-required": node.attrs.required,
    "data-regexp": node.attrs.regexp,
    "data-alignment": node.attrs.alignment
  };
}

export class InputTextNode implements NodeConfig {
  get name(): string {
    return "inputText";
  }

  get spec(): NodeSpec {
    return {
      group: "block input gridInput",
      draggable: true,
      selectable: true,
      focusable: true,
      allowGapCursor: true,
      allowIndentation: false,
      allowAlignment: ["left", "center", "right"],
      blockAlignment: true,
      attrs: {
        id: { default: null },
        controlType: { default: InputTextControlType.textbox },
        rows: { default: 1 },
        width: { default: InputTextWidth.default },
        questionTitleText: { default: null },
        questionTitleRefElementId: { default: new Array<string>() },
        description: { default: "" },
        coding: { default: "" },
        defaultValue: { default: "" },
        watermark: { default: "" },
        meaning: { default: null },
        textLength: { default: null },
        format: { default: "none" },
        required: { default: false },
        regexp: { default: null },
        alignment: { default: null }
      },
      parseDOM: [
        {
          tag: "input-text",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getInputTextAttrs(node as Element);
          }
        },
        {
          tag: '[data-input="text"]',
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getInputTextAttrs(node as Element);
          }
        }
      ],
      toDOM(node) {
        return ["input-text", setInputTextAttrs(node)];
      },
      applyBlueprint(
        attrs: Record<string, any>,
        blueprint: Record<string, any>
      ) {
        const updated = { ...attrs };

        updated.required = blueprint.required;
        updated.controlType = blueprint.controlType;
        updated.watermark = blueprint.watermark;
        updated.defaultValue = blueprint.defaultValue;
        updated.rows = blueprint.rows;
        updated.meaning = blueprint.meaning;
        updated.textLength = blueprint.textLength;
        updated.format = blueprint.format;
        updated.regexp = blueprint.regexp;

        return updated;
      }
    };
  }

  get builders(): DocumentBuilders {
    return { inputText: { nodeType: "inputText" } };
  }
}
