import { Schema } from "prosemirror-model";
import { Selection } from "prosemirror-state";
import {
  CommandConfiguration,
  CommandConfigurations
} from "../../../editor/extension";
import { editableTransaction } from "../../../editor/plugins/editable";
import { findNode } from "../../../util";
import { RangeSelection } from "../types";
import { InlineRange } from "./inline-range";
import {
  EndSelectionAction,
  inlineSelectorKey,
  ReplaceSelectionAction,
  StartSelectionAction
} from "./plugin";

export interface UpdateSelectionCommandProps {
  ranges: RangeSelection[];
}

export function commands(schema: Schema): CommandConfigurations<Schema> {
  return {
    startInlineSelector: startInlineSelectorCommand(schema),
    updateInlineSelector: updateInlineSelectorCommand(schema),
    endInlineSelector: endInlineSelectorCommand(schema)
  };
}

function startInlineSelectorCommand(
  _schema: Schema
): CommandConfiguration<Schema, {}, null> {
  return {
    isActive: () => {
      return false;
    },
    isEnabled: () => {
      return true;
    },
    execute: () => {
      return (state, dispatch) => {
        if (dispatch) {
          let tr = state.tr;

          const action: StartSelectionAction = {
            type: "start"
          };
          tr = tr.setSelection(Selection.near(tr.doc.resolve(0)));
          tr = editableTransaction(tr, { editable: false, focusable: false });
          tr = tr.setMeta(inlineSelectorKey, action);

          dispatch(tr);
        }
        return true;
      };
    },
    requiresEditable: false
  };
}

function updateInlineSelectorCommand(
  _schema: Schema
): CommandConfiguration<Schema, UpdateSelectionCommandProps, null> {
  return {
    isActive: () => {
      return false;
    },
    isEnabled: () => {
      return true;
    },
    execute: (props) => {
      if (props == null) {
        throw new Error(
          "To start an inline selector `UpdateSelectionCommandProps` must be passed."
        );
      }

      return (state, dispatch, _view) => {
        if (dispatch) {
          const { doc } = state;
          const { ranges } = props;

          let tr = state.tr;

          const selection = ranges.map((r) => {
            const start = findNode(
              doc,
              (n) => n.attrs?.id === r.startElementId
            );
            const end = findNode(doc, (n) => n.attrs?.id === r.endElementId);

            return new InlineRange({
              id: r.id,
              start: start == null ? null : start,
              end: end == null ? null : end
            });
          });

          const action: ReplaceSelectionAction = {
            type: "replace",
            selection: selection,
            emit: false
          };

          tr = tr.setMeta(inlineSelectorKey, action);

          dispatch(tr);
        }
        return true;
      };
    },
    requiresEditable: false
  };
}

function endInlineSelectorCommand(
  _schema: Schema
): CommandConfiguration<Schema, {}, null> {
  return {
    isActive: () => {
      return false;
    },
    isEnabled: () => {
      return true;
    },
    execute: () => {
      return (state, dispatch) => {
        if (dispatch) {
          let tr = state.tr;

          const action: EndSelectionAction = { type: "end" };
          tr = tr.setMeta(inlineSelectorKey, action);
          tr = editableTransaction(tr, { editable: true });

          dispatch(tr);
        }
        return true;
      };
    },
    requiresEditable: false
  };
}
