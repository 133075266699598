import { NodeSpec, Node, DOMOutputSpecArray } from "prosemirror-model";
import {
  BarChartIndexAxis,
  BarChartLegend,
  BarChartQuestionType,
  BarChartStyle,
  BarChartSegment,
  BarChartRankConfiguration,
  BarChartSegmentMode
} from "../schema";
import { DocumentBuilders, NodeConfig } from "../../../editor";
import { toNumberValue } from "../../../util";
import { BarChartWidth, BarChartInputValueType } from "../schema";
import { AlignmentType } from "../../alignment";

export class BarChartNode implements NodeConfig {
  get name(): string {
    return "barChart";
  }

  get spec(): NodeSpec {
    return {
      group: "block gridInput report",
      draggable: true,
      selectable: true,
      focusable: true,
      allowAlignment: ["left", "center", "right"],
      blockAlignment: true,
      allowIndentation: false,
      allowGapCursor: true,
      attrs: {
        id: {
          default: null
        },
        surveyId: {
          default: null
        },
        inputId: {
          default: null
        },
        questionType: {
          default: null
        },
        type: {
          default: BarChartIndexAxis.horizontal
        },
        style: {
          default: BarChartStyle.medium
        },
        width: {
          default: BarChartWidth.default
        },
        rankConfiguration: {
          default: null
        },
        segmentMode: {
          default: BarChartSegmentMode.automatic
        },
        segments: {
          default: []
        },
        showInputLabel: {
          default: true
        },
        inputValueType: {
          default: BarChartInputValueType.count
        },
        showInputValue: {
          default: true
        },
        showDataLabel: {
          default: true
        },
        showSkippedCount: {
          default: false
        },
        showHiddenCount: {
          default: false
        },
        legend: {
          default: BarChartLegend.none
        },
        alignment: {
          default: null
        },
        filters: {
          default: null
        }
      },
      parseDOM: [
        {
          tag: "bar-chart",
          getAttrs(node) {
            if (typeof node === "string") {
              return false;
            }

            return getAttrs(node as Element);
          }
        }
      ],
      toDOM(node): DOMOutputSpecArray {
        return ["bar-chart", setAttrs(node)];
      },
      applyBlueprint(
        attrs: Record<string, any>,
        blueprint: Record<string, any>
      ) {
        const updated = { ...attrs };

        updated.surveyId = blueprint.surveyId;
        updated.inputId = blueprint.inputId;
        updated.questionType = blueprint.questionType;
        updated.type = blueprint.type;
        updated.style = blueprint.style;
        updated.width = blueprint.width;
        updated.rankConfiguration = blueprint.rankConfiguration;
        updated.segmentMode = blueprint.segmentMode;
        updated.segments = blueprint.segments;
        updated.showInputLabel = blueprint.showInputLabel;
        updated.inputValueType = blueprint.inputValueType;
        updated.showInputValue = blueprint.showInputValue;
        updated.showSkippedCount = blueprint.showSkippedCount;
        updated.showHiddenCount = blueprint.showHiddenCount;
        updated.legend = blueprint.legend;
        updated.alignment = blueprint.alignment;

        return updated;
      }
    };
  }

  get builders(): DocumentBuilders {
    return {
      barChart: { nodeType: "barChart" }
    };
  }
}

function setAttrs(node: Node): { [key: string]: string | undefined } {
  return {
    id: node.attrs.id,
    "data-survey-id": node.attrs.surveyId,
    "data-input-id": node.attrs.inputId,
    "data-question-type": node.attrs.questionType,
    "data-type": node.attrs.type,
    "data-style": node.attrs.style,
    "data-width": node.attrs.width,
    "data-segment-mode": node.attrs.segmentMode,
    "data-segments": node.attrs.segments
      ? JSON.stringify(node.attrs.segments)
      : undefined,
    "data-rank-configuration": node.attrs.rankConfiguration
      ? JSON.stringify(node.attrs.rankConfiguration)
      : undefined,
    "data-show-input-label": node.attrs.showInputLabel,
    "data-input-value-type": node.attrs.inputValueType,
    "data-show-input-value": node.attrs.showInputValue,
    "data-show-data-label": node.attrs.showDataLabel,
    "data-show-skipped-count": node.attrs.showSkippedCount,
    "data-show-hidden-count": node.attrs.showHiddenCount,
    "data-legend": node.attrs.legend,
    "data-alignment": node.attrs.alignment
  };
}

function getAttrs(element: Element): { [key: string]: any } {
  const attrs: {
    id?: string;
    surveyId?: string;
    inputId?: string;
    questionType?: BarChartQuestionType;
    width?: number;
    type?: BarChartIndexAxis;
    style?: BarChartStyle;
    rankConfiguration?: BarChartRankConfiguration;
    segmentMode?: BarChartSegmentMode;
    segments?: BarChartSegment[];
    showInputLabel?: boolean;
    inputValueType?: BarChartInputValueType;
    showInputValue?: boolean;
    showDataLabel?: boolean;
    showSkippedCount?: boolean;
    showHiddenCount?: boolean;
    legend?: BarChartLegend;
    alignment?: AlignmentType;
  } = {};

  const id = element.getAttribute("id");
  if (id != null) {
    attrs.id = id;
  }

  const surveyId = element.getAttribute("data-survey-id");
  if (surveyId != null) {
    attrs.surveyId = surveyId;
  }

  const inputId = element.getAttribute("data-input-id");
  if (inputId != null) {
    attrs.inputId = inputId;
  }

  const questionType = element.getAttribute(
    "data-question-type"
  ) as BarChartQuestionType;

  const width = toNumberValue(element.getAttribute("data-width"), 0);
  if (width != null) {
    attrs.width = width;
  }

  if (questionType != null) {
    switch (questionType) {
      case BarChartQuestionType.choice:
      case BarChartQuestionType.scale:
      case BarChartQuestionType.number:
      case BarChartQuestionType.dateTime:
      case BarChartQuestionType.constantSum:
      case BarChartQuestionType.ranking:
        attrs.questionType = questionType;
        break;
      default:
        break;
    }
  }

  const type = element.getAttribute("data-type") as BarChartIndexAxis;

  if (type != null) {
    switch (type) {
      case BarChartIndexAxis.horizontal:
      case BarChartIndexAxis.vertical:
        attrs.type = type;
        break;
      default:
        break;
    }
  }

  const style = element.getAttribute("data-style") as BarChartStyle;

  if (style != null) {
    switch (style) {
      case BarChartStyle.small:
      case BarChartStyle.medium:
      case BarChartStyle.large:
        attrs.style = style;
        break;
      default:
        break;
    }
  }

  const rankConfiguration = element.getAttribute("data-rank-configuration");
  if (rankConfiguration != null) {
    attrs.rankConfiguration = JSON.parse(rankConfiguration);
  }

  const segmentMode = element.getAttribute(
    "data-segment-mode"
  ) as BarChartSegmentMode;

  if (segmentMode != null) {
    switch (segmentMode) {
      case BarChartSegmentMode.automatic:
      case BarChartSegmentMode.manual:
        attrs.segmentMode = segmentMode;
        break;
      default:
        break;
    }
  }

  const segments = element.getAttribute("data-segments");
  if (segments != null) {
    attrs.segments = JSON.parse(segments);
  }

  const showInputLabel = element.getAttribute("data-show-input-label");
  if (showInputLabel != null) {
    attrs.showInputLabel = showInputLabel.toLowerCase() === "true";
  }

  const inputValueType = element.getAttribute(
    "data-input-value-type"
  ) as BarChartInputValueType;

  if (inputValueType != null) {
    switch (inputValueType) {
      case BarChartInputValueType.count:
      case BarChartInputValueType.percentage:
        attrs.inputValueType = inputValueType;
        break;
      default:
        break;
    }
  }

  const showInputValue = element.getAttribute("data-show-input-value");
  if (showInputValue != null) {
    attrs.showInputValue = showInputValue.toLowerCase() === "true";
  }

  const showDataLabel = element.getAttribute("data-show-data-label");
  if (showDataLabel != null) {
    attrs.showDataLabel = showDataLabel.toLowerCase() === "true";
  }

  const showSkippedCount = element.getAttribute("data-show-skipped-count");
  if (showSkippedCount != null) {
    attrs.showSkippedCount = showSkippedCount.toLowerCase() === "true";
  }

  const showHiddenCount = element.getAttribute("data-show-hidden-count");
  if (showHiddenCount != null) {
    attrs.showHiddenCount = showHiddenCount.toLowerCase() === "true";
  }

  const legend = element.getAttribute("data-legend") as BarChartLegend;

  if (legend != null) {
    switch (legend) {
      case BarChartLegend.none:
      case BarChartLegend.top:
      case BarChartLegend.right:
      case BarChartLegend.bottom:
      case BarChartLegend.left:
        attrs.legend = legend;
        break;
      default:
        break;
    }
  }

  const alignment = element.getAttribute("data-alignment") as AlignmentType;
  if (alignment != null) {
    switch (alignment) {
      case "center":
      case "left":
      case "right":
        attrs.alignment = alignment;
        break;
      default:
        break;
    }
  }

  return attrs;
}
