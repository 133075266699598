import {
  Fragment,
  Node,
  NodeType,
  ResolvedPos,
  Schema,
  Slice
} from "prosemirror-model";
import {
  AllSelection,
  EditorState,
  NodeSelection,
  Selection
} from "prosemirror-state";
import { canSplit } from "prosemirror-transform";
import { EditorView } from "prosemirror-view";
import { GapCursor } from "../editor/plugins/gap-cursor/gap-cursor";
import { findChildren, findParent, NodeWithPos } from "./nodes";

export function textForSelection<S extends Schema>(
  selection: Selection<S>
): string {
  const content = selection.content();

  let text = "";
  content.content.forEach((node) => {
    text = text.concat(node.textContent);
  });
  return text;
}

export function numberOfLinesInSelection<S extends Schema>(
  schema: S,
  selection: Selection<S>
): number {
  const nodeTypes = [schema.nodes.paragraph, schema.nodes.headings];
  const content = selection.content();

  let count = 0;
  content.content.descendants((node) => {
    if (nodeTypes.includes(node.type)) {
      count = count + 1;
      return false;
    } else {
      return;
    }
  });

  return count;
}

export function atTheEndOfDoc<S extends Schema>(
  state: EditorState<S>
): boolean {
  const { selection, doc } = state;
  return doc.nodeSize - selection.$to.pos - 2 === selection.$to.depth;
}

export function atTheBeginningOfDoc<S extends Schema>(
  state: EditorState<S>
): boolean {
  const { selection } = state;
  return selection.$from.pos === selection.$from.depth;
}

export function getResolvedSelection<S extends Schema>(
  state: EditorState<S>
): Selection<S> {
  const { selection, doc } = state;

  let $from: ResolvedPos<S>;
  let $to: ResolvedPos<S>;

  if (selection instanceof AllSelection) {
    const { from: start } = Selection.atStart(state.doc);
    const { to: end } = Selection.atEnd(state.doc);
    $from = doc.resolve(start);
    $to = doc.resolve(end);
  } else {
    $from = selection.$from;
    $to = selection.$to;
  }

  return new Selection($from, $to);
}

export function canInsert<S extends Schema>(
  nodeType: NodeType<S> | null | undefined
): (state: EditorState<S>) => boolean {
  return (state) => {
    if (nodeType == null) {
      return false;
    }

    const { selection, doc } = state;
    const { $from, $to } = selection;

    if (selection instanceof NodeSelection) {
      return false;
    }

    if (selection instanceof AllSelection) {
      return true;
    }

    if (selection instanceof GapCursor) {
      return true;
    }

    if (focusedQuestionTitle(state) != null) {
      return false;
    }

    if (nodeType.isBlock) {
      const fromCanSplit = canSplit(doc, $from.pos);
      const toCanSplit = canSplit(doc, $to.pos);

      const targetCanSplit = fromCanSplit && toCanSplit;

      if (!targetCanSplit) {
        return false;
      }
    }

    const depth = nodeType.isInline ? undefined : $from.depth - 1;
    const insertTarget = $from.node(depth);

    if (insertTarget == null) {
      return false;
    }

    const index = $from.index(depth);
    const isValid = insertTarget.canReplaceWith(index, index, nodeType);
    return isValid;
  };
}

function fitsTrivially<S extends Schema>(
  $from: ResolvedPos<S>,
  $to: ResolvedPos<S>,
  slice: Slice<S>
): boolean {
  return (
    !slice.openStart &&
    !slice.openEnd &&
    $from.start() === $to.start() &&
    $from.parent.canReplace($from.index(), $to.index(), slice.content)
  );
}

// : (ResolvedPos, ResolvedPos) → [number]
// Returns an array of all depths for which $from - $to spans the
// whole content of the nodes at that depth.
function coveredDepths<S extends Schema>(
  $from: ResolvedPos<S>,
  $to: ResolvedPos<S>
): number[] {
  let result = [];
  let minDepth = Math.min($from.depth, $to.depth);
  for (let d = minDepth; d >= 0; d--) {
    let start = $from.start(d);
    if (
      start < $from.pos - ($from.depth - d) ||
      $to.end(d) > $to.pos + ($to.depth - d) ||
      $from.node(d).type.spec.isolating ||
      $to.node(d).type.spec.isolating
    ) {
      break;
    }
    if (start === $to.start(d)) {
      result.push(d);
    }
  }
  return result;
}

export function sliceSingleNode<S extends Schema>(
  slice: Slice<S>
): Node<S> | null | undefined {
  return slice.openStart === 0 &&
    slice.openEnd === 0 &&
    slice.content.childCount === 1
    ? slice.content.firstChild
    : null;
}

export function canInsertSlice<S extends Schema>(
  $from: ResolvedPos<S>,
  $to: ResolvedPos<S>,
  slice: Slice<S>
): boolean {
  if (!slice.size) {
    return false;
  }

  let singleNode = sliceSingleNode(slice);
  if (singleNode) {
    const depth = singleNode.isInline ? undefined : $from.depth - 1;
    const insertTarget = $from.node(depth);

    if (
      insertTarget != null &&
      insertTarget.type.validContent(Fragment.from(singleNode))
    ) {
      return true;
    }
  }

  if (fitsTrivially($from, $to, slice)) {
    return true;
  }

  let targetDepths = coveredDepths($from, $to);
  // Can't replace the whole document, so remove 0 if it's present
  if (targetDepths[targetDepths.length - 1] === 0) {
    targetDepths.pop();
  }
  // Negative numbers represent not expansion over the whole node at
  // that depth, but replacing from $from.before(-D) to $to.pos.
  let preferredTarget = -($from.depth + 1);
  targetDepths.unshift(preferredTarget);
  // This loop picks a preferred target depth, if one of the covering
  // depths is not outside of a defining node, and adds negative
  // depths for any depth that has $from at its start and does not
  // cross a defining node.
  for (let d = $from.depth, pos = $from.pos - 1; d > 0; d--, pos--) {
    let spec = $from.node(d).type.spec;
    if (spec.defining || spec.isolating) {
      break;
    }
    if (targetDepths.indexOf(d) > -1) {
      preferredTarget = d;
    } else if ($from.before(d) === pos) {
      targetDepths.splice(1, 0, -d);
    }
  }
  // Try to fit each possible depth of the slice into each possible
  // target depth, starting with the preferred depths.
  let preferredTargetIndex = targetDepths.indexOf(preferredTarget);

  let leftNodes = [];
  let preferredDepth = slice.openStart;
  for (let content = slice.content, i = 0; ; i++) {
    let node = content.firstChild;
    leftNodes.push(node);
    if (i === slice.openStart) {
      break;
    }
    content = node!.content;
  }

  // Back up if the node directly above openStart, or the node above
  // that separated only by a non-defining textblock node, is defining.
  if (
    preferredDepth > 0 &&
    leftNodes[preferredDepth - 1]!.type.spec.defining &&
    $from.node(preferredTargetIndex)!.type !==
      leftNodes[preferredDepth - 1]!.type
  ) {
    preferredDepth -= 1;
  } else if (
    preferredDepth >= 2 &&
    leftNodes[preferredDepth - 1]!.isTextblock &&
    leftNodes[preferredDepth - 2]!.type.spec.defining &&
    $from.node(preferredTargetIndex).type !==
      leftNodes[preferredDepth - 2]!.type
  ) {
    preferredDepth -= 2;
  }

  for (let j = slice.openStart; j >= 0; j--) {
    let openDepth = (j + preferredDepth + 1) % (slice.openStart + 1);
    let insert = leftNodes[openDepth];
    if (!insert) {
      continue;
    }
    for (let i = 0; i < targetDepths.length; i++) {
      // Loop over possible expansion levels, starting with the
      // preferred one
      let targetDepth =
        targetDepths[(i + preferredTargetIndex) % targetDepths.length];
      // expand = true;
      if (targetDepth < 0) {
        // expand = false;
        targetDepth = -targetDepth;
      }
      let parent = $from.node(targetDepth - 1);
      let index = $from.index(targetDepth - 1);
      let grandParent =
        targetDepth - 2 > 0 ? $from.node(targetDepth - 2) : $from.doc;
      let grandParentIndex =
        targetDepth - 2 > 0 ? $from.index(targetDepth - 2) : 0;

      const remaining = slice.content.cut(openDepth + insert.nodeSize);
      const isEmpty = remaining.size === openDepth * 2;

      const canReplaceStart = parent.canReplaceWith(
        index,
        index,
        insert.type,
        insert.marks
      );
      const canReplaceRemaining =
        parent.canReplace(index, index, remaining) ||
        grandParent.canReplace(grandParentIndex, grandParentIndex, remaining);
      const isCompletelyReplacingTextOnly = isEmpty && insert.type.isText;

      if (
        canReplaceStart &&
        (canReplaceRemaining || isCompletelyReplacingTextOnly)
      ) {
        return true;
      } else {
        if (parent.type.validContent(slice.content)) {
          return true;
        }

        const firstChild = slice.content.firstChild;
        if (firstChild == null) {
          continue;
        }

        let content = slice.content.cut(firstChild.nodeSize);
        firstChild.content.forEach((child) => {
          content = content.append(Fragment.from(child));
        });

        if (content.childCount > 0 && parent.type.validContent(content)) {
          return true;
        }
      }
    }
  }

  if (slice.content.childCount === 1) {
    for (let i = $from.depth; i >= 0; i--) {
      let depth = i;
      let parent = $from.node(depth - 1);
      if (parent != null) {
        if (parent.type === slice.content.firstChild?.type) {
          return parent.type !== parent.type.schema.nodes.customArea;
        }
      }
    }
  }

  return false;
}

export function canInsertAtPos<S extends Schema>(
  node: Node<S>,
  $pos: ResolvedPos<S>
): boolean {
  const depth = $pos.depth;
  const insertTarget = depth > 0 ? $pos.node(depth) : $pos.doc;

  if (insertTarget == null) {
    return false;
  }

  const isValid = insertTarget.type.validContent(Fragment.from(node));
  return isValid;
}

export function scrollToPos<S extends Schema>(
  view: EditorView<S>,
  pos: number,
  scrollIntoViewOptions: ScrollIntoViewOptions
): void {
  const element = view.nodeDOM(pos) as Element | null | undefined;
  if (element != null) {
    element.scrollIntoView(scrollIntoViewOptions);
  }
}

export function findParentNode<S extends Schema>(
  predicate: (node: Node<S>) => boolean
) {
  return (selection: Selection<S>) => {
    return findParent<S>(selection.$from, predicate);
  };
}

export function hasParentNode<S extends Schema>(
  predicate: (node: Node<S>) => boolean
) {
  return (selection: Selection<S>) => {
    return findParentNode<S>(predicate)(selection) != null;
  };
}

export function findParentNodeOfType<S extends Schema>(nodeType: NodeType<S>) {
  return (selection: Selection<S>) =>
    findParent<S>(selection.$from, (node) => node.type === nodeType);
}

export function hasParentNodeOfType<S extends Schema>(nodeType: NodeType<S>) {
  return (selection: Selection<S>) => {
    return hasParentNode<S>((node) => node.type === nodeType)(selection);
  };
}

export function findPositionOfNodeBefore<S extends Schema>(
  selection: Selection<S>
): number | undefined {
  const { nodeBefore } = selection.$from;
  const maybeSelection = Selection.findFrom(selection.$from, -1);
  if (maybeSelection && nodeBefore) {
    // leaf node
    const parent = findParentNodeOfType(nodeBefore.type)(maybeSelection);
    if (parent) {
      return parent.pos;
    }
    return maybeSelection.$from.pos;
  }

  return undefined;
}

export function focusedQuestionTitle<S extends Schema>(
  state: EditorState<S>
): NodeWithPos<S> | undefined {
  const { doc, selection } = state;
  const { $from, $to } = selection;

  return focusedQuestionTitleAtPos(doc, $from, $to);
}

export function focusedQuestionTitleAtPos<S extends Schema>(
  doc: Node<S>,
  $from: ResolvedPos<S>,
  $to: ResolvedPos<S>
): NodeWithPos<S> | undefined {
  const fromNode = $from.node();
  const toNode = $to.node();

  if (fromNode === toNode) {
    const inputs = findInputs(doc, fromNode);
    if (inputs.length > 0) {
      const parent = findParent($from, (node) => {
        return node.type.spec.supportsQuestionTitle === true;
      });

      return parent;
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
}

export function findInputs<S extends Schema>(
  doc: Node<S>,
  node: Node<S>
): NodeWithPos<S>[] {
  const id = node.attrs.id as string;
  return findChildren(
    doc,
    (child) => {
      const childIds = child.attrs?.questionTitleRefElementId as
        | string[]
        | undefined;
      if (childIds != null && childIds.includes(id)) {
        return true;
      } else {
        return false;
      }
    },
    true
  );
}

export function canReplaceWithNode<S extends Schema>(
  $pos: ResolvedPos<S>,
  type: NodeType<S>
): boolean {
  const index = $pos.index();
  return $pos.parent.canReplaceWith(index, index + 1, type);
}
