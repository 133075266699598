import { Node, NodeType, Schema } from "prosemirror-model";
import { EditorState, NodeSelection, Plugin } from "prosemirror-state";
import { Decoration, EditorView, NodeView } from "prosemirror-view";
import {
  CommandConfiguration,
  CommandConfigurations,
  CommandFn,
  Editor,
  Extension,
  NodeConfig
} from "../../editor";
import { en, fr } from "../../i18n";
import { canInsert } from "../../util";
import { Bold } from "../bold";
import { Doc } from "../doc";
import { HardBreak } from "../hard-break";
import { Headings } from "../headings";
import { Image } from "../image";
import { InputChoice } from "../input-choice";
import { InputScale } from "../input-scale";
import { Italic } from "../italic";
import { Link } from "../link";
import { ChangeLanguageActiveValue, Localization } from "../localization";
import { Paragraph } from "../paragraph";
import { Strikethrough } from "../strikethrough";
import { Subscript } from "../subscript";
import { Superscript } from "../superscript";
import { Text } from "../text";
import { TextBackgroundColor } from "../text-background-color";
import { TextColor } from "../text-color";
import { TextFont } from "../text-font";
import { TextSize } from "../text-size";
import { Underline } from "../underline";
import { Video } from "../video";
import {
  LinkVariableNode,
  LinkVariableNodeView,
  QuestionVariableNode,
  QuestionVariableNodeView,
  TextVariableNode,
  TextVariableNodeView
} from "./nodes";
import {
  VariableDropdownRenderer,
  variablePickerPlugin,
  variablesKey,
  variablesPlugin
} from "./plugin";
import {
  VariableDefinition,
  VariableItem,
  VariableSchema,
  VariableSource,
  VariableType
} from "./schema";
import { QuestionVariableRenderingDTO } from "./types";
import {
  createVariable,
  findVariable,
  focusedVariable,
  variableTypeForNodeType
} from "./util";

export interface VariableActiveValue {
  type: VariableType;
  source: VariableSource;
  code: string;
  defaultValue: string | null;
  displayedValue: string | null;
}

export interface InsertVariableCommandProps {
  definition: VariableDefinition;
}

export interface UpdateVariableCommandProps {
  definition?: VariableDefinition;
  defaultValue?: string;
  displayedValue?: string;
}

export interface SetVariablesCommandProps {
  variables: VariableItem[];
}

export class Variable implements Extension<VariableSchema> {
  constructor(
    private supportedTypes: VariableType[],
    private variables: VariableItem[],
    private questionForCode: (
      code: string
    ) => QuestionVariableRenderingDTO | null,
    private variablePickerRenderer?: VariableDropdownRenderer
  ) {}

  get name(): string {
    return "variable";
  }

  get nodes(): NodeConfig[] {
    let nodes: NodeConfig[] = [];

    if (this.supportedTypes.includes("text")) {
      nodes = nodes.concat(new TextVariableNode());
    }

    if (this.supportedTypes.includes("link")) {
      nodes = nodes.concat(new LinkVariableNode());
    }

    if (this.supportedTypes.includes("question")) {
      nodes = nodes.concat(new QuestionVariableNode());
    }

    return nodes;
  }

  plugins(): Plugin[] {
    let nodes: {
      [name: string]: (
        node: Node<VariableSchema>,
        view: EditorView<VariableSchema>,
        getPos: (() => number) | boolean,
        decorations: Decoration[]
      ) => NodeView<VariableSchema>;
    } = {};

    if (this.supportedTypes.includes("text")) {
      nodes = {
        ...nodes,
        textVariable: (node, view, getPos, decorations) =>
          new TextVariableNodeView(
            node,
            view,
            getPos as () => number,
            decorations
          )
      };
    }

    if (this.supportedTypes.includes("link")) {
      nodes = {
        ...nodes,
        linkVariable: (node, view, getPos, decorations) =>
          new LinkVariableNodeView(
            node,
            view,
            getPos as () => number,
            decorations
          )
      };
    }

    if (this.supportedTypes.includes("question")) {
      nodes = {
        ...nodes,
        questionVariable: (node, view, getPos) =>
          new QuestionVariableNodeView(
            node,
            view,
            getPos as () => number,
            (code: string) => {
              const question = this.questionForCode(code);
              if (question == null) {
                return null;
              } else {
                const editor = renderEditor();
                const schema = editor.state.schema;

                const content = new Array<Node>();
                if (question.questionTitle != null) {
                  content.push(schema.nodeFromJSON(question.questionTitle));
                }
                content.push(schema.nodeFromJSON(question.input));

                const doc = schema.nodes.doc.create(null, content);
                editor.replaceState(doc);

                const currentEditor = view as Editor<Schema>;

                const editorLanguage: ChangeLanguageActiveValue | null = currentEditor.commands.changeLanguage?.activeValue();
                if (editorLanguage != null) {
                  editor.commands.changeLanguage?.execute(
                    {
                      language: editorLanguage.language
                    },
                    false
                  );
                }

                const editorVariables:
                  | VariableItem[]
                  | null = currentEditor.commands.setVariables?.activeValue();
                if (editorVariables == null) {
                  editor.commands.setVariables?.execute(
                    {
                      variables: editorVariables
                    },
                    false
                  );
                }

                editor.setEditable({ editable: false, focusable: false });

                return editor.dom;
              }
            }
          )
      };
    }

    const plugins = [
      new Plugin({ props: { nodeViews: nodes } }),
      variablesPlugin(this.variables)
    ];

    if (this.variablePickerRenderer != null) {
      plugins.push(
        variablePickerPlugin(this.variables, this.variablePickerRenderer)
      );
    }

    return plugins;
  }

  commands(schema: VariableSchema): CommandConfigurations<VariableSchema> {
    return {
      insertVariable: this.insertVariableCommand(schema),
      updateVariable: this.updateVariableCommand(schema),
      setVariables: this.setVariablesCommand(schema)
    };
  }

  private insertVariableCommand(
    schema: VariableSchema
  ): CommandConfiguration<VariableSchema, InsertVariableCommandProps, null> {
    return {
      isActive: () => false,
      isEnabled: (props) => {
        return (state) => {
          const type =
            props != null
              ? getNodeTypeForDefinitionType(schema, props.definition.type)
              : schema.nodes.textVariable;

          if (!canInsert(type)(state)) {
            return false;
          } else {
            return true;
          }
        };
      },
      execute: (props) => {
        if (props == null) {
          throw new Error(
            `To insert a variable, InsertVariableCommandProps needs to be provided.`
          );
        }

        return insertVariable(schema, props);
      }
    };
  }

  private updateVariableCommand(
    schema: VariableSchema
  ): CommandConfiguration<
    VariableSchema,
    UpdateVariableCommandProps,
    VariableActiveValue | undefined
  > {
    return {
      isActive: () => {
        return (state) => {
          return focusedVariable(state) != null;
        };
      },
      isEnabled: () => {
        return (state) => {
          return focusedVariable(state) != null;
        };
      },
      execute: (props) => {
        if (props == null) {
          throw new Error(
            `To update a variable, UpdateVariableCommandProps needs to be provided.`
          );
        }

        return (state, dispatch) => {
          const focused = focusedVariable(state);
          return updateVariable(schema, props, focused)(state, dispatch);
        };
      },
      activeValue: () => {
        return (state) => {
          const focused = focusedVariable(state);
          if (!focused) {
            return undefined;
          }

          const { code, defaultValue, source } = focused.node.attrs;

          return {
            type: variableTypeForNodeType(focused.node, schema),
            source: source,
            code: code,
            defaultValue: defaultValue,
            displayedValue:
              focused.node.type === schema.nodes.linkVariable
                ? focused.node.textContent
                : null
          };
        };
      }
    };
  }

  private setVariablesCommand(
    _schema: VariableSchema
  ): CommandConfiguration<
    VariableSchema,
    SetVariablesCommandProps,
    VariableItem[]
  > {
    return {
      isActive: () => {
        return false;
      },
      isEnabled: () => {
        return true;
      },
      execute: (props) => {
        if (props == null) {
          throw new Error(
            `To update variables, SetVariablesCommandProps needs to be provided.`
          );
        }

        return (state, dispatch) => {
          if (dispatch) {
            let tr = state.tr;
            tr = tr.setMeta(variablesKey, { variables: props.variables });
            dispatch(tr);
          }

          return true;
        };
      },
      activeValue: () => {
        return (state) => {
          const value = variablesKey.getState(state);
          return value != null ? value.variables : [];
        };
      },
      requiresEditable: false
    };
  }
}

function insertVariable(
  schema: VariableSchema,
  props: InsertVariableCommandProps
): CommandFn<VariableSchema> {
  return (state, dispatch) => {
    if (props == null) {
      return false;
    }

    const type =
      props != null
        ? getNodeTypeForDefinitionType(schema, props.definition.type)
        : schema.nodes.textVariable;

    if (!canInsert(type)(state)) {
      return false;
    }

    const { definition } = props;

    const variable = getVariable(definition, state);
    if (variable == null) {
      return false;
    }

    if (dispatch) {
      const toInsert = createVariable(
        schema,
        variable.definition,
        variable.name
      );
      if (toInsert == null) {
        return false;
      }

      let tr = state.tr;

      tr = tr.replaceSelectionWith(toInsert);
      tr = tr.scrollIntoView();

      dispatch(tr);
    }

    return true;
  };
}

function updateVariable(
  schema: VariableSchema,
  props: UpdateVariableCommandProps,
  variable: { node: Node<VariableSchema>; pos: number } | undefined
): CommandFn<VariableSchema> {
  return (state, dispatch) => {
    if (variable == null) {
      return false;
    }

    const { definition, defaultValue, displayedValue } = props;

    if (dispatch) {
      let { node, pos } = variable;

      let { tr } = state;

      const type = variableTypeForNodeType(variable.node, schema);
      const { code, source } = variable.node.attrs;

      if (definition !== undefined) {
        if (hasVariableChanged(type, source, code, definition)) {
          const variable = getVariable(definition, state);

          if (variable == null) {
            return false;
          }

          const from = pos;
          const to = pos + node.nodeSize;

          node = createVariable(schema, variable.definition, variable.name);

          const currentNode = tr.doc.nodeAt(from);
          if (
            currentNode != null &&
            currentNode.type !== schema.nodes.questionVariable &&
            node.type === schema.nodes.questionVariable
          ) {
            pos = pos + 1;
          }

          if (
            currentNode != null &&
            currentNode.type === schema.nodes.questionVariable &&
            node.type !== schema.nodes.questionVariable
          ) {
            pos = pos + 1;
          }

          tr = tr.replaceWith(from, to, node);
        }
      }

      let attrs = { ...node.attrs };
      if (defaultValue !== undefined) {
        attrs = { ...attrs, defaultValue: defaultValue };
      }

      tr = tr.setNodeMarkup(pos, undefined, attrs);

      if (
        displayedValue !== undefined &&
        displayedValue?.length > 0 &&
        node.type === schema.nodes.linkVariable &&
        !hasVariableChanged(type, source, code, definition)
      ) {
        const node = tr.doc.nodeAt(pos);
        if (node != null) {
          tr = tr.replaceWith(
            pos + 1,
            pos + node.nodeSize - 1,
            schema.text(displayedValue)
          );
        }
      }

      tr = tr.setSelection(new NodeSelection(tr.doc.resolve(pos)));

      dispatch(tr);
    }
    return true;
  };
}

function getVariable(
  definition: VariableDefinition,
  state: EditorState<VariableSchema>
): VariableItem | undefined {
  const plugin = variablesKey.getState(state);
  if (plugin == null) {
    return undefined;
  }

  const variables = plugin.variables;
  return findVariable(definition, variables);
}

function hasVariableChanged(
  type: VariableType,
  source: VariableSource,
  code: string,
  definition?: VariableDefinition
): boolean {
  if (definition != null) {
    return (
      type !== definition.type ||
      source !== definition.source ||
      code !== definition.code
    );
  } else {
    return false;
  }
}

function getNodeTypeForDefinitionType(
  schema: VariableSchema,
  definitionType: VariableType
): NodeType<VariableSchema> {
  switch (definitionType) {
    case "link":
      return schema.nodes.linkVariable;

    case "question":
      return schema.nodes.questionVariable;

    case "text":
      return schema.nodes.textVariable;

    default:
      return schema.nodes.textVariable;
  }
}

function renderEditor<S extends Schema>(): Editor<S> {
  const extensions = [
    new Doc(),
    new Paragraph(),
    new Text(),
    new Bold(),
    new Subscript(),
    new Superscript(),
    new Underline(),
    new Strikethrough(),
    new Italic(),
    new TextSize(11),
    new TextColor(),
    new TextBackgroundColor(),
    new TextFont([
      { name: "Headings", css: `Headings, serif` },
      { name: "Body", css: `Body, sans-serif`, default: true },
      { name: "Arial", css: `Arial, Helvetica, sans-serif` },
      { name: "Arial Black", css: `"Arial Black", Gadget, sans-serif` },
      { name: "Georgia", css: `Georgia, serif` },
      { name: "Impact", css: `Impact, Charcoal, sans-serif` },
      { name: "Tahoma", css: `Tahoma, Geneva, sans-serif` },
      { name: "Times New Roman", css: `"Times New Roman", Times, serif` },
      { name: "Verdana", css: `Verdana, Geneva, sans-serif` },
      { name: "Courier New", css: `"Courier New", Courier, monospace` }
    ]),
    new Headings([
      {
        level: 1,
        properties: {
          textFont: { name: "Headings", css: `Headings, serif` },
          textSize: 24
        }
      },
      {
        level: 2,
        properties: {
          textFont: { name: "Headings", css: `Headings, serif` },
          textSize: 20
        }
      },
      {
        level: 3,
        properties: {
          textFont: { name: "Headings", css: `Headings, serif` },
          textSize: 18
        }
      },
      {
        level: 4,
        properties: {
          textFont: { name: "Headings", css: `Headings, serif` },
          textSize: 16
        }
      },
      {
        level: 5,
        properties: {
          textFont: { name: "Headings", css: `Headings, serif` },
          textSize: 14
        }
      },
      {
        level: 6,
        properties: {
          textFont: { name: "Headings", css: `Headings, serif` },
          textSize: 12
        }
      }
    ]),
    new HardBreak(),
    new Link(),
    new Image(() => Promise.resolve("")),
    new Video(),
    new Variable(["text", "link"], [], () => null),
    new InputScale([
      { id: "migration", label: "Migration", options: [], default: true }
    ]),
    new InputChoice(),
    new Localization([
      {
        code: "en",
        translations: en,
        default: true
      },
      {
        code: "fr",
        translations: fr
      }
    ])
  ] as Extension<S>[];

  return new Editor<S>(
    extensions,
    () => {},
    () => {}
  );
}
