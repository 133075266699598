import {
  BarChartActiveValue,
  BarChartIndexAxis,
  BarChartInputValueType,
  BarChartLegend,
  BarChartStyle,
  BarChartWidth,
  Editor as BlueXEditor,
  findChildren,
  Schema,
  BarChartQuestionType,
  BarChartRankConfigurationSegmentType,
  mapColorToRgba,
  findNode,
  BarChartItem,
  BarChartSegmentMode,
  Node,
  setMinForSegment,
  setMaxForSegment,
  InputDateTimeControlType,
  formatDateTime,
  parseInputDateTime,
  colorSet
} from "@blue-x/editor";
import React, { useCallback, useMemo } from "react";
import {
  CheckboxFormControl,
  NumberFormControl,
  SelectFormControl,
  TextFormControl,
  MultipleSelectFormControl,
  SelectItem
} from "../ProportiesPanelControls";
import "./BarChart.css";

interface ToolbarBarChartButtonProps {
  editor: BlueXEditor<Schema>;
}

export const BarChartPropertiesPanel: React.FC<ToolbarBarChartButtonProps> = (
  props
) => {
  const { editor } = props;
  const { state } = editor;
  const { doc, schema } = state;
  const { commands } = editor;

  const activeValue: BarChartActiveValue = useMemo(() => {
    return commands.updateBarChart.activeValue();
  }, [commands]);

  const update = useCallback(
    (value) => {
      commands.updateBarChart.execute(value, false);
    },
    [commands]
  );

  const addSegment = useCallback(() => {
    commands.addSegment.execute();
  }, [commands]);

  const removeSegment = useCallback(
    (value) => {
      commands.removeSegment.execute(value, false);
    },
    [commands]
  );

  const updateValueLabel = useCallback(
    (id, valueLabel) => {
      const segments = activeValue.segments.map((s) => {
        return { ...s, label: s.id === id ? valueLabel : s.label };
      });

      update({ segments: segments });
    },
    [activeValue, update]
  );
  const updateImageUrl = useCallback(
    (id, imageUrl) => {
      const segments = activeValue.segments.map((s) => {
        return { ...s, imageUrl: s.id === id ? imageUrl : s.imageUrl };
      });

      update({ segments: segments });
    },
    [activeValue, update]
  );

  const setProject = useCallback(() => {
    const inputs = findChildren(
      doc,
      (node) =>
        node.type === schema.nodes.inputChoice ||
        node.type === schema.nodes.inputScale ||
        node.type === schema.nodes.inputNumber ||
        node.type === schema.nodes.inputDateTime ||
        node.type === schema.nodes.constantSum ||
        node.type === schema.nodes.inputRank
    );

    const gotInputs = inputs.length > 0;

    if (gotInputs) {
      const firstInputNode = inputs[0].node;
      commands.updateBarChart.execute(
        {
          surveyId: gotInputs ? "test" : null,
          node: firstInputNode
        },
        false
      );
    } else {
      commands.updateBarChart.execute(
        {
          segments: []
        },
        false
      );
    }
  }, [commands, doc, schema]);

  const questionType = activeValue.questionType;
  const type = activeValue.type;
  const style = activeValue.style;
  const rankConfiguration = activeValue.rankConfiguration;
  const segmentMode = activeValue.segmentMode;
  const isItemsInput =
    activeValue.questionType === BarChartQuestionType.choice ||
    activeValue.questionType === BarChartQuestionType.scale ||
    activeValue.questionType === BarChartQuestionType.ranking;

  const isNumberRange =
    activeValue.questionType === BarChartQuestionType.number ||
    activeValue.questionType === BarChartQuestionType.constantSum;
  const isDateTimeRange =
    activeValue.questionType === BarChartQuestionType.dateTime;

  const selectedNode = findNode(
    doc,
    (node) => node.attrs.id === activeValue.inputId
  );

  let firstChildren: BarChartItem[] = [];
  let lastChildren: BarChartItem[] = [];
  let list: SelectItem[] = [];
  let dateTimeFormat: string;
  let dateTimeControlType: InputDateTimeControlType;

  if (selectedNode) {
    list = mapList(selectedNode.node, schema);

    if (questionType === BarChartQuestionType.ranking) {
      const sections = selectedNode.node.firstChild;
      const options = selectedNode.node.lastChild;

      if (sections) {
        sections.forEach((section) => {
          firstChildren.push({
            id: section.attrs.id,
            label: section.textContent
          });
        });
      }

      if (options) {
        options.forEach((option) => {
          lastChildren.push({
            id: option.attrs.id,
            label: option.textContent
          });
        });
      }
    }

    if (questionType === BarChartQuestionType.dateTime) {
      const { node } = selectedNode;
      const controlType = node.attrs.controlType;
      dateTimeControlType = controlType;

      if (dateTimeControlType === InputDateTimeControlType.date) {
        dateTimeFormat = node.attrs.dateFormat;
      } else {
        dateTimeFormat = node.attrs.timeFormat;
      }
    }
  }

  const ranks = lastChildren.map((_o, index) => {
    return index + 1;
  });

  const segmentLabel =
    questionType === BarChartQuestionType.ranking ? "Rank" : "Segments";

  const segmentModeClass = (mode: BarChartSegmentMode) => {
    return mode === activeValue.segmentMode
      ? "BarChart-segment-mode-button active"
      : "BarChart-segment-mode-button";
  };

  return (
    <div className="BarChartPropertiesPanel">
      <div className="BarChart-label">Display</div>
      <SelectFormControl
        label={"Type"}
        value={type}
        onUpdate={(value) => {
          update({ type: value });
        }}
      >
        <option value={BarChartIndexAxis.horizontal}>Horizontal</option>
        <option value={BarChartIndexAxis.vertical}>Vertical</option>
      </SelectFormControl>

      <SelectFormControl
        label={"Style"}
        value={style}
        onUpdate={(value) => {
          update({ style: value });
        }}
      >
        <option value={BarChartStyle.small}>Small</option>
        <option value={BarChartStyle.medium}>Medium</option>
        <option value={BarChartStyle.large}>Large</option>
      </SelectFormControl>

      <NumberFormControl
        label={"Width (%)"}
        value={activeValue.width}
        min={BarChartWidth.minimum}
        max={BarChartWidth.maximum}
        disabled={activeValue.widthDisabled}
        onUpdate={(value) => {
          update({ width: value });
        }}
      />

      <hr></hr>
      {type === BarChartIndexAxis.horizontal ? (
        <div className="BarChart-label">Y-axis</div>
      ) : (
        <div className="BarChart-label">X-axis</div>
      )}
      {questionType === BarChartQuestionType.ranking ? (
        <div>
          <SelectFormControl
            label={"Ranking question"}
            value={rankConfiguration.sectionId}
            onUpdate={(value) => {
              update({ sectionId: value });
            }}
          >
            {firstChildren.map((value) => {
              return (
                <option value={value.id} key={value.id}>
                  {value.label}
                </option>
              );
            })}
          </SelectFormControl>

          <div className="BarChart-sublabel">Segments</div>
          <CheckboxFormControl
            label={"Items"}
            value={
              rankConfiguration.segmentType ===
              BarChartRankConfigurationSegmentType.item
            }
            onUpdate={() => {
              update({
                segmentTypeProps: {
                  type: BarChartRankConfigurationSegmentType.item,
                  options: lastChildren
                }
              });
            }}
          />
          <CheckboxFormControl
            label={"Rank"}
            value={
              rankConfiguration.segmentType ===
              BarChartRankConfigurationSegmentType.rank
            }
            onUpdate={() => {
              update({
                segmentTypeProps: {
                  type: BarChartRankConfigurationSegmentType.rank,
                  options: lastChildren
                }
              });
            }}
          />
          {rankConfiguration.segmentType ===
          BarChartRankConfigurationSegmentType.item ? (
            <div>
              <div className="BarChart-sublabel">Item</div>
              <SelectFormControl
                value={rankConfiguration.option.id}
                onUpdate={(value) => {
                  const expectedOption = lastChildren.find(
                    (o) => o.id === value
                  );

                  if (expectedOption) {
                    update({
                      option: expectedOption
                    });
                  }
                }}
              >
                {lastChildren.map((value) => {
                  return (
                    <option value={value.id} key={value.id}>
                      {value.label}
                    </option>
                  );
                })}
              </SelectFormControl>
            </div>
          ) : (
            <div>
              <div className="BarChart-sublabel">Rank</div>
              <SelectFormControl
                value={rankConfiguration.rank.id}
                onUpdate={(value) => {
                  update({
                    rank: value
                  });
                }}
              >
                {ranks.map((value) => {
                  return (
                    <option value={value} key={value}>
                      R{value}
                    </option>
                  );
                })}
              </SelectFormControl>
            </div>
          )}
          <TextFormControl
            className="BarChartPropertiesPanel-table-cell"
            value={
              rankConfiguration.segmentType ===
              BarChartRankConfigurationSegmentType.item
                ? rankConfiguration.option.label
                : rankConfiguration.rank.label
            }
            onUpdate={(value) => {
              if (
                rankConfiguration.segmentType ===
                BarChartRankConfigurationSegmentType.item
              ) {
                update({ optionLabel: value });
              } else {
                update({ rankLabel: value });
              }
            }}
          />
        </div>
      ) : null}

      {activeValue.segments.length > 0 ? (
        <div className="BarChart-segment-mode-container">
          <span className="BarChart-sublabel">{segmentLabel}</span>
          <div className="BarChart-segment-mode-selector">
            <button
              className={segmentModeClass(BarChartSegmentMode.automatic)}
              onClick={() => {
                update({
                  segmentModeProps: {
                    mode: BarChartSegmentMode.automatic,
                    node: selectedNode?.node
                  }
                });
              }}
            >
              <span className="bx bx-settings-menu"></span>
              Automatic
            </button>
            <button
              className={segmentModeClass(BarChartSegmentMode.manual)}
              onClick={() => {
                update({
                  segmentModeProps: { mode: BarChartSegmentMode.manual }
                });
              }}
            >
              <span className="bx bx-webhooks"></span>
              Manual
            </button>
          </div>
        </div>
      ) : null}

      {activeValue.segments.map((segment, index) => {
        const items = segment.items;
        const selectedList = items
          ? (items as any[]).map((i) => {
              const expectedItem = list.find((item) => item.value === i);
              return {
                value: i,
                label: expectedItem ? expectedItem.label : ""
              };
            })
          : [];
        let minDate: string | null = null;
        let maxDate: string | null = null;

        if (isDateTimeRange) {
          if (segment.range) {
            if (segment.range.min) {
              const date = formatDateTime(
                dateTimeControlType,
                segment.range.min as string,
                dateTimeFormat
              );

              minDate = date ? date : null;
            }

            if (segment.range.max) {
              const date = formatDateTime(
                dateTimeControlType,
                segment.range.max as string,
                dateTimeFormat
              );

              maxDate = date ? date : null;
            }
          }
        }
        return (
          <div
            className="BarChartPropertiesPanel-input-setting-container"
            key={segment.id}
          >
            {segmentMode === BarChartSegmentMode.manual && isItemsInput ? (
              <MultipleSelectFormControl
                list={list}
                selectedList={selectedList}
                onUpdate={(value) => {
                  const items = value.map((v) => v.value);
                  const currentSegments = activeValue.segments;

                  currentSegments.forEach((s) => {
                    if (s.id === segment.id) {
                      s.items = items;
                    }
                  });
                  update({ segments: currentSegments });
                }}
              ></MultipleSelectFormControl>
            ) : null}

            {segmentMode === BarChartSegmentMode.manual && isDateTimeRange ? (
              <div className="BarChartPropertiesPanel-range-container">
                <TextFormControl
                  value={minDate}
                  onUpdate={(min) => {
                    const parseDate = parseInputDateTime(
                      dateTimeControlType,
                      min,
                      dateTimeFormat
                    );

                    const segments = setMinForSegment(
                      segment.id,
                      activeValue.segments,
                      parseDate
                    );

                    update({ segments: segments });
                  }}
                />

                <span className="BarChartPropertiesPanel-range-text">to</span>

                <TextFormControl
                  value={maxDate}
                  onUpdate={(max) => {
                    const parseDate = parseInputDateTime(
                      dateTimeControlType,
                      max,
                      dateTimeFormat
                    );

                    const segments = setMaxForSegment(
                      segment.id,
                      activeValue.segments,
                      parseDate
                    );

                    update({ segments: segments });
                  }}
                />
              </div>
            ) : null}

            {segmentMode === BarChartSegmentMode.manual && isNumberRange ? (
              <div className="BarChartPropertiesPanel-range-container">
                <NumberFormControl
                  className="BarChartPropertiesPanel-range-field"
                  value={segment.range ? (segment.range.min as number) : null}
                  onUpdate={(min) => {
                    const segments = setMinForSegment(
                      segment.id,
                      activeValue.segments,
                      min
                    );

                    update({ segments: segments });
                  }}
                />
                <span className="BarChartPropertiesPanel-range-text">to</span>
                <NumberFormControl
                  className="BarChartPropertiesPanel-range-field"
                  value={segment.range ? (segment.range.max as number) : null}
                  onUpdate={(max) => {
                    const segments = setMaxForSegment(
                      segment.id,
                      activeValue.segments,
                      max
                    );

                    update({ segments: segments });
                  }}
                />
              </div>
            ) : null}
            <div className="BarChartPropertiesPanel-segment-container">
              <div className="BarChartPropertiesPanel-field-container">
                <div className="BarChartPropertiesPanel-table-row">
                  <div
                    style={{
                      backgroundColor: mapColorToRgba(
                        segment.color
                          ? segment.color
                          : colorSet[index % colorSet.length].color
                      )
                    }}
                    className="BarChartPropertiesPanel-table-pin"
                  ></div>

                  <TextFormControl
                    className="BarChartPropertiesPanel-table-cell"
                    value={segment.label ? segment.label : ""}
                    placeHolder={
                      segment.label != null
                        ? "Insert custom label…"
                        : `{Segment ${index + 1}}`
                    }
                    onUpdate={(label) => {
                      updateValueLabel(segment.id, label);
                    }}
                  />
                </div>
                <div className="BarChartPropertiesPanel-table-row Align-end">
                  <i className="bx bx-thumbnail-icon"></i>
                  <TextFormControl
                    className="BarChartPropertiesPanel-table-cell"
                    value={segment.imageUrl ? segment.imageUrl : ""}
                    onUpdate={(imageUrl) => {
                      updateImageUrl(segment.id, imageUrl);
                    }}
                  />
                </div>
              </div>

              {segmentMode === BarChartSegmentMode.manual ? (
                <button
                  onClick={() => {
                    removeSegment({ segmentId: segment.id });
                  }}
                  disabled={activeValue.segments.length < 2}
                >
                  <span className="bx bx-action-close"></span>
                </button>
              ) : null}
            </div>
          </div>
        );
      })}
      {segmentMode === BarChartSegmentMode.manual ? (
        <button
          className="BarChart-segment-mode-button active align-right"
          onClick={() => {
            addSegment();
          }}
        >
          <span className="bx bx-add"></span>
          Add another segment
        </button>
      ) : null}

      <CheckboxFormControl
        label={"Axis label"}
        value={activeValue.showInputLabel}
        onUpdate={(value) => {
          update({ showInputLabel: value });
        }}
      />

      <hr></hr>
      {type === BarChartIndexAxis.horizontal ? (
        <div className="BarChart-label">X-axis</div>
      ) : (
        <div className="BarChart-label">Y-axis</div>
      )}
      <CheckboxFormControl
        label={"Count of responses"}
        value={activeValue.inputValueType === BarChartInputValueType.count}
        onUpdate={() => {
          update({ inputValueType: BarChartInputValueType.count });
        }}
      />
      <CheckboxFormControl
        label={"Percentage of responses"}
        value={activeValue.inputValueType === BarChartInputValueType.percentage}
        onUpdate={() => {
          update({ inputValueType: BarChartInputValueType.percentage });
        }}
      />
      <CheckboxFormControl
        label={"Axis label"}
        value={activeValue.showInputValue}
        onUpdate={(value) => {
          update({ showInputValue: value });
        }}
      />

      <hr></hr>
      <div className="BarChart-label">Additional visuals</div>
      <CheckboxFormControl
        label={"Data label"}
        value={activeValue.showDataLabel}
        onUpdate={(value) => {
          update({ showDataLabel: value });
        }}
      />
      <CheckboxFormControl
        label={"Skipped question count"}
        value={activeValue.showSkippedCount}
        onUpdate={(value) => {
          update({ showSkippedCount: value });
        }}
      />

      <CheckboxFormControl
        label={"Hidden count"}
        value={activeValue.showHiddenCount}
        onUpdate={(value) => {
          update({ showHiddenCount: value });
        }}
      />

      <SelectFormControl
        label={"Legend"}
        value={activeValue.legend}
        onUpdate={(value) => {
          update({ legend: value });
        }}
      >
        <option value={BarChartLegend.none}>None</option>
        <option value={BarChartLegend.top}>Top</option>
        <option value={BarChartLegend.right}>Right</option>
        <option value={BarChartLegend.bottom}>Bottom</option>
        <option value={BarChartLegend.left}>Left</option>
      </SelectFormControl>

      <div className="BarChart-mock-text">
        Mock data is automatically applied to simulate the result.
      </div>

      <hr></hr>
      <button onClick={setProject}>Set project</button>
    </div>
  );
};

const mapList = (node: Node, schema: Schema) => {
  let list: SelectItem[] = [];
  if (node) {
    switch (node.type) {
      case schema.nodes.inputChoice:
      case schema.nodes.inputScale:
        node.content.forEach((choice) => {
          list.push({ value: choice.attrs.id, label: choice.textContent });
        });
        break;

      case schema.nodes.inputRank:
        const options = node.lastChild;

        if (options) {
          options.content.forEach((option) => {
            list.push({ value: option.attrs.id, label: option.textContent });
          });
        }
        break;

      default:
        break;
    }
  }
  return list;
};
