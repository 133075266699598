import OrderedMap from "orderedmap";
import { MarkSpec, NodeSpec, Schema } from "prosemirror-model";
import {
  MarkTypeAttributes,
  NodeTypeAttributes
} from "prosemirror-test-builder";

type SpecTypes = MarkSpec | NodeSpec;

export type DocumentBuilders<
  Obj extends Record<string, NodeTypeAttributes | MarkTypeAttributes> = Record<
    string,
    NodeTypeAttributes | MarkTypeAttributes
  >
> = Obj;

export const BASE_PRIORITY = 50;

interface SchemaConfig<T extends SpecTypes> {
  name: string;
  spec: T;
  builders: DocumentBuilders;
  priority?: number;
}

export interface NodeConfig extends SchemaConfig<NodeSpec> {}
export interface MarkConfig extends SchemaConfig<MarkSpec> {}

export interface ExtensionSchema {
  nodes?: NodeConfig[];
  marks?: MarkConfig[];
}

function initializeSchema<T extends SpecTypes>(
  extensions: ExtensionSchema[],
  type: keyof ExtensionSchema
): OrderedMap<T> {
  const items = extensions.reduce((acc, elem) => {
    const extension = elem[type] as SchemaConfig<T>[] | undefined;
    if (extension) {
      return acc.concat(...extension);
    } else {
      return acc;
    }
  }, new Array<SchemaConfig<T>>());

  const sortedItems = items.sort((a, b) => {
    const aPriority = a.priority == null ? BASE_PRIORITY : a.priority;
    const bPriority = b.priority == null ? BASE_PRIORITY : b.priority;

    if (aPriority > bPriority) {
      return -1;
    } else if (aPriority < bPriority) {
      return 1;
    } else {
      return 0;
    }
  });

  return sortedItems.reduce((map, item) => {
    const record: Record<string, T> = {};
    record[item.name] = item.spec;
    return map.append(record);
  }, OrderedMap.from<T>({}));
}

export function extensionSchema<S extends Schema>(
  extensions: ExtensionSchema[]
): S {
  const nodes = initializeSchema<NodeSpec>(extensions, "nodes");
  const marks = initializeSchema<MarkSpec>(extensions, "marks");

  return new Schema({
    nodes: normalizeNodes(nodes, marks),
    marks: normalizeMarks(marks)
  }) as S;
}

export function extensionBuilders(
  extensions: ExtensionSchema[]
): DocumentBuilders {
  return extensions.reduce((acc, elem) => {
    const safeNodes = elem.nodes ? elem.nodes : [];
    const safeMarks = elem.marks ? elem.marks : [];
    const builders = [...safeNodes, ...safeMarks].map((item) => item.builders);

    return builders.reduce((prev, item) => {
      return { ...prev, ...item };
    }, acc);
  }, {} as DocumentBuilders);
}

function normalizeNodes(
  items: OrderedMap<NodeSpec>,
  marks: OrderedMap<MarkSpec>
): OrderedMap<NodeSpec> {
  let updatedNodes: OrderedMap<NodeSpec> = items;

  items.forEach((name, item) => {
    if (item.marks != null && item.marks !== "_") {
      const nodeMarks = item.marks.split(" ");
      const updatedNodeMarks: string[] = [];

      marks.forEach((markName, mark) => {
        if (nodeMarks.includes(markName)) {
          updatedNodeMarks.push(markName);
        } else if (mark.group != null) {
          const found = mark.group.split(" ").find((x) => {
            return nodeMarks.includes(x);
          });

          if (found != null) {
            updatedNodeMarks.push(found);
          }
        }
      });

      updatedNodes = updatedNodes.update(name, {
        ...item,
        marks: updatedNodeMarks.length === 0 ? null : updatedNodeMarks.join(" ")
      });
    }
  });

  return updatedNodes;
}

function normalizeMarks(items: OrderedMap<MarkSpec>): OrderedMap<MarkSpec> {
  let updatedMarks: OrderedMap<MarkSpec> = items;

  items.forEach((name, item) => {
    if (item.excludes != null && item.excludes !== "_") {
      const markExcludes = item.excludes.split(" ");
      const updatedExcludes: string[] = [];

      items.forEach((markName, mark) => {
        if (markExcludes.includes(markName)) {
          updatedExcludes.push(markName);
        } else if (mark.group != null) {
          const found = mark.group.split(" ").find((x) => {
            return markExcludes.includes(x);
          });

          if (found != null) {
            updatedExcludes.push(found);
          }
        }
      });

      updatedMarks = updatedMarks.update(name, {
        ...item,
        excludes:
          updatedExcludes.length === 0 ? null : updatedExcludes.join(" ")
      });
    }
  });

  return updatedMarks;
}
