import { Schema } from "prosemirror-model";
import { EditorState, Plugin, PluginKey } from "prosemirror-state";

export type ScrollHandler = () => void;

export class ScrollState {
  private changeHandlers: ScrollHandler[] = [];

  constructor() {
    this.changeHandlers = [];
  }

  subscribe(cb: ScrollHandler) {
    this.changeHandlers.push(cb);
  }

  unsubscribe(cb: ScrollHandler) {
    this.changeHandlers = this.changeHandlers.filter((ch) => ch !== cb);
  }

  notifyScroll() {
    this.changeHandlers.forEach((cb) => cb());
  }
}

export const scrollTrackingKey = new PluginKey<ScrollState>("scrollTracking");

export function notifyScroll<S extends Schema>(state: EditorState<S>): void {
  const value = scrollTrackingKey.getState(state);
  value?.notifyScroll();
}

export const scrollTracking = new Plugin<ScrollState>({
  key: scrollTrackingKey,
  state: {
    init() {
      return new ScrollState();
    },
    apply(_tr, state) {
      return state;
    }
  },
  props: {
    handleDOMEvents: {
      scroll: (view) => {
        notifyScroll(view.state);
        return false;
      }
    }
  }
});
