import { InputRule } from "prosemirror-inputrules";
import { Node, NodeRange, NodeType, Schema } from "prosemirror-model";
import { Transaction } from "prosemirror-state";
import { canJoin, findWrapping } from "prosemirror-transform";

export function wrappingInputRule<S extends Schema>(
  regexp: RegExp,
  nodeType: NodeType,
  getAttrs?:
    | { [key: string]: any }
    | ((p: string[]) => { [key: string]: any } | undefined),
  joinPredicate?: (p1: string[], p2: Node<S>) => boolean,
  beforeWrap?: (tr: Transaction<S>, range: NodeRange<S>) => Transaction<S>
) {
  return new InputRule(regexp, (state, match, start, end) => {
    const attrs = getAttrs instanceof Function ? getAttrs(match) : getAttrs;
    let tr = state.tr.delete(start, end);
    const $start = tr.doc.resolve(start);
    const range = $start.blockRange();
    const wrapping = range && findWrapping(range, nodeType, attrs);

    if (range == null || wrapping == null) {
      return null;
    }

    if (beforeWrap != null) {
      tr = beforeWrap(tr, range);
    }
    tr = tr.wrap(range, wrapping);

    const before = tr.doc.resolve(start - 1).nodeBefore;
    if (
      before &&
      before.type === nodeType &&
      canJoin(tr.doc, start - 1) &&
      (!joinPredicate || joinPredicate(match, before))
    ) {
      tr = tr.join(start - 1);
    }

    return tr;
  });
}

export function joiningInputRule<S extends Schema>(
  regexp: RegExp,
  nodeType: NodeType<S>,
  getAttrs?:
    | { [key: string]: any }
    | ((p: string[]) => { [key: string]: any } | undefined),
  joinPredicate?: (p1: string[], p2: Node<S>) => boolean,
  beforeWrap?: (tr: Transaction<S>, range: NodeRange<S>) => Transaction<S>
): InputRule<S> {
  return new InputRule(regexp, (state, match, start, end) => {
    const attrs = getAttrs instanceof Function ? getAttrs(match) : getAttrs;
    let tr = state.tr.delete(start, end);
    const $start = tr.doc.resolve(start);
    const range = $start.blockRange();
    const wrapping = range && findWrapping(range, nodeType, attrs);

    if (range == null || wrapping == null) {
      return null;
    }

    if (beforeWrap != null) {
      tr = beforeWrap(tr, range);
    }
    tr = tr.wrap(range, wrapping);

    const before = tr.doc.resolve(start - 1).nodeBefore;
    if (
      before &&
      before.type === nodeType &&
      canJoin(tr.doc, start - 1) &&
      (!joinPredicate || joinPredicate(match, before))
    ) {
      tr = tr.join(start - 1);
      return tr;
    } else {
      return null;
    }
  });
}
