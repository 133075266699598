export function reportOutlineOverlay(onClick: () => void): HTMLElement {
  const container = document.createElement("div");
  container.className = "ProseMirror-report-overlay-container";
  container.contentEditable = "false";

  const middle = document.createElement("div");
  middle.className = "ProseMirror-report-overlay-middle";
  middle.contentEditable = "false";

  const trailing = document.createElement("div");
  trailing.className = "ProseMirror-report-overlay-trailing";
  trailing.contentEditable = "false";

  const removeButton = document.createElement("button");
  removeButton.className = "bx bx-action-close";
  removeButton.onmousedown = (event) => {
    event.preventDefault();
  };
  removeButton.onclick = () => onClick();

  trailing.appendChild(removeButton);
  container.appendChild(middle);
  container.appendChild(trailing);

  return container;
}
