import { keydownHandler } from "prosemirror-keymap";
import { Node, ResolvedPos, Schema } from "prosemirror-model";
import {
  NodeSelection,
  Plugin,
  PluginKey,
  Selection,
  TextSelection
} from "prosemirror-state";
import { CellSelection } from "prosemirror-tables";
import { Decoration, DecorationSet, EditorView } from "prosemirror-view";
import {
  canInsertAtPos,
  computeNestedStyle,
  findPositionOfNodeBefore,
  measureHeight,
  measureWidth
} from "../../../util";
import { arrow, deleteNode, setGapCursorAtPos } from "./commands";
import { Direction } from "./direction";
import { GapCursor, JSON_ID } from "./gap-cursor";
import { isLeftCursor, Side } from "./side";
import { isIgnoredClick } from "./util";

function gapCursorWidgetForSelection<S extends Schema>(
  doc: Node<S>,
  selection: Selection<S>,
  force?: boolean
): DecorationSet<S> | null {
  if (selection instanceof GapCursor) {
    const { $from, side } = selection;

    // render decoration DOM node always to the left of the target node even if selection points to the right
    // otherwise positioning of the right gap cursor is a nightmare when the target node has a nodeView with vertical margins
    let position = selection.head;
    const isRightCursor = side === Side.RIGHT;
    if (isRightCursor && $from.nodeBefore) {
      const nodeBeforeStart = findPositionOfNodeBefore(selection);
      if (typeof nodeBeforeStart === "number") {
        position = nodeBeforeStart;
      }
    }

    let key = `${JSON_ID}-${side}-${position}`;
    if (force === true) {
      key = `${key}-force`;
    }

    return DecorationSet.create(doc, [
      Decoration.widget(position, toDOM, {
        key: key,
        side: 0
      })
    ]);
  }

  return null;
}

const gapCursorKey = new PluginKey("gapCursor");

export function gapCursor<S extends Schema>(): Plugin<DecorationSet | null, S> {
  return new Plugin<DecorationSet | null, S>({
    key: gapCursorKey,
    view: () => {
      return {
        update: (view, prevState) => {
          const currentSelection = view.state.selection;
          const previousSelection = prevState.selection;

          if (currentSelection instanceof GapCursor) {
            if (
              previousSelection instanceof GapCursor &&
              currentSelection.side === previousSelection.side &&
              currentSelection.nodePos !== previousSelection.nodePos
            ) {
              view.dispatch(view.state.tr.setMeta(gapCursorKey, true));
            } else if (previousSelection instanceof TextSelection) {
              view.dispatch(view.state.tr.setMeta(gapCursorKey, true));
            }
          }
        }
      };
    },
    state: {
      init(_config, state) {
        return gapCursorWidgetForSelection(state.doc, state.selection);
      },
      apply(tr, _value, _oldState, _newState) {
        return gapCursorWidgetForSelection(
          tr.doc,
          tr.selection,
          tr.getMeta(gapCursorKey)
        );
      }
    },
    props: {
      decorations(state) {
        return this.getState(state);
      },

      // render gap cursor only when its valid
      createSelectionBetween(
        view: EditorView<S>,
        $anchor: ResolvedPos<S>,
        $head: ResolvedPos<S>
      ) {
        if (
          view &&
          view.state &&
          view.state.selection instanceof CellSelection
        ) {
          // Do not show GapCursor when there is a CellSection happening
          return;
        }

        if (
          $anchor.pos === $head.pos &&
          GapCursor.valid<S>($head, Side.RIGHT)
        ) {
          return new GapCursor<S>($head, Side.RIGHT);
        }
        return;
      },

      // place gap cursor when clicking on the side of the editor
      handleClick(view: EditorView, position: number, event: MouseEvent) {
        const posAtCoords = view.posAtCoords({
          left: event.clientX,
          top: event.clientY
        });

        if (posAtCoords != null && posAtCoords.inside > -1) {
          const node = view.state.doc.nodeAt(posAtCoords.inside);
          if (node != null && NodeSelection.isSelectable(node)) {
            return false;
          }
        }

        // this helps to ignore all of the clicks outside of the parent (e.g. nodeView controls)
        if (
          posAtCoords &&
          posAtCoords.inside !== position &&
          !isIgnoredClick(event.target as HTMLElement)
        ) {
          // max available space between parent and child from the left side in px
          // this ensures the correct side of the gap cursor in case of clicking in between two block nodes
          const style = window.getComputedStyle(view.dom);
          const leftSideOffsetX = Math.max(parseInt(style.paddingLeft, 10), 20);
          const side = event.offsetX > leftSideOffsetX ? Side.RIGHT : Side.LEFT;
          return setGapCursorAtPos(position, side)(view.state, view.dispatch);
        }
        return false;
      },

      handleTextInput(view, _from, _to, text) {
        const { state } = view;
        const { selection, schema } = state;

        if (!(selection instanceof GapCursor)) {
          return false;
        }

        const { $from } = selection;
        const paragraph = schema.nodes.paragraph.create(
          null,
          schema.text(text)
        );
        if (!canInsertAtPos(paragraph, $from)) {
          return true;
        } else {
          return false;
        }
      },

      handleKeyDown: keydownHandler({
        ArrowLeft: (state, dispatch, view) => {
          const endOfTextblock = view
            ? view.endOfTextblock.bind(view)
            : undefined;
          return arrow<S>(Direction.LEFT, endOfTextblock)(
            state,
            dispatch,
            view
          );
        },
        ArrowRight: (state, dispatch, view) => {
          const endOfTextblock = view
            ? view.endOfTextblock.bind(view)
            : undefined;
          return arrow<S>(Direction.RIGHT, endOfTextblock)(
            state,
            dispatch,
            view
          );
        },
        ArrowUp: (state, dispatch, view) => {
          const endOfTextblock = view
            ? view.endOfTextblock.bind(view)
            : undefined;
          return arrow<S>(Direction.UP, endOfTextblock)(state, dispatch, view);
        },
        ArrowDown: (state, dispatch, view) => {
          const endOfTextblock = view
            ? view.endOfTextblock.bind(view)
            : undefined;
          return arrow<S>(Direction.DOWN, endOfTextblock)(
            state,
            dispatch,
            view
          );
        },
        Backspace: (state, dispatch, view) => {
          return deleteNode<S>(Direction.BACKWARD)(state, dispatch, view);
        },
        Delete: (state, dispatch, view) => {
          return deleteNode<S>(Direction.FORWARD)(state, dispatch, view);
        }
      })
    }
  });
}

const mutateElementStyle = (
  element: HTMLElement,
  style: CSSStyleDeclaration,
  side: Side
) => {
  if (isLeftCursor(side)) {
    element.style.marginLeft = style.getPropertyValue("margin-left");
  } else {
    element.style.marginLeft = style.getPropertyValue("margin-left");
  }
};

export function toDOM<S extends Schema>(
  view: EditorView<S>,
  getPos: () => number
) {
  const selection = view.state.selection as GapCursor<S>;
  const { side } = selection;
  const isRightCursor = side === Side.RIGHT;
  const nodeStart = getPos();
  const dom = view.nodeDOM(nodeStart);

  const element = document.createElement("span");
  element.className = `ProseMirror-gapcursor ${
    isRightCursor ? "right" : "left"
  }`;
  element.appendChild(document.createElement("span"));

  if (dom instanceof HTMLElement && element.firstChild) {
    const style = computeNestedStyle(dom) || window.getComputedStyle(dom);

    const gapCursor = element.firstChild as HTMLSpanElement;
    gapCursor.style.height = `${measureHeight(style)}px`;
    gapCursor.style.width = `${measureWidth(style)}px`;

    if (dom.previousElementSibling) {
      const previous = dom.previousElementSibling as HTMLElement;
      const previousStyle =
        computeNestedStyle(previous) || window.getComputedStyle(previous);
      const diff =
        parseFloat(style.marginTop) - parseFloat(previousStyle.marginBottom);

      gapCursor.style.marginTop = `${diff}px`;
    } else {
      gapCursor.style.marginTop = style.marginTop;
    }

    mutateElementStyle(gapCursor, style, selection.side);
  }

  return element;
}
