import { Schema } from "prosemirror-model";
import { Plugin } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";
import { Extension } from "../../editor/extension";
import { getLanguage } from "../localization";

const placeholdePlugin = (placeholder: (language?: string) => string) => {
  return new Plugin({
    props: {
      decorations(state) {
        const { doc } = state;

        const hasSingleEmptyElement =
          doc.childCount === 1 &&
          doc.firstChild != null &&
          doc.firstChild.isTextblock &&
          doc.firstChild.content.size === 0;

        if (!hasSingleEmptyElement) {
          return;
        }

        const language = getLanguage(state);

        const placeHolder = document.createElement("div");
        placeHolder.classList.add("ProseMirror-placeholder");
        placeHolder.innerHTML = placeholder(language);

        return DecorationSet.create(doc, [Decoration.widget(0, placeHolder)]);
      }
    }
  });
};

export class Placeholder implements Extension<Schema> {
  constructor(private placeholder: (language?: string) => string) {}

  get name(): string {
    return "placeholder";
  }

  plugins(): Plugin[] {
    return [placeholdePlugin(this.placeholder)];
  }
}
