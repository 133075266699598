import React, { useCallback, useMemo, useState } from "react";
import "./ToolbarTooltip.css";

function isMac(): boolean {
  return typeof navigator !== "undefined"
    ? /Mac/.test(navigator.platform)
    : false;
}

export interface Tooltip {
  title: string;
  subtitle?: string;
  shortcut?: string;
}

interface ToolbarTooltipProps extends Tooltip {}

const ToolbarTooltip: React.FC<ToolbarTooltipProps> = (props) => {
  const { title, subtitle, shortcut, children } = props;

  const [showTooltip, setShowTooltip] = useState(false);

  const onEnter = useCallback(() => {
    setShowTooltip(true);
  }, []);

  const onLeave = useCallback(() => {
    setShowTooltip(false);
  }, []);

  const uiShortcut = useMemo(() => {
    if (shortcut) {
      return shortcut
        .toUpperCase()
        .replace("ALT-", isMac() ? "⌥+" : "Alt+")
        .replace("CTRL-", isMac() ? "⌃+" : "Ctrl+")
        .replace("META-", isMac() ? "⌘+" : "⊞+")
        .replace("SHIFT-", isMac() ? "⇧+" : "Shift+");
    } else {
      return undefined;
    }
  }, [shortcut]);

  return (
    <div
      className="ToolbarTooltip-container"
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
    >
      {children}
      {showTooltip ? (
        <div className="ToolbarTooltip">
          <div className="ToolbarTooltip-title">
            {title}
            {uiShortcut ? ` (${uiShortcut})` : undefined}
          </div>

          {subtitle ? (
            <div className="ToolbarTooltip-subtitle">{subtitle}</div>
          ) : undefined}
        </div>
      ) : undefined}
    </div>
  );
};

export default ToolbarTooltip;
