import { Node } from "prosemirror-model";
import { EditorState } from "prosemirror-state";
import { selectionFocusKey } from "../../editor/plugins/selection-focus";
import { GetTranslationFn } from "../../extensions/localization/util";
import { UnreachableCaseError } from "../../util";
import {
  InputChoiceControlType,
  InputChoiceLabelPosition,
  InputChoiceRows,
  InputChoiceSchema,
  InputChoiceType
} from "./schema";

export function createInputChoice(
  schema: InputChoiceSchema,
  type: InputChoiceControlType,
  getTranslation: GetTranslationFn
): Node<InputChoiceSchema> {
  const { inputChoice } = schema.nodes;

  const contents: Node<InputChoiceSchema>[] = [];
  contents.push(
    createInputChoiceValue(schema, InputChoiceType.standard, getTranslation)
  );

  return inputChoice.createChecked(
    {
      controlType: type,
      labelPosition: mapControlTypeToLabelPosition(type),
      choiceSelectionRangeMax:
        type === InputChoiceControlType.multipleVertical ||
        type === InputChoiceControlType.multipleHorizontal
          ? 0
          : 1,
      ...(type === InputChoiceControlType.listbox
        ? { rows: InputChoiceRows.defaultListBox }
        : { rows: InputChoiceRows.defaultStandard })
    },
    contents
  );
}

export function createInputChoiceValue(
  schema: InputChoiceSchema,
  type: InputChoiceType,
  getTranslation: GetTranslationFn
): Node<InputChoiceSchema> {
  const {
    inputChoiceValue,
    inputChoiceAllOfTheAboveValue,
    inputChoiceNoneOfTheAboveValue
  } = schema.nodes;

  switch (type) {
    case InputChoiceType.standard:
      return inputChoiceValue.create()!;

    case InputChoiceType.allOfTheAbove:
      return inputChoiceAllOfTheAboveValue.createChecked(
        { id: null },
        schema.text(getTranslation("INPUT_CHOICE.ALL_OF_THE_ABOVE_CHOICE"))
      )!;

    case InputChoiceType.noneOfTheAbove:
      return inputChoiceNoneOfTheAboveValue.createChecked(
        { id: null },
        schema.text(getTranslation("INPUT_CHOICE.NONE_OF_THE_ABOVE_CHOICE"))
      )!;

    default:
      throw new UnreachableCaseError(type);
  }
}

function mapControlTypeToLabelPosition(
  type: InputChoiceControlType
): InputChoiceLabelPosition {
  switch (type) {
    case InputChoiceControlType.singleVertical:
    case InputChoiceControlType.multipleVertical:
    case InputChoiceControlType.dropdown:
    case InputChoiceControlType.listbox:
      return InputChoiceLabelPosition.right;

    case InputChoiceControlType.singleHorizontal:
    case InputChoiceControlType.multipleHorizontal:
      return InputChoiceLabelPosition.top;

    default:
      throw new UnreachableCaseError(type);
  }
}

export function createInputChoiceOtherSpecifyValue(
  schema: InputChoiceSchema,
  getTranslation: GetTranslationFn
): Node<InputChoiceSchema> {
  const { inputChoiceOtherSpecifyValue } = schema.nodes;

  return inputChoiceOtherSpecifyValue.create(
    { id: null },
    schema.text(getTranslation("INPUT_CHOICE.OTHER_SPECIFY_CHOICE"))
  )!;
}

export function focusedInputChoice(state: EditorState<InputChoiceSchema>) {
  const { schema } = state;

  const focused = selectionFocusKey.getState(state);
  if (focused != null && focused.node.type === schema.nodes.inputChoice) {
    return focused;
  } else {
    return undefined;
  }
}

export function isSpecialChoice(
  node: Node<InputChoiceSchema>,
  schema: InputChoiceSchema
) {
  return [
    schema.nodes.inputChoiceAllOfTheAboveValue,
    schema.nodes.inputChoiceNoneOfTheAboveValue,
    schema.nodes.inputChoiceOtherSpecifyValue
  ].includes(node.type);
}
