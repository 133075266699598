import { Schema } from "prosemirror-model";
import { Selection } from "prosemirror-state";
import {
  CommandConfiguration,
  CommandConfigurations
} from "../../../editor/extension";
import { editableTransaction } from "../../../editor/plugins/editable";
import { findNode, scrollToPos } from "../../../util";
import { InputType, mapInputTypeToNodeType } from "../types";
import {
  EndSelectionAction,
  inputSelectorKey,
  RemoveSelectionAction,
  StartSelectionAction,
  UpdateSelectionAction
} from "./plugin";

export interface StartSelectionCommandProps {
  allowedTypes: InputType[];
}

export interface UpdateSelectionCommandProps {
  selection: string | null;
}

export function commands(schema: Schema): CommandConfigurations<Schema> {
  return {
    startInputSelector: startInputSelectorCommand(schema),
    updateInputSelector: updateInputSelectorCommand(schema),
    endInputSelector: endInputSelectorCommand(schema)
  };
}

function startInputSelectorCommand(
  schema: Schema
): CommandConfiguration<Schema, StartSelectionCommandProps, null> {
  return {
    isActive: () => {
      return false;
    },
    isEnabled: () => {
      return true;
    },
    execute: (props) => {
      if (props == null) {
        throw new Error(
          "To start an input selector `StartSelectionCommandProps` must be passed."
        );
      }

      return (state, dispatch) => {
        if (dispatch) {
          let tr = state.tr;

          const action: StartSelectionAction = {
            type: "start",
            allowedTypes: mapInputTypeToNodeType(props.allowedTypes, schema)
          };
          tr = tr.setSelection(Selection.near(tr.doc.resolve(0)));
          tr = editableTransaction(tr, { editable: false, focusable: false });
          tr = tr.setMeta(inputSelectorKey, action);

          dispatch(tr);
        }
        return true;
      };
    },
    requiresEditable: false
  };
}

function updateInputSelectorCommand(
  _schema: Schema
): CommandConfiguration<Schema, UpdateSelectionCommandProps, null> {
  return {
    isActive: () => {
      return false;
    },
    isEnabled: () => {
      return true;
    },
    execute: (props) => {
      if (props == null) {
        throw new Error(
          "To start an input selector `UpdateSelectionCommandProps` must be passed."
        );
      }

      return (state, dispatch, view) => {
        if (dispatch) {
          const { doc } = state;
          const { selection } = props;

          let tr = state.tr;

          const node =
            selection == null
              ? null
              : findNode(doc, (n) => n.attrs?.id === selection);

          if (node == null) {
            const action: RemoveSelectionAction = {
              type: "remove",
              emit: false
            };

            tr = tr.setMeta(inputSelectorKey, action);
          } else {
            const action: UpdateSelectionAction = {
              type: "update",
              node: node,
              emit: false
            };

            tr = tr.setMeta(inputSelectorKey, action);

            if (view != null) {
              scrollToPos(view, node.pos, {
                behavior: "smooth",
                block: "center",
                inline: "center"
              });
            }
          }

          dispatch(tr);
        }
        return true;
      };
    },
    requiresEditable: false
  };
}

function endInputSelectorCommand(
  _schema: Schema
): CommandConfiguration<Schema, {}, null> {
  return {
    isActive: () => {
      return false;
    },
    isEnabled: () => {
      return true;
    },
    execute: () => {
      return (state, dispatch) => {
        if (dispatch) {
          let tr = state.tr;

          const action: EndSelectionAction = { type: "end" };
          tr = tr.setMeta(inputSelectorKey, action);
          tr = editableTransaction(tr, { editable: true });

          dispatch(tr);
        }
        return true;
      };
    },
    requiresEditable: false
  };
}
