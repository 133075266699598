import { Node, Schema } from "prosemirror-model";
import { EditorState } from "prosemirror-state";
import { pointsToPixels, round } from "../../util/size";

const OFFSET = 30;

function indentationForMargin(value: string): number | null {
  if (value.includes("pt")) {
    const pt = parseFloat(value);
    const px = pointsToPixels(pt);
    const level = round(px / OFFSET, 1);

    return level;
  } else if (value.includes("px")) {
    const px = parseFloat(value);
    const level = round(px / OFFSET, 1);
    return level;
  } else {
    return null;
  }
}

export function getIndentation(value: string | null): number | null {
  if (value != null) {
    return indentationForMargin(value);
  } else {
    return null;
  }
}

export function getIndentationPX(indentation: number): number {
  return indentation * OFFSET;
}

export function hasIndentation<S extends Schema>(node: Node<S>): boolean {
  return node.type.spec.attrs?.indentation != null;
}

export function getIndentationNodes<S extends Schema>(): (
  state: EditorState<S>
) => { nodes: { node: Node; pos: number }[]; count: number } {
  return (state) => {
    const { selection, doc } = state;
    const { from, to } = selection;

    let nodes = new Array<{ node: Node<S>; pos: number }>();
    let count = 0;
    doc.nodesBetween(from, to, (node, pos) => {
      if (node.type.spec.allowIndentation === false) {
        return false;
      }

      if (hasIndentation(node)) {
        nodes.push({ node, pos });
        count = count + 1;
        return false;
      }
      return;
    });

    return { nodes, count };
  };
}
