import { Mark, MarkSpec, MarkType, Schema } from "prosemirror-model";
import {
  AllSelection,
  EditorState,
  Plugin,
  PluginKey,
  Selection,
  TextSelection
} from "prosemirror-state";
import {
  BASE_PRIORITY,
  CommandConfiguration,
  CommandConfigurations,
  CommandFn,
  DocumentBuilders,
  Extension,
  MarkConfig
} from "../../editor";
import { selectionFocusKey } from "../../editor/plugins/selection-focus";
import {
  activeTextMark,
  getMarkRange,
  isEqual,
  numberOfLinesInSelection,
  textForSelection,
  textMarkIsActive,
  toggleMark
} from "../../util";

class LinkMark implements MarkConfig {
  get name(): string {
    return "link";
  }

  get spec(): MarkSpec {
    return {
      group: "link",
      attrs: {
        href: {}
      },
      inclusive: false,
      parseDOM: [
        {
          tag: "a[href]",
          getAttrs: (node) => {
            const element = node as HTMLLinkElement;

            return { href: element.getAttribute("href") };
          }
        }
      ],
      toDOM(node) {
        return ["a", { href: node.attrs.href }, 0];
      }
    };
  }

  get priority(): number {
    return BASE_PRIORITY + 2;
  }

  get builders(): DocumentBuilders {
    return {
      a: { markType: "link", href: "https://test.com" },
      a2: { markType: "link", href: "https://test2.com" }
    };
  }
}

type LinkSchema = Schema<"link", any>;

export interface InsertLinkCommandProps {
  url: string;
  text: string;
}

export interface UpdateLinkCommandProps {
  url: string;
}

export interface LinkState {
  textToDisplay: string | undefined;
  textToDisplayDisabled: boolean;
  isActive: boolean;
}

export interface LinkActiveValue {
  text: string;
  url: string;
}

export const LinkKey = new PluginKey<LinkState, LinkSchema>("link");

export class Link implements Extension<LinkSchema> {
  get name() {
    return "link";
  }

  get marks() {
    return [new LinkMark()];
  }

  plugins(): Plugin[] {
    return [
      new Plugin<LinkState, LinkSchema>({
        key: LinkKey,
        state: {
          init: (_config, instance) => {
            const { schema, selection } = instance;
            const numberOfLines = numberOfLinesInSelection(schema, selection);
            const markIsActive = textMarkIsActive(schema.marks.link)(instance);

            return {
              textToDisplay: textForSelection(selection),
              textToDisplayDisabled: numberOfLines > 1,
              isActive: markIsActive && numberOfLines <= 1
            };
          },
          apply: (_tr, _value, _oldState, newState) => {
            const { schema, selection } = newState;
            const numberOfLines = numberOfLinesInSelection(schema, selection);
            const markIsActive = textMarkIsActive(schema.marks.link)(newState);

            return {
              textToDisplay: textForSelection(selection),
              textToDisplayDisabled: numberOfLines > 1,
              isActive: markIsActive && numberOfLines <= 1
            };
          }
        }
      })
    ];
  }

  commands(schema: LinkSchema): CommandConfigurations<LinkSchema> {
    return {
      insertLink: this.insertLinkCommand(schema),
      updateLink: this.updateLinkCommand(schema),
      removeLink: this.removeLinkCommand(schema)
    };
  }

  private insertLinkCommand(
    schema: LinkSchema
  ): CommandConfiguration<LinkSchema, InsertLinkCommandProps, undefined> {
    return {
      isActive: () => {
        return (state) => {
          const linkState = LinkKey.getState(state);
          return linkState != null ? linkState.isActive : false;
        };
      },
      isEnabled: () => {
        return linkIsEnabled(schema.marks.link);
      },
      execute: (props) => {
        if (props == null) {
          throw new Error(
            "To insert a Link, LinkCommandProps needs to be provided."
          );
        }

        const url = props.url;
        const text = props.text;

        if (text.length === 0) {
          throw new Error(
            "To insert a Link, LinkCommandProps needs to have a text property of length > 0."
          );
        }

        if (url.length === 0) {
          throw new Error(
            "To insert a Link, LinkCommandProps needs to have a url property of length > 0."
          );
        }

        return addLinkMark(url, text);
      }
    };
  }

  private updateLinkCommand(
    schema: LinkSchema
  ): CommandConfiguration<
    LinkSchema,
    UpdateLinkCommandProps,
    LinkActiveValue | undefined
  > {
    return {
      isActive: () => {
        return (state) => {
          const linkState = LinkKey.getState(state);
          return linkState != null ? linkState.isActive : false;
        };
      },
      isEnabled: () => {
        return (state) => {
          const linkState = LinkKey.getState(state);
          return linkState != null ? linkState.isActive : false;
        };
      },
      execute: (props) => {
        if (props == null) {
          throw new Error(
            "To update a Link, LinkCommandProps needs to be provided."
          );
        }

        const url = props.url;

        if (url.length === 0) {
          throw new Error(
            "To update a Link, LinkCommandProps needs to have a url property of length > 0."
          );
        }

        return (state, dispatch) => {
          const activeLink = activeTextMark(schema.marks.link)(state);
          if (activeLink == null) {
            return false;
          }

          const range = getMarkRange(state, activeLink);
          if (range == null) {
            return false;
          }

          if (dispatch) {
            const mark =
              url == null
                ? activeLink
                : schema.marks.link.create({ href: url });
            const start = range.from;
            const end = range.to;

            let tr = state.tr;
            tr = tr.addMark(start, end, mark);

            dispatch(tr);
          }
          return false;
        };
      },
      activeValue: () => {
        return (state) => {
          const linkState = LinkKey.getState(state);
          if (linkState == null) {
            return undefined;
          }

          if (!linkState.isActive) {
            return undefined;
          }

          const { doc } = state;

          const activeLink = activeTextMark(schema.marks.link)(state);
          if (activeLink == null) {
            return undefined;
          }

          const range = getMarkRange(state, activeLink);
          if (range == null) {
            return undefined;
          }

          const url = activeLink.attrs.href;
          const text = doc.textBetween(range.from, range.to);

          return { text: text, url: url };
        };
      }
    };
  }

  private removeLinkCommand(
    schema: LinkSchema
  ): CommandConfiguration<LinkSchema, {}, undefined> {
    return {
      isActive: () => {
        return (state) => {
          const linkState = LinkKey.getState(state);
          return linkState != null ? linkState.isActive : false;
        };
      },
      isEnabled: () => {
        return (state) => {
          const linkState = LinkKey.getState(state);
          return linkState != null ? linkState.isActive : false;
        };
      },
      execute: () => {
        return (state, dispatch) => {
          const activeLink = activeTextMark(schema.marks.link)(state);
          if (activeLink == null) {
            return false;
          }

          const range = getMarkRange(state, activeLink);
          if (range == null) {
            return false;
          }

          if (dispatch) {
            let tr = state.tr;
            tr.removeMark(range.from, range.to, activeLink);

            dispatch(tr);
          }

          return true;
        };
      }
    };
  }
}

function addLinkMark(url: string, text: string): CommandFn<LinkSchema> {
  return (state, dispatch) => {
    const { schema, selection } = state;
    const replaceText = numberOfLinesInSelection(schema, selection) <= 1;
    const mark = schema.marks.link.create({ href: url });

    if (replaceText) {
      if (dispatch) {
        const { $cursor } = selection as TextSelection<LinkSchema>;
        if ($cursor) {
          let tr = state.tr;
          tr = tr.addStoredMark(mark);
          tr = tr.insertText(text, $cursor.pos);
          tr = tr.scrollIntoView();

          dispatch(tr);
        } else {
          let tr = state.tr;

          if (selection instanceof AllSelection) {
            const { from: start } = Selection.atStart(state.doc);
            const { to: end } = Selection.atEnd(state.doc);
            tr = tr.setSelection(
              new TextSelection(tr.doc.resolve(start), tr.doc.resolve(end))
            );
          }

          tr = tr.addStoredMark(mark);
          tr = tr.insertText(text, tr.selection.from, tr.selection.to);
          tr = tr.scrollIntoView();

          dispatch(tr);
        }
      }
      return true;
    } else {
      if (dispatch) {
        const { from, to } = selection as TextSelection<LinkSchema>;

        let tr = state.tr;
        tr = tr.addMark(from, to, mark);
        tr = tr.scrollIntoView();

        dispatch(tr);
      }
      return true;
    }
  };
}

function linkIsEnabled<S extends Schema>(
  type: MarkType<S>
): (state: EditorState<S>) => boolean {
  return (state: EditorState<S>) => {
    const isActive = textMarkIsActive(type)(state);
    if (isActive) {
      return false;
    } else {
      if (!toggleMark(type)(state)) {
        return false;
      }
      const { schema } = state;

      const focused = selectionFocusKey.getState(state);

      if (
        focused &&
        [schema.nodes.image, schema.nodes.video].includes(focused.node.type)
      ) {
        return false;
      }

      const { selection } = state;
      const { from, to } = selection;
      let count = 0;
      let marks: Mark<S>[] = [];
      let insideForbiddenNode = false;

      state.doc.nodesBetween(from, to, (node, _pos, _parent) => {
        if (node.type === state.schema.nodes.linkVariable) {
          insideForbiddenNode = true;
        }

        if (node.isText) {
          count = count + 1;
          const mark = type.isInSet(node.marks);
          if (mark) {
            if (mark.attrs) {
              const activeMark = mark.type.isInSet(marks);
              if (activeMark == null) {
                marks.push(mark);
              } else {
                if (isEqual(mark.attrs, activeMark.attrs)) {
                  marks.push(mark);
                }
              }
            } else {
              marks.push(mark);
            }
          }
        }
      });

      if (insideForbiddenNode) {
        return false;
      }

      if (count > 0 && marks.length > 0) {
        return count > marks.length ? false : true;
      } else {
        return true;
      }
    }
  };
}
