import { ResolvedPos } from "prosemirror-model";
import { Plugin, PluginKey, TextSelection } from "prosemirror-state";
import { GapCursor, Side } from "../../../editor/plugins/gap-cursor";
import { CustomAreaSchema } from "../schema";

// A plugin that makes sure that the selecion is not put into a node that has been
// hidden.

function isHidden(
  $pos: ResolvedPos<CustomAreaSchema>,
  schema: CustomAreaSchema
): boolean {
  let hidden = false;
  for (let i = $pos.depth; i > 0; i--) {
    const node = $pos.node(i);
    if (
      node.type === schema.nodes.contentVariant &&
      node.attrs.active === false
    ) {
      hidden = true;
    }
  }
  return hidden;
}

const key = new PluginKey("jump-hidden-areas");

export function jumpHiddenAreas() {
  return new Plugin({
    key,
    appendTransaction: (transactions, oldState, state) => {
      const { schema } = state;
      if (state.selection.from !== state.selection.to) {
        // Only applies to collapsed selection
        return undefined;
      }

      const selectionSet = transactions.find((tr) => tr.selectionSet);

      if (selectionSet && isHidden(state.selection.$from, schema)) {
        const dir = state.selection.from > oldState.selection.from ? 1 : -1;
        let newPos = state.selection.from,
          hidden = true,
          validTextSelection = false,
          validGapCursor = false,
          $pos;
        while (hidden || (!validGapCursor && !validTextSelection)) {
          newPos += dir;
          if (newPos === 0 || newPos === state.doc.nodeSize) {
            // Could not find any valid position
            return;
          }
          $pos = state.doc.resolve(newPos);
          validTextSelection = $pos.parent.inlineContent;
          validGapCursor = GapCursor.valid($pos, dir ? Side.LEFT : Side.RIGHT);
          hidden = isHidden($pos, schema);
        }

        if ($pos == null) {
          return undefined;
        }

        const selection = validTextSelection
          ? new TextSelection($pos)
          : new GapCursor($pos, dir ? Side.LEFT : Side.RIGHT);
        return state.tr.setSelection(selection);
      }

      return undefined;
    }
  });
}
