import { NodeSpec } from "prosemirror-model";
import { DocumentBuilders, NodeConfig } from "../../../editor";
import { VariableSource } from "../schema";

export class TextVariableNode implements NodeConfig {
  get name(): string {
    return "textVariable";
  }

  get spec(): NodeSpec {
    return {
      group: "inline variable",
      marks: "text-style",
      inline: true,
      selectable: true,
      atom: true,
      draggable: true,
      focusable: true,
      attrs: {
        id: { default: null },
        displayedValue: {},
        source: {},
        code: {},
        defaultValue: {
          default: null
        }
      },
      parseDOM: [
        {
          tag: "text-variable",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return;
            }
            const element = node as HTMLElement;

            const id = element.getAttribute("id");
            const source = element.getAttribute(
              "data-source"
            ) as VariableSource;
            const code = element.getAttribute("data-code");
            const defaultValue = element.getAttribute("data-default-value");
            const displayedValue = element.textContent;

            return {
              id: id,
              source: source,
              code: code,
              defaultValue: defaultValue,
              displayedValue: displayedValue
            };
          }
        },
        {
          tag: "[data-widget='variable'][data-type='text']",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return;
            }
            const element = node as HTMLElement;

            const id = element.getAttribute("id");
            const source = element.getAttribute(
              "data-source"
            ) as VariableSource;
            const code = element.getAttribute("data-code");
            const defaultValue = element.getAttribute("data-default-value");
            const displayedValue = element.querySelector<HTMLElement>(
              "[data-displayed-value]"
            );

            return {
              id: id,
              source: source,
              code: code,
              defaultValue: defaultValue,
              displayedValue:
                displayedValue == null ? null : displayedValue.textContent
            };
          }
        }
      ],
      toDOM(node) {
        let attrs: Record<string, string> = {
          id: node.attrs.id,
          "data-source": node.attrs.source,
          "data-code": node.attrs.code
        };

        if (node.attrs.defaultValue != null) {
          attrs = {
            ...attrs,
            "data-default-value": node.attrs.defaultValue
          };
        }

        return ["text-variable", attrs, node.attrs.displayedValue];
      }
    };
  }

  get builders(): DocumentBuilders {
    return {
      textVariable: {
        nodeType: "textVariable",
        source: "demographics",
        code: "test-code",
        displayedValue: "TestCode"
      }
    };
  }
}
