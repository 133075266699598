import { MarkSpec, Schema } from "prosemirror-model";
import {
  CommandConfiguration,
  CommandConfigurations,
  DocumentBuilders,
  Extension,
  MarkConfig
} from "../../editor";
import { textMarkIsActive, textMarkIsEnabled, toggleMark } from "../../util";

class ItalicMark implements MarkConfig {
  get name(): string {
    return "italic";
  }

  get spec(): MarkSpec {
    return {
      group: "text-style",
      parseDOM: [{ tag: "i" }, { tag: "em" }, { style: "font-style=italic" }],
      toDOM() {
        return ["em", 0];
      }
    };
  }

  get builders(): DocumentBuilders {
    return { italic: { markType: "italic" } };
  }
}

type ItalicSchema = Schema<any, "italic">;

export class Italic implements Extension<ItalicSchema> {
  get name(): string {
    return "italic";
  }

  get marks(): MarkConfig[] {
    return [new ItalicMark()];
  }

  commands(schema: ItalicSchema): CommandConfigurations<ItalicSchema> {
    return {
      italic: this.italicCommand(schema)
    };
  }

  private italicCommand(
    schema: ItalicSchema
  ): CommandConfiguration<ItalicSchema, {}, undefined> {
    return {
      isActive: () => textMarkIsActive(schema.marks.italic),
      isEnabled: () => textMarkIsEnabled(schema.marks.italic),
      execute: () => toggleMark(schema.marks.italic),
      shortcuts: {
        "Mod-i": undefined
      }
    };
  }
}
