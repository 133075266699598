import { Schema } from "prosemirror-model";
import { Transaction } from "prosemirror-state";
import { NodeWithPos } from "../../util/nodes";
import { addQuestionTitleBindings } from "../../util/transforms";
import {
  findQuestionTitles,
  nodeHasQuestionTitleBinding,
  questionTitleIsEmpty,
  supportsQuestionTitleBinding
} from "./utils";

export function removeQuestionTitleBindings<S extends Schema>(
  tr: Transaction<S>,
  input: NodeWithPos<S>,
  questionTitles: NodeWithPos<S>[]
): Transaction<S> {
  const questionTitleText = questionTitles
    .map((questionTitle) => questionTitle.node.textContent)
    .join(" ");

  let attrs: Record<string, any> = {
    ...input.node.attrs,
    questionTitleRefElementId: []
  };

  if (questionTitleText.length !== 0) {
    attrs = { ...attrs, questionTitleText: questionTitleText };
  }

  tr = tr.setNodeMarkup(input.pos, undefined, attrs);

  return tr;
}

export function setInputQuestionTitles(
  tr: Transaction<Schema>,
  inputsWithoutQuestionTitles: NodeWithPos<Schema>[],
  validQuestionTitles: NodeWithPos<Schema>[],
  searchIndex: number,
  updates: (count: number) => void
) {
  let updatedQuestionTitles = 0;
  inputsWithoutQuestionTitles.forEach((inputNode) => {
    const sorted = validQuestionTitles
      .filter((n) => n.pos < inputNode.pos)
      .sort(
        (a, b) =>
          Math.abs(inputNode.pos - a.pos) - Math.abs(inputNode.pos - b.pos)
      );

    if (!sorted.length) {
      return;
    }

    let questionTitle: NodeWithPos<Schema>;
    if (searchIndex > sorted.length) {
      questionTitle = sorted[sorted.length - 1];
    } else {
      questionTitle = sorted[searchIndex - 1];
    }
    tr = addQuestionTitleBindings(tr, inputNode, [questionTitle.node]);

    updatedQuestionTitles = updatedQuestionTitles + 1;
  });

  updates(updatedQuestionTitles);

  return tr;
}

export function removeEmptyBindings<S extends Schema>(
  tr: Transaction<S>
): Transaction<S> {
  const { doc } = tr;

  const nodes = new Array<{
    input: NodeWithPos<S>;
    questionTitles: NodeWithPos<S>[];
  }>();
  doc.descendants((node, pos) => {
    if (
      supportsQuestionTitleBinding(node) &&
      nodeHasQuestionTitleBinding(node)
    ) {
      const questionTitles = findQuestionTitles(doc, node);
      if (questionTitleIsEmpty(questionTitles)) {
        nodes.push({
          input: { node: node, pos: pos },
          questionTitles: questionTitles
        });
      }
    }
  });

  nodes.forEach(({ input, questionTitles }) => {
    tr = removeQuestionTitleBindings(tr, input, questionTitles);
  });

  return tr;
}
