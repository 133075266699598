import { Node } from "prosemirror-model";

export function getQuestionTitleAttrs(
  element: Element
): { questionTitleText?: string; questionTitleRefElementId?: string[] } {
  const questionTitleRefElementId = element.getAttribute(
    "data-question-title-ref-element-id"
  );

  if (
    questionTitleRefElementId != null &&
    questionTitleRefElementId.length > 0
  ) {
    return {
      questionTitleRefElementId: questionTitleRefElementId.split(",")
    };
  } else {
    const questionTitleText = element.getAttribute("data-question-title-text");
    if (questionTitleText != null && questionTitleText.length > 0) {
      return { questionTitleText: questionTitleText };
    }
  }

  return {};
}

export function setQuestionTitleAttrs(
  node: Node
): {
  "data-question-title-ref-element-id": string;
  "data-question-title-text": string;
} {
  const questionTitleRefElementId = node.attrs
    .questionTitleRefElementId as string[];

  if (questionTitleRefElementId.length > 0) {
    return {
      "data-question-title-ref-element-id": questionTitleRefElementId.join(","),
      "data-question-title-text": ""
    };
  } else {
    const questionTitleText = node.attrs.questionTitleText as string | null;
    return {
      "data-question-title-ref-element-id": "",
      "data-question-title-text":
        questionTitleText == null ? "" : questionTitleText
    };
  }
}

export function updateQuestionTitleText(
  attrs: Record<string, any>,
  questionTitleText: string | null
): Record<string, any> {
  if (questionTitleText != null && questionTitleText.length > 0) {
    return {
      ...attrs,
      questionTitleText: questionTitleText
    };
  } else {
    return attrs;
  }
}

export function updateName(
  attrs: Record<string, any>,
  name: string | null
): Record<string, any> {
  if (name != null && name.length > 0) {
    return {
      ...attrs,
      name: name
    };
  } else {
    return attrs;
  }
}
