import { Fragment, Node, NodeType, Schema } from "prosemirror-model";
import {
  EditorState,
  NodeSelection,
  Plugin,
  Selection,
  Transaction
} from "prosemirror-state";
import { TableMap } from "prosemirror-tables";
import { addQuestionTitleBindingsInGrid, isEqual } from "../../../../util";
import { findNode, findParent, NodeWithPos } from "../../../../util/nodes";
import { InputScaleControlType } from "../../../input-scale";
import { isQuestionTitleAutoCreation } from "../../../question-title/plugins/question-title-autocreation-flag";
import { removeQuestionTitleBindings } from "../../../question-title/transactions";
import { findQuestionTitles } from "../../../question-title/utils";
import { CustomGridSchema } from "../../schema";
import { findGrids } from "../../util";

export function repeatGrid(schema: CustomGridSchema) {
  return new Plugin({
    appendTransaction(transactions, oldState, newState) {
      if (transactions.length === 0) {
        return;
      }

      if (!transactions.find((tr) => tr.docChanged)) {
        return;
      }

      if (
        transactions.find((x) => {
          return x.getMeta("history$");
        }) != null
      ) {
        return;
      }

      let tr = newState.tr;
      tr = handleRepeatGrid(tr, schema, oldState, newState);

      return tr;
    }
  });
}

function duplicateFromBlueprint<S extends Schema>(blueprint: Node<S>): Node<S> {
  const apply = blueprint.type.spec.applyBlueprint;
  if (apply == null) {
    return blueprint.copy(blueprint.content);
  }

  const defaultAttrs =
    blueprint.type.spec.attrs?.id != null ? { id: null } : {};
  const attrs = apply(defaultAttrs, blueprint.attrs);

  const children = new Array<Node<S>>();
  blueprint.content.forEach((node) => {
    children.push(duplicateFromBlueprint(node));
  });

  return blueprint.type.create(attrs, children);
}

function nodeWithoutId<S extends Schema>(node: Node<S>): Node<S> {
  let content: Node<S>[] = [];
  node.content.forEach((node) => {
    content.push(nodeWithoutId(node));
  });

  if (node.type.spec.attrs?.id != null) {
    return node.type.create({ ...node.attrs, id: null }, content, node.marks);
  } else if (content.length > 0) {
    return node.copy(Fragment.from(content));
  } else {
    return node;
  }
}

function toLabelWithoutId<S extends Schema>(node: Node<S>, schema: S): Node<S> {
  let content: Node<S>[] = [];
  node.content.forEach((node) => {
    content.push(toLabelWithoutId(node, schema));
  });

  if (node.type === schema.nodes.inputScale) {
    return schema.nodes.inputScaleLabels.create(
      {},
      content,
      node.marks
    ) as Node<S>;
  } else if (node.type === schema.nodes.inputScaleValue) {
    return schema.nodes.inputScaleLabel.create(
      { ...node.attrs, id: null },
      content,
      node.marks
    ) as Node<S>;
  } else if (node.type === schema.nodes.inputScaleNotApplicable) {
    return schema.nodes.inputScaleLabelNotApplicable.create(
      { ...node.attrs, id: null },
      content,
      node.marks
    ) as Node<S>;
  } else {
    return node;
  }
}

function equalWithoutId<S extends Schema>(a: Node<S>, b: Node<S>): boolean {
  const aWithoutId = nodeWithoutId(a);
  const bWithoutId = nodeWithoutId(b);

  return aWithoutId.eq(bWithoutId);
}

function equalContentWithoutId<S extends Schema>(
  a: Node<S>,
  b: Node<S>
): boolean {
  const aWithoutId = nodeWithoutId(a);
  const bWithoutId = nodeWithoutId(b);

  return aWithoutId.content.eq(bWithoutId.content);
}

function equalContentToLabelsWithoutId<S extends Schema>(
  input: Node<S>,
  labels: Node<S>,
  schema: S
): boolean {
  const aWithoutId = toLabelWithoutId(input, schema);
  const bWithoutId = nodeWithoutId(labels);

  return aWithoutId.content.eq(bWithoutId.content);
}

interface ChildUpdates<S extends Schema> {
  inserted: { index: number; id: string; type: NodeType<S> }[];
  deleted: { index: number; id: string; type: NodeType<S> }[];
}

function childUpdates<S extends Schema>(
  oldNode: Node<S>,
  newNode: Node<S>
): ChildUpdates<S> {
  let oldIds = new Array<{ index: number; id: string; type: NodeType<S> }>();
  oldNode.forEach((oldChild, _offset, index) => {
    oldIds.push({ index: index, id: oldChild.attrs.id, type: oldChild.type });
  });

  let newIds = new Array<{ index: number; id: string; type: NodeType<S> }>();
  newNode.forEach((newChild, _offset, index) => {
    newIds.push({ index: index, id: newChild.attrs.id, type: newChild.type });
  });

  const deleted = oldIds.filter(
    (x) => newIds.find((y) => y.id === x.id) == null
  );
  const inserted = newIds.filter(
    (x) => oldIds.find((y) => y.id === x.id) == null
  );

  return { inserted: inserted, deleted: deleted };
}

function setBlueprintAttrs<S extends Schema>(
  tr: Transaction<S>,
  node: Node<S>,
  pos: number,
  blueprint: Node<S>
): Transaction<S> {
  const apply = node.type.spec.applyBlueprint as (
    attrs: Record<string, any>,
    blueprint: Record<string, any>
  ) => Record<string, any>;
  if (apply == null) {
    return tr;
  }

  const attrs = apply(node.attrs, blueprint.attrs);
  if (!isEqual(attrs, node.attrs)) {
    let nodeSelection: number | undefined = undefined;
    if (tr.selection instanceof NodeSelection) {
      nodeSelection = tr.selection.from;
    }

    tr = tr.setNodeMarkup(pos, undefined, attrs);

    if (nodeSelection != null) {
      tr = tr.setSelection(new NodeSelection(tr.doc.resolve(nodeSelection)));
    }
  }

  return tr;
}

function updateHeaderInputs<S extends Schema>(
  tr: Transaction<S>,
  schema: CustomGridSchema,
  input: NodeWithPos<S>,
  blueprint: Node<S> | null,
  childUpdates: ChildUpdates<S> | null
): Transaction<S> {
  const { node, pos } = input;

  if (
    blueprint == null ||
    !equalContentToLabelsWithoutId(blueprint, node, schema)
  ) {
    const from = pos + 1;
    const to = from + node.nodeSize - 2;

    let nodes = new Array<Node<S>>();
    node.content.forEach((node, _offset, _index) => {
      nodes.push(node);
    });

    if (childUpdates != null) {
      const { inserted, deleted } = childUpdates;
      deleted.forEach(({ index }) => {
        nodes.splice(index, 1, (null as unknown) as Node<S>);
      });

      nodes = nodes.filter((n) => n != null);

      inserted.forEach(({ index, type }) => {
        const newType =
          type === schema.nodes.inputScaleNotApplicable
            ? (schema.nodes.inputScaleLabelNotApplicable as NodeType<S>)
            : (schema.nodes.inputScaleLabel as NodeType<S>);

        nodes.splice(index, 0, newType.create({ id: null }));
      });
    }

    if (blueprint != null) {
      const replacement = new Array<Node<S>>();
      blueprint.content.forEach((node, _offset, index) => {
        const apply = node.type.spec.applyBlueprint as (
          attrs: Record<string, any>,
          blueprint: Record<string, any>
        ) => Record<string, any>;

        const child = nodes[index];
        replacement.push(
          child.type.create(apply(child.attrs, node.attrs), node.content)
        );
      });

      tr = tr.replaceWith(from, to, replacement);
    } else {
      tr = tr.replaceWith(from, to, nodes);
    }
  }

  return tr;
}

function applyBlueprint<S extends Schema>(
  tr: Transaction<S>,
  schema: CustomGridSchema,
  input: NodeWithPos<S>,
  blueprint: Node<S>,
  childUpdates: ChildUpdates<S> | null
): Transaction<S> {
  if (isHeaderInput(blueprint)) {
    const { node, pos } = input;
    if (!equalContentToLabelsWithoutId(node, blueprint, schema)) {
      const from = pos + 1;
      const to = from + node.nodeSize - 2;

      let nodes = new Array<Node<S>>();
      node.content.forEach((node, _offset, _index) => {
        nodes.push(node);
      });

      if (childUpdates != null) {
        const { inserted, deleted } = childUpdates;
        deleted.forEach(({ index }) => {
          nodes.splice(index, 1, (null as unknown) as Node<S>);
        });

        nodes = nodes.filter((n) => n != null) as Node<S>[];

        inserted.forEach(({ index, type }) => {
          const newType =
            type === schema.nodes.inputScaleLabelNotApplicable
              ? (schema.nodes.inputScaleNotApplicable as NodeType<S>)
              : (schema.nodes.inputScaleValue as NodeType<S>);

          nodes.splice(index, 0, newType.create({ id: null }));
        });
      }

      const replacement = new Array<Node<S>>();
      blueprint.content.forEach((node, _offset, index) => {
        const apply = node.type.spec.applyBlueprint as (
          attrs: Record<string, any>,
          blueprint: Record<string, any>
        ) => Record<string, any>;

        const child = nodes[index];
        replacement.push(
          child.type.create(apply(child.attrs, node.attrs), node.content)
        );
      });

      tr = tr.replaceWith(from, to, replacement);
    }

    return tr;
  }

  const { node, pos } = input;
  tr = setBlueprintAttrs(tr, node, pos, blueprint);

  if (!equalContentWithoutId(node, blueprint)) {
    const from = pos + 1;
    const to = from + node.nodeSize - 2;

    let ids = new Array<string | null>();
    node.content.forEach((node, _offset, _index) => {
      ids.push(node.attrs.id);
    });

    if (childUpdates != null) {
      const { inserted, deleted } = childUpdates;
      deleted.forEach(({ index }) => {
        ids.splice(index, 1, null);
      });

      ids = ids.filter((n) => n != null);

      inserted.forEach(({ index }) => {
        ids.splice(index, 0, null);
      });
    }

    const replacement = new Array<Node<S>>();
    blueprint.content.forEach((node, _offset, index) => {
      const id = ids[index];
      replacement.push(
        node.type.create({ ...node.attrs, id: id }, node.content, node.marks)
      );
    });

    tr = tr.replaceWith(from, to, replacement);
  }

  return tr;
}

function isInput<S extends Schema>(node: Node<S>): boolean {
  const group = node.type.spec.group;
  if (group != null && group.split(" ").includes("gridInput")) {
    return true;
  } else {
    return false;
  }
}

function isHeaderInput<S extends Schema>(node: Node<S>): boolean {
  const group = node.type.spec.group;
  if (group != null && group.split(" ").includes("gridHeaderInput")) {
    return true;
  } else {
    return false;
  }
}

function isHeaderCell<S extends Schema>(node: Node<S>): boolean {
  const group = node.type.spec.group;
  if (group != null && group.split(" ").includes("headerCell")) {
    return true;
  } else {
    return false;
  }
}

function isFooterCell<S extends Schema>(node: Node<S>): boolean {
  const group = node.type.spec.group;
  if (group != null && group.split(" ").includes("footerCell")) {
    return true;
  } else {
    return false;
  }
}

function isRepeatGrid<S extends Schema>(node: Node<S>): boolean {
  return node.attrs.repeatGrid === true;
}

interface GridChange<S extends Schema> {
  type: "inserted" | "deleted" | "updated" | "fill";
  row: number;
  column: number;
  inputs: Node<S>[];
  tablePos: number;
  shouldSelect: boolean;
  childUpdates?: ChildUpdates<S>;
}

function cellsWithInputs<S extends Schema>(
  schema: CustomGridSchema,
  newGrid: NodeWithPos<S>,
  oldGrid?: NodeWithPos<S>
) {
  if (oldGrid == null) {
    return [];
  }

  const oldMap = TableMap.get(oldGrid.node);
  const newMap = TableMap.get(newGrid.node);

  const getCell = (
    row: number,
    column: number,
    map: TableMap,
    table: NodeWithPos<S>
  ) => {
    const { node, pos } = table;
    const cellPos = row < map.height ? map.positionAt(row, column, node) : null;
    if (cellPos == null) {
      return null;
    }
    const cell = node.nodeAt(cellPos);
    if (cell != null) {
      const inputs = new Array<Node<S>>();
      cell.descendants((node) => {
        if (isInput(node)) {
          inputs.push(node);
        } else if (isHeaderInput(node) && isHeaderCell(cell)) {
          inputs.push(node);
        }
      });
      return {
        row: row,
        column: column,
        type: cell.type,
        inputs: inputs,
        table: node,
        tablePos: pos
      };
    } else {
      return null;
    }
  };

  const changes = new Array<GridChange<S>>();

  // column was removed
  if (newMap.width < oldMap.width) {
    return [];
  }

  // column was added
  if (newMap.width > oldMap.width) {
    return [];
  }

  // row was removed
  if (newMap.height < oldMap.height) {
    return [];
  }

  // row was added
  if (newMap.height > oldMap.height) {
    let oldCellInputs = new Map<number, Node<S>[]>();
    for (let i = 0; i < oldMap.height; ++i) {
      const row = oldGrid.node.maybeChild(i);
      if (row?.type === schema.nodes.tableRow) {
        for (let j = 0; j < row.childCount; ++j) {
          const oldCell = getCell(i, j, oldMap, oldGrid);
          oldCellInputs = oldCellInputs.set(j, oldCell?.inputs ?? []);
        }
        break;
      }
    }

    for (let i = 0; i < newMap.height; ++i) {
      for (let j = 0; j < newMap.width; ++j) {
        const newCell = getCell(i, j, newMap, newGrid);
        const inputs = oldCellInputs.get(j);
        if (newCell != null) {
          if (inputs != null) {
            if (newCell.inputs.length !== inputs.length) {
              changes.push({
                type: "fill",
                row: newCell.row,
                column: newCell.column,
                inputs: inputs,
                tablePos: newCell.tablePos,
                shouldSelect: false
              });
            }
          }
        }
      }
    }

    return changes;
  }

  for (let i = 0; i < newMap.width; ++i) {
    for (let j = 0; j < newMap.height; ++j) {
      const newCell = getCell(j, i, newMap, newGrid);
      const oldCell = getCell(j, i, oldMap, oldGrid);

      if (newCell != null) {
        if (oldCell != null) {
          if (newCell.inputs.length > oldCell.inputs.length) {
            changes.push({
              type: "inserted",
              row: newCell.row,
              column: newCell.column,
              inputs: newCell.inputs,
              tablePos: newCell.tablePos,
              shouldSelect: true
            });
          } else if (oldCell.inputs.length > newCell.inputs.length) {
            changes.push({
              type: "deleted",
              row: newCell.row,
              column: newCell.column,
              inputs: oldCell.inputs,
              tablePos: newCell.tablePos,
              shouldSelect: true
            });
          } else if (
            oldCell.inputs.length === newCell.inputs.length &&
            newCell.inputs.length > 0
          ) {
            let updates: ChildUpdates<S> | undefined = undefined;
            for (let k = 0; k < newCell.inputs.length; ++k) {
              const oldInput = oldCell.inputs[k];
              const newInput = newCell.inputs[k];
              if (!equalWithoutId(oldInput, newInput)) {
                updates = childUpdates(oldInput, newInput);
                break;
              }
            }
            if (updates != null) {
              const childCount =
                newCell.inputs.length > 0 ? newCell.inputs[0].childCount : null;
              if (
                childCount != null &&
                childCount === 0 &&
                updates.deleted.length > 0
              ) {
                changes.push({
                  type: "deleted",
                  row: newCell.row,
                  column: newCell.column,
                  inputs: newCell.inputs,
                  tablePos: newCell.tablePos,
                  shouldSelect: true
                });
              } else {
                changes.push({
                  type: "updated",
                  row: newCell.row,
                  column: newCell.column,
                  inputs: newCell.inputs,
                  tablePos: newCell.tablePos,
                  childUpdates: updates,
                  shouldSelect: true
                });
              }
            }
          }
        } else {
          if (newCell.inputs.length > 0) {
            changes.push({
              type: "inserted",
              row: newCell.row,
              column: newCell.column,
              inputs: newCell.inputs,
              tablePos: newCell.tablePos,
              shouldSelect: true
            });
          }
        }
      }
    }
  }

  return changes
    .filter((x) => x != null)
    .sort((a, b) => {
      const aType = a.type;
      const bType = b.type;
      switch (aType) {
        case "deleted":
          switch (bType) {
            case "deleted":
              return 0;
            case "inserted":
              return -1;
            case "updated":
              return -1;
            case "fill":
              return -1;
            default:
              return 0;
          }
        case "inserted":
          switch (bType) {
            case "deleted":
              return 1;
            case "inserted":
              return 0;
            case "updated":
              return -1;
            case "fill":
              return -1;
            default:
              return 0;
          }
        case "updated":
          switch (bType) {
            case "deleted":
              return -1;
            case "inserted":
              return -1;
            case "updated":
              return 0;
            case "fill":
              return 1;
            default:
              return 0;
          }
        case "fill":
          switch (bType) {
            case "deleted":
              return -1;
            case "inserted":
              return -1;
            case "updated":
              return -1;
            case "fill":
              return 0;
            default:
              return 0;
          }
        default:
          return 0;
      }
    });
}

function needsHeaderInput<S extends Schema>(
  input: Node<S> | null,
  schema: S
): boolean {
  if (input == null) {
    return false;
  }

  if (
    input.type === schema.nodes.inputScale &&
    input.attrs.controlType === InputScaleControlType.labelsInRow
  ) {
    return true;
  } else {
    return false;
  }
}

function inputScaleLabelsFromInput<S extends Schema>(
  input: Node<S>,
  schema: S,
  fill: boolean
): Node<S> {
  const content = new Array<Node<S>>();

  input.content.forEach((node) => {
    const inputScaleLabelType =
      node.type === schema.nodes.inputScaleNotApplicable
        ? schema.nodes.inputScaleLabelNotApplicable
        : schema.nodes.inputScaleLabel;

    const apply = inputScaleLabelType.spec.applyBlueprint as (
      attrs: Record<string, any>,
      blueprint: Record<string, any>
    ) => Record<string, any>;

    const defaultAttrs = node.type.spec.attrs?.id != null ? { id: null } : {};
    const attrs = apply == null ? undefined : apply(defaultAttrs, node.attrs);

    const child = inputScaleLabelType.createAndFill(
      attrs,
      fill === true ? node.content : undefined
    )! as Node<S>;
    content.push(child);
  });

  return schema.nodes.inputScaleLabels.createAndFill(
    undefined,
    content
  )! as Node<S>;
}

function applyChangeToGrid<S extends Schema>(
  tr: Transaction<S>,
  change: GridChange<S>,
  callback: (
    tr: Transaction<S>,
    from: number,
    to: number,
    isInsideCell: boolean,
    input: Node<S> | null,
    childUpdates: ChildUpdates<S> | null,
    cellPos: number
  ) => Transaction<S>
): Transaction<S> {
  const getMap = (tr: Transaction<S>) => {
    const table = tr.doc.nodeAt(change.tablePos);
    if (table == null) {
      return null;
    }

    const map = TableMap.get(table);
    return { table: table, map: map };
  };

  const start = getMap(tr);
  if (start == null) {
    return tr;
  }

  const tableStart = change.tablePos + 1;
  const input = change.inputs.length > 0 ? change.inputs[0] : null;

  const { map } = start;
  for (let i = 0; i < map.height; ++i) {
    const current = getMap(tr);
    if (current == null) {
      continue;
    }

    const pos = current.map.positionAt(i, change.column, current.table);
    const cellPos = tableStart + pos;
    const cell = tr.doc.nodeAt(cellPos);

    if (cell == null) {
      continue;
    }

    const changePos = current.map.positionAt(
      change.row,
      change.column,
      current.table
    );
    const isInsideCell = changePos === pos;
    const cellFrom = cellPos + 1;
    const cellTo = cellFrom + cell.nodeSize - 2;
    tr = callback(
      tr,
      cellFrom,
      cellTo,
      isInsideCell,
      input,
      change.childUpdates == null ? null : change.childUpdates,
      cellPos
    );
  }

  return tr;
}

function cellsWithInputsInFirstRow<S extends Schema>(
  schema: CustomGridSchema,
  grid: NodeWithPos<S>
): GridChange<S>[] {
  const { node, pos } = grid;
  const rowIndex = 1;
  const row = node.maybeChild(rowIndex);
  if (row == null) {
    return [];
  }

  if (row.type !== schema.nodes.tableRow) {
    return [];
  }

  const changes = new Array<GridChange<S>>();

  const map = TableMap.get(node);
  for (let columnIndex = 0; columnIndex < map.width; ++columnIndex) {
    const cellPos = map.positionAt(rowIndex, columnIndex, node);
    const cell = node.nodeAt(cellPos);
    if (cell != null) {
      const inputs = new Array<Node<S>>();
      cell.descendants((node) => {
        if (isInput(node)) {
          inputs.push(node);
        }
      });

      changes.push({
        type: inputs.length > 0 ? "inserted" : "deleted",
        row: rowIndex,
        column: columnIndex,
        inputs: inputs,
        tablePos: pos,
        shouldSelect: false
      });
    }
  }

  return changes;
}

function handleRepeatGrid<S extends Schema>(
  tr: Transaction<S>,
  schema: CustomGridSchema,
  oldState: EditorState<S>,
  newState: EditorState<S>
): Transaction<S> {
  const addQuestionTitle = isQuestionTitleAutoCreation(newState);

  const oldGrids = findGrids(oldState.doc, oldState.schema);
  const newGrids = findGrids(newState.doc, newState.schema);

  newGrids.forEach((newGrid) => {
    if (!isRepeatGrid(newGrid.node)) {
      const { node: table, pos: tablePos } = newGrid;

      const tableStart = tablePos + 1;
      table.descendants((node, pos) => {
        const nodePos = tr.mapping.map(tableStart + pos);
        if (node.type === schema.nodes.tableInputCell) {
          tr = tr.setNodeMarkup(
            nodePos,
            schema.nodes.tableCell as NodeType<S>,
            node.attrs,
            node.marks
          );
          return false;
        }

        if (node.type === schema.nodes.tableInputHeader) {
          tr = tr.replaceWith(
            nodePos,
            nodePos + node.nodeSize,
            schema.nodes.tableHeader.createAndFill(node.attrs)! as Node<S>
          );
          return false;
        }

        if (node.type === schema.nodes.tableInputFooter) {
          tr = tr.replaceWith(
            nodePos,
            nodePos + node.nodeSize,
            schema.nodes.tableFooter.createAndFill(node.attrs)! as Node<S>
          );
          return false;
        }

        return;
      });

      return;
    }

    const oldGrid = oldGrids.find((oldGrid) => {
      return oldGrid.node.attrs.id === newGrid.node.attrs.id;
    });

    const repeatHasBeenToggledOn =
      oldGrid != null && !isRepeatGrid(oldGrid.node);

    const changes = repeatHasBeenToggledOn
      ? cellsWithInputsInFirstRow(schema, newGrid)
      : cellsWithInputs(schema, newGrid, oldGrid);

    changes.forEach((change) => {
      switch (change.type) {
        case "inserted":
          tr = applyChangeToGrid(
            tr,
            change,
            (tr, from, to, isInsideCell, input, childUpdates, cellPos) => {
              if (input == null) {
                return tr;
              }

              const cell = tr.doc.nodeAt(cellPos);
              if (cell != null) {
                if (isHeaderCell(cell)) {
                  if (!isInsideCell) {
                    if (!needsHeaderInput(input, schema)) {
                      return tr;
                    } else {
                      const scaleLabelsHeader = schema.nodes.tableInputHeader.createAndFill(
                        cell.attrs,
                        inputScaleLabelsFromInput(input, schema, true)
                      )! as Node<S>;

                      tr = tr.replaceWith(
                        cellPos,
                        cellPos + cell.nodeSize,
                        scaleLabelsHeader
                      );
                      return tr;
                    }
                  } else {
                    return tr;
                  }
                } else if (isFooterCell(cell)) {
                  if (!isInsideCell) {
                    if (!needsHeaderInput(input, schema)) {
                      return tr;
                    } else {
                      const scaleLabelsFooter = schema.nodes.tableInputFooter.createAndFill(
                        cell.attrs,
                        inputScaleLabelsFromInput(input, schema, false)
                      )! as Node<S>;

                      tr = tr.replaceWith(
                        cellPos,
                        cellPos + cell.nodeSize,
                        scaleLabelsFooter
                      );
                      return tr;
                    }
                  } else {
                    return tr;
                  }
                } else {
                  if (!isInsideCell) {
                    const node = tr.doc.nodeAt(from);
                    if (node != null && node.type === input.type) {
                      tr = tr.replaceWith(from, to, node);
                      tr = applyBlueprint(
                        tr,
                        schema,
                        { node: node, pos: from },
                        input,
                        childUpdates
                      );
                    } else {
                      const copy = duplicateFromBlueprint(input);
                      tr = tr.replaceWith(from, to, copy);
                    }
                  } else {
                    tr = tr.replaceWith(from, to, input);
                    const node = tr.doc.nodeAt(from);
                    if (node != null) {
                      tr = applyBlueprint(
                        tr,
                        schema,
                        { node: node, pos: from },
                        input,
                        childUpdates
                      );
                      if (change.shouldSelect) {
                        if (node.type === schema.nodes.inputChoice) {
                          tr = tr.setSelection(
                            Selection.near(tr.doc.resolve(from))
                          );
                        } else {
                          tr = tr.setSelection(
                            new NodeSelection(tr.doc.resolve(from))
                          );
                        }
                      }
                    }
                  }

                  tr = tr.clearIncompatible(
                    cellPos,
                    schema.nodes.tableInputCell as NodeType<S>
                  );
                  tr = tr.setNodeMarkup(
                    cellPos,
                    schema.nodes.tableInputCell as NodeType<S>,
                    cell.attrs,
                    cell.marks
                  );

                  if (addQuestionTitle) {
                    const node = tr.doc.nodeAt(from);
                    const parentGrid = findParent(
                      tr.doc.resolve(cellPos),
                      (n) => {
                        return (
                          n.type === schema.nodes.table &&
                          n.attrs.repeatGrid === true
                        );
                      }
                    );

                    if (node != null && parentGrid != null) {
                      tr = addQuestionTitleBindingsInGrid<S>(
                        tr,
                        schema as S,
                        parentGrid,
                        {
                          node: node,
                          pos: from
                        }
                      );
                    }
                  }
                }
              }

              return tr;
            }
          );
          break;

        case "updated":
          tr = applyChangeToGrid(
            tr,
            change,
            (tr, from, _to, isInsideCell, input, childUpdates, cellPos) => {
              if (input == null) {
                return tr;
              }

              const cell = tr.doc.nodeAt(cellPos);
              if (cell != null) {
                if (isHeaderCell(cell)) {
                  if (!needsHeaderInput(input, schema)) {
                    if (input.type === schema.nodes.inputScaleLabels) {
                      return tr;
                    } else {
                      const node = tr.doc.nodeAt(from);
                      if (node?.type === schema.nodes.inputScaleLabels) {
                        const cell = tr.doc.nodeAt(cellPos);
                        if (cell != null) {
                          tr = tr.replaceWith(
                            cellPos,
                            cellPos + cell.nodeSize,
                            schema.nodes.tableHeader.createAndFill(
                              cell.attrs
                            )! as Node<S>
                          );
                        }
                      }
                    }
                    return tr;
                  } else {
                    const labels = tr.doc.nodeAt(from);
                    if (
                      labels != null &&
                      labels.type === schema.nodes.inputScaleLabels
                    ) {
                      tr = updateHeaderInputs(
                        tr,
                        schema,
                        { node: labels, pos: from },
                        input,
                        childUpdates
                      );
                    } else {
                      tr = tr.replaceWith(
                        cellPos,
                        cellPos + cell.nodeSize,
                        schema.nodes.tableInputHeader.createAndFill(
                          cell.attrs,
                          inputScaleLabelsFromInput(input, schema, true)
                        )! as Node<S>
                      );
                    }
                    return tr;
                  }
                } else if (isFooterCell(cell)) {
                  if (!needsHeaderInput(input, schema)) {
                    if (input.type === schema.nodes.inputScaleLabels) {
                      if (isInsideCell) {
                        return tr;
                      } else {
                        const labels = tr.doc.nodeAt(from);
                        if (
                          labels != null &&
                          labels.type === schema.nodes.inputScaleLabels
                        ) {
                          if (labels.childCount !== input.childCount) {
                            tr = updateHeaderInputs(
                              tr,
                              schema,
                              { node: labels, pos: from },
                              null,
                              childUpdates
                            );
                          }
                        }

                        return tr;
                      }
                    } else {
                      const node = tr.doc.nodeAt(from);
                      if (node?.type === schema.nodes.inputScaleLabels) {
                        const cell = tr.doc.nodeAt(cellPos);
                        if (cell != null) {
                          tr = tr.replaceWith(
                            cellPos,
                            cellPos + cell.nodeSize,
                            schema.nodes.tableFooter.createAndFill(
                              cell.attrs
                            )! as Node<S>
                          );
                        }
                      }
                    }
                    return tr;
                  } else {
                    const labels = tr.doc.nodeAt(from);
                    if (
                      labels != null &&
                      labels.type === schema.nodes.inputScaleLabels
                    ) {
                      if (labels.childCount !== input.childCount) {
                        tr = updateHeaderInputs(
                          tr,
                          schema,
                          { node: labels, pos: from },
                          null,
                          childUpdates
                        );
                      }
                    } else {
                      tr = tr.replaceWith(
                        cellPos,
                        cellPos + cell.nodeSize,
                        schema.nodes.tableInputFooter.createAndFill(
                          cell.attrs,
                          inputScaleLabelsFromInput(input, schema, false)
                        )! as Node<S>
                      );
                    }
                    return tr;
                  }
                } else {
                  const node = tr.doc.nodeAt(from);
                  if (node != null) {
                    tr = applyBlueprint(
                      tr,
                      schema,
                      { node: node, pos: from },
                      input,
                      childUpdates
                    );
                  }

                  return tr;
                }
              }

              return tr;
            }
          );
          break;

        case "deleted":
          tr = applyChangeToGrid(
            tr,
            change,
            (tr, from, _to, isInsideCell, input, _childUpdate, cellPos) => {
              const cell = tr.doc.nodeAt(cellPos);

              if (cell != null) {
                if (isHeaderCell(cell)) {
                  if (cell.type === schema.nodes.tableInputHeader) {
                    tr = tr.replaceWith(
                      cellPos,
                      cellPos + cell.nodeSize,
                      schema.nodes.tableHeader.createAndFill(
                        cell.attrs
                      )! as Node<S>
                    );
                  }
                } else if (isFooterCell(cell)) {
                  if (cell.type === schema.nodes.tableInputFooter) {
                    tr = tr.replaceWith(
                      cellPos,
                      cellPos + cell.nodeSize,
                      schema.nodes.tableFooter.createAndFill(
                        cell.attrs
                      )! as Node<S>
                    );
                  }
                } else {
                  if (cell.content.childCount === 0) {
                    tr = tr.replaceWith(
                      cellPos,
                      cellPos + cell.nodeSize,
                      schema.nodes.tableCell.createAndFill(
                        cell.attrs
                      )! as Node<S>
                    );
                  } else {
                    tr = tr.setNodeMarkup(
                      cellPos,
                      schema.nodes.tableCell as NodeType<S>,
                      cell.attrs,
                      cell.marks
                    );
                    tr = tr.clearIncompatible(
                      cellPos,
                      schema.nodes.tableCellWithoutInputs as NodeType<S>
                    );
                  }

                  if (isInsideCell && input != null && input.attrs.id != null) {
                    if (addQuestionTitle) {
                      const focused = findNode(
                        tr.doc,
                        (n) => n.attrs.id === input.attrs.id
                      );
                      const parentGrid = findParent(
                        tr.doc.resolve(cellPos),
                        (n) => {
                          return (
                            n.type === schema.nodes.table &&
                            n.attrs.repeatGrid === true
                          );
                        }
                      );

                      if (focused != null && parentGrid != null) {
                        const boundQuestionTitles = findQuestionTitles(
                          tr.doc,
                          focused.node
                        );

                        tr = removeQuestionTitleBindings<S>(
                          tr,
                          focused,
                          boundQuestionTitles
                        );
                      }
                    }
                  }
                }
              }

              if (isInsideCell) {
                if (change.shouldSelect) {
                  tr = tr.setSelection(Selection.near(tr.doc.resolve(from)));

                  if (input != null && input.attrs.id != null) {
                    const focused = findNode(tr.doc, (n, p) => {
                      return (
                        n.attrs.id === input.attrs.id &&
                        !["cell", "header_cell"].includes(p.type.spec.tableRole)
                      );
                    });
                    if (focused != null) {
                      tr = tr.setSelection(
                        new NodeSelection(tr.doc.resolve(focused.pos))
                      );
                    }
                  }
                }
              }

              return tr;
            }
          );
          break;

        case "fill":
          tr = applyChangeToGrid(
            tr,
            change,
            (tr, from, to, isInsideCell, input, _childUpdates, cellPos) => {
              if (input == null) {
                return tr;
              }

              const cell = tr.doc.nodeAt(cellPos);
              if (cell != null) {
                if (isHeaderCell(cell)) {
                  return tr;
                } else if (isFooterCell(cell)) {
                  return tr;
                } else {
                  if (isInsideCell) {
                    const copy = duplicateFromBlueprint(input);
                    tr = tr.replaceWith(from, to, copy);

                    tr = tr.clearIncompatible(
                      cellPos,
                      schema.nodes.tableInputCell as NodeType<S>
                    );
                    tr = tr.setNodeMarkup(
                      cellPos,
                      schema.nodes.tableInputCell as NodeType<S>,
                      cell.attrs,
                      cell.marks
                    );

                    return tr;
                  }
                }
              }

              return tr;
            }
          );
          break;
      }
    });
  });

  return tr;
}
