import { MarkSpec, Schema } from "prosemirror-model";
import { Plugin, PluginKey } from "prosemirror-state";
import {
  CommandConfiguration,
  CommandConfigurations,
  DocumentBuilders,
  Extension,
  MarkConfig
} from "../../editor";
import {
  activeTextMark,
  ApplyAround,
  removeMark,
  textMarkIsActive,
  textMarkIsEnabled,
  updateMark
} from "../../util";

export interface Font {
  name: string;
  css: string;
  default?: boolean;
}

class TextFontMark implements MarkConfig {
  constructor(private fonts: Font[]) {}

  get name(): string {
    return "textFont";
  }

  get spec(): MarkSpec {
    const fonts = this.fonts;

    return {
      group: "text-style",
      attrs: { family: {} },
      parseDOM: [
        {
          style: "font-family",
          getAttrs: (value) => {
            if (typeof value !== "string") {
              return false;
            }

            if (value == null) {
              return false;
            }

            const processedValue = value
              .split(",")
              .map((css) => css.trim().replace(/"/g, "").replace(/'/g, ""));

            const font = fonts.find((font) => {
              return processedValue.includes(font.name);
            });

            return font != null ? { family: font.name } : false;
          }
        }
      ],
      toDOM(node) {
        const font = fonts.find((font) => font.name === node.attrs.family);
        return [
          "span",
          {
            style: `font-family: ${font?.css}`
          },
          0
        ];
      }
    };
  }

  get builders(): DocumentBuilders {
    return {
      headingsFont: { markType: "textFont", family: "Headings" },
      arialFont: { markType: "textFont", family: "Arial" },
      timesNewRomanFont: {
        markType: "textFont",
        family: "Times New Roman"
      },
      unknownFont: {
        markType: "textFont",
        family: "NOT A REAL FONT"
      }
    };
  }
}

type TextFontSchema = Schema<any, "textFont" | "styles">;

interface TextFontCommandProps {
  family: string;
  around?: ApplyAround;
}

export const TextFontKey = new PluginKey<Font[], TextFontSchema>("text-font");

export class TextFont implements Extension<TextFontSchema> {
  private defaultFont: Font;

  constructor(private fonts: Font[]) {
    const defaultFont = this.fonts.find((font) => font.default === true);

    if (defaultFont == null) {
      throw new Error(
        `Fonts provided to the ${TextFont.name} extension must have a default font. Set the default property to true for one of the provided fonts.`
      );
    }

    this.defaultFont = defaultFont;
  }

  get name(): string {
    return "textFont";
  }

  get marks(): MarkConfig[] {
    return [new TextFontMark(this.fonts)];
  }

  get documentStyles(): { [key: string]: string } {
    return { "text-font": this.defaultFont.css };
  }

  plugins(): Plugin[] {
    return [
      new Plugin<Font[], TextFontSchema>({
        key: TextFontKey,
        state: {
          init: () => {
            return this.fonts;
          },
          apply: () => {
            return this.fonts;
          }
        }
      })
    ];
  }

  commands(schema: TextFontSchema): CommandConfigurations<TextFontSchema> {
    return {
      textFont: this.textFontCommand(schema)
    };
  }

  private textFontCommand(
    schema: TextFontSchema
  ): CommandConfiguration<TextFontSchema, TextFontCommandProps, string> {
    return {
      isActive: (props) => {
        return (state) => {
          const markIsActiveFn = textMarkIsActive(
            schema.marks.textFont,
            props,
            {
              family: this.defaultFont.name
            }
          );

          return markIsActiveFn(state);
        };
      },
      isEnabled: () => textMarkIsEnabled(schema.marks.textFont),
      execute: (props) => {
        return props?.family
          ? updateMark(
              schema.marks.textFont,
              props,
              props.around == null ? "word" : props.around
            )
          : removeMark(schema.marks.textFont);
      },
      activeValue: () => {
        return (state) => {
          const hasActiveMark = activeTextMark(schema.marks.textFont)(state);

          if (hasActiveMark != null) {
            return hasActiveMark.attrs.family;
          }

          const acitveMarkFn = activeTextMark(
            schema.marks.textFont,
            undefined,
            {
              family: this.defaultFont.name
            }
          );

          const mark = acitveMarkFn(state);

          if (mark) {
            return mark.attrs.family;
          } else {
            return undefined;
          }
        };
      }
    };
  }
}
