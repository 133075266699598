import { Schema } from "prosemirror-model";
import { Transaction } from "prosemirror-state";
import { CommandFn } from "../../editor";
import { hasAlignment } from "./util";

export function removeAlignment<S extends Schema>(): CommandFn<S> {
  return (state, dispatch) => {
    if (dispatch) {
      const { from, to } = state.selection;
      let tr = removeAlignmentTr(state.tr, from, to);

      dispatch(tr);
    }
    return true;
  };
}

export function removeAlignmentTr<S extends Schema>(
  tr: Transaction<S>,
  from: number,
  to: number
): Transaction {
  tr.doc.nodesBetween(from, to, (node, pos) => {
    if (hasAlignment(node)) {
      tr = tr.setNodeMarkup(
        pos,
        node.type,
        {
          ...node.attrs,
          alignment: undefined
        },
        node.marks
      );
    }
  });

  return tr;
}
