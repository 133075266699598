interface ControlWithDom {
  dom: HTMLElement;
  destroy(): void;
}

export class ActionControls {
  dom: HTMLElement;

  constructor(private buttons: ControlWithDom[]) {
    const container = document.createElement("action-controls");
    container.contentEditable = "false";

    buttons.forEach((button) => {
      container.appendChild(button.dom);
    });

    this.dom = container;
  }

  destroy() {
    this.buttons.forEach((button) => {
      if (button.destroy) {
        button.destroy();
      }
    });
  }
}
