import { Schema } from "prosemirror-model";
import { EditorView } from "prosemirror-view";
import { LanguageObserver } from "../../../extensions/localization";

interface IconProps {
  icon: string;
  title: string;
}
export class ActionControlButton {
  private languageObserver: LanguageObserver<Schema>;

  dom: HTMLElement;

  constructor(
    view: EditorView,
    private icon: IconProps,
    active: boolean,
    onClick: () => void
  ) {
    this.languageObserver = new LanguageObserver(view, (getTranslation) => {
      this.dom.title = getTranslation(this.icon.title);
    });

    const container = document.createElement("action-control-button");
    container.contentEditable = "false";
    container.onmousedown = (e: MouseEvent) => {
      e.preventDefault();
    };
    container.onclick = () => {
      onClick();
    };

    this.dom = container;

    this.setIcon(icon);
    this.setActive(active);
  }

  setIcon(props: IconProps): void {
    const getTranslation = this.languageObserver.getTranslation;
    this.icon = props;

    this.dom.className = `bx bx-${props.icon}`;
    this.dom.title = getTranslation(props.title);
  }

  setActive(active: boolean): void {
    if (active) {
      this.dom.classList.add("active");
    } else {
      this.dom.classList.remove("active");
    }
  }

  ignoreMutation(
    mutation:
      | MutationRecord
      | {
          type: "selection";
          target: Element;
        }
  ): boolean {
    if (mutation.target === this.dom) {
      return true;
    }

    return false;
  }

  destroy(): void {
    this.languageObserver.destroy();
  }
}
