import { UnreachableCaseError } from "./unreachable-error";

export function rgbToHex(value: string): string | null {
  const matches = value.match(/(0?\.?\d{1,3})%?\b/g);

  if (matches && matches.length >= 3) {
    const [red, green, blue] = matches.map(Number);
    const hexCode =
      "#" +
      (blue | (green << 8) | (red << 16) | (1 << 24)).toString(16).slice(1);

    return hexCode.toUpperCase();
  }

  return null;
}

export function isHexString(value: string): boolean {
  const hexRegex = new RegExp(/^#[0-9A-F]{6}$/i);
  return hexRegex.test(value);
}

function appendToBody(element: HTMLElement): void {
  if (!isInBody(element)) {
    document.body.appendChild(element);
  }
}

function removeToBody(element: HTMLElement): void {
  if (isInBody(element)) {
    document.body.removeChild(element);
  }
}

export function getHexColor(
  property: "background" | "color",
  value: string
): string | null {
  const invalidColors = ["inherit", "inital", "transparent", "windowtext"];
  if (invalidColors.includes(value)) {
    return null;
  }

  const newElt = document.createElement("div");
  newElt.style.display = "none";
  newElt.style[property] = value;

  appendToBody(newElt);
  const newEltStyle = window.getComputedStyle(newElt);
  const colorProperties = {
    color: rgbToHex(newEltStyle.color),
    backgroundColor: rgbToHex(newEltStyle.backgroundColor)
  };
  removeToBody(newElt);

  switch (property) {
    case "background":
      return colorProperties.backgroundColor;
    case "color":
      return colorProperties.color;
    default:
      throw new UnreachableCaseError(property);
  }
}

function isInBody(element: HTMLElement): boolean {
  return element.parentNode === document.body;
}
