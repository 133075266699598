import {
  Editor as BlueXEditor,
  InputScaleActiveValue,
  InputScaleControlType,
  InputScaleOrientation,
  InputScaleRows,
  InputScaleWidth,
  scalesKey,
  Schema,
  userDefinedScale
} from "@blue-x/editor";
import React, { useCallback, useMemo } from "react";
import {
  CheckboxFormControl,
  NumberFormControl,
  QuestionTitleFormControl,
  SelectFormControl,
  TextFormControl
} from "../ProportiesPanelControls";
import { toText } from "../util";
import "./InputScale.css";

interface InputScalePropertiesPanelProps {
  editor: BlueXEditor<Schema>;
}

export const InputScalePropertiesPanel: React.FC<InputScalePropertiesPanelProps> = (
  props
) => {
  const { editor } = props;
  const { commands } = editor;

  const activeValue: InputScaleActiveValue = useMemo(() => {
    return commands.updateInputScale.activeValue();
  }, [commands]);

  const update = useCallback(
    (value) => {
      commands.updateInputScale.execute(value, false);
    },
    [commands]
  );

  const updateResponseIds = useCallback(
    (id, responseId) => {
      const responseIds = activeValue.values.map((v) => {
        return { id: v.id, coding: v.id === id ? responseId : v.coding };
      });

      update({ responseIds: responseIds });
    },
    [activeValue, update]
  );

  const scales = useMemo(() => {
    const state = scalesKey.getState(editor.state);
    if (state == null) {
      return [];
    }

    return [userDefinedScale].concat(state.scales);
  }, [editor]);

  return (
    <div className="InputScalePropertiesPanel">
      <SelectFormControl
        label={"Type"}
        value={activeValue.controlType}
        onUpdate={(value) => {
          update({ controlType: value });
        }}
      >
        <option value={InputScaleControlType.labelsInRow}>Labels in row</option>
        <option value={InputScaleControlType.dropdown}>Dropdown</option>
        <option value={InputScaleControlType.listbox}>Listbox</option>
      </SelectFormControl>

      <SelectFormControl
        label={"Label"}
        value={activeValue.orientation}
        onUpdate={(value) => {
          update({ orientation: value });
        }}
      >
        <option value={InputScaleOrientation.top}>Top</option>
        <option value={InputScaleOrientation.bottom}>Bottom</option>
        <option value={InputScaleOrientation.boxed}>Boxed</option>
      </SelectFormControl>

      <NumberFormControl
        label={"Maximum height"}
        value={activeValue.rows}
        min={InputScaleRows.minimum}
        max={InputScaleRows.maximum}
        onUpdate={(value) => {
          update({ rows: value });
        }}
      />

      <NumberFormControl
        label={"Width (%)"}
        value={activeValue.width}
        min={InputScaleWidth.minimum}
        max={InputScaleWidth.maximum}
        onUpdate={(value) => {
          update({ width: value });
        }}
      />

      <QuestionTitleFormControl
        editor={editor}
        label={"Question title*"}
        value={activeValue.questionTitle}
      />

      <TextFormControl
        label={"Description"}
        value={activeValue.description}
        onUpdate={(value) => {
          update({ description: value });
        }}
      />

      <TextFormControl
        label={"Question ID"}
        value={activeValue.coding}
        onUpdate={(value) => {
          update({ coding: value });
        }}
      />

      <CheckboxFormControl
        label={"Not applicable"}
        value={activeValue.notApplicable}
        onUpdate={(value) => {
          update({ notApplicable: value });
        }}
      />

      <SelectFormControl
        label={"Scale category"}
        value={activeValue.scale}
        onUpdate={(value) => {
          update({ scale: value });
        }}
      >
        {scales.map((scale) => {
          return (
            <option
              key={scale.id}
              value={scale.id}
              disabled={scale.id === userDefinedScale.id}
            >
              {scale.label}
            </option>
          );
        })}
      </SelectFormControl>

      <div className="InputScale-response-id-table PropertiesPanel-FormControl">
        <div className="InputScale-response-id-table-row">
          <div className="InputScale-response-id-table-header">Lables</div>
          <div className="InputScale-response-id-table-header">Response ID</div>
        </div>

        {activeValue.values.map((value) => {
          return (
            <div className="InputScale-response-id-table-row" key={value.id}>
              <div className="InputScale-response-id-table-cell">
                {value.content.map((c) => toText(c)).join("")}
              </div>

              <TextFormControl
                className="InputScale-response-id-table-cell"
                value={value.coding}
                onUpdate={(responseId) => {
                  updateResponseIds(value.id, responseId);
                }}
              />
            </div>
          );
        })}
      </div>

      <CheckboxFormControl
        label={"Required"}
        value={activeValue.required}
        onUpdate={(value) => {
          update({ required: value });
        }}
      />
    </div>
  );
};
