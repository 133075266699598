import { Node, Schema } from "prosemirror-model";
import {
  findParent,
  IdGenerator,
  NodeWithPos,
  UniqueIdGenerator
} from "../../../util";
import { Block } from "./block";
import { BlockRange } from "./block-range";
import { BlockSelection } from "./block-selection";
import { BlockSequence } from "./block-sequence";

interface Builder {
  root: Node<Schema>;
  selection: BlockSelection | null;
  sequence: BlockSequence | null;
  sequenceIdGenerator?: IdGenerator;
}

export class BlockTemplate {
  private readonly _root: Block;
  private readonly _selection: BlockSelection;
  private readonly _sequence: BlockSequence | null;
  private readonly _sequenceIdGenerator: IdGenerator;

  constructor(builder: Builder) {
    this._root = { node: builder.root, pos: 0 };
    this._selection = builder.selection || new BlockSelection();
    this._sequence = builder.sequence;
    this._sequenceIdGenerator =
      builder.sequenceIdGenerator || new UniqueIdGenerator();
  }

  private get root(): Block {
    return this._root;
  }

  get selection(): BlockSelection {
    return this._selection;
  }

  get sequence(): BlockSequence | null {
    return this._sequence;
  }

  private createSequence(anchor: Block): BlockSequence {
    const id = this._sequenceIdGenerator.generateId();
    return new BlockSequence({ id, anchor, focus: anchor });
  }

  beginSequence(anchor: Block): BlockTemplate {
    const sequence = this.createSequence(anchor);
    return this.applySequence(sequence);
  }

  sequenceFocusOn(focus: Block): BlockTemplate {
    if (this.sequence != null) {
      const sequence = this.sequence.focusOn(focus);
      return this.applySequence(sequence);
    } else {
      return this;
    }
  }

  endSequence(): BlockTemplate {
    return this.replaceSequence(null);
  }

  private addSelectionRange(range: BlockRange): BlockTemplate {
    const selection = this.selection.addRange(range);
    return this.replaceSelection(selection);
  }

  removeSelectionRangeById(id: string): BlockTemplate {
    const range = this.selection.rangeOfId(id);
    return range != null ? this.removeSelectionRange(range) : this;
  }

  private removeSelectionRange(range: BlockRange): BlockTemplate {
    const selection = this.selection.removeRange(range);
    return this.replaceSelection(selection);
  }

  private replaceSequence(sequence: BlockSequence | null): BlockTemplate {
    return new BlockTemplate({
      root: this.root.node,
      selection: this.selection,
      sequence,
      sequenceIdGenerator: this._sequenceIdGenerator
    });
  }

  replaceSelection(selection: BlockSelection): BlockTemplate {
    return new BlockTemplate({
      root: this.root.node,
      selection,
      sequence: this.sequence,
      sequenceIdGenerator: this._sequenceIdGenerator
    });
  }

  private applySequence(sequence: BlockSequence): BlockTemplate {
    if (!sequence) {
      return this;
    }

    const sequenceRange = this.selection.rangeOfId(sequence.id);
    const templateWithoutSequenceRange = sequenceRange
      ? this.removeSelectionRange(sequenceRange)
      : this;
    const selectableRange = templateWithoutSequenceRange.selectableRangeFor(
      sequence
    );

    return templateWithoutSequenceRange
      .addSelectionRange(selectableRange)
      .replaceSequence(sequence);
  }

  private selectableRangeFor(seq: BlockSequence): BlockRange {
    if (seq.isEmpty()) {
      return BlockRange.empty(seq.id);
    }

    const anchorParent = this.findParentOf(seq.anchor);
    const focusParent = this.findParentOf(seq.focus);

    let parent: Block;
    let adjustedSeq: BlockSequence = seq;

    if (isEqual(anchorParent, this.root) && isEqual(focusParent, this.root)) {
      // anchor and focus outside table
      parent = this.root;
      adjustedSeq = seq;
    } else if (
      isEqual(anchorParent, this.root) &&
      isEqual(focusParent, this.root)
    ) {
      // anchor outside table, focus inside table
      parent = this.root;
      adjustedSeq = seq.focusOn(focusParent);
    } else if (
      isEqual(anchorParent, this.root) &&
      isEqual(focusParent, this.root)
    ) {
      // anchor inside table, focus outside
      parent = anchorParent;
      adjustedSeq =
        comparePosition(anchorParent, seq.focus) < 0
          ? seq.focusOn(getLastChild(anchorParent))
          : seq.focusOn(getFirstChild(anchorParent));
    } else if (isEqual(anchorParent, focusParent)) {
      // anchor and focus inside same table
      parent = anchorParent;
      adjustedSeq = seq;
    } else {
      // anchor and focus inside different tables
      parent = anchorParent;
      adjustedSeq =
        comparePosition(anchorParent, focusParent) <= 0
          ? seq.focusOn(getLastChild(anchorParent))
          : seq.focusOn(getFirstChild(anchorParent));
    }

    const forward = comparePosition(adjustedSeq.anchor, adjustedSeq.focus) <= 0;
    return forward
      ? this.forwardSelectableRangeFor(parent, adjustedSeq)
      : this.backwardSelectableRangeFor(parent, adjustedSeq);
  }

  private findParentOf(block: Block): Block {
    // knowingly considering a fixed depth tree
    const $pos = this._root.node.resolve(block.pos);
    return findParent($pos, () => true) || this.root;
  }

  private forwardSelectableRangeFor(
    parent: Block,
    seq: BlockSequence
  ): BlockRange {
    const subDomain = getSubdomain(parent, seq, this.root);

    const selectableAnchorBlock = subDomain.find((b) =>
      this.isBlockSelectable(b)
    );
    if (!selectableAnchorBlock) {
      return BlockRange.empty(seq.id);
    }

    let i = subDomain.indexOf(selectableAnchorBlock);
    while (subDomain[i] && this.isBlockSelectable(subDomain[i])) {
      i++;
    }

    const selectableFocusBlock = subDomain[i - 1];

    return this.createRange(
      seq.id,
      selectableAnchorBlock,
      selectableFocusBlock
    );
  }

  private backwardSelectableRangeFor(
    parent: Block,
    seq: BlockSequence
  ): BlockRange {
    const subDomain = getSubdomain(parent, seq, this.root);

    const reversedSubDomain = subDomain.slice().reverse();

    const selectableAnchorBlock = reversedSubDomain.find((b) =>
      this.isBlockSelectable(b)
    );
    if (!selectableAnchorBlock) {
      return BlockRange.empty(seq.id);
    }

    let i = subDomain.indexOf(selectableAnchorBlock);
    while (subDomain[i] && this.isBlockSelectable(subDomain[i])) {
      i--;
    }

    const selectableFocusBlock = subDomain[i + 1];

    return this.createRange(
      seq.id,
      selectableFocusBlock,
      selectableAnchorBlock
    );
  }

  private createRange(id: string, start: Block, end: Block): BlockRange {
    return new BlockRange({ id, start, end });
  }

  private isBlockSelectable(block: Block): boolean {
    return this.selection.ranges.every(
      (r) => !this.rangeContainsBlock(r, block)
    );
  }

  private rangeContainsBlock(range: BlockRange, block: Block): boolean {
    if (range.isEmpty()) {
      return false;
    }

    if (range.start == null) {
      return false;
    }

    const rangeParent = this.findParentOf(range.start);
    const blockParent = this.findParentOf(block);

    // same level containment
    if (isEqual(rangeParent, blockParent) && range.end != null) {
      return (
        comparePosition(range.start, block) <= 0 &&
        comparePosition(block, range.end) <= 0
      );
    }

    // range is at a higher level than block
    if (isEqual(rangeParent, this.root) && range.end != null) {
      return (
        comparePosition(range.start, blockParent) <= 0 &&
        comparePosition(blockParent, range.end) <= 0
      );
    }

    // block is at a higher level than range
    if (isEqual(blockParent, this.root)) {
      return block === rangeParent;
    }

    return false;
  }
}

function isEqual(a: NodeWithPos<Schema>, b: NodeWithPos<Schema>): boolean {
  return a.pos === b.pos && a.node.eq(b.node);
}

function getFirstChild(value: NodeWithPos<Schema>): NodeWithPos<Schema> {
  const { node, pos } = value;
  const firstChild = node.firstChild!;
  const firstChildPos = pos + 1;

  return { node: firstChild, pos: firstChildPos };
}

function getLastChild(value: NodeWithPos<Schema>): NodeWithPos<Schema> {
  const { node, pos } = value;
  const lastChild = node.lastChild!;
  const lastChildPos = pos + node.nodeSize - 1 - lastChild.nodeSize;

  return { node: lastChild, pos: lastChildPos };
}

function comparePosition(b1: Block, b2: Block): number {
  const idx1 = b1.pos;
  const idx2 = b2.pos;

  if (idx1 < idx2) {
    return -1;
  }
  if (idx1 > idx2) {
    return 1;
  }
  return 0;
}

function getSubdomain(parent: Block, seq: BlockSequence, root: Block): Block[] {
  const forward = comparePosition(seq.anchor, seq.focus) <= 0;
  const from = forward ? seq.anchor.pos : seq.focus.pos;
  const to = forward ? seq.focus.pos : seq.anchor.pos;

  const { node, pos: parentPos } = parent;

  let blocks = new Array<Block>();
  node.descendants((node, pos, parent) => {
    const childPos = parent.type === root.node.type ? pos : parentPos + 1 + pos;

    if (childPos >= from && childPos <= to) {
      blocks.push({ node: node, pos: childPos });
    }

    return false;
  });

  return blocks;
}
