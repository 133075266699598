import { Node, Schema } from "prosemirror-model";
import { NodeSelection } from "prosemirror-state";
import {
  CommandConfiguration,
  CommandConfigurations,
  Extension
} from "../../editor";
import {
  getAlignmentNodes,
  isAlignmentActive,
  isAlignmentEnabled
} from "./util";
import { AlignmentType, availableAlignment } from "./values";

interface TextAlignCommandProps {
  alignment: AlignmentType;
}

export class Alignment implements Extension<Schema> {
  constructor(private defaultAlignment: AlignmentType) {}

  get name(): string {
    return "alignment";
  }

  get documentStyles(): { [key: string]: string } {
    return { "text-align": this.defaultAlignment };
  }

  commands(schema: Schema): CommandConfigurations<Schema> {
    return {
      alignment: this.alignmentCommand(schema)
    };
  }

  private alignmentCommand(
    _schema: Schema
  ): CommandConfiguration<Schema, TextAlignCommandProps, AlignmentType> {
    return {
      isActive: (props) => {
        const alignment = props?.alignment;

        if (alignment != null) {
          return isAlignmentActive(alignment, this.defaultAlignment);
        } else {
          return false;
        }
      },
      isEnabled: (props) => {
        const alignment = props?.alignment;
        if (alignment == null) {
          return false;
        }

        return isAlignmentEnabled(alignment);
      },
      execute: (props) => {
        const alignment = props?.alignment;

        if (alignment != null && availableAlignment.includes(alignment)) {
          return (state, dispatch) => {
            const { nodes, count } = getAlignmentNodes(
              alignment,
              this.defaultAlignment
            )(state);

            const shouldRemove = count === nodes.length;
            let tr = state.tr;

            nodes.forEach(({ node, pos, parent }) => {
              const nodeDefaultAlignment = node.type.spec.defaultAlignment as
                | ((node: Node<Schema>, parent: Node<Schema>) => AlignmentType)
                | undefined;

              let newAlignment: AlignmentType | null;
              if (shouldRemove) {
                newAlignment = null;
              } else {
                if (
                  nodeDefaultAlignment == null &&
                  alignment === this.defaultAlignment
                ) {
                  newAlignment = null;
                } else if (
                  nodeDefaultAlignment != null &&
                  alignment === nodeDefaultAlignment(node, parent)
                ) {
                  newAlignment = null;
                } else {
                  newAlignment = alignment;
                }
              }

              tr = tr.setNodeMarkup(
                pos,
                node.type,
                {
                  ...node.attrs,
                  alignment: newAlignment
                },
                node.marks
              );
            });

            const { selection } = state;
            if (selection instanceof NodeSelection) {
              tr = tr.setSelection(
                new NodeSelection(tr.doc.resolve(selection.anchor))
              );
            }

            if (dispatch) {
              dispatch(tr);
            }

            return true;
          };
        } else {
          throw new Error("You must pass text align property");
        }
      }
    };
  }
}
