export function toNumberValue(
  value: string | null,
  numberOfDecimals: number | null
): number | null {
  if (value && !isNaN(Number(value))) {
    return numberOfDecimals === 0 ? parseInt(value, 10) : parseFloat(value);
  } else {
    return null;
  }
}
