import { Node, Schema } from "prosemirror-model";
import { EditorState } from "prosemirror-state";
import { TableMap } from "prosemirror-tables";
import { findParent } from "../../../util";
import { questionTitleBindingKey } from "../plugins/question-title-binding";
import { findQuestionTitles, nodeHasQuestionTitleBinding } from "../utils";

export interface QuestionTitleActiveValue {
  bound: boolean;
  text: string;
  isBindingInProgress: boolean;
}

export function getQuestionTitleActiveValue<S extends Schema>(
  state: EditorState<S>,
  node: Node<S>
): QuestionTitleActiveValue {
  if (nodeHasQuestionTitleBinding(node)) {
    const boundQuestionTitles = findQuestionTitles(state.doc, node);
    if (boundQuestionTitles.length === 0) {
      const questionTitleText = node.attrs.questionTitleText;

      return {
        bound: false,
        text: questionTitleText,
        isBindingInProgress: false
      };
    } else {
      const { schema, doc } = state;

      const questionTitleText = boundQuestionTitles
        .map((questionTitle) => {
          if (
            questionTitle.node.type.spec?.tableRole === "header_cell" ||
            questionTitle.node.type.spec?.tableRole === "cell"
          ) {
            const table = findParent(
              doc.resolve(questionTitle.pos),
              (n) => n.type === schema.nodes.table
            );

            if (table != null) {
              const map = TableMap.get(table.node);
              const cellPos = questionTitle.pos - table.start;
              const cellRect = map.findCell(cellPos);

              if (questionTitle.node.type.spec.tableRole === "header_cell") {
                return `C${cellRect.left + 1}: ${
                  questionTitle.node.textContent
                }`;
              } else {
                return `L${cellRect.top}: ${questionTitle.node.textContent}`;
              }
            } else {
              return questionTitle.node.textContent;
            }
          } else {
            return questionTitle.node.textContent;
          }
        })
        .join(" ");

      return {
        bound: true,
        text: questionTitleText,
        isBindingInProgress: false
      };
    }
  } else {
    const questionTitleText = node.attrs.questionTitleText;
    return {
      bound: false,
      text: questionTitleText,
      isBindingInProgress: isQuestionTitleBindingInProgress(state)
    };
  }
}

function isQuestionTitleBindingInProgress(state: EditorState): boolean {
  const plugin = questionTitleBindingKey.getState(state);
  if (plugin != null) {
    return true;
  } else {
    return false;
  }
}
