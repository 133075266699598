import { dropCursor } from "prosemirror-dropcursor";
import { keymap } from "prosemirror-keymap";
import { DOMSerializer, Mark, Node, Schema } from "prosemirror-model";
import { EditorState, Selection, Transaction } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { EditorNotification, NodeDTO } from "../util";
import {
  BASE_PRIORITY,
  Commands,
  Extension,
  extensionCommands,
  extensionInputRules,
  extensionKeymaps,
  extensionPlugins,
  extensionSchema,
  extensionShortcuts
} from "./extension";
import { baseKeymap } from "./keymaps";
import { dropPlugin } from "./plugins/drop";
import {
  Editable,
  editable,
  setEditable,
  Uneditable
} from "./plugins/editable";
import { gapCursor } from "./plugins/gap-cursor";
import { nodeRangeSelection } from "./plugins/node-range-selection";
import { nodeSelection } from "./plugins/node-selection";
import { notificationHandler } from "./plugins/notification";
import { pastePlugin } from "./plugins/paste";
import { scrollTracking } from "./plugins/scroll-tracking";
import { selectionChange } from "./plugins/selection-change";
import { selectionFocus } from "./plugins/selection-focus";

function createEmptyDocument<S extends Schema>(schema: S): Node<S> {
  const emptyDocument = {
    type: "doc",
    content: [
      {
        type: "paragraph"
      }
    ]
  };

  return schema.nodeFromJSON(emptyDocument) as Node<S>;
}

export class Editor<S extends Schema> extends EditorView<S> {
  private _commands: Commands;
  private _extensions: Extension<S>[];

  constructor(
    extensions: Extension<S>[],
    onUpdate: (editor: Editor<S>, docChanged: boolean) => void,
    onNotification: (notification: EditorNotification) => void
  ) {
    const sortedExtensions = extensions.sort((a, b) => {
      const aPriority = a.priority == null ? BASE_PRIORITY : a.priority;
      const bPriority = b.priority == null ? BASE_PRIORITY : b.priority;

      if (aPriority > bPriority) {
        return -1;
      } else if (aPriority < bPriority) {
        return 1;
      } else {
        return 0;
      }
    });

    const schema = extensionSchema<S>(sortedExtensions);

    const plugins = [
      ...extensionPlugins<S>(sortedExtensions, schema),
      ...extensionInputRules<S>(sortedExtensions, schema),
      ...extensionKeymaps<S>(sortedExtensions, schema),
      ...extensionShortcuts<S>(sortedExtensions, schema)
    ];

    const doc = createEmptyDocument(schema);

    const state = EditorState.create<S>({
      doc: doc,
      schema: schema,
      plugins: [
        editable(),
        selectionChange,
        scrollTracking,
        dropCursor(),
        gapCursor(),
        nodeSelection(),
        selectionFocus(),
        nodeRangeSelection(),
        notificationHandler(onNotification),
        ...plugins,
        pastePlugin(),
        dropPlugin(),
        keymap(baseKeymap)
      ]
    });

    super(undefined, {
      state: state,
      dispatchTransaction(this: EditorView<S>, transaction: Transaction<S>) {
        const editor = this as Editor<S>;
        const state = editor.state.apply(transaction);

        editor.updateState(state);
        editor._commands = extensionCommands<S>(sortedExtensions, editor);
        onUpdate(editor, transaction.docChanged);
      },
      handlePaste: (_view, event, _slice) => {
        if (__DEV__) {
          const clipboardEvent = event as ClipboardEvent;
          const clipboardData = clipboardEvent.clipboardData;
          console.group("Paste Event");
          clipboardData?.types
            .map((type) => ({
              type: type,
              data: clipboardData?.getData(type)
            }))
            .forEach((result) => {
              console.debug(result);
            });
          console.groupEnd();
        }
        return false;
      },
      handleDrop: (_view, event, _slice) => {
        if (__DEV__) {
          const clipboardEvent = event as DragEvent;
          const dataTransfer = clipboardEvent.dataTransfer;
          console.group("Drop Event");
          dataTransfer?.types
            .map((type) => ({
              type: type,
              data: dataTransfer?.getData(type)
            }))
            .forEach((result) => {
              console.debug(result);
            });
          console.groupEnd();
        }
        return false;
      }
    });

    this._extensions = sortedExtensions;
    this._commands = extensionCommands<S>(sortedExtensions, this);

    const styles = sortedExtensions.reduce((acc, extension) => {
      const documentStyles = extension.documentStyles;
      if (documentStyles != null) {
        return { ...acc, ...documentStyles };
      } else {
        return acc;
      }
    }, {} as { [key: string]: string });

    const dom = this.dom as HTMLElement;
    Object.entries(styles).forEach(([key, value]) => {
      dom.style.setProperty(`--document-${key}`, value);
    });
  }

  get commands(): Commands {
    return this._commands;
  }

  getJSON(): NodeDTO {
    return this.state.doc.toJSON() as NodeDTO;
  }

  getHTML(): string {
    const div = document.createElement("div");
    const fragment = DOMSerializer.fromSchema(
      this.state.schema
    ).serializeFragment(this.state.doc.content);

    div.appendChild(fragment);

    return div.innerHTML;
  }

  setEditable(value: Editable | Uneditable): void {
    setEditable(value)(this.state, this.dispatch);
  }

  replaceState(
    doc: Node<S>,
    selection?: Selection<S>,
    storedMarks?: Mark<S>[]
  ): void {
    const state = this.state;

    const newState = EditorState.create<S>({
      doc: doc,
      selection: selection,
      storedMarks: storedMarks,
      schema: state.schema,
      plugins: state.plugins
    });

    this.updateState(newState);
    this._commands = extensionCommands<S>(this._extensions, this);
  }
}
