import { Node, Schema } from "prosemirror-model";
import { Plugin, PluginKey } from "prosemirror-state";
import { Decoration, DecorationSet, EditorView } from "prosemirror-view";
import { findNode, NodeWithPos } from "../../../util";
import { setActive } from "../../custom-area";
import { customAreaToolbarView } from "../../custom-area/custom-area-variant-toolbar-view";
import {
  isSelectionRemoveButton,
  selectionOverlay
} from "../selection-overlay";
import { decorationForTarget, getNodeForTarget } from "../selection-target";
import { ConfigurationItem } from "./types";

export interface ConfigurationItemNodes {
  area: NodeWithPos<Schema>;
  variant: NodeWithPos<Schema>;
}

export interface StartSelectionAction {
  type: "start";
}

export interface UpdateSelectionAction {
  type: "update";
  area: NodeWithPos<Schema>;
  variant: NodeWithPos<Schema>;
  emit: boolean;
}

export interface ReplaceSelectionAction {
  type: "replace";
  items: ConfigurationItemNodes[];
  emit: boolean;
}

export interface RemoveSelectionAction {
  type: "remove";
  id: string;
  emit: boolean;
}

export interface EndSelectionAction {
  type: "end";
}

const CLASS_NAMES = {
  Selector: "ProseMirror-fork-selector"
};

interface ForkSelectorState {
  items: ConfigurationItemNodes[];
  targets: Decoration[];
  selected: Decoration[];
}

export const forkSelectorKey = new PluginKey<ForkSelectorState | null, Schema>(
  "forkSelector"
);

export function forkSelector(onChange: (items: ConfigurationItem[]) => void) {
  return new Plugin({
    key: forkSelectorKey,
    state: {
      init() {
        return null;
      },
      apply(tr, value, _oldState, newState) {
        const meta = tr.getMeta(forkSelectorKey) as
          | StartSelectionAction
          | UpdateSelectionAction
          | ReplaceSelectionAction
          | RemoveSelectionAction
          | EndSelectionAction
          | undefined;

        if (meta == null) {
          return value;
        }

        switch (meta.type) {
          case "start":
            const { schema } = newState;
            const targets = decorationsForStart(tr.doc, schema);

            return {
              items: [],
              targets: targets,
              selected: []
            };

          case "update":
            if (value != null) {
              const { area, variant } = meta;
              const { items } = value;

              const updatedItems = items
                .filter((i) => {
                  const areaId = i.area.node.attrs?.id as string;
                  return areaId !== area.node.attrs.id;
                })
                .concat({
                  area: area,
                  variant: variant
                })
                .sort(sortItems);
              const updatedSelected = decorationsForSelectionItems(
                updatedItems
              );

              if (meta.emit) {
                onChange(toConfigurationItem(updatedItems));
              }

              return {
                ...value,
                items: updatedItems,
                selected: updatedSelected
              };
            } else {
              return null;
            }

          case "replace":
            if (value != null) {
              const { items } = meta;

              const updatedItems = items.sort(sortItems);
              const updatedSelected = decorationsForSelectionItems(
                updatedItems
              );

              if (meta.emit) {
                onChange(toConfigurationItem(updatedItems));
              }

              return {
                ...value,
                items: updatedItems,
                selected: updatedSelected
              };
            } else {
              return null;
            }

          case "remove":
            if (value != null) {
              const { items } = value;
              const { id } = meta;

              const updatedItems = items
                .filter((i) => {
                  const areaId = i.area.node.attrs?.id as string;
                  return areaId !== id;
                })
                .sort(sortItems);
              const updatedSelected = decorationsForSelectionItems(
                updatedItems
              );

              if (meta.emit) {
                onChange(toConfigurationItem(updatedItems));
              }

              return {
                ...value,
                items: updatedItems,
                selected: updatedSelected
              };
            } else {
              return null;
            }

          case "end":
            return null;

          default:
            return value;
        }
      }
    },
    props: {
      decorations(state) {
        const value = this.getState(state);
        if (value == null) {
          return null;
        }

        const { doc } = state;
        const { targets, selected } = value;

        return DecorationSet.create(doc, targets.concat(selected));
      },
      attributes(state) {
        const value = this.getState(state);
        if (value == null) {
          return null;
        }

        let attributes: Record<string, string> = { "data-selector": "fork" };

        return attributes;
      },
      handleDOMEvents: {
        click(view, event) {
          const value = this.getState(view.state);
          if (value == null) {
            return false;
          }

          if (isSelectionRemoveButton(event.target as HTMLElement)) {
            return false;
          }

          const { state } = view;
          const { schema } = state;

          const target = getNodeForTarget(view, event.target as HTMLElement);
          if (target != null && target.node.type === schema.nodes.customArea) {
            const variant = findNode(target.node, (n) => {
              return n.attrs.default === true;
            });
            if (variant != null) {
              selectArea(view, target, variant.node.attrs.id);
            }
            return true;
          }

          return false;
        }
      }
    }
  });
}

function decorationForNode(from: number, to: number): Decoration {
  return decorationForTarget(from, to, CLASS_NAMES.Selector);
}

function decorationForSelectionItem(
  item: ConfigurationItemNodes,
  label: string
): Decoration | null {
  const { area, variant } = item;
  const { node: areaNode } = area;
  const { node: variantNode, pos: variantPos } = variant;

  return Decoration.widget(area.pos, (view, getPos) => {
    const pos = getPos();
    const node = view.nodeDOM(pos)! as HTMLElement;
    const id = areaNode.attrs?.id as string;
    const name = areaNode.attrs?.name as string;

    const box = node.getBoundingClientRect();
    const top = node.offsetTop;
    const left = node.offsetLeft;
    const width = box.width;
    const height = node.offsetHeight;

    const onClick = (id: string) => {
      const action: RemoveSelectionAction = {
        type: "remove",
        id: id,
        emit: true
      };
      view.dispatch(view.state.tr.setMeta(forkSelectorKey, action));
    };

    const customAreaToolbar = customAreaToolbarView(
      (variant) => {
        return variant.attrs.id === variantNode.attrs.id;
      },
      () => {
        const doc = view.state.doc;
        const $pos = doc.resolve(variantPos);
        const nodeBefore = $pos.nodeBefore;
        if (nodeBefore != null) {
          selectArea(view, area, nodeBefore.attrs.id);
        }
      },
      () => {
        const doc = view.state.doc;
        const $pos = doc.resolve(variantPos + variantNode.nodeSize);
        const nodeAfter = $pos.nodeAfter;
        if (nodeAfter != null) {
          selectArea(view, area, nodeAfter.attrs.id);
        }
      },
      (id) => {
        selectArea(view, area, id);
      }
    );

    const isDefault = variantNode.attrs?.default;
    customAreaToolbar.updateIndicator(name, isDefault);
    customAreaToolbar.updatePagination(areaNode);

    return selectionOverlay(
      CLASS_NAMES.Selector,
      { id: id, label: label },
      width,
      height,
      top,
      left,
      onClick,
      customAreaToolbar.element
    );
  });
}

function selectArea(
  view: EditorView<Schema>,
  area: NodeWithPos<Schema>,
  id: string
): void {
  const variant = findNode(area.node, (n) => {
    return n.attrs.id === id;
  });

  if (variant != null) {
    const action: UpdateSelectionAction = {
      type: "update",
      area: area,
      variant: {
        node: variant.node,
        pos: area.pos + variant.pos + 1
      },
      emit: true
    };

    let setActiveTr = view.state.tr;
    setActiveTr = setActive(setActiveTr, view.state.schema, area, id);
    setActiveTr.setMeta("addToHistory", false);

    view.dispatch(setActiveTr);

    let tr = view.state.tr;
    tr = tr.setMeta(forkSelectorKey, action);

    view.dispatch(tr);
  }
}

function decorationsForSelectionItems(
  items: ConfigurationItemNodes[]
): Decoration[] {
  return items.reduce((acc, r, index) => {
    const decoration = decorationForSelectionItem(r, `${index + 1}`);
    return decoration == null ? acc : acc.concat(decoration);
  }, new Array<Decoration>());
}

function decorationsForStart(doc: Node<Schema>, schema: Schema): Decoration[] {
  const targets = new Array<Decoration>();

  doc.descendants((node, pos) => {
    if (node.type === schema.nodes.customArea) {
      const from = pos;
      const to = from + node.nodeSize;

      targets.push(decorationForNode(from, to));
    }
  });

  return targets;
}

function toConfigurationItem(
  items: ConfigurationItemNodes[]
): ConfigurationItem[] {
  return items.map((i) => {
    const areaId = i.area.node.attrs?.id as string;
    const activeVariantId = i.variant.node.attrs?.id as string;

    return { areaId: areaId, activeVariantId: activeVariantId };
  });
}

function sortItems(
  a: ConfigurationItemNodes,
  b: ConfigurationItemNodes
): -1 | 1 | 0 {
  if (a.area.pos > b.area.pos) {
    return 1;
  } else if (a.area.pos < b.area.pos) {
    return -1;
  } else {
    return 0;
  }
}
