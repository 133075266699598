import { Node, Schema } from "prosemirror-model";
import { EditorView, NodeView } from "prosemirror-view";
import { ZeroWidthSpace } from "../../../util";
import { AlignmentType } from "../../alignment";

export class InputScaleValueNodeView<S extends Schema> implements NodeView<S> {
  dom: HTMLElement;
  contentDOM: HTMLElement;

  private readonly bullet: HTMLElement;

  constructor(private node: Node<S>, private view: EditorView<S>) {
    const container = document.createElement("input-scale-value");

    const { bullet, content } = labelsInRow();
    container.appendChild(bullet);
    container.appendChild(content);

    this.dom = container;
    this.contentDOM = content;
    this.bullet = bullet;

    this.updateAlignment(node.attrs.alignment);
    this.updateType(node, this.view.state.schema);
  }

  update(node: Node<S>): boolean {
    if (node.type !== this.node.type) {
      return false;
    }

    this.updateAlignment(node.attrs.alignment);
    this.updateType(node, this.view.state.schema);

    return true;
  }

  private updateAlignment(alignment: AlignmentType): void {
    if (alignment != null) {
      this.contentDOM.style.textAlign = alignment;
      this.bullet.setAttribute("data-alignment", alignment);
    } else {
      this.contentDOM.style.textAlign = "";
      this.bullet.removeAttribute("data-alignment");
    }
  }

  private updateType(node: Node<S>, schema: S): void {
    if (node.type === schema.nodes.inputScaleNotApplicable) {
      this.dom.classList.add("not-applicable");
    } else {
      this.dom.classList.remove("not-applicable");
    }
  }
}

function labelsInRow(): {
  bullet: HTMLElement;
  content: HTMLElement;
} {
  const bullet = document.createElement("div");
  bullet.className = "bullet";
  bullet.contentEditable = "false";
  bullet.appendChild(document.createTextNode(ZeroWidthSpace));

  const content = document.createElement("div");
  content.className = "content";

  return { bullet, content };
}
