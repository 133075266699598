import { Node, Schema } from "prosemirror-model";
import { NodeSelection, Plugin } from "prosemirror-state";
import {
  Decoration,
  DecorationSet,
  EditorView,
  NodeView
} from "prosemirror-view";
import { Editor } from "../../editor";
import {
  ActionControlButton,
  ActionControls
} from "../../editor/plugins/action-controls";
import { editableKey } from "../../editor/plugins/editable";
import { ZeroWidthSpace } from "../../util";
import { findChildren, findParent } from "../../util/nodes";
import { findParentNodeOfType } from "../../util/selection";
import {
  customAreaToolbarView,
  CustomAreaToolbarView
} from "./custom-area-variant-toolbar-view";

export function customAreaNodeViewPlugin() {
  return new Plugin({
    props: {
      decorations: (state) => {
        const editable = editableKey.getState(state);
        if (editable && editable.focusable === false) {
          return DecorationSet.empty;
        }

        const { doc } = state;

        const parent = findParentNodeOfType(state.schema.nodes.customArea)(
          state.selection
        );

        if (!parent) {
          return DecorationSet.empty;
        }

        const decorations = [
          Decoration.node(parent.pos, parent.pos + parent.node.nodeSize, {
            class: "ProseMirror-outline-hide"
          })
        ];

        return DecorationSet.create(doc, decorations);
      },
      nodeViews: {
        customArea: (node, view, getPos) => {
          return new CustomAreaNodeView(node, view, getPos as () => number);
        }
      },
      handleClickOn(view, _pos, node, nodePos, event, direct) {
        const { state } = view;

        const editable = editableKey.getState(state);
        if (editable && editable.focusable === false) {
          return false;
        }

        const { schema } = state;
        const target = event.target as Element;
        const isInToolbar = target.closest("custom-area-toolbar");

        const isCustomAreaSelectable =
          node.type.spec.selectable && node.type === schema.nodes.customArea;

        if (isCustomAreaSelectable && direct && isInToolbar) {
          const $pos = state.doc.resolve(nodePos + 1);
          const parent = findParent($pos, () => true);
          if (parent == null) {
            return false;
          }
          const tr = state.tr.setSelection(
            new NodeSelection(state.doc.resolve(parent.pos))
          );
          view.dispatch(tr);
          return true;
        } else {
          return false;
        }
      }
    }
  });
}

class CustomAreaNodeView<S extends Schema> implements NodeView<S> {
  dom: HTMLElement;
  contentDOM: HTMLElement;

  private customAreaToolbar: CustomAreaToolbarView;
  private actionControls: ActionControls;
  private defaultButton: ActionControlButton;

  constructor(
    private node: Node<S>,
    private view: EditorView<S>,
    getPos: () => number
  ) {
    const container = document.createElement("custom-area");

    const customAreaBefore = document.createElement("custom-area-before");
    customAreaBefore.contentEditable = "false";
    customAreaBefore.innerText = ZeroWidthSpace;

    const customAreaToolbar = customAreaToolbarView(
      (variant) => {
        return variant.attrs.active;
      },
      () => {
        const editor = view as Editor<S>;
        editor.commands.navigateBefore.execute({
          focused: {
            node: this.node,
            pos: getPos()
          }
        });
      },
      () => {
        const editor = view as Editor<S>;
        editor.commands.navigateAfter.execute({
          focused: {
            node: this.node,
            pos: getPos()
          }
        });
      },
      (id) => {
        const editor = this.view as Editor<S>;
        editor.commands.selectArea.execute({
          id: id,
          focused: {
            node: this.node,
            pos: getPos()
          }
        });
      }
    );

    const variants = document.createElement("custom-area-variants");

    const defaultButton = new ActionControlButton(
      view,
      { icon: "favorite", title: "ACTION_BUTTONS.SET_AS_DEFAULT.TITLE" },
      false,
      () => {
        const editor = view as Editor<S>;
        editor.commands.updateDefaultArea.execute();
      }
    );

    const addButton = new ActionControlButton(
      view,
      { icon: "plus", title: "ACTION_BUTTONS.ADD_AREA.TITLE" },
      false,
      () => {
        const editor = view as Editor<S>;
        editor.commands.addArea.execute();
      }
    );

    const removeButton = new ActionControlButton(
      view,
      { icon: "minus", title: "ACTION_BUTTONS.REMOVE_AREA.TITLE" },
      false,
      () => {
        const editor = view as Editor<S>;
        editor.commands.deleteArea.execute();
      }
    );

    const actionControls = new ActionControls([
      defaultButton,
      addButton,
      removeButton
    ]);

    container.appendChild(customAreaBefore);
    container.appendChild(customAreaToolbar.element);
    container.appendChild(variants);
    container.appendChild(actionControls.dom);

    this.dom = container;
    this.contentDOM = variants;
    this.customAreaToolbar = customAreaToolbar;
    this.actionControls = actionControls;
    this.defaultButton = defaultButton;

    this.updateId(node);
    this.updateDefault(node);
    this.updatePagination(node);
  }

  update(node: Node<S>, _decorations: Decoration[]): boolean {
    if (node.type !== this.node.type) {
      return false;
    }

    this.node = node;

    this.updateId(node);
    this.updateDefault(node);
    this.updatePagination(node);

    return true;
  }

  destroy() {
    this.actionControls.destroy();
  }

  private updateId(node: Node<S>): void {
    this.dom.id = node.attrs.id;
  }

  private updateDefault(node: Node<S>) {
    const schema = this.view.state.schema;
    const variants = findChildren(node, (c) => {
      return c.type === schema.nodes.contentVariant && c.attrs.active === true;
    });
    const activeVariant = variants[0];

    if (activeVariant) {
      const isDefault = activeVariant.node.attrs.default;
      this.updateIndicator(node.attrs.name, isDefault);
    }
  }

  private updateIndicator(name: string, isDefault: boolean) {
    this.customAreaToolbar.updateIndicator(name, isDefault);
    this.defaultButton.setIcon({
      icon: isDefault ? "favorite-solid" : "favorite",
      title: "ACTION_BUTTONS.SET_AS_DEFAULT.TITLE"
    });
    this.defaultButton.setActive(isDefault);
  }

  private updatePagination(node: Node<S>) {
    this.customAreaToolbar.updatePagination(node);
  }
}
