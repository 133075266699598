import { DOMParser, Schema } from "prosemirror-model";
import { Extension, extensionSchema } from "../editor";
import {
  Alignment,
  Bold,
  BulletList,
  ConstantSum,
  CustomArea,
  CustomGrid,
  Doc,
  HardBreak,
  Headings,
  History,
  Image,
  Indentation,
  InputChoice,
  InputDateTime,
  InputFile,
  InputNumber,
  InputRank,
  InputScale,
  InputText,
  Italic,
  Link,
  Logic,
  NodeIdentifier,
  OrderedList,
  PageBreak,
  Paragraph,
  QuestionTitle,
  Strikethrough,
  Subscript,
  Superscript,
  Text,
  TextBackgroundColor,
  TextColor,
  TextFont,
  TextSize,
  Underline,
  Variable,
  VerticalAlignment,
  Video
} from "../extensions";
import { NodeDTO, UnreachableCaseError } from "../util";

type QuestionnaireTemplateType = "main" | "thank_you";

type NotificationTemplateType =
  | "invitation"
  | "reminder"
  | "final-reminder"
  | "completion"
  | "manual-reminder";

type TemplateType = QuestionnaireTemplateType | NotificationTemplateType;

export function migrate(type: TemplateType, html: string): NodeDTO {
  const schema = extensionSchema(extensions(type));
  const parser = DOMParser.fromSchema(schema);
  const template = document.createElement("template");
  template.innerHTML = html.trim();

  if (template.content.firstChild == null) {
    throw new Error("No content found.");
  }

  const parsed = parser.parse(template.content.firstChild);
  return parsed.toJSON() as NodeDTO;
}

function extensions(type: TemplateType): Extension<Schema>[] {
  switch (type) {
    case "main":
      return main();

    case "thank_you":
      return thankYou();

    case "invitation":
    case "reminder":
    case "final-reminder":
    case "completion":
    case "manual-reminder":
      return notification();

    default:
      throw new UnreachableCaseError(type);
  }
}

function main(): Extension<Schema>[] {
  return [
    new Doc(),
    new Paragraph(),
    new Text(),
    new NodeIdentifier(),
    new Bold(),
    new Subscript(),
    new Superscript(),
    new Underline(),
    new Strikethrough(),
    new Italic(),
    new TextSize(11),
    new TextColor(),
    new TextBackgroundColor(),
    new TextFont([
      { name: "Headings", css: `Headings, serif` },
      { name: "Body", css: `Body, sans-serif`, default: true },
      { name: "Roboto", css: `Roboto, sans-serif` },
      { name: "Arial", css: `Arial, Helvetica, sans-serif` },
      { name: "Arial Black", css: `"Arial Black", Gadget, sans-serif` },
      { name: "Georgia", css: `Georgia, serif` },
      { name: "Impact", css: `Impact, Charcoal, sans-serif` },
      { name: "Tahoma", css: `Tahoma, Geneva, sans-serif` },
      { name: "Times New Roman", css: `"Times New Roman", Times, serif` },
      { name: "Verdana", css: `Verdana, Geneva, sans-serif` },
      { name: "Courier New", css: `"Courier New", Courier, monospace` }
    ]),
    new Headings([
      {
        level: 1,
        properties: {
          textFont: { name: "Headings", css: `Headings, serif` },
          textSize: 24
        }
      },
      {
        level: 2,
        properties: {
          textFont: { name: "Headings", css: `Headings, serif` },
          textSize: 20
        }
      },
      {
        level: 3,
        properties: {
          textFont: { name: "Headings", css: `Headings, serif` },
          textSize: 18
        }
      },
      {
        level: 4,
        properties: {
          textFont: { name: "Headings", css: `Headings, serif` },
          textSize: 16
        }
      },
      {
        level: 5,
        properties: {
          textFont: { name: "Headings", css: `Headings, serif` },
          textSize: 14
        }
      },
      {
        level: 6,
        properties: {
          textFont: { name: "Headings", css: `Headings, serif` },
          textSize: 12
        }
      }
    ]),
    new BulletList(),
    new OrderedList(),
    new History(),
    new HardBreak(),
    new Alignment("left"),
    new VerticalAlignment(),
    new Indentation(),
    new Link(),
    new Image(() => Promise.resolve("")),
    new Video(),
    new Variable(["text"], [], () => null),
    new CustomGrid(true),
    new CustomArea(),
    new PageBreak(),
    new QuestionTitle(false),
    new InputText(),
    new InputNumber(),
    new InputDateTime(),
    new InputScale([
      { id: "migration", label: "Migration", options: [], default: true }
    ]),
    new InputFile(),
    new InputChoice(),
    new ConstantSum(),
    new InputRank(),
    new Logic(
      () => {},
      () => {},
      () => {},
      () => {}
    )
  ];
}

function thankYou(): Extension<Schema>[] {
  return [
    new Doc(),
    new Paragraph(),
    new Text(),
    new NodeIdentifier(),
    new Bold(),
    new Subscript(),
    new Superscript(),
    new Underline(),
    new Strikethrough(),
    new Italic(),
    new TextSize(11),
    new TextColor(),
    new TextBackgroundColor(),
    new TextFont([
      { name: "Headings", css: `Headings, serif` },
      { name: "Body", css: `Body, sans-serif`, default: true },
      { name: "Roboto", css: `Roboto, sans-serif` },
      { name: "Arial", css: `Arial, Helvetica, sans-serif` },
      { name: "Arial Black", css: `"Arial Black", Gadget, sans-serif` },
      { name: "Georgia", css: `Georgia, serif` },
      { name: "Impact", css: `Impact, Charcoal, sans-serif` },
      { name: "Tahoma", css: `Tahoma, Geneva, sans-serif` },
      { name: "Times New Roman", css: `"Times New Roman", Times, serif` },
      { name: "Verdana", css: `Verdana, Geneva, sans-serif` },
      { name: "Courier New", css: `"Courier New", Courier, monospace` }
    ]),
    new Headings([
      {
        level: 1,
        properties: {
          textFont: { name: "Headings", css: `Headings, serif` },
          textSize: 24
        }
      },
      {
        level: 2,
        properties: {
          textFont: { name: "Headings", css: `Headings, serif` },
          textSize: 20
        }
      },
      {
        level: 3,
        properties: {
          textFont: { name: "Headings", css: `Headings, serif` },
          textSize: 18
        }
      },
      {
        level: 4,
        properties: {
          textFont: { name: "Headings", css: `Headings, serif` },
          textSize: 16
        }
      },
      {
        level: 5,
        properties: {
          textFont: { name: "Headings", css: `Headings, serif` },
          textSize: 14
        }
      },
      {
        level: 6,
        properties: {
          textFont: { name: "Headings", css: `Headings, serif` },
          textSize: 12
        }
      }
    ]),
    new BulletList(),
    new OrderedList(),
    new History(),
    new HardBreak(),
    new Alignment("left"),
    new Indentation(),
    new Link(),
    new Image(() => Promise.resolve("")),
    new Video(),
    new Variable(["text"], [], () => null)
  ];
}

function notification(): Extension<Schema>[] {
  return [
    new Doc(),
    new Paragraph(),
    new Text(),
    new NodeIdentifier(),
    new Bold(),
    new Subscript(),
    new Superscript(),
    new Underline(),
    new Strikethrough(),
    new Italic(),
    new TextSize(11),
    new TextColor(),
    new TextBackgroundColor(),
    new TextFont([
      { name: "Headings", css: `Headings, serif` },
      { name: "Body", css: `Body, sans-serif`, default: true },
      { name: "Arial", css: `Arial, Helvetica, sans-serif` },
      { name: "Arial Black", css: `"Arial Black", Gadget, sans-serif` },
      { name: "Georgia", css: `Georgia, serif` },
      { name: "Impact", css: `Impact, Charcoal, sans-serif` },
      { name: "Tahoma", css: `Tahoma, Geneva, sans-serif` },
      { name: "Times New Roman", css: `"Times New Roman", Times, serif` },
      { name: "Verdana", css: `Verdana, Geneva, sans-serif` },
      { name: "Courier New", css: `"Courier New", Courier, monospace` }
    ]),
    new Headings([
      {
        level: 1,
        properties: {
          textFont: { name: "Headings", css: `Headings, serif` },
          textSize: 24
        }
      },
      {
        level: 2,
        properties: {
          textFont: { name: "Headings", css: `Headings, serif` },
          textSize: 20
        }
      },
      {
        level: 3,
        properties: {
          textFont: { name: "Headings", css: `Headings, serif` },
          textSize: 18
        }
      },
      {
        level: 4,
        properties: {
          textFont: { name: "Headings", css: `Headings, serif` },
          textSize: 16
        }
      },
      {
        level: 5,
        properties: {
          textFont: { name: "Headings", css: `Headings, serif` },
          textSize: 14
        }
      },
      {
        level: 6,
        properties: {
          textFont: { name: "Headings", css: `Headings, serif` },
          textSize: 12
        }
      }
    ]),
    new BulletList(),
    new OrderedList(),
    new History(),
    new HardBreak(),
    new Alignment("left"),
    new VerticalAlignment(),
    new Indentation(),
    new Link(),
    new Image(() => Promise.resolve("")),
    new Video(),
    new CustomGrid(false),
    new Variable(["text", "link", "question"], [], () => null)
  ];
}
