import {
  Fragment,
  NodeRange,
  NodeType,
  Schema,
  Slice
} from "prosemirror-model";
import {
  EditorState,
  Selection,
  TextSelection,
  Transaction
} from "prosemirror-state";
import { liftTarget, ReplaceAroundStep } from "prosemirror-transform";
import { getListLiftTarget } from "./util";

function liftListItem<S extends Schema>(
  state: EditorState<S>,
  selection: Selection<S>,
  tr: Transaction<S>
): Transaction<S> {
  let { $from, $to } = selection;
  const nodeType = state.schema.nodes.listItem as NodeType<S>;
  let range = $from.blockRange(
    $to,
    (node) =>
      !!node.childCount &&
      !!node.firstChild &&
      node.firstChild.type === nodeType
  );
  if (
    !range ||
    range.depth < 2 ||
    $from.node(range.depth - 1).type !== nodeType
  ) {
    return tr;
  }
  let end = range.end;
  let endOfList = $to.end(range.depth);
  if (end < endOfList) {
    tr.step(
      new ReplaceAroundStep(
        end - 1,
        endOfList,
        end,
        endOfList,
        new Slice<S>(
          Fragment.from(nodeType.create(undefined, range.parent.copy())),
          1,
          0
        ),
        1,
        true
      )
    );

    range = new NodeRange(
      tr.doc.resolve($from.pos),
      tr.doc.resolve(endOfList),
      range.depth
    );
  }
  return tr.lift(range, liftTarget(range) as number).scrollIntoView();
}

// Function will lift list item following selection to level-1.
export function liftFollowingList<S extends Schema>(
  state: EditorState<S>,
  from: number,
  to: number,
  rootListDepth: number,
  tr: Transaction<S>
): Transaction<S> {
  const { listItem } = state.schema.nodes;
  let lifted = false;
  tr.doc.nodesBetween(from, to, (node, pos) => {
    if (!lifted && node.type === listItem && pos > from) {
      lifted = true;
      let listDepth = rootListDepth + 3;
      while (listDepth > rootListDepth + 2) {
        const start = tr.doc.resolve(tr.mapping.map(pos));
        listDepth = start.depth;
        const end = tr.doc.resolve(
          tr.mapping.map(pos + node.textContent.length)
        );
        const sel = new TextSelection(start, end);
        tr = liftListItem(state, sel, tr);
      }
    }
  });
  return tr;
}

// The function will list paragraphs in selection out to level 1 below root list.
export function liftSelectionList<S extends Schema>(
  state: EditorState<S>,
  tr: Transaction<S>
): Transaction<S> {
  const { from, to } = state.selection;
  const { paragraph } = state.schema.nodes;
  const listCol: any[] = [];
  tr.doc.nodesBetween(from, to, (node, pos) => {
    if (node.type === paragraph) {
      listCol.push({ node, pos });
    }
  });
  for (let i = listCol.length - 1; i >= 0; i--) {
    const paragraph = listCol[i];
    const start = tr.doc.resolve(tr.mapping.map(paragraph.pos));
    if (start.depth > 0) {
      let end;
      if (paragraph.node.textContent && paragraph.node.textContent.length > 0) {
        end = tr.doc.resolve(
          tr.mapping.map(paragraph.pos + paragraph.node.textContent.length)
        );
      } else {
        end = tr.doc.resolve(tr.mapping.map(paragraph.pos + 1));
      }
      const range = start.blockRange(end);
      if (range) {
        tr.lift(range, getListLiftTarget(state.schema, start));
      }
    }
  }
  return tr;
}
