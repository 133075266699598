import { Plugin } from "prosemirror-state";
import { CellSelection, fixTables } from "prosemirror-tables";
import { DecorationSet } from "prosemirror-view";
import { editableKey } from "../../../../editor/plugins/editable";
import { key } from "./key";
import {
  drawCellSelection,
  handleKeyDown,
  handleMouseDown,
  handleTripleClick,
  normalizeSelection
} from "./util";

export function cellSelection(allowTableNodeSelection = true) {
  return new Plugin({
    key: key,
    // This piece of state is used to remember when a mouse-drag
    // cell-selection is happening, so that it can continue even as
    // transactions (which might move its anchor cell) come in.
    state: {
      init() {
        return null;
      },
      apply(tr, cur, _oldState, newState) {
        const modeState = editableKey.getState(newState);
        if (modeState != null && !modeState.focusable) {
          return null;
        }

        let set = tr.getMeta(key);

        if (set != null) {
          return set === -1 ? null : set;
        }

        if (cur == null || !tr.docChanged) {
          return cur;
        }

        let { deleted, pos } = tr.mapping.mapResult(cur);
        return deleted ? null : pos;
      }
    },
    props: {
      handleTextInput(view, _from, _to, _text) {
        const { state } = view;
        const { selection } = state;

        if (!(selection instanceof CellSelection)) {
          return false;
        }
        return true;
      },

      decorations(state) {
        const modeState = editableKey.getState(state);
        if (modeState != null && !modeState.editable) {
          return DecorationSet.empty;
        }

        return drawCellSelection(state);
      },

      handleDOMEvents: {
        mousedown: handleMouseDown
      },

      createSelectionBetween(view) {
        if (key.getState(view.state) != null) {
          return view.state.selection;
        } else {
          return;
        }
      },

      handleTripleClick: handleTripleClick,

      handleKeyDown: handleKeyDown
    },
    appendTransaction(_, oldState, state) {
      return normalizeSelection(
        state,
        fixTables(state, oldState)!,
        allowTableNodeSelection
      );
    }
  });
}
