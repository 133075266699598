import { Schema } from "prosemirror-model";

export type CustomGridSchema = Schema<
  | "table"
  | "tableRow"
  | "tableHeaderRow"
  | "tableFooterRow"
  | "tableCell"
  | "tableCellWithoutInputs"
  | "tableInputCell"
  | "tableHeader"
  | "tableInputHeader"
  | "tableFooter"
  | "tableInputFooter",
  any
>;

export enum CustomGridWidth {
  default = 100,
  minimum = 25,
  maximum = 100
}

export interface CustomGridBorders {
  top: boolean;
  bottom: boolean;
  left: boolean;
  right: boolean;
  "inside-horizontal": boolean;
  "inside-vertical": boolean;
}
