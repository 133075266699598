import { Node, Schema } from "prosemirror-model";
import { EditorState, Transaction } from "prosemirror-state";
import {
  isInTable,
  selectedRect,
  TableMap,
  TableRect
} from "prosemirror-tables";
import { CELL_MIN_WIDTH, setAttr, zeroes } from "../../util";

function addColumn<S extends Schema>(
  tr: Transaction<S>,
  tableRect: TableRect,
  col: number
) {
  const { map, table, tableStart, right } = tableRect;
  const schema = table.type.schema as S;

  for (let row = 0; row < map.height; row++) {
    const posToInsertAt = map.positionAt(row, col, table);
    let currentPos = posToInsertAt;

    if (col === right) {
      currentPos = map.positionAt(row, col - 1, table);
    }

    const currentNode = table.nodeAt(currentPos)!;
    const { attrs } = currentNode;

    const index = attrs.colspan === 1 ? 0 : col - map.colCount(currentPos);
    const currentWidth = attrs.colwidth[index];

    const widthSplitted = Math.max(CELL_MIN_WIDTH, currentWidth / 2);

    const colwidth = attrs.colwidth
      ? attrs.colwidth.slice()
      : zeroes(attrs.colspan);
    colwidth[index] = widthSplitted;

    if (row === 0) {
      tr = tr.insert(
        tr.mapping.map(tableStart + posToInsertAt),
        schema.nodes.tableHeader.createAndFill({
          colwidth: [widthSplitted]
        })! as Node<S>
      );
    } else if (row === map.height - 1) {
      tr = tr.insert(
        tr.mapping.map(tableStart + posToInsertAt),
        schema.nodes.tableFooter.createAndFill({
          colwidth: [widthSplitted]
        })! as Node<S>
      );
    } else {
      tr = tr.insert(
        tr.mapping.map(tableStart + posToInsertAt),
        schema.nodes.tableCell.createAndFill({
          colwidth: [widthSplitted]
        })! as Node<S>
      );
    }

    tr = tr.setNodeMarkup(
      tr.mapping.map(tableStart + currentPos),
      undefined,
      setAttr(attrs, "colwidth", colwidth)
    );
  }

  return tr;
}

function removeColumn<S extends Schema>(
  tr: Transaction<S>,
  { map, table, tableStart }: TableRect,
  col: number
) {
  let mapStart = tr.mapping.maps.length;
  for (let row = 0; row < map.height; row++) {
    let index = row * map.width + col;
    let pos = map.map[index];
    let cell = table.nodeAt(pos);
    if (cell != null) {
      const toDeleteColspanIndex =
        cell.attrs.colspan === 1 ? 0 : col - map.colCount(pos);
      const toDeleteWidth = cell.attrs.colwidth[toDeleteColspanIndex];

      const siblingCellIndex = row * map.width + (col > 0 ? col - 1 : col + 1);
      const siblingCellPos = map.map[siblingCellIndex];
      const siblingCell = table.nodeAt(siblingCellPos);
      if (siblingCell != null) {
        const toCombineColspanIndex =
          siblingCell.attrs.colspan === 1 ? 0 : col - map.colCount(pos);
        const toCombineWidth =
          siblingCell.attrs.colwidth[toCombineColspanIndex];

        const combinedWidth = Math.min(100, toDeleteWidth + toCombineWidth);

        const colwidth = siblingCell.attrs.colwidth
          ? siblingCell.attrs.colwidth.slice()
          : zeroes(siblingCell.attrs.colspan);
        colwidth[toCombineColspanIndex] = combinedWidth;

        tr = tr.setNodeMarkup(
          tr.mapping.map(tableStart + siblingCellPos),
          undefined,
          setAttr(siblingCell.attrs, "colwidth", colwidth)
        );
      }

      let start = tr.mapping.slice(mapStart).map(tableStart + pos);
      tr = tr.delete(start, start + cell.nodeSize);
    }
  }
  return tr;
}

function addRow<S extends Schema>(
  tr: Transaction<S>,
  { map, table, tableStart }: TableRect,
  row: number
) {
  const schema = table.type.schema as S;

  let rowPos = tableStart;
  for (let i = 0; i < row; i++) {
    rowPos += table.child(i).nodeSize;
  }

  let cells = new Array<Node<S>>();

  for (let column = 0; column < map.width; column++) {
    cells.push(schema.nodes.tableCell.createAndFill()! as Node<S>);
  }

  tr = tr.insert(rowPos, schema.nodes.tableRow.create(null, cells) as Node<S>);

  return tr;
}

function removeRow<S extends Schema>(
  tr: Transaction<S>,
  { table, tableStart }: TableRect,
  row: number
) {
  let rowPos = 0;
  for (let i = 0; i < row; i++) {
    rowPos += table.child(i).nodeSize;
  }
  let nextRow = rowPos + table.child(row).nodeSize;

  tr = tr.delete(rowPos + tableStart, nextRow + tableStart);

  return tr;
}

export function addColumnBefore<S extends Schema>(
  state: EditorState<S>,
  dispatch?: (tr: Transaction<S>) => void
): boolean {
  if (!isInTable(state)) {
    return false;
  }

  if (dispatch) {
    let rect = selectedRect(state);
    dispatch(addColumn(state.tr, rect, rect.left));
  }
  return true;
}

export function addColumnAfter<S extends Schema>(
  state: EditorState<S>,
  dispatch?: (tr: Transaction<S>) => void
): boolean {
  if (!isInTable(state)) {
    return false;
  }

  if (dispatch) {
    let rect = selectedRect(state);
    dispatch(addColumn(state.tr, rect, rect.right));
  }
  return true;
}

export function deleteColumn<S extends Schema>(
  state: EditorState<S>,
  dispatch?: (tr: Transaction<S>) => void
): boolean {
  if (!isInTable(state)) {
    return false;
  }

  if (dispatch) {
    let rect = selectedRect(state);
    let tr = state.tr;

    if (rect.left === 0 && rect.right === rect.map.width) {
      deleteTable(state, dispatch);
      return true;
    }

    for (let i = rect.right - 1; ; i--) {
      removeColumn(tr, rect, i);

      if (i === rect.left) {
        break;
      }

      rect.table = rect.tableStart
        ? (tr.doc.nodeAt(rect.tableStart - 1) as Node<S>)
        : tr.doc;
      rect.map = TableMap.get(rect.table);
    }

    dispatch(tr);
  }

  return true;
}

export function addRowBefore<S extends Schema>(
  state: EditorState<S>,
  dispatch?: (tr: Transaction<S>) => void
): boolean {
  if (!isInTable(state)) {
    return false;
  }

  const rect = selectedRect(state);
  if (rect.top === 0) {
    return false;
  }

  if (dispatch) {
    dispatch(addRow(state.tr, rect, rect.top));
  }

  return true;
}

export function addRowAfter<S extends Schema>(
  state: EditorState<S>,
  dispatch?: (tr: Transaction<S>) => void
): boolean {
  if (!isInTable(state)) {
    return false;
  }

  const rect = selectedRect(state);
  if (rect.bottom === rect.map.height) {
    return false;
  }

  if (dispatch) {
    dispatch(addRow(state.tr, rect, rect.bottom));
  }

  return true;
}

export function deleteRow<S extends Schema>(
  state: EditorState<S>,
  dispatch?: (tr: Transaction<S>) => void
): boolean {
  if (!isInTable(state)) {
    return false;
  }

  const rect = selectedRect(state);
  if (rect.top === 0 || rect.bottom === rect.map.height) {
    return false;
  }

  if (rect.top === 0 && rect.bottom === rect.map.height) {
    deleteTable(state, dispatch);
    return true;
  }

  if (dispatch) {
    let tr = state.tr;
    if (rect.top === 0 && rect.bottom === rect.map.height) {
      return false;
    }

    for (let i = rect.bottom - 1; ; i--) {
      removeRow(tr, rect, i);

      if (i === rect.top) {
        break;
      }

      rect.table = rect.tableStart
        ? (tr.doc.nodeAt(rect.tableStart - 1) as Node<S>)
        : tr.doc;
      rect.map = TableMap.get(rect.table);
    }

    dispatch(tr);
  }

  return true;
}

export function deleteTable<S extends Schema>(
  state: EditorState<S>,
  dispatch?: (tr: Transaction<S>) => void
): boolean {
  let $pos = state.selection.$anchor;

  for (let d = $pos.depth; d > 0; d--) {
    let node = $pos.node(d);
    if (node.type.spec.tableRole === "table") {
      if (dispatch) {
        dispatch(
          state.tr.delete($pos.before(d), $pos.after(d)).scrollIntoView()
        );
      }

      return true;
    }
  }

  return false;
}
