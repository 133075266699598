import { Plugin, NodeSelection } from "prosemirror-state";

export function nodeSelection() {
  return new Plugin({
    props: {
      handleTextInput(view, _from, _to, _text) {
        const { state } = view;
        const { selection } = state;

        if (!(selection instanceof NodeSelection)) {
          return false;
        }

        return true;
      }
    }
  });
}
