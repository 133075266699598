import { Node, Schema } from "prosemirror-model";
import {
  EditorState,
  NodeSelection,
  Plugin,
  PluginKey,
  Transaction
} from "prosemirror-state";
import { Extension } from "../../editor/extension";
import {
  findChildren,
  findInputs,
  IdGenerator,
  NodeWithPos,
  UniqueIdGenerator
} from "../../util";

function isUnidentified<S extends Schema>(node: Node<S>): boolean {
  return node.type.spec.attrs?.id != null && node.attrs.id == null;
}

function isIdentified<S extends Schema>(node: Node<S>): boolean {
  return node.type.spec.attrs?.id != null && node.attrs.id != null;
}

function findDuplicateNodes<S extends Schema>(
  oldState: EditorState<S>,
  newState: EditorState<S>
): NodeWithPos<S>[] {
  const oldIdNodes = findChildren(oldState.doc, (node) => {
    return isIdentified(node);
  });

  const newIdNodes = findChildren(newState.doc, (node) => {
    return isIdentified(node);
  });

  const duplicatesMap = newIdNodes.reduce((acc, elem) => {
    const id = elem.node.attrs.id;
    if (acc.has(id)) {
      return acc.set(id, acc.get(id)!.concat(elem));
    } else {
      return acc.set(id, [elem]);
    }
  }, new Map<string, NodeWithPos<S>[]>());

  const duplicates = Array.from(duplicatesMap.values()).reduce(
    (acc, duplicates) => {
      if (duplicates.length > 1) {
        const toUpdate = duplicates.filter((duplicate) => {
          const oldNode = oldIdNodes.find(
            (x) =>
              x.node.attrs.id === duplicate.node.attrs.id &&
              x.pos === duplicate.pos
          );

          return oldNode == null;
        });
        return acc.concat(...toUpdate);
      } else {
        return acc;
      }
    },
    new Array<NodeWithPos<S>>()
  );

  return duplicates;
}

function findUnidentifiedNodes<S extends Schema>(
  newState: EditorState<S>
): NodeWithPos<S>[] {
  return findChildren(newState.doc, (node) => {
    return isUnidentified(node);
  });
}

function updateId<S extends Schema>(
  tr: Transaction<S>,
  node: Node<S>,
  pos: number,
  id: string
): Transaction<S> {
  const { selection } = tr;
  const nodeSelectionPos =
    selection instanceof NodeSelection ? selection.from : null;

  tr = tr.setNodeMarkup(
    pos,
    node.type,
    {
      ...node.attrs,
      id: id
    },
    node.marks
  );

  if (nodeSelectionPos != null) {
    tr = tr.setSelection(new NodeSelection(tr.doc.resolve(nodeSelectionPos)));
  }

  return tr;
}

const nodeIdentifierKey = new PluginKey<IdGenerator, any>("nodeIdentifier");

function nodeIdentifier<S extends Schema>(
  idGenerator: IdGenerator,
  predicate: (node: Node<S>) => boolean
) {
  return new Plugin<IdGenerator, S>({
    key: nodeIdentifierKey,
    state: {
      init() {
        return idGenerator;
      },
      apply() {
        return idGenerator;
      }
    },
    appendTransaction(transactions, oldState, newState) {
      if (!transactions.find((tr) => tr.docChanged)) {
        return;
      }

      if (transactions.length === 0) {
        return;
      }

      const transaction = transactions[transactions.length - 1];

      const duplicates = findDuplicateNodes(oldState, newState);
      const unidentified = findUnidentifiedNodes(newState);

      const updates = [...duplicates, ...unidentified];

      let ids = new Array<{ old: string; new: string }>();

      if (updates.length > 0) {
        let tr = newState.tr;
        updates.forEach(({ node, pos }) => {
          if (predicate(node)) {
            const newId = idGenerator.generateId();
            const shouldUpdateInputs = hasQuestionTitleRef(node, oldState);

            if (shouldUpdateInputs) {
              const inputs = findInputs(newState.doc, node);

              const updateInputs = inputs.filter((input) => {
                if (input.node.attrs.questionTitleRefElementId) {
                  return input.node.attrs.questionTitleRefElementId.includes(
                    node.attrs.id
                  );
                } else {
                  return false;
                }
              });

              updateInputs.forEach((input) => {
                const newQuestionTitleRefElementIds = input.node.attrs.questionTitleRefElementId.filter(
                  (id: string) => id !== node.attrs.id
                );
                newQuestionTitleRefElementIds.push(newId);
                tr = tr.setNodeMarkup(
                  input.pos,
                  input.node.type,
                  {
                    ...input.node.attrs,
                    questionTitleRefElementId: newQuestionTitleRefElementIds
                  },
                  input.node.marks
                );
              });
            }

            ids.push({ new: newId, old: node.attrs.id });
            tr = updateId(tr, node, pos, newId);
          }
        });

        if (transaction.storedMarks != null) {
          tr.setStoredMarks(transaction.storedMarks);
        }

        tr.setMeta("ids", ids);

        return tr;
      } else {
        return undefined;
      }
    }
  });
}

export function getIdGenerator<S extends Schema>(
  state: EditorState<S>
): IdGenerator {
  const idGenerator = nodeIdentifierKey.getState(state);
  if (idGenerator) {
    return idGenerator;
  } else {
    return new UniqueIdGenerator();
  }
}

export class NodeIdentifier implements Extension<Schema> {
  constructor(
    private idGenerator: IdGenerator = new UniqueIdGenerator(),
    private predicate: (node: Node<Schema>) => boolean = () => true
  ) {}

  get name(): string {
    return "nodeIdentifier";
  }

  plugins(): Plugin[] {
    return [nodeIdentifier(this.idGenerator, this.predicate)];
  }
}

function hasQuestionTitleRef<S extends Schema>(
  duplicateNode: Node,
  oldState: EditorState<S>
): any {
  const oldIdNodes = findChildren(oldState.doc, (node) => {
    return isIdentified(node);
  });

  const filteredIdNodes = oldIdNodes.filter((el) => {
    return el.node.attrs && el.node.attrs.id === duplicateNode.attrs.id;
  });

  return (
    filteredIdNodes.find((el) => {
      return el.node === duplicateNode;
    }) !== undefined
  );
}
