import { Plugin, PluginKey } from "prosemirror-state";
import { Decoration, DecorationSet, EditorView } from "prosemirror-view";
import { Editor } from "../../../editor";
import { scrollTrackingKey } from "../../../editor/plugins/scroll-tracking";
import { Focus } from "../../../editor/plugins/selection-focus";
import { scrollParent } from "../../../util";
import { VariableDefinition, VariableItem, VariableSchema } from "../schema";
import { focusedVariable } from "../util";
import { variablesKey } from "./variables-plugin";

type PluginState = {
  focused: Focus | undefined;
  opened: boolean;
  variables: VariableItem[];
  renderer: VariableDropdownRenderer;
};

export const variablePickerKey = new PluginKey<PluginState, VariableSchema>(
  "variablePickerPlugin"
);

export type VariableDropdownRenderer = (
  variables: VariableItem[],
  onSelect: (definition: VariableDefinition) => void
) => HTMLElement;

export function variablePickerPlugin(
  variables: VariableItem[],
  renderer: VariableDropdownRenderer
) {
  return new Plugin<PluginState, VariableSchema>({
    key: variablePickerKey,
    state: {
      init(_config, state) {
        const focused = focusedVariable(state);
        return {
          opened: false,
          focused: focused,
          variables: variables,
          renderer: renderer
        };
      },
      apply(tr, value, _oldState, newState) {
        const updateVariables = tr.getMeta(variablesKey) as
          | { variables: VariableItem[] }
          | undefined;

        if (updateVariables != null) {
          return { ...value, variables: updateVariables.variables };
        }

        const newFocused = focusedVariable(newState);
        if (!newFocused) {
          return { ...value, opened: false, focused: newFocused };
        } else {
          if (newFocused.node === value.focused?.node) {
            const action = tr.getMeta(variablePickerKey);
            if (action != null && action.setOpened != null) {
              const { opened } = action.setOpened as { opened: boolean };
              return {
                ...value,
                opened: opened
              };
            }

            if (newFocused.pos !== value.focused?.pos) {
              return {
                ...value,
                opened: false,
                focused: newFocused
              };
            } else {
              return value;
            }
          } else {
            return { ...value, opened: false, focused: newFocused };
          }
        }
      }
    },
    view(view) {
      const updateDropdownPosition = () => {
        const dropdown = view.dom.querySelector(".variable-picker-dropdown");
        if (dropdown == null) {
          return;
        }

        const toggle = dropdown.querySelector<HTMLElement>(
          ".variable-picker-dropdown-toggle"
        );
        const content = dropdown.querySelector<HTMLElement>(
          ".variable-picker-dropdown-content"
        );

        if (toggle == null || content == null) {
          return;
        }

        const { vertical, horizontal } = positionDropdown(toggle, content);
        dropdown.setAttribute(
          "data-dropdown-position",
          `${vertical}-${horizontal}`
        );
      };

      const scrollTracking = scrollTrackingKey.getState(view.state);
      scrollTracking?.subscribe(updateDropdownPosition);

      return {
        update: (_view) => {
          updateDropdownPosition();
        },
        destroy: () => {
          scrollTracking?.unsubscribe(updateDropdownPosition);
        }
      };
    },
    props: {
      decorations(state) {
        const { opened, focused, variables, renderer } = this.getState(state);
        if (focused == null) {
          return null;
        }

        const { doc, schema } = state;
        const { pos, node } = focused;

        if (node.type === schema.nodes.questionVariable) {
          return DecorationSet.create(doc, [
            Decoration.widget(
              pos,
              dropdown(false, opened, renderer, variables),
              {
                marks: [],
                stopEvent: () => {
                  return true;
                },
                ignoreSelection: true
              }
            )
          ]);
        } else {
          return DecorationSet.create(doc, [
            Decoration.widget(
              pos + node.nodeSize,
              dropdown(true, opened, renderer, variables),
              {
                marks: [],
                stopEvent: () => {
                  return true;
                },
                ignoreSelection: true
              }
            )
          ]);
        }
      }
    }
  });
}

function dropdown(
  inline: boolean,
  opened: boolean,
  render: VariableDropdownRenderer,
  variables: VariableItem[]
) {
  return (view: EditorView<VariableSchema>, getPos: () => number) => {
    const onSelect = (definition: VariableDefinition) => {
      const editor = view as Editor<VariableSchema>;
      editor.commands.updateVariable.execute({ definition: definition });
    };

    const container = document.createElement("div");
    container.classList.add("variable-picker-dropdown");
    container.classList.toggle("opened", opened);

    if (!inline) {
      const domNode = view.nodeDOM(getPos()) as HTMLElement | null | undefined;
      if (domNode != null) {
        const rect = domNode.getBoundingClientRect();
        const left = domNode.offsetLeft + rect.width;
        const top = domNode.offsetTop;

        container.style.left = `${left}px`;
        container.style.top = `${top}px`;
      }
    }

    const toggle = dropdownToggle();
    toggle.onmousedown = (event) => {
      event.preventDefault();
    };

    toggle.onclick = (_event) => {
      view.dispatch(
        view.state.tr.setMeta(variablePickerKey, {
          setOpened: { opened: !opened }
        })
      );
    };

    container.appendChild(toggle);

    if (opened) {
      const content = dropdownContent(render, variables, onSelect);
      container.appendChild(content);
    }

    return container;
  };
}

function dropdownToggle() {
  const container = document.createElement("button");
  container.className = "variable-picker-dropdown-toggle";

  const triangle = document.createElement("span");
  triangle.className = "triangle";

  container.appendChild(triangle);

  return container;
}

function dropdownContent(
  render: VariableDropdownRenderer,
  variables: VariableItem[],
  onSelect: (definition: VariableDefinition) => void
) {
  const container = render(variables, onSelect);
  container.classList.add("variable-picker-dropdown-content");
  return container;
}

function positionDropdown(
  dom: HTMLElement,
  contentDOM: HTMLElement
): { vertical: "top" | "bottom"; horizontal: "left" | "right" } {
  contentDOM.style.zIndex = "-1";

  const scrollingContainer = scrollParent(dom);

  const scrollingContainerRect = scrollingContainer.getBoundingClientRect();
  const domToggleRect = dom.getBoundingClientRect();
  const dropdownContentRect = contentDOM.getBoundingClientRect();

  const vertical = calculateVerticalPosition(
    scrollingContainerRect,
    domToggleRect,
    dropdownContentRect
  );

  const horizontal = calculateHorizontalPosition(
    scrollingContainerRect,
    domToggleRect,
    dropdownContentRect
  );

  contentDOM.style.zIndex = "";

  return { vertical: vertical, horizontal: horizontal };
}

function calculateVerticalPosition(
  scrollingContainerRect: DOMRect,
  domToggleRect: DOMRect,
  dropdownContentRect: DOMRect
): "top" | "bottom" {
  const scrollingContainerHeight = scrollingContainerRect.height;

  const dropdownOffset = 4;

  const scrollOffset =
    domToggleRect.bottom +
    dropdownOffset +
    dropdownContentRect.height -
    scrollingContainerRect.top;

  if (scrollOffset >= scrollingContainerHeight) {
    return "top";
  } else {
    return "bottom";
  }
}

function calculateHorizontalPosition(
  scrollingContainerRect: DOMRect,
  domToggleRect: DOMRect,
  dropdownContentRect: DOMRect
): "right" | "left" {
  const scrollingContainerWidth = scrollingContainerRect.width;

  const dropdownOffset = 0;

  const scrollOffset =
    domToggleRect.left +
    dropdownOffset +
    dropdownContentRect.width -
    scrollingContainerRect.left;

  if (scrollOffset >= scrollingContainerWidth) {
    return "left";
  } else {
    return "right";
  }
}
