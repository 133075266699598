import { Node, Schema } from "prosemirror-model";
import { Plugin } from "prosemirror-state";
import { Decoration, EditorView, NodeView } from "prosemirror-view";
import { Editor } from "../../editor";
import {
  ActionControlButton,
  ActionControls
} from "../../editor/plugins/action-controls";
import { selectionFocusKey } from "../../editor/plugins/selection-focus";
import { ValidationMessagesNodeView } from "../../editor/plugins/validation-messages";
import { WidthResizerNodeView } from "../../editor/plugins/width-resizer";
import { UnreachableCaseError } from "../../util";
import { AlignmentType } from "../alignment";
import { GetTranslationFn, LanguageObserver } from "../localization";
import { QuestionTitleBindingButton } from "../question-title";
import { InputFileFormat } from "./schema";

export function nodeViewPlugin() {
  return new Plugin({
    props: {
      nodeViews: {
        inputFile: (node, view, getPos, decorations) => {
          return new InputFileNodeView(
            node,
            view,
            getPos as () => number,
            decorations
          );
        }
      }
    }
  });
}

class InputFileNodeView<S extends Schema> implements NodeView<S> {
  dom: HTMLElement;

  private languageObserver: LanguageObserver<S>;

  private inputContainer: HTMLElement;
  private resizer: WidthResizerNodeView<S>;
  private validationMessages: ValidationMessagesNodeView;
  private actionControls: ActionControls;
  private requiredButton: ActionControlButton;
  private questionTitleBindingButton: QuestionTitleBindingButton<S>;

  constructor(
    private node: Node<S>,
    view: EditorView<S>,
    getPos: () => number,
    decorations: Decoration[]
  ) {
    this.languageObserver = new LanguageObserver(view, (getTranslation) => {
      this.updateText(
        this.node.attrs.format,
        this.node.attrs.maxFiles,
        this.node.attrs.maxSize,
        getTranslation
      );
      this.updateRequired(this.node.attrs.required, getTranslation);
    });

    const container = document.createElement("input-file");

    this.resizer = new WidthResizerNodeView(
      container,
      node,
      view,
      getPos,
      25,
      100,
      (width) => {
        const editor = view as Editor<S>;
        editor.commands.updateInputFile.execute({
          width: width
        });
      }
    );

    this.validationMessages = new ValidationMessagesNodeView();

    const innerContainer = document.createElement("div");
    innerContainer.className = "input-container";

    const requiredButton = new ActionControlButton(
      view,
      { icon: "asterisk", title: "ACTION_BUTTONS.REQUIRED.TITLE" },
      false,
      () => {
        const editor = view as Editor<S>;
        const focused = selectionFocusKey.getState(editor.state);

        if (focused != null && focused.node.type === this.node.type) {
          const { node } = focused;
          const required = !node.attrs.required;
          editor.commands.updateInputFile.execute({ required: required });
        }
      }
    );

    const questionTitleBindingButton = new QuestionTitleBindingButton(
      view,
      node,
      decorations
    );

    const actionControls = new ActionControls([
      requiredButton,
      questionTitleBindingButton
    ]);

    container.appendChild(this.validationMessages.dom);
    container.appendChild(innerContainer);
    container.appendChild(actionControls.dom);

    this.dom = container;
    this.inputContainer = innerContainer;
    this.actionControls = actionControls;
    this.requiredButton = requiredButton;
    this.questionTitleBindingButton = questionTitleBindingButton;

    const getTranslation = this.languageObserver.getTranslation;

    this.updateId(node);
    this.updateText(
      node.attrs.format,
      node.attrs.maxFiles,
      node.attrs.maxSize,
      getTranslation
    );
    this.updateRequired(node.attrs.required, getTranslation);
    this.updateQuestionTitle(node, decorations);
    this.updateAlignment(node.attrs.alignment);
  }

  ignoreMutation(
    mutation:
      | MutationRecord
      | {
          type: "selection";
          target: Element;
        }
  ): boolean {
    const resizeIgnoreMutation = this.resizer.ignoreMutation(mutation);
    const validationMessagesIgnoreMutation = this.validationMessages.ignoreMutation(
      mutation
    );
    const requiredButtonIgnoreMutation = this.requiredButton.ignoreMutation(
      mutation
    );
    const questionTitleBindingButtonIgnoreMutation = this.questionTitleBindingButton.ignoreMutation(
      mutation
    );

    return [
      resizeIgnoreMutation,
      validationMessagesIgnoreMutation,
      requiredButtonIgnoreMutation,
      questionTitleBindingButtonIgnoreMutation
    ].some((x) => x);
  }

  update(node: Node<S>, decorations: Decoration[]): boolean {
    if (node.type !== this.node.type) {
      return false;
    }

    this.node = node;

    const getTranslation = this.languageObserver.getTranslation;

    this.resizer.update(node);

    this.updateId(node);
    this.updateText(
      node.attrs.format,
      node.attrs.maxFiles,
      node.attrs.maxSize,
      getTranslation
    );
    this.updateRequired(node.attrs.required, getTranslation);
    this.updateQuestionTitle(node, decorations);
    this.updateAlignment(node.attrs.alignment);

    return true;
  }

  destroy() {
    this.languageObserver.destroy();
    this.actionControls.destroy();
  }

  private updateId(node: Node<S>) {
    this.dom.id = node.attrs.id;
  }

  private updateText(
    format: InputFileFormat,
    maxFiles: number,
    maxSize: number,
    getTranslation: GetTranslationFn
  ): void {
    const text = [
      instructionText(format, maxFiles, getTranslation),
      maxFileSizeText(maxSize, getTranslation)
    ]
      .filter((s) => s.trim().length > 0)
      .join(" ");

    this.inputContainer.textContent = text;
  }

  private updateRequired(
    required: boolean,
    getTranslation: GetTranslationFn
  ): void {
    this.validationMessages.setRequired(required, false, getTranslation);
    this.requiredButton.setActive(required);
  }

  private updateQuestionTitle(node: Node<S>, decorations: Decoration[]): void {
    this.questionTitleBindingButton.update(node, decorations);
  }

  private updateAlignment(alignment: AlignmentType): void {
    if (alignment != null) {
      this.dom.setAttribute("data-alignment", alignment);
    } else {
      this.dom.removeAttribute("data-alignment");
    }
  }
}

function instructionText(
  format: InputFileFormat,
  maxFiles: number,
  getTranslation: GetTranslationFn
): string {
  let key: string;
  switch (format) {
    case InputFileFormat.document:
      key =
        maxFiles === 1
          ? "INPUT_FILE.FILE_INSTRUCTION_SINGULAR"
          : "INPUT_FILE.FILE_INSTRUCTION_PLURAL";
      break;
    case InputFileFormat.image:
      key =
        maxFiles === 1
          ? "INPUT_FILE.IMAGE_INSTRUCTION_SINGULAR"
          : "INPUT_FILE.IMAGE_INSTRUCTION_PLURAL";
      break;
    case InputFileFormat.sound:
      key =
        maxFiles === 1
          ? "INPUT_FILE.AUDIO_INSTRUCTION_SINGULAR"
          : "INPUT_FILE.AUDIO_INSTRUCTION_PLURAL";
      break;
    case InputFileFormat.video:
      key =
        maxFiles === 1
          ? "INPUT_FILE.VIDEO_INSTRUCTION_SINGULAR"
          : "INPUT_FILE.VIDEO_INSTRUCTION_PLURAL";
      break;
    default:
      throw new UnreachableCaseError(format);
  }

  return getTranslation(key);
}

function maxFileSizeText(
  maxSize: number,
  getTranslation: GetTranslationFn
): string {
  if (maxSize < 0) {
    const fileSize = getTranslation("INPUT_FILE.INVALID_VALUE");
    return getTranslation("INPUT_FILE.MAX_FILE_SIZE", { fileSize: fileSize });
  } else if (maxSize > 0) {
    const fileSize = formatBytes(maxSize * 1048576, getTranslation);
    return getTranslation("INPUT_FILE.MAX_FILE_SIZE", { fileSize: fileSize });
  } else {
    return "";
  }
}

function formatBytes(bytes: number, getTranslation: GetTranslationFn): string {
  let value: number;
  let size: string;

  if (bytes < 0) {
    return getTranslation("INPUT_FILE.INVALID_VALUE");
  }

  if (bytes === 0 || !isFinite(bytes)) {
    value = 0;
    size = "Byte";
  } else {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    value = bytes / Math.pow(1024, i);

    if (i >= sizes.length) {
      size = sizes[sizes.length - 1];
    } else {
      size = sizes[i];
    }
  }

  const formattedNumber = value.toFixed(1);

  return `${formattedNumber} ${size}`;
}
