import {
  Editor as BlueXEditor,
  EnableNextButton,
  EnablePreviousButton,
  PageBreakActiveValue,
  Schema
} from "@blue-x/editor";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./PageBreak.css";

interface PagePropertiesPanelProps {
  editor: BlueXEditor<Schema>;
}

export const PageBreakPropertiesPanel: React.FC<PagePropertiesPanelProps> = (
  props
) => {
  const { editor } = props;
  const { commands } = editor;

  const activeValue: PageBreakActiveValue = useMemo(() => {
    return commands.updatePageBreak.activeValue();
  }, [commands]);

  const [enablePrevious, setEnablePrevious] = useState<EnablePreviousButton>(
    "always"
  );
  const [enableNext, setEnableNext] = useState<EnableNextButton>("always");
  const [enableNextMinimum, setEnableNextMinimum] = useState<number | "">("");

  useEffect(() => {
    setEnablePrevious(activeValue.enablePrevious);
    setEnableNext(activeValue.enableNext);
    setEnableNextMinimum(
      activeValue.enableNextMinimum == null ? "" : activeValue.enableNextMinimum
    );
  }, [activeValue, setEnablePrevious, setEnableNext, setEnableNextMinimum]);

  const updateEnablePreviousCommand = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value;
      commands.updatePageBreak.execute({ enablePrevious: value }, false);
    },
    [commands]
  );

  const updateEnableNextCommand = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value;
      commands.updatePageBreak.execute({ enableNext: value }, false);
    },
    [commands]
  );

  const updateEnableNextMinimum = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.valueAsNumber;
      if (!isNaN(value)) {
        setEnableNextMinimum(Math.round(value));
      } else {
        setEnableNextMinimum("");
      }
    },
    [setEnableNextMinimum]
  );

  const updateEnableNextMinimumCommand = useCallback(() => {
    commands.updatePageBreak.execute(
      {
        enableNextMinimum:
          enableNextMinimum === "" || enableNextMinimum < 0
            ? null
            : enableNextMinimum
      },
      false
    );
  }, [commands, enableNextMinimum]);

  const showEnableNextMinimum = useMemo(() => {
    return enableNext === "minimum-answered";
  }, [enableNext]);

  const applyAll = useCallback(() => {
    commands.bulkUpdatePageBreak.execute(undefined, false);
  }, [commands]);

  return (
    <div className="PageBreakPropertiesPanel">
      <label>Allow respondent to navigate to previous page</label>
      <select value={enablePrevious} onChange={updateEnablePreviousCommand}>
        <option value="always">Always</option>
        <option value="disabled">Not allowed but show the button</option>
        <option value="hidden">Not allowed and hide the button</option>
      </select>

      <label>Allow respondent to navigate to next page</label>
      <select value={enableNext} onChange={updateEnableNextCommand}>
        <option value="always">Always</option>
        <option value="required-answered">
          Only if all questions on the page are answered
        </option>
        <option value="all-answered">
          Only if all the answers on the page are valid
        </option>
        <option value="minimum-answered">
          Only if minimum questions on the page are answered
        </option>
      </select>

      {showEnableNextMinimum ? (
        <>
          <label>Minimum amount of questions answered</label>
          <input
            type="number"
            min="0"
            value={enableNextMinimum}
            onChange={updateEnableNextMinimum}
            onBlur={updateEnableNextMinimumCommand}
          />
        </>
      ) : null}

      <button onClick={applyAll}>Apply to all page breaks</button>
    </div>
  );
};
