import { Node, Schema } from "prosemirror-model";
import { EditorView } from "prosemirror-view";
import { findParent, limit, widthAsPercent } from "../../../util";

export class WidthResizerNodeView<S extends Schema> {
  private dom: HTMLElement;
  private handle: HTMLElement;

  constructor(
    element: HTMLElement,
    node: Node<S>,
    view: EditorView<S>,
    getPos: () => number,
    minWidth: number,
    maxWidth: number,
    onChange: (width: number) => void,
    inline: boolean = false
  ) {
    const container = element;
    container.classList.add("ProseMirror-width-resizer");
    if (inline === true) {
      container.classList.add("ProseMirror-width-resizer-inline");
    }
    container.style.minWidth = `${minWidth}%`;
    container.style.maxWidth = `${maxWidth}%`;

    const handle = createResizeHandle(
      view,
      container,
      getPos,
      minWidth,
      maxWidth,
      onChange
    );

    container.appendChild(handle);

    this.dom = container;
    this.handle = handle;

    this.setWidth(node);
  }

  ignoreMutation(
    mutation:
      | MutationRecord
      | {
          type: "selection";
          target: Element;
        }
  ): boolean {
    if (mutation.type === "attributes" && mutation.target === this.handle) {
      return true;
    }

    if (
      mutation.type === "attributes" &&
      mutation.attributeName === "style" &&
      mutation.target === this.dom
    ) {
      return true;
    }

    return false;
  }

  update(node: Node<S>): void {
    this.setWidth(node);
  }

  updateMinWidth(minWidth: number): void {
    this.setMinWidth(minWidth);
  }

  private setWidth(node: Node<S>): void {
    const width = node.attrs.width;
    this.dom.style.width = width == null ? "" : `${width}%`;
  }

  private setMinWidth(minWidth: number): void {
    this.dom.style.minWidth = `${minWidth}%`;
  }
}

function measureWidthWithoutPadding(style: CSSStyleDeclaration): number {
  const paddingLeft = parseFloat(style.paddingLeft);
  const paddingRight = parseFloat(style.paddingRight);
  const width = parseFloat(style.width);

  return width - paddingLeft - paddingRight;
}

function createResizeHandle<S extends Schema>(
  view: EditorView<S>,
  container: HTMLElement,
  getPos: () => number,
  minWidth: number,
  maxWidth: number,
  update: (width: number) => void
): HTMLElement {
  const addEventHandlers = (handle: HTMLElement) => {
    handle.onmousedown = function (e) {
      e.preventDefault();
      view.focus();

      if (!view.editable) {
        return;
      }

      handle.classList.add("active");

      const $pos = view.state.doc.resolve(getPos());
      const parent = findParent($pos, () => true);
      const parentElement =
        parent != null
          ? (view.nodeDOM(parent.pos) as HTMLElement | null)
          : view.dom;

      if (parentElement == null) {
        return;
      }

      const parentWidth = measureWidthWithoutPadding(
        window.getComputedStyle(parentElement)
      );
      const containerWidth = measureWidthWithoutPadding(
        window.getComputedStyle(container)
      );

      const startX = e.pageX;

      const onMouseMove = (e: MouseEvent) => {
        const currentX = e.pageX;

        const diffInPx = currentX - startX;
        const diffInPercent = widthAsPercent(
          containerWidth + diffInPx,
          parentWidth
        );

        const width = limit(diffInPercent, minWidth, maxWidth);
        handle.setAttribute("data-width", `${width}`);
        container.style.width = `${width}%`;
      };

      const onMouseUp = (e: MouseEvent) => {
        e.preventDefault();
        handle.classList.remove("active");

        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);

        const width = handle.getAttribute("data-width");

        if (width != null) {
          update(parseInt(width));
        }
      };

      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    };

    return handle;
  };

  const handle = addEventHandlers(
    document.createElement("width-resizer-handle")
  );

  return handle;
}
