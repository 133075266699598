import { Fragment, Node } from "prosemirror-model";
import { Plugin, PluginKey } from "prosemirror-state";
import { getTranslation } from "../../localization";
import { PageBreakSchema } from "../schema";
import { createPageBreak } from "../util";

const pageBreakPluginKey = new PluginKey<boolean, PageBreakSchema>(
  "autoClosingPageBreak"
);

export function autoClosingPageBreak() {
  return new Plugin<boolean, PageBreakSchema>({
    key: pageBreakPluginKey,
    appendTransaction(transactions, _, state) {
      if (!transactions.find((tr) => tr.docChanged)) {
        return;
      }

      const insertNodeAtEnd = pageBreakPluginKey.getState(state);
      if (!insertNodeAtEnd) {
        return;
      }

      const { doc, tr, schema } = state;
      const pageBreak = createPageBreak(schema, getTranslation(state));
      const paragraph = schema.nodes.paragraph.create();

      return tr.insert(doc.content.size, Fragment.from([pageBreak, paragraph]));
    },
    state: {
      init() {
        return false;
      },
      apply(tr, value, _, state) {
        if (!tr.docChanged) {
          return value;
        }

        const { schema } = state;
        const { doc } = tr;
        const { childCount } = doc;

        if (childCount === 0) {
          return false;
        }

        const supportedNodes = [schema.nodes.headings, schema.nodes.paragraph];

        if (
          childCount === 1 &&
          doc.lastChild &&
          supportedNodes.includes(doc.lastChild.type) &&
          doc.lastChild.childCount === 0
        ) {
          return false;
        }

        let childIndex = childCount - 1;
        let node: Node<PageBreakSchema> | null = null;

        while (childIndex >= 0) {
          node = doc.child(childIndex);
          if (supportedNodes.includes(node.type) && node.childCount === 0) {
            childIndex = childIndex - 1;
          } else {
            break;
          }
        }

        if (node && node.type !== schema.nodes.pageBreak) {
          return true;
        } else {
          return false;
        }
      }
    }
  });
}
