import { NodeDTO } from "@blue-x/editor";

export function toText(elt: NodeDTO): string {
  let text = "";

  if (elt.type === "text") {
    text = [text, elt.text].join("");
  } else if (elt.type === "image") {
    text = [text, "<image>"].join("");
  } else if (elt.type === "video") {
    text = [text, "<video>"].join("");
  } else {
    if (elt.content != null) {
      text = [text, ...elt.content.map((c) => toText(c))].join("");
    }
  }

  return text;
}
