import { NodeSelection, Plugin } from "prosemirror-state";
import { Decoration, DecorationAttrs, DecorationSet } from "prosemirror-view";
import { editableKey } from "../../../editor/plugins/editable";
import { findChildrenByType } from "../../../util/nodes";
import { PageBreakNodeView } from "./page-break-node-view";
import { PageBreakPageIndicatorSpec } from "./types";

export function nodeViewPlugin() {
  return new Plugin({
    props: {
      nodeViews: {
        pageBreak: (node, view, getPos, decorations) => {
          return new PageBreakNodeView(
            node,
            view,
            getPos as () => number,
            decorations
          );
        }
      },
      decorations: (state) => {
        const { doc, schema } = state;
        const pageBreaks = findChildrenByType(doc, schema.nodes.pageBreak);
        const pageCount = pageBreaks.length;

        const pageBreakDecorations = pageBreaks.map(({ pos, node }, index) => {
          const pageBreakPageIndicatorSpec: PageBreakPageIndicatorSpec = {
            type: "pageBreakProperties",
            index: index + 1,
            total: pageCount
          };

          const from = pos;
          const to = pos + node.nodeSize;

          const attrs: DecorationAttrs = {};

          if (pageCount === 1) {
            attrs.class = "only";
          } else if (index === 0) {
            attrs.class = "first";
          } else if (index + 1 === pageCount) {
            attrs.class = "last";
          }

          return Decoration.node(from, to, attrs, pageBreakPageIndicatorSpec);
        });

        return DecorationSet.create(doc, pageBreakDecorations);
      },
      handleClickOn(view, _pos, node, nodePos, _event, direct) {
        const { state } = view;

        const editable = editableKey.getState(state);
        if (editable && editable.focusable === false) {
          return false;
        }

        const { schema } = state;
        if (
          node.type.spec.selectable &&
          direct &&
          node.type === schema.nodes.pageBreak
        ) {
          const tr = state.tr.setSelection(
            new NodeSelection(state.doc.resolve(nodePos))
          );
          view.dispatch(tr);
          return true;
        } else {
          return false;
        }
      }
    }
  });
}
