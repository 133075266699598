import { Schema } from "prosemirror-model";
import {
  EditorState,
  NodeSelection,
  Plugin,
  PluginKey,
  Selection
} from "prosemirror-state";
import { CellSelection, selectedRect, TableMap } from "prosemirror-tables";
import { Decoration, DecorationSet } from "prosemirror-view";
import { CommandFn } from "../../../../editor";
import { editableKey } from "../../../../editor/plugins/editable";
import { NodeWithPos } from "../../../../util";
import { getTranslation } from "../../../localization";
import { CustomGridSchema } from "../../schema";
import { focusedCustomGrid } from "../../util";
import {
  addColumnAfter,
  addColumnBefore,
  addRowAfter,
  addRowBefore,
  deleteColumn,
  deleteRow
} from "./commands";

interface TableControls {
  controls:
    | false
    | { pos: number; opened: boolean; direction: "row" | "column" };
}

const key = new PluginKey<TableControls>("tableControls");

const ROW_CONTROLS_CLASS = "ProseMirror-table-row-control";
const COLUMN_CONTROLS_CLASS = "ProseMirror-table-column-control";
const TABLE_CONTROLS_CLASS = "ProseMirror-table-control";
const ROW_ACTIONS_CLASS = "ProseMirror-table-row-actions";
const COLUMN_ACTIONS_CLASS = "ProseMirror-table-column-actions";
const DROPDOWN_TOGGLE_CLASS = "ProseMirror-dropdown-toggle";
const DROPDOWN_CONTENT_CLASS = "ProseMirror-dropdown-content";
const DROPDOWN_ITEM_CLASS = "ProseMirror-dropdown-item";

export function tableControls() {
  const showRowControl = (state: EditorState) => {
    const { selection } = state;
    return (
      selection instanceof CellSelection &&
      selection.isRowSelection() &&
      !selection.isColSelection()
    );
  };

  const rowControlPos = (state: EditorState) => {
    const { selection } = state;

    if (selection instanceof CellSelection) {
      const { map, table, tableStart, bottom, right } = selectedRect(state);
      return map.positionAt(bottom - 1, right - 1, table) + tableStart;
    } else {
      return null;
    }
  };

  const showColumnControl = (state: EditorState) => {
    const { selection } = state;
    if (selection instanceof CellSelection) {
      return selection.isColSelection();
    } else {
      return false;
    }
  };

  const columnControlPos = (state: EditorState) => {
    const { selection } = state;
    if (selection instanceof CellSelection) {
      const { map, table, tableStart, top, right } = selectedRect(state);
      const showHeader = table.attrs.showHeader;
      const header = showHeader ? top : top + 1;
      return map.positionAt(header, right - 1, table) + tableStart;
    } else {
      return null;
    }
  };

  const setOpened = <S extends Schema>(opened: boolean): CommandFn<S> => {
    return (state, dispatch) => {
      if (dispatch) {
        let tr = state.tr;
        tr = tr.setMeta(key, {
          setOpened: { opened: !opened }
        });

        dispatch(tr);
      }
      return true;
    };
  };

  return new Plugin<TableControls>({
    key: key,
    state: {
      init(_config, state) {
        const modeState = editableKey.getState(state);
        if (modeState != null && !modeState.editable) {
          return {
            controls: false
          };
        }

        const newShowRowControl = showRowControl(state);
        const newRowControlPos = rowControlPos(state);

        if (newShowRowControl === true && newRowControlPos != null) {
          return {
            controls: {
              opened: false,
              direction: "row",
              pos: newRowControlPos + 1
            }
          };
        }

        const newShowColumnControl = showColumnControl(state);
        const newColumnControlPos = columnControlPos(state);

        if (newShowColumnControl === true && newColumnControlPos != null) {
          return {
            controls: {
              opened: false,
              direction: "column",
              pos: newColumnControlPos + 1
            }
          };
        }

        return {
          controls: false
        };
      },
      apply(tr, value, oldState, newState) {
        const modeState = editableKey.getState(newState);
        if (modeState != null && !modeState.editable) {
          return {
            controls: false
          };
        }

        const oldShowRowControl = showRowControl(oldState);
        const oldRowControlPos = rowControlPos(oldState);

        const newShowRowControl = showRowControl(newState);
        const newRowControlPos = rowControlPos(newState);

        if (newShowRowControl === true && newRowControlPos != null) {
          if (oldShowRowControl === true) {
            if (oldRowControlPos !== newRowControlPos) {
              return {
                controls: {
                  opened: false,
                  direction: "row",
                  pos: newRowControlPos + 1
                }
              };
            } else {
              const action = tr.getMeta(key);
              if (action != null && action.setOpened != null) {
                const { opened } = action.setOpened as { opened: boolean };
                return {
                  controls: {
                    opened: opened,
                    direction: "row",
                    pos: newRowControlPos + 1
                  }
                };
              } else {
                return {
                  controls: {
                    opened:
                      value.controls === false ? false : value.controls.opened,
                    direction: "row",
                    pos: newRowControlPos + 1
                  }
                };
              }
            }
          } else {
            return {
              controls: {
                opened: false,
                direction: "row",
                pos: newRowControlPos + 1
              }
            };
          }
        }

        const oldShowColumnControl = showColumnControl(oldState);
        const oldColumnControlPos = columnControlPos(oldState);

        const newShowColumnControl = showColumnControl(newState);
        const newColumnControlPos = columnControlPos(newState);

        if (newShowColumnControl === true && newColumnControlPos != null) {
          if (oldShowColumnControl === true) {
            if (oldColumnControlPos !== newColumnControlPos) {
              return {
                controls: {
                  opened: false,
                  direction: "column",
                  pos: newColumnControlPos + 1
                }
              };
            } else {
              const action = tr.getMeta(key);
              if (action != null && action.setOpened != null) {
                const { opened } = action.setOpened as { opened: boolean };
                return {
                  controls: {
                    opened: opened,
                    direction: "column",
                    pos: newColumnControlPos + 1
                  }
                };
              } else {
                return {
                  controls: {
                    opened:
                      value.controls === false ? false : value.controls.opened,
                    direction: "column",
                    pos: newColumnControlPos + 1
                  }
                };
              }
            }
          } else {
            return {
              controls: {
                opened: false,
                direction: "column",
                pos: newColumnControlPos + 1
              }
            };
          }
        }

        return {
          controls: false
        };
      }
    },
    props: {
      decorations(state) {
        const modeState = editableKey.getState(state);
        if (modeState != null && !modeState.editable) {
          return DecorationSet.empty;
        }

        const translation = getTranslation(state);
        const focused = focusedCustomGrid(state);
        if (focused == null) {
          return DecorationSet.empty;
        }

        const { selection } = state;
        const { node: table, pos } = focused;

        const showHeader = table.attrs.showHeader;
        const map = TableMap.get(table);
        const tableControls = getCellsWithTableControls(map, showHeader);
        const columnControls = getCellsWithColumnControls(map, showHeader);
        const rowControls = getCellsWithRowControls(map);

        let selectedCells = new Array<NodeWithPos<CustomGridSchema>>();
        if (selection instanceof CellSelection) {
          selection.forEachCell((node, pos) => {
            selectedCells.push({ node: node, pos: pos });
          });
        }

        const start = pos + 1;

        const tableDecorations = tableControls.map((cellPos) => {
          const decorationPos = start + cellPos + 1;
          return Decoration.widget(decorationPos, () => {
            const elt = document.createElement("div");
            elt.classList.add(TABLE_CONTROLS_CLASS);
            elt.setAttribute("data-table-pos", `${pos}`);

            if (
              selection instanceof NodeSelection &&
              selection.node === table
            ) {
              elt.classList.add("active");
            }
            return elt;
          });
        });

        const columnDecorations = columnControls.map((cellPos, index) => {
          const decorationPos = start + cellPos + 1;
          let isInsideSelection = false;
          if (
            selection instanceof CellSelection &&
            selection.isColSelection()
          ) {
            const found = selectedCells.find((c) => c.pos === start + cellPos);
            isInsideSelection = found != null;
          }

          return Decoration.widget(decorationPos, () => {
            const elt = document.createElement("div");
            elt.classList.add(COLUMN_CONTROLS_CLASS);
            elt.setAttribute("data-column-index", `${index}`);
            elt.setAttribute("data-table-pos", `${pos}`);

            if (isInsideSelection) {
              elt.classList.add("active");
            }

            return elt;
          });
        });

        const rowDecorations = rowControls.map((cellPos, index) => {
          const decorationPos = start + cellPos + 1;
          let isInsideSelection = false;
          if (
            selection instanceof CellSelection &&
            selection.isRowSelection()
          ) {
            const found = selectedCells.find((c) => c.pos === start + cellPos);
            isInsideSelection = found != null;
          }

          return Decoration.widget(decorationPos, () => {
            const elt = document.createElement("div");
            elt.classList.add(ROW_CONTROLS_CLASS);
            elt.setAttribute("data-row-index", `${index}`);
            elt.setAttribute("data-table-pos", `${pos}`);

            if (isInsideSelection) {
              elt.classList.add("active");
            }

            return elt;
          });
        });

        const controlDecorations = new Array<Decoration>();
        const value = this.getState(state);

        if (value.controls && value.controls.direction === "row") {
          const { pos, opened } = value.controls;
          const rowControl = Decoration.widget(pos, () => {
            const dropdown = document.createElement("div");
            dropdown.className = ROW_ACTIONS_CLASS;

            const dropdownToggle = document.createElement("button");
            dropdownToggle.className = DROPDOWN_TOGGLE_CLASS;

            const triangle = document.createElement("span");
            triangle.className = "triangle";

            dropdownToggle.appendChild(triangle);

            const dropdownContent = document.createElement("div");
            dropdownContent.className = DROPDOWN_CONTENT_CLASS;

            if (opened === false) {
              dropdownContent.style.display = "none";
            }

            [
              {
                label: translation("CUSTOM_GRID.ROW_ACTIONS.ADD_BEFORE"),
                action: "addRowBefore",
                disabled: !addRowBefore(state)
              },
              {
                label: translation("CUSTOM_GRID.ROW_ACTIONS.ADD_AFTER"),
                action: "addRowAfter",
                disabled: !addRowAfter(state)
              },
              {
                label: translation("CUSTOM_GRID.ROW_ACTIONS.DELETE"),
                action: "deleteRow",
                disabled: !deleteRow(state)
              }
            ].forEach(({ label, action, disabled }) => {
              const dropdownItem = document.createElement("button");
              dropdownItem.disabled = disabled;
              dropdownItem.className = DROPDOWN_ITEM_CLASS;
              dropdownItem.textContent = label;
              dropdownItem.setAttribute("data-action", action);
              dropdownContent.appendChild(dropdownItem);
            });

            dropdown.appendChild(dropdownToggle);
            dropdown.appendChild(dropdownContent);

            return dropdown;
          });

          controlDecorations.push(rowControl);
        }

        if (value.controls && value.controls.direction === "column") {
          const { pos, opened } = value.controls;
          const columnControl = Decoration.widget(pos, () => {
            const dropdown = document.createElement("div");
            dropdown.className = COLUMN_ACTIONS_CLASS;

            const dropdownToggle = document.createElement("button");
            dropdownToggle.className = DROPDOWN_TOGGLE_CLASS;

            const triangle = document.createElement("span");
            triangle.className = "triangle";

            dropdownToggle.appendChild(triangle);

            const dropdownContent = document.createElement("div");
            dropdownContent.className = DROPDOWN_CONTENT_CLASS;

            if (opened === false) {
              dropdownContent.style.display = "none";
            }

            [
              {
                label: translation("CUSTOM_GRID.COLUMN_ACTIONS.ADD_BEFORE"),
                action: "addColumnBefore",
                disabled: !addColumnBefore(state)
              },
              {
                label: translation("CUSTOM_GRID.COLUMN_ACTIONS.ADD_AFTER"),
                action: "addColumnAfter",
                disabled: !addColumnAfter(state)
              },
              {
                label: translation("CUSTOM_GRID.COLUMN_ACTIONS.DELETE"),
                action: "deleteColumn",
                disabled: !deleteColumn(state)
              }
            ].forEach(({ label, action, disabled }) => {
              const dropdownItem = document.createElement("button");
              dropdownItem.disabled = disabled;
              dropdownItem.className = DROPDOWN_ITEM_CLASS;
              dropdownItem.textContent = label;
              dropdownItem.setAttribute("data-action", action);
              dropdownContent.appendChild(dropdownItem);
            });
            dropdown.appendChild(dropdownToggle);
            dropdown.appendChild(dropdownContent);

            return dropdown;
          });

          controlDecorations.push(columnControl);
        }

        return DecorationSet.create(state.doc, [
          ...tableDecorations,
          ...columnDecorations,
          ...rowDecorations,
          ...controlDecorations
        ]);
      },
      handleDOMEvents: {
        mousedown(view, event) {
          let pluginState = key.getState(view.state);
          if (pluginState == null) {
            return false;
          }

          const target = event.target as Element;
          const targetIsTableControls =
            target.classList.contains(TABLE_CONTROLS_CLASS) ||
            target.classList.contains(ROW_CONTROLS_CLASS) ||
            target.classList.contains(COLUMN_CONTROLS_CLASS) ||
            target.closest(`.${ROW_ACTIONS_CLASS}`) != null ||
            target.closest(`.${COLUMN_ACTIONS_CLASS}`) != null;

          if (targetIsTableControls) {
            if (target.closest(`.${ROW_ACTIONS_CLASS}`) != null) {
              event.preventDefault();
              return true;
            } else if (target.closest(`.${COLUMN_ACTIONS_CLASS}`) != null) {
              event.preventDefault();
              return true;
            } else {
              const tablePosAttr = target.getAttribute("data-table-pos");
              const pos =
                tablePosAttr != null ? parseInt(tablePosAttr, 10) : null;

              if (pos == null) {
                return false;
              }

              const table = view.state.doc.nodeAt(pos);

              if (table != null && table.type.name === "table") {
                const map = TableMap.get(table);

                if (target.classList.contains(TABLE_CONTROLS_CLASS)) {
                  const selection = new NodeSelection(
                    view.state.doc.resolve(pos)
                  );
                  view.dispatch(view.state.tr.setSelection(selection));

                  event.preventDefault();
                  return true;
                } else if (target.classList.contains(ROW_CONTROLS_CLASS)) {
                  const rowAttribute = target.getAttribute("data-row-index");
                  const row =
                    rowAttribute == null ? 0 : parseInt(rowAttribute, 10);

                  const cellPos = pos + 1 + map.positionAt(row, 0, table);
                  const selection = CellSelection.rowSelection(
                    view.state.doc.resolve(cellPos)
                  );
                  view.dispatch(
                    view.state.tr.setSelection(
                      (selection as unknown) as Selection
                    )
                  );

                  event.preventDefault();
                  return true;
                } else if (target.classList.contains(COLUMN_CONTROLS_CLASS)) {
                  const columnAttribute = target.getAttribute(
                    "data-column-index"
                  );
                  const column =
                    columnAttribute == null ? 0 : parseInt(columnAttribute, 10);

                  const cellPos = pos + 1 + map.positionAt(0, column, table);
                  const selection = CellSelection.colSelection(
                    view.state.doc.resolve(cellPos)
                  );
                  view.dispatch(
                    view.state.tr.setSelection(
                      (selection as unknown) as Selection
                    )
                  );

                  event.preventDefault();
                  return true;
                }
              }
            }
          }

          return false;
        },
        click(view, event) {
          let pluginState = key.getState(view.state);
          if (pluginState == null) {
            return false;
          }

          const target = event.target as Element;
          if (
            pluginState.controls !== false &&
            (target.closest(`.${ROW_ACTIONS_CLASS}`) != null ||
              target.closest(`.${COLUMN_ACTIONS_CLASS}`) != null)
          ) {
            const { opened } = pluginState.controls;
            const dropdownItem = target.closest(`.${DROPDOWN_ITEM_CLASS}`);

            setOpened(opened)(view.state, view.dispatch);

            if (dropdownItem) {
              const action = dropdownItem.getAttribute("data-action");

              switch (action) {
                case "addColumnBefore":
                  addColumnBefore(view.state, view.dispatch);
                  break;

                case "addColumnAfter":
                  addColumnAfter(view.state, view.dispatch);
                  break;

                case "deleteColumn":
                  deleteColumn(view.state, view.dispatch);
                  break;

                case "addRowBefore":
                  addRowBefore(view.state, view.dispatch);
                  break;

                case "addRowAfter":
                  addRowAfter(view.state, view.dispatch);
                  break;

                case "deleteRow":
                  deleteRow(view.state, view.dispatch);
                  break;
              }
            }

            event.preventDefault();
            return true;
          }

          return false;
        }
      }
    }
  });
}

export function getCellsWithTableControls(
  map: TableMap,
  showHeader: boolean
): number[] {
  return map.cellsInRect({
    left: 0,
    right: 1,
    top: showHeader ? 0 : 1,
    bottom: showHeader ? 1 : 2
  });
}

export function getCellsWithColumnControls(
  map: TableMap,
  showHeader: boolean
): number[] {
  return map.cellsInRect({
    left: 0,
    right: map.width,
    top: showHeader ? 0 : 1,
    bottom: showHeader ? 1 : 2
  });
}

export function getCellsWithRowControls(map: TableMap): number[] {
  return map.cellsInRect({
    left: 0,
    right: 1,
    top: 0,
    bottom: map.height
  });
}
