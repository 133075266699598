import { Node, Schema } from "prosemirror-model";
import { EditorState, NodeSelection, Plugin } from "prosemirror-state";
import { isInTable } from "prosemirror-tables";
import {
  CommandConfiguration,
  CommandConfigurations,
  CommandFn,
  Extension,
  NodeConfig
} from "../../editor";
import {
  dropInsertPlugin,
  executeAtPos,
  isEnableAtPos
} from "../../editor/plugins/drop-insert-plugin";
import { emitNotification } from "../../editor/plugins/notification";
import {
  applyName,
  namePredicate,
  questionTitleCacheKey,
  questionTitleCachePlugin
} from "../../editor/plugins/question-title-cache/question-title-cache";
import { Focus } from "../../editor/plugins/selection-focus";
import {
  findChildren,
  findNodePos,
  findParent,
  isMac,
  UnreachableCaseError
} from "../../util";
import { canInsert } from "../../util/selection";
import { getTranslation } from "../localization";
import { getQuestionTitleActiveValue, updateName } from "../question-title";
import { insertConstantSumMatrix } from "./commands";
import { ConstantSumNode, nodeViewPlugin } from "./nodes";
import { pasteDropPlugin } from "./plugins";
import { constantSumAssociations } from "./plugins/associations-decorator";
import {
  ConstantSumLogicDirection,
  ConstantSumNumberOfDecimals,
  ConstantSumSchema,
  ConstantSumType
} from "./schema";
import {
  containsConstantSum,
  createConstantSum,
  focusedConstantSum,
  getAssociationsForConstantSum,
  isInTableCell
} from "./util";

export interface InsertConstantSumCommandProps {
  direction: ConstantSumLogicDirection | "auto-assign";
}

interface InputNumberAccociation {
  name: string;
  type: "inputNumber";
}

interface ConstantSumAccociation {
  name: string;
  type: "constantSum";
  direction: ConstantSumLogicDirection;
}

type Association = InputNumberAccociation | ConstantSumAccociation;

export interface ConstantSumActiveValue {
  name: string;
  type: ConstantSumType;
  direction: ConstantSumLogicDirection;
  required: boolean;
  prefix: string | null;
  suffix: string | null;
  minValue: number | null;
  maxValue: number | null;
  numberOfDecimals: number;
  associations: Association[];
  hasRepeatErrors: boolean;
}

export interface UpdateConstantSumCommandProps {
  name: string;
  type: ConstantSumType;
  direction: ConstantSumLogicDirection;
  required: boolean;
  prefix: string | null;
  suffix: string | null;
  minValue: number | null;
  maxValue: number | null;
  numberOfDecimals: number;
}

export interface InsertConstantSumGridProps {
  direction: ConstantSumLogicDirection;
}

const questionTitleKey = questionTitleCacheKey();

export class ConstantSum implements Extension<ConstantSumSchema> {
  get name(): string {
    return "constantSum";
  }

  get nodes(): NodeConfig[] {
    return [new ConstantSumNode()];
  }

  commands(
    schema: ConstantSumSchema
  ): CommandConfigurations<ConstantSumSchema> {
    return {
      insertConstantSum: this.insertConstantSumCommand(),
      updatedConstantSum: this.updatedConstantSumCommand(schema),
      insertConstantSumGrid: this.insertConstantSumGridCommand()
    };
  }

  plugins(schema: ConstantSumSchema): Plugin[] {
    return [
      dropInsertPlugin((view, data, posAtCoords) => {
        if (data.type !== "constantSum") {
          return false;
        }

        let direction: ConstantSumLogicDirection;
        switch (data.subType) {
          case ConstantSumLogicDirection.horizontal:
            direction = ConstantSumLogicDirection.horizontal;
            break;

          case ConstantSumLogicDirection.vertical:
            direction = ConstantSumLogicDirection.vertical;
            break;

          default:
            direction = ConstantSumLogicDirection.vertical;
            break;
        }

        const command = this.insertConstantSumGridCommand();

        const isEnabled = isEnableAtPos(command, view, posAtCoords);
        if (
          !isEnabled({
            direction: direction
          })
        ) {
          emitNotification(view.state, {
            type: "warning",
            message: "drop.insert.invalid-location"
          });
        } else {
          executeAtPos(
            command.execute({
              direction: direction
            }),
            view,
            posAtCoords
          );
        }

        return true;
      }),
      dropInsertPlugin((view, data, posAtCoords) => {
        if (data.type !== "function") {
          return false;
        }

        const command = this.insertConstantSumCommand();

        const isEnabled = isEnableAtPos(command, view, posAtCoords);
        if (
          !isEnabled({
            direction: "auto-assign"
          })
        ) {
          emitNotification(view.state, {
            type: "warning",
            message: "drop.insert.invalid-location"
          });
        } else {
          executeAtPos(
            command.execute({
              direction: "auto-assign"
            }),
            view,
            posAtCoords
          );
        }

        return true;
      }),
      questionTitleCachePlugin(
        schema.nodes.constantSum,
        "INPUT_CONSTANT_SUM.DEFAULT_FUNCTION_TITLE",
        namePredicate,
        applyName,
        questionTitleKey
      ),
      nodeViewPlugin(),
      constantSumAssociations(),
      pasteDropPlugin()
    ];
  }

  private insertConstantSumCommand(): CommandConfiguration<
    ConstantSumSchema,
    InsertConstantSumCommandProps,
    undefined
  > {
    return {
      isActive: () => {
        return false;
      },
      isEnabled: () => {
        return (state) => {
          const { schema, selection } = state;
          const { $from } = selection;
          return (
            canInsert(schema.nodes.constantSum)(state) &&
            isInTable(state) &&
            !containsConstantSum($from, schema)
          );
        };
      },
      execute: (props) => {
        if (props?.direction == null) {
          throw new Error(
            `To insert an constant-sum, InsertConstantSumCommandProps need to be provided.`
          );
        }

        return insertConstantSumFunction(props);
      }
    };
  }

  private updatedConstantSumCommand(
    schema: ConstantSumSchema
  ): CommandConfiguration<
    ConstantSumSchema,
    UpdateConstantSumCommandProps,
    ConstantSumActiveValue | undefined
  > {
    return {
      isActive: () => {
        return (state) => {
          return focusedConstantSum(state) != null;
        };
      },
      isEnabled: () => {
        return (state) => {
          return focusedConstantSum(state) != null;
        };
      },
      execute: (props) => {
        return (state, dispatch) => {
          if (props == null) {
            throw new Error(
              `To update a constant-sum, UpdateConstantSumCommandProps needs to be provided.`
            );
          }

          const translation = getTranslation(state);

          const focused = focusedConstantSum(state);
          if (!focused) {
            return false;
          }

          if (dispatch) {
            const { node, pos } = focused;

            let updatedAttrs = { ...node.attrs };

            let tr = state.tr;

            if (props.name !== undefined) {
              updatedAttrs = updateName(updatedAttrs, props.name);
            }

            if (props.type !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                type: props.type
              };
            }

            if (props.direction !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                direction: props.direction
              };
            }

            if (props.maxValue !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                maxValue: props.maxValue
              };
            }

            if (props.minValue !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                minValue: props.minValue
              };
            }

            if (
              props.numberOfDecimals != null &&
              props.numberOfDecimals >= ConstantSumNumberOfDecimals.minimum &&
              props.numberOfDecimals <= ConstantSumNumberOfDecimals.maximum
            ) {
              updatedAttrs = {
                ...updatedAttrs,
                numberOfDecimals: props.numberOfDecimals
              };
            }

            if (props.required !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                required: props.required
              };
            }

            if (props.prefix !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                prefix: props.prefix
              };
            }

            if (props.suffix !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                suffix: props.suffix
              };
            }

            tr = tr.setNodeMarkup(pos, undefined, updatedAttrs);
            if (props.type !== undefined && node.attrs.type !== props.type) {
              const text =
                props.type === ConstantSumType.sum
                  ? translation("INPUT_CONSTANT_SUM.TOTAL")
                  : translation("INPUT_CONSTANT_SUM.REMAINDER");

              const from = pos + 1;
              const to = from + node.nodeSize - 2;
              tr = tr.replaceWith(from, to, schema.text(text));
            }
            tr = tr.setSelection(new NodeSelection(tr.doc.resolve(pos)));

            dispatch(tr);
          }

          return true;
        };
      },
      activeValue: () => {
        return (state) => {
          const focused = focusedConstantSum(state);
          if (!focused) {
            return undefined;
          }

          const { node } = focused;
          const direction = node.attrs.direction;

          return {
            name: node.attrs.name,
            type: node.attrs.type,
            direction,
            prefix: node.attrs.prefix,
            suffix: node.attrs.suffix,
            minValue: node.attrs.minValue,
            maxValue: node.attrs.maxValue,
            numberOfDecimals: node.attrs.numberOfDecimals,
            required: node.attrs.required,
            associations: getActiveAssociations(state),
            hasRepeatErrors: getRepeatErrors(state, focused, direction)
          };
        };
      }
    };
  }

  private insertConstantSumGridCommand(): CommandConfiguration<
    ConstantSumSchema,
    InsertConstantSumGridProps,
    undefined
  > {
    return {
      isActive: () => false,
      isEnabled: () => {
        return insertConstantSumMatrix(ConstantSumLogicDirection.vertical);
      },
      execute: (props) => {
        if (props == null) {
          throw new Error(
            `To insert a grid, InsertConstantSumGridProps needs to be provided.`
          );
        }

        const { direction } = props;

        if (direction == null) {
          throw new Error(
            `To insert a constant sum grid, direction needs to be provided for InsertConstantSumGridProps.`
          );
        }

        switch (direction) {
          case ConstantSumLogicDirection.vertical:
            return insertConstantSumMatrix(direction);

          case ConstantSumLogicDirection.horizontal:
            return insertConstantSumMatrix(direction);

          default:
            throw new UnreachableCaseError(direction);
        }
      },
      shortcuts: {
        [isMac() ? "Ctrl-o" : "Alt-o"]: {
          direction: ConstantSumLogicDirection.vertical
        }
      }
    };
  }
}

function insertConstantSumFunction(
  props: InsertConstantSumCommandProps
): CommandFn<ConstantSumSchema> {
  return (state, dispatch) => {
    if (props == null) {
      return false;
    }

    if (props.direction === "auto-assign") {
      props.direction = isInTableCell(state)
        ? ConstantSumLogicDirection.horizontal
        : ConstantSumLogicDirection.vertical;
    }

    const { schema } = state;

    if (!canInsert(schema.nodes.constantSum)(state)) {
      return false;
    }

    if (dispatch) {
      const translation = getTranslation(state);

      const constantSum = createConstantSum(
        schema,
        props.direction,
        ConstantSumType.sum,
        translation
      );

      let tr = state.tr;
      tr = tr.replaceSelectionWith(constantSum, false);
      const $pos = findNodePos(tr.doc, tr.selection.from, constantSum);
      if ($pos) {
        tr = tr.setSelection(new NodeSelection($pos));
      }
      tr = tr.scrollIntoView();

      dispatch(tr);
    }

    return true;
  };
}

function getActiveAssociations(
  state: EditorState<ConstantSumSchema>
): Association[] {
  const associations = getAssociationsForConstantSum(state);

  return associations.reduce((acc, { node, constantSum }) => {
    if (constantSum === true) {
      return acc;
    } else {
      const labels = getAssociations(node, state);
      return acc.concat(...labels);
    }
  }, new Array<Association>());
}

function getRepeatErrors(
  state: EditorState<ConstantSumSchema>,
  constantSum: Focus,
  direction: ConstantSumLogicDirection
): boolean {
  const { doc } = state;
  const { pos } = constantSum;
  const $pos = doc.resolve(pos);

  const table = findParent($pos, (n) => n.type.spec.tableRole === "table");
  const row = findParent($pos, (n) => n.type.spec.tableRole === "row");

  if (table != null && row != null) {
    const { schema } = state;

    const tableRows = findChildren(
      table.node,
      (n) => n.type === schema.nodes.tableRow
    );

    if (tableRows.length !== 0) {
      const lastRow = tableRows[tableRows.length - 1];

      const isSameRow = lastRow.node.attrs.id === row.node.attrs.id;

      return (
        isSameRow &&
        direction === ConstantSumLogicDirection.vertical &&
        table.node.attrs.lastRowManualRepeat
      );
    }
  }
  return false;
}

function getAssociations(
  cell: Node,
  state: EditorState<Schema>
): Association[] {
  const { schema } = state;

  let returnValue = new Array<Association>();
  cell.descendants((node) => {
    if (node.type === schema.nodes.inputNumber) {
      const questionTitle = getQuestionTitleActiveValue(state, node);
      returnValue.push({ name: questionTitle.text, type: "inputNumber" });
      return;
    } else if (node.type === schema.nodes.constantSum) {
      returnValue.push({
        name: node.attrs.name,
        type: "constantSum",
        direction: node.attrs.direction
      });
      return;
    }
  });

  return returnValue;
}
