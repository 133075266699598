export type SkinId = "default" | "rounded" | "simple";

interface SkinBuilder {
  id: SkinId;
  name: string;
}
export class Skin {
  private readonly _id: SkinId;
  private readonly _name: string;

  constructor(builder: SkinBuilder) {
    this._id = builder.id;
    this._name = builder.name;
  }

  get id(): SkinId {
    return this._id;
  }

  get name(): string {
    return this._name;
  }
}
