import {
  CommandConfiguration,
  CommandConfigurations,
  CommandFn,
  Extension,
  KeyMap,
  NodeConfig
} from "../../editor";
import { NodeSelection, Plugin } from "prosemirror-state";
import {
  BarChartActiveValue,
  BarChartIndexAxis,
  BarChartQuestionType,
  BarChartSchema,
  BarChartWidth,
  InsertBarChartProps,
  UpdateBarChartProps,
  BarChartRankConfigurationSegmentType,
  BarChartSegmentMode,
  RemoveSegmentProps,
  BarChartSegment
} from "./schema";
import { canInsert } from "../../util/selection";
import {
  createBarChart,
  focusedBarChart,
  mapNodeToAutomaticBarCharSegments,
  updateSegments,
  newEmptySegment
} from "./util";
import { insertBlock, isInsideGrid } from "../../util";
import { getIdGenerator } from "../node-identifier/node-identifier";
import { BarChartNode } from "./nodes";
import { barChartViewPlugin } from "./node-views/plugin";
import {
  dropInsertPlugin,
  executeAtPos,
  isEnableAtPos
} from "../../editor/plugins/drop-insert-plugin";
import { emitNotification } from "../../editor/plugins/notification";
import { reportOutline } from "../report-outline/plugins";
import { Node, Schema } from "prosemirror-model";

export class BarChart implements Extension<BarChartSchema> {
  get name(): string {
    return "barChart";
  }

  get nodes(): NodeConfig[] {
    return [new BarChartNode()];
  }

  plugins(schema: BarChartSchema): Plugin[] {
    const plugins: Plugin[] = [
      barChartViewPlugin(),
      reportOutline(schema.nodes.barChart),
      dropInsertPlugin((view, data, posAtCoords) => {
        if (data.type !== "barChart") {
          return false;
        }

        let type: BarChartIndexAxis;
        switch (data.subType) {
          case BarChartIndexAxis.horizontal:
            type = BarChartIndexAxis.horizontal;
            break;

          case BarChartIndexAxis.vertical:
            type = BarChartIndexAxis.vertical;
            break;

          default:
            type = BarChartIndexAxis.horizontal;
            break;
        }

        const command = this.insertBarChartCommand(view.state.schema);

        const isEnabled = isEnableAtPos(command, view, posAtCoords);
        if (
          !isEnabled({
            questionType: null,
            type: type
          })
        ) {
          emitNotification(view.state, {
            type: "warning",
            message: "drop.insert.invalid-location"
          });
        } else {
          executeAtPos(
            command.execute({
              questionType: null,
              type: type
            }),
            view,
            posAtCoords
          );
        }

        return true;
      })
    ];

    return plugins;
  }

  commands(schema: BarChartSchema): CommandConfigurations<BarChartSchema> {
    return {
      insertBarChart: this.insertBarChartCommand(schema),
      insertBarChartFromQuestion: this.insertBarChartCommand(schema),
      updateBarChart: this.updateBarChartCommand(schema),
      removeSegment: this.removeSegment(),
      addSegment: this.addSegment()
    };
  }

  keymaps(_schema: BarChartSchema): KeyMap<BarChartSchema> {
    return {};
  }

  private insertBarChartCommand(
    schema: BarChartSchema
  ): CommandConfiguration<BarChartSchema, InsertBarChartProps, undefined> {
    return {
      isActive: () => {
        return false;
      },
      isEnabled: () => {
        return insertBarChart(schema, null, BarChartIndexAxis.horizontal);
      },
      execute: (props) => {
        if (props == null) {
          throw new Error(
            `To insert a bar chart, InsertBarChartProps needs to be provided.`
          );
        }

        const { type, questionType } = props;

        if (type == null) {
          throw new Error(
            `To insert a bar chart, type need to be provided for InsertBarChartProps.`
          );
        }

        return insertBarChart(schema, questionType, type);
      }
    };
  }

  private updateBarChartCommand(
    schema: Schema
  ): CommandConfiguration<
    BarChartSchema,
    UpdateBarChartProps,
    BarChartActiveValue | undefined
  > {
    return {
      isActive: () => {
        return (state) => {
          return focusedBarChart(state) != null;
        };
      },
      isEnabled: () => {
        return (state) => {
          return focusedBarChart(state) != null;
        };
      },
      execute: (props) => {
        return (state, dispatch) => {
          const focused = focusedBarChart(state);

          if (!focused) {
            return false;
          }

          if (props == null) {
            throw new Error(
              `To update a bar chart, UpdateBarChartProps needs to be provided.`
            );
          }

          if (dispatch) {
            const from = focused.pos;

            let updatedAttrs = { ...focused.node.attrs };

            let tr = state.tr;

            if (props.surveyId !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                surveyId: props.surveyId
              };
            }

            if (props.type !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                type: props.type
              };
            }

            if (props.style !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                style: props.style
              };
            }

            if (props.segmentTypeProps !== undefined) {
              const type = props.segmentTypeProps.type;
              const options = props.segmentTypeProps.options;

              const rankConfiguration = updatedAttrs.rankConfiguration;
              const segments = updateSegments(
                type,
                options,
                updatedAttrs.segments
              );

              updatedAttrs = {
                ...updatedAttrs,
                segments: segments,
                rankConfiguration: {
                  ...rankConfiguration,
                  segmentType: type
                }
              };
            }

            if (props.sectionId !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                rankConfiguration: {
                  ...updatedAttrs.rankConfiguration,
                  sectionId: props.sectionId
                }
              };
            }

            if (props.option !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                rankConfiguration: {
                  ...updatedAttrs.rankConfiguration,
                  option: props.option
                }
              };
            }

            if (props.optionLabel !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                rankConfiguration: {
                  ...updatedAttrs.rankConfiguration,
                  option: {
                    id: updatedAttrs.rankConfiguration.option.id,
                    label: props.optionLabel
                  }
                }
              };
            }

            if (props.rankLabel !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                rankConfiguration: {
                  ...updatedAttrs.rankConfiguration,
                  rank: {
                    id: updatedAttrs.rankConfiguration.rank.id,
                    label: props.rankLabel
                  }
                }
              };
            }

            if (props.rank !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                rankConfiguration: {
                  ...updatedAttrs.rankConfiguration,
                  rank: {
                    id: props.rank,
                    label: `R${props.rank}`
                  }
                }
              };
            }

            if (props.segmentModeProps !== undefined) {
              if (updatedAttrs.segmentMode !== props.segmentModeProps.mode) {
                updatedAttrs = {
                  ...updatedAttrs,
                  segments:
                    props.segmentModeProps.mode ===
                    BarChartSegmentMode.automatic
                      ? mapNodeToAutomaticBarCharSegments(
                          getIdGenerator(state),
                          props.segmentModeProps.node,
                          schema
                        )
                      : [newEmptySegment(getIdGenerator(state))]
                };
              }

              updatedAttrs = {
                ...updatedAttrs,
                segmentMode: props.segmentModeProps.mode
              };
            }

            if (props.segments !== undefined) {
              const segments = props.segments;

              if (segments.length === 0) {
                updatedAttrs = {
                  ...updatedAttrs,
                  segments: [],
                  surveyId: null,
                  inputId: null,
                  questionType: null
                };
              } else {
                updatedAttrs = {
                  ...updatedAttrs,
                  segments: props.segments
                };
              }
            }

            if (props.node !== undefined) {
              const expectedNode = props.node as Node;

              updatedAttrs = {
                ...updatedAttrs,
                inputId: expectedNode.attrs.id,
                questionType: expectedNode.type.name,
                segmentMode: BarChartSegmentMode.automatic,
                segments: mapNodeToAutomaticBarCharSegments(
                  getIdGenerator(state),
                  expectedNode,
                  state.schema
                )
              };

              if (expectedNode.type === schema.nodes.inputRank) {
                const sections = expectedNode.firstChild;
                const options = expectedNode.lastChild;

                if (sections != null && options != null) {
                  const firstSection = sections.firstChild;
                  const firstOption = options.firstChild;

                  updatedAttrs = {
                    ...updatedAttrs,
                    rankConfiguration: {
                      segmentType: BarChartRankConfigurationSegmentType.item,
                      sectionId: firstSection ? firstSection.attrs.id : "",
                      option: {
                        id: firstOption ? firstOption.attrs.id : "",
                        label: firstOption
                          ? firstOption.content.firstChild?.text
                          : ""
                      },
                      rank: {
                        id: 1,
                        label: "R1"
                      }
                    }
                  };
                }
              }
            }

            if (props.showInputLabel !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                showInputLabel: props.showInputLabel
              };
            }

            if (props.inputValueType !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                inputValueType: props.inputValueType
              };
            }

            if (props.showInputValue !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                showInputValue: props.showInputValue
              };
            }

            if (props.width != null && !isNaN(props.width)) {
              updatedAttrs = {
                ...updatedAttrs,
                width: Math.min(
                  Math.max(props.width, BarChartWidth.minimum),
                  BarChartWidth.maximum
                )
              };
            }

            if (props.showDataLabel !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                showDataLabel: props.showDataLabel
              };
            }

            if (props.showSkippedCount !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                showSkippedCount: props.showSkippedCount
              };
            }

            if (props.showHiddenCount !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                showHiddenCount: props.showHiddenCount
              };
            }

            if (props.legend !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                legend: props.legend
              };
            }

            if (props.filters !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                filters: props.filters
              };
            }

            tr = tr.setNodeMarkup(from, undefined, updatedAttrs);
            tr = tr.setSelection(new NodeSelection(tr.doc.resolve(from)));
            dispatch(tr);
          }

          return true;
        };
      },
      activeValue: () => {
        return (state) => {
          const focused = focusedBarChart(state);

          if (!focused) {
            return undefined;
          }

          const { node } = focused;
          const widthDisabled = isInsideGrid(state, focused);

          return {
            surveyId: node.attrs.surveyId,
            inputId: node.attrs.inputId,
            questionType: node.attrs.questionType,
            type: node.attrs.type,
            style: node.attrs.style,
            rankConfiguration: node.attrs.rankConfiguration,
            segmentMode: node.attrs.segmentMode,
            segments: node.attrs.segments,
            showInputLabel: node.attrs.showInputLabel,
            inputValueType: node.attrs.inputValueType,
            showInputValue: node.attrs.showInputValue,
            width: widthDisabled ? 100 : node.attrs.width,
            showDataLabel: node.attrs.showDataLabel,
            showSkippedCount: node.attrs.showSkippedCount,
            showHiddenCount: node.attrs.showHiddenCount,
            legend: node.attrs.legend,
            widthDisabled: widthDisabled,
            filters: node.attrs.filters
          };
        };
      }
    };
  }

  private removeSegment(): CommandConfiguration<
    BarChartSchema,
    RemoveSegmentProps,
    undefined
  > {
    return {
      isActive: () => {
        return (state) => {
          return focusedBarChart(state) != null;
        };
      },
      isEnabled: () => {
        return (state) => {
          return focusedBarChart(state) != null;
        };
      },
      execute: (props) => {
        return (state, dispatch) => {
          const focused = focusedBarChart(state);

          if (!focused) {
            return false;
          }

          if (props == null) {
            throw new Error(
              `To remove a segment, RemoveSegmentProps needs to be provided.`
            );
          }

          const { segmentId } = props;

          if (segmentId == null) {
            throw new Error(
              `To remove a segment, segmentId needs to be provided.`
            );
          }

          if (dispatch) {
            const from = focused.pos;
            let updatedAttrs = { ...focused.node.attrs };
            const { segments } = updatedAttrs;

            let tr = state.tr;

            const expectedSegment = segments.filter(
              (s: BarChartSegment) => s.id !== segmentId
            );
            updatedAttrs = {
              ...updatedAttrs,
              segments: expectedSegment
            };

            tr = tr.setNodeMarkup(from, undefined, updatedAttrs);
            dispatch(tr);
          }

          return true;
        };
      }
    };
  }

  private addSegment(): CommandConfiguration<BarChartSchema, {}, undefined> {
    return {
      isActive: () => {
        return (state) => {
          return focusedBarChart(state) != null;
        };
      },
      isEnabled: () => {
        return (state) => {
          return focusedBarChart(state) != null;
        };
      },
      execute: () => {
        return (state, dispatch) => {
          const focused = focusedBarChart(state);

          if (!focused) {
            return false;
          }

          if (dispatch) {
            const from = focused.pos;
            let updatedAttrs = { ...focused.node.attrs };
            const { segments } = updatedAttrs;

            let tr = state.tr;

            const expectedSegment = [
              ...segments,
              newEmptySegment(getIdGenerator(state))
            ];
            updatedAttrs = {
              ...updatedAttrs,
              segments: expectedSegment
            };

            tr = tr.setNodeMarkup(from, undefined, updatedAttrs);
            dispatch(tr);
          }

          return true;
        };
      }
    };
  }
}

function insertBarChart(
  schema: BarChartSchema,
  questionType: BarChartQuestionType | null,
  type: BarChartIndexAxis
): CommandFn<BarChartSchema> {
  return (state, dispatch) => {
    if (!canInsert(schema.nodes.barChart)(state)) {
      return false;
    } else {
      if (dispatch) {
        const barChart = createBarChart(schema, questionType, type);

        if (barChart == null) {
          return false;
        }

        let tr = state.tr;
        tr = insertBlock(
          tr,
          state.schema,
          barChart,
          true,
          getIdGenerator(state),
          false,
          true
        );

        dispatch(tr);
      }
      return true;
    }
  };
}
