import { Plugin, PluginKey, TextSelection } from "prosemirror-state";
import { selectionFocusKey } from "../../../editor/plugins/selection-focus";
import { findNodePos } from "../../../util";
import { inputRankViewKey } from "./input-rank-view-plugin";

// A plugin that makes sure we have at least one option

const key = new PluginKey("rank-options-plugin");

export function inputRankOptionsPlugin() {
  return new Plugin({
    key,
    appendTransaction: (_transactions, oldState, state) => {
      const focused = selectionFocusKey.getState(state);

      if (focused == null) {
        return;
      }

      const { schema } = state;

      if (focused.node.type === schema.nodes.inputRank) {
        const options = focused.node.child(1);

        if (options.childCount === 0) {
          let { tr } = state;

          const $pos = findNodePos(tr.doc, tr.selection.from, options);

          if ($pos == null) {
            return;
          }

          const option = schema.nodes.inputRankOption.createChecked({
            id: null
          });

          tr = tr.insert($pos.pos + 1, option);
          tr = tr.setSelection(
            TextSelection.near(tr.doc.resolve($pos.pos + option.nodeSize - 1))
          );

          return tr;
        }

        const rankState = inputRankViewKey.getState(oldState);
        let { tr, selection } = state;

        if (rankState == null) {
          // Do the behavior from the expected plugin
          if (selection instanceof TextSelection) {
            return tr;
          }
          const sections = focused.node.child(0);
          const firstSection = sections.child(0);
          tr = tr.setMeta(inputRankViewKey, {
            setActive: {
              focused,
              sectionId: firstSection.attrs.id
            }
          });
          return tr;
        }
      }

      return undefined;
    }
  });
}
