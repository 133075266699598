import { Schema } from "prosemirror-model";
import { Plugin, PluginKey } from "prosemirror-state";
import { Editable, Uneditable } from "./types";

export interface EditorMode {
  editable: boolean;
  focusable: boolean;
}

export const editableKey = new PluginKey<EditorMode>("editable");

export function editable<S extends Schema>() {
  return new Plugin<EditorMode, S>({
    key: editableKey,
    state: {
      init: (_, _state) => {
        return { editable: true, focusable: true };
      },
      apply: (tr, value) => {
        const meta = tr.getMeta(editableKey) as
          | Editable
          | Uneditable
          | undefined;

        if (meta == null) {
          return value;
        } else {
          if (meta.editable === true) {
            return { editable: true, focusable: true };
          } else {
            const { focusable } = meta;
            return { editable: false, focusable: focusable };
          }
        }
      }
    },
    props: {
      editable(state) {
        const { editable } = this.getState(state);
        return editable;
      },
      attributes(state) {
        const { editable } = this.getState(state);
        if (editable === false) {
          return {
            class: "ProseMirror-readonly"
          };
        } else {
          return;
        }
      }
    }
  });
}
