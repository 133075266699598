import { Node, Schema } from "prosemirror-model";
import { EditorView, NodeView } from "prosemirror-view";
import { Editor } from "../../editor";
import { WidthResizerNodeView } from "../../editor/plugins/width-resizer";

export class ImageNodeView<S extends Schema> implements NodeView<S> {
  dom: HTMLElement;

  private resizer: WidthResizerNodeView<S>;

  constructor(
    private node: Node<S>,
    view: EditorView<S>,
    getPos: () => number,
    minWidth: number,
    maxWidth: number
  ) {
    const container = document.createElement("div");
    container.style.display = "inline-block";
    container.style.lineHeight = "0";

    this.resizer = new WidthResizerNodeView(
      container,
      node,
      view,
      getPos,
      minWidth,
      maxWidth,
      (width) => {
        const editor = view as Editor<S>;
        editor.commands.updateImage.execute({
          width: width
        });
      },
      true
    );

    this.dom = container;

    const img = document.createElement("img");
    img.src = node.attrs.src;
    img.alt = node.attrs.alt == null ? "" : node.attrs.alt;
    img.style.verticalAlign = "baseline";
    img.style.width = "100%";
    img.style.borderLeft = "1px solid transparent";
    img.style.borderRight = "1px solid transparent";

    this.dom.appendChild(img);
  }

  ignoreMutation(
    mutation:
      | MutationRecord
      | {
          type: "selection";
          target: Element;
        }
  ): boolean {
    const resizeIgnoreMutation = this.resizer.ignoreMutation(mutation);
    return resizeIgnoreMutation;
  }

  update(node: Node<S>): boolean {
    if (node.type !== this.node.type) {
      return false;
    }

    this.resizer.update(node);

    return true;
  }
}
