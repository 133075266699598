import { Node, NodeSpec } from "prosemirror-model";
import { DocumentBuilders, NodeConfig } from "../../editor/extension/schema";
import { toNumberValue } from "../../util";
import { AlignmentType } from "../alignment";
import {
  getQuestionTitleAttrs,
  setQuestionTitleAttrs
} from "../question-title";
import { InputDateTimeControlType, InputDateTimeWidth } from "./schema";

function getInputDateTimeAttrs(element: Element): { [key: string]: any } {
  const attrs: {
    id?: string;
    controlType?: InputDateTimeControlType;
    width?: number;
    questionTitleText?: string;
    questionTitleRefElementId?: string[];
    description?: string;
    coding?: string;
    defaultDate?: string;
    defaultTime?: string;
    isDefaultNow?: boolean;
    watermark?: string;
    dateFormat?: string;
    timeFormat?: string;
    minimumDate?: string;
    maximumDate?: string;
    minimumTime?: string;
    maximumTime?: string;
    required?: boolean;
    alignment?: AlignmentType;
  } = { ...getQuestionTitleAttrs(element) };

  const id = element.getAttribute("id");
  if (id != null) {
    attrs.id = id;
  }

  const controlType = element.getAttribute(
    "data-control-type"
  ) as InputDateTimeControlType;
  if (controlType != null) {
    switch (controlType) {
      case InputDateTimeControlType.date:
      case InputDateTimeControlType.time:
        attrs.controlType = controlType;
        break;
      default:
        break;
    }
  }

  const width = toNumberValue(element.getAttribute("data-width"), 0);
  if (width != null) {
    attrs.width = width;
  }

  const description = element.getAttribute("data-description");
  if (description != null) {
    attrs.description = description;
  }

  const coding = element.getAttribute("data-coding");
  if (coding != null) {
    attrs.coding = coding;
  }

  const defaultDate = element.getAttribute("data-defaultdate");
  if (defaultDate != null) {
    attrs.defaultDate = defaultDate;
  }

  const defaultTime = element.getAttribute("data-defaulttime");
  if (defaultTime != null) {
    attrs.defaultTime = defaultTime;
  }

  const isDefaultNow = element.getAttribute("data-isdefaultnow");
  if (isDefaultNow != null) {
    attrs.isDefaultNow = isDefaultNow.toLowerCase() === "true";
  }

  const watermark = element.getAttribute("data-watermark");
  if (watermark != null) {
    attrs.watermark = watermark;
  }

  const dateFormat = element.getAttribute("data-dateformat");
  if (dateFormat != null) {
    attrs.dateFormat = dateFormat;
  }

  const timeFormat = element.getAttribute("data-timeformat");
  if (timeFormat != null) {
    attrs.timeFormat = timeFormat;
  }

  const minimumDate = element.getAttribute("data-minimumdate");
  if (minimumDate != null) {
    attrs.minimumDate = minimumDate;
  }

  const maximumDate = element.getAttribute("data-maximumdate");
  if (maximumDate != null) {
    attrs.maximumDate = maximumDate;
  }

  const minimumTime = element.getAttribute("data-minimumtime");
  if (minimumTime != null) {
    attrs.minimumTime = minimumTime;
  }

  const maximumTime = element.getAttribute("data-maximumtime");
  if (maximumTime != null) {
    attrs.maximumTime = maximumTime;
  }

  const required = element.getAttribute("data-required");
  if (required != null) {
    attrs.required = required.toLowerCase() === "true";
  }

  const alignment = element.getAttribute("data-alignment") as AlignmentType;
  if (alignment != null) {
    switch (alignment) {
      case "center":
      case "left":
      case "right":
        attrs.alignment = alignment;
        break;
      default:
        break;
    }
  }

  return attrs;
}

function setInputDateTimeAttrs(node: Node): { [key: string]: string } {
  return {
    id: node.attrs.id,
    ...setQuestionTitleAttrs(node),
    "data-control-type": node.attrs.controlType,
    "data-width": node.attrs.width,
    "data-description": node.attrs.description,
    "data-coding": node.attrs.coding,
    "data-defaultdate": node.attrs.defaultDate,
    "data-defaulttime": node.attrs.defaultTime,
    "data-isdefaultnow": node.attrs.isDefaultNow,
    "data-watermark": node.attrs.watermark,
    "data-dateformat": node.attrs.dateFormat,
    "data-timeformat": node.attrs.timeFormat,
    "data-minimumdate": node.attrs.minimumDate,
    "data-maximumdate": node.attrs.maximumDate,
    "data-minimumtime": node.attrs.minimumTime,
    "data-maximumtime": node.attrs.maximumTime,
    "data-required": node.attrs.required,
    "data-alignment": node.attrs.alignment
  };
}

export class InputDateTimeNode implements NodeConfig {
  get name(): string {
    return "inputDateTime";
  }

  get spec(): NodeSpec {
    return {
      group: "block input gridInput",
      draggable: true,
      selectable: true,
      focusable: true,
      allowGapCursor: true,
      allowIndentation: false,
      allowAlignment: ["left", "center", "right"],
      blockAlignment: true,
      attrs: {
        id: { default: null },
        controlType: { default: InputDateTimeControlType.date },
        width: { default: InputDateTimeWidth.default },
        questionTitleText: { default: null },
        questionTitleRefElementId: { default: new Array<string>() },
        description: { default: "" },
        coding: { default: "" },
        defaultDate: { default: null },
        defaultTime: { default: null },
        isDefaultNow: { default: false },
        watermark: { default: null },
        dateFormat: { default: null },
        timeFormat: { default: null },
        minimumDate: { default: null },
        maximumDate: { default: null },
        minimumTime: { default: null },
        maximumTime: { default: null },
        required: { default: false },
        alignment: { default: null }
      },
      parseDOM: [
        {
          tag: "input-datetime",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getInputDateTimeAttrs(node as Element);
          }
        },
        {
          tag: '[data-input="datetime"]',
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getInputDateTimeAttrs(node as Element);
          }
        }
      ],
      toDOM(node) {
        return ["input-datetime", setInputDateTimeAttrs(node)];
      },
      applyBlueprint(
        attrs: Record<string, any>,
        blueprint: Record<string, any>
      ) {
        const updated = { ...attrs };

        updated.required = blueprint.required;
        updated.controlType = blueprint.controlType;
        updated.dateFormat = blueprint.dateFormat;
        updated.timeFormat = blueprint.timeFormat;
        updated.watermark = blueprint.watermark;
        updated.minimumDate = blueprint.minimumDate;
        updated.maximumDate = blueprint.maximumDate;
        updated.minimumTime = blueprint.minimumTime;
        updated.maximumTime = blueprint.maximumTime;
        updated.defaultTime = blueprint.defaultTime;
        updated.defaultDate = blueprint.defaultDate;
        updated.isDefaultNow = blueprint.isDefaultNow;

        return updated;
      }
    };
  }

  get builders(): DocumentBuilders {
    return { inputDateTime: { nodeType: "inputDateTime" } };
  }
}
