import { Schema } from "prosemirror-model";
import { Plugin } from "prosemirror-state";
import {
  CommandConfiguration,
  CommandConfigurations,
  Extension
} from "../../editor/extension";
import {
  colorPaletteKey,
  colorSchemePicker
} from "./plugin/color-scheme-picker";
import { ColorPalette, ColorPaletteId } from "./types";

export interface ChangeColorSchemeCommandProps {
  paletteId: string;
}

export interface ChangeSchemeActiveValue {
  palette: ColorPalette;
}

export class ColorScheme implements Extension<Schema> {
  constructor(
    private palettes: ColorPalette[],
    private defaultPaletteId: ColorPaletteId
  ) {}

  get name(): string {
    return "colorScheme";
  }

  plugins(): Plugin[] {
    return [colorSchemePicker(this.palettes, this.defaultPaletteId)];
  }

  commands(schema: Schema): CommandConfigurations<Schema> {
    return {
      changeColorScheme: this.changeColorSchemeCommand(schema)
    };
  }

  private changeColorSchemeCommand(
    _schema: Schema
  ): CommandConfiguration<
    Schema,
    ChangeColorSchemeCommandProps,
    ChangeSchemeActiveValue | undefined
  > {
    return {
      isActive: () => {
        return false;
      },
      isEnabled: () => {
        return true;
      },
      execute: (props) => {
        if (props == null) {
          throw new Error(
            `To change color scheme, ChangeColorSchemeCommandProps needs to be provided.`
          );
        }

        const { paletteId } = props;

        return (state, dispatch) => {
          if (dispatch) {
            let tr = state.tr;
            tr = tr.setMeta(colorPaletteKey, { paletteId: paletteId });
            dispatch(tr);
          }

          return true;
        };
      },
      activeValue: () => {
        return (state) => {
          const colorScheme = colorPaletteKey.getState(state);
          if (colorScheme == null) {
            return undefined;
          }

          const { palette } = colorScheme;

          return {
            palette: palette
          };
        };
      },
      requiresEditable: false
    };
  }
}
