import { Node, Schema } from "prosemirror-model";
import { Decoration, EditorView, NodeView } from "prosemirror-view";
import { AlignmentType, getAlignment } from "../../alignment";
import { GetTranslationFn, LanguageObserver } from "../../localization";
import { EnableNextButton, EnablePreviousButton } from "../nodes";
import { PageBreakPageIndicatorSpec } from "./types";

export class PageBreakNodeView<S extends Schema> implements NodeView<S> {
  dom: HTMLElement;
  contentDOM: HTMLElement;
  private pageIndicator: HTMLElement;
  private enableNextIcon: HTMLElement;
  private enableNextTooltip: HTMLElement;

  private languageObserver: LanguageObserver<S>;
  private properties: { index: number; total: number };

  constructor(
    private node: Node<S>,
    view: EditorView<S>,
    _getPos: () => number,
    decorations: Decoration[]
  ) {
    this.properties = this.getPageBreakProperties(decorations);

    this.languageObserver = new LanguageObserver(view, (getTranslation) => {
      this.updatePageIndicator(
        this.pageIndicator,
        this.properties,
        getTranslation
      );
      this.updateEnableNext(
        this.enableNextIcon,
        this.enableNextTooltip,
        this.node.attrs.enableNext,
        getTranslation
      );
    });

    const container = document.createElement("page-break");

    const pageBreakButtons = document.createElement("page-break-buttons");

    const propertiesContainer = document.createElement("page-break-properties");
    propertiesContainer.contentEditable = "false";

    const pageIndicator = document.createElement("page-break-page-indicator");
    pageIndicator.contentEditable = "false";

    const enableNext = document.createElement("page-break-enable-next");
    enableNext.contentEditable = "false";

    const enableNextIcon = document.createElement(
      "page-break-enable-next-icon"
    );
    enableNextIcon.className = "bx";

    const enableNextTooltip = document.createElement(
      "page-break-enable-next-tooltip"
    );

    enableNext.appendChild(enableNextIcon);
    enableNext.appendChild(enableNextTooltip);

    propertiesContainer.appendChild(pageIndicator);
    propertiesContainer.appendChild(enableNext);

    container.appendChild(pageBreakButtons);
    container.appendChild(propertiesContainer);

    this.dom = container;
    this.contentDOM = pageBreakButtons;
    this.pageIndicator = pageIndicator;
    this.enableNextIcon = enableNextIcon;
    this.enableNextTooltip = enableNextTooltip;

    const getTranslation = this.languageObserver.getTranslation;

    this.updateId(node);
    this.updateAlignment(getAlignment(node.attrs.alignment));
    this.updateEnablePrevious(node.attrs.enablePrevious);
    this.updatePageIndicator(
      this.pageIndicator,
      this.properties,
      getTranslation
    );
    this.updateEnableNext(
      this.enableNextIcon,
      this.enableNextTooltip,
      this.node.attrs.enableNext,
      getTranslation
    );
  }

  update(node: Node<S>, decorations: Decoration[]): boolean {
    if (node.type !== this.node.type) {
      return false;
    }

    this.node = node;

    this.properties = this.getPageBreakProperties(decorations);
    const getTranslation = this.languageObserver.getTranslation;

    this.updateId(node);
    this.updateAlignment(getAlignment(node.attrs.alignment));
    this.updateEnablePrevious(node.attrs.enablePrevious);
    this.updatePageIndicator(
      this.pageIndicator,
      this.properties,
      getTranslation
    );
    this.updateEnableNext(
      this.enableNextIcon,
      this.enableNextTooltip,
      this.node.attrs.enableNext,
      getTranslation
    );

    return true;
  }

  ignoreMutation(
    mutation:
      | MutationRecord
      | {
          type: "selection";
          target: Element;
        }
  ): boolean {
    if (mutation.target === this.pageIndicator) {
      return true;
    }

    if (mutation.target === this.enableNextIcon) {
      return true;
    }

    if (mutation.target === this.enableNextTooltip) {
      return true;
    }

    return false;
  }

  destroy() {
    this.languageObserver.destroy();
  }

  private getPageBreakProperties(
    decorations: Decoration[]
  ): { index: number; total: number } {
    const pageBreakDecoration = decorations.find(
      (decoration) => decoration.spec.type === "pageBreakProperties"
    );

    let properties = { index: 0, total: 0 };
    if (pageBreakDecoration != null) {
      const pageBreakDecorationSpec = pageBreakDecoration.spec as PageBreakPageIndicatorSpec;
      properties = {
        index: pageBreakDecorationSpec.index,
        total: pageBreakDecorationSpec.total
      };
    }

    return properties;
  }

  private updateId(node: Node<S>): void {
    this.dom.id = node.attrs.id;
  }

  private updateAlignment(alignment: AlignmentType | null) {
    if (alignment != null) {
      this.dom.setAttribute("data-alignment", alignment);
    } else {
      this.dom.removeAttribute("data-alignment");
    }
  }

  private updateEnablePrevious(enablePrevious: EnablePreviousButton) {
    this.dom.setAttribute("data-enable-previous", enablePrevious);
  }

  private updatePageIndicator(
    pageIndicator: HTMLElement,
    properties: { index: number; total: number },
    getTranslation: GetTranslationFn
  ) {
    const { index, total } = properties;
    pageIndicator.innerText = getTranslation("PAGE_BREAK.PAGE_NUMBER", {
      current: index.toFixed(0),
      total: total.toFixed(0)
    });
  }

  private updateEnableNext(
    enableNextIcon: HTMLElement,
    enableNextTooltip: HTMLElement,
    enableNext: EnableNextButton,
    getTranslation: GetTranslationFn
  ) {
    switch (enableNext) {
      case "all-answered":
        enableNextIcon.classList.add("bx-ban");
        enableNextIcon.classList.remove("bx-asterisk");
        enableNextIcon.classList.remove("bx-download-arrow");
        enableNextIcon.classList.remove("bx-input-number");
        enableNextTooltip.innerText = getTranslation(
          "PAGE_BREAK.ENABLE_NEXT.ALL_ANSWERED.TOOLTIP"
        );
        break;

      case "required-answered":
        enableNextIcon.classList.remove("bx-ban");
        enableNextIcon.classList.add("bx-asterisk");
        enableNextIcon.classList.remove("bx-download-arrow");
        enableNextIcon.classList.remove("bx-input-number");
        enableNextTooltip.innerText = getTranslation(
          "PAGE_BREAK.ENABLE_NEXT.REQUIRED_ANSWERED.TOOLTIP"
        );
        break;

      case "minimum-answered":
        enableNextIcon.classList.remove("bx-ban");
        enableNextIcon.classList.remove("bx-asterisk");
        enableNextIcon.classList.remove("bx-download-arrow");
        enableNextIcon.classList.add("bx-input-number");
        enableNextTooltip.innerText = getTranslation(
          "PAGE_BREAK.ENABLE_NEXT.MINIMUM_ANSWERED.TOOLTIP"
        );
        break;

      case "always":
      default:
        enableNextIcon.classList.remove("bx-ban");
        enableNextIcon.classList.remove("bx-asterisk");
        enableNextIcon.classList.add("bx-download-arrow");
        enableNextIcon.classList.remove("bx-input-number");
        enableNextTooltip.innerText = getTranslation(
          "PAGE_BREAK.ENABLE_NEXT.ALWAYS.TOOLTIP"
        );
        break;
    }
  }
}
