import { Schema } from "prosemirror-model";

export type InputTextSchema = Schema<"inputText", any>;

export enum InputTextControlType {
  textbox = "textbox",
  textarea = "textarea"
}

export enum InputTextWidth {
  default = 100,
  minimum = 25,
  maximum = 100
}

export enum InputTextRows {
  defaultForTextBox = 1,
  defaultForTextArea = 5,
  minimum = 1,
  maximum = 20
}

export enum InputTextLength {
  minimum = 1,
  maximum = 10000
}
