import { Slice } from "prosemirror-model";
import { Plugin } from "prosemirror-state";
import { CustomAreaSchema } from "../schema";

export function pastePlugin(_schema: CustomAreaSchema) {
  return new Plugin<null, CustomAreaSchema>({
    props: {
      transformPasted(slice): Slice {
        const firstChild = slice.content.firstChild;
        if (
          slice.content.childCount === 1 &&
          slice.openStart > 0 &&
          firstChild != null &&
          firstChild.type === firstChild.type.schema.nodes.customArea &&
          firstChild.content.childCount === 1 &&
          firstChild.firstChild != null
        ) {
          return new Slice(
            firstChild.firstChild.content,
            slice.openStart - 2,
            slice.openStart - 2
          );
        } else {
          return slice;
        }
      }
    }
  });
}
