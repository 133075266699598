import { Node, Schema } from "prosemirror-model";
import { Plugin } from "prosemirror-state";
import { EditorView, NodeView } from "prosemirror-view";
import { pointsToPixels } from "../../util";
import { getIndentationPX } from "../indentation";
import { GetTranslationFn, LanguageObserver } from "../localization";
import { Heading } from "./headings";

export function headingsNodeViewPlugin(headings: Map<number, Heading>) {
  return new Plugin({
    props: {
      nodeViews: {
        headings: (node, view, _getPos) => {
          return new HeadingsView(node, view, headings);
        }
      }
    }
  });
}

class HeadingsView<S extends Schema> implements NodeView<S> {
  dom: HTMLElement;
  contentDOM: HTMLElement;

  private languageObserver: LanguageObserver<S>;

  constructor(
    private node: Node<S>,
    view: EditorView<S>,
    private headings: Map<number, Heading>
  ) {
    this.languageObserver = new LanguageObserver(view, (getTranslation) => {
      this.updatePlaceholderCaption(getTranslation);
    });

    const level: number = node.attrs.level;
    const container = document.createElement(`h${level}`);

    this.dom = container;
    this.contentDOM = container;

    const getTranslation = this.languageObserver.getTranslation;

    this.updateId(node);
    this.updateAlignment(node);
    this.updateIndentation(node);
    this.updatePlaceholderCaption(getTranslation);
    this.setStylesForNode(node);
  }

  update(node: Node<S>): boolean {
    if (node.type !== this.node.type) {
      return false;
    }

    this.node = node;

    const getTranslation = this.languageObserver.getTranslation;

    this.updateId(node);
    this.updateAlignment(node);
    this.updateIndentation(node);
    this.updatePlaceholderCaption(getTranslation);
    this.setStylesForNode(node);

    return true;
  }

  ignoreMutation(
    mutation:
      | MutationRecord
      | {
          type: "selection";
          target: Element;
        }
  ): boolean {
    if (
      mutation.target === this.dom &&
      mutation.type === "attributes" &&
      mutation.attributeName === "data-placeholder"
    ) {
      return true;
    }

    return false;
  }

  destroy() {
    this.languageObserver.destroy();
  }

  private updateId(node: Node<S>): void {
    this.dom.id = node.attrs.id;
  }

  private updateAlignment(node: Node<S>): void {
    if (node.attrs.alignment != null) {
      this.dom.style.textAlign = node.attrs.alignment;
    } else {
      this.dom.style.textAlign = "";
    }
  }

  private updateIndentation(node: Node<S>): void {
    if (node.attrs.indentation != null) {
      this.dom.style.marginLeft = `${getIndentationPX(
        node.attrs.indentation
      )}px`;
    } else {
      this.dom.style.marginLeft = "";
    }
  }

  private updatePlaceholderCaption(getTranslation: GetTranslationFn): void {
    this.dom.setAttribute(
      "data-placeholder",
      getTranslation("QUESTION_TITLE.PLACEHOLDER")
    );
  }

  private setStylesForNode(node: Node<S>): void {
    const heading = this.headings.get(node.attrs.level);
    const textFont = heading?.properties.textFont;
    const textSize = heading?.properties.textSize;

    this.dom.style.removeProperty("--styles-font-family");
    this.dom.style.removeProperty("--styles-font-size");

    if (textFont != null) {
      this.dom.style.setProperty("--styles-font-family", `${textFont.css}`);
    }

    if (textSize != null) {
      this.dom.style.setProperty(
        "--styles-font-size",
        `${pointsToPixels(textSize)}px`
      );
    }
  }
}
