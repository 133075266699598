import { chainCommands, exitCode } from "prosemirror-commands";
import { NodeSpec, Schema } from "prosemirror-model";
import {
  BASE_PRIORITY,
  CommandFn,
  DocumentBuilders,
  Extension,
  KeyMap,
  NodeConfig
} from "../../editor";
import { canInsert } from "../../util";

class HardBreakNode implements NodeConfig {
  get name(): string {
    return "hardBreak";
  }

  get spec(): NodeSpec {
    return {
      inline: true,
      selectable: false,
      parseDOM: [{ tag: "br" }],
      toDOM: () => ["br"]
    };
  }

  get builders(): DocumentBuilders {
    return {
      br: { nodeType: "hardBreak" }
    };
  }
}

type HardBreakSchema = Schema<"hardBreak", any>;

export class HardBreak implements Extension<HardBreakSchema> {
  get name(): string {
    return "hardBreak";
  }

  get nodes(): NodeConfig[] {
    return [new HardBreakNode()];
  }

  get priority(): number {
    return BASE_PRIORITY - 1;
  }

  keymaps(schema: HardBreakSchema): KeyMap<HardBreakSchema> {
    return {
      "Shift-Enter": this.createHardBreak(schema)
    };
  }

  private createHardBreak(schema: HardBreakSchema): CommandFn<HardBreakSchema> {
    return chainCommands(exitCode, (state, dispatch) => {
      if (dispatch) {
        const canInsertBreak = canInsert(schema.nodes.hardBreak)(state);
        if (!canInsertBreak) {
          return false;
        }

        const node = schema.nodes.hardBreak.create();
        dispatch(state.tr.replaceSelectionWith(node).scrollIntoView());
      }
      return true;
    });
  }
}
