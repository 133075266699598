import {
  Command,
  Editor as BlueXEditor,
  LinkKey,
  Schema
} from "@blue-x/editor";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ToolbarDropdown, {
  ToolbarDropdownToggle,
  useToolbarDropdown
} from "../ToolbarDropdown";
import ToolbarIcon from "../ToolbarIcon";
import ToolbarTooltip from "../ToolbarTooltip";
import "./File.css";

interface FileDefinitionProps {
  command: Command;
  display?: string;
}

const FileDefinition: React.FC<FileDefinitionProps> = (props) => {
  const { command, display } = props;
  const dropdown = useToolbarDropdown();

  const [file, setFile] = useState<File>();
  const [isUploading, setIsUpload] = useState<boolean>(false);

  const startUpload = useCallback(() => {
    if (file != null) {
      setIsUpload(true);

      const formData = new FormData();
      formData.set("file", file);

      fetch(
        "https://api.gateway.dev.bluexsurveydev.com/qdef/v1/Questionnaires/Shared/Files",
        { method: "POST", body: formData }
      )
        .then((response) => {
          return response.json();
        })
        .then((json: FileUploadResponse) => {
          if (display == null || display.length === 0) {
            command.execute({ url: json.link, text: file.name });
          } else {
            command.execute({ url: json.link, text: display });
          }
          setIsUpload(false);
          const { opened, setOpened } = dropdown;
          setOpened(!opened);
        })
        .catch((reason) => {
          alert(reason);
          setIsUpload(false);
          const { opened, setOpened } = dropdown;
          setOpened(!opened);
        });
    }
  }, [file, setIsUpload, command, display, dropdown]);

  useEffect(() => {});

  const filePicked = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (files != null && files.length > 0) {
        const file = files[0];
        setFile(file);
      }
    },
    [setFile]
  );

  const insertDisabled = useMemo(() => {
    return file == null || isUploading;
  }, [file, isUploading]);

  return (
    <div className="FileDefinition">
      <input type="file" onChange={filePicked} disabled={isUploading}></input>
      <button onClick={startUpload} disabled={insertDisabled}>
        Insert
      </button>
      {isUploading ? (
        <i className={`bx bx-spinner-line bx-fw bx-pulse`}></i>
      ) : null}
    </div>
  );
};

interface ToolbarFileButtonProps {
  editor: BlueXEditor<Schema>;
}

export const ToolbarFileButton: React.FC<ToolbarFileButtonProps> = (props) => {
  const { editor } = props;
  const { commands, state } = editor;

  const linkState = useMemo(() => {
    const rawLinkState = LinkKey.getState(state);
    return rawLinkState != null
      ? rawLinkState
      : { textToDisplay: undefined, textToDisplayDisabled: false };
  }, [state]);

  const textToDisplay = useMemo(() => {
    return linkState.textToDisplay;
  }, [linkState]);

  return (
    <ToolbarDropdown
      toggle={
        <ToolbarTooltip title="File" subtitle="Add a file.">
          <ToolbarDropdownToggle
            active={commands.insertLink?.isActive()}
            disabled={!commands.insertLink?.isEnabled()}
          >
            <ToolbarIcon icon="file" />
          </ToolbarDropdownToggle>
        </ToolbarTooltip>
      }
      content={
        <FileDefinition command={commands.insertLink} display={textToDisplay} />
      }
    />
  );
};

interface FileUploadResponse {
  id: string;
  link: string;
}
