import { Fragment, Node, NodeType, Schema, Slice } from "prosemirror-model";
import { EditorState, Plugin, Selection, Transaction } from "prosemirror-state";
import {
  CellSelection,
  isInTable,
  Rect,
  removeColSpan,
  selectionCell,
  TableMap
} from "prosemirror-tables";
import { Transform } from "prosemirror-transform";
import { EditorView } from "prosemirror-view";
import { CellAttributes } from "../nodes";
import { CustomGridSchema } from "../schema";
import { setAttr } from "../util";

interface Cells<S extends Schema> {
  height: number;
  width: number;
  rows: Fragment<S>[];
}

// : (Schema, [Fragment]) → {width: number, height: number, rows: [Fragment]}
// Compute the width and height of a set of cells, and make sure each
// row has the same number of cells.
function ensureRectangular<S extends Schema>(
  schema: S,
  rows: Fragment<S>[]
): Cells<S> {
  let widths = new Array<number>();
  for (let i = 0; i < rows.length; i++) {
    let row = rows[i];
    for (let j = row.childCount - 1; j >= 0; j--) {
      let { rowspan, colspan } = row.child(j).attrs as CellAttributes;
      for (let r = i; r < i + rowspan; r++)
        widths[r] = (widths[r] || 0) + colspan;
    }
  }
  let width = 0;
  for (let r = 0; r < widths.length; r++) width = Math.max(width, widths[r]);
  for (let r = 0; r < widths.length; r++) {
    if (r >= rows.length) rows.push(Fragment.empty);
    if (widths[r] < width) {
      let empty = (schema.nodes.tableCell as NodeType<S>).createAndFill()!,
        cells = new Array<Node<S>>();
      for (let i = widths[r]; i < width; i++) cells.push(empty);
      rows[r] = rows[r].append(Fragment.from(cells));
    }
  }
  return { height: rows.length, width, rows };
}

function fitSlice<S extends Schema>(
  nodeType: NodeType<S>,
  slice: Slice<S>
): Node<S> {
  let node = nodeType.createAndFill()!;
  let tr = new Transform(node).replace(0, node.content.size, slice);
  return tr.doc;
}

function isTableNode<S extends Schema>(
  node: Node<S>,
  roles: string[]
): boolean {
  return roles.includes(node.type.spec.tableRole);
}

// : (Slice) → ?{width: number, height: number, rows: [Fragment]}
// Get a rectangular area of cells from a slice, or null if the outer
// nodes of the slice aren't table cells or rows.
function pastedCells<S extends Schema>(
  slice: Slice<S>,
  schema: S
): Cells<S> | null {
  const rows = new Array<Fragment<S>>();

  let previous: Node<S> | undefined;
  slice.content.forEach((node) => {
    if (isTableNode(node, ["table"])) {
      node.content.forEach((node) => {
        rows.push(node.content);
      });
    } else if (isTableNode(node, ["row"])) {
      rows.push(node.content);
    } else if (isTableNode(node, ["cell", "header_cell"])) {
      if (
        previous != null &&
        isTableNode(previous, ["cell", "header_cell"]) &&
        rows.length > 0
      ) {
        const temp = rows[rows.length - 1];
        rows[rows.length - 1] = temp.append(Fragment.from(node));
      } else {
        rows.push(Fragment.from(node));
      }
    }

    previous = node;
  });

  if (rows.length === 0) {
    return null;
  }

  const rect = ensureRectangular(schema, rows);

  if (rect.height === 0 || rect.width === 0) {
    return null;
  } else {
    return rect;
  }
}

// : ({width: number, height: number, rows: [Fragment]}, number, number) → {width: number, height: number, rows: [Fragment]}
// Clip or extend (repeat) the given set of cells to cover the given
// width and height. Will clip rowspan/colspan cells at the edges when
// they stick out.
function clipCells<S extends Schema>(
  { width, height, rows }: Cells<S>,
  newWidth: number,
  newHeight: number
) {
  if (width !== newWidth) {
    let added = new Array<number>(),
      newRows = new Array<Fragment<S>>();
    for (let row = 0; row < rows.length; row++) {
      let frag = rows[row],
        cells = [];
      for (let col = added[row] || 0, i = 0; col < newWidth; i++) {
        let cell = frag.child(i % frag.childCount);
        if (col + cell.attrs.colspan > newWidth)
          cell = cell.type.create(
            removeColSpan(
              cell.attrs,
              cell.attrs.colspan,
              col + cell.attrs.colspan - newWidth
            ),
            cell.content
          );
        cells.push(cell);
        col += cell.attrs.colspan;
        for (let j = 1; j < cell.attrs.rowspan; j++)
          added[row + j] = (added[row + j] || 0) + cell.attrs.colspan;
      }
      newRows.push(Fragment.from(cells));
    }
    rows = newRows;
    width = newWidth;
  }

  if (height !== newHeight) {
    let newRows = [];
    for (let row = 0, i = 0; row < newHeight; row++, i++) {
      let cells = [],
        source = rows[i % height];
      for (let j = 0; j < source.childCount; j++) {
        let cell = source.child(j);
        if (row + cell.attrs.rowspan > newHeight)
          cell = cell.type.create(
            setAttr(
              cell.attrs,
              "rowspan",
              Math.max(1, newHeight - cell.attrs.rowspan)
            ),
            cell.content
          );
        cells.push(cell);
      }
      newRows.push(Fragment.from(cells));
    }
    rows = newRows;
    height = newHeight;
  }

  return { width, height, rows };
}

// Insert the given set of cells (as returned by `pastedCells`) into a
// table, at the position pointed at by rect.
function insertCells<S extends Schema>(
  state: EditorState<S>,
  dispatch: (tr: Transaction<S>) => void,
  tableStart: number,
  rect: Rect,
  cells: Cells<S>
) {
  let table = tableStart ? state.doc.nodeAt(tableStart - 1)! : state.doc,
    map = TableMap.get(table);
  let { top, left } = rect;
  let right = left + cells.width - 1,
    bottom = top + cells.height - 1;
  let tr = state.tr,
    mapFrom = 0;

  let selection: CellSelection<S>;

  if (state.selection instanceof CellSelection) {
    selection = state.selection;
  } else {
    selection = new CellSelection(
      tr.doc.resolve(tableStart + map.positionAt(top, left, table)),
      tr.doc.resolve(
        tableStart +
          map.positionAt(
            Math.min(bottom, map.height - 1),
            Math.min(right, map.width - 1),
            table
          )
      )
    );

    tr = tr.setSelection((selection as unknown) as Selection<S>);
  }

  selection.forEachCell((cell, pos) => {
    const rect = map.findCell(pos - tableStart);
    const rowIndex = (rect.top - top) % cells.height;
    const cellIndex = (rect.left - left) % cells.width;

    const fragment = cells.rows[rowIndex];
    const child = fragment?.maybeChild(cellIndex);

    if (child != null) {
      tr.replace(
        tr.mapping.slice(mapFrom).map(pos + 1),
        tr.mapping.slice(mapFrom).map(pos + cell.nodeSize - 1),
        new Slice(child.content, 0, 0)
      );
    }
  });

  dispatch(tr);
}

function handlePaste<S extends Schema>(
  view: EditorView,
  _event: ClipboardEvent,
  slice: Slice<S>
): boolean {
  if (!isInTable(view.state)) {
    return false;
  }

  let cells = pastedCells(slice, view.state.schema),
    sel = view.state.selection;

  if (sel instanceof CellSelection) {
    if (!cells)
      cells = {
        width: 1,
        height: 1,
        rows: [
          Fragment.from(fitSlice(view.state.schema.nodes.tableCell, slice))
        ]
      };
    let table = sel.$anchorCell.node(-1),
      start = sel.$anchorCell.start(-1);
    let rect = TableMap.get(table).rectBetween(
      sel.$anchorCell.pos - start,
      sel.$headCell.pos - start
    );
    cells = clipCells(cells, rect.right - rect.left, rect.bottom - rect.top);
    insertCells(view.state, view.dispatch, start, rect, cells);
    return true;
  } else if (cells && !(cells.height === 1 && cells.width === 1)) {
    let $cell = selectionCell(view.state)!,
      start = $cell.start(-1);
    insertCells(
      view.state,
      view.dispatch,
      start,
      TableMap.get($cell.node(-1)).findCell($cell.pos - start),
      cells
    );
    return true;
  } else {
    return false;
  }
}

export function pastePlugin(_schema: CustomGridSchema) {
  return new Plugin<null, CustomGridSchema>({
    props: {
      handlePaste(view, event, slice) {
        if (handlePaste(view, event, slice)) {
          return true;
        }

        return false;
      }
    }
  });
}
