import { NodeSelection, Plugin, PluginKey } from "prosemirror-state";

export type SelectionChangeHandler = (
  anchorPos: number,
  headPos: number
) => void;

export class SelectionState {
  private changeHandlers: SelectionChangeHandler[] = [];

  constructor() {
    this.changeHandlers = [];
  }

  subscribe(cb: SelectionChangeHandler) {
    this.changeHandlers.push(cb);
  }

  unsubscribe(cb: SelectionChangeHandler) {
    this.changeHandlers = this.changeHandlers.filter((ch) => ch !== cb);
  }

  notifyNewSelection(anchorPos: number, headPos: number) {
    this.changeHandlers.forEach((cb) => cb(anchorPos, headPos));
  }
}

export const selectionChangeKey = new PluginKey<SelectionState>(
  "selectionChange"
);

export const selectionChange = new Plugin<SelectionState>({
  key: selectionChangeKey,
  state: {
    init() {
      return new SelectionState();
    },
    apply(tr, state) {
      if (!tr.docChanged) {
        const { $anchor, $head } = tr.selection;
        if (tr.selection instanceof NodeSelection) {
          return state;
        }

        state.notifyNewSelection($anchor.pos, $head.pos);
      }

      return state;
    }
  }
});
