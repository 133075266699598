import {
  Editor as BlueXEditor,
  QuestionTitleActiveValue,
  Schema
} from "@blue-x/editor";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import "./ProportiesPanelControls.css";

export interface SelectItem {
  value: string;
  label: string;
}

export const TextFormControl: React.FC<{
  value: string | null;
  label?: string;
  disabled?: boolean;
  className?: string;
  placeHolder?: string;
  onUpdate: (value: string) => void;
}> = (props) => {
  const { label, value, disabled, className, placeHolder, onUpdate } = props;

  const [state, setState] = useState<string>("");

  useEffect(() => {
    setState(value == null ? "" : value);
  }, [value, setState]);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setState(value);
    },
    [setState]
  );

  const onBlur = useCallback(() => {
    onUpdate(state);
  }, [state, onUpdate]);

  const name = useMemo(() => {
    return label?.toLowerCase();
  }, [label]);

  const classNames = useMemo(() => {
    return ["PropertiesPanel-FormControl", className]
      .filter((x) => x != null)
      .join(" ");
  }, [className]);

  return (
    <div className={classNames}>
      {name != null ? <label htmlFor={name}>{label}</label> : null}
      <input
        name={name}
        type="text"
        value={state}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeHolder}
        disabled={disabled}
      />
    </div>
  );
};

export const NumberFormControl: React.FC<{
  value: number | null;
  label?: string;
  disabled?: boolean;
  min?: number;
  max?: number;
  step?: number;
  className?: string;
  onUpdate: (value: number | null) => void;
}> = (props) => {
  const { label, value, disabled, onUpdate, min, max, step, className } = props;

  const [state, setState] = useState<number | "">("");

  useEffect(() => {
    setState(value == null ? "" : value);
  }, [value, setState]);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.valueAsNumber;
      if (!isNaN(value)) {
        if (min != null && max != null) {
          setState(Math.min(Math.max(value, min), max));
        } else if (min != null && max == null) {
          setState(Math.max(value, min));
        } else if (min == null && max != null) {
          setState(Math.min(value, max));
        } else {
          setState(value);
        }
      } else {
        setState("");
      }
    },
    [setState, min, max]
  );

  const onBlur = useCallback(() => {
    onUpdate(state === "" ? null : state);
  }, [state, onUpdate]);

  const name = useMemo(() => {
    return label?.toLowerCase();
  }, [label]);

  const classNames = useMemo(() => {
    return ["PropertiesPanel-FormControl", className]
      .filter((x) => x != null)
      .join(" ");
  }, [className]);

  return (
    <div className={classNames}>
      {name != null ? <label htmlFor={name}>{label}</label> : null}
      <input
        name={name}
        type="number"
        min={min}
        max={max}
        step={step}
        value={state}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      />
    </div>
  );
};

export const SelectFormControl: React.FC<{
  value: string | number | null;
  label?: string;
  disabled?: boolean;
  className?: string;
  onUpdate: (value: string | number) => void;
}> = (props) => {
  const { label, value, disabled, onUpdate, children, className } = props;

  const controlledValue = useMemo(() => {
    return value == null ? "" : value;
  }, [value]);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value;
      onUpdate(value);
    },
    [onUpdate]
  );

  const name = useMemo(() => {
    return label?.toLowerCase();
  }, [label]);

  const classNames = useMemo(() => {
    return ["PropertiesPanel-FormControl", className]
      .filter((x) => x != null)
      .join(" ");
  }, [className]);

  return (
    <div className={classNames}>
      {name != null ? <label htmlFor={name}>{label}</label> : null}
      <select
        name={name}
        value={controlledValue}
        onChange={onChange}
        disabled={disabled}
      >
        {children}
      </select>
    </div>
  );
};

export const MultipleSelectFormControl: React.FC<{
  list: SelectItem[];
  selectedList: SelectItem[];
  className?: string;
  onUpdate(items: SelectItem[]);
}> = (props) => {
  const { list, selectedList, onUpdate, className } = props;

  const onChange = useCallback(
    (event) => {
      onUpdate(event);
    },
    [onUpdate]
  );

  const classNames = useMemo(() => {
    return ["PropertiesPanel-FormControl", className]
      .filter((x) => x != null)
      .join(" ");
  }, [className]);

  return (
    <div className={classNames}>
      <Select
        className="PropertiesPanel-FormControl-Multiple-Select"
        closeMenuOnSelect={false}
        defaultValue={selectedList}
        isMulti
        options={list}
        onChange={onChange}
      ></Select>
    </div>
  );
};

export const CheckboxFormControl: React.FC<{
  value: boolean;
  label?: string;
  disabled?: boolean;
  className?: string;
  onUpdate: (value: boolean) => void;
}> = (props) => {
  const { label, value, disabled, onUpdate, className } = props;

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.checked;
      onUpdate(value);
    },
    [onUpdate]
  );

  const name = useMemo(() => {
    return label?.toLowerCase();
  }, [label]);

  const classNames = useMemo(() => {
    return ["PropertiesPanel-FormControl", className]
      .filter((x) => x != null)
      .join(" ");
  }, [className]);

  return (
    <div className={classNames}>
      {name != null ? <label htmlFor={name}>{label}</label> : null}
      <input
        type="checkbox"
        checked={value}
        name={name}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
};

export const QuestionTitleFormControl: React.FC<{
  editor: BlueXEditor<Schema>;
  value: QuestionTitleActiveValue;
  label?: string;
  className?: string;
}> = (props) => {
  const { editor, value, label, className } = props;

  const buttonClassName = useMemo(() => {
    if (value.bound) {
      return `bx bx-remove-question`;
    } else if (value.isBindingInProgress) {
      return `bx bx-ban`;
    } else {
      return `bx bx-add-question`;
    }
  }, [value]);

  const buttonOnClick = useCallback(() => {
    if (value.bound) {
      editor.commands.unlinkQuestionTitle.execute();
    } else if (value.isBindingInProgress) {
      editor.commands.cancelQuestionTitleBinding.execute();
    } else {
      editor.commands.startQuestionTitleBinding.execute();
    }
  }, [value, editor]);

  const onUpdate = useCallback(
    (value: string) => {
      editor.commands.updateQuestionTitleText.execute({ text: value });
    },
    [editor]
  );

  return (
    <div className="PropertiesPanel-FormControl PropertiesPanel-QuestionTitle">
      <TextFormControl
        label={label}
        value={value.text}
        disabled={value.bound}
        className={className}
        onUpdate={onUpdate}
      ></TextFormControl>
      <button className={buttonClassName} onClick={buttonOnClick}></button>
    </div>
  );
};
