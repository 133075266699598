import { Schema } from "prosemirror-model";

export type InputDateTimeSchema = Schema<"inputDateTime", any>;

export type InputDateTimeFormat = {
  id: string;
  text: string | { key: string };
  format: string;
};

export enum InputDateTimeControlType {
  date = "date",
  time = "time"
}

export enum InputDateTimeWidth {
  default = 25,
  minimum = 25,
  maximum = 100
}

export const inputTimeAvailableFormats: InputDateTimeFormat[] = [
  { id: "HH:mm", text: "HH:mm (24h)", format: "HH:mm" },
  { id: "hh:mm a", text: "hh:mm (AM/PM)", format: "h:mm a" }
];

export const inputDateAvailableFormats: InputDateTimeFormat[] = [
  {
    id: "yyyy-MM-dd",
    text: { key: "INPUT_DATETIME.DATE_FORMATS.YYYY_MM_DD" },
    format: "yyyy-MM-dd"
  },
  {
    id: "yyyy-MM-dd HH:mm",
    text: { key: "INPUT_DATETIME.DATE_FORMATS.YYYY_MM_DD_HH_MM_24H" },
    format: "yyyy-MM-dd HH:mm"
  },
  {
    id: "yyyy-MM-dd hh:mm a",
    text: { key: "INPUT_DATETIME.DATE_FORMATS.YYYY_MM_DD_HH_MM_AM_PM" },
    format: "yyyy-MM-dd h:mm a"
  },
  {
    id: "MM-dd-yyyy",
    text: { key: "INPUT_DATETIME.DATE_FORMATS.MM_DD_YYYY" },
    format: "MM-dd-yyyy"
  },
  {
    id: "MM-dd-yyyy HH:mm",
    text: { key: "INPUT_DATETIME.DATE_FORMATS.MM_DD_YYYY_HH_MM_24H" },
    format: "MM-dd-yyyy HH:mm"
  },
  {
    id: "MM-dd-yyyy hh:mm a",
    text: { key: "INPUT_DATETIME.DATE_FORMATS.MM_DD_YYYY_HH_MM_AM_PM" },
    format: "MM-dd-yyyy h:mm a"
  },
  {
    id: "dd-MM-yyyy",
    text: { key: "INPUT_DATETIME.DATE_FORMATS.DD_MM_YYYY" },
    format: "dd-MM-yyyy"
  },
  {
    id: "dd-MM-yyyy HH:mm",
    text: { key: "INPUT_DATETIME.DATE_FORMATS.DD_MM_YYYY_HH_MM_24H" },
    format: "dd-MM-yyyy HH:mm"
  },
  {
    id: "dd-MM-yyyy hh:mm a",
    text: { key: "INPUT_DATETIME.DATE_FORMATS.DD_MM_YYYY_HH_MM_AM_PM" },
    format: "dd-MM-yyyy h:mm a"
  }
];
