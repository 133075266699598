import { Node } from "prosemirror-model";
import { EditorState } from "prosemirror-state";
import { selectionFocusKey } from "../../editor/plugins/selection-focus";
import { CustomAreaSchema } from "./schema";

export function createCustomArea(
  schema: CustomAreaSchema,
  areas: number
): Node<CustomAreaSchema> {
  const { customArea } = schema.nodes;

  const contents: Node<CustomAreaSchema>[] = [];

  for (let i = 0; i < areas; i++) {
    contents.push(createContentVariant(schema, i === 0, i === 0));
  }

  return customArea.createChecked({}, contents);
}

export function createContentVariant(
  schema: CustomAreaSchema,
  isActive: boolean,
  isDefault: boolean
): Node<CustomAreaSchema> {
  const { contentVariant } = schema.nodes;

  return contentVariant.createAndFill(
    {
      active: isActive,
      default: isDefault
    },
    [
      schema.nodes.paragraph.create(),
      schema.nodes.paragraph.create(),
      schema.nodes.paragraph.create()
    ]
  )!;
}

export function focusedCustomArea(state: EditorState<CustomAreaSchema>) {
  const { schema } = state;

  const focused = selectionFocusKey.getState(state);
  if (focused != null && focused.node.type === schema.nodes.customArea) {
    return focused;
  } else {
    return undefined;
  }
}
