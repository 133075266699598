import { Node, NodeSpec } from "prosemirror-model";
import { DocumentBuilders, NodeConfig } from "../../../editor";

export class RangeMarkerNode implements NodeConfig {
  get name(): string {
    return "rangeMarker";
  }

  get spec(): NodeSpec {
    return {
      group: "inline",
      inline: true,
      attrs: {
        id: { default: null }
      },
      parseDOM: [
        {
          tag: "bx-range-marker",
          getAttrs(node) {
            if (typeof node === "string") {
              return false;
            }
            return getAttrs(node as Element);
          }
        }
      ],
      toDOM(node) {
        return ["bx-range-marker", setAttrs(node)];
      }
    };
  }

  get builders(): DocumentBuilders {
    return { rangeMarker: { nodeType: "rangeMarker" } };
  }
}

function setAttrs(node: Node): { [key: string]: string } {
  return {
    id: node.attrs.id
  };
}

function getAttrs(element: Element): { [key: string]: any } {
  const attrs: {
    id?: string;
  } = {};

  const id = element.getAttribute("id");
  if (id != null) {
    attrs.id = id;
  }

  return attrs;
}
