const SELECTION_OVERLAY_CLASS_NAMES = {
  SelectionOverlay: "ProseMirror-selection-overlay",
  Leading: "ProseMirror-selection-overlay-leading",
  Middle: "ProseMirror-selection-overlay-middle",
  Trailing: "ProseMirror-selection-overlay-trailing"
};

export function isSelectionRemoveButton(target: HTMLElement): boolean {
  const removeButton = target.closest(
    `.${SELECTION_OVERLAY_CLASS_NAMES.SelectionOverlay} .${SELECTION_OVERLAY_CLASS_NAMES.Trailing} button`
  );

  return removeButton != null;
}

export function selectionOverlay(
  className: string,
  range: { id: string; label: string },
  width: number,
  height: number,
  top: number,
  left: number,
  onClick: (id: string) => void,
  content?: HTMLElement
): HTMLElement {
  const { id, label } = range;

  const element = document.createElement("div");
  element.setAttribute("data-range-id", id);
  element.classList.add(SELECTION_OVERLAY_CLASS_NAMES.SelectionOverlay);
  element.classList.add(className);

  element.style.width = `${width + 25 + 25}px`;
  element.style.height = `${height}px`;
  element.style.top = `${top}px`;
  element.style.left = `${left - 25}px`;

  const leading = document.createElement("div");
  leading.classList.add(SELECTION_OVERLAY_CLASS_NAMES.Leading);
  leading.style.width = "25px";

  const leadingText = document.createElement("span");
  leadingText.innerText = label;

  leading.appendChild(leadingText);

  const middle = document.createElement("div");
  middle.classList.add(SELECTION_OVERLAY_CLASS_NAMES.Middle);

  if (content != null) {
    middle.appendChild(content);
  }

  const trailing = document.createElement("div");
  trailing.classList.add(SELECTION_OVERLAY_CLASS_NAMES.Trailing);
  trailing.style.width = "25px";

  const removeButton = document.createElement("button");
  removeButton.classList.add("bx", "bx-action-close");
  removeButton.onmousedown = (event) => {
    event.preventDefault();
  };
  removeButton.onclick = () => onClick(id);

  trailing.appendChild(removeButton);

  element.appendChild(leading);
  element.appendChild(middle);
  element.appendChild(trailing);

  return element;
}
