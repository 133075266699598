import { Node, Schema } from "prosemirror-model";
import { NodeView } from "prosemirror-view";
import { ZeroWidthSpace } from "../../../util";
import { AlignmentType } from "../../alignment";

export const CONTROLS_CLASS = "ProseMirror-scale-labels-control";

export class InputScaleLabelNodeView<S extends Schema> implements NodeView<S> {
  dom: HTMLElement;
  contentDOM: HTMLElement;

  constructor(private node: Node<S>) {
    const container = document.createElement("input-scale-label");

    const control = document.createElement("div");
    control.contentEditable = "false";
    control.appendChild(document.createTextNode(ZeroWidthSpace));
    control.classList.add(CONTROLS_CLASS);

    const content = document.createElement("div");
    content.className = "content";

    container.appendChild(control);
    container.appendChild(content);

    this.dom = container;
    this.contentDOM = content;

    this.updateAlignment(node.attrs.alignment);
  }

  update(node: Node<S>): boolean {
    if (node.type !== this.node.type) {
      return false;
    }

    this.updateAlignment(node.attrs.alignment);

    return true;
  }

  private updateAlignment(alignment: AlignmentType): void {
    if (alignment != null) {
      this.contentDOM.style.textAlign = alignment;
    } else {
      this.contentDOM.style.textAlign = "";
    }
  }
}
