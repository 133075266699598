import { Focus } from "../../../editor/plugins/selection-focus";
import { PluginKey } from "prosemirror-state";

export interface PluginState {
  opened: boolean;
  focused: Focus;
}

export const inputRankNodeViewKey = new PluginKey<PluginState | null>(
  "inputRankNodeView"
);
