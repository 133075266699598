import { Schema } from "prosemirror-model";
import { Plugin, Selection, Transaction } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import {
  CommandConfiguration,
  CommandFn,
  commandIsEnabled
} from "../../extension";

export interface DragEventData {
  event: "insert";
  type: string;
  subType?: string;
}

export function setDropInsertData(
  dataTransfer: DataTransfer,
  data: DragEventData
): void {
  dataTransfer.setData("prosemirror/editor", JSON.stringify(data));
}

function setSelectionFromPosAtCoords<S extends Schema>(
  tr: Transaction<S>,
  posAtCoords: { pos: number; inside: number }
): Transaction<S> {
  const node =
    posAtCoords && posAtCoords.inside >= 0
      ? tr.doc.nodeAt(posAtCoords.inside)
      : null;

  if (node != null && node.type.spec.isolating === true) {
    const $pos = tr.doc.resolve(posAtCoords.pos);
    const end = $pos.end();
    const isAtEnd = end === $pos.pos;
    const dir = isAtEnd ? -1 : 1;

    tr = tr.setSelection(Selection.near(tr.doc.resolve(posAtCoords.pos), dir));
  } else {
    tr = tr.setSelection(Selection.near(tr.doc.resolve(posAtCoords.pos)));
  }
  return tr;
}

export function isEnableAtPos<S extends Schema>(
  command: CommandConfiguration<S, any, any>,
  view: EditorView<S>,
  posAtCoords: { pos: number; inside: number }
): (attrs?: Record<string, any>) => boolean {
  const state = view.state;
  const editable = view.editable;

  let tr = state.tr;
  tr = setSelectionFromPosAtCoords(tr, posAtCoords);

  return commandIsEnabled(command, state.apply(tr), editable);
}

function setSelectionAtPos<S extends Schema>(posAtCoords: {
  pos: number;
  inside: number;
}): CommandFn<S> {
  return (state, dispatch) => {
    if (dispatch) {
      let tr = state.tr;
      tr = setSelectionFromPosAtCoords(tr, posAtCoords);

      dispatch(tr);
    }

    return true;
  };
}

export function executeAtPos<S extends Schema>(
  command: CommandFn<S>,
  view: EditorView<S>,
  posAtCoords: { pos: number; inside: number }
): boolean {
  view.focus();
  setSelectionAtPos<S>(posAtCoords)(view.state, view.dispatch);
  command(view.state, view.dispatch);

  return true;
}

export function dropInsertPlugin<S extends Schema>(
  execute: (
    view: EditorView<S>,
    data: DragEventData,
    posAtCoords: { pos: number; inside: number }
  ) => boolean
) {
  return new Plugin<null, S>({
    props: {
      handleDOMEvents: {
        drop(view, event) {
          const dragEvent = event as DragEvent;
          const { dataTransfer } = dragEvent;

          if (dataTransfer == null) {
            return false;
          }

          const isEditorDrag = dataTransfer.types.includes(
            "prosemirror/editor"
          );

          if (!isEditorDrag) {
            return false;
          }

          const data = JSON.parse(
            dataTransfer.getData("prosemirror/editor")
          ) as DragEventData;

          if (data.event !== "insert") {
            return false;
          }

          const posAtCoords = view.posAtCoords({
            left: event.clientX,
            top: event.clientY
          });

          if (posAtCoords != null) {
            return execute(view, data, posAtCoords);
          }

          return false;
        }
      }
    }
  });
}
