import { Node, Schema } from "prosemirror-model";
import { EditorState, Plugin } from "prosemirror-state";
import {
  CommandConfiguration,
  CommandConfigurations,
  CommandFn,
  Extension,
  NodeConfig
} from "../../editor";
import {
  dropInsertPlugin,
  executeAtPos,
  isEnableAtPos
} from "../../editor/plugins/drop-insert-plugin";
import { emitNotification } from "../../editor/plugins/notification";
import { selectionFocusKey } from "../../editor/plugins/selection-focus";
import { getIdGenerator } from "../../extensions/node-identifier";
import { canInsert, executeSequence, isEqual, isMac } from "../../util";
import { insertBlock } from "../../util/transforms";
import { getTranslation } from "../localization";
import { nodeViewPlugin } from "./node-views";
import {
  EnableNextButton,
  EnablePreviousButton,
  NextPageBreakButtonNode,
  PageBreakNode,
  PreviousPageBreakButtonNode,
  SubmitPageBreakButtonNode
} from "./nodes";
import { autoClosingPageBreak, skipHiddenPageBreakButtons } from "./plugins";
import { PageBreakSchema } from "./schema";
import {
  createPageBreak,
  getPageBreakNodes,
  PageBreakNotifications
} from "./util";

export interface PageBreakActiveValue {
  enableNext: EnableNextButton;
  enableNextMinimum: number | null;
  enablePrevious: EnablePreviousButton;
}

export interface UpdatePageBreakCommandProps {
  enableNext?: EnableNextButton;
  enableNextMinimum?: number | null;
  enablePrevious?: EnablePreviousButton;
}

export class PageBreak implements Extension<PageBreakSchema> {
  get name(): string {
    return "pageBreak";
  }

  get nodes(): NodeConfig[] {
    return [
      new PageBreakNode(),
      new PreviousPageBreakButtonNode(),
      new NextPageBreakButtonNode(),
      new SubmitPageBreakButtonNode()
    ];
  }

  plugins(schema: PageBreakSchema): Plugin[] {
    return [
      nodeViewPlugin(),
      autoClosingPageBreak(),
      skipHiddenPageBreakButtons(),
      dropInsertPlugin((view, data, posAtCoords) => {
        if (data.type !== "pageBreak") {
          return false;
        }

        const command = this.insertPageBreakCommand(schema);

        const isEnabled = isEnableAtPos(command, view, posAtCoords);
        if (!isEnabled()) {
          emitNotification(view.state, {
            type: "warning",
            message: "drop.insert.invalid-location"
          });
        } else {
          executeAtPos(command.execute(), view, posAtCoords);
        }

        return true;
      })
    ];
  }

  commands(schema: PageBreakSchema): CommandConfigurations<PageBreakSchema> {
    return {
      insertPageBreak: this.insertPageBreakCommand(schema),
      updatePageBreak: this.updatePageBreakCommand(schema),
      bulkUpdatePageBreak: this.bulkUpdatePageBreakCommand(schema)
    };
  }

  private insertPageBreakCommand(
    schema: PageBreakSchema
  ): CommandConfiguration<PageBreakSchema, {}, null> {
    return {
      isActive: () => {
        return false;
      },
      isEnabled: () => {
        return insertPageBreak(schema);
      },
      execute: () => {
        return insertPageBreak(schema);
      },
      shortcuts: { [isMac() ? "Ctrl-p" : "Alt-p"]: undefined }
    };
  }

  private updatePageBreakCommand(
    _schema: PageBreakSchema
  ): CommandConfiguration<
    PageBreakSchema,
    UpdatePageBreakCommandProps,
    PageBreakActiveValue | undefined
  > {
    return {
      isActive: () => {
        return (state) => {
          return focusedPageBreak(state) != null;
        };
      },
      isEnabled: () => {
        return (state) => {
          return focusedPageBreak(state) != null;
        };
      },
      execute: (props) => {
        if (props == null) {
          throw new Error(
            `To update a page break, UpdatePageBreakCommandProps needs to be provided.`
          );
        }

        return (state, dispatch) => {
          const focused = focusedPageBreak(state);
          return updatePageBreak(props, focused)(state, dispatch);
        };
      },
      activeValue: () => {
        return (state) => {
          const focused = focusedPageBreak(state);
          if (!focused) {
            return undefined;
          }

          return {
            enableNext: focused.node.attrs.enableNext,
            enableNextMinimum: focused.node.attrs.enableNextMinimum,
            enablePrevious: focused.node.attrs.enablePrevious
          };
        };
      }
    };
  }

  private bulkUpdatePageBreakCommand(
    _schema: PageBreakSchema
  ): CommandConfiguration<PageBreakSchema, {}, undefined> {
    return {
      isActive: () => {
        return (state) => {
          return focusedPageBreak(state) != null;
        };
      },
      isEnabled: () => {
        return (state) => {
          return focusedPageBreak(state) != null;
        };
      },
      execute: () => {
        return (state, dispatch) => {
          const focused = focusedPageBreak(state);
          if (focused == null) {
            return false;
          }

          const { nodes } = getPageBreakNodes()(state);

          const updates = nodes.reduce((acc, elem) => {
            const updateAttrs = (
              nodeAttrs: Record<string, any>
            ): UpdatePageBreakCommandProps => {
              return {
                enableNext: nodeAttrs.enableNext,
                enableNextMinimum: nodeAttrs.enableNextMinimum,
                enablePrevious: nodeAttrs.enablePrevious
              };
            };

            const currentAttrs = updateAttrs(elem.node.attrs);
            const toUpdateAttrs = updateAttrs(focused.node.attrs);

            if (isEqual(currentAttrs, toUpdateAttrs)) {
              return acc;
            } else {
              return acc.concat(updatePageBreak(toUpdateAttrs, elem));
            }
          }, new Array<CommandFn<PageBreakSchema>>());

          if (updates.length > 0) {
            executeSequence(...updates)(state, dispatch);

            emitNotification(state, {
              type: "success",
              message:
                updates.length === 1
                  ? PageBreakNotifications.UpdateSuccessSingular
                  : PageBreakNotifications.UpdateSuccessPlural,
              params: { updated: updates.length }
            });
          } else {
            emitNotification(state, {
              type: "warning",
              message: PageBreakNotifications.NoUpdate
            });
          }

          return true;
        };
      }
    };
  }
}

function insertPageBreak(schema: PageBreakSchema): CommandFn<PageBreakSchema> {
  return (state, dispatch) => {
    if (!canInsert(schema.nodes.pageBreak)(state)) {
      return false;
    } else {
      if (dispatch) {
        const pageBreak = createPageBreak(schema, getTranslation(state));

        if (pageBreak == null) {
          return false;
        }

        let tr = state.tr;
        tr = insertBlock(
          tr,
          state.schema,
          pageBreak,
          false,
          getIdGenerator(state)
        );

        dispatch(tr);
      }
      return true;
    }
  };
}

function focusedPageBreak(state: EditorState<PageBreakSchema>) {
  const { schema } = state;

  const focused = selectionFocusKey.getState(state);
  if (focused != null && focused.node.type === schema.nodes.pageBreak) {
    return focused;
  } else {
    return undefined;
  }
}

function updatePageBreak<S extends Schema>(
  props: UpdatePageBreakCommandProps,
  pageBreak: { node: Node<S>; pos: number } | undefined
): CommandFn<S> {
  return (state, dispatch) => {
    if (pageBreak == null) {
      return false;
    }

    if (dispatch) {
      const { node, pos } = pageBreak;
      let updatedAttrs = { ...node.attrs } as PageBreakActiveValue;

      if (props.enableNext !== undefined) {
        updatedAttrs = { ...updatedAttrs, enableNext: props.enableNext };
      }

      if (props.enableNextMinimum !== undefined) {
        const enableNextMinimum =
          props.enableNextMinimum == null
            ? null
            : Math.round(props.enableNextMinimum);
        if (
          enableNextMinimum != null &&
          !isNaN(enableNextMinimum) &&
          enableNextMinimum >= 0
        ) {
          updatedAttrs = {
            ...updatedAttrs,
            enableNextMinimum: enableNextMinimum
          };
        } else {
          updatedAttrs = {
            ...updatedAttrs,
            enableNextMinimum: null
          };
        }
      }

      if (props.enablePrevious !== undefined) {
        updatedAttrs = {
          ...updatedAttrs,
          enablePrevious: props.enablePrevious
        };
      }

      let tr = state.tr;
      tr = tr.setNodeMarkup(pos, undefined, updatedAttrs);

      dispatch(tr);
    }
    return true;
  };
}
