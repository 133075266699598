import { Node, Schema } from "prosemirror-model";
import { Decoration, EditorView, NodeView } from "prosemirror-view";
import { Editor } from "../../../editor";
import {
  ActionControlButton,
  ActionControls
} from "../../../editor/plugins/action-controls";
import { scrollTrackingKey } from "../../../editor/plugins/scroll-tracking";
import { selectionFocusKey } from "../../../editor/plugins/selection-focus";
import { ValidationMessagesNodeView } from "../../../editor/plugins/validation-messages";
import { WidthResizerNodeView } from "../../../editor/plugins/width-resizer";
import { scrollParent, ZeroWidthSpace } from "../../../util";
import { UnreachableCaseError } from "../../../util/unreachable-error";
import { AlignmentType } from "../../alignment";
import { GetTranslationFn, LanguageObserver } from "../../localization";
import { QuestionTitleBindingButton } from "../../question-title";
import { InputChoiceControlType, InputChoiceLabelPosition } from "../schema";

export class InputChoiceNodeView<S extends Schema> implements NodeView<S> {
  dom: HTMLElement;
  contentDOM: HTMLElement;

  private languageObserver: LanguageObserver<S>;

  private inputContainer: HTMLElement;
  private onScroll: () => void;

  private resizer: WidthResizerNodeView<S>;
  private validationMessages: ValidationMessagesNodeView;
  private actionControls: ActionControls;
  private requiredButton: ActionControlButton;
  private questionTitleBindingButton: QuestionTitleBindingButton<S>;

  constructor(
    private node: Node<S>,
    private view: EditorView<S>,
    getPos: () => number,
    decorations: Decoration[]
  ) {
    this.languageObserver = new LanguageObserver(view, (getTranslation) => {
      this.updateRequired(this.node.attrs.required, getTranslation);
    });

    const container = document.createElement("input-choice");

    this.resizer = new WidthResizerNodeView(
      container,
      node,
      view,
      getPos,
      25,
      100,
      (width) => {
        const editor = view as Editor<S>;
        editor.commands.updateInputChoice.execute({
          width: width
        });
      }
    );

    this.validationMessages = new ValidationMessagesNodeView();

    container.appendChild(this.validationMessages.dom);

    const requiredButton = new ActionControlButton(
      view,
      { icon: "asterisk", title: "ACTION_BUTTONS.REQUIRED.TITLE" },
      false,
      () => {
        const editor = view as Editor<S>;
        const focused = selectionFocusKey.getState(editor.state);

        if (focused != null && focused.node.type === this.node.type) {
          const { node } = focused;
          const required = !node.attrs.required;
          editor.commands.updateInputChoice.execute({ required: required });
        }
      }
    );

    const questionTitleBindingButton = new QuestionTitleBindingButton(
      view,
      node,
      decorations
    );

    const actionControls = new ActionControls([
      requiredButton,
      questionTitleBindingButton
    ]);
    container.appendChild(actionControls.dom);

    const values = document.createElement("input-choice-values");

    container.append(values);

    this.dom = container;
    this.contentDOM = values;
    this.inputContainer = this.renderControlType(node.attrs.controlType);
    this.actionControls = actionControls;
    this.requiredButton = requiredButton;
    this.questionTitleBindingButton = questionTitleBindingButton;

    const getTranslation = this.languageObserver.getTranslation;

    this.updateChoiceControlType(node);
    this.updateId(node);
    this.updateLabelPosition(node.attrs.labelPosition);
    this.updateMaximunHeight(node.attrs.rows, node.attrs.controlType);
    this.updateRequired(node.attrs.required, getTranslation);
    this.updateAlignment(node.attrs.alignment);
    this.updateQuestionTitle(node, decorations);
    this.updateChoiceSelectionRangeMax(node.attrs.choiceSelectionRangeMax);
    this.setDropdownToggleText(node);
    this.updateDropdownPosition(node.attrs.controlType);

    this.onScroll = () => {
      this.updateDropdownPosition(node.attrs.controlType);
    };

    const scrollTracking = scrollTrackingKey.getState(view.state);
    scrollTracking?.subscribe(this.onScroll);
  }

  update(node: Node<S>, decorations: Decoration[]): boolean {
    if (node.type !== this.node.type) {
      return false;
    }

    const controlTypeHasChanged =
      this.node.attrs.controlType !== node.attrs.controlType;

    this.node = node;

    const getTranslation = this.languageObserver.getTranslation;

    this.resizer.update(node);

    if (controlTypeHasChanged) {
      this.inputContainer = this.renderControlType(node.attrs.controlType);
    }

    this.updateChoiceControlType(node);
    this.updateId(node);
    this.updateLabelPosition(node.attrs.labelPosition);
    this.updateMaximunHeight(node.attrs.rows, node.attrs.controlType);
    this.updateRequired(node.attrs.required, getTranslation);
    this.updateAlignment(node.attrs.alignment);
    this.updateQuestionTitle(node, decorations);
    this.updateChoiceSelectionRangeMax(node.attrs.choiceSelectionRangeMax);
    this.setDropdownToggleText(node);

    if (controlTypeHasChanged) {
      this.updateDropdownPosition(node.attrs.controlType);
    }

    return true;
  }

  ignoreMutation(
    mutation:
      | MutationRecord
      | {
          type: "selection";
          target: Element;
        }
  ): boolean {
    const resizeIgnoreMutation = this.resizer.ignoreMutation(mutation);
    const validationMessagesIgnoreMutation = this.validationMessages.ignoreMutation(
      mutation
    );
    const requiredButtonIgnoreMutation = this.requiredButton.ignoreMutation(
      mutation
    );
    const questionTitleBindingButtonIgnoreMutation = this.questionTitleBindingButton.ignoreMutation(
      mutation
    );

    const ignoreStyle =
      mutation.type === "attributes" &&
      mutation.attributeName === "style" &&
      mutation.target === this.contentDOM;

    const ignoreDropdownPosition =
      mutation.type === "attributes" &&
      mutation.attributeName === "data-dropdown-position" &&
      mutation.target === this.dom;

    return [
      resizeIgnoreMutation,
      validationMessagesIgnoreMutation,
      requiredButtonIgnoreMutation,
      questionTitleBindingButtonIgnoreMutation,
      ignoreStyle,
      ignoreDropdownPosition
    ].some((x) => x);
  }

  destroy() {
    const scrollTracking = scrollTrackingKey.getState(this.view.state);
    scrollTracking?.unsubscribe(this.onScroll);
    this.languageObserver.destroy();
    this.actionControls.destroy();
  }

  private updateId(node: Node<S>): void {
    this.dom.id = node.attrs.id;
  }

  private updateChoiceControlType(node: Node<S>): void {
    this.dom.setAttribute("data-control-type", node.attrs.controlType);
  }

  private updateLabelPosition(labelPosition: InputChoiceLabelPosition): void {
    switch (labelPosition) {
      case InputChoiceLabelPosition.top:
        this.dom.setAttribute("data-label-position", "top");
        break;
      case InputChoiceLabelPosition.right:
        this.dom.setAttribute("data-label-position", "right");
        break;
      case InputChoiceLabelPosition.bottom:
        this.dom.setAttribute("data-label-position", "bottom");
        break;
      case InputChoiceLabelPosition.boxed:
        this.dom.setAttribute("data-label-position", "boxed");
        break;
      default:
        throw new UnreachableCaseError(labelPosition);
    }
  }

  private updateRequired(
    required: boolean,
    getTranslation: GetTranslationFn
  ): void {
    this.validationMessages.setRequired(required, false, getTranslation);
    this.requiredButton.setActive(required);
  }

  private updateAlignment(alignment: AlignmentType): void {
    if (alignment != null) {
      this.dom.setAttribute("data-alignment", alignment);
    } else {
      this.dom.removeAttribute("data-alignment");
    }
  }

  private updateQuestionTitle(node: Node<S>, decorations: Decoration[]): void {
    this.questionTitleBindingButton.update(node, decorations);
  }

  private setDropdownToggleText(node: Node<S>): void {
    if (node.attrs.controlType !== InputChoiceControlType.dropdown) {
      return;
    }

    const toggle = this.inputContainer.querySelector(
      "input-choice-dropdown-toggle-text"
    );
    if (toggle == null) {
      return;
    }

    let defaultValueLabel: string = "";
    node.content.forEach((child) => {
      if (child.attrs.default) {
        defaultValueLabel = child.textContent;
        return;
      }
    });
    const watermark = node.attrs.watermark;

    if (defaultValueLabel !== "") {
      toggle.innerHTML = defaultValueLabel;
    } else if (watermark != null && watermark !== "") {
      toggle.innerHTML = watermark;
    } else {
      toggle.innerHTML = ZeroWidthSpace;
    }
  }

  private renderControlType(controlType: InputChoiceControlType): HTMLElement {
    const inputContainer = document.createElement("div");
    inputContainer.className = "input-container";

    switch (controlType) {
      case InputChoiceControlType.singleVertical:
      case InputChoiceControlType.singleHorizontal:
      case InputChoiceControlType.multipleVertical:
      case InputChoiceControlType.multipleHorizontal:
      case InputChoiceControlType.listbox:
        inputContainer.appendChild(this.contentDOM);
        break;
      case InputChoiceControlType.dropdown:
        const dropdownToggle = document.createElement(
          "input-choice-dropdown-toggle"
        );
        dropdownToggle.contentEditable = "false";

        const text = document.createElement(
          "input-choice-dropdown-toggle-text"
        );

        const carretButton = document.createElement(
          "input-choice-dropdown-toggle-carret"
        );

        const carret = document.createElement("span");
        carret.className = "bx bx-caret-down";

        carretButton.appendChild(carret);
        dropdownToggle.appendChild(text);
        dropdownToggle.appendChild(carretButton);

        inputContainer.appendChild(dropdownToggle);
        inputContainer.appendChild(this.contentDOM);
        break;
      default:
        throw new UnreachableCaseError(controlType);
    }

    if (this.inputContainer != null) {
      this.dom.replaceChild(inputContainer, this.inputContainer);
    } else {
      this.dom.appendChild(inputContainer);
    }

    return inputContainer;
  }

  private updateDropdownPosition(controlType: InputChoiceControlType): void {
    if (controlType === InputChoiceControlType.dropdown) {
      const position = positionDropdown(this.dom, this.contentDOM);
      this.dom.setAttribute("data-dropdown-position", position);
    } else {
      this.dom.removeAttribute("data-dropdown-position");
    }
  }

  private updateMaximunHeight(
    rows: number,
    controlType: InputChoiceControlType
  ): void {
    const rowHeight = 36;
    switch (controlType) {
      case InputChoiceControlType.singleVertical:
      case InputChoiceControlType.singleHorizontal:
      case InputChoiceControlType.multipleVertical:
      case InputChoiceControlType.multipleHorizontal:
        this.contentDOM.style.maxHeight = "";
        break;
      case InputChoiceControlType.dropdown:
        this.contentDOM.style.maxHeight = `${5 * rowHeight + 2}px`;
        break;
      case InputChoiceControlType.listbox:
        this.contentDOM.style.maxHeight = `${rows * rowHeight + 2}px`;
        break;
      default:
        throw new UnreachableCaseError(controlType);
    }
  }

  private updateChoiceSelectionRangeMax(choiceSelectionRangeMax: string): void {
    this.dom.setAttribute("data-choice-selection-max", choiceSelectionRangeMax);
  }
}

function positionDropdown(
  dom: HTMLElement,
  contentDOM: HTMLElement
): "top" | "bottom" {
  contentDOM.style.zIndex = "-1";
  contentDOM.style.display = "initial";

  const scrollingContainer = scrollParent(dom);

  const scrollingContainerRect = scrollingContainer.getBoundingClientRect();
  const scrollingContainerHeight = scrollingContainerRect.height;

  const domContentRect = dom.getBoundingClientRect();
  const dropdownOffset = 4;

  const dropdownContentRect = contentDOM.getBoundingClientRect();
  const scrollOffset =
    domContentRect.bottom +
    dropdownOffset +
    dropdownContentRect.height -
    scrollingContainerRect.top;

  contentDOM.style.zIndex = "";
  contentDOM.style.display = "";

  if (scrollOffset >= scrollingContainerHeight) {
    return "top";
  } else {
    return "bottom";
  }
}
