import { Schema } from "prosemirror-model";
import { EditorState, Plugin, PluginKey } from "prosemirror-state";
import { EditorNotification } from "../../../util/notification";

type NotificationHandler = (notification: EditorNotification) => void;

const notificationHandlerKey = new PluginKey<NotificationHandler>(
  "notificationHandler"
);

export function notificationHandler(
  callback: NotificationHandler
): Plugin<NotificationHandler> {
  return new Plugin<NotificationHandler>({
    key: notificationHandlerKey,
    state: {
      init() {
        return callback;
      },
      apply(_tr, state) {
        return state;
      }
    }
  });
}

export function emitNotification<S extends Schema>(
  state: EditorState<S>,
  notification: EditorNotification
): void {
  const value = notificationHandlerKey.getState(state);
  if (value != null) {
    value(notification);
  }
}
