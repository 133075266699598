import { Fragment, Node, ResolvedPos, Schema } from "prosemirror-model";
import {
  EditorState,
  NodeSelection,
  Plugin,
  TextSelection,
  Transaction
} from "prosemirror-state";
import { canSplit } from "prosemirror-transform";
import { EditorView } from "prosemirror-view";
import {
  CommandConfiguration,
  CommandConfigurations,
  CommandFn,
  Extension,
  KeyMap,
  NodeConfig
} from "../../editor";
import {
  dropInsertPlugin,
  executeAtPos,
  isEnableAtPos
} from "../../editor/plugins/drop-insert-plugin";
import { emitNotification } from "../../editor/plugins/notification";
import {
  applyQuestionTitle,
  questionTitleCacheKey,
  questionTitleCachePlugin,
  questionTitlePredicate
} from "../../editor/plugins/question-title-cache";
import { Focus } from "../../editor/plugins/selection-focus";
import {
  getTranslation,
  GetTranslationFn
} from "../../extensions/localization/util";
import { getIdGenerator } from "../../extensions/node-identifier";
import { isQuestionTitleAutoCreation } from "../../extensions/question-title/plugins/question-title-autocreation-flag";
import {
  canInsert,
  canInsertAtPos,
  findParentNodeOfType,
  isEmptySelectionAtEnd,
  isEmptySelectionAtStart,
  isFirstChildOfParent,
  isLastChildOfParent,
  isMac,
  UnreachableCaseError
} from "../../util";
import { findNodePos, insertBlock } from "../../util/transforms";
import {
  getQuestionTitleActiveValue,
  QuestionTitleActiveValue,
  updateQuestionTitleText
} from "../question-title";
import { inputRankNodeViewPlugin } from "./node-views/plugins";
import {
  InputRankOptionNode,
  InputRankOptionOtherSpecifyNode,
  InputRankOptionSelectAllNode,
  InputRankOptionsNode,
  InputRankSectionEmptyNode,
  InputRankSectionNode,
  InputRankSectionsNode
} from "./nodes";
import { InputRankNode } from "./nodes/input-rank-node";
import {
  dropPlugin,
  inputRankDropdownPlugin,
  inputRankOptionsPlugin,
  inputRankSelectPlugin,
  inputRankViewKey,
  inputRankViewPlugin,
  pastePlugin
} from "./plugin";
import {
  InputRankControlType,
  InputRankSchema,
  InputRankSectionPosition,
  InputRankWidth
} from "./schema";
import {
  createInputRank,
  createInputRankOptionOtherSpecify,
  createInputRankOptionSelectAll,
  createInputSection,
  focusedInputRank,
  focusedInputRankSection,
  isSpecialRankOption
} from "./util";

interface InsertInputRankCommandProps {
  controlType: InputRankControlType;
}

export interface UpdateInputRankCommandProps {
  controlType?: InputRankControlType;
  width?: number;
  questionTitleText?: string;
  description?: string;
  coding?: string;
  repeatableScale?: boolean;
  repeatableItemSections?: boolean;
  randomizeItems?: boolean;
  randomizeSections?: boolean;
  selectAll?: boolean;
  otherSpecify?: boolean;
  respondentsMustSpecify?: boolean;
  required?: boolean;
  minRankedItem?: number;
  maxRankedItem?: number;
}

export interface InputRankActiveValue {
  controlType: InputRankControlType;
  width: number;
  questionTitle: QuestionTitleActiveValue;
  description: string;
  coding: string;
  repeatableScale: boolean;
  repeatableItemSections: boolean;
  randomizeItems: boolean;
  randomizeSections: boolean;
  selectAll: boolean;
  otherSpecify: boolean;
  respondentsMustSpecify: boolean;
  required: boolean;
  minRankedItem: number;
  maxRankedItem: number;
  sectionCount: number;
  optionCount: number;
}

export interface UpdateInputRankSectionCommandProps {
  position: InputRankSectionPosition;
  focusedSectionNode: Focus;
}

const questionTitleKey = questionTitleCacheKey();

export class InputRank implements Extension<InputRankSchema> {
  get name(): string {
    return "inputRank";
  }

  get nodes(): NodeConfig[] {
    return [
      new InputRankNode(),
      new InputRankOptionsNode(),
      new InputRankSectionsNode(),
      new InputRankOptionNode(),
      new InputRankOptionSelectAllNode(),
      new InputRankOptionOtherSpecifyNode(),
      new InputRankSectionNode(),
      new InputRankSectionEmptyNode()
    ];
  }

  plugins(schema: InputRankSchema): Plugin[] {
    const plugins: Plugin[] = [
      inputRankNodeViewPlugin(),
      inputRankViewPlugin(),
      pastePlugin(schema),
      dropPlugin(schema),
      dropInsertPlugin((view, data, posAtCoords) => {
        if (data.type !== "inputRank") {
          return false;
        }

        let controlType: InputRankControlType;
        switch (data.subType) {
          case InputRankControlType.dragDrop:
            controlType = InputRankControlType.dragDrop;
            break;

          case InputRankControlType.dropdown:
            controlType = InputRankControlType.dropdown;
            break;

          default:
            controlType = InputRankControlType.dragDrop;
            break;
        }

        const command = this.insertInputRankCommand(schema);

        const isEnabled = isEnableAtPos(command, view, posAtCoords);
        if (
          !isEnabled({
            controlType: controlType
          })
        ) {
          emitNotification(view.state, {
            type: "warning",
            message: "drop.insert.invalid-location"
          });
        } else {
          executeAtPos(
            command.execute({
              controlType: controlType
            }),
            view,
            posAtCoords
          );
        }

        return true;
      }),
      questionTitleCachePlugin(
        schema.nodes.inputRank,
        "INPUT_RANKING.DEFAULT_QUESTION_TITLE",
        questionTitlePredicate,
        applyQuestionTitle,
        questionTitleKey
      ),
      inputRankDropdownPlugin(),
      inputRankOptionsPlugin(),
      inputRankSelectPlugin()
    ];

    return plugins;
  }

  commands(schema: InputRankSchema): CommandConfigurations<InputRankSchema> {
    return {
      insertInputRank: this.insertInputRankCommand(schema),
      updateInputRank: this.updateInputRankCommand(schema),
      updateInputRankSection: this.updateInputRankSectionCommand(schema)
    };
  }

  keymaps(_schema: InputRankSchema): KeyMap<InputRankSchema> {
    return {
      Enter: enter,
      Backspace: preventDelete,
      Delete: deleteCommand,
      "Shift-Enter": preventDelete
    };
  }

  private insertInputRankCommand(
    schema: InputRankSchema
  ): CommandConfiguration<
    InputRankSchema,
    InsertInputRankCommandProps,
    undefined
  > {
    return {
      isActive: () => {
        return false;
      },
      isEnabled: () => {
        return insertInputRank(schema, InputRankControlType.dragDrop);
      },
      execute: (props) => {
        if (props == null) {
          throw new Error(
            `To insert an input rank, InsertInputRankCommandProps needs to be provided.`
          );
        }
        const { controlType } = props;

        if (controlType == null) {
          throw new Error(
            `To insert an input rank, control type need to be provided.`
          );
        }
        return insertInputRank(schema, controlType);
      },
      shortcuts: {
        [isMac() ? "Ctrl-r" : "Alt-r"]: {
          controlType: InputRankControlType.dragDrop
        }
      }
    };
  }

  private updateInputRankCommand(
    schema: InputRankSchema
  ): CommandConfiguration<
    InputRankSchema,
    UpdateInputRankCommandProps,
    InputRankActiveValue | undefined
  > {
    return {
      isActive: () => {
        return (state) => {
          return focusedInputRank(state) != null;
        };
      },
      isEnabled: () => {
        return (state) => {
          return focusedInputRank(state) != null;
        };
      },
      execute: (props) => {
        if (props == null) {
          throw new Error(
            `To update an input rank, UpdateInputRankCommandProps needs to be provided.`
          );
        }

        return (state, dispatch) => {
          const inputRank = focusedInputRank(state);

          if (inputRank == null) {
            return false;
          }

          if (dispatch) {
            const { node, pos } = inputRank;
            let currentControlType = node.attrs.controlType;

            let updatedAttrs = { ...node.attrs };

            let tr = state.tr;

            if (props.width != null && !isNaN(props.width)) {
              const updatedWidth = getWidth(
                props.width,
                inputRank.node.child(0).childCount
              );
              updatedAttrs = { ...updatedAttrs, width: updatedWidth };
            }

            if (props.questionTitleText !== undefined) {
              updatedAttrs = updateQuestionTitleText(
                updatedAttrs,
                props.questionTitleText
              );
            }

            if (props.description !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                description: props.description
              };
            }

            if (props.coding !== undefined) {
              updatedAttrs = { ...updatedAttrs, coding: props.coding };
            }

            if (
              props.repeatableScale !== undefined &&
              currentControlType === InputRankControlType.dropdown
            ) {
              updatedAttrs = {
                ...updatedAttrs,
                repeatableScale: props.repeatableScale
              };
            }

            if (props.repeatableItemSections !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                repeatableItemSections: props.repeatableItemSections
              };
            }

            if (props.randomizeItems !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                randomizeItems: props.randomizeItems
              };
            }

            if (props.randomizeSections !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                randomizeSections: props.randomizeSections
              };
            }

            if (
              props.selectAll !== undefined &&
              currentControlType === InputRankControlType.dragDrop
            ) {
              const lastNode = node.child(1).lastChild;

              if (lastNode == null) {
                return false;
              }

              const shouldRemoveRank =
                props.selectAll === false &&
                node.child(1).childCount === 1 &&
                lastNode.type === schema.nodes.inputRankOptionSelectAll;

              if (shouldRemoveRank) {
                let tr = state.tr;

                const $pos = findNodePos(tr.doc, tr.selection.from, lastNode);

                if ($pos == null) {
                  return false;
                }

                tr = tr.replaceRangeWith(
                  $pos.pos,
                  pos + node.nodeSize - 3,
                  schema.nodes.inputRankOption.create() as Node
                );
                tr = tr.setSelection(new NodeSelection(tr.doc.resolve(pos)));

                dispatch(tr);

                return true;
              }

              updatedAttrs = { ...updatedAttrs, selectAll: props.selectAll };

              tr = toggleSelectAll(
                tr,
                schema,
                pos + node.child(0).nodeSize + 1,
                props.selectAll,
                getTranslation(state)
              );
            }

            if (props.otherSpecify !== undefined) {
              const lastNode = node.child(1).lastChild;

              if (lastNode == null) {
                return false;
              }

              const shouldRemoveRank =
                props.otherSpecify === false &&
                node.child(1).childCount === 1 &&
                lastNode.type === schema.nodes.inputRankOptionOtherSpecify;

              if (shouldRemoveRank) {
                let tr = state.tr;

                const $pos = findNodePos(tr.doc, tr.selection.from, lastNode);

                if ($pos == null) {
                  return false;
                }

                tr = tr.replaceRangeWith(
                  $pos.pos,
                  pos + node.nodeSize - 3,
                  schema.nodes.inputRankOption.create() as Node
                );
                tr = tr.setSelection(new NodeSelection(tr.doc.resolve(pos)));
                dispatch(tr);

                return true;
              }
              updatedAttrs = {
                ...updatedAttrs,
                otherSpecify: props.otherSpecify
              };

              tr = toggleOtherSpecify(
                tr,
                schema,
                pos + node.child(0).nodeSize + 1,
                props.otherSpecify,
                getTranslation(state)
              );
            }

            if (props.respondentsMustSpecify !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                respondentsMustSpecify: props.respondentsMustSpecify
              };
            }

            if (props.required !== undefined) {
              updatedAttrs = { ...updatedAttrs, required: props.required };
            }

            if (props.minRankedItem !== undefined) {
              const optionsCount = node.child(1).childCount;
              if (props.minRankedItem > optionsCount) {
                updatedAttrs = {
                  ...updatedAttrs,
                  minRankedItem: optionsCount
                };
              } else if (props.minRankedItem < 0) {
                updatedAttrs = {
                  ...updatedAttrs,
                  minRankedItem: 0
                };
              } else {
                updatedAttrs = {
                  ...updatedAttrs,
                  minRankedItem: props.minRankedItem
                };
              }
            }

            if (props.maxRankedItem !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                maxRankedItem: props.maxRankedItem
              };
            }

            if (props.controlType !== undefined) {
              updatedAttrs = {
                ...updatedAttrs,
                controlType: props.controlType
              };
              currentControlType = props.controlType;

              tr = toggleEmptyNode(
                tr,
                schema,
                pos + 1,
                currentControlType,
                getTranslation(state)
              );
            }

            tr = tr.setNodeMarkup(pos, undefined, updatedAttrs);
            tr = tr.setSelection(new NodeSelection(tr.doc.resolve(pos)));

            dispatch(tr);
          }
          return true;
        };
      },
      activeValue: () => {
        return (state) => {
          let expectedNode;

          const focused = focusedInputRank(state);

          if (!focused) {
            const newfocused = focusedInputRankSection(state);

            const parent = findParentNodeOfType(state.schema.nodes.inputRank)(
              state.selection
            );
            if (newfocused && parent) {
              expectedNode = parent.node;
            } else {
              return undefined;
            }
          } else {
            expectedNode = focused.node;
          }

          if (!expectedNode) {
            return undefined;
          }

          const sectionCount = expectedNode.child(0).childCount;
          const optionCount = expectedNode.child(1).childCount;
          const selectAll = selectAllNode(expectedNode, schema) != null;
          const otherSpecify = otherSpecifyNode(expectedNode, schema) != null;

          return {
            controlType: expectedNode.attrs.controlType,
            width: expectedNode.attrs.width,
            questionTitle: getQuestionTitleActiveValue(state, expectedNode),
            description: expectedNode.attrs.description,
            coding: expectedNode.attrs.coding,
            repeatableScale: expectedNode.attrs.repeatableScale,
            repeatableItemSections: expectedNode.attrs.repeatableItemSections,
            randomizeItems: expectedNode.attrs.randomizeItems,
            randomizeSections: expectedNode.attrs.randomizeSections,
            selectAll: selectAll,
            otherSpecify: otherSpecify,
            respondentsMustSpecify: expectedNode.attrs.respondentsMustSpecify,
            required: expectedNode.attrs.required,
            minRankedItem: expectedNode.attrs.minRankedItem,
            maxRankedItem: expectedNode.attrs.maxRankedItem,
            sectionCount: sectionCount,
            optionCount: optionCount
          };
        };
      }
    };
  }
  private updateInputRankSectionCommand(
    schema: InputRankSchema
  ): CommandConfiguration<
    InputRankSchema,
    UpdateInputRankSectionCommandProps,
    undefined
  > {
    return {
      isActive: () => {
        return (state) => {
          return focusedInputRank(state) != null;
        };
      },
      isEnabled: () => {
        return (state) => {
          return focusedInputRank(state) != null;
        };
      },
      execute: (props) => {
        if (props == null) {
          throw new Error(
            `To update the sections, UpdateInputRankSectionCommandProps needs to be provided.`
          );
        }
        const { position, focusedSectionNode } = props;

        if (position == null) {
          throw new Error(
            `To update the sections, position need to be provided.`
          );
        }

        if (focusedSectionNode == null) {
          throw new Error(
            `To update the sections, the focused section node need to be provided.`
          );
        }

        return (state, dispatch) => {
          const inputRankSection = createInputSection(
            schema,
            false,
            getTranslation(state),
            getIdGenerator(state)
          );
          if (inputRankSection == null) {
            return false;
          }

          const focused = focusedInputRank(state);

          if (!focused) {
            return false;
          }

          if (dispatch) {
            let tr = state.tr;

            switch (position) {
              case InputRankSectionPosition.before:
                tr = insertSectionNode(
                  tr,
                  inputRankSection,
                  "before",
                  focusedSectionNode
                );
                tr = this.updateRankWidth(tr, focused.node, focused.pos);
                tr = tr.setMeta(inputRankViewKey, {
                  setActive: {
                    sectionId: inputRankSection.attrs.id
                  }
                });
                break;

              case InputRankSectionPosition.after:
                tr = insertSectionNode(
                  tr,
                  inputRankSection,
                  "after",
                  focusedSectionNode
                );
                tr = this.updateRankWidth(tr, focused.node, focused.pos);
                tr = tr.setMeta(inputRankViewKey, {
                  setActive: {
                    sectionId: inputRankSection.attrs.id
                  }
                });
                break;

              case InputRankSectionPosition.delete:
                const $pos = findNodePos(
                  tr.doc,
                  tr.selection.from,
                  focusedSectionNode.node
                );

                if ($pos == null) {
                  return false;
                }

                let parentResolved: Node;
                if ($pos.nodeBefore) {
                  const resolvedPos = tr.doc.resolve(
                    focusedSectionNode.pos - 1
                  );

                  tr = tr.setSelection(TextSelection.near(resolvedPos));
                  parentResolved = resolvedPos.parent;
                } else {
                  const sections = focused.node.firstChild;

                  if (sections == null) {
                    return false;
                  }

                  parentResolved = sections.child(1);

                  tr = tr.setSelection(
                    TextSelection.near(
                      tr.doc.resolve(focused.pos + sections.nodeSize - 1)
                    )
                  );
                }

                if (
                  parentResolved &&
                  parentResolved.type === schema.nodes.inputRankSection
                ) {
                  tr = tr.setMeta(inputRankViewKey, {
                    setActive: {
                      sectionId: parentResolved.attrs.id
                    }
                  });
                } else {
                  const sections = focused.node.firstChild;

                  if (sections) {
                    const firstSection = sections.firstChild;

                    if (firstSection) {
                      tr = tr.setMeta(inputRankViewKey, {
                        setActive: {
                          sectionId: parentResolved.attrs.id
                        }
                      });
                    }
                  }
                }

                tr = tr.delete(
                  focusedSectionNode.pos,
                  focusedSectionNode.pos + focusedSectionNode.node.nodeSize
                );
                break;

              default:
                throw new UnreachableCaseError(position);
            }
            dispatch(tr);
          }
          return true;
        };
      }
    };
  }

  private updateRankWidth<S extends Schema>(
    tr: Transaction<S>,
    node: Node<S>,
    pos: number
  ): Transaction<S> {
    let updatedAttrs = { ...node.attrs };
    const width = getWidth(node.attrs.width, node.child(0).childCount + 1);

    if (width !== node.attrs.width) {
      updatedAttrs = { ...updatedAttrs, width: width };
      tr = tr.setNodeMarkup(pos, undefined, updatedAttrs);
    }

    return tr;
  }
}

function insertInputRank(
  schema: InputRankSchema,
  controlType: InputRankControlType
): CommandFn<InputRankSchema> {
  return (state, dispatch) => {
    if (!canInsert(schema.nodes.inputRank)(state)) {
      return false;
    } else {
      if (dispatch) {
        const inputRank = createInputRank(
          schema,
          controlType,
          getTranslation(state),
          getIdGenerator(state)
        );

        if (inputRank == null) {
          return false;
        }

        let tr = state.tr;
        tr = insertBlock(
          tr,
          schema,
          inputRank,
          true,
          getIdGenerator(state),
          isQuestionTitleAutoCreation(state)
        );

        const sections = inputRank.firstChild;

        if (sections) {
          const firstSection = sections.firstChild;

          if (firstSection) {
            tr = tr.setMeta(inputRankViewKey, {
              setActive: {
                sectionId: firstSection.attrs.id
              }
            });
          }
        }

        const $pos = findNodePos(tr.doc, tr.selection.from, inputRank);
        const options = inputRank.lastChild;

        if ($pos && options) {
          const resolved = tr.doc.resolve(
            $pos.pos + inputRank.nodeSize - options.nodeSize
          );

          tr = tr.setSelection(TextSelection.near(resolved));
        }

        dispatch(tr);
      }
      return true;
    }
  };
}

const preventDelete: CommandFn<InputRankSchema> = (
  state: EditorState<InputRankSchema>,
  _dispatch?: (tr: Transaction<InputRankSchema>) => void,
  _view?: EditorView<InputRankSchema>
) => {
  const prevent = shouldPrevent(state);

  return prevent;
};

const deleteCommand: CommandFn<InputRankSchema> = (
  state: EditorState<InputRankSchema>,
  dispatch?: (tr: Transaction<InputRankSchema>) => void,
  _view?: EditorView<InputRankSchema>
) => {
  const prevent = shouldPrevent(state);

  if (!prevent) {
    const { selection, schema } = state;
    if (!(selection instanceof TextSelection)) {
      return false;
    }

    const { $from, $to } = selection;

    const atStart = isEmptySelectionAtStart(state);
    const atEnd = isEmptySelectionAtEnd(state);
    if (!atStart && !atEnd) {
      return false;
    }

    const fromParent = $from.node($from.depth - 1);
    const toParent = $to.node($to.depth - 1);

    if (
      fromParent.type !== schema.nodes.inputRankOptions ||
      toParent.type !== schema.nodes.inputRankOptions
    ) {
      return false;
    }

    if (fromParent !== toParent) {
      return false;
    }

    if (atStart && atEnd && dispatch) {
      const isLastChild = isLastChildOfParent(state);

      if (isLastChild) {
        let tr = state.tr;
        tr = tr.deleteSelection();

        const toInsert = schema.nodes.paragraph.create();
        tr = deleteNode(tr, $to, $to.depth - 1);
        tr = insertNode(tr, toInsert, "after", $to, $to.depth - 3, true);

        dispatch(tr);
        return true;
      }
    }
    return false;
  }

  return prevent;
};

const enter: CommandFn<InputRankSchema> = (
  state: EditorState<InputRankSchema>,
  dispatch?: (tr: Transaction<InputRankSchema>) => void,
  _view?: EditorView<InputRankSchema>
) => {
  const focused = focusedInputRank(state);
  const atStart = isEmptySelectionAtStart(state);
  const atEnd = isEmptySelectionAtEnd(state);

  if (!focused) {
    return false;
  }

  const { selection, schema, doc } = state;
  if (!(selection instanceof TextSelection)) {
    return false;
  }

  let tr = state.tr;

  const { $from, $to, from, to } = tr.selection;

  const nodes = new Array<Node>();

  doc.nodesBetween(from, to, (node) => {
    const isSection = node.type === schema.nodes.inputRankSection;
    if (isSection) {
      nodes.push(node);
    }
  });

  if (nodes.length > 1) {
    return true;
  }

  const node = $from.node();

  if (!node) {
    return false;
  }

  if (node.type === schema.nodes.inputRankSection) {
    return false;
  }

  if (node.type === schema.nodes.inputRankOption && isBlank(node.textContent)) {
    const options = focused.node.child(1);
    if (options && options.type === schema.nodes.inputRankOptions) {
      let tr = state.tr;
      if (options.childCount === 1 && dispatch) {
        const { $to } = tr.selection;

        const toInsert = schema.nodes.paragraph.create();
        tr = insertNode(tr, toInsert, "after", $to, $to.depth - 3, true);

        dispatch(tr);
        return true;
      } else if (options.childCount > 1 && dispatch) {
        const isFirstChild = isFirstChildOfParent(state);
        const isLastChild = isLastChildOfParent(state);

        if (isFirstChild) {
          tr = tr.deleteSelection();

          const toInsert = schema.nodes.paragraph.create();
          tr = deleteNode(tr, $from, $from.depth - 1);
          tr = insertNode(tr, toInsert, "before", $from, $from.depth - 3, true);

          dispatch(tr);
          return true;
        } else if (isLastChild) {
          const toInsert = schema.nodes.paragraph.create();
          tr = deleteNode(tr, $to, $to.depth - 1);
          tr = insertNode(tr, toInsert, "after", $to, $to.depth - 3, true);
          tr = tr.deleteSelection();

          dispatch(tr);
          return true;
        }
      }
    }
  }

  if (!atStart && !atEnd && !canSplit(doc, from)) {
    return true;
  }

  if (atStart && dispatch) {
    let tr = state.tr;

    const { $from } = tr.selection;
    const node = $from.node();

    if (!node) {
      return false;
    }

    if (isSpecialRankOption(node, schema)) {
      if (node.type === schema.nodes.inputRankOptionOtherSpecify) {
        const toInsert = schema.nodes.inputRankOption.create();
        tr = insertNode(tr, toInsert, "before", $from, $from.depth - 1, false);
        dispatch(tr);
      }

      return true;
    }
  }

  return false;
};

function shouldPrevent(state: EditorState<InputRankSchema>): boolean {
  const { selection, doc, schema } = state;
  if (!(selection instanceof TextSelection)) {
    return false;
  }
  const nodes = new Array<Node>();
  const { from, to } = selection;

  let containOptions = false;
  let selectionIsOutsideRank = false;
  doc.nodesBetween(from, to, (node, pos) => {
    const isRank = node.type === schema.nodes.inputRank;
    const isSection = node.type === schema.nodes.inputRankSection;
    const isOption = node.type === schema.nodes.inputRankOption;
    if (isRank && (from <= pos + 1 || pos + 1 + node.nodeSize <= to)) {
      selectionIsOutsideRank = true;
    }

    if (isSection) {
      nodes.push(node);
    }

    if (isOption) {
      containOptions = true;
    }
  });

  if (selectionIsOutsideRank) {
    return false;
  } else {
    return nodes.length > 1 || (nodes.length >= 1 && containOptions);
  }
}

function insertNode<S extends Schema>(
  tr: Transaction<S>,
  toInsert: Node<S>,
  position: "before" | "after",
  $pos: ResolvedPos<S>,
  depth: number,
  shouldSelect: boolean
): Transaction<S> {
  const index = $pos.index(depth);
  const offset = position === "after" ? 1 : 0;
  const pos = tr.mapping.map($pos.posAtIndex(index + offset, depth));

  if (canInsertAtPos(toInsert, tr.doc.resolve(pos))) {
    tr = tr.insert(pos, toInsert);
    if (shouldSelect) {
      tr = tr.setSelection(TextSelection.near(tr.doc.resolve(pos)));
    }
  }

  return tr;
}

function insertSectionNode<S extends Schema>(
  tr: Transaction<S>,
  toInsert: Node<S>,
  position: "before" | "after",
  focused: Focus
): Transaction<S> {
  const pos =
    position === "before" ? focused.pos : focused.pos + focused.node.nodeSize;
  tr = tr.insert(pos, toInsert);
  tr = tr.setSelection(
    TextSelection.near(tr.doc.resolve(pos + toInsert.nodeSize - 1))
  );

  return tr;
}

function deleteNode<S extends Schema>(
  tr: Transaction<S>,
  $pos: ResolvedPos<S>,
  depth: number
): Transaction<S> {
  const toRemoveIndex = $pos.index(depth);
  const parentIndex = $pos.index(depth - 1);
  const parentPos = tr.mapping.map($pos.posAtIndex(parentIndex, depth - 1));
  const parent = $pos.node(depth);

  let children = new Array<Node<S>>();
  parent.forEach((child, _offset, index) => {
    if (index !== toRemoveIndex) {
      children.push(child);
    }
  });

  tr = tr.replaceRangeWith(
    parentPos,
    parentPos + parent.nodeSize,
    parent.copy(Fragment.from(children))
  );

  return tr;
}

function isBlank(str: string): boolean {
  return !str || /^\s*$/.test(str);
}

function getWidth(width: number, sectionCount: number): number {
  switch (sectionCount) {
    case 1:
      if (width < 25) {
        return 25;
      }
      break;

    case 2:
      if (width < 50) {
        return 50;
      }
      break;

    case 3:
      if (width < 75) {
        return 75;
      }
      break;

    default:
      if (width < 100) {
        return 100;
      }
      break;
  }

  if (width > InputRankWidth.maximum) {
    return InputRankWidth.maximum;
  }

  return width;
}

function toggleSelectAll<S extends Schema>(
  tr: Transaction<S>,
  schema: S,
  pos: number,
  selectAll: boolean,
  getTranslation: GetTranslationFn
): Transaction<S> {
  const node = tr.doc.nodeAt(pos);
  if (node == null) {
    return tr;
  }

  if (selectAll === true) {
    if (node.firstChild?.type === schema.nodes.inputRankOptionSelectAll) {
      return tr;
    }

    const selectAll = createInputRankOptionSelectAll(
      schema,
      getTranslation
    ) as Node<S>;

    let children = new Array<Node<S>>();
    node.forEach((child, _offset, index) => {
      const isLastChild = index === node.childCount - 1;
      const isChildEmpty = child.content.size === 0;
      if (isLastChild) {
        if (index === 0) {
          children.push(selectAll);
        }

        if (!isChildEmpty) {
          children.push(child);
        }
      } else {
        if (index === 0) {
          children.push(selectAll);
        }
        children.push(child);
      }
    });

    tr = tr.replaceRangeWith(
      pos,
      pos + node.nodeSize,
      node.copy(Fragment.from(children))
    );
  } else {
    let selectAll: { node: Node<InputRankSchema>; pos: number } | undefined;

    node.forEach((node, offset) => {
      if (node.type === schema.nodes.inputRankOptionSelectAll) {
        selectAll = { node: node, pos: pos + offset };
      }
    });

    if (selectAll != null) {
      tr = tr.delete(
        selectAll.pos + 1,
        selectAll.pos + selectAll.node.nodeSize + 1
      );
    }
  }

  return tr;
}

function toggleOtherSpecify<S extends Schema>(
  tr: Transaction<S>,
  schema: S,
  pos: number,
  otherSpecify: boolean,
  getTranslation: GetTranslationFn
): Transaction<S> {
  const node = tr.doc.nodeAt(pos);
  if (node == null) {
    return tr;
  }

  if (otherSpecify === true) {
    if (node.lastChild?.type === schema.nodes.inputRankOptionOtherSpecify) {
      return tr;
    }

    const otherSpecify = createInputRankOptionOtherSpecify(
      schema,
      getTranslation
    ) as Node<S>;

    let children = new Array<Node<S>>();
    node.forEach((child, _offset, index) => {
      const isLastChild = index === node.childCount - 1;
      const isChildEmpty = child.content.size === 0;
      if (isLastChild) {
        if (!isChildEmpty) {
          children.push(child);
        }
      } else {
        children.push(child);
      }
    });

    children.push(otherSpecify);

    tr = tr.replaceRangeWith(
      pos,
      pos + node.nodeSize,
      node.copy(Fragment.from(children))
    );
  } else {
    let otherSpecify: { node: Node<InputRankSchema>; pos: number } | undefined;

    node.forEach((node, offset) => {
      if (node.type === schema.nodes.inputRankOptionOtherSpecify) {
        otherSpecify = { node: node, pos: pos + offset };
      }
    });

    if (otherSpecify != null) {
      tr = tr.delete(
        otherSpecify.pos,
        otherSpecify.pos + otherSpecify.node.nodeSize
      );
    }
  }

  return tr;
}

function toggleEmptyNode<S extends Schema>(
  tr: Transaction<S>,
  schema: S,
  pos: number,
  controlType: InputRankControlType,
  getTranslation: GetTranslationFn
): Transaction<S> {
  const node = tr.doc.nodeAt(pos);
  if (node == null) {
    return tr;
  }

  if (
    node.firstChild?.type === schema.nodes.inputRankSectionEmpty &&
    controlType === InputRankControlType.dropdown
  ) {
    return tr;
  }

  const emptyNode = createInputSection(schema, true, getTranslation) as Node<S>;

  let children = new Array<Node<S>>();
  node.forEach((child, _offset, index) => {
    const isFirstChild = index === 0;
    const isNotFirstChild = index > 0;
    if (isFirstChild && controlType === InputRankControlType.dropdown) {
      children.push(emptyNode);
      children.push(child);
    }

    if (
      isFirstChild &&
      controlType === InputRankControlType.dragDrop &&
      child.type === schema.nodes.inputRankSection
    ) {
      children.push(child);
    }

    if (isNotFirstChild) {
      children.push(child);
    }
  });

  tr = tr.replaceRangeWith(
    pos,
    pos + node.nodeSize,
    node.copy(Fragment.from(children))
  );
  tr = tr.setSelection(TextSelection.near(tr.doc.resolve(pos + 1)));

  return tr;
}

function selectAllNode(
  node: Node<InputRankSchema>,
  schema: InputRankSchema
): Node<InputRankSchema> | undefined {
  let selectAllNode: Node<InputRankSchema> | undefined = undefined;
  const options = node.child(1);

  for (let i = 0; i < options.childCount; ++i) {
    let child = options.maybeChild(i);

    if (child != null && child.type === schema.nodes.inputRankOptionSelectAll) {
      selectAllNode = child;
      break;
    }
  }

  return selectAllNode;
}

function otherSpecifyNode(
  node: Node<InputRankSchema>,
  schema: InputRankSchema
): Node<InputRankSchema> | undefined {
  let otherSpecifyNode: Node<InputRankSchema> | undefined = undefined;
  const options = node.child(1);

  for (let i = 0; i < options.childCount; ++i) {
    let child = options.maybeChild(i);

    if (
      child != null &&
      child.type === schema.nodes.inputRankOptionOtherSpecify
    ) {
      otherSpecifyNode = child;
      break;
    }
  }

  return otherSpecifyNode;
}
