import { Skin } from "./types";
import { Schema } from "prosemirror-model";
import { EditorView } from "prosemirror-view";
import { EditorState } from "prosemirror-state";
import {
  documentSkinKey,
  DocumentSkinState
} from "./plugin/document-skin-picker";

export const documentSkins = [
  new Skin({ id: "default", name: "Default" }),
  new Skin({ id: "rounded", name: "Rounded" }),
  new Skin({ id: "simple", name: "Simple" })
];

interface DOMObserver {
  start(): void;
  stop(): void;
}

interface DOMObserverView extends EditorView {
  domObserver: DOMObserver;
}

export class InputStyleObserver<S extends Schema> {
  private observer: MutationObserver;

  private _getInputStyle: DocumentSkinState | null;
  get getInputStyle(): DocumentSkinState | null {
    return this._getInputStyle;
  }

  constructor(
    view: EditorView<S>,
    onChange: (getInputStyle: DocumentSkinState | null) => void
  ) {
    this.observer = new MutationObserver((mutations) => {
      for (let mutation of mutations) {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "data-input-style"
        ) {
          this._getInputStyle = getInputStyle(view.state);
          (view as DOMObserverView).domObserver.stop();
          onChange(this._getInputStyle);
          (view as DOMObserverView).domObserver.start();
        }
      }
    });

    this._getInputStyle = getInputStyle(view.state);

    this.observer.observe(view.dom, {
      attributes: true,
      attributeFilter: ["data-input-style"]
    });
  }

  destroy() {
    this.observer.disconnect();
  }
}

export function getInputStyle<S extends Schema>(
  state: EditorState<S>
): DocumentSkinState | null {
  const documentSkin = documentSkinKey.getState(state);
  if (documentSkin == null) {
    console.error(`No document skin found.`);
    return null;
  }

  return documentSkin;
}
