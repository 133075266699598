import { DOMParser, Fragment, Node, NodeSpec } from "prosemirror-model";
import { DocumentBuilders, NodeConfig } from "../../../editor";
import { toNumberValue } from "../../../util";
import { AlignmentType } from "../../alignment";
import {
  getQuestionTitleAttrs,
  setQuestionTitleAttrs
} from "../../question-title";
import {
  InputScaleControlType,
  InputScaleOrientation,
  InputScaleRows,
  InputScaleSchema,
  InputScaleWidth
} from "../schema";

export class InputScaleNode implements NodeConfig {
  get name(): string {
    return "inputScale";
  }

  get spec(): NodeSpec {
    return {
      group: "block input gridInput",
      content: "inputScaleValue* inputScaleNotApplicable{0,1}",
      draggable: true,
      selectable: true,
      focusable: true,
      allowGapCursor: true,
      allowIndentation: false,
      allowAlignment: ["left", "center", "right"],
      blockAlignment: true,
      isolating: true,
      attrs: {
        id: { default: null },
        questionTitleText: { default: null },
        questionTitleRefElementId: { default: new Array<string>() },
        description: { default: "" },
        required: { default: false },
        coding: { default: "" },
        controlType: { default: InputScaleControlType.labelsInRow },
        orientation: { default: InputScaleOrientation.top },
        rows: { default: InputScaleRows.default },
        width: { default: InputScaleWidth.default },
        scale: { default: null },
        alignment: { default: null }
      },
      parseDOM: [
        {
          tag: "input-scale",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getAttrs(node as Element);
          }
        },
        {
          tag: '[data-input="likert_item"]',
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getAttrs(node as Element);
          },
          getContent: (node, schema) => {
            return legacyValues(node as Element, schema);
          }
        }
      ],
      toDOM(node) {
        return ["input-scale", setAttrs(node), 0];
      },
      applyBlueprint(
        attrs: Record<string, any>,
        blueprint: Record<string, any>
      ) {
        const updated = { ...attrs };

        updated.required = blueprint.required;
        updated.controlType = blueprint.controlType as InputScaleControlType;
        if (updated.controlType !== InputScaleControlType.labelsInRow) {
          updated.orientation = blueprint.orientation;
        } else {
          updated.orientation = InputScaleOrientation.top;
        }
        updated.rows = blueprint.rows;
        updated.scale = blueprint.scale;

        return updated;
      }
    };
  }

  get builders(): DocumentBuilders {
    return { inputScale: { nodeType: "inputScale" } };
  }
}

function getAttrs(element: Element): { [key: string]: any } {
  const attrs: {
    id?: string;
    questionTitleText?: string;
    questionTitleRefElementId?: string[];
    description?: string;
    required?: boolean;
    coding?: string;
    controlType?: InputScaleControlType;
    orientation?: InputScaleOrientation;
    rows?: number;
    width?: number;
    scale?: string;
    notApplicable?: boolean;
    alignment?: AlignmentType;
  } = { ...getQuestionTitleAttrs(element) };

  const id = element.getAttribute("id");
  if (id != null) {
    attrs.id = id;
  }

  const description = element.getAttribute("data-description");
  if (description != null) {
    attrs.description = description;
  }

  const required = element.getAttribute("data-required");
  if (required != null) {
    attrs.required = required.toLowerCase() === "true";
  }

  const coding = element.getAttribute("data-coding");
  if (coding != null) {
    attrs.coding = coding;
  }

  const controlType = element.getAttribute(
    "data-control-type"
  ) as InputScaleControlType;
  if (controlType != null) {
    switch (controlType) {
      case InputScaleControlType.labelsInRow:
      case InputScaleControlType.dropdown:
      case InputScaleControlType.listbox:
        attrs.controlType = controlType;
        break;
      default:
        break;
    }
  }

  const orientation = element.getAttribute(
    "data-orientation"
  ) as InputScaleOrientation;
  if (orientation != null) {
    switch (orientation) {
      case InputScaleOrientation.bottom:
      case InputScaleOrientation.boxed:
      case InputScaleOrientation.top:
        attrs.orientation = orientation;
        break;
      default:
        break;
    }
  }

  const rows = toNumberValue(element.getAttribute("data-rows"), 0);
  if (rows != null) {
    attrs.rows = rows;
  }

  const width = toNumberValue(element.getAttribute("data-width"), 0);
  if (width != null) {
    attrs.width = width;
  }

  const scale = element.getAttribute("data-scale");
  if (scale != null) {
    attrs.scale = scale;
  }

  const alignment = element.getAttribute("data-alignment") as AlignmentType;
  if (alignment != null) {
    switch (alignment) {
      case "center":
      case "left":
      case "right":
        attrs.alignment = alignment;
        break;
      default:
        break;
    }
  }

  return attrs;
}

function setAttrs(node: Node): { [key: string]: any } {
  const id = node.attrs.id as string;
  const description = node.attrs.description as string;
  const required = node.attrs.required as boolean;
  const coding = node.attrs.coding as string;
  const controlType = node.attrs.controlType as InputScaleControlType;
  const orientation = node.attrs.orientation as InputScaleOrientation;
  const rows = node.attrs.rows as number;
  const width = node.attrs.width as number;
  const scale = node.attrs.scale as string | null;

  return {
    id: id,
    ...setQuestionTitleAttrs(node),
    "data-description": description,
    "data-required": required === true ? "true" : "false",
    "data-coding": coding,
    "data-control-type": controlType,
    "data-orientation": orientation,
    "data-rows": rows,
    "data-width": width,
    "data-scale": scale,
    "data-alignment": node.attrs.alignment
  };
}

function legacyValues(element: Element, schema: InputScaleSchema): Fragment {
  const parser = DOMParser.fromSchema(schema);
  const options = { topNode: schema.nodes.inputScale.create() };
  const node = parser.parse(element, options);
  const fragment = node.content;

  let updated = Fragment.from(fragment);

  const parentId = element.getAttribute("id");
  if (parentId != null) {
    fragment.forEach((node, _offset, index) => {
      if (
        [
          schema.nodes.inputScaleValue,
          schema.nodes.inputScaleNotApplicable
        ].includes(node.type)
      ) {
        updated = updated.replaceChild(
          index,
          node.type.create(
            { ...node.attrs, id: `${parentId}-${node.attrs.id}` },
            node.content,
            node.marks
          )
        );
      }
    });
  }

  return updated;
}
