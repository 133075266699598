import { keymap } from "prosemirror-keymap";
import { Schema } from "prosemirror-model";
import { Plugin } from "prosemirror-state";
import { CommandFn } from "./command";

export type KeyMap<S extends Schema> = Record<string, CommandFn<S>>;

export interface ExtensionKeymaps<S extends Schema> {
  keymaps?: (schema: S) => KeyMap<S>;
}

export function extensionKeymaps<S extends Schema>(
  extensions: ExtensionKeymaps<S>[],
  schema: S
): Plugin[] {
  return extensions.reduce((acc, elem) => {
    const extension = elem.keymaps;
    if (extension) {
      const keymaps = extension.bind(elem)(schema);
      const plugin = keymap(keymaps);
      return acc.concat(plugin);
    } else {
      return acc;
    }
  }, new Array<Plugin>());
}
