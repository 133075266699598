import { EditorState, NodeSelection, Plugin } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";
import { editableKey } from "../../../editor/plugins/editable";
import {
  Focus,
  selectionFocusKey
} from "../../../editor/plugins/selection-focus";
import { findParent, findParentNodeOfType } from "../../../util";
import { InputRankControlType } from "../schema";
import { InputRankNodeView } from "./input-rank-node-view";
import {
  InputRankOptionNodeView,
  InputRankOptionOtherSpecifyNodeView
} from "./input-rank-option-node-view";
import { InputRankOptionsNodeView } from "./input-rank-options-node-view";
import {
  InputRankSectionEmptyNodeView,
  InputRankSectionNodeView
} from "./input-rank-section-node-view";
import { InputRankSectionsNodeView } from "./input-rank-sections-node-view";
import { inputRankNodeViewKey, PluginState } from "./types";

function setValue(
  state: EditorState,
  value: (focused: Focus) => PluginState
): PluginState | null {
  const focused = selectionFocusKey.getState(state);

  if (!focused) {
    return null;
  } else {
    const { schema } = state;

    if (focused.node.type === schema.nodes.inputRank) {
      const { node } = focused;
      const controlType = node.attrs.controlType as InputRankControlType;
      if (controlType === InputRankControlType.dragDrop) {
        return value(focused);
      } else {
        return null;
      }
    } else {
      if (focused.node.type === schema.nodes.inputRankSection) {
        return null;
      }

      const parent = findParentNodeOfType(schema.nodes.inputRank)(
        state.selection
      );

      if (parent) {
        const expectedFocused = {
          node: parent.node,
          pos: parent.pos
        };

        return value(expectedFocused);
      } else {
        return null;
      }
    }
  }
}

export function inputRankNodeViewPlugin() {
  return new Plugin({
    key: inputRankNodeViewKey,
    state: {
      init(_config, state) {
        return setValue(state, (focused) => {
          return { opened: true, focused: focused };
        });
      },
      apply(_tr, value, _oldState, newState) {
        return setValue(newState, (focused) => {
          if (value != null && focused.node === value.focused.node) {
            const updatedValue =
              focused.pos === value.focused.pos
                ? { ...value }
                : { ...value, focused: focused };

            return updatedValue;
          } else {
            return { opened: true, focused: focused };
          }
        });
      }
    },
    props: {
      decorations: (state) => {
        const { doc } = state;
        const decorations: Decoration[] = [];

        const dropdownState = inputRankNodeViewKey.getState(state);

        if (dropdownState) {
          const { focused, opened } = dropdownState;

          if (opened) {
            decorations.push(
              Decoration.node(
                focused.pos,
                focused.pos + focused.node.nodeSize,
                {
                  "data-dropdown-opened": ""
                }
              )
            );
          }
        }

        const focused = selectionFocusKey.getState(state);

        if (!focused) {
          return null;
        }

        const { schema } = state;
        if (focused.node.type === schema.nodes.inputRankSection) {
          const parent = findParentNodeOfType(state.schema.nodes.inputRank)(
            state.selection
          );

          if (parent) {
            decorations.push(
              Decoration.node(parent.pos, parent.pos + parent.node.nodeSize, {
                class: "ProseMirror-background-active"
              })
            );
          }
        }

        return decorations.length === 0
          ? DecorationSet.empty
          : DecorationSet.create(doc, decorations);
      },
      nodeViews: {
        inputRank: (node, view, getPos, decorations) => {
          return new InputRankNodeView(
            node,
            view,
            getPos as () => number,
            decorations
          );
        },
        inputRankSections: (node, view, _getPos, _decorations) => {
          return new InputRankSectionsNodeView(node, view);
        },
        inputRankSection: (node, view, getPos, _decorations) => {
          return new InputRankSectionNodeView(
            node,
            view,
            getPos as () => number
          );
        },
        inputRankSectionEmpty: (_node, _view, _getPos, _decorations) => {
          return new InputRankSectionEmptyNodeView();
        },
        inputRankOptions: (node, _view, _getPos, _decorations) => {
          return new InputRankOptionsNodeView(node);
        },
        inputRankOption: (node, view, _getPos, decorations) => {
          return new InputRankOptionNodeView(node, view, decorations);
        },
        inputRankOptionSelectAll: (node, view, _getPos, decorations) => {
          return new InputRankOptionNodeView(node, view, decorations);
        },
        inputRankOptionOtherSpecify: (node, _view, _getPos, _decorations) => {
          return new InputRankOptionOtherSpecifyNodeView(node);
        }
      },
      handleClickOn(view, _pos, node, nodePos, event, direct) {
        const { state } = view;

        const editable = editableKey.getState(state);
        if (editable && editable.focusable === false) {
          return false;
        }

        const { schema } = state;
        const target = event.target as Element;
        const isInContent = target.closest(".content") != null;
        const isSectionSelectable =
          node.type.spec.selectable &&
          node.type === schema.nodes.inputRankSection;

        const isRankSelectable =
          node.type.spec.selectable && node.type === schema.nodes.inputRank;
        if (isSectionSelectable && direct && isInContent) {
          const tr = state.tr.setSelection(
            new NodeSelection(state.doc.resolve(nodePos))
          );
          view.dispatch(tr);
          return true;
        } else if (node.type === schema.nodes.inputRankSections && direct) {
          const $pos = state.doc.resolve(nodePos);
          const parent = findParent($pos, () => true);
          if (parent == null) {
            return false;
          }
          const tr = state.tr.setSelection(
            new NodeSelection(state.doc.resolve(parent.pos))
          );
          view.dispatch(tr);
          return true;
        } else if (isRankSelectable && direct) {
          const tr = state.tr.setSelection(
            new NodeSelection(state.doc.resolve(nodePos))
          );
          view.dispatch(tr);
          return true;
        } else {
          return false;
        }
      }
    }
  });
}
