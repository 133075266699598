import { Node, NodeSpec, Schema } from "prosemirror-model";
import { BASE_PRIORITY, DocumentBuilders, NodeConfig } from "../../../editor";
import { AlignmentType } from "../../alignment";
import { InputChoiceControlType } from "../schema";

function setInputChoiceContentAttrs(
  node: Node
): { [key: string]: string | null } {
  return {
    id: node.attrs.id,
    "data-alignment": node.attrs.alignment,
    "data-coding": node.attrs.coding,
    "data-default": node.attrs.default === true ? "true" : null
  };
}

function getInputChoiceContentAttrs(element: Element): { [key: string]: any } {
  const attrs: {
    id?: string;
    coding?: string | null;
    default?: boolean;
    alignment?: AlignmentType;
  } = {};

  const id = element.getAttribute("id");
  if (id != null) {
    attrs.id = id;
  }

  const legacyId = element.getAttribute("data-choice-id");
  if (legacyId != null) {
    attrs.id = legacyId;
  }

  const coding = element.getAttribute("data-coding");
  if (coding != null) {
    attrs.coding = coding;
  }

  const legacyCoding = element.getAttribute("data-choice-coding");
  if (legacyCoding != null) {
    attrs.coding = legacyCoding;
  }

  const defaultValue = element.hasAttribute("data-default");
  if (defaultValue === true) {
    attrs.default = defaultValue;
  }

  const legacyDefaultValue = element.hasAttribute("data-choice-default");
  if (legacyDefaultValue === true) {
    attrs.default = legacyDefaultValue;
  }

  const alignment = element.getAttribute("data-alignment") as AlignmentType;
  if (alignment != null) {
    switch (alignment) {
      case "center":
      case "justify":
      case "left":
      case "right":
        attrs.alignment = alignment;
        break;
      default:
        break;
    }
  }

  return attrs;
}

const BaseSpec: NodeSpec = {
  content: "(inline|hardBreak)*",
  draggable: false,
  selectable: false,
  focusable: false,
  allowGapCursor: false,
  allowIndentation: false,
  defaultAlignment: (_node: Node<Schema>, parent: Node<Schema>) => {
    const controlType = parent.attrs.controlType as InputChoiceControlType;
    switch (controlType) {
      case InputChoiceControlType.singleHorizontal:
      case InputChoiceControlType.multipleHorizontal:
        return "center";
      case InputChoiceControlType.singleVertical:
      case InputChoiceControlType.multipleVertical:
        return "left";
      case InputChoiceControlType.dropdown:
        return "left";
      case InputChoiceControlType.listbox:
        return "left";
      default:
        return "left";
    }
  },
  attrs: {
    id: { default: null },
    alignment: { default: null },
    coding: { default: null },
    default: { default: false }
  },
  applyBlueprint(attrs: Record<string, any>, blueprint: Record<string, any>) {
    const updated = { ...attrs };

    updated.coding = blueprint.coding;
    updated.default = blueprint.default;

    return updated;
  }
};

export class InputChoiceValueNode implements NodeConfig {
  get name(): string {
    return "inputChoiceValue";
  }

  get spec(): NodeSpec {
    return {
      ...BaseSpec,
      parseDOM: [
        {
          tag: "input-choice-value",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getInputChoiceContentAttrs(node as Element);
          }
        },
        {
          tag: "[data-choice-id][data-choice-type='standard']",
          context: "inputChoice/",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getInputChoiceContentAttrs(node as Element);
          }
        },
        {
          tag: "p",
          context: "inputChoiceValue/",
          priority: BASE_PRIORITY + 10
        },
        {
          tag: "ul",
          context: "inputChoiceValue/",
          priority: BASE_PRIORITY + 10,
          skip: true
        },
        {
          tag: "ol",
          context: "inputChoiceValue/",
          priority: BASE_PRIORITY + 10,
          skip: true
        },
        {
          tag: "li",
          context: "inputChoiceValue/",
          priority: BASE_PRIORITY + 10
        },
        {
          tag: "table",
          context: "inputChoiceValue/",
          priority: BASE_PRIORITY + 10,
          skip: true
        },
        {
          tag: "tr",
          context: "inputChoiceValue/",
          priority: BASE_PRIORITY + 10,
          getAttrs(node) {
            if (typeof node === "string") {
              return false;
            }

            const row = node as HTMLTableRowElement;
            const cells = row.cells;

            const hasMultipleCells = cells.length > 1;
            if (!hasMultipleCells) {
              return;
            }

            const cell = cells.item(0);
            if (cell == null) {
              return;
            }

            const cellText = cell.textContent;
            if (cellText == null) {
              return;
            }

            const coding = cellText
              .replace(/\r/g, "")
              .replace(/\n/g, "")
              .trim();

            return { coding: coding };
          },
          contentElement(node) {
            const row = node as HTMLTableRowElement;
            const cells = row.cells;
            const label = cells.length > 1 ? cells.item(1) : cells.item(0);
            if (label == null) {
              return node;
            }

            return label;
          }
        }
      ],
      toDOM(node) {
        return ["input-choice-value", setInputChoiceContentAttrs(node), 0];
      }
    };
  }

  get builders(): DocumentBuilders {
    return {
      inputChoiceValue: {
        nodeType: "inputChoiceValue"
      }
    };
  }
}

export class InputChoiceAllOfTheAboveValueNode implements NodeConfig {
  get name(): string {
    return "inputChoiceAllOfTheAboveValue";
  }

  get spec(): NodeSpec {
    return {
      // setting no default for id means that this node wont be used for node splitting.
      ...{
        ...BaseSpec,
        attrs: { ...BaseSpec.attrs, id: {} }
      },
      parseDOM: [
        {
          tag: "input-choice-all-of-the-above-value",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getInputChoiceContentAttrs(node as Element);
          }
        },
        {
          tag: "[data-choice-id][data-choice-type='all-of-the-above']",
          context: "inputChoice/",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getInputChoiceContentAttrs(node as Element);
          }
        }
      ],
      toDOM(node) {
        return [
          "input-choice-all-of-the-above-value",
          setInputChoiceContentAttrs(node),
          0
        ];
      }
    };
  }

  get builders(): DocumentBuilders {
    return {
      inputChoiceAllOfTheAboveValue: {
        nodeType: "inputChoiceAllOfTheAboveValue",
        id: null
      }
    };
  }
}

export class InputChoiceNoneOfTheAboveValueNode implements NodeConfig {
  get name(): string {
    return "inputChoiceNoneOfTheAboveValue";
  }

  get spec(): NodeSpec {
    return {
      // setting no default for id means that this node wont be used for node splitting.
      ...{
        ...BaseSpec,
        attrs: { ...BaseSpec.attrs, id: {} }
      },
      parseDOM: [
        {
          tag: "input-choice-none-of-the-above-value",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getInputChoiceContentAttrs(node as Element);
          }
        },
        {
          tag: "[data-choice-id][data-choice-type='none-of-the-above']",
          context: "inputChoice/",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getInputChoiceContentAttrs(node as Element);
          }
        }
      ],
      toDOM(node) {
        return [
          "input-choice-none-of-the-above-value",
          setInputChoiceContentAttrs(node),
          0
        ];
      }
    };
  }

  get builders(): DocumentBuilders {
    return {
      inputChoiceNoneOfTheAboveValue: {
        nodeType: "inputChoiceNoneOfTheAboveValue",
        id: null
      }
    };
  }
}

export class InputChoiceOtherSpecifyValueNode implements NodeConfig {
  get name(): string {
    return "inputChoiceOtherSpecifyValue";
  }

  get spec(): NodeSpec {
    return {
      // setting no default for id means that this node wont be used for node splitting.
      ...{
        ...BaseSpec,
        content: "inline*",
        attrs: { ...BaseSpec.attrs, id: {} }
      },
      isolating: true,
      parseDOM: [
        {
          tag: "input-choice-other-specify-value",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getInputChoiceContentAttrs(node as Element);
          }
        },
        {
          tag: "[data-choice-id][data-choice-type='other-specify']",
          context: "inputChoice/",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getInputChoiceContentAttrs(node as Element);
          }
        }
      ],
      toDOM(node) {
        return [
          "input-choice-other-specify-value",
          setInputChoiceContentAttrs(node),
          0
        ];
      }
    };
  }

  get builders(): DocumentBuilders {
    return {
      inputChoiceOtherSpecifyValue: {
        nodeType: "inputChoiceOtherSpecifyValue",
        id: null
      }
    };
  }
}
