import { ResolvedPos, Schema } from "prosemirror-model";
import {
  EditorState,
  NodeSelection,
  Selection,
  TextSelection,
  Transaction
} from "prosemirror-state";
import { CellSelection, TableMap } from "prosemirror-tables";
import { Decoration, DecorationSet, EditorView } from "prosemirror-view";
import { key } from "./key";
import { keydownHandler } from "prosemirror-keymap";
import { CommandFn } from "../../../../editor";

export function drawCellSelection<S extends Schema>(
  state: EditorState<S>
): DecorationSet | null {
  if (!(state.selection instanceof CellSelection)) {
    return null;
  }
  let cells = new Array<Decoration>();
  state.selection.forEachCell((node, pos) => {
    cells.push(
      Decoration.node(pos, pos + node.nodeSize, { class: "selectedCell" })
    );
  });
  return DecorationSet.create(state.doc, cells);
}

function isCellBoundarySelection<S extends Schema>({
  $from,
  $to
}: {
  $from: ResolvedPos<S>;
  $to: ResolvedPos<S>;
}): boolean {
  if ($from.pos === $to.pos || $from.pos < $from.pos - 6) return false; // Cheap elimination
  let afterFrom = $from.pos,
    beforeTo = $to.pos,
    depth = $from.depth;
  for (; depth >= 0; depth--, afterFrom++)
    if ($from.after(depth + 1) < $from.end(depth)) break;
  for (let d = $to.depth; d >= 0; d--, beforeTo--)
    if ($to.before(d + 1) > $to.start(d)) break;
  return (
    afterFrom === beforeTo &&
    /row|table/.test($from.node(depth).type.spec.tableRole)
  );
}

function isTextSelectionAcrossCells<S extends Schema>({
  $from,
  $to
}: {
  $from: ResolvedPos<S>;
  $to: ResolvedPos<S>;
}): boolean {
  let fromCellBoundaryNode;
  let toCellBoundaryNode;

  for (let i = $from.depth; i > 0; i--) {
    let node = $from.node(i);
    if (
      node.type.spec.tableRole === "cell" ||
      node.type.spec.tableRole === "header_cell"
    ) {
      fromCellBoundaryNode = node;
      break;
    }
  }

  for (let i = $to.depth; i > 0; i--) {
    let node = $to.node(i);
    if (
      node.type.spec.tableRole === "cell" ||
      node.type.spec.tableRole === "header_cell"
    ) {
      toCellBoundaryNode = node;
      break;
    }
  }

  return fromCellBoundaryNode !== toCellBoundaryNode && $to.parentOffset === 0;
}

export function normalizeSelection<S extends Schema>(
  state: EditorState<S>,
  tr: Transaction<S>,
  allowTableNodeSelection: boolean
) {
  let sel = (tr || state).selection,
    doc = (tr || state).doc,
    normalize,
    role;
  if (sel instanceof NodeSelection && (role = sel.node.type.spec.tableRole)) {
    if (role === "cell" || role === "header_cell") {
      normalize = CellSelection.create(doc, sel.from);
    } else if (role === "row") {
      let $cell = doc.resolve(sel.from + 1);
      normalize = CellSelection.rowSelection($cell, $cell);
    } else if (!allowTableNodeSelection) {
      let map = TableMap.get(sel.node),
        start = sel.from + 1;
      let lastCell = start + map.map[map.width * map.height - 1];
      normalize = CellSelection.create(doc, start + 1, lastCell);
    }
  } else if (sel instanceof TextSelection && isCellBoundarySelection(sel)) {
    normalize = TextSelection.create(doc, sel.from);
  } else if (sel instanceof TextSelection && isTextSelectionAcrossCells(sel)) {
    normalize = TextSelection.create(doc, sel.$from.start(), sel.$from.end());
  }
  if (normalize)
    (tr || (tr = state.tr)).setSelection(normalize as Selection<S>);
  return tr;
}

function domInCell<S extends Schema>(view: EditorView<S>, dom: Node) {
  for (; dom && dom !== view.dom; dom = dom.parentNode!) {
    if (dom.nodeName === "TD" || dom.nodeName === "TH") {
      return dom;
    }
  }

  return undefined;
}

function cellAround<S extends Schema>(
  $pos: ResolvedPos<S>
): ResolvedPos<S> | null {
  for (let d = $pos.depth - 1; d > 0; d--) {
    if ($pos.node(d).type.spec.tableRole === "row") {
      return $pos.node(0).resolve($pos.before(d + 1));
    }
  }
  return null;
}

function cellUnderMouse<S extends Schema>(
  view: EditorView<S>,
  event: MouseEvent
): ResolvedPos<S> | null {
  let mousePos = view.posAtCoords({ left: event.clientX, top: event.clientY });
  if (!mousePos) {
    return null;
  }
  return mousePos ? cellAround(view.state.doc.resolve(mousePos.pos)) : null;
}

function inSameTable<S extends Schema>(
  $a: ResolvedPos<S>,
  $b: ResolvedPos<S>
): boolean {
  return (
    $a.depth === $b.depth && $a.pos >= $b.start(-1) && $a.pos <= $b.end(-1)
  );
}

export function handleMouseDown<S extends Schema>(
  view: EditorView<S>,
  startEvent: MouseEvent
): boolean {
  if (startEvent.ctrlKey || startEvent.metaKey) {
    return false;
  }

  let startDOMCell = domInCell(view, startEvent.target as Node),
    $anchor;
  if (startEvent.shiftKey && view.state.selection instanceof CellSelection) {
    // Adding to an existing cell selection
    setCellSelection(view.state.selection.$anchorCell, startEvent);
    startEvent.preventDefault();
  } else if (
    startEvent.shiftKey &&
    startDOMCell &&
    ($anchor = cellAround(view.state.selection.$anchor)) != null &&
    cellUnderMouse(view, startEvent)?.pos !== $anchor.pos
  ) {
    // Adding to a selection that starts in another cell (causing a
    // cell selection to be created).
    setCellSelection($anchor, startEvent);
    startEvent.preventDefault();
  } else if (!startDOMCell) {
    // Not in a cell, let the default behavior happen.
    return false;
  }

  // Create and dispatch a cell selection between the given anchor and
  // the position under the mouse.
  function setCellSelection($anchor: ResolvedPos<S>, event: MouseEvent) {
    let $head = cellUnderMouse(view, event);
    let starting = key.getState(view.state) == null;
    if (!$head || !inSameTable($anchor, $head)) {
      if (starting) $head = $anchor;
      else return;
    }
    let selection = new CellSelection($anchor, $head);
    if (
      starting ||
      !view.state.selection.eq((selection as unknown) as Selection<S>)
    ) {
      let tr = view.state.tr.setSelection(
        (selection as unknown) as Selection<S>
      );
      if (starting) tr.setMeta(key, $anchor.pos);
      view.dispatch(tr);
    }
  }

  // Stop listening to mouse motion events.
  function stop() {
    view.root.removeEventListener("mouseup", stop);
    view.root.removeEventListener("dragstart", stop);
    view.root.removeEventListener("mousemove", move);
    if (key.getState(view.state) != null)
      view.dispatch(view.state.tr.setMeta(key, -1));
  }

  function move(event: Event) {
    const mouseEvent = event as MouseEvent;
    let anchor = key.getState(view.state),
      $anchor;
    if (anchor != null) {
      // Continuing an existing cross-cell selection
      $anchor = view.state.doc.resolve(anchor);
    } else if (domInCell(view, mouseEvent.target as Node) !== startDOMCell) {
      // Moving out of the initial cell -- start a new cell selection
      $anchor = cellUnderMouse(view, startEvent);
      if (!$anchor) return stop();
    }
    if ($anchor) setCellSelection($anchor, mouseEvent);
  }

  view.root.addEventListener("mouseup", stop);
  view.root.addEventListener("dragstart", stop);
  view.root.addEventListener("mousemove", move);

  return false;
}

export function handleTripleClick<S extends Schema>(
  view: EditorView<S>,
  pos: number
): boolean {
  let doc = view.state.doc,
    $cell = cellAround(doc.resolve(pos));
  if (!$cell) return false;
  view.dispatch(
    view.state.tr.setSelection(
      (new CellSelection<S>($cell) as unknown) as Selection<S>
    )
  );
  return true;
}

function maybeSetSelection<S extends Schema>(
  state: EditorState<S>,
  dispatch: ((tr: Transaction<S>) => void) | undefined,
  selection: Selection<S>
): boolean {
  if (selection.eq(state.selection)) {
    return false;
  }
  if (dispatch) {
    dispatch(state.tr.setSelection(selection).scrollIntoView());
  }
  return true;
}

// Check whether the cursor is at the end of a cell (so that further
// motion would move out of the cell)
function atEndOfCell<S extends Schema>(
  view: EditorView<S>,
  axis: "horiz" | "vert",
  dir: 1 | -1
): number | null {
  if (!(view.state.selection instanceof TextSelection)) return null;
  let { $head } = view.state.selection;
  for (let d = $head.depth - 1; d >= 0; d--) {
    let parent = $head.node(d),
      index = dir < 0 ? $head.index(d) : $head.indexAfter(d);
    if (index !== (dir < 0 ? 0 : parent.childCount)) return null;
    if (
      parent.type.spec.tableRole === "cell" ||
      parent.type.spec.tableRole === "header_cell"
    ) {
      let cellPos = $head.before(d);
      let dirStr: "up" | "down" | "left" | "right" =
        axis === "vert"
          ? dir > 0
            ? "down"
            : "up"
          : dir > 0
          ? "right"
          : "left";
      return view.endOfTextblock(dirStr) ? cellPos : null;
    }
  }
  return null;
}

function nextCell<S extends Schema>(
  $pos: ResolvedPos<S>,
  axis: "horiz" | "vert",
  dir: 1 | -1
): ResolvedPos<S> | null {
  let start = $pos.start(-1),
    map = TableMap.get($pos.node(-1));
  let moved = map.nextCell($pos.pos - start, axis, dir);
  return moved == null ? null : $pos.node(0).resolve(start + moved);
}

function arrow<S extends Schema>(
  axis: "horiz" | "vert",
  dir: 1 | -1
): CommandFn<S> {
  return (state, dispatch, view) => {
    if (view == null) {
      return false;
    }

    let sel = state.selection;
    if (sel instanceof CellSelection) {
      return maybeSetSelection(
        state,
        dispatch,
        Selection.near(sel.$headCell, dir)
      );
    }
    if (axis !== "horiz" && !sel.empty) return false;
    let end = atEndOfCell(view, axis, dir);
    if (end == null) return false;
    if (axis === "horiz") {
      return maybeSetSelection(
        state,
        dispatch,
        Selection.near(state.doc.resolve(sel.head + dir), dir)
      );
    } else {
      let $cell = state.doc.resolve(end),
        $next = nextCell($cell, axis, dir),
        newSel;
      if ($next) newSel = Selection.near($next, 1);
      else if (dir < 0)
        newSel = Selection.near(state.doc.resolve($cell.before(-1)), -1);
      else newSel = Selection.near(state.doc.resolve($cell.after(-1)), 1);
      return maybeSetSelection(state, dispatch, newSel);
    }
  };
}

function shiftArrow<S extends Schema>(
  axis: "horiz" | "vert",
  dir: 1 | -1
): CommandFn<S> {
  return (state, dispatch, view) => {
    if (view == null) {
      return false;
    }

    let sel = (state.selection as unknown) as CellSelection<S>;
    if (!(sel instanceof CellSelection)) {
      let end = atEndOfCell(view, axis, dir);
      if (end == null) return false;
      sel = new CellSelection(state.doc.resolve(end));
    }
    let $head = nextCell(sel.$headCell, axis, dir);
    if (!$head) return false;
    return maybeSetSelection(
      state,
      dispatch,
      (new CellSelection(sel.$anchorCell, $head) as unknown) as Selection<S>
    );
  };
}

export const handleKeyDown = keydownHandler({
  ArrowLeft: arrow("horiz", -1),
  ArrowRight: arrow("horiz", 1),
  ArrowUp: arrow("vert", -1),
  ArrowDown: arrow("vert", 1),

  "Shift-ArrowLeft": shiftArrow("horiz", -1),
  "Shift-ArrowRight": shiftArrow("horiz", 1),
  "Shift-ArrowUp": shiftArrow("vert", -1),
  "Shift-ArrowDown": shiftArrow("vert", 1)
});
