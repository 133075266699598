export const fr = {
  "ACTION_BUTTONS.ADD_AREA.TITLE": "Ajouter",
  "ACTION_BUTTONS.ADD_BINDING.TITLE": "Ajouter le titre de la question",
  "ACTION_BUTTONS.CANCEL_BINDING.TITLE": "Annuler",
  "ACTION_BUTTONS.REMOVE_AREA.TITLE": "Supprimer",
  "ACTION_BUTTONS.REMOVE_BINDING.TITLE": "Supprimer le titre de la question",
  "ACTION_BUTTONS.REQUIRED.TITLE": "Basculer requis",
  "ACTION_BUTTONS.SET_AS_DEFAULT.TITLE": "Définir par défaut",
  "BAR_CHART.MOCKED_LABEL": "Données simulées",
  "BAR_CHART.NAME": "Graphique à colonnes",
  BAR_CHART_HIDDEN_PLURAL: "répondants n'ont pas vu cette question",
  BAR_CHART_HIDDEN_SINGULAR: "répondant n'a pas vu cette question",
  BAR_CHART_SKIPPED_PLURAL: "répondants ont sauté cette question",
  BAR_CHART_SKIPPED_SINGULAR: "répondant a sauté cette question",
  "CONSTANT_SUM.MATRIX.ITEM": "Élément sans titre",
  "CUSTOM_AREA.DEFAULT_NAME": "Zone personnalisée {{count}}",
  "CUSTOM_GRID.COLUMN_ACTIONS.ADD_AFTER": "Insérer la colonne après",
  "CUSTOM_GRID.COLUMN_ACTIONS.ADD_BEFORE": "Insérer la colonne avant",
  "CUSTOM_GRID.COLUMN_ACTIONS.DELETE": "Supprimer la colonne",
  "CUSTOM_GRID.DEFAULT_NAME": "Grille personnalisée {{count}}",
  "CUSTOM_GRID.ROW_ACTIONS.ADD_AFTER": "Insérer une ligne après",
  "CUSTOM_GRID.ROW_ACTIONS.ADD_BEFORE": "Insérer une ligne avant",
  "CUSTOM_GRID.ROW_ACTIONS.DELETE": "Supprimer la ligne",
  "INPUT_CHOICE.ALL_OF_THE_ABOVE_CHOICE": "Toutes ces réponses",
  "INPUT_CHOICE.DEFAULT_QUESTION_TITLE": "Question à choix {{count}}",
  "INPUT_CHOICE.NONE_OF_THE_ABOVE_CHOICE": "Aucune de ces réponses",
  "INPUT_CHOICE.OTHER_SPECIFY_CHOICE": "Autre",
  "INPUT_CONSTANT_SUM.DEFAULT_FUNCTION_TITLE":
    "Composant de fonction {{count}}",
  "INPUT_CONSTANT_SUM.ITEM_CHOICE": "Item {{number}}",
  "INPUT_CONSTANT_SUM.OTHER_SPECIFY_CHOICE": "Autre",
  "INPUT_CONSTANT_SUM.REMAINDER": "Reste",
  "INPUT_CONSTANT_SUM.TOTAL": "Total",
  "INPUT_DATETIME.DATE_FORMATS.DD_MM_YYYY": "jj-MM-aaaa",
  "INPUT_DATETIME.DATE_FORMATS.DD_MM_YYYY_HH_MM_24H": "jj-MM-aaaa HH:mm (24h)",
  "INPUT_DATETIME.DATE_FORMATS.DD_MM_YYYY_HH_MM_AM_PM":
    "jj-MM-aaaa hh:mm (AM/PM)",
  "INPUT_DATETIME.DATE_FORMATS.MM_DD_YYYY": "MM-jj-aaaa",
  "INPUT_DATETIME.DATE_FORMATS.MM_DD_YYYY_HH_MM_24H": "MM-jj-aaaa HH:mm (24h)",
  "INPUT_DATETIME.DATE_FORMATS.MM_DD_YYYY_HH_MM_AM_PM":
    "MM-jj-aaaa hh:mm (AM/PM)",
  "INPUT_DATETIME.DATE_FORMATS.YYYY_MM_DD": "aaaa-MM-jj",
  "INPUT_DATETIME.DATE_FORMATS.YYYY_MM_DD_HH_MM_24H": "aaaa-MM-jj HH:mm (24h)",
  "INPUT_DATETIME.DATE_FORMATS.YYYY_MM_DD_HH_MM_AM_PM":
    "aaaa-MM-jj hh:mm (AM/PM)",
  "INPUT_DATETIME.DEFAULT_QUESTION_TITLE": "Question de date {{count}}",
  "INPUT_FILE.AUDIO_INSTRUCTION_PLURAL":
    "Déposer les fichiers audio ici ou cliquer pour téléverser.",
  "INPUT_FILE.AUDIO_INSTRUCTION_SINGULAR":
    "Déposer le fichier audio ici ou cliquer pour téléverser.",
  "INPUT_FILE.DEFAULT_QUESTION_TITLE":
    "Question à réponse médiatique {{count}}",
  "INPUT_FILE.FILE_INSTRUCTION_PLURAL":
    "Déposer les fichiers ici ou cliquer pour téléverser.",
  "INPUT_FILE.FILE_INSTRUCTION_SINGULAR":
    "Déposer le fichier ici ou cliquer pour téléverser.",
  "INPUT_FILE.IMAGE_INSTRUCTION_PLURAL":
    "Déposer les fichiers image ici ou cliquer pour téléverser.",
  "INPUT_FILE.IMAGE_INSTRUCTION_SINGULAR":
    "Déposer le fichier image ici ou cliquer pour téléverser.",
  "INPUT_FILE.INVALID_VALUE": "valeur invalide",
  "INPUT_FILE.MAX_FILE_SIZE":
    "Limite du volume des fichiers permise: {{fileSize}}.",
  "INPUT_FILE.MIN_HEIGHT": "Hauteur minimale: {{minHeight}}px",
  "INPUT_FILE.MIN_WIDTH": "Largueur minimale: {{minWidth}}px",
  "INPUT_FILE.MIN_WIDTH_AND_MIN_HEIGHT":
    "Largueur minimale: {{minWidth}}px - Hauteur minimale: {{minHeight}}px",
  "INPUT_FILE.VIDEO_INSTRUCTION_PLURAL":
    "Déposer les fichiers vidéo ici ou cliquer pour téléverser.",
  "INPUT_FILE.VIDEO_INSTRUCTION_SINGULAR":
    "Déposer le fichier vidéo ici ou cliquer pour téléverser.",
  "INPUT_LIKERT.AFFECT_ON_X.0": "Aucun effet",
  "INPUT_LIKERT.AFFECT_ON_X.1": "Effet mineur",
  "INPUT_LIKERT.AFFECT_ON_X.2": "Neutre",
  "INPUT_LIKERT.AFFECT_ON_X.3": "Effet modéré",
  "INPUT_LIKERT.AFFECT_ON_X.4": "Effet majeur",
  "INPUT_LIKERT.AMOUNT_OF_USE.0": "Jamais utilisé",
  "INPUT_LIKERT.AMOUNT_OF_USE.1": "Presque jamais",
  "INPUT_LIKERT.AMOUNT_OF_USE.2": "De temps en temps / Parfois",
  "INPUT_LIKERT.AMOUNT_OF_USE.3": "Presqu'à chaque fois",
  "INPUT_LIKERT.AMOUNT_OF_USE.4": "Fréquemment utilisé",
  "INPUT_LIKERT.BARRIERS.0": "N'est pas un obtacle",
  "INPUT_LIKERT.BARRIERS.1": "Un peu un obstacle",
  "INPUT_LIKERT.BARRIERS.2": "Obstacle modéré",
  "INPUT_LIKERT.BARRIERS.3": "Obstacle extrême",
  "INPUT_LIKERT.COMPARISON_OF_TWO_PRODUCTS.0": "Bien pire",
  "INPUT_LIKERT.COMPARISON_OF_TWO_PRODUCTS.1": "Un peu moins bon",
  "INPUT_LIKERT.COMPARISON_OF_TWO_PRODUCTS.2": "Équivalent",
  "INPUT_LIKERT.COMPARISON_OF_TWO_PRODUCTS.3": "Un peu meilleur",
  "INPUT_LIKERT.COMPARISON_OF_TWO_PRODUCTS.4": "Meilleur",
  "INPUT_LIKERT.DEFAULT_QUESTION_TITLE": "Question d'échelle {{count}}",
  "INPUT_LIKERT.FREQUENCY.0": "Jamais",
  "INPUT_LIKERT.FREQUENCY.1": "Rarement",
  "INPUT_LIKERT.FREQUENCY.2": "Parfois",
  "INPUT_LIKERT.FREQUENCY.3": "Modérément",
  "INPUT_LIKERT.FREQUENCY.4": "Énormément",
  "INPUT_LIKERT.FREQUENCY_5_POINT.0": "Jamais",
  "INPUT_LIKERT.FREQUENCY_5_POINT.1": "Rarement",
  "INPUT_LIKERT.FREQUENCY_5_POINT.2": "Parfois",
  "INPUT_LIKERT.FREQUENCY_5_POINT.3": "Souvent",
  "INPUT_LIKERT.FREQUENCY_5_POINT.4": "Toujours",
  "INPUT_LIKERT.FREQUENCY_7_POINT.0": "Jamais",
  "INPUT_LIKERT.FREQUENCY_7_POINT.1":
    "Rarement, dans moins de 10% des chances que je pouvais avoir",
  "INPUT_LIKERT.FREQUENCY_7_POINT.2":
    "De temps en temps, dans environ 30% des chances que je pouvais avoir",
  "INPUT_LIKERT.FREQUENCY_7_POINT.3":
    "Parfois, dans environ 50% des chances que je pouvais avoir",
  "INPUT_LIKERT.FREQUENCY_7_POINT.4":
    "Souvent, dans environ 70% des chances que je pouvais avoir",
  "INPUT_LIKERT.FREQUENCY_7_POINT.5":
    "Habituellement, dans environ 90% des chances que je pourrais avoir",
  "INPUT_LIKERT.FREQUENCY_7_POINT.6": "À chaque fois",
  "INPUT_LIKERT.FREQUENCY_OF_USE.0": "Jamais",
  "INPUT_LIKERT.FREQUENCY_OF_USE.1": "Presque jamais",
  "INPUT_LIKERT.FREQUENCY_OF_USE.2": "De temps en temps / Parfois",
  "INPUT_LIKERT.FREQUENCY_OF_USE.3": "Presque à chaque fois",
  "INPUT_LIKERT.FREQUENCY_OF_USE.4": "À chaque fois",
  "INPUT_LIKERT.GOOD_BAD.0": "Très négatif",
  "INPUT_LIKERT.GOOD_BAD.1": "Neutre",
  "INPUT_LIKERT.GOOD_BAD.2": "Très positif",
  "INPUT_LIKERT.KNOWLEDGE_OF_ACTION.0": "Jamais vrai",
  "INPUT_LIKERT.KNOWLEDGE_OF_ACTION.1": "Rarement vrai",
  "INPUT_LIKERT.KNOWLEDGE_OF_ACTION.2": "Parfois, mais rarement vrai",
  "INPUT_LIKERT.KNOWLEDGE_OF_ACTION.3": "Neutre",
  "INPUT_LIKERT.KNOWLEDGE_OF_ACTION.4": "Parfois vrai",
  "INPUT_LIKERT.KNOWLEDGE_OF_ACTION.5": "Habituellement vrai",
  "INPUT_LIKERT.KNOWLEDGE_OF_ACTION.6": "Toujours vrai",
  "INPUT_LIKERT.LEVEL_OF_ACCEPTABILITY.0": "Totalement inacceptable",
  "INPUT_LIKERT.LEVEL_OF_ACCEPTABILITY.1": "Inacceptable",
  "INPUT_LIKERT.LEVEL_OF_ACCEPTABILITY.2": "Légèrement inacceptable",
  "INPUT_LIKERT.LEVEL_OF_ACCEPTABILITY.3": "Neutre",
  "INPUT_LIKERT.LEVEL_OF_ACCEPTABILITY.4": "Légèrement acceptable",
  "INPUT_LIKERT.LEVEL_OF_ACCEPTABILITY.5": "Acceptable",
  "INPUT_LIKERT.LEVEL_OF_ACCEPTABILITY.6": "Parfaitement acceptable",
  "INPUT_LIKERT.LEVEL_OF_AGREEMENT.0": "Fortement en désaccord",
  "INPUT_LIKERT.LEVEL_OF_AGREEMENT.1": "En désaccord",
  "INPUT_LIKERT.LEVEL_OF_AGREEMENT.2": "Plutôt en désaccord",
  "INPUT_LIKERT.LEVEL_OF_AGREEMENT.3": "Ni en accord ni en désaccord",
  "INPUT_LIKERT.LEVEL_OF_AGREEMENT.4": "Plutôt d'accord",
  "INPUT_LIKERT.LEVEL_OF_AGREEMENT.5": "En accord",
  "INPUT_LIKERT.LEVEL_OF_AGREEMENT.6": "Fortement en accord",
  "INPUT_LIKERT.LEVEL_OF_AGREEMENT_2.0": "Fortement en désaccord",
  "INPUT_LIKERT.LEVEL_OF_AGREEMENT_2.1": "En désaccord",
  "INPUT_LIKERT.LEVEL_OF_AGREEMENT_2.2": "Ni en accord ni en désaccord",
  "INPUT_LIKERT.LEVEL_OF_AGREEMENT_2.3": "En accord",
  "INPUT_LIKERT.LEVEL_OF_AGREEMENT_2.4": "Tout à fait en accord",
  "INPUT_LIKERT.LEVEL_OF_APPROPRIATENESS.0": "Tout à fait inapproprié",
  "INPUT_LIKERT.LEVEL_OF_APPROPRIATENESS.1": "Inapproprié",
  "INPUT_LIKERT.LEVEL_OF_APPROPRIATENESS.2": "Légèrement inapproprié",
  "INPUT_LIKERT.LEVEL_OF_APPROPRIATENESS.3": "Neutre",
  "INPUT_LIKERT.LEVEL_OF_APPROPRIATENESS.4": "Un peu approprié",
  "INPUT_LIKERT.LEVEL_OF_APPROPRIATENESS.5": "Approprié",
  "INPUT_LIKERT.LEVEL_OF_APPROPRIATENESS.6": "Tout à fait approprié",
  "INPUT_LIKERT.LEVEL_OF_AWARENESS.0": "Pas du tout au courant",
  "INPUT_LIKERT.LEVEL_OF_AWARENESS.1": "Légèrement au courant",
  "INPUT_LIKERT.LEVEL_OF_AWARENESS.2": "Un peu au courant",
  "INPUT_LIKERT.LEVEL_OF_AWARENESS.3": "Modérément au courant",
  "INPUT_LIKERT.LEVEL_OF_AWARENESS.4": "Très au courant",
  "INPUT_LIKERT.LEVEL_OF_CONCERN.0": "Pas du tout concerné",
  "INPUT_LIKERT.LEVEL_OF_CONCERN.1": "Légèrement concerné",
  "INPUT_LIKERT.LEVEL_OF_CONCERN.2": "Un peu concerné",
  "INPUT_LIKERT.LEVEL_OF_CONCERN.3": "Modérément concerné",
  "INPUT_LIKERT.LEVEL_OF_CONCERN.4": "Extrêmement concerné",
  "INPUT_LIKERT.LEVEL_OF_CONSIDERATION.0": "Ne considérerait pas",
  "INPUT_LIKERT.LEVEL_OF_CONSIDERATION.1": "Pourrait considérer ou non",
  "INPUT_LIKERT.LEVEL_OF_CONSIDERATION.2": "Considérerait définitivement",
  "INPUT_LIKERT.LEVEL_OF_DESIRABILITY.0": "Très indésirable",
  "INPUT_LIKERT.LEVEL_OF_DESIRABILITY.1": "Indésirable",
  "INPUT_LIKERT.LEVEL_OF_DESIRABILITY.2": "Neutre",
  "INPUT_LIKERT.LEVEL_OF_DESIRABILITY.3": "Souhaitable",
  "INPUT_LIKERT.LEVEL_OF_DESIRABILITY.4": "Très souhaitable",
  "INPUT_LIKERT.LEVEL_OF_DETRACTION.0": "Très peu diminué",
  "INPUT_LIKERT.LEVEL_OF_DETRACTION.1": "Neutre",
  "INPUT_LIKERT.LEVEL_OF_DETRACTION.2": "Beaucoup diminué",
  "INPUT_LIKERT.LEVEL_OF_DIFFICULTY.0": "Très difficile",
  "INPUT_LIKERT.LEVEL_OF_DIFFICULTY.1": "Difficile",
  "INPUT_LIKERT.LEVEL_OF_DIFFICULTY.2": "Neutre",
  "INPUT_LIKERT.LEVEL_OF_DIFFICULTY.3": "Facile",
  "INPUT_LIKERT.LEVEL_OF_DIFFICULTY.4": "Très facile",
  "INPUT_LIKERT.LEVEL_OF_FAMILIARITY.0": "Pas familier du tout",
  "INPUT_LIKERT.LEVEL_OF_FAMILIARITY.1": "Légèrement familier",
  "INPUT_LIKERT.LEVEL_OF_FAMILIARITY.2": "Un peu familier",
  "INPUT_LIKERT.LEVEL_OF_FAMILIARITY.3": "Modérément familier",
  "INPUT_LIKERT.LEVEL_OF_FAMILIARITY.4": "Très familier",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE.0": "Pas important du tout",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE.1": "Faible importance",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE.2": "Légèrement important",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE.3": "Neutre",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE.4": "Modérément important",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE.5": "Très important",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE.6": "Extrêmement important",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE_NUMERIC_5_POINT.0": "1",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE_NUMERIC_5_POINT.1": "2",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE_NUMERIC_5_POINT.2": "3",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE_NUMERIC_5_POINT.3": "4",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE_NUMERIC_5_POINT.4": "5",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE_NUMERIC_7_POINT.0": "1",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE_NUMERIC_7_POINT.1": "2",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE_NUMERIC_7_POINT.2": "3",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE_NUMERIC_7_POINT.3": "4",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE_NUMERIC_7_POINT.4": "5",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE_NUMERIC_7_POINT.5": "6",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE_NUMERIC_7_POINT.6": "7",
  "INPUT_LIKERT.LEVEL_OF_INFLUENCE.0": "Pas du tout d'influence",
  "INPUT_LIKERT.LEVEL_OF_INFLUENCE.1": "Légèrement d'influence",
  "INPUT_LIKERT.LEVEL_OF_INFLUENCE.2": "Un peu d'influence",
  "INPUT_LIKERT.LEVEL_OF_INFLUENCE.3": "Beaucoup d'influence",
  "INPUT_LIKERT.LEVEL_OF_INFLUENCE.4": "Énormément d'influence",
  "INPUT_LIKERT.LEVEL_OF_PARTICIPATION.0": "Non, et non considéré",
  "INPUT_LIKERT.LEVEL_OF_PARTICIPATION.1": "Non, mais considéré",
  "INPUT_LIKERT.LEVEL_OF_PARTICIPATION.2": "Oui",
  "INPUT_LIKERT.LEVEL_OF_PROBABILITY.0": "Improbable",
  "INPUT_LIKERT.LEVEL_OF_PROBABILITY.1": "Un peu improbable",
  "INPUT_LIKERT.LEVEL_OF_PROBABILITY.2": "Neutre",
  "INPUT_LIKERT.LEVEL_OF_PROBABILITY.3": "Un peu probable",
  "INPUT_LIKERT.LEVEL_OF_PROBABILITY.4": "Très propable",
  "INPUT_LIKERT.LEVEL_OF_PROBLEM.0": "Pas du tout un problème",
  "INPUT_LIKERT.LEVEL_OF_PROBLEM.1": "Problème mineur",
  "INPUT_LIKERT.LEVEL_OF_PROBLEM.2": "Problème modéré",
  "INPUT_LIKERT.LEVEL_OF_PROBLEM.3": "Problème important",
  "INPUT_LIKERT.LEVEL_OF_QUALITY_5_POINT.0": "Pauvre",
  "INPUT_LIKERT.LEVEL_OF_QUALITY_5_POINT.1": "Juste",
  "INPUT_LIKERT.LEVEL_OF_QUALITY_5_POINT.2": "Bien",
  "INPUT_LIKERT.LEVEL_OF_QUALITY_5_POINT.3": "Très bien",
  "INPUT_LIKERT.LEVEL_OF_QUALITY_5_POINT.4": "Excellent",
  "INPUT_LIKERT.LEVEL_OF_RESPONSIBILITY.0": "Pas du tout responsable",
  "INPUT_LIKERT.LEVEL_OF_RESPONSIBILITY.1": "Un peu responsable",
  "INPUT_LIKERT.LEVEL_OF_RESPONSIBILITY.2": "Responsable la plupart du temps",
  "INPUT_LIKERT.LEVEL_OF_RESPONSIBILITY.3": "Totalement responsable",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_5_POINT.0": "Très insatisfait",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_5_POINT.1": "Insatisfait",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_5_POINT.2": "Incertain",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_5_POINT.3": "Satisfait",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_5_POINT.4": "Très satisfait",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_5_POINT_2.0": "Pas du tout satisfait",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_5_POINT_2.1": "Légèrement satisfait",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_5_POINT_2.2": "Moyennement satisfait",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_5_POINT_2.3": "Très satisfait",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_5_POINT_2.4": "Extrêmement satisfait",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_7_POINT.0": "Complètement insatisfait",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_7_POINT.1":
    "Insatisfait la plupart du temps",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_7_POINT.2": "Plutôt insatisfait",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_7_POINT.3": "Ni satisfait ni insatisfait",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_7_POINT.4": "Plutôt satisfait",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_7_POINT.5":
    "Satisfait la plupart du temps",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_7_POINT.6": "Complètement satisfait",
  "INPUT_LIKERT.LEVEL_OF_SUPPORT_OPPOSITION.0": "Fortement opposé",
  "INPUT_LIKERT.LEVEL_OF_SUPPORT_OPPOSITION.1": "Un peu opposé",
  "INPUT_LIKERT.LEVEL_OF_SUPPORT_OPPOSITION.2": "Neutre",
  "INPUT_LIKERT.LEVEL_OF_SUPPORT_OPPOSITION.3": "Un peu en faveur",
  "INPUT_LIKERT.LEVEL_OF_SUPPORT_OPPOSITION.4": "Fortement en faveur",
  "INPUT_LIKERT.LIKELIHOOD.0": "Extrêmement improbable",
  "INPUT_LIKERT.LIKELIHOOD.1": "Peu probable",
  "INPUT_LIKERT.LIKELIHOOD.2": "Neutre",
  "INPUT_LIKERT.LIKELIHOOD.3": "Probable",
  "INPUT_LIKERT.LIKELIHOOD.4": "Extrêmement probable",
  "INPUT_LIKERT.MY_BELIEFS.0": "Ne réflète pas mes croyances du tout",
  "INPUT_LIKERT.MY_BELIEFS.1": "Ne réflète pas mes croyances",
  "INPUT_LIKERT.MY_BELIEFS.2": "Ne réflète plutôt pas mes croyances",
  "INPUT_LIKERT.MY_BELIEFS.3": "Neutre",
  "INPUT_LIKERT.MY_BELIEFS.4": "Réflète un peu mes croyances",
  "INPUT_LIKERT.MY_BELIEFS.5": "Réflète bien mes croyances",
  "INPUT_LIKERT.MY_BELIEFS.6": "Réflète toujours mes croyances",
  "INPUT_LIKERT.NOT_APPLICABLE": "N/A",
  "INPUT_LIKERT.PRIORITY.0": "Pas une priorité",
  "INPUT_LIKERT.PRIORITY.1": "Basse priorité",
  "INPUT_LIKERT.PRIORITY.2": "Un peu prioritaire",
  "INPUT_LIKERT.PRIORITY.3": "Neutre",
  "INPUT_LIKERT.PRIORITY.4": "Modérément une priorité",
  "INPUT_LIKERT.PRIORITY.5": "De haute priorité",
  "INPUT_LIKERT.PRIORITY.6": "Priorité essentielle",
  "INPUT_LIKERT.PRIORITY_LEVEL.0": "Pas une priorité",
  "INPUT_LIKERT.PRIORITY_LEVEL.1": "Basse priorité",
  "INPUT_LIKERT.PRIORITY_LEVEL.2": "Moyenne priorité",
  "INPUT_LIKERT.PRIORITY_LEVEL.3": "Haute priorité",
  "INPUT_LIKERT.PRIORITY_LEVEL.4": "Essentiel",
  "INPUT_LIKERT.REFLECT_ME.0": "Ne me réflète jamais",
  "INPUT_LIKERT.REFLECT_ME.1": "Ne me réflète pas",
  "INPUT_LIKERT.REFLECT_ME.2": "Ne me réflète plutôt pas",
  "INPUT_LIKERT.REFLECT_ME.3": "Neutre",
  "INPUT_LIKERT.REFLECT_ME.4": "Me réflète un peu",
  "INPUT_LIKERT.REFLECT_ME.5": "Me réflète bien",
  "INPUT_LIKERT.REFLECT_ME.6": "Me réflète toujours",
  "INPUT_MESSAGES.INVALID_DATE": "Date invalide",
  "INPUT_MESSAGES.INVALID_EMAIL": "Courriel invalide",
  "INPUT_MESSAGES.INVALID_NUMBER": "Nombre invalide",
  "INPUT_MESSAGES.INVALID_PHONE_NUMBER": "Numéro de téléphone invalide",
  "INPUT_MESSAGES.INVALID_POSTAL_CODE": "Code postal invalide (Canada)",
  "INPUT_MESSAGES.INVALID_TEXT_FORMAT": "Format invalide",
  "INPUT_MESSAGES.INVALID_TIME": "Heure invalide",
  "INPUT_MESSAGES.INVALID_ZIP_CODE": "Code zip invalide (É.-U.)",
  "INPUT_MESSAGES.REQUIRED": "Requis(e)",
  "INPUT_NUMBER.DEFAULT_QUESTION_TITLE": "Question numérique {{count}}",
  "INPUT_NUMBER.NOT_SELECTED": "N'a pas été&lt;br&gt;sélectionné(e)",
  "INPUT_RANK.SECTION_DROPDOWN.ADD_AFTER": "Insérer une section après",
  "INPUT_RANK.SECTION_DROPDOWN.ADD_BEFORE": "Insérer une section avant",
  "INPUT_RANK.SECTION_DROPDOWN.DELETE": "Supprimer la section",
  "INPUT_RANKING.DEFAULT_QUESTION_TITLE": "Question de classement {{count}}",
  "INPUT_RANKING.ITEM_CHOICE": "Item {{number}}",
  "INPUT_RANKING.OTHER_SPECIFY_CHOICE": "Autre",
  "INPUT_RANKING.SECTION": "Section sans titre",
  "INPUT_RANKING.SELECT_ALL": "Tout sélectionner",
  "INPUT_TEXT.DEFAULT_QUESTION_TITLE": "Question de commentaire {{count}}",
  "PAGE_BREAK.ENABLE_NEXT.ALL_ANSWERED.TOOLTIP":
    "Naviguer à la page suivante lorsque toutes les questions sont répondues",
  "PAGE_BREAK.ENABLE_NEXT.ALWAYS.TOOLTIP":
    "Naviguer à la page suivante sans restriction",
  "PAGE_BREAK.ENABLE_NEXT.MINIMUM_ANSWERED.TOOLTIP":
    "Naviguer à la page suivante lorsque le nombre minimum de questions est répondu",
  "PAGE_BREAK.ENABLE_NEXT.REQUIRED_ANSWERED.TOOLTIP":
    "Naviguer à la page suivante lorsque toutes les réponses sont valides",
  "PAGE_BREAK.NEXT_BUTTON": "Suivant",
  "PAGE_BREAK.PAGE_NUMBER": "Saut de page (PAGE {{current}} DE {{total}})",
  "PAGE_BREAK.PREVIOUS_BUTTON": "Précédent",
  "PAGE_BREAK.SUBMIT_BUTTON": "Soumettre",
  "QUESTION_TITLE.PLACEHOLDER": "Entrez le titre de la question ici",
  "SCALE_LABELS.ACTIONS.ADD_AFTER": "Ajouter une valeur après",
  "SCALE_LABELS.ACTIONS.ADD_BEFORE": "Ajoutez une valeur avant",
  "SCALE_LABELS.ACTIONS.DELETE": "Supprimer la valeur",
  "SCALE_LABELS.ACTIONS.ENABLE_NOT_APPLICABLE": "Activer non applicable",
  "VARIABLES.NOT_FOUND": "variable introuvable",
  "VARIABLES.QUESTION_NOT_FOUND":
    "La question n'est plus valide, veuillez en choisir une autre ou aucune question ne sera affichée dans l'e-mail."
};
