import { Node, Schema } from "prosemirror-model";
import { EditorView, NodeView } from "prosemirror-view";
import { Editor } from "../../editor";
import { WidthResizerNodeView } from "../../editor/plugins/width-resizer";

export class VideoNodeView<S extends Schema> implements NodeView<S> {
  dom: HTMLElement;

  private resizer: WidthResizerNodeView<S>;

  constructor(
    private node: Node<S>,
    view: EditorView<S>,
    getPos: () => number,
    minWidth: number,
    maxWidth: number
  ) {
    const container = document.createElement("div");
    container.style.display = "inline-block";
    container.style.lineHeight = "0";

    this.resizer = new WidthResizerNodeView(
      container,
      node,
      view,
      getPos,
      minWidth,
      maxWidth,
      (width) => {
        const editor = view as Editor<S>;
        editor.commands.updateVideo.execute({
          width: width
        });
      },
      true
    );

    this.dom = container;

    const videoContainer = document.createElement("div");
    videoContainer.style.display = "inline-block";
    videoContainer.style.overflow = "hidden";
    videoContainer.style.paddingTop = "56.25%"; /* 16:9 */
    videoContainer.style.position = "relative";
    videoContainer.style.verticalAlign = "baseline";
    videoContainer.style.width = "100%";
    videoContainer.style.borderLeft = "1px solid transparent";
    videoContainer.style.borderRight = "1px solid transparent";

    const video = document.createElement("iframe");
    video.src = node.attrs.src;
    video.frameBorder = "0";
    video.allowFullscreen = true;
    video.setAttribute("loading", "lazy");
    video.style.position = "absolute";
    video.style.top = "0";
    video.style.left = "0";
    video.style.width = "100%";
    video.style.height = "100%";
    video.style.pointerEvents = "none";
    video.setAttribute("tabindex", "-1");

    videoContainer.appendChild(video);

    this.dom.appendChild(videoContainer);
  }

  ignoreMutation(
    mutation:
      | MutationRecord
      | {
          type: "selection";
          target: Element;
        }
  ): boolean {
    const resizeIgnoreMutation = this.resizer.ignoreMutation(mutation);
    return resizeIgnoreMutation;
  }

  update(node: Node<S>): boolean {
    if (node.type !== this.node.type) {
      return false;
    }

    this.resizer.update(node);

    return true;
  }
}
