import { chainCommands } from "prosemirror-commands";
import { history, redo, undo } from "prosemirror-history";
import { undoInputRule } from "prosemirror-inputrules";
import { Schema } from "prosemirror-model";
import { CommandConfigurations, Extension } from "../../editor";

const undoInputRuleThenHistory = chainCommands(undoInputRule, undo);

export class History implements Extension<Schema> {
  constructor(
    private config?: {
      depth?: number | null;
      newGroupDelay?: number | null;
    }
  ) {}

  get name(): string {
    return "history";
  }

  plugins() {
    return [
      history({
        depth: this.config?.depth,
        newGroupDelay: this.config?.newGroupDelay
      })
    ];
  }

  commands(): CommandConfigurations<Schema> {
    return {
      undo: {
        isActive: () => false,
        isEnabled: () => (state) => undoInputRuleThenHistory(state),
        execute: () => undoInputRuleThenHistory,
        shortcuts: {
          "Mod-z": undefined
        }
      },
      redo: {
        isActive: () => false,
        isEnabled: () => (state) => redo(state),
        execute: () => redo,
        shortcuts: { "Mod-y": undefined, "Shift-Mod-z": undefined }
      }
    };
  }
}
