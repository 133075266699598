import { Block } from "./block";

interface Builder {
  id: string;
  anchor: Block;
  focus: Block;
}

export class BlockSequence {
  private readonly _id: string;
  private readonly _anchor: Block;
  private readonly _focus: Block;

  constructor(builder: Builder) {
    this._id = builder.id;
    this._anchor = builder.anchor;
    this._focus = builder.focus;
  }

  get id(): string {
    return this._id;
  }

  get anchor(): Block {
    return this._anchor;
  }

  get focus(): Block {
    return this._focus;
  }

  isEmpty(): boolean {
    return !(this._anchor && this._focus);
  }

  anchorOn(anchor: Block): BlockSequence {
    return new BlockSequence({ id: this.id, anchor, focus: this.focus });
  }

  focusOn(focus: Block): BlockSequence {
    return new BlockSequence({ id: this.id, anchor: this.anchor, focus });
  }
}
