import { Node as PMNode, Schema } from "prosemirror-model";
import { EditorView, NodeView } from "prosemirror-view";
import { GetTranslationFn, LanguageObserver } from "../../localization";

export class QuestionVariableNodeView<S extends Schema> implements NodeView<S> {
  dom: HTMLElement;

  private languageObserver: LanguageObserver<S>;

  constructor(
    private node: PMNode<S>,
    view: EditorView<S>,
    _getPos: () => number,
    private questionForCode: (code: string) => Node | null
  ) {
    this.languageObserver = new LanguageObserver(view, (getTranslation) => {
      this.updateCode(this.node.attrs.code, getTranslation);
    });

    const container = document.createElement("question-variable");

    this.dom = container;

    const getTranslation = this.languageObserver.getTranslation;

    this.updateCode(node.attrs.code, getTranslation);
  }

  update(node: PMNode<S>): boolean {
    if (node.type !== this.node.type) {
      return false;
    }

    this.node = node;

    const getTranslation = this.languageObserver.getTranslation;

    this.updateCode(this.node.attrs.code, getTranslation);

    return true;
  }

  ignoreMutation(
    mutation:
      | MutationRecord
      | {
          type: "selection";
          target: Element;
        }
  ): boolean {
    if (mutation.type !== "selection") {
      return mutation.target !== this.dom;
    }
    return false;
  }

  destroy() {
    this.languageObserver.destroy();
  }

  private updateCode(code: string, getTranslation: GetTranslationFn): void {
    while (this.dom.lastChild) {
      this.dom.removeChild(this.dom.lastChild);
    }

    const question = this.questionForCode(code);
    if (question == null) {
      const invalidMessage = document.createElement("question-not-found");
      invalidMessage.innerText = getTranslation("VARIABLES.QUESTION_NOT_FOUND");

      this.dom.appendChild(invalidMessage);
    } else {
      this.dom.appendChild(question);
    }
  }
}
