import { Node, ResolvedPos, Schema, Slice } from "prosemirror-model";
import { Selection, TextSelection } from "prosemirror-state";
import {
  CellSelection,
  isInTable,
  moveCellForward,
  selectionCell,
  tableNodeTypes
} from "prosemirror-tables";
import { CellMoveDirection } from ".";
import { CommandFn } from "../../editor";
import { canInsert, insertBlock } from "../../util";
import { getIdGenerator } from "../node-identifier";
import { CustomGridSchema } from "./schema";
import { findNextCell } from "./util";

function createTable(
  schema: CustomGridSchema,
  rowsCount: number,
  columnsCount: number
): Node<CustomGridSchema> {
  const {
    table,
    tableRow,
    tableHeaderRow,
    tableFooterRow,
    tableHeader,
    tableCell,
    tableFooter
  } = schema.nodes;

  const cells: Node<CustomGridSchema>[] = [];
  const headerCells: Node<CustomGridSchema>[] = [];
  const footerCells: Node<CustomGridSchema>[] = [];

  const width = 100 / columnsCount;

  for (let i = 0; i < columnsCount; i++) {
    const cell = tableCell.createAndFill({ colwidth: [width] })!;
    cells.push(cell);

    const headerCell = tableHeader.createAndFill({ colwidth: [width] })!;
    headerCells.push(headerCell);

    const footerCell = tableFooter.createAndFill({ colwidth: [width] })!;
    footerCells.push(footerCell);
  }

  const rows: Node<CustomGridSchema>[] = [];
  for (let i = 0; i < rowsCount; i++) {
    const row = tableRow.createChecked(null, cells);
    rows.push(row);
  }

  const headerRow: Node<CustomGridSchema> = tableHeaderRow.createChecked(
    null,
    headerCells
  );
  const footerRow: Node<CustomGridSchema> = tableFooterRow.createChecked(
    null,
    footerCells
  );

  return table.createChecked(null, [headerRow, ...rows, footerRow]);
}

export function insert<S extends Schema>(
  rows: number,
  columns: number
): CommandFn<S> {
  return (state, dispatch) => {
    const { schema } = state;

    if (!canInsert(schema.nodes.table)(state)) {
      return false;
    }

    if (dispatch) {
      const table = createTable(schema, rows, columns);

      let tr = state.tr;
      tr = insertBlock(
        tr,
        state.schema,
        table as Node<S>,
        true,
        getIdGenerator(state)
      );

      dispatch(tr);
    }

    return true;
  };
}

export function deleteCellSelection<S extends Schema>(
  _schema: CustomGridSchema
): CommandFn<S> {
  return (state, dispatch) => {
    if (!(state.selection instanceof CellSelection)) {
      return false;
    }

    let sel = state.selection;

    if (dispatch) {
      if (sel.isColSelection() && sel.isRowSelection()) {
        let tr = state.tr;

        const from = sel.$from.before(sel.$from.depth - 2);
        const to = sel.$to.after(sel.$to.depth - 2);
        tr = tr.deleteRange(from, to);

        dispatch(tr);
      } else {
        let tr = state.tr,
          baseContent = tableNodeTypes(state.schema).cell.createAndFill()!
            .content;
        sel.forEachCell((cell, pos) => {
          if (!cell.content.eq(baseContent))
            tr.replace(
              tr.mapping.map(pos + 1),
              tr.mapping.map(pos + cell.nodeSize - 1),
              new Slice(baseContent, 0, 0)
            );
        });

        if (tr.docChanged) {
          dispatch(tr);
        }
      }
    }

    return true;
  };
}

export function isCellContentSelectable<S extends Schema>(
  cellNode: Node<S>
): boolean {
  let isCellContentSelectable: boolean = true;

  cellNode.descendants((child) => {
    if (
      isCellContentSelectable !== false &&
      (child.isTextblock || child.isInline)
    ) {
      isCellContentSelectable = true;
      return true;
    }
    isCellContentSelectable = false;
    return false;
  });

  return isCellContentSelectable;
}

export function goToCell<S extends Schema>(
  direction: CellMoveDirection
): CommandFn<S> {
  return (state, dispatch) => {
    if (!isInTable(state)) return false;

    const selectedCellPos = selectionCell(state);
    if (selectedCellPos == null) return false;

    const nextCellPos: number | undefined = findNextCell(
      selectionCell(state) as ResolvedPos<S>,
      direction
    );

    if (nextCellPos == null) return false;

    const $cell = state.doc.resolve(nextCellPos);
    const cellIndexInRow = $cell.index();

    const cellNode = $cell.node().child(cellIndexInRow);

    let selection: Selection;

    if (isCellContentSelectable(cellNode)) {
      selection = TextSelection.between($cell, moveCellForward($cell));
    } else {
      selection = (new CellSelection($cell) as unknown) as Selection;
    }

    if (dispatch) {
      if (selection != null) {
        dispatch(state.tr.setSelection(selection).scrollIntoView());
      }
    }

    return true;
  };
}
