import { Fragment, Slice } from "prosemirror-model";
import { Plugin } from "prosemirror-state";
import { InputChoiceSchema } from "../schema";
import { focusedInputChoice, isSpecialChoice } from "../util";

export function pastePlugin(schema: InputChoiceSchema) {
  return new Plugin<null, InputChoiceSchema>({
    props: {
      handlePaste(view, _event, slice) {
        const { state, dispatch } = view;
        const focused = focusedInputChoice(state);
        if (focused == null) {
          return false;
        }

        if (!isPastingValues(slice, schema)) {
          let tr = state.tr;

          const { $from } = tr.selection;

          const node = $from.node();

          if (!node) {
            return false;
          }

          if (isSpecialChoice(node, schema)) {
            let fragments = new Array<Fragment<InputChoiceSchema>>();
            slice.content.forEach((n) => {
              if (n.type === schema.nodes.paragraph) {
                fragments.push(n.content);
              }
            });

            tr = tr.deleteSelection();
            fragments.reverse().forEach((f) => {
              tr = tr.insert($from.pos, f);
            });

            dispatch(tr);
          } else {
            return false;
          }

          return true;
        }

        if (dispatch) {
          let tr = state.tr;
          tr = tr.deleteSelection();

          const { selection } = tr;
          const { empty, $from } = selection;
          const isAtStart = empty && $from.parentOffset === 0;

          if (isAtStart) {
            const pos = $from.before($from.depth);
            tr = tr.insert(pos, slice.content);
          } else {
            tr = tr.insert($from.pos, slice.content);
          }

          dispatch(tr);
        }
        return true;
      }
    }
  });
}

function isPastingValues(
  slice: Slice<InputChoiceSchema>,
  schema: InputChoiceSchema
): boolean {
  const firstChild = slice.content.firstChild;
  const lastChild = slice.content.lastChild;

  if (firstChild != null && lastChild != null) {
    return (
      firstChild.type === schema.nodes.inputChoiceValue &&
      lastChild.type === schema.nodes.inputChoiceValue
    );
  } else {
    return false;
  }
}
