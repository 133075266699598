import {
  Editor as BlueXEditor,
  Schema,
  UpdateQuestionTitleBindingsActiveValue,
  scrollToPos,
  Node
} from "@blue-x/editor";
import React, { useMemo, useCallback } from "react";
import "./QuestionTitle.css";

interface QuestionTitlePropertiesPanelProps {
  editor: BlueXEditor<Schema>;
}

export const QuestionTitlePropertiesPanel: React.FC<QuestionTitlePropertiesPanelProps> = (
  props
) => {
  const { editor } = props;
  const { commands } = editor;

  const activeValue: UpdateQuestionTitleBindingsActiveValue = useMemo(() => {
    return commands.updateQuestionTitleBindings.activeValue();
  }, [commands]);

  const scrollToQuestion = useCallback(
    (question: { node: Node; pos: number }) => {
      scrollToPos(editor, question.pos, {
        behavior: "smooth",
        block: "center",
        inline: "center"
      });
    },
    [editor]
  );

  const unlinkQuestion = useCallback(
    (question: { node: Node; pos: number }) => {
      commands.updateQuestionTitleBindings.execute({ question: question });
    },
    [commands]
  );

  return (
    <div className="QuestionTitlePropertiesPanel">
      <label>Question title</label>
      <textarea rows={5} disabled={true} value={activeValue.text} />
      <label>Bound question</label>
      <div>
        {activeValue.questions.map((question) => {
          return (
            <div
              key={question.node.attrs.id}
              className="QuestionTitlePropertiesPanel-question"
            >
              <div onClick={scrollToQuestion.bind(this, question)}>
                {question.node.type.name}
              </div>
              <button
                className="bx bx-remove-question"
                onClick={unlinkQuestion.bind(this, question)}
              ></button>
            </div>
          );
        })}
      </div>
    </div>
  );
};
