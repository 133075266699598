import { RangeSelection } from "../types";
import { Inline } from "./inline";

interface Builder {
  id: string;
  start: Inline | null;
  end: Inline | null;
}

export class InlineRange {
  private readonly _id: string;
  private readonly _start: Inline | null;
  private readonly _end: Inline | null;

  static empty(id: string): InlineRange {
    return new InlineRange({ id, start: null, end: null });
  }

  constructor(builder: Builder) {
    this._id = builder.id;
    this._start = builder.start || null;
    this._end = builder.end || null;
  }

  get id(): string {
    return this._id;
  }

  get start(): Inline | null {
    return this._start;
  }

  get end(): Inline | null {
    return this._end;
  }

  isEmpty(): boolean {
    return !this.start && !this.end;
  }

  toJSON(): RangeSelection {
    return {
      id: this._id,
      startElementId: this._start?.node?.attrs?.id ?? "",
      endElementId: this._end?.node?.attrs?.id ?? ""
    };
  }
}
