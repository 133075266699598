import { NodeSpec } from "prosemirror-model";
import { DocumentBuilders, NodeConfig } from "../../../editor";

export class InputRankSectionsNode implements NodeConfig {
  get name(): string {
    return "inputRankSections";
  }

  get spec(): NodeSpec {
    return {
      content: "inputRankSectionEmpty{0,1} inputRankSection+",
      isolating: true,
      selectable: false,
      focusable: false,
      parseDOM: [
        {
          tag: "input-rank-sections"
        }
      ],
      toDOM() {
        return ["input-rank-sections", 0];
      }
    };
  }

  get builders(): DocumentBuilders {
    return { inputRankSections: { nodeType: "inputRankSections" } };
  }
}
