import { Node, Schema } from "prosemirror-model";
import { NodeView } from "prosemirror-view";

export class InputScaleLabelsNodeView<S extends Schema> implements NodeView<S> {
  dom: HTMLElement;
  contentDOM: HTMLElement;

  constructor(private node: Node<S>) {
    const container = document.createElement("input-scale-labels");

    const content = document.createElement("input-scale-labels-content");

    container.appendChild(content);

    this.dom = container;
    this.contentDOM = content;
  }

  update(node: Node<S>): boolean {
    if (node.type !== this.node.type) {
      return false;
    }

    return true;
  }
}
