import { Node, NodeSpec } from "prosemirror-model";
import { BASE_PRIORITY, DocumentBuilders, NodeConfig } from "../../../editor";
import { AlignmentType } from "../../../extensions/alignment";

const BaseSpec: NodeSpec = {
  content: "(inline|hardBreak)*",
  draggable: false,
  selectable: false,
  focusable: false,
  allowGapCursor: false,
  allowIndentation: false,
  allowAlignment: ["left", "center", "right", "justify"],
  defaultAlignment: () => "left",
  attrs: {
    id: { default: null },
    alignment: { default: null }
  }
};

function setInputRankOptionAttrs(node: Node): { [key: string]: string | null } {
  return {
    id: node.attrs.id,
    "data-alignment": node.attrs.alignment
  };
}

function getInputRankOptionAttrs(element: Element): { [key: string]: any } {
  const attrs: {
    id?: string;
    alignment?: AlignmentType;
  } = {};

  const id = element.getAttribute("id");
  if (id != null) {
    attrs.id = id;
  }

  const alignment = element.getAttribute("data-alignment") as AlignmentType;
  if (alignment != null) {
    switch (alignment) {
      case "center":
      case "justify":
      case "left":
      case "right":
        attrs.alignment = alignment;
        break;
      default:
        break;
    }
  }

  return attrs;
}
export class InputRankOptionNode implements NodeConfig {
  get name(): string {
    return "inputRankOption";
  }

  get spec(): NodeSpec {
    return {
      ...BaseSpec,
      parseDOM: [
        {
          tag: "input-rank-option",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getInputRankOptionAttrs(node as Element);
          }
        },
        {
          tag: "p",
          context: "inputRankOption/",
          priority: BASE_PRIORITY + 10
        }
      ],
      toDOM(node) {
        return ["input-rank-option", setInputRankOptionAttrs(node), 0];
      }
    };
  }

  get builders(): DocumentBuilders {
    return { inputRankOption: { nodeType: "inputRankOption" } };
  }
}

export class InputRankOptionSelectAllNode implements NodeConfig {
  get name(): string {
    return "inputRankOptionSelectAll";
  }

  get spec(): NodeSpec {
    return {
      // setting no default for id means that this node wont be used for node splitting.
      ...{
        ...BaseSpec,
        attrs: { ...BaseSpec.attrs, id: {} }
      },
      parseDOM: [
        {
          tag: "input-rank-option-select-all",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getInputRankOptionAttrs(node as Element);
          }
        },
        {
          tag: "p",
          context: "inputRankOption/",
          priority: BASE_PRIORITY + 10
        }
      ],
      toDOM(node) {
        return [
          "input-rank-option-select-all",
          setInputRankOptionAttrs(node),
          0
        ];
      }
    };
  }

  get builders(): DocumentBuilders {
    return {
      inputRankOptionSelectAll: {
        nodeType: "inputRankOptionSelectAll",
        id: null
      }
    };
  }
}

export class InputRankOptionOtherSpecifyNode implements NodeConfig {
  get name(): string {
    return "inputRankOptionOtherSpecify";
  }

  get spec(): NodeSpec {
    return {
      // setting no default for id means that this node wont be used for node splitting.
      ...{
        ...BaseSpec,
        content: "inline*",
        attrs: { ...BaseSpec.attrs, id: {} }
      },
      isolating: true,
      parseDOM: [
        {
          tag: "input-rank-option-other-specify",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getInputRankOptionAttrs(node as Element);
          }
        }
      ],
      toDOM(node) {
        return [
          "input-rank-option-other-specify",
          setInputRankOptionAttrs(node),
          0
        ];
      }
    };
  }

  get builders(): DocumentBuilders {
    return {
      inputRankOptionOtherSpecify: {
        nodeType: "inputRankOptionOtherSpecify",
        id: null
      }
    };
  }
}
