import { Node, Schema } from "prosemirror-model";
import { EditorState, Plugin } from "prosemirror-state";
import {
  CommandConfiguration,
  CommandConfigurations,
  Extension
} from "../../editor";
import { emitNotification } from "../../editor/plugins/notification";
import {
  addQuestionTitleBindings,
  addQuestionTitleBindingsInGrid,
  EditorNotificationType,
  findChildren,
  findInputs,
  findParent,
  focusedQuestionTitle,
  isEmptySelectionAtEnd,
  isEmptySelectionAtStart,
  NodeWithPos
} from "../../util";
import { updateQuestionTitleText } from "./nodes";
import {
  questionAutoCreationFlag,
  questionAutocreationFlagKey
} from "./plugins/question-title-autocreation-flag";
import {
  questionTitleBinding,
  questionTitleBindingKey
} from "./plugins/question-title-binding";
import { questionTitleInsertionRestriction } from "./plugins/question-title-insertion-restriction";
import { questionTitleOutline } from "./plugins/question-title-outline";
import { QuestionTitleSchema } from "./schema";
import {
  removeEmptyBindings,
  removeQuestionTitleBindings,
  setInputQuestionTitles
} from "./transactions";
import {
  findQuestionTitles,
  focusedElementWithQuestionTitle,
  getGridForFocus,
  nodeHasQuestionTitleBinding
} from "./utils";

export interface BindQuestionTitleCommandProps {
  pos: number;
}

export interface UpdateQuestionTitleTextCommandProps {
  text: string;
}

export interface QuestionTitleAutoCreationProps {
  isActive: boolean;
}

export interface QuestionTitleAutoCreationActiveValue {
  isActive: boolean;
}

export interface BindNodesWithoutTitlesProps {
  useQuestionStyle: boolean;
  searchIndex: number;
  starstWith: boolean;
  endsWith: boolean;
  startsWithText: string[];
  endsWithText: string[];
}

export interface UpdateQuestionTitleBindingsCommandProps {
  question: NodeWithPos<Schema>;
}

export interface UpdateQuestionTitleBindingsActiveValue {
  text: string;
  questions: NodeWithPos<Schema>[];
}

export enum QuestionTitleNotification {
  NoElementsBound = "question-title.no-elements-bound",
  ElementHasBeenBound = "question-title.element-has-been-bound",
  ElementsHaveBeenBound = "question-title.elements-have-been-bound"
}

export class QuestionTitle implements Extension<QuestionTitleSchema> {
  constructor(private shouldAddQuestionTitleOnInsert: boolean) {}

  get name(): string {
    return "questionTitle";
  }

  plugins(): Plugin[] {
    return [
      questionTitleBinding(),
      questionTitleOutline(),
      questionAutoCreationFlag(this.shouldAddQuestionTitleOnInsert),
      questionTitleInsertionRestriction()
    ];
  }

  keymaps() {
    return {
      Enter: (state: EditorState<QuestionTitleSchema>) => {
        const focused = focusedQuestionTitle(state);
        if (focused == null) {
          return false;
        } else {
          if (isEmptySelectionAtStart(state) || isEmptySelectionAtEnd(state)) {
            return false;
          } else {
            return true;
          }
        }
      },
      Backspace: (state: EditorState<QuestionTitleSchema>) => {
        const focused = focusedQuestionTitle(state);
        if (focused == null) {
          return false;
        } else {
          if (isEmptySelectionAtStart(state)) {
            return true;
          } else {
            return false;
          }
        }
      },
      Delete: (state: EditorState<QuestionTitleSchema>) => {
        const focused = focusedQuestionTitle(state);
        if (focused == null) {
          return false;
        } else {
          if (isEmptySelectionAtEnd(state)) {
            return true;
          } else {
            return false;
          }
        }
      }
    };
  }

  commands(
    schema: QuestionTitleSchema
  ): CommandConfigurations<QuestionTitleSchema> {
    return {
      startQuestionTitleBinding: this.startQuestionTitleBindingCommand(schema),
      cancelQuestionTitleBinding: this.cancelQuestionTitleBindingCommand(
        schema
      ),
      bindQuestionTitle: this.bindQuestionTitleCommand(schema),
      unlinkQuestionTitle: this.unlinkQuestionTitleCommand(schema),
      updateQuestionTitleText: this.updateQuestionTitleTextCommand(schema),
      updateQuestionTitleAutoCreationFlag: this.updateQuestionTitleAutoCreationFlagCommand(
        schema
      ),
      bindNodesWithoutTitles: this.bindNodesWithoutTitlesCommand(schema),
      updateQuestionTitleBindings: this.updateQuestionTitleBindings(schema)
    };
  }

  private startQuestionTitleBindingCommand(
    _schema: QuestionTitleSchema
  ): CommandConfiguration<QuestionTitleSchema, {}, null> {
    return {
      isActive: () => {
        return false;
      },
      isEnabled: () => {
        return true;
      },
      execute: () => {
        return (state, dispatch) => {
          if (dispatch) {
            const focusedGrid = getGridForFocus(state);
            if (
              focusedGrid != null &&
              focusedGrid.table.node.attrs.repeatGrid === true
            ) {
              let tr = state.tr;
              tr = addQuestionTitleBindingsInGrid(
                tr,
                state.schema,
                focusedGrid.table,
                focusedGrid.input
              );

              dispatch(tr);
            } else {
              let tr = state.tr;
              tr = tr.setMeta(questionTitleBindingKey, {
                type: "start"
              });

              dispatch(tr);
            }
          }
          return true;
        };
      }
    };
  }

  private cancelQuestionTitleBindingCommand(
    _schema: QuestionTitleSchema
  ): CommandConfiguration<QuestionTitleSchema, {}, null> {
    return {
      isActive: () => {
        return false;
      },
      isEnabled: () => {
        return true;
      },
      execute: () => {
        return (state, dispatch) => {
          if (dispatch) {
            let tr = state.tr;
            tr = tr.setMeta(questionTitleBindingKey, {
              type: "cancel"
            });

            dispatch(tr);
          }
          return true;
        };
      },
      requiresEditable: false
    };
  }

  private bindQuestionTitleCommand(
    _schema: QuestionTitleSchema
  ): CommandConfiguration<
    QuestionTitleSchema,
    BindQuestionTitleCommandProps,
    null
  > {
    return {
      isActive: () => {
        return false;
      },
      isEnabled: () => {
        return (state) => {
          const focused = focusedElementWithQuestionTitle(state);
          return focused != null;
        };
      },
      execute: (props) => {
        if (props == null) {
          throw new Error(
            "Must provide BindQuestionTitleCommandProps to bind question title."
          );
        }

        return (state, dispatch) => {
          const focused = focusedElementWithQuestionTitle(state);
          if (focused == null) {
            return true;
          }

          const { pos } = props;
          let node: Node<QuestionTitleSchema> | null | undefined;
          try {
            node = state.doc.nodeAt(pos);
          } catch {
            node = null;
          }

          if (node == null || node.type.spec.supportsQuestionTitle !== true) {
            return true;
          }

          const { doc, schema } = state;
          const parent = findParent(
            doc.resolve(pos),
            (n) => n.type === schema.nodes.contentVariant
          );
          if (parent != null && parent.node.attrs.active !== true) {
            return true;
          }

          if (dispatch) {
            let tr = state.tr;

            tr = addQuestionTitleBindings(tr, focused, [node]);
            tr = tr.setMeta(questionTitleBindingKey, {
              type: "cancel"
            });

            dispatch(tr);
          }
          return true;
        };
      },
      requiresEditable: false
    };
  }

  private unlinkQuestionTitleCommand(
    _schema: QuestionTitleSchema
  ): CommandConfiguration<QuestionTitleSchema, {}, null> {
    return {
      isActive: () => {
        return false;
      },
      isEnabled: () => {
        return (state) => {
          const focused = focusedElementWithQuestionTitle(state);
          return focused != null;
        };
      },
      execute: () => {
        return (state, dispatch) => {
          const focused = focusedElementWithQuestionTitle(state);
          if (focused == null) {
            return true;
          }

          const boundQuestionTitles = findQuestionTitles(
            state.doc,
            focused.node
          );

          if (boundQuestionTitles.length === 0) {
            return true;
          }

          if (dispatch) {
            let tr = state.tr;

            tr = removeQuestionTitleBindings(tr, focused, boundQuestionTitles);

            dispatch(tr);
          }
          return true;
        };
      }
    };
  }

  private updateQuestionTitleTextCommand(
    _schema: QuestionTitleSchema
  ): CommandConfiguration<
    QuestionTitleSchema,
    UpdateQuestionTitleTextCommandProps,
    null
  > {
    return {
      isActive: () => {
        return false;
      },
      isEnabled: () => {
        return (state) => {
          const focused = focusedElementWithQuestionTitle(state);
          if (focused != null) {
            return !nodeHasQuestionTitleBinding(focused.node);
          } else {
            return false;
          }
        };
      },
      execute: (props) => {
        if (props == null) {
          throw new Error(
            "Must provide UpdateQuestionTitleTextCommandProps to update question title text."
          );
        }

        const { text } = props;

        return (state, dispatch) => {
          const focused = focusedElementWithQuestionTitle(state);
          if (focused == null) {
            return true;
          }

          if (nodeHasQuestionTitleBinding(focused.node)) {
            return true;
          }

          if (dispatch) {
            let tr = state.tr;

            let updatedAttrs = {
              ...focused.node.attrs
            };

            if (text !== undefined) {
              updatedAttrs = updateQuestionTitleText(updatedAttrs, text);
            }

            tr = tr.setNodeMarkup(focused.pos, undefined, updatedAttrs);

            dispatch(tr);
          }
          return true;
        };
      }
    };
  }

  private updateQuestionTitleAutoCreationFlagCommand(
    _schema: QuestionTitleSchema
  ): CommandConfiguration<
    QuestionTitleSchema,
    QuestionTitleAutoCreationProps,
    QuestionTitleAutoCreationActiveValue | undefined
  > {
    return {
      isActive: () => true,
      isEnabled: () => true,
      execute: (props) => {
        if (props == null) {
          throw new Error(
            "Must provide QuestionTitleAutoCreationProps to toggle question title autocreation."
          );
        }

        const { isActive } = props;

        return (state, dispatch) => {
          if (dispatch) {
            let tr = state.tr;
            tr = tr.setMeta(questionAutocreationFlagKey, { isActive });

            if (isActive === false) {
              tr = removeEmptyBindings(tr);
            }

            dispatch(tr);
          }

          return true;
        };
      },
      activeValue: () => {
        return (state) => {
          const autoCreateFlag = questionAutocreationFlagKey.getState(state);
          if (autoCreateFlag == null) {
            return undefined;
          }

          const { isActive } = autoCreateFlag;

          return {
            isActive
          };
        };
      }
    };
  }

  private bindNodesWithoutTitlesCommand(
    _schema: QuestionTitleSchema
  ): CommandConfiguration<
    QuestionTitleSchema,
    BindNodesWithoutTitlesProps,
    null
  > {
    return {
      isActive: () => true,
      isEnabled: () => true,
      execute: (props) => {
        if (props == null) {
          throw new Error(
            "Must provide BindNodesWithoutTitlesProps to manually add Question titles."
          );
        }

        return (state, dispatch) => {
          const inputsWithoutQuestionTitles = findChildren(
            state.doc,
            (node) =>
              node.type.spec.attrs?.questionTitleText !== undefined &&
              node.type.spec.attrs?.questionTitleRefElementId !== undefined &&
              !nodeHasQuestionTitleBinding(node)
          );

          if (inputsWithoutQuestionTitles.length === 0) {
            emitNotification(state, {
              type: "warning",
              message: QuestionTitleNotification.NoElementsBound
            });
            return true;
          }

          const { doc, schema } = state;

          const questionTitles = new Array<NodeWithPos<QuestionTitleSchema>>();
          doc.descendants((child, pos, parent) => {
            if (child.type.spec.supportsQuestionTitle) {
              const isInCell = ["cell", "header_cell"].includes(
                parent.type.spec.tableRole
              );
              if (isInCell) {
                const $pos = doc.resolve(pos);
                const repeatGrid = findParent($pos, (n) => {
                  return (
                    n.type === schema.nodes.table && n.attrs.repeatGrid === true
                  );
                });
                if (repeatGrid == null) {
                  questionTitles.push({ node: child, pos });
                }
              } else {
                questionTitles.push({ node: child, pos });
              }
            }

            return;
          });

          let validQuestionTitles = questionTitles;
          const questionTitleStyleLevel = 6;
          if (props.useQuestionStyle) {
            validQuestionTitles = validQuestionTitles.filter(
              (q) =>
                q.node.type === state.schema.nodes.headings &&
                q.node.attrs?.level === questionTitleStyleLevel
            );
          }

          if (props.starstWith) {
            validQuestionTitles = validQuestionTitles.filter((q) => {
              const text = q.node.textContent;
              const hasText = text.length > 0;
              const hasStartsWithText = props.startsWithText.length > 0;

              return (
                hasText &&
                hasStartsWithText &&
                props.startsWithText.some(
                  (x) => x.length > 0 && text.startsWith(x)
                )
              );
            });
          }

          if (props.endsWith) {
            validQuestionTitles = validQuestionTitles.filter((q) => {
              const text = q.node.textContent;
              const hasText = text.length > 0;
              const hasEndsWithText = props.endsWithText.length > 0;

              return (
                hasText &&
                hasEndsWithText &&
                props.endsWithText.some((x) => x.length > 0 && text.endsWith(x))
              );
            });
          }

          if (dispatch) {
            let tr = state.tr;
            tr = setInputQuestionTitles(
              tr,
              inputsWithoutQuestionTitles,
              validQuestionTitles,
              props.searchIndex,
              (count) => {
                const type: EditorNotificationType =
                  count === 0 ? "warning" : "success";

                let message: QuestionTitleNotification;
                if (count === 0) {
                  message = QuestionTitleNotification.NoElementsBound;
                } else if (count === 1) {
                  message = QuestionTitleNotification.ElementHasBeenBound;
                } else {
                  message = QuestionTitleNotification.ElementsHaveBeenBound;
                }

                const params = count === 0 ? undefined : { count: count };

                emitNotification(state, {
                  type: type,
                  message: message,
                  params
                });
              }
            );

            dispatch(tr);
          }

          return true;
        };
      }
    };
  }

  private updateQuestionTitleBindings(
    _schema: QuestionTitleSchema
  ): CommandConfiguration<
    QuestionTitleSchema,
    UpdateQuestionTitleBindingsCommandProps,
    UpdateQuestionTitleBindingsActiveValue | undefined
  > {
    return {
      isActive: () => {
        return (state) => {
          const focused = focusedQuestionTitle(state);
          return focused != null;
        };
      },
      isEnabled: () => {
        return (state) => {
          const focused = focusedQuestionTitle(state);
          return focused != null;
        };
      },
      execute: (props) => {
        if (props == null) {
          throw new Error(
            "Must provide UpdateQuestionTitleBindingsCommandProps to update question title bindings."
          );
        }

        return (state, dispatch) => {
          const boundQuestionTitle = focusedQuestionTitle(state);
          if (boundQuestionTitle == null) {
            return false;
          }

          const { question } = props;

          if (dispatch) {
            let tr = state.tr;
            tr = removeQuestionTitleBindings(tr, question, [
              boundQuestionTitle
            ]);

            dispatch(tr);
          }
          return true;
        };
      },
      activeValue: () => {
        return (state) => {
          const focused = focusedQuestionTitle(state);
          if (focused == null) {
            return undefined;
          }

          const { doc } = state;
          const { node } = focused;

          const text = node.textContent;
          const questions = findInputs(doc, node);

          return {
            text: text,
            questions: questions
          };
        };
      }
    };
  }
}
