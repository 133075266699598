import { MarkSpec, Schema } from "prosemirror-model";
import {
  CommandConfiguration,
  CommandConfigurations,
  DocumentBuilders,
  Extension,
  MarkConfig
} from "../../editor";
import { textMarkIsActive, textMarkIsEnabled, toggleMark } from "../../util";

class BoldMark implements MarkConfig {
  get name(): string {
    return "bold";
  }

  get spec(): MarkSpec {
    return {
      group: "text-style",
      parseDOM: [
        { tag: "strong" },
        // This works around a Google Docs misbehavior where
        // pasted content will be inexplicably wrapped in `<b>`
        // tags with a font-weight normal.
        {
          tag: "b",
          getAttrs: (node) => {
            const element = node as HTMLElement;
            return element.style.fontWeight !== "normal" && null;
          }
        },
        {
          style: "font-weight",
          getAttrs: (value) => {
            return (
              typeof value === "string" &&
              /^(bold(er)?|[5-9]\d{2,})$/.test(value) &&
              null
            );
          }
        }
      ],
      toDOM() {
        return ["strong", 0];
      }
    };
  }

  get builders(): DocumentBuilders {
    return { bold: { markType: "bold" } };
  }
}

type BoldSchema = Schema<any, "bold">;

export class Bold implements Extension<BoldSchema> {
  get name(): string {
    return "bold";
  }

  get marks(): MarkConfig[] {
    return [new BoldMark()];
  }

  commands(schema: BoldSchema): CommandConfigurations<BoldSchema> {
    return {
      bold: this.boldCommand(schema)
    };
  }

  private boldCommand(
    schema: BoldSchema
  ): CommandConfiguration<BoldSchema, {}, undefined> {
    return {
      isActive: () => textMarkIsActive(schema.marks.bold),
      isEnabled: () => textMarkIsEnabled(schema.marks.bold),
      execute: () => toggleMark(schema.marks.bold),
      shortcuts: {
        "Mod-b": undefined
      }
    };
  }
}
