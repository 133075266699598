import {
  Fragment,
  Mark,
  Node as ProsemirrorNode,
  NodeType,
  ResolvedPos,
  Schema
} from "prosemirror-model";
import { EditorState } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { GapCursor } from "../editor/plugins/gap-cursor/gap-cursor";
import { isEqual } from "./is-equal";

export function nodeEqualsType<S extends Schema>(
  node: ProsemirrorNode<S>,
  types: NodeType<S>[] | NodeType<S>
): boolean {
  if (Array.isArray(types)) {
    return types.includes(node.type);
  } else {
    return node.type === types;
  }
}

export function activeBlock<S extends Schema>(
  type: NodeType<S>,
  attrs?: { [key: string]: any },
  marks?: Array<Mark<S>>
): (state: EditorState<S>) => ProsemirrorNode<S> | null | undefined {
  return (state: EditorState<S>) => {
    const { from, to } = state.selection;
    let count = 0;
    let nodes: ProsemirrorNode<S>[] = [];

    state.doc.nodesBetween(from, to, (node) => {
      if (node.isBlock && node.type.inlineContent) {
        count = count + 1;

        const nodeAttrs =
          attrs == null ? node.attrs : { ...node.attrs, ...attrs };
        const nodeMarks = marks == null ? node.marks : marks;

        if (node.hasMarkup(type, nodeAttrs, nodeMarks)) {
          nodes.push(node);
        }
      }
    });

    if (count > 0) {
      if (count === nodes.length) {
        const node = nodes[0];
        const hasNodeWithDifferentAttrs = nodes.some(
          (nodeWithAttrs) => !isEqual(nodeWithAttrs.attrs, node.attrs)
        );

        return hasNodeWithDifferentAttrs ? null : node;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
}

export function blockIsActive<S extends Schema>(
  type: NodeType<S>,
  predicate?: (node: ProsemirrorNode<S>) => boolean
): (state: EditorState<S>) => boolean {
  return (state: EditorState<S>) => {
    const active = activeBlock(type)(state);
    if (active) {
      return predicate != null ? predicate(active) : true;
    } else {
      return false;
    }
  };
}

export function isEmptySelectionAtStart<S extends Schema>(
  state: EditorState<S>
): boolean {
  const { empty, $from } = state.selection;
  return (
    empty && ($from.parentOffset === 0 || state.selection instanceof GapCursor)
  );
}

export function isEmptySelectionAtEnd<S extends Schema>(
  state: EditorState<S>
): boolean {
  const { empty, $from } = state.selection;
  return (
    empty && ($from.end() === $from.pos || state.selection instanceof GapCursor)
  );
}

export function isFirstChildOfParent<S extends Schema>(
  state: EditorState<S>
): boolean {
  const { $from } = state.selection;
  return $from.depth > 1
    ? (state.selection instanceof GapCursor && $from.parentOffset === 0) ||
        $from.index($from.depth - 1) === 0
    : true;
}

export function isLastChildOfParent<S extends Schema>(
  state: EditorState<S>
): boolean {
  const { $to } = state.selection;
  return $to.depth > 1
    ? (state.selection instanceof GapCursor &&
        $to.parentOffset === $to.parent.content.size) ||
        $to.index($to.depth - 1) === $to.node($to.depth - 1).childCount - 1
    : true;
}

// https://github.com/ProseMirror/prosemirror-commands/blob/master/src/commands.js#L90
// Keep going left up the tree, without going across isolating boundaries, until we
// can go along the tree at that same level
//
// You can think of this as, if you could construct each document like we do in the tests,
// return the position of the first ) backwards from the current selection.
export function findCutBefore<S extends Schema>(
  $pos: ResolvedPos<S>
): ResolvedPos<S> | null {
  // parent is non-isolating, so we can look across this boundary
  if (!$pos.parent.type.spec.isolating) {
    // search up the tree from the pos's *parent*
    for (let i = $pos.depth - 1; i >= 0; i--) {
      // starting from the inner most node's parent, find out
      // if we're not its first child
      if ($pos.index(i) > 0) {
        return $pos.doc.resolve($pos.before(i + 1));
      }

      if ($pos.node(i).type.spec.isolating) {
        break;
      }
    }
  }

  return null;
}

export function findCutAfter<S extends Schema>(
  $pos: ResolvedPos<S>
): ResolvedPos<S> | null {
  if (!$pos.parent.type.spec.isolating) {
    for (let i = $pos.depth - 1; i >= 0; i--) {
      let parent = $pos.node(i);
      if ($pos.index(i) + 1 < parent.childCount) {
        return $pos.doc.resolve($pos.after(i + 1));
      }

      if (parent.type.spec.isolating) {
        break;
      }
    }
  }
  return null;
}

/**
 * Traverse the document until an "ancestor" is found. Any nestable block can be an ancestor.
 */
export function findAncestorPosition<S extends Schema>(
  doc: ProsemirrorNode<S>,
  pos: ResolvedPos<S>,
  nestableBlocks: NodeType<S>[]
): ResolvedPos<S> {
  if (pos.depth === 1) {
    return pos;
  }

  let node: ProsemirrorNode | undefined = pos.node(pos.depth);
  let newPos = pos;
  while (pos.depth >= 1) {
    pos = doc.resolve(pos.before(pos.depth));
    node = pos.node(pos.depth);

    if (node && nestableBlocks.indexOf(node.type) !== -1) {
      newPos = pos;
    }
  }

  return newPos;
}

/**
 * Returns all top-level ancestor-nodes between $from and $to
 */
export function getAncestorNodesBetween<S extends Schema>(
  doc: ProsemirrorNode<S>,
  $from: ResolvedPos<S>,
  $to: ResolvedPos<S>,
  nestableBlocks: NodeType<S>[]
): ProsemirrorNode<S>[] {
  const nodes = Array<ProsemirrorNode<S>>();
  const maxDepth = findAncestorPosition(doc, $from, nestableBlocks).depth;
  let current = doc.resolve($from.start(maxDepth));

  while (current.pos <= $to.start($to.depth)) {
    const depth = Math.min(current.depth, maxDepth);
    const node = current.node(depth);

    if (node) {
      nodes.push(node);
    }

    if (depth === 0) {
      break;
    }

    let next: ResolvedPos = doc.resolve(current.after(depth));
    if (next.start(depth) >= doc.nodeSize - 2) {
      break;
    }

    if (next.depth !== current.depth) {
      next = doc.resolve(next.pos + 2);
    }

    if (next.depth) {
      current = doc.resolve(next.start(next.depth));
    } else {
      current = doc.resolve(next.end(next.depth));
    }
  }

  return nodes;
}

/**
 * Step through block-nodes between $from and $to and returns false if a node is
 * found that isn't of the specified type
 */
export function isRangeOfType<S extends Schema>(
  doc: ProsemirrorNode<S>,
  $from: ResolvedPos<S>,
  $to: ResolvedPos<S>,
  nodeType: NodeType<S>
): boolean {
  return (
    getAncestorNodesBetween(doc, $from, $to, [nodeType]).filter(
      (node) => node.type !== nodeType
    ).length === 0
  );
}

export function findDomRefAtPos<N extends Element>(
  position: number,
  domAtPos: (pos: number, side?: number) => { node: Node; offset: number }
) {
  const dom = domAtPos(position);
  const node = dom.node.childNodes[dom.offset];

  if (dom.node.nodeType === Node.TEXT_NODE) {
    return dom.node.parentNode as N | null;
  }

  if (!node || node.nodeType === Node.TEXT_NODE) {
    return dom.node as N;
  }

  return node as N;
}

export function getParentDomNode<N extends Element>(
  view: EditorView
): N | null {
  const domAtPos = view.domAtPos.bind(view);
  const selection = view.state.selection;
  const parent = findParent(selection.$from, () => true);
  if (parent) {
    return findDomRefAtPos<N>(parent.pos, domAtPos);
  }

  return null;
}

export function findNodeIndex<S extends Schema>(
  $pos: ResolvedPos<S>,
  toFind: ProsemirrorNode<S>,
  depth?: number
): { index: number; depth: number } {
  let d = depth == null ? $pos.depth : depth;

  let node = $pos.node(d);
  let index = $pos.index(d);

  while (index >= 0) {
    let child = node.maybeChild(index);
    if (child === toFind) {
      break;
    } else {
      index = index - 1;
    }
  }

  if (index === -1 && d !== 0) {
    return findNodeIndex($pos, toFind, d - 1);
  } else {
    return { index: index, depth: d };
  }
}

export interface ContentNodeWithPos<S extends Schema> {
  pos: number;
  start: number;
  depth: number;
  node: ProsemirrorNode<S>;
}

export interface NodeWithPos<S extends Schema> {
  pos: number;
  node: ProsemirrorNode<S>;
}

export function findParent<S extends Schema>(
  $pos: ResolvedPos<S>,
  predicate: (node: ProsemirrorNode<S>) => boolean
): ContentNodeWithPos<S> | undefined {
  for (let i = $pos.depth; i > 0; i--) {
    const node = $pos.node(i);
    if (predicate(node)) {
      return {
        pos: i > 0 ? $pos.before(i) : 0,
        start: $pos.start(i),
        depth: i,
        node
      };
    }
  }

  return undefined;
}

export function findNode<S extends Schema>(
  doc: ProsemirrorNode<S> | Fragment<S>,
  predicate: (node: ProsemirrorNode<S>, parent: ProsemirrorNode<S>) => boolean
): NodeWithPos<S> | undefined {
  let found: NodeWithPos<S> | undefined;

  doc.descendants((node, pos, parent) => {
    if (found != null) {
      return false;
    } else {
      if (predicate(node, parent)) {
        found = { node: node, pos: pos };
        return false;
      } else {
        return;
      }
    }
  });

  return found;
}

// FIXME: this is going slow on large documents
function flatten<S extends Schema>(
  node: ProsemirrorNode<S>,
  descend: boolean = true
): NodeWithPos<S>[] {
  if (!node) {
    throw new Error('Invalid "node" parameter');
  }
  const result = new Array<NodeWithPos<S>>();
  node.descendants((child, pos) => {
    result.push({ node: child, pos });
    if (!descend) {
      return false;
    }
    return;
  });
  return result;
}

export function findChildren<S extends Schema>(
  node: ProsemirrorNode<S>,
  predicate: (node: ProsemirrorNode<S>) => boolean,
  descend?: boolean
): NodeWithPos<S>[] {
  if (!node) {
    throw new Error('Invalid "node" parameter');
  } else if (!predicate) {
    throw new Error('Invalid "predicate" parameter');
  }
  return flatten(node, descend).filter((child) => predicate(child.node));
}

export function findChildrenByType<S extends Schema>(
  node: ProsemirrorNode<S>,
  nodeType: NodeType<S>,
  descend?: boolean
) {
  return findChildren(node, (child) => child.type === nodeType, descend);
}
