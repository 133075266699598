import { Mark, MarkType, ResolvedPos, Schema } from "prosemirror-model";
import {
  AllSelection,
  EditorState,
  NodeSelection,
  Selection
} from "prosemirror-state";
import { isEqual } from "./is-equal";

export function getMarkAttrs<S extends Schema>(
  type: MarkType<S>
): (state: EditorState<S>) => { [key: string]: any } {
  return (state: EditorState<S>) => {
    const { from, to } = state.selection;
    let marks = new Array<Mark<S>>();

    state.doc.nodesBetween(from, to, (node) => {
      marks = [...marks, ...node.marks];
    });

    const mark = marks.find((markItem) => markItem.type.name === type.name);

    if (mark) {
      return mark.attrs;
    }

    return {};
  };
}

export function getMarksWithPosition<S extends Schema>(
  filter?: (mark: Mark<S>) => boolean
): (state: EditorState<S>) => { from: number; to: number; mark: Mark<S> }[] {
  return (state: EditorState<S>) => {
    const { from, $from, to, empty } = state.selection;
    if (empty) {
      let marks = state.storedMarks || $from.marks();
      if (filter) {
        marks = marks.filter((mark) => filter(mark));
      }

      return marks.map((mark) => {
        return { from: from, to: to, mark: mark };
      });
    } else {
      let marks = new Array<{ from: number; to: number; mark: Mark<S> }>();
      state.doc.nodesBetween(from, to, (node, pos) => {
        let nodeMarks = node.marks;
        if (filter) {
          nodeMarks = nodeMarks.filter((mark) => filter(mark));
        }

        marks = [
          ...marks,
          ...nodeMarks.map((mark) => {
            return { from: pos, to: pos + node.nodeSize, mark: mark };
          })
        ];
      });

      return marks;
    }
  };
}

export function getMarks<S extends Schema>(
  filter?: (mark: Mark<S>) => boolean
): (state: EditorState<S>) => Mark<S>[] {
  return (state: EditorState<S>) => {
    const { from, to, empty } = state.selection;
    if (empty && state.storedMarks != null) {
      let marks = state.storedMarks;
      if (filter) {
        marks = marks.filter((mark) => filter(mark));
      }
      return marks;
    } else {
      let marks = new Array<Mark<S>>();
      state.doc.nodesBetween(from, to, (node) => {
        let nodeMarks = node.marks;
        if (filter) {
          nodeMarks = nodeMarks.filter((mark) => filter(mark));
        }
        marks = [...marks, ...nodeMarks];
      });

      return marks;
    }
  };
}

export function getMarksWithPositionOfType<S extends Schema>(
  type: MarkType<S>
): (state: EditorState<S>) => { from: number; to: number; mark: Mark<S> }[] {
  return getMarksWithPosition<S>(
    (markItem) => markItem.type.name === type.name
  );
}

export function getMarksOfType<S extends Schema>(
  type: MarkType<S>
): (state: EditorState<S>) => Mark<S>[] {
  return getMarks<S>((markItem) => markItem.type.name === type.name);
}

export function getMarkRange<S extends Schema>(
  state: EditorState<S>,
  mark: Mark<S>
): { from: number; to: number } | null {
  if (!state || !mark) {
    return null;
  }

  const { selection } = state;

  let $pos: ResolvedPos<S>;
  if (selection instanceof AllSelection) {
    $pos = Selection.atStart(state.doc).$from;
  } else {
    $pos = selection.$from;
  }

  const start = $pos.parent.childAfter($pos.parentOffset);

  if (!start.node) {
    return null;
  }

  let startIndex = $pos.index();
  let startPos = $pos.start() + start.offset;
  let endIndex = startIndex + 1;
  let endPos = startPos + start.node.nodeSize;

  while (
    startIndex > 0 &&
    mark.isInSet($pos.parent.child(startIndex - 1).marks)
  ) {
    startIndex -= 1;
    startPos -= $pos.parent.child(startIndex).nodeSize;
  }

  while (
    endIndex < $pos.parent.childCount &&
    mark.isInSet($pos.parent.child(endIndex).marks)
  ) {
    endPos += $pos.parent.child(endIndex).nodeSize;
    endIndex += 1;
  }

  return { from: startPos, to: endPos };
}

function activeMarkForCursor<S extends Schema>(
  type: MarkType<S>,
  attrs?: { [key: string]: any },
  defaultValue?: { [key: string]: any }
) {
  return (state: EditorState<S>) => {
    const { $from } = state.selection;
    const node = $from.node();
    let set = state.storedMarks || $from.marks();
    if (node.type.allowsMarkType(type) && node.type.spec.modifyMarks != null) {
      const added: Mark[] = node.type.spec.modifyMarks(node, type);
      set = set.concat(added);
    }

    const mark = type.isInSet(set);
    if (mark) {
      if (attrs) {
        return isEqual(mark.attrs, attrs) ? mark : null;
      } else {
        return mark;
      }
    } else {
      if (defaultValue != null) {
        if (attrs != null) {
          return isEqual(attrs, defaultValue)
            ? type.create(defaultValue)
            : null;
        } else {
          return type.create(defaultValue);
        }
      } else {
        return null;
      }
    }
  };
}

function activeMarkForSelection<S extends Schema>(
  type: MarkType<S>,
  attrs?: { [key: string]: any },
  defaultValue?: { [key: string]: any }
) {
  return (state: EditorState<S>) => {
    const { ranges } = state.selection;
    let count = 0;
    let marks: Mark<S>[] = [];

    ranges.forEach((range) => {
      const { $from, $to } = range;
      const from = $from.pos;
      const to = $to.pos;

      state.doc.nodesBetween(from, to, (node, _, parent) => {
        if (node.isInline) {
          count = count + 1;
          let set = node.marks;
          if (
            parent.type.allowsMarkType(type) &&
            parent.type.spec.modifyMarks != null
          ) {
            const added: Mark[] = parent.type.spec.modifyMarks(parent, type);
            set = set.concat(added);
          }

          const mark = type.isInSet(set);

          if (mark) {
            if (mark.attrs) {
              const activeMark = mark.type.isInSet(marks);
              if (activeMark == null) {
                marks.push(mark);
              } else {
                if (isEqual(mark.attrs, activeMark.attrs)) {
                  marks.push(mark);
                }
              }
            } else {
              marks.push(mark);
            }
          } else {
            if (defaultValue != null) {
              marks.push(type.create(defaultValue));
            }
          }

          return false;
        }

        return;
      });
    });

    const filteredMarks =
      attrs == null ? marks : marks.filter((x) => isEqual(x.attrs, attrs));

    if (count > 0) {
      if (count === filteredMarks.length) {
        const startingMark = filteredMarks[0];
        const hasSameAttributes = filteredMarks.every((mark) =>
          isEqual(mark.attrs, startingMark.attrs)
        );

        return hasSameAttributes ? startingMark : null;
      } else {
        return null;
      }
    } else {
      if (defaultValue != null) {
        if (attrs != null) {
          return isEqual(attrs, defaultValue)
            ? type.create(defaultValue)
            : null;
        } else {
          return type.create(defaultValue);
        }
      } else {
        return null;
      }
    }
  };
}

export function activeTextMark<S extends Schema>(
  type: MarkType<S>,
  attrs?: { [key: string]: any },
  defaultValue?: { [key: string]: any }
): (state: EditorState<S>) => Mark<S> | null | undefined {
  return (state: EditorState<S>) => {
    const { selection } = state;
    const { empty } = selection;
    if (empty) {
      return activeMarkForCursor(type, attrs, defaultValue)(state);
    } else {
      return activeMarkForSelection(type, attrs, defaultValue)(state);
    }
  };
}

export function textMarkIsActive<S extends Schema>(
  type: MarkType<S>,
  attrs?: { [key: string]: any },
  defaultValue?: { [key: string]: any }
): (state: EditorState<S>) => boolean {
  return (state: EditorState<S>) => {
    return activeTextMark(type, attrs, defaultValue)(state) != null;
  };
}

export function textMarkIsEnabled<S extends Schema>(type: MarkType<S>) {
  return (state: EditorState<S>) => {
    const { selection } = state;
    if (selection instanceof NodeSelection) {
      const node = selection.node;
      if (node.type.allowsMarkType(type)) {
        return true;
      } else {
        let allowedCount = 0;
        let textNodes = 0;
        node.descendants((child) => {
          if (child.isTextblock) {
            const allowed =
              child.type.allowsMarkType(type) && child.content.size > 0;
            textNodes = textNodes + 1;

            if (allowed) {
              allowedCount = allowedCount + 1;
            }
          }
        });

        return textNodes > 0 ? textNodes === allowedCount : false;
      }
    } else {
      return true;
    }
  };
}
