import { Schema } from "prosemirror-model";
import { NodeDTO } from "../../util";

export enum InputScaleWidth {
  default = 100,
  minimum = 25,
  maximum = 100
}

export enum InputScaleControlType {
  labelsInRow = "labelRow",
  dropdown = "dropdown",
  listbox = "listbox"
}

export enum InputScaleOrientation {
  bottom = "bottom",
  top = "top",
  boxed = "boxed"
}

export enum InputScaleRows {
  default = 5,
  minimum = 1,
  maximum = 10
}

export interface ScaleOption {
  label: string;
}

export interface Scale {
  id: string;
  label: string;
  options: ScaleOption[];
  default?: boolean;
}

export interface InputScaleValue {
  id: string;
  content: NodeDTO[];
  coding: string | null;
}

export const userDefinedScale: Scale = {
  id: "user_defined",
  label: "User defined",
  options: []
};

export type InputScaleSchema = Schema<
  | "inputScale"
  | "inputScaleValue"
  | "inputScaleNotApplicable"
  | "inputScaleLabels"
  | "inputScaleLabel"
  | "inputScaleLabelNotApplicable",
  any
>;
