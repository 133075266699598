import { ChangeSet } from "prosemirror-changeset";
import { Node, Schema } from "prosemirror-model";
import { NodeSelection, Plugin, PluginKey } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";
import { Editor } from "../../../editor";
import { selectionFocusKey } from "../../../editor/plugins/selection-focus";
import { addQuestionTitleBindings, findInputs } from "../../../util";
import { findChildren } from "../../../util/nodes";
import { removeQuestionTitleBindings } from "../transactions";
import {
  findQuestionTitles,
  focusedElementWithQuestionTitle,
  supportsQuestionTitleBinding
} from "../utils";

type QuestionTitleSchema = Schema<"paragraph" | "headings", any>;

export const questionTitleBindingKey = new PluginKey<
  QuestionTitleBindingActivity<Schema> | null,
  Schema
>("questionTitleLinking");

interface QuestionTitleBindingActivity<S extends Schema> {
  target: Node<S>;
  pos: number;
}

interface StartBindingAction {
  type: "start";
}

interface CancelBindingAction {
  type: "cancel";
}

export function questionTitleBinding() {
  return new Plugin<
    QuestionTitleBindingActivity<QuestionTitleSchema> | null,
    QuestionTitleSchema
  >({
    key: questionTitleBindingKey,
    state: {
      init(_, _state) {
        return null;
      },
      apply(tr, value, _oldState, newState) {
        const meta = tr.getMeta(questionTitleBindingKey) as
          | StartBindingAction
          | CancelBindingAction
          | undefined;

        if (meta == null) {
          const focused = selectionFocusKey.getState(newState);
          if (focused == null) {
            return null;
          } else if (
            focused.pos === value?.pos &&
            focused.node === value.target
          ) {
            return value;
          } else {
            return null;
          }
        }

        switch (meta.type) {
          case "start":
            const focused = focusedElementWithQuestionTitle(newState);
            if (focused == null) {
              return null;
            }

            return { target: focused.node, pos: focused.pos };
          case "cancel":
            return null;
          default:
            return value;
        }
      }
    },
    appendTransaction(transactions, oldState, newState) {
      if (!transactions.find((tr) => tr.docChanged)) {
        return;
      }

      if (transactions.length === 0) {
        return;
      }

      const idTransaction = transactions.find((t) => t.getMeta("ids") != null);
      const ids = idTransaction?.getMeta("ids") as
        | Array<{ new: string; old: string }>
        | undefined;

      let changeset = ChangeSet.create(oldState.doc);

      transactions.forEach((tr) => {
        changeset = changeset.addSteps(tr.doc, tr.mapping.maps);
      });

      if (changeset.changes.length === 0) {
        return;
      }

      let tr = newState.tr;

      changeset.changes.forEach((change) => {
        if (change.deleted.length > 0) {
          const { fromA, toA } = change;
          const removed = oldState.doc.slice(fromA + 1, toA);

          removed.content.descendants((node) => {
            if (node.type.spec.supportsQuestionTitle === true) {
              //unlink inputs that use this question title
              const inputs = findInputs(tr.doc, node);

              inputs.forEach((input) => {
                const newBoundQuestionTitles = findQuestionTitles(
                  newState.doc,
                  input.node
                );

                const boundQuestionTitles = findQuestionTitles(
                  oldState.doc,
                  input.node
                );

                const shouldRemoveBinding = newBoundQuestionTitles.find(
                  (question) => question.node.content.size < 1
                );

                if (
                  newBoundQuestionTitles.length === 0 ||
                  (newBoundQuestionTitles.length > 0 && shouldRemoveBinding)
                ) {
                  tr = removeQuestionTitleBindings(
                    tr,
                    input,
                    boundQuestionTitles
                  );
                }
              });
            }
          });
        }

        if (change.inserted.length > 0) {
          const { fromB, toB } = change;
          const inserted = tr.doc.slice(fromB, toB);

          if (ids != null) {
            tr.doc.nodesBetween(fromB, toB, (node, pos) => {
              if (supportsQuestionTitleBinding(node)) {
                const newInputId = ids.find((id) => id.new === node.attrs.id);
                if (newInputId != null && newInputId.old != null) {
                  const childIds = node.attrs?.questionTitleRefElementId as
                    | string[]
                    | undefined;

                  if (childIds != null) {
                    const newChildIds = new Array<string>();
                    childIds.forEach((id) => {
                      const newId = ids.find((x) => x.old === id);
                      if (newId != null) {
                        newChildIds.push(newId.new);
                      }
                    });

                    const selection = tr.selection;

                    tr = tr.setNodeMarkup(pos, node.type, {
                      ...node.attrs,
                      questionTitleRefElementId: newChildIds
                    });

                    if (selection instanceof NodeSelection) {
                      tr = tr.setSelection(
                        NodeSelection.create(tr.doc, selection.from)
                      );
                    }
                  }
                }
              }
            });
          }

          inserted.content.descendants((node) => {
            if (node.type.spec.supportsQuestionTitle === true) {
              //relink inputs that use this question title
              const inputs = findInputs(tr.doc, node);

              inputs.forEach((input) => {
                const boundQuestionTitles = findQuestionTitles(
                  tr.doc,
                  input.node
                );

                tr = addQuestionTitleBindings(
                  tr,
                  input,
                  boundQuestionTitles.map((x) => x.node)
                );
              });
            }
          });
        }
      });

      return tr;
    },
    props: {
      editable(state) {
        const value = this.getState(state);
        if (value != null) {
          return false;
        }

        return true;
      },
      attributes(state) {
        const value = this.getState(state);
        if (value != null) {
          return { class: "ProseMirror-disable-selection" };
        }

        return;
      },
      decorations(state) {
        const value = questionTitleBindingKey.getState(state);
        if (value == null) {
          return DecorationSet.empty;
        }

        const targetNodes = findChildren(
          state.doc,
          (node) => {
            return node.type.spec.supportsQuestionTitle === true;
          },
          true
        );

        const targetDecorations = targetNodes.map(({ node, pos }) => {
          return Decoration.node(pos, pos + node.nodeSize, {
            class: "ProseMirror-question-title-binding"
          });
        });

        return DecorationSet.create(state.doc, [
          ...targetDecorations,
          Decoration.node(
            value.pos,
            value.pos + value.target.nodeSize,
            {},
            { type: "question-title-binding" }
          )
        ]);
      },
      handleDOMEvents: {
        mousedown(view, event) {
          const state = this.getState(view.state);
          if (state != null) {
            const target = event.target as HTMLElement;
            const bindingTarget = target.closest(
              ".ProseMirror-question-title-binding"
            );
            if (bindingTarget == null) {
              event.preventDefault();
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }
      },
      handleClickOn(view, _pos, node, nodePos, _event, direct) {
        const state = this.getState(view.state);
        if (state != null) {
          if (direct && node.type.spec.supportsQuestionTitle === true) {
            const editor = view as Editor<QuestionTitleSchema>;
            editor.commands.bindQuestionTitle.execute({ pos: nodePos });
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    }
  });
}
