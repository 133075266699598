import { GetTranslationFn } from "../../../extensions";

export class ValidationMessagesNodeView {
  dom: HTMLElement;

  private required: {
    container: HTMLElement;
    text: HTMLElement;
    icon: HTMLElement;
  } | null = null;

  constructor(private hasText = true) {
    const validationMessages = createValidationMessages();
    this.dom = validationMessages;
  }

  ignoreMutation(
    mutation:
      | MutationRecord
      | {
          type: "selection";
          target: Element;
        }
  ): boolean {
    if (mutation.target === this.dom) {
      return true;
    }

    if (this.required != null) {
      if (mutation.target === this.required.container) {
        return true;
      }

      if (mutation.target === this.required.text) {
        return true;
      }

      if (mutation.target === this.required.icon) {
        return true;
      }
    }

    return false;
  }

  setRequired(
    required: boolean,
    done: boolean,
    getTranslation: GetTranslationFn
  ): void {
    if (required) {
      if (this.required == null) {
        const required = creatRequiredMessage();
        const { container } = required;
        this.dom.appendChild(container);

        this.required = required;
      }

      if (this.required != null) {
        const { container, icon, text } = this.required;

        container.classList.toggle("done", done);

        icon.classList.toggle("bx-asterisk", !done);
        icon.classList.toggle("bx-check-circle", done);

        if (this.hasText) {
          text.innerText = getTranslation("INPUT_MESSAGES.REQUIRED");
        }
      }
    } else {
      if (this.required != null) {
        this.dom.removeChild(this.required.container);
        this.required = null;
      }
    }

    if (this.required == null) {
      this.dom.classList.add("hidden");
    } else {
      this.dom.classList.remove("hidden");
    }
  }
}

function createValidationMessages(): HTMLElement {
  const validationMessages = document.createElement("span");
  validationMessages.classList.add("validation-messages");
  validationMessages.classList.add("hidden");

  return validationMessages;
}

function creatRequiredMessage(): {
  container: HTMLElement;
  text: HTMLElement;
  icon: HTMLElement;
} {
  const spanElt = document.createElement("span");
  spanElt.classList.add("required");

  const iconElt = document.createElement("span");
  iconElt.classList.add("icon");
  iconElt.classList.add("bx");

  spanElt.appendChild(iconElt);

  const textElt = document.createElement("span");
  textElt.classList.add("text");

  spanElt.appendChild(textElt);

  return { container: spanElt, text: textElt, icon: iconElt };
}
