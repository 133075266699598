import { Node, NodeSpec, Schema } from "prosemirror-model";
import { DocumentBuilders, NodeConfig } from "../../../editor";
import { AlignmentType, getAlignment } from "../../alignment";

const BaseSpec: NodeSpec = {
  content: "(inline|hardBreak)*",
  draggable: false,
  selectable: false,
  focusable: false,
  allowGapCursor: false,
  allowIndentation: false,
  isolating: true,
  attrs: {
    id: { default: null },
    alignment: { default: null }
  }
};

export class InputScaleLabelNode implements NodeConfig {
  get name(): string {
    return "inputScaleLabel";
  }

  get spec(): NodeSpec {
    return {
      ...BaseSpec,
      parseDOM: [
        {
          tag: "input-scale-label",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getAttrs(node as HTMLElement);
          }
        },
        {
          tag: "[data-id][data-type='standard']",
          context: "inputScaleLabels/",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getAttrs(node as HTMLElement);
          }
        }
      ],
      toDOM(node) {
        return ["input-scale-label", setAttrs(node), 0];
      },
      defaultAlignment: (_node: Node<Schema>, _parent: Node<Schema>) => {
        return "center";
      },
      applyBlueprint(
        attrs: Record<string, any>,
        blueprint: Record<string, any>
      ) {
        const updated = { ...attrs };

        updated.alignment = blueprint.alignment;

        return updated;
      }
    };
  }

  get builders(): DocumentBuilders {
    return { inputScaleLabel: { nodeType: "inputScaleLabel" } };
  }
}

export class InputScaleLabelNotApplicableNode implements NodeConfig {
  get name(): string {
    return "inputScaleLabelNotApplicable";
  }

  get spec(): NodeSpec {
    return {
      // setting no default for id means that this node wont be used for node splitting.
      ...{ ...BaseSpec, attrs: { ...BaseSpec.attrs, id: {} } },
      isolating: true,
      parseDOM: [
        {
          tag: "input-scale-label-not-applicable",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getAttrs(node as HTMLElement);
          }
        },
        {
          tag: "[data-id][data-type='not-applicable']",
          context: "inputScaleLabels/",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getAttrs(node as HTMLElement);
          }
        }
      ],
      toDOM(node) {
        return ["input-scale-label-not-applicable", setAttrs(node), 0];
      },
      defaultAlignment: (_node: Node<Schema>, _parent: Node<Schema>) => {
        return "center";
      },
      applyBlueprint(
        attrs: Record<string, any>,
        blueprint: Record<string, any>
      ) {
        const updated = { ...attrs };

        updated.alignment = blueprint.alignment;

        return updated;
      }
    };
  }

  get builders(): DocumentBuilders {
    return {
      inputScaleLabelNotApplicable: {
        nodeType: "inputScaleLabelNotApplicable",
        id: null
      }
    };
  }
}

function getAttrs(element: HTMLElement): { [key: string]: any } {
  const attrs: { id?: string; alignment?: AlignmentType } = {};

  const id = element.getAttribute("id");
  if (id != null) {
    attrs.id = id;
  }

  const legacyId = element.getAttribute("data-id");
  if (legacyId != null) {
    attrs.id = legacyId;
  }

  const alignment = getAlignment(element.style.textAlign);
  if (alignment != null) {
    attrs.alignment = alignment;
  }

  return attrs;
}

function setAttrs(node: Node): { [key: string]: any } {
  let styles: string[] = [];
  if (node.attrs.alignment != null) {
    styles = styles.concat(`text-align: ${node.attrs.alignment}`);
  }
  const style = styles.length > 0 ? { style: styles.join("; ") } : {};

  return {
    id: node.attrs.id,
    ...style
  };
}
