import { Node, Schema, NodeType } from "prosemirror-model";
import { PluginKey, Plugin } from "prosemirror-state";
import { Decoration, DecorationSet, EditorView } from "prosemirror-view";
import { focusedReport } from "../util";
import { editableKey } from "../../../editor/plugins/editable";
import { findChildren, NodeWithPos } from "../../../util";
import { Editor } from "../../../editor";
import { reportOutlineOverlay } from "../report-outline-overlay";
import { focusedBarChart } from "../../bar-chart/util";
import {
  BarChartQuestionType,
  BarChartRankConfiguration
} from "../../bar-chart/schema";

export const reportOutlineKey = new PluginKey<DecorationSet, Schema>(
  "reportOutline"
);

interface DOMObserverView extends EditorView {
  domObserver: DOMObserver;
}

interface DOMObserver {
  start(): void;
  stop(): void;
}

export function reportOutline(nodeType: NodeType<Schema>) {
  return new Plugin({
    key: reportOutlineKey,
    props: {
      decorations(state) {
        const editable = editableKey.getState(state);
        if (editable && editable.focusable === false) {
          return DecorationSet.empty;
        }

        const focused = focusedReport(state);
        if (focused == null) {
          return DecorationSet.empty;
        }

        const { node } = focused;
        const { schema } = state;
        const questionType = node.attrs.questionType as BarChartQuestionType;
        const inputId = node.attrs.inputId as string;
        const rankConfiguration = node.attrs
          .rankConfiguration as BarChartRankConfiguration;
        const sectionId = rankConfiguration
          ? (rankConfiguration.sectionId as string)
          : "";

        const inputs =
          questionType !== BarChartQuestionType.ranking
            ? findInputsWithSameId(state.doc, inputId)
            : findInputsWithSameId(state.doc, sectionId);

        const decorations = [
          ...decorationsLinkedForInputs(inputs),
          ...decorationsOverlayForInputs(nodeType, schema, inputs)
        ];

        return DecorationSet.create(state.doc, decorations);
      }
    },
    view(view) {
      const updateOutlineContainer = () => {
        const focused = focusedBarChart(view.state);

        if (!focused) {
          return;
        }
        const container = view.dom.querySelector<HTMLElement>(
          `.ProseMirror-report-overlay-container`
        );

        const inputLinked = view.dom.querySelector<HTMLElement>(
          `.ProseMirror-input-linked`
        );

        if (container && inputLinked) {
          const width = inputLinked.offsetWidth;
          const top = inputLinked.offsetTop;
          const left = inputLinked.offsetLeft;
          const height = inputLinked.offsetHeight;
          const expectedWidth = width + 20;

          if (expectedWidth !== container.offsetWidth) {
            const trailing = view.dom.querySelector<HTMLElement>(
              `.ProseMirror-report-overlay-trailing`
            );

            (view as DOMObserverView).domObserver.stop();
            container.style.width = `${expectedWidth}px`;
            container.style.height = `${height}px`;
            container.style.top = `${top}px`;
            container.style.left = `${left}px`;

            if (trailing) {
              trailing.style.width = `20px`;
              trailing.style.top = `-2px`;
              trailing.style.height = `${height + 4}px`;
            }
            (view as DOMObserverView).domObserver.start();
          }
        }
      };

      return {
        update: (_view) => {
          updateOutlineContainer();
        },
        destroy: () => {}
      };
    }
  });
}

export function findInputsWithSameId<S extends Schema>(
  doc: Node<S>,
  id: string
): NodeWithPos<S>[] {
  return findChildren(
    doc,
    (child) => {
      const childId = child.attrs?.id as string | undefined;
      if (childId != null && childId === id) {
        return true;
      } else {
        return false;
      }
    },
    true
  );
}

function decorationsLinkedForInputs(
  inputs: NodeWithPos<Schema>[]
): Decoration[] {
  const decorations = inputs.map((input) => {
    const from = input.pos;
    const to = from + input.node.nodeSize;

    return Decoration.node(from, to, {
      class: "ProseMirror-input-linked"
    });
  });

  return decorations;
}

function decorationsOverlayForInputs<S extends Schema>(
  nodeType: NodeType<S>,
  schema: S,
  inputs: NodeWithPos<S>[]
): Decoration[] {
  const decorations = inputs.map((input) => {
    const { pos } = input;
    return Decoration.widget(
      pos,
      (view, _getPos) => {
        const editor = view as Editor<S>;

        const onClick = () => {
          onRemoveClick(editor, nodeType, schema);
        };

        return reportOutlineOverlay(onClick);
      },
      {
        stopEvent: () => {
          return true;
        },
        ignoreSelection: true
      }
    );
  });

  return decorations;
}

function onRemoveClick<S extends Schema>(
  editor: Editor<S>,
  type: NodeType<S>,
  schema: Schema
): void {
  switch (type) {
    case schema.nodes.barChart:
      editor.commands.updateBarChart.execute({
        segments: []
      });
      break;

    default:
      break;
  }
}
