import {
  ConstantSumActiveValue,
  ConstantSumLogicDirection,
  ConstantSumType,
  Editor as BlueXEditor,
  Schema
} from "@blue-x/editor";
import React, { useCallback, useMemo } from "react";
import {
  CheckboxFormControl,
  NumberFormControl,
  SelectFormControl,
  TextFormControl
} from "../ProportiesPanelControls";
import "./InputNumber.css";

interface ConstantSumPropertiesProps {
  editor: BlueXEditor<Schema>;
}

export const ConstantSumPropertiesPanel: React.FC<ConstantSumPropertiesProps> = (
  props
) => {
  const { editor } = props;
  const { commands } = editor;

  const activeValue: ConstantSumActiveValue = useMemo(() => {
    return commands.updatedConstantSum.activeValue();
  }, [commands]);

  const update = useCallback(
    (value) => {
      commands.updatedConstantSum.execute(value, false);
    },
    [commands]
  );

  const hasAssociationErrors = useMemo(() => {
    const direction = activeValue.direction;
    const count = activeValue.associations.reduce((acc, elem) => {
      if (elem.type === "constantSum" && elem.direction === direction) {
        return acc + 1;
      } else {
        return acc;
      }
    }, 0);

    return count > 0;
  }, [activeValue]);

  return (
    <div className="ConstantSumPropertiesPanel">
      <label>Settings</label>
      <TextFormControl
        label={"Name"}
        value={activeValue.name}
        onUpdate={(value) => {
          update({ name: value });
        }}
      />

      <SelectFormControl
        label={"Direction"}
        value={activeValue.direction}
        onUpdate={(value) => {
          update({ direction: value });
        }}
      >
        <option value={ConstantSumLogicDirection.horizontal}>Horizontal</option>
        <option value={ConstantSumLogicDirection.vertical}>Vertical</option>
      </SelectFormControl>

      <SelectFormControl
        label={"Type"}
        value={activeValue.type}
        onUpdate={(value) => {
          update({ type: value });
        }}
      >
        <option value={ConstantSumType.sum}>Sum</option>
        <option value={ConstantSumType.distribution}>Distribution</option>
      </SelectFormControl>

      <TextFormControl
        label={"Prefix"}
        value={activeValue.prefix}
        onUpdate={(value) => {
          update({ prefix: value });
        }}
      />

      <TextFormControl
        label={"Suffix"}
        value={activeValue.suffix}
        onUpdate={(value) => {
          update({ suffix: value });
        }}
      />

      <NumberFormControl
        label={"Minimum"}
        value={activeValue.minValue}
        onUpdate={(value) => {
          update({ minValue: value });
        }}
      />

      <NumberFormControl
        label={"Maximum"}
        value={activeValue.maxValue}
        onUpdate={(value) => {
          update({ maxValue: value });
        }}
      />

      <NumberFormControl
        label={"Number of decimals"}
        value={activeValue.numberOfDecimals}
        onUpdate={(value) => {
          update({ numberOfDecimals: value });
        }}
      />

      <CheckboxFormControl
        label={"Required"}
        value={activeValue.required}
        onUpdate={(value: boolean) => {
          update({ required: value });
        }}
      />

      <div>
        {activeValue.associations.map((a, i) => {
          return <div key={i}>{a.name}</div>;
        })}
      </div>

      {hasAssociationErrors ? (
        <div style={{ color: "red" }}>
          There are one or more circular references where a function refers to
          itself.
        </div>
      ) : null}

      {activeValue.hasRepeatErrors ? (
        <div style={{ color: "red" }}>
          Function component will encounter an error if last row is repeated by
          respondent as vertical function refers to itself.
        </div>
      ) : null}
    </div>
  );
};
