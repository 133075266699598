import { Command, DragEventData, setDropInsertData } from "@blue-x/editor";
import React, { useCallback, useMemo } from "react";
import "./ToolbarButton.css";
import { useToolbarDropdown } from "./ToolbarDropdown";

interface Props {
  command: Command;
  attributes?: Record<string, any>;
  shouldCloseDropdown?: boolean;
  drag?: DragEventData;
}

const ToolbarButton: React.FC<Props> = (props) => {
  const { command, attributes, shouldCloseDropdown, children, drag } = props;
  const dropdown = useToolbarDropdown();

  const closeDropdown = useCallback(() => {
    const { setOpened } = dropdown;
    setOpened(false);
  }, [dropdown]);

  const draggable = useMemo(() => {
    return drag != null;
  }, [drag]);

  const dragstart = useCallback(
    (event: React.DragEvent) => {
      if (drag != null) {
        setDropInsertData(event.dataTransfer, drag);
      }
    },
    [drag]
  );

  return (
    <button
      className={
        command?.isActive(attributes) ? "ToolbarButton active" : "ToolbarButton"
      }
      onClick={() => {
        command?.execute(attributes);

        if (shouldCloseDropdown === true) {
          closeDropdown();
        }
      }}
      disabled={!command?.isEnabled(attributes)}
      draggable={draggable}
      onDragStart={dragstart}
    >
      {children}
    </button>
  );
};

export default ToolbarButton;
