import { Node, Schema } from "prosemirror-model";
import { Decoration, NodeView } from "prosemirror-view";
import { AlignmentType } from "../../../extensions/alignment";
import { ZeroWidthSpace } from "../../../util";

export class InputChoiceValueNodeView<S extends Schema> implements NodeView<S> {
  dom: HTMLElement;
  contentDOM: HTMLElement;
  inputChoiceValue: HTMLElement;

  constructor(private node: Node<S>) {
    const container = document.createElement("input-choice-value");
    const { bullet, content } = inputChoiceValueBuilder();

    this.dom = container;

    container.appendChild(bullet);
    container.appendChild(content);

    this.contentDOM = content;
    this.inputChoiceValue = container;

    this.updateId(node);
    this.updateAlignment(node.attrs.alignment);
    this.updateDefaultValue(node.attrs.default);
  }

  update(node: Node<S>, _decorations: Decoration[]): boolean {
    if (node.type !== this.node.type) {
      return false;
    }

    this.updateId(node);
    this.updateAlignment(node.attrs.alignment);
    this.updateDefaultValue(node.attrs.default);

    return true;
  }

  private updateId(node: Node<S>): void {
    this.dom.id = node.attrs.id;
  }

  private updateAlignment(alignment: AlignmentType): void {
    if (alignment != null) {
      this.dom.setAttribute("data-alignment", alignment);
    } else {
      this.dom.removeAttribute("data-alignment");
    }
  }

  private updateDefaultValue(defaultValue: boolean): void {
    if (defaultValue) {
      this.dom.setAttribute("data-choice-default", "true");
    } else {
      this.dom.removeAttribute("data-choice-default");
    }
  }
}
export class InputChoiceOtherSpecifyValueNodeView<S extends Schema>
  implements NodeView<S> {
  dom: HTMLElement;
  contentDOM: HTMLElement;

  constructor(private node: Node<S>) {
    const container = document.createElement(
      "input-choice-other-specify-value"
    );
    const {
      bullet,
      content,
      otherContainer,
      inputText
    } = inputChoiceOtherSpecifyValueBuilder();

    this.dom = container;

    container.appendChild(bullet);
    otherContainer.appendChild(content);
    otherContainer.appendChild(inputText);
    container.appendChild(otherContainer);

    this.contentDOM = content;

    this.updateId(node);
    this.updateAlignment(node.attrs.alignment);
    this.updateDefaultValue(node.attrs.default);
  }

  update(node: Node<S>, _decorations: Decoration[]): boolean {
    if (node.type !== this.node.type) {
      return false;
    }

    this.updateId(node);
    this.updateAlignment(node.attrs.alignment);
    this.updateDefaultValue(node.attrs.default);
    return true;
  }

  private updateId(node: Node<S>): void {
    this.dom.id = node.attrs.id;
  }
  private updateAlignment(alignment: AlignmentType): void {
    if (alignment != null) {
      this.dom.setAttribute("data-alignment", alignment);
    } else {
      this.dom.removeAttribute("data-alignment");
    }
  }

  private updateDefaultValue(defaultValue: boolean): void {
    if (defaultValue) {
      this.dom.setAttribute("data-choice-default", "true");
    } else {
      this.dom.removeAttribute("data-choice-default");
    }
  }
}

function inputChoiceValueBuilder(): {
  bullet: HTMLElement;
  content: HTMLElement;
} {
  const bullet = document.createElement("div");
  bullet.className = "bullet";
  bullet.contentEditable = "false";
  bullet.appendChild(document.createTextNode(ZeroWidthSpace));

  const content = document.createElement("div");
  content.className = "content";

  return { bullet, content };
}

function inputChoiceOtherSpecifyValueBuilder(): {
  bullet: HTMLElement;
  content: HTMLElement;
  otherContainer: HTMLElement;
  inputText: HTMLElement;
} {
  const bullet = document.createElement("div");
  bullet.className = "bullet";
  bullet.contentEditable = "false";
  bullet.appendChild(document.createTextNode(ZeroWidthSpace));

  const content = document.createElement("div");
  content.className = "content";

  const otherContainer = document.createElement("div");
  otherContainer.className = "other-container";

  const inputText = document.createElement("div");
  inputText.className = "inputText";
  inputText.contentEditable = "false";

  return { bullet, content, otherContainer, inputText };
}
