import { Node, Schema } from "prosemirror-model";
import { Decoration, EditorView, NodeView } from "prosemirror-view";

export class LinkVariableNodeView<S extends Schema> implements NodeView<S> {
  dom: HTMLElement;

  private text: HTMLElement;

  constructor(
    private node: Node<S>,
    _view: EditorView<S>,
    _getPos: () => number,
    _decorations: Decoration[]
  ) {
    const container = document.createElement("link-variable");
    container.classList.add("ProseMirror-inline");

    const startMarker = document.createElement("span");
    startMarker.innerText = "{";
    startMarker.contentEditable = "false";

    const content = document.createElement("link-variable-content");

    const endMarker = document.createElement("span");
    endMarker.innerText = "}";
    endMarker.contentEditable = "false";

    container.appendChild(startMarker);
    container.appendChild(content);
    container.appendChild(endMarker);

    this.dom = container;
    this.text = content;

    this.updateText(node);
  }

  update(node: Node<S>, _decorations: Decoration[]): boolean {
    if (node.type !== this.node.type) {
      return false;
    }

    this.updateText(node);

    return true;
  }

  private updateText(node: Node<S>) {
    this.text.innerText = node.textContent;
  }
}
