import { Node as PMNode } from "prosemirror-model";
import { EditorState } from "prosemirror-state";
import { Focus, selectionFocusKey } from "../../editor/plugins/selection-focus";
import { UnreachableCaseError } from "../../util";
import {
  BaseVariableDefinition,
  VariableDefinition,
  VariableItem,
  VariableSchema,
  VariableType
} from "./schema";

export function createVariable(
  schema: VariableSchema,
  definition: VariableDefinition,
  name: string
): PMNode<VariableSchema> {
  const { textVariable, linkVariable, questionVariable } = schema.nodes;
  const { source, code } = definition;

  switch (definition.type) {
    case "link":
      const defaultValue = definition.defaultDisplayedValue;
      return linkVariable.createChecked(
        {
          source: source,
          code: code,
          defaultValue: defaultValue != null ? defaultValue : null
        },
        defaultValue != null ? [schema.text(defaultValue)] : []
      );

    case "question":
      return questionVariable.createChecked({ source: source, code: code });

    case "text":
      return textVariable.createChecked({
        source: source,
        code: code,
        displayedValue: name
      });

    default:
      throw new UnreachableCaseError(definition);
  }
}

export function isVariable(
  schema: VariableSchema,
  node: PMNode<VariableSchema>
): boolean {
  return [
    schema.nodes.textVariable,
    schema.nodes.linkVariable,
    schema.nodes.questionVariable
  ].includes(node.type);
}

export function variableTypeForNodeType(
  node: PMNode<VariableSchema>,
  schema: VariableSchema
): VariableType {
  let type: VariableType;
  switch (node.type) {
    case schema.nodes.textVariable:
      type = "text";
      break;
    case schema.nodes.linkVariable:
      type = "link";
      break;
    case schema.nodes.questionVariable:
      type = "question";
      break;
    default:
      type = "text";
      break;
  }

  return type;
}

export function findVariable(
  definition: BaseVariableDefinition,
  variables: VariableItem[]
): VariableItem | undefined {
  const variable = variables.find((v) => {
    return (
      v.definition.type === definition.type &&
      v.definition.source === definition.source &&
      v.definition.code === definition.code
    );
  });

  return variable;
}

export function focusedVariable(
  state: EditorState<VariableSchema>
): Focus | undefined {
  const { schema } = state;

  const focused = selectionFocusKey.getState(state);
  if (focused != null && isVariable(schema, focused.node)) {
    return focused;
  } else {
    return undefined;
  }
}
