import {
  Editor as BlueXEditor,
  Schema,
  VariableActiveValue,
  VariableDefinition,
  VariableItem,
  VariableType
} from "@blue-x/editor";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./Variable.css";

interface VariablePropertiesPanelProps {
  editor: BlueXEditor<Schema>;
}

export const VariablePropertiesPanel: React.FC<VariablePropertiesPanelProps> = (
  props
) => {
  const { editor } = props;
  const { commands } = editor;

  const activeValue: VariableActiveValue = useMemo(() => {
    return commands.updateVariable.activeValue();
  }, [commands]);

  const [code, setCode] = useState<string>("");
  const [defaultValue, setDefaultValue] = useState<string>("");
  const [displayedValue, setDisplayedValue] = useState<string>("");
  const [type, setType] = useState<VariableType>("text");

  useEffect(() => {
    setType(activeValue.type);
    setCode(activeValue.code);
    setDefaultValue(
      activeValue.defaultValue == null ? "" : activeValue.defaultValue
    );
    setDisplayedValue(
      activeValue.displayedValue == null ? "" : activeValue.displayedValue
    );
  }, [activeValue, setCode, setDefaultValue, setDisplayedValue, setType]);

  const updateDefaultValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setDefaultValue(value);
    },
    [setDefaultValue]
  );

  const updateDefaultValueCommand = useCallback(() => {
    commands.updateVariable.execute(
      {
        defaultValue: defaultValue === "" ? null : defaultValue
      },
      false
    );
  }, [commands, defaultValue]);

  const updateDisplayedValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setDisplayedValue(value);
    },
    [setDisplayedValue]
  );

  const updateDisplayedValueCommand = useCallback(() => {
    commands.updateVariable.execute(
      {
        displayedValue: displayedValue === "" ? null : displayedValue
      },
      false
    );
  }, [commands, displayedValue]);

  const variables = useMemo(() => {
    return (commands.setVariables?.activeValue() as VariableItem[]) ?? [];
  }, [commands]);

  const updateVariableCommand = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value;
      const variable = variables.find((x) => x.definition.code === value);
      if (variable == null) {
        return;
      }

      commands.updateVariable.execute(
        {
          definition: {
            type: variable.definition.type,
            source: variable.definition.source,
            code: variable.definition.code
          }
        },
        false
      );
    },
    [commands, variables]
  );

  const defaultValueDisable = useMemo(() => {
    return activeValue.type !== "text";
  }, [activeValue]);

  return (
    <div className="VariablePropertiesPanel">
      <label>Variable:</label>
      <select value={code} onChange={updateVariableCommand}>
        {variables.map((variable) => {
          return (
            <option
              value={variable.definition.code}
              key={variable.definition.code}
            >
              {variable.name}
            </option>
          );
        })}
      </select>

      {type === "text" && (
        <React.Fragment>
          <label>Default value: </label>
          <input
            value={defaultValue}
            onChange={updateDefaultValue}
            onBlur={updateDefaultValueCommand}
            disabled={defaultValueDisable}
          />
        </React.Fragment>
      )}

      {type === "link" && (
        <React.Fragment>
          <label>Text to display: </label>
          <input
            value={displayedValue}
            onChange={updateDisplayedValue}
            onBlur={updateDisplayedValueCommand}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export const VariablePicker: React.FC<{
  variables: VariableItem[];
  onSelect: (definition: VariableDefinition) => void;
}> = (props) => {
  const { variables, onSelect } = props;
  return (
    <div className="VariableDropdownPicker">
      <input type="text" placeholder="search" />
      {variables.length === 0 ? (
        <div className="VariableDropdownPickerEmpty">No Variables</div>
      ) : (
        variables.map((variable) => {
          return (
            <button
              key={`${variable.definition.type}-${variable.definition.source}-${variable.definition.code}`}
              className="VariableDropdownPickerItem"
              onClick={() => onSelect(variable.definition)}
            >
              {variable.name}
            </button>
          );
        })
      )}
    </div>
  );
};
