import { Schema } from "prosemirror-model";
import {
  CommandConfiguration,
  CommandConfigurations,
  Extension
} from "../../editor";
import { setIndentation } from "./commands";

export class Indentation implements Extension<Schema> {
  constructor(private maxIndentationLevel: number = 30) {}

  get name(): string {
    return "indentation";
  }

  commands(schema: Schema): CommandConfigurations<Schema> {
    return {
      indent: this.indentCommand(schema),
      outdent: this.outdentCommand(schema)
    };
  }

  private indentCommand(
    _schema: Schema
  ): CommandConfiguration<Schema, {}, void> {
    const indent = setIndentation(this.indentAttributes.bind(this));
    return {
      isActive: () => false,
      isEnabled: () => {
        return indent;
      },
      execute: () => {
        return indent;
      }
    };
  }

  private outdentCommand(
    _schema: Schema
  ): CommandConfiguration<Schema, {}, void> {
    const outdent = setIndentation(this.outdentAttributes.bind(this));
    return {
      isActive: () => false,
      isEnabled: () => {
        return outdent;
      },
      execute: () => {
        return outdent;
      }
    };
  }

  private indentAttributes(previous?: number): number | undefined | false {
    if (!previous) {
      return 1; // No indenetation exist, create a new one with level 1
    }

    if (previous >= this.maxIndentationLevel) {
      return false; // Max indentation level reached, do nothing.
    }

    return previous + 1; // Otherwise, increase the level by one
  }

  private outdentAttributes(previous?: number): number | undefined | false {
    if (!previous) {
      return false; // Do nothing;
    }

    if (previous <= 1) {
      return undefined; // Remove the mark
    }

    return previous - 1; // Decrease the level on other cases
  }
}
