import { MarkSpec, Schema } from "prosemirror-model";
import {
  CommandConfiguration,
  CommandConfigurations,
  DocumentBuilders,
  Extension,
  MarkConfig
} from "../../editor";
import { textMarkIsActive, textMarkIsEnabled, toggleMark } from "../../util";

class StrikethroughMark implements MarkConfig {
  get name(): string {
    return "strikethrough";
  }

  get spec(): MarkSpec {
    return {
      group: "text-style",
      parseDOM: [
        {
          tag: "s"
        },
        {
          tag: "del"
        },
        {
          tag: "strike"
        },
        {
          style: "text-decoration",
          getAttrs: (value) =>
            typeof value === "string" && value === "line-through" && null
        }
      ],
      toDOM() {
        return ["s", 0];
      }
    };
  }

  get builders(): DocumentBuilders {
    return { strikethrough: { markType: "strikethrough" } };
  }
}

type StrikethroughSchema = Schema<any, "strikethrough">;

export class Strikethrough implements Extension<StrikethroughSchema> {
  get name(): string {
    return "strikethrough";
  }

  get marks(): MarkConfig[] {
    return [new StrikethroughMark()];
  }

  commands(
    schema: StrikethroughSchema
  ): CommandConfigurations<StrikethroughSchema> {
    return {
      strikethrough: this.strikethroughCommand(schema)
    };
  }

  private strikethroughCommand(
    schema: StrikethroughSchema
  ): CommandConfiguration<StrikethroughSchema, {}, undefined> {
    return {
      isActive: () => textMarkIsActive(schema.marks.strikethrough),
      isEnabled: () => textMarkIsEnabled(schema.marks.strikethrough),
      execute: () => toggleMark(schema.marks.strikethrough)
    };
  }
}
