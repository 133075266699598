import { autoJoin, chainCommands } from "prosemirror-commands";
import { InputRule } from "prosemirror-inputrules";
import {
  Fragment,
  Node,
  NodeRange,
  NodeType,
  ResolvedPos,
  Schema
} from "prosemirror-model";
import {
  liftListItem,
  splitListItem,
  wrapInList as baseWrapInList
} from "prosemirror-schema-list";
import { EditorState, NodeSelection, Transaction } from "prosemirror-state";
import { liftTarget, ReplaceAroundStep } from "prosemirror-transform";
import { CommandFn } from "../../editor";
import { fixSplit } from "../../editor/commands";
import { GapCursor } from "../../editor/plugins/gap-cursor";
import {
  executeSequence,
  filter,
  findCutAfter,
  findCutBefore,
  findParent,
  findPositionOfNodeBefore,
  getResolvedSelection,
  hasParentNodeOfType,
  isEmptySelectionAtEnd,
  isEmptySelectionAtStart,
  isFirstChildOfParent,
  isRangeOfType,
  joiningInputRule,
  removeBlockStyles,
  wrappingInputRule
} from "../../util";
import { removeAlignmentTr } from "../alignment";
import { removeIndentationTr } from "../indentation";
import { liftFollowingList, liftSelectionList } from "./transforms";

function isInsideList<S extends Schema>(
  state: EditorState<S>,
  listType: NodeType<S>
) {
  const { $from } = state.selection;
  const parent = $from.node(-2);
  const grandgrandParent = $from.node(-3);

  return (
    (parent && parent.type === listType) ||
    (grandgrandParent && grandgrandParent.type === listType)
  );
}

function liftListItems<S extends Schema>(): CommandFn<S> {
  return (state, dispatch) => {
    const { tr } = state;
    const { $from, $to } = state.selection;

    tr.doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
      // Following condition will ensure that block types paragraph, heading, codeBlock, blockquote, panel are lifted.
      // isTextblock is true for paragraph, heading, codeBlock.
      if (node.isTextblock) {
        const sel = new NodeSelection<S>(tr.doc.resolve(tr.mapping.map(pos)));
        const range = sel.$from.blockRange(sel.$to);

        if (!range || sel.$from.parent.type !== state.schema.nodes.listItem) {
          return false;
        }

        const target = range && liftTarget(range);

        if (target === undefined || target === null) {
          return false;
        }

        tr.lift(range, target);
      }
      return;
    });

    if (dispatch) {
      dispatch(tr);
    }

    return true;
  };
}

function wrapInList<S extends Schema>(nodeType: NodeType): CommandFn<S> {
  return (state, dispatch) => {
    const wrapList = autoJoin(
      baseWrapInList(nodeType),
      (before, after) => before.type === after.type && before.type === nodeType
    );

    return executeSequence(removeBlockStyles(), wrapList)(state, dispatch);
  };
}

export function wrappingListInputRule<S extends Schema>(
  regexp: RegExp,
  nodeType: NodeType,
  getAttrs?:
    | { [key: string]: any }
    | ((p: string[]) => { [key: string]: any } | undefined),
  joinPredicate?: (p1: string[], p2: Node<S>) => boolean
) {
  return wrappingInputRule(
    regexp,
    nodeType,
    getAttrs,
    joinPredicate,
    (tr, range) => {
      tr = removeAlignmentTr(tr, range.$from.pos, range.$to.pos);
      tr = removeIndentationTr(tr, range.$from.pos, range.$to.pos);

      return tr;
    }
  );
}

export function joiningListInputRule<S extends Schema>(
  regexp: RegExp,
  nodeType: NodeType<S>,
  getAttrs?:
    | { [key: string]: any }
    | ((p: string[]) => { [key: string]: any } | undefined),
  joinPredicate?: (p1: string[], p2: Node<S>) => boolean
): InputRule<S> {
  return joiningInputRule(
    regexp,
    nodeType,
    getAttrs,
    joinPredicate,
    (tr, range) => {
      tr = removeAlignmentTr(tr, range.$from.pos, range.$to.pos);
      tr = removeIndentationTr(tr, range.$from.pos, range.$to.pos);

      return tr;
    }
  );
}

function toggleListCommand<S extends Schema>(
  listType: NodeType<S>
): CommandFn<S> {
  return (state, dispatch) => {
    const { $from, $to } = state.selection;
    const isRangeOfSingleType = isRangeOfType(state.doc, $from, $to, listType);

    if (isInsideList(state, listType) && isRangeOfSingleType) {
      // Untoggles list
      return liftListItems<S>()(state, dispatch);
    } else {
      // Converts list type e.g. bullet_list -> ordered_list if needed
      if (!isRangeOfSingleType) {
        return executeSequence(liftListItems<S>(), wrapInList<S>(listType))(
          state,
          dispatch
        );
      } else {
        // Wraps selection in list
        return wrapInList<S>(listType)(state, dispatch);
      }
    }
  };
}

function rootListDepth<S extends Schema>(
  pos: ResolvedPos<S>,
  nodes: Record<string, NodeType<S>>
) {
  const { bulletList, orderedList, listItem } = nodes;
  let depth;
  for (let i = pos.depth - 1; i > 0; i--) {
    const node = pos.node(i);
    if (node.type === bulletList || node.type === orderedList) {
      depth = i;
    }
    if (
      node.type !== bulletList &&
      node.type !== orderedList &&
      node.type !== listItem
    ) {
      break;
    }
  }
  return depth;
}

export function toggleList<S extends Schema>(
  listType: NodeType<S>
): CommandFn<S> {
  return (state, dispatch) => {
    const { selection } = state;
    const { $from, $to } = getResolvedSelection(state);

    const fromNode = $from.node($from.depth - 2);
    const endNode = $to.node($to.depth - 2);
    if (
      !fromNode ||
      fromNode.type !== listType ||
      !endNode ||
      endNode.type !== listType
    ) {
      return toggleListCommand(listType)(state, dispatch);
    } else {
      const depth = rootListDepth(selection.$to, state.schema.nodes);
      let tr = liftFollowingList(
        state,
        selection.$to.pos,
        selection.$to.end(depth),
        depth == null ? 0 : depth,
        state.tr
      );
      tr = liftSelectionList(state, tr);
      if (dispatch) {
        dispatch(tr);
      }
      return true;
    }
  };
}

function canOutdent<S extends Schema>(state: EditorState<S>): boolean {
  const { parent } = state.selection.$from;
  const { listItem, paragraph } = state.schema.nodes;

  if (state.selection instanceof GapCursor) {
    return parent.type === listItem;
  }

  return (
    parent.type === paragraph &&
    hasParentNodeOfType<S>(listItem as NodeType<S>)(state.selection)
  );
}

function deletePreviousEmptyListItem<S extends Schema>(
  state: EditorState<S>,
  dispatch?: (tr: Transaction<S>) => void
) {
  const { $from } = state.selection;
  const { listItem } = state.schema.nodes;

  const $cut = findCutBefore($from);
  if (!$cut || !$cut.nodeBefore || !($cut.nodeBefore.type === listItem)) {
    return false;
  }

  const previousListItemEmpty =
    $cut.nodeBefore.childCount === 1 &&
    $cut.nodeBefore.firstChild!.nodeSize <= 2;

  if (previousListItemEmpty) {
    const { tr } = state;

    if (dispatch) {
      dispatch(
        tr
          .delete($cut.pos - $cut.nodeBefore.nodeSize, $from.pos)
          .scrollIntoView()
      );
    }
    return true;
  }

  return false;
}

function isInsideListItem<S extends Schema>(state: EditorState<S>): boolean {
  const { $from } = state.selection;
  const { listItem, paragraph } = state.schema.nodes;

  if (state.selection instanceof GapCursor) {
    return $from.parent.type === listItem;
  }

  return (
    hasParentNodeOfType<S>(listItem as NodeType<S>)(state.selection) &&
    $from.parent.type === paragraph
  );
}

function mergeLists<S extends Schema>(
  listItem: NodeType<S>,
  range: NodeRange<S>
) {
  return (
    state: EditorState<S>,
    dispatch?: (tr: Transaction<S>) => void
  ): boolean => {
    /* we now need to handle the case that we lifted a sublist out,
     * and any listItems at the current level get shifted out to
     * their own new list; e.g.:
     *
     * unorderedList
     *  listItem(A)
     *  listItem
     *    unorderedList
     *      listItem(B)
     *  listItem(C)
     *
     * becomes, after unindenting the first, top level listItem, A:
     *
     * content of A
     * unorderedList
     *  listItem(B)
     * unorderedList
     *  listItem(C)
     *
     * so, we try to merge these two lists if they're of the same type, to give:
     *
     * content of A
     * unorderedList
     *  listItem(B)
     *  listItem(C)
     */

    const tr = state.tr;
    const $start = state.doc.resolve(range.start);
    const $end = state.doc.resolve(range.end);
    const $join = tr.doc.resolve(tr.mapping.map(range.end - 1));

    if (
      $join.nodeBefore &&
      $join.nodeAfter &&
      $join.nodeBefore.type === $join.nodeAfter.type
    ) {
      if (
        $end.nodeAfter &&
        $end.nodeAfter.type === listItem &&
        $end.parent.type === $start.parent.type
      ) {
        tr.join($join.pos);
      }
    }

    if (dispatch) {
      dispatch(tr.scrollIntoView());
    }

    return true;
  };
}

function outdentList<S extends Schema>(): CommandFn<S> {
  return (state, dispatch) => {
    const { listItem } = state.schema.nodes as { [key: string]: NodeType<S> };
    const { $from, $to } = state.selection;
    if (isInsideListItem(state)) {
      // if we're backspacing at the start of a list item, unindent it
      // take the the range of nodes we might be lifting

      // the predicate is for when you're backspacing a top level list item:
      // we don't want to go up past the doc node, otherwise the range
      // to clear will include everything
      let range = $from.blockRange(
        $to,
        (node) => node.childCount > 0 && node.firstChild!.type === listItem
      );

      if (!range) {
        return false;
      }

      return executeSequence<S>(
        mergeLists(listItem, range), // 2. Check if I need to merge nearest list
        liftListItem(listItem) // 1. First lift list item
      )(state, dispatch);
    }

    return false;
  };
}

function canToJoinToPreviousListItem<S extends Schema>(
  state: EditorState<S>
): boolean {
  const { $from } = state.selection;
  const { bulletList, orderedList } = state.schema.nodes;

  let nodeBefore: Node<S> | null | undefined;
  if (state.selection instanceof GapCursor) {
    nodeBefore = $from.nodeBefore;
  } else {
    const $before = state.doc.resolve($from.pos - 1);
    nodeBefore = $before ? $before.nodeBefore : null;
  }

  return (
    !!nodeBefore && [bulletList, orderedList].indexOf(nodeBefore.type) > -1
  );
}

function joinToPreviousListItem<S extends Schema>(
  state: EditorState<S>,
  dispatch?: (tr: Transaction<S>) => void
) {
  const { $from } = state.selection;
  const { paragraph, listItem, codeBlock, bulletList, orderedList } = state
    .schema.nodes as { [key: string]: NodeType<S> };
  const isGapCursorShown = state.selection instanceof GapCursor;
  const $cutPos = isGapCursorShown ? state.doc.resolve($from.pos + 1) : $from;
  let $cut = findCutBefore($cutPos);
  if (!$cut) {
    return false;
  }

  // see if the containing node is a list
  if (
    $cut.nodeBefore &&
    [bulletList, orderedList].indexOf($cut.nodeBefore.type) > -1
  ) {
    // and the node after this is a paragraph or a codeBlock
    if (
      $cut.nodeAfter &&
      ($cut.nodeAfter.type === paragraph || $cut.nodeAfter.type === codeBlock)
    ) {
      // find the nearest paragraph that precedes this node
      let $lastNode = $cut.doc.resolve($cut.pos - 1);

      while ($lastNode.parent.type !== paragraph) {
        $lastNode = state.doc.resolve($lastNode.pos - 1);
      }

      let { tr } = state;
      if (isGapCursorShown) {
        const nodeBeforePos = findPositionOfNodeBefore(tr.selection);
        if (typeof nodeBeforePos !== "number") {
          return false;
        }
        // append the codeblock to the list node
        const list = $cut.nodeBefore.copy(
          $cut.nodeBefore.content.append(
            Fragment.from<S>(listItem.createChecked({}, $cut.nodeAfter))
          )
        );
        tr.replaceWith(
          nodeBeforePos,
          $from.pos + $cut.nodeAfter.nodeSize,
          list
        );
      } else {
        // take the text content of the paragraph and insert after the paragraph up until before the the cut
        tr = state.tr.step(
          new ReplaceAroundStep(
            $lastNode.pos,
            $cut.pos + $cut.nodeAfter.nodeSize,
            $cut.pos + 1,
            $cut.pos + $cut.nodeAfter.nodeSize - 1,
            state.tr.doc.slice($lastNode.pos, $cut.pos),
            0,
            true
          )
        );
      }

      // find out if there's now another list following and join them
      // as in, [list, p, list] => [list with p, list], and we want [joined list]
      let $postCut = tr.doc.resolve(
        tr.mapping.map($cut.pos + $cut.nodeAfter.nodeSize)
      );
      if (
        $postCut.nodeBefore &&
        $postCut.nodeAfter &&
        $postCut.nodeBefore.type === $postCut.nodeAfter.type &&
        [bulletList, orderedList].indexOf($postCut.nodeBefore.type) > -1
      ) {
        tr = tr.join($postCut.pos);
      }

      if (dispatch) {
        dispatch(tr.scrollIntoView());
      }
      return true;
    }
  }

  return false;
}

export const backspaceCommand = chainCommands(
  // if we're at the start of a list item, we need to either backspace
  // directly to an empty list item above, or outdent this node
  filter(
    [
      isEmptySelectionAtStart,
      // list items might have multiple paragraphs; only do this at the first one
      isFirstChildOfParent,
      canOutdent
    ],
    chainCommands(deletePreviousEmptyListItem, outdentList())
  ),

  // if we're just inside a paragraph node (or gapcursor is shown) and backspace, then try to join
  // the text to the previous list item, if one exists
  filter(
    [isEmptySelectionAtStart, canToJoinToPreviousListItem],
    joinToPreviousListItem
  )
);

function isFirstItemInList<S extends Schema>(state: EditorState<S>): boolean {
  const { $from } = state.selection;
  return $from.depth > 2 ? $from.index($from.depth - 2) === 0 : false;
}

function isLastItemInList<S extends Schema>(state: EditorState<S>): boolean {
  const { $to } = state.selection;
  return $to.depth > 2
    ? $to.index($to.depth - 2) === $to.node($to.depth - 2).childCount - 1
    : false;
}

function deleteLastListItem<S extends Schema>(
  state: EditorState<S>,
  dispatch?: (tr: Transaction<S>) => void
) {
  const { schema, selection } = state;
  const { $to } = selection;
  const listItem = findParent(
    $to,
    (node) => node.type === schema.nodes.listItem
  );

  if (listItem == null) {
    return false;
  }

  if (dispatch) {
    let tr = state.tr;
    tr = tr.delete(listItem.pos, listItem.pos + listItem.node.nodeSize);
    tr = tr.scrollIntoView();

    dispatch(tr);
  }

  return true;
}

function deleteList<S extends Schema>(
  state: EditorState<S>,
  dispatch?: (tr: Transaction<S>) => void
) {
  const { schema, selection } = state;
  const { $to } = selection;
  const list = findParent(
    $to,
    (node) =>
      node.type === schema.nodes.bulletList ||
      node.type === schema.nodes.orderedList
  );

  if (list == null) {
    return false;
  }

  if (dispatch) {
    let tr = state.tr;
    tr = tr.delete(list.pos, list.pos + list.node.nodeSize);
    tr = tr.scrollIntoView();

    dispatch(tr);
  }

  return true;
}

function canToJoinToListItem<S extends Schema>(state: EditorState<S>): boolean {
  const { schema, selection } = state;
  const { paragraph, listItem } = schema.nodes;
  const { $to } = selection;

  const $cut = findCutAfter($to);
  if ($cut == null) {
    return false;
  }

  const nodeAfter = $cut.nodeAfter;
  if (nodeAfter == null) {
    return false;
  }

  return [paragraph, listItem].indexOf(nodeAfter.type) > -1;
}

function joinToListItem<S extends Schema>(
  state: EditorState<S>,
  dispatch?: (tr: Transaction<S>) => void
): boolean {
  const { schema, selection } = state;
  const { paragraph, listItem, bulletList, orderedList } = schema.nodes as {
    [key: string]: NodeType<S>;
  };
  const { $to } = selection;

  const $cut = findCutAfter($to);
  if ($cut == null) {
    return false;
  }

  const nodeAfter = $cut.nodeAfter;
  if (nodeAfter == null) {
    return false;
  }

  if ([paragraph, listItem].indexOf(nodeAfter.type) > -1) {
    let tr = state.tr;

    // find the nearest paragraph that precedes this node
    let $lastNode = $cut.doc.resolve($cut.pos - 1);

    while ($lastNode.parent.type !== paragraph) {
      $lastNode = state.doc.resolve($lastNode.pos - 1);
    }

    if (nodeAfter.type === schema.nodes.listItem) {
      tr = tr.step(
        new ReplaceAroundStep(
          $lastNode.pos,
          $cut.pos + nodeAfter.nodeSize,
          $cut.pos + 2,
          $cut.pos + nodeAfter.nodeSize - 2,
          state.tr.doc.slice($lastNode.pos, $cut.pos),
          0,
          true
        )
      );
    } else {
      tr = tr.step(
        new ReplaceAroundStep(
          $lastNode.pos,
          $cut.pos + nodeAfter.nodeSize,
          $cut.pos + 1,
          $cut.pos + nodeAfter.nodeSize - 1,
          state.tr.doc.slice($lastNode.pos, $cut.pos),
          0,
          true
        )
      );
    }

    // find out if there's now another list following and join them
    // as in, [list, p, list] => [list with p, list], and we want [joined list]
    let $postCut = tr.doc.resolve(
      tr.mapping.map($cut.pos + nodeAfter.nodeSize)
    );
    if (
      $postCut.nodeBefore &&
      $postCut.nodeAfter &&
      $postCut.nodeBefore.type === $postCut.nodeAfter.type &&
      [bulletList, orderedList].indexOf($postCut.nodeBefore.type) > -1
    ) {
      tr = tr.join($postCut.pos);
    }

    if (dispatch) {
      dispatch(tr.scrollIntoView());
    }

    return true;
  }

  return false;
}

export const deleteCommand = chainCommands(
  filter(
    [
      isEmptySelectionAtStart,
      isEmptySelectionAtEnd,
      isFirstItemInList,
      isLastItemInList
    ],
    deleteList
  ),

  filter(
    [isEmptySelectionAtStart, isEmptySelectionAtEnd, isLastItemInList],
    deleteLastListItem
  ),

  filter([isEmptySelectionAtEnd, canToJoinToListItem], joinToListItem)
);

export const enterCommand = (
  state: EditorState<Schema>,
  dispatch?: (tr: Transaction<Schema>) => void
) => {
  const { schema } = state;
  return splitListItem(schema.nodes.listItem)(
    state,
    dispatch &&
      ((tr) => {
        fixSplit(tr, state, dispatch);
      })
  );
};
