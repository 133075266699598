import { Schema } from "prosemirror-model";
import { Plugin, PluginKey } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";
import { ConstantSumSchema } from "../schema";
import { getAssociationsForConstantSum } from "../util";

export const constantSumsKey = new PluginKey<DecorationSet, ConstantSumSchema>(
  "constantSumPlugin"
);

export function constantSumAssociations() {
  return new Plugin<DecorationSet, Schema>({
    key: constantSumsKey,
    state: {
      init() {
        return DecorationSet.empty;
      },
      apply() {
        return DecorationSet.empty;
      }
    },
    props: {
      decorations(state) {
        const associatedCells = getAssociationsForConstantSum(state);

        if (associatedCells.length > 0) {
          const decorations = associatedCells.reduce(
            (acc, { pos, node, constantSum }) => {
              const from = pos;
              const to = from + node.nodeSize;

              if (constantSum === true) {
                // return acc.concat(
                //   Decoration.node(from, to, {
                //     class: "constant-sum-result-cell"
                //   })
                // );
                return acc;
              } else {
                return acc.concat(
                  Decoration.node(from, to, {
                    class: "selectedCell constant-sum-associations"
                  })
                );
              }
            },
            new Array<Decoration>()
          );

          return DecorationSet.create(state.doc, decorations);
        }
        return DecorationSet.empty;
      }
    }
  });
}
