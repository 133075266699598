import { ResolvedPos, Schema, Slice } from "prosemirror-model";
import { Plugin, TextSelection } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { canInsertSlice, posFromEvent } from "../../../util";
import { emitNotification } from "../notification";
import { getFirstAndLastNodeAtDepth } from "../paste/paste-plugin";

export enum EditorDropNotification {
  DropNotAllowed = "drop.not-allowed"
}

export function dropPlugin<S extends Schema>() {
  return new Plugin<boolean, S>({
    props: {
      handleDrop(view, event, slice) {
        const dropEvent = event as DragEvent;

        const target = posFromEvent(dropEvent, view, slice);
        if (target == null) {
          emitNotification(view.state, {
            type: "warning",
            message: EditorDropNotification.DropNotAllowed
          });
          return true;
        }

        const $pos = view.state.doc.resolve(target);
        const isValid = canInsertSlice($pos, $pos, slice);

        if (isValid) {
          if (handleDropInsidePlaceholder(slice, view, $pos)) {
            return true;
          }
        }

        const showNotification = isValid === false;

        if (showNotification) {
          emitNotification(view.state, {
            type: "warning",
            message: EditorDropNotification.DropNotAllowed
          });
          return true;
        }

        return false;
      }
    }
  });
}

function handleDropInsidePlaceholder<S extends Schema>(
  slice: Slice<S>,
  view: EditorView<S>,
  target: ResolvedPos<S>
): boolean {
  const { state } = view;
  const { selection, schema } = state;
  const { $from, $to } = selection;
  const { firstChild } = getFirstAndLastNodeAtDepth(slice);

  if (
    slice.content.childCount < 2 &&
    target.parent.content.size === 0 &&
    firstChild != null
  ) {
    if (
      (target.parent.type !== schema.nodes.headings &&
        target.parent.type !== schema.nodes.paragraph) ||
      (firstChild.type !== schema.nodes.headings &&
        firstChild.type !== schema.nodes.paragraph &&
        firstChild.type !== schema.nodes.text)
    ) {
      return false;
    }

    let tr = view.state.tr;

    if (firstChild == null) {
      return true;
    }

    tr = tr.delete($from.pos, $to.pos);
    tr = tr.insert(tr.mapping.map(target.pos), firstChild.content);

    if (firstChild.type === schema.nodes.headings) {
      let attrs: Record<string, any> = {
        ...target.parent.attrs,
        level: firstChild.attrs.level
      };
      tr = tr.setNodeMarkup(
        tr.mapping.map(target.pos - 1),
        firstChild.type,
        attrs
      );
    }

    tr = tr.setSelection(
      new TextSelection(
        tr.doc.resolve(tr.mapping.map(target.pos) - firstChild.content.size),
        tr.doc.resolve(tr.mapping.map(target.pos))
      )
    );
    view.dispatch(tr);

    return true;
  }
  return false;
}
