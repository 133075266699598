import { Node, ResolvedPos, Schema } from "prosemirror-model";
import { EditorState } from "prosemirror-state";
import { selectionFocusKey } from "../../editor/plugins/selection-focus";
import { NodeWithPos } from "../../util/nodes";
import { CustomGridSchema } from "./schema";

export const CELL_MIN_WIDTH = 5;

export function focusedCustomGrid(state: EditorState<CustomGridSchema>) {
  const { schema } = state;

  const focused = selectionFocusKey.getState(state);
  if (focused != null && focused.node.type === schema.nodes.table) {
    return focused;
  } else {
    return undefined;
  }
}

export function findGrids<S extends Schema>(
  doc: Node<S>,
  schema: S
): NodeWithPos<S>[] {
  const grids = new Array<NodeWithPos<S>>();
  doc.descendants((node, pos) => {
    if (node.type === schema.nodes.table) {
      grids.push({ node: node, pos: pos });
      return false;
    }
    return;
  });

  return grids;
}

export function pointsAtCell<S extends Schema>($pos: ResolvedPos<S>) {
  return $pos.parent.type.spec.tableRole === "row" && $pos.nodeAfter;
}

export function setAttr(
  attrs: { [key: string]: any },
  name: string,
  value: any
) {
  let result: { [key: string]: any } = {};
  for (let prop in attrs) {
    result[prop] = attrs[prop];
  }
  result[name] = value;
  return result;
}

export function zeroes(n: number) {
  const result = [];
  for (let i = 0; i < n; i++) {
    result.push(0);
  }
  return result;
}

export function findNextCell<S extends Schema>(
  $cell: ResolvedPos<S>,
  dir: number
) {
  if ($cell == null) return undefined;
  if (dir < 0) {
    let before = $cell.nodeBefore;
    if (before) return $cell.pos - before.nodeSize;
    for (
      let row = $cell.index(-1) - 1, rowEnd = $cell.before();
      row >= 0;
      row--
    ) {
      let rowNode = $cell.node(-1).child(row);
      if (rowNode.childCount) return rowEnd - 1 - rowNode!.lastChild!.nodeSize;
      rowEnd -= rowNode.nodeSize;
    }
  } else {
    if ($cell.index() < $cell.parent.childCount - 1)
      return $cell.pos + $cell!.nodeAfter!.nodeSize;
    let table = $cell.node(-1);
    for (
      let row = $cell.indexAfter(-1), rowStart = $cell.after();
      row < table.childCount;
      row++
    ) {
      let rowNode = table.child(row);
      if (rowNode.childCount) return rowStart + 1;
      rowStart += rowNode.nodeSize;
    }
  }

  return undefined;
}

interface ResizeObserverEntry {
  target: Element;
  contentRect: DOMRectReadOnly;
  readonly borderBoxSize: ResizeObserverSize[];
  readonly devicePixelContentBoxSize: ResizeObserverSize[];
}

interface ResizeObserverSize {
  readonly inlineSize: number;
  readonly blockSize: number;
}

export function getChartWidth(tableWidth: any, colWidths: number[], j: number) {
  const padding = 16;
  const border = 1;
  return (
    +Math.trunc((tableWidth * colWidths[j]) / 100).toFixed(2) -
    padding * 2 -
    border * 2
  );
}

export function resizeGrid(entries: ResizeObserverEntry[]) {
  const tableWidth = entries[0].contentRect.width;
  const colWidths: number[] = [];

  let table: HTMLElement | undefined;
  const targetChildren = Array.from(
    entries[0].target.children
  ) as HTMLElement[];

  if (targetChildren.length !== 2) return;

  if (targetChildren[1].tagName === "TABLE") {
    table = targetChildren[1];
  }

  if (!table || !(table instanceof HTMLElement) || !table.children) return;

  const tableChildren = Array.from(table.children) as HTMLElement[];

  if (tableChildren.length !== 2) return;

  let body: HTMLElement | undefined;
  let colgroup: HTMLElement | undefined;
  if (tableChildren[1].tagName === "TBODY") {
    body = tableChildren[1];
  }
  if (tableChildren[0].tagName === "COLGROUP") {
    colgroup = tableChildren[0];
  }

  if (!body || !colgroup) return;

  (Array.from(colgroup.children) as HTMLElement[]).forEach(
    (col: HTMLElement) => {
      const rawWidth = col.style.width;
      let width = +rawWidth.replace("%", "");
      width = +width.toFixed(1);
      colWidths.push(width);
    }
  );

  let trs = Array.from(body.children) as HTMLElement[];
  for (let i = 1; i < trs.length - 1; i++) {
    const tds = Array.from(trs[i].children) as HTMLElement[];
    for (let j = 0; j < tds.length; j++) {
      if (!tds[j].classList.contains("ProseMirror-input-cell")) continue;
      const tdChildren = Array.from(tds[j].children) as HTMLElement[];
      tdChildren.forEach((tdChild) => {
        if (tdChild.tagName === "BAR-CHART") {
          const chartWidth = getChartWidth(tableWidth, colWidths, j);
          const barChartContainer = Array.from(tdChild.children).find((c) =>
            c.classList.contains("bar-chart-container")
          );
          if (
            barChartContainer &&
            tdChild.children.length > 2 &&
            barChartContainer.classList.contains("horizontal")
          ) {
            const canvasParent = tdChild.querySelector(
              ".bar-chart-canvas-container"
            ) as HTMLElement;
            if (canvasParent != null) {
              canvasParent.style.width = `${(chartWidth * 80) / 100}px`;
            }
            const yAxisContainer = tdChild.querySelector(
              ".bar-chart-yaxis-container"
            ) as HTMLElement;
            if (yAxisContainer != null) {
              yAxisContainer.style.width = `${(chartWidth * 20) / 100}px`;
            }
          } else {
            const canvasParent = tdChild.querySelector(
              ".bar-chart-canvas-container"
            ) as HTMLElement;
            if (canvasParent != null) {
              canvasParent.style.width = `${chartWidth}px`;
            }
          }
        }
      });
    }
  }
}
