import { Node, Schema } from "prosemirror-model";
import { EditorState } from "prosemirror-state";
import { selectionFocusKey } from "../../editor/plugins/selection-focus";
import {
  ContentNodeWithPos,
  findParent,
  NodeWithPos,
  findNode
} from "../../util/nodes";

export function focusedElementWithQuestionTitle(state: EditorState<Schema>) {
  const focused = selectionFocusKey.getState(state);
  if (
    focused != null &&
    focused.node.type.spec.attrs?.questionTitleText !== undefined &&
    focused.node.type.spec.attrs?.questionTitleRefElementId !== undefined
  ) {
    return focused;
  } else {
    return undefined;
  }
}

export function nodeHasQuestionTitleBinding(node: Node<Schema>): boolean {
  const questionTitleRefElementId = node.attrs?.questionTitleRefElementId as
    | string[]
    | undefined;

  if (questionTitleRefElementId == null) {
    return false;
  }

  return questionTitleRefElementId.length > 0;
}

export function findQuestionTitles<S extends Schema>(
  doc: Node<S>,
  node: Node<S>
): NodeWithPos<S>[] {
  const ids = node.attrs.questionTitleRefElementId as string[];

  const children =
    ids != null
      ? ids
          .map((id) => {
            return findNode(doc, (child) => {
              const childId = child.attrs?.id;
              if (childId != null && childId === id) {
                return true;
              } else {
                return false;
              }
            });
          })
          .filter((x) => x != null)
      : [];

  return children as NodeWithPos<S>[];
}

export function hasQuestionTitles<S extends Schema>(
  doc: Node<S>,
  node: Node<S>
): boolean {
  const boundQuestionTitles = findQuestionTitles(doc, node);
  return boundQuestionTitles.length > 0;
}

export function supportsQuestionTitleBinding<S extends Schema>(
  node: Node<S>
): boolean {
  return (
    node.type.spec.attrs?.questionTitleText !== undefined &&
    node.type.spec.attrs?.questionTitleRefElementId !== undefined
  );
}

export function questionTitleIsEmpty<S extends Schema>(
  questionTitles: NodeWithPos<S>[]
): boolean {
  const questionTitle =
    questionTitles.length === 1 ? questionTitles[0] : undefined;
  if (
    questionTitle != null &&
    questionTitle.node.type.spec.supportsQuestionTitle === true &&
    questionTitle.node.textContent.length === 0
  ) {
    return true;
  } else {
    return false;
  }
}

export function getGridForFocus<S extends Schema>(
  state: EditorState<S>
): { table: ContentNodeWithPos<S>; input: NodeWithPos<S> } | undefined {
  const { doc, schema } = state;
  const focused = selectionFocusKey.getState(state);
  if (focused != null) {
    const $pos = doc.resolve(focused.pos);
    const table = findParent($pos, (n) => n.type === schema.nodes.table);
    if (table == null) {
      return undefined;
    } else {
      return {
        table: table,
        input: { node: focused.node as Node<S>, pos: focused.pos }
      };
    }
  } else {
    return undefined;
  }
}
