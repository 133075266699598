import { Schema } from "prosemirror-model";
import { Decoration, EditorView } from "prosemirror-view";
import { NodeWithPos } from "../../util";
import { Block } from "./block-selector/block";

const TARGET_OVERLAY_CLASS_NAMES = {
  TargetOverlay: "ProseMirror-target-overlay"
};

export function decorationForTarget(
  from: number,
  to: number,
  className: string
): Decoration {
  return Decoration.node(from, to, {
    class: `${TARGET_OVERLAY_CLASS_NAMES.TargetOverlay} ${className}`
  });
}

export function isSelectionInTargetOverlay(target: HTMLElement): boolean {
  return target.closest(`.${TARGET_OVERLAY_CLASS_NAMES.TargetOverlay}`) != null;
}

function isOverlayNode(
  view: EditorView,
  pos: number,
  overlay: Element
): boolean {
  const domNode = view.nodeDOM(pos);

  if (domNode != null) {
    if (domNode.nodeType === Node.ELEMENT_NODE && domNode === overlay) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function getNodeForTarget(
  view: EditorView,
  target: HTMLElement,
  anchor?: Block | undefined
): NodeWithPos<Schema> | undefined {
  const overlay = target.closest(
    `.${TARGET_OVERLAY_CLASS_NAMES.TargetOverlay}`
  );

  if (overlay != null) {
    let pos = view.posAtDOM(overlay, 0);
    while (pos >= 0 && !isOverlayNode(view, pos, overlay)) {
      pos = pos - 1;
    }

    const node = view.state.doc.nodeAt(pos);

    if (node != null) {
      if (anchor != null) {
        const targetDomNode = view.nodeDOM(anchor.pos)?.parentNode;
        const anchorDomNode = view.nodeDOM(pos)?.parentNode;

        if (
          targetDomNode != null &&
          anchorDomNode != null &&
          targetDomNode !== anchorDomNode
        ) {
          return undefined;
        }
      }

      return { node: node, pos: pos };
    }
  }

  return undefined;
}
