import { Node, Schema } from "prosemirror-model";
import { EditorState, Plugin } from "prosemirror-state";
import {
  CommandConfiguration,
  CommandConfigurations,
  CommandFn,
  Extension,
  NodeConfig
} from "../../editor";
import {
  dropInsertPlugin,
  executeAtPos,
  isEnableAtPos
} from "../../editor/plugins/drop-insert-plugin";
import { emitNotification } from "../../editor/plugins/notification";
import {
  applyQuestionTitle,
  questionTitleCacheKey,
  questionTitleCachePlugin,
  questionTitlePredicate
} from "../../editor/plugins/question-title-cache";
import { selectionFocusKey } from "../../editor/plugins/selection-focus";
import { getIdGenerator } from "../../extensions/node-identifier";
import { isQuestionTitleAutoCreation } from "../../extensions/question-title/plugins/question-title-autocreation-flag";
import { canInsert, isInsideGrid, isMac } from "../../util";
import { insertBlock } from "../../util/transforms";
import {
  getQuestionTitleActiveValue,
  QuestionTitleActiveValue,
  updateQuestionTitleText
} from "../question-title";
import { InputTextNode } from "./input-text-node";
import { nodeViewPlugin } from "./input-text-node-view";
import {
  InputTextControlType,
  InputTextLength,
  InputTextRows,
  InputTextSchema,
  InputTextWidth
} from "./schema";
import { textFormats } from "./util";

interface InsertInputTextCommandProps {
  controlType: InputTextControlType;
}

export interface UpdateInputTextCommandProps {
  controlType?: InputTextControlType;
  rows?: number;
  width?: number;
  questionTitleText?: string;
  description?: string;
  coding?: string;
  defaultValue?: string;
  watermark?: string;
  meaning?: string | null;
  textLength?: number;
  format?: string;
  required?: boolean;
  regexp?: string | null;
}

export interface InputTextActiveValue {
  controlType: InputTextControlType;
  rows: number;
  width: number;
  questionTitle: QuestionTitleActiveValue;
  description: string;
  coding: string;
  defaultValue: string;
  watermark: string;
  meaning: string | null;
  textLength: number;
  format: string;
  required: boolean;
  regexp: string | null;
  widthDisabled: boolean;
}

const questionTitleKey = questionTitleCacheKey();

export class InputText implements Extension<InputTextSchema> {
  get name(): string {
    return "inputText";
  }

  get nodes(): NodeConfig[] {
    return [new InputTextNode()];
  }

  plugins(schema: InputTextSchema): Plugin[] {
    return [
      nodeViewPlugin(),
      dropInsertPlugin((view, data, posAtCoords) => {
        if (data.type !== "inputText") {
          return false;
        }

        let controlType: InputTextControlType;
        switch (data.subType) {
          case InputTextControlType.textbox:
            controlType = InputTextControlType.textbox;
            break;

          case InputTextControlType.textarea:
            controlType = InputTextControlType.textarea;
            break;

          default:
            controlType = InputTextControlType.textarea;
            break;
        }

        const command = this.insertInputTextCommand(schema);

        const isEnabled = isEnableAtPos(command, view, posAtCoords);
        if (
          !isEnabled({
            controlType: controlType
          })
        ) {
          emitNotification(view.state, {
            type: "warning",
            message: "drop.insert.invalid-location"
          });
        } else {
          executeAtPos(
            command.execute({
              controlType: controlType
            }),
            view,
            posAtCoords
          );
        }

        return true;
      }),
      questionTitleCachePlugin(
        schema.nodes.inputText,
        "INPUT_TEXT.DEFAULT_QUESTION_TITLE",
        questionTitlePredicate,
        applyQuestionTitle,
        questionTitleKey
      )
    ];
  }

  commands(schema: InputTextSchema): CommandConfigurations<InputTextSchema> {
    return {
      insertInputText: this.insertInputTextCommand(schema),
      updateInputText: this.updateInputTextCommand(schema)
    };
  }

  private insertInputTextCommand(
    schema: InputTextSchema
  ): CommandConfiguration<
    InputTextSchema,
    InsertInputTextCommandProps,
    undefined
  > {
    return {
      isActive: () => {
        return false;
      },
      isEnabled: () => {
        return insertInputText(schema, InputTextControlType.textbox);
      },
      execute: (props) => {
        if (props == null) {
          throw new Error(
            `To insert an input text, InsertInputTextCommandProps needs to be provided.`
          );
        }
        const { controlType } = props;

        if (controlType == null) {
          throw new Error(
            `To insert an input text, control type need to be provided.`
          );
        }
        return insertInputText(schema, controlType);
      },
      shortcuts: {
        [isMac() ? "Ctrl-t" : "Alt-t"]: {
          controlType: InputTextControlType.textbox
        }
      }
    };
  }

  private updateInputTextCommand(
    _schema: InputTextSchema
  ): CommandConfiguration<
    InputTextSchema,
    UpdateInputTextCommandProps,
    InputTextActiveValue | undefined
  > {
    return {
      isActive: () => {
        return (state) => {
          return focusedInputText(state) != null;
        };
      },
      isEnabled: () => {
        return (state) => {
          return focusedInputText(state) != null;
        };
      },
      execute: (props) => {
        if (props == null) {
          throw new Error(
            `To update an input text, UpdateInputTextCommandProps needs to be provided.`
          );
        }

        return (state, dispatch) => {
          const focused = focusedInputText(state);
          return updateInputText(props, focused)(state, dispatch);
        };
      },
      activeValue: () => {
        return (state) => {
          const focused = focusedInputText(state);
          if (!focused) {
            return undefined;
          }

          const widthDisabled = isInsideGrid(state, focused);

          return {
            controlType: focused.node.attrs.controlType,
            rows: focused.node.attrs.rows,
            width: widthDisabled ? 100 : focused.node.attrs.width,
            questionTitle: getQuestionTitleActiveValue(state, focused.node),
            description: focused.node.attrs.description,
            coding: focused.node.attrs.coding,
            defaultValue: focused.node.attrs.defaultValue,
            watermark: focused.node.attrs.watermark,
            meaning: focused.node.attrs.meaning,
            textLength: focused.node.attrs.textLength,
            format: focused.node.attrs.format,
            required: focused.node.attrs.required,
            regexp: focused.node.attrs.regexp,
            widthDisabled: widthDisabled
          };
        };
      }
    };
  }
}

function insertInputText(
  schema: InputTextSchema,
  controlType: InputTextControlType
): CommandFn<InputTextSchema> {
  return (state, dispatch) => {
    if (!canInsert(schema.nodes.inputText)(state)) {
      return false;
    } else {
      if (dispatch) {
        const inputText = createInputText(schema, controlType);
        if (inputText == null) {
          return false;
        }

        let tr = state.tr;
        tr = insertBlock(
          tr,
          state.schema,
          inputText,
          false,
          getIdGenerator(state),
          isQuestionTitleAutoCreation(state)
        );

        dispatch(tr);
      }
      return true;
    }
  };
}

function createInputText(
  schema: InputTextSchema,
  controlType: InputTextControlType
): Node<InputTextSchema> {
  const { inputText } = schema.nodes;
  return inputText.createChecked({
    controlType: controlType,
    ...(controlType === InputTextControlType.textarea
      ? { rows: InputTextRows.defaultForTextArea }
      : { rows: InputTextRows.defaultForTextBox })
  });
}

function focusedInputText(state: EditorState<InputTextSchema>) {
  const { schema } = state;

  const focused = selectionFocusKey.getState(state);
  if (focused != null && focused.node.type === schema.nodes.inputText) {
    return focused;
  } else {
    return undefined;
  }
}

function updateInputText<S extends Schema>(
  props: UpdateInputTextCommandProps,
  inputText: { node: Node<S>; pos: number } | undefined
): CommandFn<S> {
  return (state, dispatch) => {
    if (inputText == null) {
      return false;
    }

    if (dispatch) {
      const { node, pos } = inputText;

      let updatedAttrs = {
        ...node.attrs
      };

      if (props.controlType !== undefined) {
        updatedAttrs = { ...updatedAttrs, controlType: props.controlType };
      }

      if (props.rows !== undefined) {
        updatedAttrs = { ...updatedAttrs, rows: props.rows };
      }

      if (props.questionTitleText !== undefined) {
        updatedAttrs = updateQuestionTitleText(
          updatedAttrs,
          props.questionTitleText
        );
      }

      if (props.description !== undefined) {
        updatedAttrs = { ...updatedAttrs, description: props.description };
      }

      if (props.coding !== undefined) {
        updatedAttrs = { ...updatedAttrs, coding: props.coding };
      }

      if (props.defaultValue !== undefined) {
        updatedAttrs = { ...updatedAttrs, defaultValue: props.defaultValue };
      }

      if (props.watermark !== undefined) {
        updatedAttrs = { ...updatedAttrs, watermark: props.watermark };
      }

      if (props.meaning !== undefined) {
        updatedAttrs = { ...updatedAttrs, meaning: props.meaning };
      }

      if (props.textLength !== undefined) {
        updatedAttrs = { ...updatedAttrs, textLength: props.textLength };
      }

      if (props.format !== undefined) {
        updatedAttrs = { ...updatedAttrs, format: props.format };
      }

      if (props.required !== undefined) {
        updatedAttrs = { ...updatedAttrs, required: props.required };
      }

      if (props.regexp !== undefined) {
        updatedAttrs = { ...updatedAttrs, regexp: props.regexp };
      }

      if (
        props.controlType !== undefined &&
        props.controlType !== node.attrs.controlType
      ) {
        const updatedRows = updateRows(props.controlType);
        updatedAttrs = { ...updatedAttrs, rows: updatedRows };
      }

      if (props.width != null && !isNaN(props.width)) {
        const updatedWidth = updateWidth(props.width);
        updatedAttrs = { ...updatedAttrs, width: updatedWidth };
      }

      if (props.textLength !== undefined) {
        if (
          updatedAttrs.textLength != null &&
          updatedAttrs.defaultValue != null
        ) {
          const expectedTextLength = Math.min(
            Math.max(props.textLength, InputTextLength.minimum),
            InputTextLength.maximum
          );

          updatedAttrs = {
            ...updatedAttrs,
            defaultValue: updatedAttrs.defaultValue.substring(
              0,
              expectedTextLength
            ),
            textLength: expectedTextLength
          };
        }
      }

      if (props.format !== undefined) {
        if (props.format === "custom") {
          updatedAttrs = {
            ...updatedAttrs,
            format: "custom"
          };
        } else if (props.format !== "custom") {
          const updatedRegexp = updateRegex(props.format);
          updatedAttrs = {
            ...updatedAttrs,
            regexp: updatedRegexp,
            format: props.format
          };
        }
      }

      if (props.rows !== undefined) {
        if (props.rows < InputTextRows.minimum) {
          updatedAttrs = {
            ...updatedAttrs,
            rows: Math.max(props.rows, InputTextRows.minimum)
          };
        } else if (props.rows > InputTextRows.maximum) {
          updatedAttrs = {
            ...updatedAttrs,
            rows: Math.min(InputTextRows.maximum, props.rows)
          };
        }
      }

      if (props.regexp !== undefined && props.regexp !== node.attrs.regexp) {
        updatedAttrs = {
          ...updatedAttrs,
          regexp: props.regexp,
          format: "custom"
        };
      }

      if (props.meaning !== undefined) {
        const updatedMeaning = updateMeaning(props.meaning);
        updatedAttrs = { ...updatedAttrs, meaning: updatedMeaning };
      }

      let tr = state.tr;
      tr = tr.setNodeMarkup(pos, undefined, updatedAttrs);

      dispatch(tr);
    }
    return true;
  };
}

function updateRegex(textFormat: string): string | null {
  const tfObj = textFormats.find((tf) => tf.id === textFormat);
  let updatedRegex: string | null = null;
  if (tfObj != null) {
    if (tfObj.id === "none") {
      updatedRegex = null;
    } else if (tfObj.id !== "custom") {
      updatedRegex = tfObj.regex == null ? null : tfObj.regex.source;
    }
  }
  return updatedRegex;
}

function updateMeaning(meaning: string | null): string | null {
  if (meaning == null || !meaning.length) {
    return null;
  } else {
    return meaning;
  }
}

function updateWidth(width: number): number {
  if (width < InputTextWidth.minimum) {
    return InputTextWidth.minimum;
  } else if (width > InputTextWidth.maximum) {
    return InputTextWidth.maximum;
  }

  return width;
}

function updateRows(type: InputTextControlType): number {
  let rows: number = 1;
  if (type === InputTextControlType.textbox) {
    rows = InputTextRows.defaultForTextBox;
  } else if (type === InputTextControlType.textarea) {
    rows = InputTextRows.defaultForTextArea;
  }
  return rows;
}
