import { MarkSpec, Schema } from "prosemirror-model";
import {
  BASE_PRIORITY,
  CommandConfiguration,
  CommandConfigurations,
  DocumentBuilders,
  Extension,
  MarkConfig
} from "../../editor";
import { textMarkIsActive, textMarkIsEnabled, toggleMark } from "../../util";

class SubscriptMark implements MarkConfig {
  get name(): string {
    return "subscript";
  }

  get spec(): MarkSpec {
    return {
      group: "text-style",
      excludes: "superscript",
      parseDOM: [
        {
          tag: "sub"
        },
        {
          // Special case for pasting from Google Docs
          // Google Docs uses vertical align to denote subscript and super script
          tag: "span",
          getAttrs: (node) => {
            const element = node as HTMLElement;
            return element.style.verticalAlign === "sub" && null;
          }
        }
      ],
      toDOM() {
        return ["sub", 0];
      }
    };
  }

  get priority(): number {
    return BASE_PRIORITY - 1;
  }

  get builders(): DocumentBuilders {
    return { subscript: { markType: "subscript" } };
  }
}

type SubscriptSchema = Schema<any, "subscript">;

export class Subscript implements Extension<SubscriptSchema> {
  get name(): string {
    return "subscript";
  }

  get marks(): MarkConfig[] {
    return [new SubscriptMark()];
  }

  commands(schema: SubscriptSchema): CommandConfigurations<SubscriptSchema> {
    return {
      subscript: this.subscriptCommand(schema)
    };
  }

  private subscriptCommand(
    schema: SubscriptSchema
  ): CommandConfiguration<SubscriptSchema, {}, undefined> {
    return {
      isActive: () => textMarkIsActive(schema.marks.subscript),
      isEnabled: () => textMarkIsEnabled(schema.marks.subscript),
      execute: () => toggleMark(schema.marks.subscript)
    };
  }
}
