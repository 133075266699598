import { Node, Schema } from "prosemirror-model";
import { NodeSelection, Plugin, PluginKey } from "prosemirror-state";
import { Decoration, DecorationSet, EditorView } from "prosemirror-view";
import { editableKey } from "../../../editor/plugins/editable";
import {
  initDecorations,
  removeDecorations,
  updateDecorations
} from "../../../util";
import { VariableItem, VariableSource } from "../schema";
import { findVariable, isVariable, variableTypeForNodeType } from "../util";

interface VariablesState {
  variables: VariableItem[];
  decorations: DecorationSet;
}

export const variablesKey = new PluginKey<VariablesState, Schema>(
  "variablesPlugin"
);

export const variablesPlugin = (variables: VariableItem[]) => {
  return new Plugin({
    key: variablesKey,
    state: {
      init(_config, state) {
        const decorations = initDecorations(
          state,
          (doc, start, end, decorationSet) => {
            return findNotFoundVariables(
              doc,
              start,
              end,
              decorationSet,
              state.schema,
              variables
            );
          }
        );
        return { variables: variables, decorations: decorations };
      },
      apply(tr, value, _oldState, newState) {
        if (tr.docChanged) {
          const decorations = updateDecorations(
            tr,
            value.decorations,
            (doc, start, end, decorationSet) => {
              return findNotFoundVariables(
                doc,
                start,
                end,
                decorationSet,
                newState.schema,
                value.variables
              );
            }
          );

          return { ...value, decorations: decorations };
        }

        const meta = tr.getMeta(variablesKey) as
          | { variables: VariableItem[] }
          | undefined;

        if (meta == null) {
          return value;
        }

        const { variables } = meta;
        if (variables == null) {
          return value;
        }

        const decorations = initDecorations(
          newState,
          (doc, start, end, decorationSet) => {
            return findNotFoundVariables(
              doc,
              start,
              end,
              decorationSet,
              newState.schema,
              variables
            );
          }
        );
        return { variables: variables, decorations: decorations };
      }
    },
    props: {
      decorations(state) {
        const value = this.getState(state);
        if (value == null) {
          return null;
        } else {
          return value.decorations;
        }
      },
      handleTripleClickOn(view, pos, node, nodePos, event, direct) {
        return setNodeSelectionOnClick(view, pos, node, nodePos, event, direct);
      }
    }
  });
};

function findNotFoundVariables(
  doc: Node<Schema>,
  from: number,
  to: number,
  decorationSet: DecorationSet,
  schema: Schema,
  variables: VariableItem[]
): DecorationSet {
  doc.nodesBetween(from, to, (node, pos) => {
    if (isVariable(schema, node)) {
      const nodeFrom = pos;
      const nodeTo = nodeFrom + node.nodeSize;

      decorationSet = removeDecorations(decorationSet, nodeFrom, nodeTo);

      const type = variableTypeForNodeType(node, schema);
      const source = node.attrs.source as VariableSource;
      const code = node.attrs.code;

      const variable = findVariable(
        { type: type, source: source, code: code },
        variables
      );

      if (variable == null) {
        decorationSet = decorationSet.add(doc, [
          Decoration.node(nodeFrom, nodeTo, {}, { type: "variableNotFound" })
        ]);
      }
    }

    return;
  });

  return decorationSet;
}

function setNodeSelectionOnClick<S extends Schema>(
  view: EditorView<S>,
  _pos: number,
  node: Node<S>,
  nodePos: number,
  _event: MouseEvent,
  direct: boolean
): boolean {
  const { state } = view;

  const editable = editableKey.getState(state);
  if (editable && editable.focusable === false) {
    return false;
  }

  const { schema } = state;

  const isSelectable =
    node.type.spec.selectable && node.type === schema.nodes.linkVariable;

  if (isSelectable && direct) {
    const tr = state.tr.setSelection(
      new NodeSelection(state.doc.resolve(nodePos))
    );
    view.dispatch(tr);
    return true;
  } else {
    return false;
  }
}
