import { Schema, Slice } from "prosemirror-model";
import { dropPoint } from "prosemirror-transform";
import { EditorView } from "prosemirror-view";

function isScrollable(element: Element): boolean {
  const style = window.getComputedStyle(element);
  const overflows = [
    style.getPropertyValue("overflow"),
    style.getPropertyValue("overflow-y"),
    style.getPropertyValue("overflow-x")
  ].join("");

  return /(auto|scroll)/.test(overflows);
}

export function scrollParent(element: Element): Element {
  if (element.parentElement === null) {
    return document.scrollingElement == null
      ? document.documentElement
      : document.scrollingElement;
  }

  if (isScrollable(element.parentElement)) {
    return element.parentElement;
  }

  return scrollParent(element.parentElement);
}

export function posFromEvent<S extends Schema>(
  event: DragEvent,
  view: EditorView<S>,
  slice: Slice<S>
): number | null | undefined {
  const posAtCoords = view.posAtCoords({
    left: event.clientX,
    top: event.clientY
  });

  if (posAtCoords == null) {
    return null;
  }

  return dropPoint(view.state.doc, posAtCoords.pos, slice);
}
