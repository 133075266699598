import { Node, ResolvedPos, Schema, Slice } from "prosemirror-model";
import { Selection } from "prosemirror-state";
import { Mapping } from "prosemirror-transform";
import { UnreachableCaseError } from "../../../util/unreachable-error";
import { Side } from "./side";
import { isValidTargetNode } from "./util";

export const JSON_ID = "gapcursor";

export class GapCursor<S extends Schema> extends Selection<S> {
  public readonly visible: boolean = false;

  /**
   * Construct a GapCursor
   * @param {ResolvedPos} $pos resolved position
   * @param {Side} side side where the gap cursor is drawn
   */
  constructor($pos: ResolvedPos<S>, public readonly side: Side) {
    super($pos, $pos);
  }

  static valid<S extends Schema>($pos: ResolvedPos<S>, side: Side): boolean {
    const { parent, nodeBefore, nodeAfter } = $pos;

    let targetNode: Node<S> | null | undefined;
    if (side === Side.LEFT) {
      targetNode = isValidTargetNode(nodeAfter) ? nodeAfter : null;
    } else if (side === Side.RIGHT) {
      targetNode = isValidTargetNode(nodeBefore) ? nodeBefore : null;
    } else {
      targetNode = null;
    }

    if (!targetNode || parent.isTextblock) {
      return false;
    }

    return true;
  }

  static findFrom<S extends Schema>(
    $pos: ResolvedPos,
    dir: number,
    mustMove = false
  ): GapCursor<S> | null {
    const side = dir === 1 ? Side.RIGHT : Side.LEFT;

    if (!mustMove && GapCursor.valid<S>($pos, side)) {
      return new GapCursor<S>($pos, side);
    }

    let pos = $pos.pos;
    let next: Node<S> | null | undefined = null;

    // Scan up from this position
    for (let d = $pos.depth; ; d--) {
      const parent = $pos.node(d);

      if (
        side === Side.RIGHT
          ? $pos.indexAfter(d) < parent.childCount
          : $pos.index(d) > 0
      ) {
        next = parent.maybeChild(
          side === Side.RIGHT ? $pos.indexAfter(d) : $pos.index(d) - 1
        );
        break;
      } else if (d === 0) {
        return null;
      }

      pos += dir;

      const $cur = $pos.doc.resolve(pos);
      if (GapCursor.valid<S>($cur, side)) {
        return new GapCursor<S>($cur, side);
      }
    }

    // And then down into the next node
    for (;;) {
      next = side === Side.RIGHT ? next?.firstChild : next?.lastChild;

      if (next === null) {
        break;
      }

      pos += dir;

      const $cur = $pos.doc.resolve(pos);
      if (GapCursor.valid<S>($cur, side)) {
        return new GapCursor<S>($cur, side);
      }
    }

    return null;
  }

  static fromJSON<S extends Schema>(
    doc: Node<S>,
    json: { pos: number; type: string; side: Side }
  ): GapCursor<S> {
    return new GapCursor<S>(doc.resolve(json.pos), json.side);
  }

  map(doc: Node<S>, mapping: Mapping): Selection<S> {
    const $pos = doc.resolve(mapping.map(this.head));
    return GapCursor.valid<S>($pos, this.side)
      ? new GapCursor<S>($pos, this.side)
      : Selection.near($pos);
  }

  eq(other: Selection<S>): boolean {
    return other instanceof GapCursor && other.head === this.head;
  }

  content() {
    return Slice.empty;
  }

  getBookmark() {
    return new GapBookmark<S>(this.anchor, this.side);
  }

  toJSON() {
    return { pos: this.head, type: JSON_ID, side: this.side };
  }

  get node(): Node<S> | null {
    switch (this.side) {
      case Side.LEFT:
        const nodeAfter = this.$anchor.nodeAfter;
        return nodeAfter == null ? null : nodeAfter;
      case Side.RIGHT:
        const nodeBefore = this.$anchor.nodeBefore;
        return nodeBefore == null ? null : nodeBefore;
      default:
        throw new UnreachableCaseError(this.side);
    }
  }

  get nodePos(): number | null {
    switch (this.side) {
      case Side.LEFT:
        const nodeAfter = this.$anchor.nodeAfter;
        return nodeAfter == null ? null : this.anchor;
      case Side.RIGHT:
        const nodeBefore = this.$anchor.nodeBefore;
        return nodeBefore == null ? null : this.anchor - nodeBefore.nodeSize;
      default:
        throw new UnreachableCaseError(this.side);
    }
  }
}

Selection.jsonID(JSON_ID, GapCursor);

export class GapBookmark<S extends Schema> {
  constructor(private readonly pos: number, private readonly side: Side) {}

  map(mapping: any) {
    return new GapBookmark<S>(mapping.map(this.pos), this.side);
  }

  resolve(doc: Node<S>): GapCursor<S> | Selection {
    const $pos = doc.resolve(this.pos);
    return GapCursor.valid<S>($pos, this.side)
      ? new GapCursor<S>($pos, this.side)
      : Selection.near($pos);
  }
}
