import {
  EditorState,
  Plugin,
  PluginKey,
  TextSelection
} from "prosemirror-state";
import { Schema, Node } from "prosemirror-model";
import {
  Focus,
  selectionFocusKey
} from "../../../editor/plugins/selection-focus";
import { findNode, findNodePos, findParent } from "../../../util";

type PluginState = {
  focused: Focus<Schema>;
  sectionId: string;
} | null;

export const inputRankViewKey = new PluginKey<PluginState, Schema>(
  "inputRankViewPlugin"
);

export function inputRankViewPlugin() {
  return new Plugin<PluginState, Schema>({
    key: inputRankViewKey,
    state: {
      init(_config, _state) {
        return null;
      },
      apply(tr, value, _oldState, newState) {
        const focused = selectionFocusKey.getState(newState);

        if (!focused) {
          return null;
        }

        const { selection, doc, schema } = newState;

        const nodes: Node<Schema>[] = [];
        if (selection instanceof TextSelection) {
          const { from, to } = selection;

          doc.nodesBetween(from, to, (node) => {
            const isSection = node.type === schema.nodes.inputRankSection;
            if (isSection) {
              nodes.push(node);
            }
          });

          if (nodes.length > 1) {
            return value;
          }
        }

        let newFocused: Focus<Schema>;

        if (focused.node.type === schema.nodes.inputRank) {
          newFocused = focused as Focus<Schema>;
        } else if (focused.node.type === schema.nodes.inputRankSection) {
          const parent = findParent(
            newState.doc.resolve(focused.pos),
            (node) => {
              return node.type === schema.nodes.inputRank;
            }
          );

          if (!parent) {
            return null;
          }

          newFocused = parent;
        } else {
          return null;
        }

        if (
          value != null &&
          newFocused.node.attrs.id === value.focused.node.attrs.id
        ) {
          const isSectionFocus = nodes.length === 1;

          let updatedValue = isSectionFocus
            ? {
                ...value,
                focused: newFocused,
                sectionId: nodes[0].attrs.id
              }
            : { ...value, focused: newFocused };

          const action = tr.getMeta(inputRankViewKey);
          if (action != null && action.setActive != null) {
            const { sectionId } = action.setActive as { sectionId: string };
            updatedValue.sectionId = sectionId;
          }

          return updatedValue;
        } else {
          const sections = newFocused.node.firstChild;

          if (!sections) {
            return null;
          }

          const firstSection = sections.firstChild;

          if (!firstSection) {
            return null;
          }

          const action = tr.getMeta(inputRankViewKey);

          if (action != null && action.setActive != null) {
            const { sectionId } = action.setActive as { sectionId: string };
            return { focused: newFocused, sectionId };
          } else {
            return { focused: newFocused, sectionId: null };
          }
        }
      }
    },
    view(view) {
      const updateSections = (state: EditorState) => {
        const rankState = inputRankViewKey.getState(state);

        if (rankState != null) {
          const { schema } = state;
          const start = rankState.focused.pos + 1;

          let section = findNode(rankState.focused.node, (node) => {
            return (
              node.type === schema.nodes.inputRankSection &&
              node.attrs.id === rankState.sectionId
            );
          });

          let sectionNodeDom;

          if (section == null) {
            const { selection } = state;
            if (!(selection instanceof TextSelection)) {
              return;
            }

            const { $to } = state.tr.selection;
            const node = $to.node();

            if (
              (node.type === schema.nodes.inputRankOption ||
                node.type === schema.nodes.inputRankOptionSelectAll ||
                node.type === schema.nodes.inputRankOptionOtherSpecify) &&
              rankState.sectionId === null
            ) {
              sectionNodeDom = view.nodeDOM(start + 1) as
                | Element
                | null
                | undefined;
            }
          } else {
            sectionNodeDom = view.nodeDOM(section.pos + start) as
              | Element
              | null
              | undefined;
          }

          if (sectionNodeDom == null) {
            return;
          }

          const options = findNode(rankState.focused.node, (node) => {
            return node.type === schema.nodes.inputRankOptions;
          });

          if (options == null) {
            return;
          }
          const optionsNodeDom = view.nodeDOM(options.pos + start) as
            | Element
            | null
            | undefined;

          if (optionsNodeDom == null) {
            return;
          }

          const rankNodeDom = view.nodeDOM(rankState.focused.pos) as
            | Element
            | null
            | undefined;

          if (rankNodeDom == null) {
            return;
          }

          const required = rankState.focused.node.attrs.required ? 25 : 0;
          const rankRect = rankNodeDom.getBoundingClientRect();
          const sectionRect = sectionNodeDom.getBoundingClientRect();

          optionsNodeDom.setAttribute(
            "style",
            `width:${sectionRect.width}px; left: ${
              sectionRect.left - rankRect.left
            }px; top:${
              sectionRect.top - rankRect.top + sectionRect.height - required
            }px`
          );
        }
      };

      const scrollIntoSection = (
        currentState: EditorState,
        previousState: EditorState
      ) => {
        const { $to: $previousTo } = previousState.tr.selection;
        const previousNode = $previousTo.node();

        const { $to } = currentState.tr.selection;
        const currentNode = $to.node();

        const { schema } = previousState;

        if (
          previousNode.type === schema.nodes.inputRankSection &&
          previousNode.attrs.id !== currentNode.attrs.id
        ) {
          const $pos = findNodePos(
            previousState.tr.doc,
            previousState.tr.selection.from,
            previousNode
          );

          if ($pos == null) {
            return;
          }

          const nodeDom = view.nodeDOM($pos.pos + 1) as
            | Element
            | null
            | undefined;

          if (nodeDom == null) {
            return;
          }

          const contentDom = nodeDom.parentElement;

          if (contentDom == null) {
            return;
          }

          contentDom.scrollLeft = 0;
        }
      };

      updateSections(view.state);
      return {
        update: (view, prevState) => {
          updateSections(view.state);
          scrollIntoSection(view.state, prevState);
        },
        destroy: () => {}
      };
    }
  });
}
