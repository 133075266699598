import { Schema } from "prosemirror-model";

export type ConstantSumSchema = Schema<"constantSum", any>;

export enum ConstantSumType {
  sum = "sum",
  distribution = "distribution"
}

export enum ConstantSumLogicDirection {
  horizontal = "horizontal",
  vertical = "vertical"
}

export interface BaseConstantSumDefinition {
  type: ConstantSumType;
  direction: ConstantSumLogicDirection;
}

export enum ConstantSumNumberOfDecimals {
  minimum = 0,
  maximum = 5
}
