import {
  AlignmentType,
  BarChartIndexAxis,
  BarChartQuestionType,
  ConstantSumLogicDirection,
  documentSkins,
  Editor as BlueXEditor,
  InputDateTimeControlType,
  InputFileFormat,
  InputNumberControlType,
  InputRankControlType,
  InputScaleControlType,
  InputTextControlType,
  palettes,
  Schema,
  VariableItem,
  VerticalAlignmentType
} from "@blue-x/editor";
import React, { useCallback, useMemo } from "react";
import {
  ToolbarCustomAreaButton,
  ToolbarFileButton,
  ToolbarGridButton,
  ToolbarImageButton,
  ToolbarVideoButton
} from "./extensions";
import { ToolbarLinkButton } from "./extensions/Link";
import { ManualBindingButton } from "./extensions/ManualBinding";
import "./Toolbar.css";
import ToolbarButton from "./ToolbarButton";
import ToolbarDropdown, {
  ToolbarDropdownList,
  ToolbarDropdownToggle
} from "./ToolbarDropdown";
import ToolbarGroup from "./ToolbarGroup";
import ToolbarIcon from "./ToolbarIcon";
import ToolbarTooltip from "./ToolbarTooltip";
interface Props {
  editor: BlueXEditor<Schema>;
}

const textSizes: number[] = [
  8,
  9,
  10,
  11,
  12,
  14,
  16,
  18,
  20,
  24,
  26,
  28,
  37,
  48,
  72
];

const fonts: string[] = [
  "Headings",
  "Body",
  "Arial",
  "Arial Black",
  "Georgia",
  "Impact",
  "Tahoma",
  "Times New Roman",
  "Verdana",
  "Courier New"
];

const textColors: string[] = ["automatic", "#FF0000", "#00FF00", "#0000FF"];

const textBackgroundColors: string[] = [
  "automatic",
  "#FF0000",
  "#00FF00",
  "#0000FF"
];

// const styles: string[] = [
//   "Normal",
//   "Heading 1",
//   "Heading 2",
//   "Heading 3",
//   "Heading 4",
//   "Heading 5",
//   "Question Title"
// ];

const headings: number[] = [-1, 1, 2, 3, 4, 5, 6];

const alignments: AlignmentType[] = ["left", "center", "right", "justify"];

const verticalAlignments: VerticalAlignmentType[] = ["top", "middle", "bottom"];

const Toolbar: React.FC<Props> = (props) => {
  const { editor } = props;
  const { commands } = editor;

  const textSizeAttribute = useCallback((option) => {
    return { size: option };
  }, []);

  const textFontAttribute = useCallback((option) => {
    return { family: option };
  }, []);

  const textColorAttribute = useCallback((option) => {
    return { color: option === "automatic" ? null : option };
  }, []);

  const textBackgroundColorAttribute = useCallback((option) => {
    return { color: option === "automatic" ? null : option };
  }, []);

  // const styleAttribute = useCallback((option) => {
  //   return { style: option };
  // }, []);

  const headingAttribute = useCallback((option) => {
    return option === -1 ? { level: null } : { level: option };
  }, []);

  const headingName = useCallback((activeValue: number) => {
    switch (activeValue) {
      case 1:
        return "Heading 01";
      case 2:
        return "Heading 02";
      case 3:
        return "Heading 03";
      case 4:
        return "Heading 04";
      case 5:
        return "Heading 05";
      case 6:
        return "Question Title";
      case -1:
        return "Normal";
      default:
        return "";
    }
  }, []);

  const headingDisplayName = useCallback(
    (activeValue: number, isParagraphActive: boolean) => {
      if (activeValue == null && isParagraphActive) {
        return headingName(-1);
      } else {
        return headingName(activeValue);
      }
    },
    [headingName]
  );

  const variables = useMemo(() => {
    return (commands.setVariables?.activeValue() as VariableItem[]) ?? [];
  }, [commands]);

  const variablesOptions = useMemo(() => {
    return variables.map((x) => x.definition.code);
  }, [variables]);

  const variableTitle = useCallback(
    (option: string): string => {
      const variable = variables.find((v) => v.definition.code === option);
      return variable == null ? "" : variable.name;
    },
    [variables]
  );

  const variablesAttribute = useCallback(
    (option: string) => {
      const variable = variables.find((v) => v.definition.code === option);
      if (variable == null) {
        return {};
      }

      return {
        definition: variable.definition
      };
    },
    [variables]
  );

  const copyState = useCallback(() => {
    navigator.clipboard.writeText(JSON.stringify(editor.getJSON()));
  }, [editor]);

  const pasteState = useCallback(() => {
    const json = window.prompt();
    if (json) {
      try {
        const parsed = JSON.parse(json);
        const doc = editor.state.schema.nodeFromJSON(parsed);
        editor.replaceState(doc);
      } catch {}
    }
  }, [editor]);

  const clearVariables = useCallback(() => {
    editor.commands.setVariables?.execute({ variables: [] });
  }, [editor]);

  const importAttributes = useMemo(() => {
    return {
      nodes: [
        {
          type: "paragraph",
          attrs: {
            id: "E32380AEBD9146B495C0B34F8B8A3539",
            alignment: null,
            indentation: null
          },
          content: [{ type: "text", text: "inserted" }]
        },
        {
          type: "inputText",
          attrs: {
            id: "36D418E594BD48B4948BEF28459569EF",
            controlType: "textbox",
            rows: 1,
            width: 25,
            questionTitleText: "Comment question 1",
            questionTitleRefElementId: [],
            description: "",
            coding: "",
            defaultValue: "",
            watermark: "",
            meaning: null,
            textLength: null,
            format: "none",
            required: false,
            regexp: null,
            alignment: null
          }
        },
        {
          type: "paragraph",
          attrs: {
            id: "91FD26A55B8640109D38DBCE404D39FD",
            alignment: null,
            indentation: null
          },
          content: [{ type: "text", text: "inserted" }]
        }
      ]
    };
  }, []);

  return (
    <div className="Toolbar">
      <div className="Toolbar-row">
        <ToolbarDropdown
          toggle={
            <ToolbarTooltip
              title="Font family"
              subtitle="Choose a font for your text."
            >
              <ToolbarDropdownToggle disabled={!commands.textFont?.isEnabled()}>
                {commands.textFont?.activeValue()}
              </ToolbarDropdownToggle>
            </ToolbarTooltip>
          }
          content={
            <ToolbarDropdownList
              command={commands.textFont}
              options={fonts}
              attribute={textFontAttribute}
            />
          }
        />

        <ToolbarDropdown
          toggle={
            <ToolbarTooltip
              title="Font size"
              subtitle="Change the size of your text."
            >
              <ToolbarDropdownToggle disabled={!commands.textSize?.isEnabled()}>
                {commands.textSize?.activeValue()}
              </ToolbarDropdownToggle>
            </ToolbarTooltip>
          }
          content={
            <ToolbarDropdownList
              command={commands.textSize}
              options={textSizes}
              attribute={textSizeAttribute}
            />
          }
        />

        <ToolbarTooltip
          title="Bold"
          subtitle="Bold your text."
          shortcut={commands.bold?.shortcut()}
        >
          <ToolbarButton command={commands.bold}>
            <ToolbarIcon icon="bold" />
          </ToolbarButton>
        </ToolbarTooltip>

        <ToolbarTooltip
          title="Italic"
          subtitle="Italicize your text."
          shortcut={commands.italic?.shortcut()}
        >
          <ToolbarButton command={commands.italic}>
            <ToolbarIcon icon="italic" />
          </ToolbarButton>
        </ToolbarTooltip>

        <ToolbarTooltip
          title="Underline"
          subtitle="Put a line under your text."
          shortcut={commands.underline?.shortcut()}
        >
          <ToolbarButton command={commands.underline}>
            <ToolbarIcon icon="underline" />
          </ToolbarButton>
        </ToolbarTooltip>

        <ToolbarDropdown
          toggle={
            <ToolbarTooltip
              title="Text color"
              subtitle="Change the font color of your text."
            >
              <ToolbarDropdownToggle
                color={commands.textColor?.activeValue()}
                disabled={!commands.textColor?.isEnabled()}
              >
                Text color
              </ToolbarDropdownToggle>
            </ToolbarTooltip>
          }
          content={
            <ToolbarDropdownList
              command={commands.textColor}
              options={textColors}
              attribute={textColorAttribute}
            />
          }
        />

        <ToolbarDropdown
          toggle={
            <ToolbarTooltip
              title="Text background"
              subtitle="Change the background color of your text."
            >
              <ToolbarDropdownToggle
                color={commands.textBackgroundColor?.activeValue()}
                disabled={!commands.textBackgroundColor?.isEnabled()}
              >
                Text background
              </ToolbarDropdownToggle>
            </ToolbarTooltip>
          }
          content={
            <ToolbarDropdownList
              command={commands.textBackgroundColor}
              options={textBackgroundColors}
              attribute={textBackgroundColorAttribute}
            />
          }
        />

        <ToolbarDropdown
          toggle={
            <ToolbarTooltip
              title="Additional font tools"
              subtitle="Additional font formatting tools."
            >
              <ToolbarDropdownToggle
                disabled={
                  !commands.strikethrough?.isEnabled() &&
                  !commands.subscript?.isEnabled() &&
                  !commands.superscript?.isEnabled()
                }
              >
                <ToolbarIcon icon="text-format" />
              </ToolbarDropdownToggle>
            </ToolbarTooltip>
          }
          content={
            <ToolbarGroup>
              <ToolbarTooltip
                title="Strikethrough"
                subtitle="Put a line through the text."
              >
                <ToolbarButton command={commands.strikethrough}>
                  <ToolbarIcon icon="strikethrough" />
                </ToolbarButton>
              </ToolbarTooltip>

              <ToolbarTooltip
                title="Subscript"
                subtitle="Make your text small at the bottom of the text line."
              >
                <ToolbarButton command={commands.subscript}>
                  <ToolbarIcon icon="subscript" />
                </ToolbarButton>
              </ToolbarTooltip>

              <ToolbarTooltip
                title="Superscript"
                subtitle="Make your text small on the top of the text line."
              >
                <ToolbarButton command={commands.superscript}>
                  <ToolbarIcon icon="superscript" />
                </ToolbarButton>
              </ToolbarTooltip>
            </ToolbarGroup>
          }
        />

        <ToolbarDropdown
          toggle={
            <ToolbarTooltip
              title="Style"
              subtitle="Apply a set of formatting to your paragraph."
            >
              <ToolbarDropdownToggle>
                {headingDisplayName(
                  commands.headings?.activeValue(),
                  commands.paragraph?.isActive()
                )}
              </ToolbarDropdownToggle>
            </ToolbarTooltip>
          }
          content={
            <>
              <ToolbarDropdownList
                command={commands.headings}
                options={headings}
                title={headingName}
                attribute={headingAttribute}
              />
            </>
          }
        />

        {alignments.map((alignment) => {
          const title = `Align ${alignment}`;
          const subtitle = `Align your text on the ${alignment}.`;
          let icon: string | null = null;
          switch (alignment) {
            case "center":
              icon = "align-center";
              break;

            case "justify":
              icon = "align-justify";
              break;

            case "left":
              icon = "align-to-left";
              break;

            case "right":
              icon = "align-to-right";
              break;
          }

          return (
            <ToolbarTooltip key={alignment} title={title} subtitle={subtitle}>
              <ToolbarButton
                command={commands.alignment}
                attributes={{ alignment: alignment }}
              >
                <ToolbarIcon icon={icon!} />
              </ToolbarButton>
            </ToolbarTooltip>
          );
        })}

        <ToolbarDropdown
          toggle={
            <ToolbarTooltip
              title="Vertical alignment"
              subtitle="Align content vertically."
            >
              <ToolbarDropdownToggle
                disabled={!commands.verticalAlignment?.isEnabled()}
              >
                <ToolbarIcon icon="text-format" />
              </ToolbarDropdownToggle>
            </ToolbarTooltip>
          }
          content={
            <ToolbarGroup>
              {verticalAlignments.map((alignment) => {
                const title = `Vertical align ${alignment}`;
                const subtitle = `Vertical align your text on the ${alignment}.`;
                let icon: string | null = null;
                switch (alignment) {
                  case "top":
                    icon = "align-top";
                    break;

                  case "middle":
                    icon = "align-middle";
                    break;

                  case "bottom":
                    icon = "align-bottom";
                    break;
                }

                return (
                  <ToolbarTooltip
                    key={alignment}
                    title={title}
                    subtitle={subtitle}
                  >
                    <ToolbarButton
                      command={commands.verticalAlignment}
                      attributes={{ alignment: alignment }}
                    >
                      <ToolbarIcon icon={icon!} />
                    </ToolbarButton>
                  </ToolbarTooltip>
                );
              })}
            </ToolbarGroup>
          }
        />

        <ToolbarTooltip title="Bullet list" subtitle="An unorderd list.">
          <ToolbarButton command={commands.bulletList}>
            <ToolbarIcon icon="unordered-list" />
          </ToolbarButton>
        </ToolbarTooltip>

        <ToolbarTooltip title="Ordered list" subtitle="An orderd list.">
          <ToolbarButton command={commands.orderedList}>
            <ToolbarIcon icon="numbered-list" />
          </ToolbarButton>
        </ToolbarTooltip>

        <ToolbarTooltip title="Outdent" subtitle="Outdent text.">
          <ToolbarButton command={commands.outdent}>
            <ToolbarIcon icon="left-indent" />
          </ToolbarButton>
        </ToolbarTooltip>

        <ToolbarTooltip title="Indent" subtitle="Indent text.">
          <ToolbarButton command={commands.indent}>
            <ToolbarIcon icon="right-indent" />
          </ToolbarButton>
        </ToolbarTooltip>

        <ToolbarTooltip
          title="Undo"
          subtitle="Undo an action."
          shortcut={commands.undo?.shortcut()}
        >
          <ToolbarButton command={commands.undo}>
            <ToolbarIcon icon="undo" />
          </ToolbarButton>
        </ToolbarTooltip>

        <ToolbarTooltip
          title="Redo"
          subtitle="Redo an action."
          shortcut={commands.redo?.shortcut()}
        >
          <ToolbarButton command={commands.redo}>
            <ToolbarIcon icon="do" />
          </ToolbarButton>
        </ToolbarTooltip>

        <ToolbarLinkButton editor={editor} />

        <ToolbarFileButton editor={editor} />

        <ToolbarImageButton editor={editor} />

        <ToolbarVideoButton editor={editor} />

        <ToolbarTooltip
          title="Page break"
          subtitle="Insert a page break to define where a page ends and the next one starts."
          shortcut={commands.insertPageBreak?.shortcut()}
        >
          <ToolbarButton
            command={commands.insertPageBreak}
            drag={{ event: "insert", type: "pageBreak" }}
          >
            <ToolbarIcon icon="pagebreak" />
          </ToolbarButton>
        </ToolbarTooltip>
      </div>
      <div className="Toolbar-row">
        <ToolbarDropdown
          toggle={
            <ToolbarTooltip
              title="Choice"
              subtitle="Insert a choice."
              shortcut={commands.insertInputChoice?.shortcut({
                controlType: "single-vertical"
              })}
            >
              <ToolbarDropdownToggle
                disabled={!commands.insertInputChoice?.isEnabled()}
                drag={{ event: "insert", type: "inputChoice" }}
              >
                <ToolbarIcon icon="input-choices" />
              </ToolbarDropdownToggle>
            </ToolbarTooltip>
          }
          content={
            <ToolbarGroup>
              <ToolbarTooltip
                title="Single response (vertical)"
                subtitle="Insert a choice with a single response in column."
              >
                <ToolbarButton
                  command={commands.insertInputChoice}
                  attributes={{
                    controlType: "single-vertical"
                  }}
                  shouldCloseDropdown={true}
                  drag={{
                    event: "insert",
                    type: "inputChoice",
                    subType: "single-vertical"
                  }}
                >
                  <ToolbarIcon icon="single-choice-vertical" />
                </ToolbarButton>
              </ToolbarTooltip>

              <ToolbarTooltip
                title="Single response (horizontal)"
                subtitle="Insert a choice with a single response in row."
              >
                <ToolbarButton
                  command={commands.insertInputChoice}
                  attributes={{
                    controlType: "single-horizontal"
                  }}
                  shouldCloseDropdown={true}
                  drag={{
                    event: "insert",
                    type: "inputChoice",
                    subType: "single-horizontal"
                  }}
                >
                  <ToolbarIcon icon="single-choice-horizontal" />
                </ToolbarButton>
              </ToolbarTooltip>

              <ToolbarTooltip
                title="Checkbox (vertical)"
                subtitle="Insert a choice with a multiple response in column."
              >
                <ToolbarButton
                  command={commands.insertInputChoice}
                  attributes={{
                    controlType: "multiple-vertical"
                  }}
                  shouldCloseDropdown={true}
                  drag={{
                    event: "insert",
                    type: "inputChoice",
                    subType: "multiple-vertical"
                  }}
                >
                  <ToolbarIcon icon="multi-choice-vertical" />
                </ToolbarButton>
              </ToolbarTooltip>

              <ToolbarTooltip
                title="Checkbox (horizontal)"
                subtitle="Insert a choice with a multiple response in row."
              >
                <ToolbarButton
                  command={commands.insertInputChoice}
                  attributes={{
                    controlType: "multiple-horizontal"
                  }}
                  shouldCloseDropdown={true}
                  drag={{
                    event: "insert",
                    type: "inputChoice",
                    subType: "multiple-horizontal"
                  }}
                >
                  <ToolbarIcon icon="multi-choice-horizontal" />
                </ToolbarButton>
              </ToolbarTooltip>

              <ToolbarTooltip
                title="Dropdown choice"
                subtitle="Insert a choice as dropdown."
              >
                <ToolbarButton
                  command={commands.insertInputChoice}
                  attributes={{
                    controlType: "dropdown-list"
                  }}
                  shouldCloseDropdown={true}
                  drag={{
                    event: "insert",
                    type: "inputChoice",
                    subType: "dropdown-list"
                  }}
                >
                  <ToolbarIcon icon="dropdown-list" />
                </ToolbarButton>
              </ToolbarTooltip>

              <ToolbarTooltip
                title="List box"
                subtitle="Insert a choice as list box."
              >
                <ToolbarButton
                  command={commands.insertInputChoice}
                  attributes={{
                    controlType: "list-box"
                  }}
                  shouldCloseDropdown={true}
                  drag={{
                    event: "insert",
                    type: "inputChoice",
                    subType: "list-box"
                  }}
                >
                  <ToolbarIcon icon="list-box" />
                </ToolbarButton>
              </ToolbarTooltip>
            </ToolbarGroup>
          }
        />

        <ToolbarDropdown
          toggle={
            <ToolbarTooltip
              title="Input Text"
              subtitle="Insert an input text."
              shortcut={commands.insertInputText?.shortcut({
                controlType: InputTextControlType.textbox
              })}
            >
              <ToolbarDropdownToggle
                disabled={!commands.insertInputText?.isEnabled()}
                drag={{ event: "insert", type: "inputText" }}
              >
                <ToolbarIcon icon="input-text" />
              </ToolbarDropdownToggle>
            </ToolbarTooltip>
          }
          content={
            <ToolbarGroup>
              <ToolbarTooltip title="Single line response">
                <ToolbarButton
                  command={commands.insertInputText}
                  attributes={{ controlType: InputTextControlType.textbox }}
                  shouldCloseDropdown={true}
                  drag={{
                    event: "insert",
                    type: "inputText",
                    subType: InputTextControlType.textbox
                  }}
                >
                  <ToolbarIcon icon="textbox" />
                </ToolbarButton>
              </ToolbarTooltip>
              <ToolbarTooltip title="Multiple line response">
                <ToolbarButton
                  command={commands.insertInputText}
                  attributes={{ controlType: InputTextControlType.textarea }}
                  shouldCloseDropdown={true}
                  drag={{
                    event: "insert",
                    type: "inputText",
                    subType: InputTextControlType.textarea
                  }}
                >
                  <ToolbarIcon icon="commentbox" />
                </ToolbarButton>
              </ToolbarTooltip>
            </ToolbarGroup>
          }
        />

        <ToolbarDropdown
          toggle={
            <ToolbarTooltip
              title="Input Rank"
              subtitle="Insert an input rank."
              shortcut={commands.insertInputRank?.shortcut({
                controlType: InputRankControlType.dragDrop
              })}
            >
              <ToolbarDropdownToggle
                disabled={!commands.insertInputRank?.isEnabled()}
                drag={{ event: "insert", type: "inputRank" }}
              >
                <ToolbarIcon icon="input-ranking" />
              </ToolbarDropdownToggle>
            </ToolbarTooltip>
          }
          content={
            <ToolbarGroup>
              <ToolbarTooltip title="Drag and drop">
                <ToolbarButton
                  command={commands.insertInputRank}
                  attributes={{ controlType: InputRankControlType.dragDrop }}
                  shouldCloseDropdown={true}
                  drag={{
                    event: "insert",
                    type: "inputRank",
                    subType: InputRankControlType.dragDrop
                  }}
                >
                  <ToolbarIcon icon="ranking-drag" />
                </ToolbarButton>
              </ToolbarTooltip>
            </ToolbarGroup>
          }
        />

        <ToolbarDropdown
          toggle={
            <ToolbarTooltip
              title="Date/Time"
              subtitle="Insert date or time."
              shortcut={commands.insertInputDateTime?.shortcut({
                controlType: InputDateTimeControlType.date
              })}
            >
              <ToolbarDropdownToggle
                disabled={!commands.insertInputDateTime?.isEnabled()}
                drag={{
                  event: "insert",
                  type: "inputDateTime"
                }}
              >
                <ToolbarIcon icon="calendar-thin" />
              </ToolbarDropdownToggle>
            </ToolbarTooltip>
          }
          content={
            <ToolbarGroup>
              <ToolbarTooltip title="Date">
                <ToolbarButton
                  command={commands.insertInputDateTime}
                  attributes={{ controlType: InputDateTimeControlType.date }}
                  shouldCloseDropdown={true}
                  drag={{
                    event: "insert",
                    type: "inputDateTime",
                    subType: InputDateTimeControlType.date
                  }}
                >
                  <ToolbarIcon icon="date" />
                </ToolbarButton>
              </ToolbarTooltip>
              <ToolbarTooltip title="Time">
                <ToolbarButton
                  command={commands.insertInputDateTime}
                  attributes={{ controlType: InputDateTimeControlType.time }}
                  shouldCloseDropdown={true}
                  drag={{
                    event: "insert",
                    type: "inputDateTime",
                    subType: InputDateTimeControlType.time
                  }}
                >
                  <ToolbarIcon icon="time" />
                </ToolbarButton>
              </ToolbarTooltip>
            </ToolbarGroup>
          }
        />

        <ToolbarDropdown
          toggle={
            <ToolbarTooltip
              title="Numeric question"
              subtitle="Choose a question type."
              shortcut={commands.insertInputNumber?.shortcut({
                controlType: InputNumberControlType.spinbox
              })}
            >
              <ToolbarDropdownToggle
                disabled={!commands.insertInputNumber?.isEnabled()}
                drag={{
                  event: "insert",
                  type: "inputNumber"
                }}
              >
                <ToolbarIcon icon="input-number" />
              </ToolbarDropdownToggle>
            </ToolbarTooltip>
          }
          content={
            <ToolbarGroup>
              <ToolbarTooltip
                title="Spin box"
                subtitle="Insert a numeric spinbox question."
              >
                <ToolbarButton
                  command={commands.insertInputNumber}
                  attributes={{
                    controlType: InputNumberControlType.spinbox
                  }}
                  shouldCloseDropdown={true}
                  drag={{
                    event: "insert",
                    type: "inputNumber",
                    subType: InputNumberControlType.spinbox
                  }}
                >
                  <ToolbarIcon icon="numberbox" />
                </ToolbarButton>
              </ToolbarTooltip>

              <ToolbarTooltip
                title="Slider"
                subtitle="Insert a numeric slider question."
              >
                <ToolbarButton
                  command={commands.insertInputNumber}
                  attributes={{
                    controlType: InputNumberControlType.slider
                  }}
                  shouldCloseDropdown={true}
                  drag={{
                    event: "insert",
                    type: "inputNumber",
                    subType: InputNumberControlType.slider
                  }}
                >
                  <ToolbarIcon icon="slider" />
                </ToolbarButton>
              </ToolbarTooltip>
            </ToolbarGroup>
          }
        />

        <ToolbarDropdown
          toggle={
            <ToolbarTooltip
              title="Scale"
              subtitle="Insert a scale question."
              shortcut={commands.insertInputScale?.shortcut({
                controlType: InputScaleControlType.labelsInRow
              })}
            >
              <ToolbarDropdownToggle
                disabled={!commands.insertInputScale?.isEnabled()}
                drag={{
                  event: "insert",
                  type: "inputScale"
                }}
              >
                <ToolbarIcon icon="input-likert" />
              </ToolbarDropdownToggle>
            </ToolbarTooltip>
          }
          content={
            <ToolbarGroup>
              <ToolbarTooltip
                title="Lables in row"
                subtitle="Insert a scale question with labels in row."
              >
                <ToolbarButton
                  command={commands.insertInputScale}
                  attributes={{
                    controlType: InputScaleControlType.labelsInRow
                  }}
                  shouldCloseDropdown={true}
                  drag={{
                    event: "insert",
                    type: "inputScale",
                    subType: InputScaleControlType.labelsInRow
                  }}
                >
                  <ToolbarIcon icon="likert-in-row" />
                </ToolbarButton>
              </ToolbarTooltip>

              <ToolbarTooltip
                title="Dropdown"
                subtitle="Insert a scale question as dropdown."
              >
                <ToolbarButton
                  command={commands.insertInputScale}
                  attributes={{
                    controlType: InputScaleControlType.dropdown
                  }}
                  shouldCloseDropdown={true}
                  drag={{
                    event: "insert",
                    type: "inputScale",
                    subType: InputScaleControlType.dropdown
                  }}
                >
                  <ToolbarIcon icon="dropdown-list" />
                </ToolbarButton>
              </ToolbarTooltip>

              <ToolbarTooltip
                title="Listbox"
                subtitle="Insert a scale question as listbox."
              >
                <ToolbarButton
                  command={commands.insertInputScale}
                  attributes={{
                    controlType: InputScaleControlType.listbox
                  }}
                  shouldCloseDropdown={true}
                  drag={{
                    event: "insert",
                    type: "inputScale",
                    subType: InputScaleControlType.listbox
                  }}
                >
                  <ToolbarIcon icon="list-box" />
                </ToolbarButton>
              </ToolbarTooltip>
            </ToolbarGroup>
          }
        />

        <ToolbarDropdown
          toggle={
            <ToolbarTooltip
              title="Media Response"
              subtitle="Insert an input file."
              shortcut={commands.insertInputFile?.shortcut({
                format: InputFileFormat.document
              })}
            >
              <ToolbarDropdownToggle
                disabled={!commands.insertInputFile?.isEnabled()}
                drag={{
                  event: "insert",
                  type: "inputFile"
                }}
              >
                <ToolbarIcon icon="input-upload" />
              </ToolbarDropdownToggle>
            </ToolbarTooltip>
          }
          content={
            <ToolbarGroup>
              <ToolbarTooltip title="Document">
                <ToolbarButton
                  command={commands.insertInputFile}
                  attributes={{ format: InputFileFormat.document }}
                  shouldCloseDropdown={true}
                  drag={{
                    event: "insert",
                    type: "inputFile",
                    subType: InputFileFormat.document
                  }}
                >
                  <ToolbarIcon icon="input-document" />
                </ToolbarButton>
              </ToolbarTooltip>
              <ToolbarTooltip title="Image">
                <ToolbarButton
                  command={commands.insertInputFile}
                  attributes={{ format: InputFileFormat.image }}
                  shouldCloseDropdown={true}
                  drag={{
                    event: "insert",
                    type: "inputFile",
                    subType: InputFileFormat.image
                  }}
                >
                  <ToolbarIcon icon="input-picture" />
                </ToolbarButton>
              </ToolbarTooltip>
              <ToolbarTooltip title="Sound">
                <ToolbarButton
                  command={commands.insertInputFile}
                  attributes={{ format: InputFileFormat.sound }}
                  shouldCloseDropdown={true}
                  drag={{
                    event: "insert",
                    type: "inputFile",
                    subType: InputFileFormat.sound
                  }}
                >
                  <ToolbarIcon icon="input-audio" />
                </ToolbarButton>
              </ToolbarTooltip>
              <ToolbarTooltip title="Video">
                <ToolbarButton
                  command={commands.insertInputFile}
                  attributes={{ format: InputFileFormat.video }}
                  shouldCloseDropdown={true}
                  drag={{
                    event: "insert",
                    type: "inputFile",
                    subType: InputFileFormat.video
                  }}
                >
                  <ToolbarIcon icon="input-video" />
                </ToolbarButton>
              </ToolbarTooltip>
            </ToolbarGroup>
          }
        />

        <ToolbarDropdown
          toggle={
            <ToolbarTooltip
              title="Bar chart"
              subtitle="Insert a bar chart."
              shortcut={commands.insertBarChart?.shortcut({
                questionType: BarChartQuestionType.empty,
                type: BarChartIndexAxis.horizontal
              })}
            >
              <ToolbarDropdownToggle
                disabled={!commands.insertBarChart?.isEnabled()}
                drag={{
                  event: "insert",
                  type: "barChart"
                }}
              >
                <ToolbarIcon icon="chart-bar" />
              </ToolbarDropdownToggle>
            </ToolbarTooltip>
          }
          content={
            <ToolbarGroup>
              <ToolbarTooltip title="Horizontal">
                <ToolbarButton
                  command={commands.insertBarChart}
                  attributes={{
                    questionType: BarChartQuestionType.empty,
                    type: BarChartIndexAxis.horizontal
                  }}
                  shouldCloseDropdown={true}
                  drag={{
                    event: "insert",
                    type: "barChart",
                    subType: BarChartIndexAxis.horizontal
                  }}
                >
                  <ToolbarIcon icon="bar-horizontal" />
                </ToolbarButton>
              </ToolbarTooltip>
              <ToolbarTooltip title="Vertical">
                <ToolbarButton
                  command={commands.insertBarChart}
                  attributes={{
                    questionType: BarChartQuestionType.empty,
                    type: BarChartIndexAxis.vertical
                  }}
                  shouldCloseDropdown={true}
                  drag={{
                    event: "insert",
                    type: "barChart",
                    subType: BarChartIndexAxis.vertical
                  }}
                >
                  <ToolbarIcon icon="bar-vertical" />
                </ToolbarButton>
              </ToolbarTooltip>
            </ToolbarGroup>
          }
        />

        <ToolbarCustomAreaButton editor={editor} />

        <ToolbarGridButton editor={editor} />
        <ManualBindingButton editor={editor} />

        <ToolbarDropdown
          toggle={
            <ToolbarTooltip title="Variable" subtitle="Choose a type variable">
              <ToolbarDropdownToggle
                disabled={!commands.insertVariable?.isEnabled()}
              >
                <ToolbarIcon icon="checkmark" />
              </ToolbarDropdownToggle>
            </ToolbarTooltip>
          }
          content={
            <ToolbarDropdownList
              options={variablesOptions}
              title={variableTitle}
              command={commands.insertVariable}
              attribute={variablesAttribute}
            />
          }
        />
        <ToolbarDropdown
          toggle={
            <ToolbarTooltip
              title="Constant Sum"
              subtitle="Insert a constant sum question"
              shortcut={commands.insertConstantSumGrid?.shortcut({
                direction: ConstantSumLogicDirection.vertical
              })}
            >
              <ToolbarDropdownToggle
                disabled={!commands.insertConstantSumGrid?.isEnabled()}
                drag={{
                  event: "insert",
                  type: "constantSum"
                }}
              >
                <ToolbarIcon icon="input-constant-sum" />
              </ToolbarDropdownToggle>
            </ToolbarTooltip>
          }
          content={
            <ToolbarGroup>
              <ToolbarTooltip
                title="Horizontal"
                subtitle="Insert a horizontal contant sum question."
              >
                <ToolbarButton
                  command={commands.insertConstantSumGrid}
                  attributes={{
                    direction: ConstantSumLogicDirection.horizontal
                  }}
                  shouldCloseDropdown={true}
                  drag={{
                    event: "insert",
                    type: "constantSum",
                    subType: ConstantSumLogicDirection.horizontal
                  }}
                >
                  <ToolbarIcon icon="horizontal-sum" />
                </ToolbarButton>
              </ToolbarTooltip>

              <ToolbarTooltip
                title="Vertical"
                subtitle="Insert a vertical contant sum question."
              >
                <ToolbarButton
                  command={commands.insertConstantSumGrid}
                  attributes={{
                    direction: ConstantSumLogicDirection.vertical
                  }}
                  shouldCloseDropdown={true}
                  drag={{
                    event: "insert",
                    type: "constantSum",
                    subType: ConstantSumLogicDirection.vertical
                  }}
                >
                  <ToolbarIcon icon="vertical-sum" />
                </ToolbarButton>
              </ToolbarTooltip>
            </ToolbarGroup>
          }
        />

        <ToolbarTooltip title="Function" subtitle="Insert a function">
          <ToolbarButton
            command={commands.insertConstantSum}
            attributes={{
              direction: "auto-assign"
            }}
            drag={{ event: "insert", type: "function" }}
          >
            <ToolbarIcon icon="function" />
          </ToolbarButton>
        </ToolbarTooltip>

        <ToolbarTooltip
          title="Import"
          subtitle="Import json nodes into the editor"
        >
          <ToolbarButton
            command={commands.import}
            attributes={importAttributes}
            shouldCloseDropdown={true}
          >
            <ToolbarIcon icon="import" />
          </ToolbarButton>
        </ToolbarTooltip>

        <button className="Editor-copy-button" onClick={copyState}>
          Copy JSON
        </button>

        <button className="Editor-copy-button" onClick={pasteState}>
          Paste JSON
        </button>

        <button className="Editor-variables-button" onClick={clearVariables}>
          Clear Variables
        </button>

        <select
          value={editor.commands.changeLanguage?.activeValue()?.language}
          onChange={(event) => {
            editor.commands.changeLanguage?.execute(
              {
                language: event.target.value
              },
              false
            );
          }}
        >
          <option value="en">English</option>
          <option value="fr">Français</option>
        </select>

        <select
          onChange={(event) => {
            const mode = event.target.value;
            switch (mode) {
              case "editable":
                editor.setEditable({ editable: true });
                break;
              case "readonly-with-focus":
                editor.setEditable({ editable: false, focusable: true });
                break;
              case "readonly":
                editor.setEditable({ editable: false, focusable: false });
                break;
            }
          }}
        >
          <option value="editable">Editable</option>
          <option value="readonly-with-focus">Readonly with focus</option>
          <option value="readonly">Readonly</option>
        </select>

        <select
          value={editor.commands.changeColorScheme?.activeValue()?.palette.id}
          onChange={(event) => {
            editor.commands.changeColorScheme?.execute(
              {
                paletteId: event.target.value
              },
              false
            );
          }}
        >
          {palettes.map((palette) => {
            return (
              <option value={palette.id} key={palette.id}>
                {palette.id}
              </option>
            );
          })}
        </select>

        <select
          value={
            editor.commands.changeDocumentSkin?.activeValue()?.documentSkin.id
          }
          onChange={(event) => {
            editor.commands.changeDocumentSkin?.execute(
              {
                skinId: event.target.value
              },
              false
            );
          }}
        >
          {documentSkins.map((skin) => {
            return (
              <option value={skin.id} key={skin.id}>
                {skin.name}
              </option>
            );
          })}
        </select>

        <select
          value={editor.commands.updateQuestionTitleAutoCreationFlag
            ?.activeValue()
            ?.isActive.toString()}
          onChange={(event) =>
            editor.commands.updateQuestionTitleAutoCreationFlag?.execute({
              isActive: event.target.value === "true"
            })
          }
        >
          <option value="true">Automatic question title</option>
          <option value="false">Dont create question title</option>
        </select>
      </div>
      <div className="Toolbar-row">
        <button
          disabled={!editor.commands.startInputSelector?.isEnabled()}
          onClick={() =>
            editor.commands.startInputSelector.execute({
              allowedTypes: [
                "text",
                "number",
                "choice",
                "datetime",
                "file",
                "likert_item",
                "ranking",
                "add_one_to_n"
              ]
            })
          }
        >
          Start Input Selector
        </button>
        <button
          disabled={!editor.commands.endInputSelector?.isEnabled()}
          onClick={() => editor.commands.endInputSelector.execute()}
        >
          End Input Selector
        </button>

        <button
          disabled={!editor.commands.startBlockSelector?.isEnabled()}
          onClick={() =>
            editor.commands.startBlockSelector.execute({
              rangeNamingStrategy: "numeric",
              includeChoices: true,
              includeHeaderFooterRow: true
            })
          }
        >
          Start Block Selector
        </button>
        <button
          disabled={!editor.commands.endBlockSelector?.isEnabled()}
          onClick={() => editor.commands.endBlockSelector.execute()}
        >
          End Block Selector
        </button>

        <button
          disabled={!editor.commands.startInlineSelector?.isEnabled()}
          onClick={() => editor.commands.startInlineSelector.execute()}
        >
          Start Inline Selector
        </button>
        <button
          disabled={!editor.commands.endInlineSelector?.isEnabled()}
          onClick={() => editor.commands.endInlineSelector.execute()}
        >
          End Inline Selector
        </button>

        <button
          disabled={!editor.commands.startForkSelector?.isEnabled()}
          onClick={() => editor.commands.startForkSelector.execute()}
        >
          Start Fork Selector
        </button>
        <button
          disabled={!editor.commands.endForkSelector?.isEnabled()}
          onClick={() => editor.commands.endForkSelector.execute()}
        >
          End Fork Selector
        </button>
      </div>
    </div>
  );
};

export default Toolbar;
