import {
  BindNodesWithoutTitlesProps,
  Editor as BlueXEditor,
  Schema
} from "@blue-x/editor";
import React, { useCallback, useState } from "react";
import ToolbarDropdown, {
  ToolbarDropdownToggle,
  useToolbarDropdown
} from "../ToolbarDropdown";
import ToolbarIcon from "../ToolbarIcon";
import ToolbarTooltip from "../ToolbarTooltip";

interface ManualBindingButtonProps {
  editor: BlueXEditor<Schema>;
}

const ManualBindingDropdown: React.FC<ManualBindingButtonProps> = (props) => {
  const { editor } = props;
  const { commands } = editor;
  const dropdown = useToolbarDropdown();

  const [useQuestionTitle, setUseQuestionTitle] = useState(false);
  const [startsWithText, setStartsWithText] = useState("");
  const [isStartsWithEnabled, setStartsWithFlag] = useState(false);
  const [endsWithText, setEndsWithText] = useState("");
  const [isEndsWithEnabled, setEndsWithFlag] = useState(false);
  const [searchIndex, setsearchIndex] = useState(1);

  const updateText = (func: React.Dispatch<React.SetStateAction<string>>) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      func(event.target.value);
    };
  };

  const toggleRadioBtn = (
    currentVal: boolean,
    func: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    return () => {
      return func(!currentVal);
    };
  };

  const searchIndexChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const valueAsNumber = +e.target.value;
    setsearchIndex(valueAsNumber);
  };

  const bindNodesWithoutTitles = useCallback(() => {
    commands.bindNodesWithoutTitles.execute({
      endsWith: isEndsWithEnabled,
      endsWithText: [endsWithText],
      searchIndex: searchIndex,
      starstWith: isStartsWithEnabled,
      startsWithText: [startsWithText],
      useQuestionStyle: useQuestionTitle
    } as BindNodesWithoutTitlesProps);
    const { opened, setOpened } = dropdown;
    setOpened(!opened);
  }, [
    commands,
    isEndsWithEnabled,
    endsWithText,
    isStartsWithEnabled,
    startsWithText,
    useQuestionTitle,
    searchIndex,
    dropdown
  ]);

  return (
    <div className="ToolbarGridSizePicker">
      <h4>Find a title for question that is not currently bound</h4>
      <p>
        Bind to the{" "}
        <select onChange={searchIndexChanged}>
          <option value="1">1st</option>
          <option value="2">2nd</option>
          <option value="3">3rd</option>
          <option value="4">4th</option>
        </select>
        paragraph before the question.
      </p>
      <input
        type="checkbox"
        id="manualBinding"
        checked={useQuestionTitle}
        onChange={toggleRadioBtn(useQuestionTitle, setUseQuestionTitle)}
      />{" "}
      <label htmlFor="manualBinding">Uses the Question Title style</label>
      <br />
      <input
        type="checkbox"
        checked={isStartsWithEnabled}
        onChange={toggleRadioBtn(isStartsWithEnabled, setStartsWithFlag)}
      />{" "}
      <label>Starts with</label>{" "}
      <input
        type="text"
        value={startsWithText}
        onChange={updateText(setStartsWithText)}
      />{" "}
      <br />
      <input
        type="checkbox"
        checked={isEndsWithEnabled}
        onChange={toggleRadioBtn(isEndsWithEnabled, setEndsWithFlag)}
      />{" "}
      <label>Ends with</label>{" "}
      <input
        type="text"
        value={endsWithText}
        onChange={updateText(setEndsWithText)}
      />{" "}
      <br />
      <button onClick={bindNodesWithoutTitles}>Apply</button>
    </div>
  );
};

export const ManualBindingButton: React.FC<ManualBindingButtonProps> = (
  props
) => {
  const { editor } = props;
  const { commands } = editor;

  return (
    <ToolbarDropdown
      toggle={
        <ToolbarTooltip
          title="Manual binding"
          subtitle="Bind question titles manually."
        >
          <ToolbarDropdownToggle
            disabled={!commands.bindNodesWithoutTitles?.isEnabled()}
          >
            <ToolbarIcon icon="" />
          </ToolbarDropdownToggle>
        </ToolbarTooltip>
      }
      content={<ManualBindingDropdown editor={editor} />}
    />
  );
};
