import { Node, Schema } from "prosemirror-model";
import { Plugin } from "prosemirror-state";
import { Decoration, EditorView, NodeView } from "prosemirror-view";
import { Editor } from "../../editor";
import {
  ActionControlButton,
  ActionControls
} from "../../editor/plugins/action-controls";
import { selectionFocusKey } from "../../editor/plugins/selection-focus";
import { ValidationMessagesNodeView } from "../../editor/plugins/validation-messages";
import { WidthResizerNodeView } from "../../editor/plugins/width-resizer";
import { AlignmentType } from "../alignment";
import { GetTranslationFn, LanguageObserver } from "../localization";
import { QuestionTitleBindingButton } from "../question-title";
import {
  inputDateAvailableFormats,
  InputDateTimeControlType,
  inputTimeAvailableFormats
} from "./schema";
import { formatDateTime } from "./util";

export function nodeViewPlugin() {
  return new Plugin({
    props: {
      nodeViews: {
        inputDateTime: (node, view, getPos, decorations) => {
          return new InputDateTimeNodeView(
            node,
            view,
            getPos as () => number,
            decorations
          );
        }
      }
    }
  });
}

class InputDateTimeNodeView<S extends Schema> implements NodeView<S> {
  dom: HTMLElement;

  private languageObserver: LanguageObserver<S>;

  private inputText: HTMLElement;
  private dateTimeButton: HTMLElement;

  private resizer: WidthResizerNodeView<S>;
  private validationMessages: ValidationMessagesNodeView;
  private actionControls: ActionControls;
  private requiredButton: ActionControlButton;
  private questionTitleBindingButton: QuestionTitleBindingButton<S>;

  constructor(
    private node: Node<S>,
    view: EditorView<S>,
    getPos: () => number,
    decorations: Decoration[]
  ) {
    this.languageObserver = new LanguageObserver(view, (getTranslation) => {
      this.updateRequired(this.node.attrs.required, getTranslation);
      this.updateText(
        this.node.attrs.isDefaultNow,
        this.node.attrs.defaultDate,
        this.node.attrs.defaultTime,
        this.node.attrs.watermark,
        this.node.attrs.controlType,
        this.node.attrs.dateFormat,
        this.node.attrs.timeFormat,
        getTranslation
      );
    });

    const container = document.createElement("input-datetime");

    this.resizer = new WidthResizerNodeView(
      container,
      node,
      view,
      getPos,
      25,
      100,
      (width) => {
        const editor = view as Editor<S>;
        editor.commands.updateInputDateTime.execute({
          width: width
        });
      }
    );

    this.validationMessages = new ValidationMessagesNodeView();

    const inputContainer = document.createElement("div");
    inputContainer.className = "input-container";

    const inputText = document.createElement("span");
    inputText.className = "date-time-text";

    const dateTimeButton = this.createDateTimeButton();

    inputContainer.appendChild(inputText);
    inputContainer.appendChild(dateTimeButton);

    const requiredButton = new ActionControlButton(
      view,
      { icon: "asterisk", title: "ACTION_BUTTONS.REQUIRED.TITLE" },
      false,
      () => {
        const editor = view as Editor<S>;
        const focused = selectionFocusKey.getState(editor.state);

        if (focused != null && focused.node.type === this.node.type) {
          const { node } = focused;
          const required = !node.attrs.required;
          editor.commands.updateInputDateTime.execute({ required: required });
        }
      }
    );

    const questionTitleBindingButton = new QuestionTitleBindingButton(
      view,
      node,
      decorations
    );

    const actionControls = new ActionControls([
      requiredButton,
      questionTitleBindingButton
    ]);

    container.appendChild(this.validationMessages.dom);
    container.appendChild(inputContainer);
    container.appendChild(actionControls.dom);

    this.dom = container;
    this.inputText = inputText;
    this.dateTimeButton = dateTimeButton;
    this.actionControls = actionControls;
    this.requiredButton = requiredButton;
    this.questionTitleBindingButton = questionTitleBindingButton;

    const getTranslation = this.languageObserver.getTranslation;

    this.updateId(node);
    this.updateText(
      node.attrs.isDefaultNow,
      node.attrs.defaultDate,
      node.attrs.defaultTime,
      node.attrs.watermark,
      node.attrs.controlType,
      node.attrs.dateFormat,
      node.attrs.timeFormat,
      getTranslation
    );
    this.updateDateTimeButton(node.attrs.controlType);
    this.updateRequired(node.attrs.required, getTranslation);
    this.updateAlignment(node.attrs.alignment);
    this.updateQuestionTitle(node, decorations);
  }

  ignoreMutation(
    mutation:
      | MutationRecord
      | {
          type: "selection";
          target: Element;
        }
  ): boolean {
    const resizeIgnoreMutation = this.resizer.ignoreMutation(mutation);
    const validationMessagesIgnoreMutation = this.validationMessages.ignoreMutation(
      mutation
    );
    const requiredButtonIgnoreMutation = this.requiredButton.ignoreMutation(
      mutation
    );
    const questionTitleBindingButtonIgnoreMutation = this.questionTitleBindingButton.ignoreMutation(
      mutation
    );

    return [
      resizeIgnoreMutation,
      validationMessagesIgnoreMutation,
      requiredButtonIgnoreMutation,
      questionTitleBindingButtonIgnoreMutation
    ].some((x) => x);
  }

  update(node: Node<S>, decorations: Decoration[]): boolean {
    if (node.type !== this.node.type) {
      return false;
    }

    this.node = node;

    const getTranslation = this.languageObserver.getTranslation;

    this.resizer.update(node);

    this.updateId(node);
    this.updateText(
      node.attrs.isDefaultNow,
      node.attrs.defaultDate,
      node.attrs.defaultTime,
      node.attrs.watermark,
      node.attrs.controlType,
      node.attrs.dateFormat,
      node.attrs.timeFormat,
      getTranslation
    );
    this.updateDateTimeButton(node.attrs.controlType);
    this.updateRequired(node.attrs.required, getTranslation);
    this.updateAlignment(node.attrs.alignment);
    this.updateQuestionTitle(node, decorations);

    return true;
  }

  destroy() {
    this.languageObserver.destroy();
    this.actionControls.destroy();
  }

  private updateId(node: Node<S>): void {
    this.dom.id = node.attrs.id;
  }

  private updateText(
    isDefaultNow: boolean,
    defaultDate: string,
    defaultTime: string,
    placeholder: string,
    controlType: InputDateTimeControlType,
    dateFormat: string,
    timeFormat: string,
    getTranslation: GetTranslationFn
  ): void {
    if (isDefaultNow) {
      this.inputText.innerHTML = this.fetchCustomPlaceholder(
        controlType,
        dateFormat,
        timeFormat,
        getTranslation
      );
      this.inputText.classList.remove("default-value");
      this.inputText.classList.add("watermark");
    } else {
      if (
        controlType === InputDateTimeControlType.date &&
        dateFormat != null &&
        defaultDate != null &&
        defaultDate !== ""
      ) {
        const dateStr = formatDateTime(controlType, defaultDate, dateFormat);
        this.inputText.innerHTML = dateStr == null ? "" : dateStr;
        this.inputText.classList.remove("watermark");
        this.inputText.classList.add("default-value");
      } else if (
        controlType === InputDateTimeControlType.time &&
        timeFormat != null &&
        defaultTime != null &&
        defaultTime !== ""
      ) {
        const timeStr = formatDateTime(controlType, defaultTime, timeFormat);
        this.inputText.innerHTML = timeStr == null ? "" : timeStr;
        this.inputText.classList.remove("watermark");
        this.inputText.classList.add("default-value");
      } else if (placeholder != null && placeholder !== "") {
        this.inputText.innerHTML = placeholder;
      } else {
        this.inputText.innerHTML = this.fetchCustomPlaceholder(
          controlType,
          dateFormat,
          timeFormat,
          getTranslation
        );
        this.inputText.classList.remove("default-value");
        this.inputText.classList.add("watermark");
      }
    }
  }

  private fetchCustomPlaceholder(
    controlType: InputDateTimeControlType,
    dateFormat: string,
    timeFormat: string,
    getTranslation: GetTranslationFn
  ): string {
    const format =
      controlType === InputDateTimeControlType.date
        ? inputDateAvailableFormats.find((format) => format.id === dateFormat)
        : inputTimeAvailableFormats.find((format) => format.id === timeFormat);
    if (format && format.text) {
      return typeof format.text === "string"
        ? format.text
        : getTranslation(format.text.key);
    } else {
      return "";
    }
  }

  private createDateTimeButton(): HTMLSpanElement {
    const dateTimeBtnEl = document.createElement("span");
    dateTimeBtnEl.classList.add("date-time-button");
    const dateSpanEl = document.createElement("span");
    dateSpanEl.classList.add("bx", "bx-date");
    const timeSpanEl = document.createElement("span");
    timeSpanEl.classList.add("bx", "bx-time");
    dateTimeBtnEl.appendChild(dateSpanEl);
    dateTimeBtnEl.appendChild(timeSpanEl);
    return dateTimeBtnEl;
  }

  private updateDateTimeButton(controlType: InputDateTimeControlType): void {
    if (controlType != null && controlType === InputDateTimeControlType.date) {
      this.dateTimeButton.classList.remove("time");
      this.dateTimeButton.classList.add("date");
    } else if (
      controlType != null &&
      controlType === InputDateTimeControlType.time
    ) {
      this.dateTimeButton.classList.remove("date");
      this.dateTimeButton.classList.add("time");
    }
  }

  private updateRequired(
    required: boolean,
    getTranslation: GetTranslationFn
  ): void {
    this.validationMessages.setRequired(required, false, getTranslation);
    this.requiredButton.setActive(required);
  }

  private updateAlignment(alignment: AlignmentType): void {
    if (alignment != null) {
      this.dom.setAttribute("data-alignment", alignment);
    } else {
      this.dom.removeAttribute("data-alignment");
    }
  }

  private updateQuestionTitle(node: Node<S>, decorations: Decoration[]): void {
    this.questionTitleBindingButton.update(node, decorations);
  }
}
