import { Node, Schema } from "prosemirror-model";
import { EditorState, Plugin, PluginKey } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";
import { editableKey } from "../../../editor/plugins/editable";
import {
  findInputs,
  findNode,
  focusedQuestionTitle,
  initDecorations,
  NodeWithPos,
  removeDecorations,
  updateDecorations
} from "../../../util";
import {
  findQuestionTitles,
  focusedElementWithQuestionTitle,
  nodeHasQuestionTitleBinding,
  supportsQuestionTitleBinding
} from "../utils";

function decorations<S extends Schema>(
  doc: Node<S>,
  from: number,
  to: number,
  decorationSet: DecorationSet
): DecorationSet {
  doc.nodesBetween(from, to, (node, pos) => {
    if (node.type.spec.supportsQuestionTitle === true) {
      const nodeFrom = pos;
      const nodeTo = nodeFrom + node.nodeSize;

      decorationSet = removeDecorations(decorationSet, nodeFrom, nodeTo);

      const inputs = findInputs(doc, node);

      if (node.content.size === 0 && inputs.length > 0) {
        decorationSet = decorationSet.add(doc, [
          questionTitleEmptyDecoration(nodeFrom, nodeTo)
        ]);
      }
    }
    return;
  });

  return decorationSet;
}

function questionTitleEmptyDecoration(from: number, to: number): Decoration {
  return Decoration.node(from, to, {
    class: "ProseMirror-question-title-empty"
  });
}

export const questionTitleOutlineKey = new PluginKey<DecorationSet, Schema>(
  "questionTitleOutline"
);

function isInput<S extends Schema>(
  node: Node<S>,
  _pos: number,
  _parent: Node<S>
): boolean {
  if (supportsQuestionTitleBinding(node) && node.attrs.id != null) {
    return true;
  } else {
    return false;
  }
}

export function questionTitleOutline() {
  return new Plugin<DecorationSet, Schema>({
    key: questionTitleOutlineKey,
    state: {
      init(_config, state) {
        return initDecorations(state, (doc, start, end, decorationSet) => {
          return decorations(doc, start, end, decorationSet);
        });
      },
      apply(tr, value, _oldState, newState) {
        let updatedValue = updateDecorations(
          tr,
          value,
          (doc, start, end, decorationSet) => {
            return decorations(doc, start, end, decorationSet);
          }
        );

        let questionTitleMap = new Map<string, number>();

        for (let i = tr.mapping.from; i < tr.mapping.to; ++i) {
          const map = tr.mapping.maps[i];
          const oldDoc = tr.docs[i];
          const newDoc = tr.docs[i + 1] ?? newState.doc;

          let oldInputs = new Array<NodeWithPos<Schema>>();
          let newInputs = new Array<NodeWithPos<Schema>>();

          const callback = (map: Map<string, number>) => (
            fromA: number,
            toA: number,
            fromB: number,
            toB: number
          ) => {
            oldDoc.nodesBetween(fromA, toA, (node, pos, parent) => {
              if (isInput(node, pos, parent)) {
                oldInputs.push({ node: node, pos: pos });
              }
            });

            newDoc.nodesBetween(fromB, toB, (node, pos, parent) => {
              if (isInput(node, pos, parent)) {
                newInputs.push({ node: node, pos: pos });
              }
            });

            if (!(oldInputs.length === 0 && newInputs.length === 0)) {
              const removed = oldInputs.reduce((acc, oldInput) => {
                const found = newInputs.find((newInput) => {
                  return newInput.node.attrs.id === oldInput.node.attrs.id;
                });

                return found == null
                  ? acc.concat(...oldInput.node.attrs.questionTitleRefElementId)
                  : acc;
              }, new Array<string>());

              const added = newInputs.reduce((acc, newInput) => {
                const found = oldInputs.find((oldInput) => {
                  return oldInput.node.attrs.id === newInput.node.attrs.id;
                });

                return found == null
                  ? acc.concat(...newInput.node.attrs.questionTitleRefElementId)
                  : acc;
              }, new Array<string>());

              const updated = newInputs.reduce((acc, newInput) => {
                const found = oldInputs.find((oldInput) => {
                  return newInput.node.attrs.id === oldInput.node.attrs.id;
                });

                return found != null
                  ? acc.concat({
                      removed: found.node.attrs.questionTitleRefElementId,
                      added: newInput.node.attrs.questionTitleRefElementId
                    })
                  : acc;
              }, new Array<{ removed: string[]; added: string[] }>());

              [...removed, ...updated.flatMap((u) => u.removed)].forEach(
                (r) => {
                  const v = map.get(r);
                  if (v != null) {
                    map = map.set(r, v - 1);
                  } else {
                    map = map.set(r, -1);
                  }
                }
              );

              [...added, ...updated.flatMap((u) => u.added)].forEach((a) => {
                const v = map.get(a);
                if (v != null) {
                  map = map.set(a, v + 1);
                } else {
                  map = map.set(a, 1);
                }
              });
            }
          };

          map.forEach(callback(questionTitleMap));
        }

        if (questionTitleMap.size > 0) {
          questionTitleMap.forEach((count, id) => {
            if (count < 0) {
              const node = findNode(newState.doc, (n) => n.attrs.id === id);
              if (node != null) {
                const found = updatedValue.find(
                  node.pos,
                  node.pos + node.node.nodeSize
                );
                updatedValue = updatedValue.remove(found);
              }
            } else if (count > 0) {
              const node = findNode(newState.doc, (n) => n.attrs.id === id);
              if (node != null) {
                const found = updatedValue.find(
                  node.pos,
                  node.pos + node.node.nodeSize
                );
                updatedValue = updatedValue.remove(found);
                if (node.node.content.size === 0) {
                  updatedValue = updatedValue.add(newState.doc, [
                    questionTitleEmptyDecoration(
                      node.pos,
                      node.pos + node.node.nodeSize
                    )
                  ]);
                }
              }
            }
          });
        }

        return updatedValue;
      }
    },
    props: {
      decorations(state) {
        const decorationSet = this.getState(state);

        const editable = editableKey.getState(state);
        if (editable && editable.focusable === false) {
          return decorationSet;
        }

        const decorations = [
          ...decorationsForInputs(state),
          ...decorationsForBindingQuestionTitles(state)
        ];

        return decorationSet.add(state.doc, decorations);
      }
    }
  });
}

function decorationsForInputs(state: EditorState<Schema>): Decoration[] {
  const focused = focusedElementWithQuestionTitle(state);
  if (focused == null) {
    return [];
  }

  const { node } = focused;
  if (!nodeHasQuestionTitleBinding(node)) {
    return [];
  }

  const boundQuestionTitles = findQuestionTitles(state.doc, node);

  const decorations = boundQuestionTitles.map((questionTitle) => {
    const from = questionTitle.pos;
    const to = from + questionTitle.node.nodeSize;

    return Decoration.node(from, to, {
      class: "ProseMirror-question-title-linked"
    });
  });

  return decorations;
}

function decorationsForBindingQuestionTitles(
  state: EditorState<Schema>
): Decoration[] {
  const focused = focusedQuestionTitle(state);
  if (focused == null) {
    return [];
  }

  const { node, pos } = focused;
  const boundInputs = findInputs(state.doc, node);

  const decorations = boundInputs.map((input) => {
    const from = input.pos;
    const to = from + input.node.nodeSize;

    return Decoration.node(from, to, {
      class: "ProseMirror-question-title-linked"
    });
  });

  const outline = Decoration.node(pos, pos + node.nodeSize, {
    class: "ProseMirror-focusednode"
  });

  return decorations.concat(outline);
}
