import { Schema } from "prosemirror-model";
import { Plugin } from "prosemirror-state";
import {
  CommandConfiguration,
  CommandConfigurations,
  Extension
} from "../../editor/extension";
import { TranslateDefaultParser, TranslateParser } from "./parser";
import { languagePicker, languagePickerKey } from "./plugin/language-picker";
import { Translations } from "./types";

export interface Language {
  code: string;
  translations: Translations;
  default?: boolean;
}

export interface ChangeLanguageCommandProps {
  language: string;
}

export interface ChangeLanguageActiveValue {
  language: string;
}

export class Localization implements Extension<Schema> {
  private defaultLanguage: string;
  private translations: Map<string, Translations>;

  constructor(
    private languages: Language[],
    private parser: TranslateParser = new TranslateDefaultParser()
  ) {
    const defaultLanguage = this.languages.find(
      (language) => language.default === true
    );

    if (defaultLanguage == null) {
      throw new Error(
        `Languages provided to the ${Localization.name} extension must have a default language. Set the default property to true for one of the provided languages.`
      );
    }

    this.defaultLanguage = defaultLanguage.code;
    this.translations = this.languages.reduce((acc, elem) => {
      return acc.set(elem.code, elem.translations);
    }, new Map<string, Translations>());
  }

  get name(): string {
    return "localization";
  }

  plugins(): Plugin[] {
    return [
      languagePicker(this.defaultLanguage, this.translations, this.parser)
    ];
  }

  commands(schema: Schema): CommandConfigurations<Schema> {
    return {
      changeLanguage: this.changeLanguageCommand(schema)
    };
  }

  private changeLanguageCommand(
    _schema: Schema
  ): CommandConfiguration<
    Schema,
    ChangeLanguageCommandProps,
    ChangeLanguageActiveValue | undefined
  > {
    return {
      isActive: () => {
        return false;
      },
      isEnabled: () => {
        return true;
      },
      execute: (props) => {
        if (props == null) {
          throw new Error(
            `To change language, ChangeLanguageCommandProps needs to be provided.`
          );
        }

        const { language } = props;

        return (state, dispatch) => {
          if (dispatch) {
            let tr = state.tr;
            tr = tr.setMeta(languagePickerKey, { language: language });
            dispatch(tr);
          }

          return true;
        };
      },
      activeValue: () => {
        return (state) => {
          const localization = languagePickerKey.getState(state);
          if (localization == null) {
            return undefined;
          }

          const { language } = localization;

          return {
            language: language
          };
        };
      }
    };
  }
}
