import { Node, Schema } from "prosemirror-model";
import { Plugin } from "prosemirror-state";
import { Decoration, EditorView, NodeView } from "prosemirror-view";
import { Editor } from "../../editor";
import {
  ActionControlButton,
  ActionControls
} from "../../editor/plugins/action-controls";
import { selectionFocusKey } from "../../editor/plugins/selection-focus";
import { ValidationMessagesNodeView } from "../../editor/plugins/validation-messages";
import { WidthResizerNodeView } from "../../editor/plugins/width-resizer";
import { AlignmentType } from "../alignment";
import { GetTranslationFn, LanguageObserver } from "../localization";
import { QuestionTitleBindingButton } from "../question-title";
import { InputTextControlType } from "./schema";

export function nodeViewPlugin() {
  return new Plugin({
    props: {
      nodeViews: {
        inputText: (node, view, getPos, decorations) => {
          return new InputTextNodeView(
            node,
            view,
            getPos as () => number,
            decorations
          );
        }
      }
    }
  });
}

class InputTextNodeView<S extends Schema> implements NodeView<S> {
  dom: HTMLElement;

  private languageObserver: LanguageObserver<S>;

  private inputContainer: HTMLElement;
  private resizer: WidthResizerNodeView<S>;
  private validationMessages: ValidationMessagesNodeView;
  private actionControls: ActionControls;
  private requiredButton: ActionControlButton;
  private questionTitleBindingButton: QuestionTitleBindingButton<S>;

  constructor(
    private node: Node<S>,
    view: EditorView<S>,
    getPos: () => number,
    decorations: Decoration[]
  ) {
    this.languageObserver = new LanguageObserver(view, (getTranslation) => {
      this.updateRequired(
        this.node.attrs.required,
        this.node.attrs.defaultValue,
        getTranslation
      );
    });

    const container = document.createElement("input-text");

    this.resizer = new WidthResizerNodeView(
      container,
      node,
      view,
      getPos,
      25,
      100,
      (width) => {
        const editor = view as Editor<S>;
        editor.commands.updateInputText.execute({
          width: width
        });
      }
    );

    this.validationMessages = new ValidationMessagesNodeView();

    const inputContainer = document.createElement("div");
    inputContainer.className = "input-container";

    const requiredButton = new ActionControlButton(
      view,
      { icon: "asterisk", title: "ACTION_BUTTONS.REQUIRED.TITLE" },
      false,
      () => {
        const editor = view as Editor<S>;
        const focused = selectionFocusKey.getState(editor.state);

        if (focused != null && focused.node.type === this.node.type) {
          const { node } = focused;
          const required = !node.attrs.required;
          editor.commands.updateInputText.execute({ required: required });
        }
      }
    );

    const questionTitleBindingButton = new QuestionTitleBindingButton(
      view,
      node,
      decorations
    );

    const actionControls = new ActionControls([
      requiredButton,
      questionTitleBindingButton
    ]);

    container.appendChild(this.validationMessages.dom);
    container.appendChild(inputContainer);
    container.appendChild(actionControls.dom);

    this.dom = container;
    this.inputContainer = inputContainer;
    this.actionControls = actionControls;
    this.requiredButton = requiredButton;
    this.questionTitleBindingButton = questionTitleBindingButton;

    const getTranslation = this.languageObserver.getTranslation;

    this.updateId(node);
    this.updateRows(node.attrs.rows);
    this.updateText(node.attrs.watermark, node.attrs.defaultValue);
    this.updateRequired(
      node.attrs.required,
      node.attrs.defaultValue,
      getTranslation
    );
    this.updateAlignment(node.attrs.alignment);
    this.updateQuestionTitle(node, decorations);
    this.updateDataControlTypeAttribute(node.attrs.controlType);
  }

  update(node: Node<S>, decorations: Decoration[]): boolean {
    if (node.type !== this.node.type) {
      return false;
    }

    this.node = node;

    const getTranslation = this.languageObserver.getTranslation;

    this.resizer.update(node);

    this.updateId(node);
    this.updateRows(node.attrs.rows);
    this.updateText(node.attrs.watermark, node.attrs.defaultValue);
    this.updateRequired(
      node.attrs.required,
      node.attrs.defaultValue,
      getTranslation
    );
    this.updateAlignment(node.attrs.alignment);
    this.updateQuestionTitle(node, decorations);
    this.updateDataControlTypeAttribute(node.attrs.controlType);

    return true;
  }

  ignoreMutation(
    mutation:
      | MutationRecord
      | {
          type: "selection";
          target: Element;
        }
  ): boolean {
    const resizeIgnoreMutation = this.resizer.ignoreMutation(mutation);
    const validationMessagesIgnoreMutation = this.validationMessages.ignoreMutation(
      mutation
    );
    const requiredButtonIgnoreMutation = this.requiredButton.ignoreMutation(
      mutation
    );
    const questionTitleBindingButtonIgnoreMutation = this.questionTitleBindingButton.ignoreMutation(
      mutation
    );

    return [
      resizeIgnoreMutation,
      validationMessagesIgnoreMutation,
      requiredButtonIgnoreMutation,
      questionTitleBindingButtonIgnoreMutation
    ].some((x) => x);
  }

  destroy() {
    this.languageObserver.destroy();
    this.actionControls.destroy();
  }

  private updateId(node: Node<S>): void {
    this.dom.id = node.attrs.id;
  }

  private updateRows(rows: number): void {
    const height = 10 + rows * 20;
    this.inputContainer.style.height = `${height}px`;
  }

  private updateText(watermark: string, defaultValue: string): void {
    if (defaultValue != null && defaultValue !== "") {
      this.inputContainer.classList.remove("watermark");
      this.inputContainer.classList.add("default-value");
      this.inputContainer.textContent = defaultValue;
    } else {
      this.inputContainer.classList.remove("default-value");
      this.inputContainer.classList.add("watermark");
      this.inputContainer.textContent = watermark;
    }
  }

  private updateRequired(
    required: boolean,
    defaultValue: string,
    getTranslation: GetTranslationFn
  ): void {
    const done = defaultValue != null && defaultValue !== "";
    this.validationMessages.setRequired(required, done, getTranslation);
    this.requiredButton.setActive(required);
  }

  private updateAlignment(alignment: AlignmentType): void {
    if (alignment != null) {
      this.dom.setAttribute("data-alignment", alignment);
    } else {
      this.dom.removeAttribute("data-alignment");
    }
  }

  private updateDataControlTypeAttribute(
    controlType: InputTextControlType
  ): void {
    this.dom.setAttribute("data-control-type", controlType);
  }

  private updateQuestionTitle(node: Node<S>, decorations: Decoration[]): void {
    this.questionTitleBindingButton.update(node, decorations);
  }
}
