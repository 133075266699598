import { Schema } from "prosemirror-model";
import { EditorState } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { languagePickerKey } from "./plugin/language-picker";

interface DOMObserver {
  start(): void;
  stop(): void;
}

interface DOMObserverView extends EditorView {
  domObserver: DOMObserver;
}

export type GetTranslationFn = (
  key: string,
  interpolateParams?: { [key: string]: string }
) => string;

export function getTranslation<S extends Schema>(
  state: EditorState<S>
): GetTranslationFn {
  return (key, interpolateParams) => {
    const localization = languagePickerKey.getState(state);
    if (localization == null) {
      console.error(`No localization found.`);
      return key;
    }

    const { language, translations, parser } = localization;
    if (translations == null) {
      console.error(`No translations for language with code ${language}.`);
      return key;
    }

    const res = parser.interpolate(
      parser.getValue(translations, key),
      interpolateParams
    );

    return res == null ? key : res;
  };
}

export function getLanguage<S extends Schema>(
  state: EditorState<S>
): string | undefined {
  const localization = languagePickerKey.getState(state);
  if (localization == null) {
    return undefined;
  } else {
    const { language } = localization;
    return language;
  }
}

export class LanguageObserver<S extends Schema> {
  private observer: MutationObserver;

  private _getTranslation: GetTranslationFn;
  get getTranslation(): GetTranslationFn {
    return this._getTranslation;
  }

  constructor(
    view: EditorView<S>,
    onChange: (getTranslation: GetTranslationFn) => void
  ) {
    this.observer = new MutationObserver((mutations) => {
      for (let mutation of mutations) {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "lang"
        ) {
          this._getTranslation = getTranslation(view.state);
          (view as DOMObserverView).domObserver.stop();
          onChange(this._getTranslation);
          (view as DOMObserverView).domObserver.start();
        }
      }
    });

    this._getTranslation = getTranslation(view.state);

    this.observer.observe(view.dom, {
      attributes: true,
      attributeFilter: ["lang"]
    });
  }

  destroy() {
    this.observer.disconnect();
  }
}
