import { Node, ResolvedPos, Schema } from "prosemirror-model";

export function isIgnored<S extends Schema>(node?: Node<S> | null): boolean {
  if (node == null) {
    return true;
  } else {
    const allowGapCursor = node.type.spec.allowGapCursor === true;
    return !allowGapCursor;
  }
}

export function isValidTargetNode<S extends Schema>(
  node?: Node<S> | null
): boolean {
  return node != null && !isIgnored(node);
}

export const isIgnoredClick = (elem: HTMLElement) => {
  if (elem.nodeName === "BUTTON" || elem.closest("button")) {
    return true;
  }

  // // check if target node has a parent table node
  // let tableWrap;
  // let node = elem;
  // while (node) {
  //   if (
  //     node.className &&
  //     (node.getAttribute("class") || "").indexOf(
  //       TableCssClassName.TABLE_CONTAINER
  //     ) > -1
  //   ) {
  //     tableWrap = node;
  //     break;
  //   }
  //   node = node.parentNode as HTMLElement;
  // }

  // if (tableWrap) {
  //   const rowControls = tableWrap.querySelector(
  //     `.${TableCssClassName.ROW_CONTROLS_WRAPPER}`
  //   );
  //   const isColumnControlsDecoration =
  //     elem &&
  //     elem.classList &&
  //     elem.classList.contains(TableCssClassName.COLUMN_CONTROLS_DECORATIONS);
  //   return (
  //     (rowControls && rowControls.contains(elem)) || isColumnControlsDecoration
  //   );
  // }

  return false;
};

export function isTextBlockNearPos<S extends Schema>(
  doc: Node<S>,
  schema: S,
  $pos: ResolvedPos<S>,
  dir: number
) {
  let $currentPos = $pos;
  let currentNode: Node<S> | null = null;

  while ($currentPos.depth > 0) {
    $currentPos = doc.resolve(
      dir === -1 ? $currentPos.before() : $currentPos.after()
    );

    if (!$currentPos) {
      return false;
    }

    currentNode =
      (dir === -1 ? $currentPos.nodeBefore : $currentPos.nodeAfter) ||
      $currentPos.parent;

    if (!currentNode || currentNode.type === schema.nodes.doc) {
      return false;
    }

    if (currentNode.isTextblock) {
      return true;
    }
  }

  let childNode: Node<S> | null = currentNode;

  while (childNode && childNode.firstChild) {
    childNode = childNode.firstChild;
    if (childNode && childNode.isTextblock) {
      return true;
    }
  }

  return false;
}
