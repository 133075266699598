import { Plugin } from "prosemirror-state";
import { BarChartNodeView } from "./bar-chart-node-view";

export function barChartViewPlugin() {
  return new Plugin({
    props: {
      nodeViews: {
        barChart: (node, view, getPos, _decorations) => {
          return new BarChartNodeView(node, view, getPos as () => number);
        }
      }
    }
  });
}
