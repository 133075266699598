import {
  chainCommands,
  deleteSelection,
  exitCode,
  joinBackward,
  joinForward,
  Keymap,
  liftEmptyBlock,
  newlineInCode,
  selectAll,
  selectNodeBackward,
  selectNodeForward
} from "prosemirror-commands";
import { undoInputRule } from "prosemirror-inputrules";
import { Schema } from "prosemirror-model";
import {
  createParagraphNearWithGapCursor,
  joinBackwardForNodeSelection,
  joinForwardForNodeSelection,
  replaceBlockWithParagraph,
  splitBlockPreservingMarksAndAttrs
} from "./commands";

let backspace = chainCommands(
  replaceBlockWithParagraph,
  undoInputRule,
  deleteSelection,
  joinBackwardForNodeSelection,
  joinBackward,
  selectNodeBackward
);
let del = chainCommands(
  replaceBlockWithParagraph,
  deleteSelection,
  joinForwardForNodeSelection,
  joinForward,
  selectNodeForward
);

// :: Object
// A basic keymap containing bindings not specific to any schema.
// Binds the following keys (when multiple commands are listed, they
// are chained with [`chainCommands`](#commands.chainCommands)):
//
// * **Enter** to `newlineInCode`, `createParagraphNear`, `liftEmptyBlock`, `splitBlock`
// * **Mod-Enter** to `exitCode`
// * **Backspace** and **Mod-Backspace** to `deleteSelection`, `joinBackward`, `selectNodeBackward`
// * **Delete** and **Mod-Delete** to `deleteSelection`, `joinForward`, `selectNodeForward`
// * **Mod-Delete** to `deleteSelection`, `joinForward`, `selectNodeForward`
// * **Mod-a** to `selectAll`
export let pcBaseKeymap: Keymap<Schema> = {
  Enter: chainCommands(
    newlineInCode,
    createParagraphNearWithGapCursor,
    liftEmptyBlock,
    splitBlockPreservingMarksAndAttrs
  ),
  "Mod-Enter": exitCode,
  Backspace: backspace,
  "Mod-Backspace": backspace,
  Delete: del,
  "Mod-Delete": del,
  "Mod-a": selectAll
};

// :: Object
// A copy of `pcBaseKeymap` that also binds **Ctrl-h** like Backspace,
// **Ctrl-d** like Delete, **Alt-Backspace** like Ctrl-Backspace, and
// **Ctrl-Alt-Backspace**, **Alt-Delete**, and **Alt-d** like
// Ctrl-Delete.
export let macBaseKeymap: Keymap<Schema> = {
  "Ctrl-h": pcBaseKeymap["Backspace"],
  "Alt-Backspace": pcBaseKeymap["Mod-Backspace"],
  "Ctrl-d": pcBaseKeymap["Delete"],
  "Ctrl-Alt-Backspace": pcBaseKeymap["Mod-Delete"],
  "Alt-Delete": pcBaseKeymap["Mod-Delete"],
  "Alt-d": pcBaseKeymap["Mod-Delete"]
};
for (let key in pcBaseKeymap) macBaseKeymap[key] = pcBaseKeymap[key];

// declare global: os, navigator
const mac =
  typeof navigator != "undefined" ? /Mac/.test(navigator.platform) : false;

// :: Object
// Depending on the detected platform, this will hold
// [`pcBasekeymap`](#commands.pcBaseKeymap) or
// [`macBaseKeymap`](#commands.macBaseKeymap).
export let baseKeymap = mac ? macBaseKeymap : pcBaseKeymap;
