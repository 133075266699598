import {
  Editor as BlueXEditor,
  InputChoiceActiveValue,
  InputChoiceControlType,
  InputChoiceLabelPosition,
  InputChoiceRows,
  InputChoiceSelectionRangeMax,
  InputChoiceSelectionRangeMin,
  InputChoiceWidth,
  Schema
} from "@blue-x/editor";
import React, { useCallback, useMemo } from "react";
import {
  CheckboxFormControl,
  NumberFormControl,
  QuestionTitleFormControl,
  SelectFormControl,
  TextFormControl
} from "../ProportiesPanelControls";
import { toText } from "../util";
import "./InputChoice.css";

interface ToolbarChoiceButtonProps {
  editor: BlueXEditor<Schema>;
}

export const InputChoicePropertiesPanel: React.FC<ToolbarChoiceButtonProps> = (
  props
) => {
  const { editor } = props;
  const { commands } = editor;

  const activeValue: InputChoiceActiveValue = useMemo(() => {
    return commands.updateInputChoice.activeValue();
  }, [commands]);

  const update = useCallback(
    (value) => {
      commands.updateInputChoice.execute(value, false);
    },
    [commands]
  );
  const controlType = activeValue.controlType;

  const updateResponseIds = useCallback(
    (id, responseId) => {
      const responseIds = activeValue.values.map((v) => {
        return { id: v.id, coding: v.id === id ? responseId : v.coding };
      });

      update({ responseIds: responseIds });
    },
    [activeValue, update]
  );

  return (
    <div className="InputChoicePropertiesPanel">
      <SelectFormControl
        label={"Type"}
        value={controlType}
        onUpdate={(value) => {
          update({ controlType: value });
        }}
      >
        <option value={InputChoiceControlType.singleVertical}>
          Single vertical
        </option>
        <option value={InputChoiceControlType.multipleVertical}>
          Multiple vertical
        </option>
        <option value={InputChoiceControlType.singleHorizontal}>
          Single horizontal
        </option>
        <option value={InputChoiceControlType.multipleHorizontal}>
          Multiple horizontal
        </option>
        <option value={InputChoiceControlType.dropdown}>Dropdown list</option>
        <option value={InputChoiceControlType.listbox}>List box</option>
      </SelectFormControl>

      <SelectFormControl
        label={"Label"}
        value={activeValue.labelPosition}
        onUpdate={(value) => {
          update({ labelPosition: value });
        }}
        disabled={
          controlType === InputChoiceControlType.dropdown ||
          controlType === InputChoiceControlType.listbox
        }
      >
        <option
          value={InputChoiceLabelPosition.top}
          disabled={
            controlType === InputChoiceControlType.singleVertical ||
            controlType === InputChoiceControlType.multipleVertical
          }
        >
          Top
        </option>
        <option
          value={InputChoiceLabelPosition.bottom}
          disabled={
            controlType === InputChoiceControlType.singleVertical ||
            controlType === InputChoiceControlType.multipleVertical
          }
        >
          Bottom
        </option>
        <option
          value={InputChoiceLabelPosition.right}
          disabled={
            controlType === InputChoiceControlType.singleHorizontal ||
            controlType === InputChoiceControlType.multipleHorizontal
          }
        >
          Right
        </option>
        <option value={InputChoiceLabelPosition.boxed}>Boxed</option>
      </SelectFormControl>

      <NumberFormControl
        label={"Maximum height"}
        value={activeValue.rows}
        min={InputChoiceRows.minimum}
        max={InputChoiceRows.maximum}
        onUpdate={(value) => {
          update({ rows: value });
        }}
      />

      <NumberFormControl
        label={"Width (%)"}
        value={activeValue.width}
        min={InputChoiceWidth.minimum}
        max={InputChoiceWidth.maximum}
        onUpdate={(value) => {
          update({ width: value });
        }}
      />

      <QuestionTitleFormControl
        editor={editor}
        label={"Question title*"}
        value={activeValue.questionTitle}
      />

      <TextFormControl
        label={"Description"}
        value={activeValue.description}
        onUpdate={(value) => {
          update({ description: value });
        }}
      />

      <TextFormControl
        label={"Question ID"}
        value={activeValue.coding}
        onUpdate={(value) => {
          update({ coding: value });
        }}
      />

      <SelectFormControl
        label={"Default value"}
        value={activeValue.defaultValueId}
        onUpdate={(value) => {
          update({ defaultValueId: value });
        }}
      >
        <option value={""} key={""}>
          &ndash;
        </option>
        {activeValue.values.map((value) => {
          return (
            <option value={value.id} key={value.id}>
              {value.content.map((c) => toText(c)).join("")}
            </option>
          );
        })}
      </SelectFormControl>

      <TextFormControl
        label={"Placeholder"}
        value={activeValue.watermark}
        onUpdate={(value) => {
          update({ watermark: value });
        }}
      />

      <CheckboxFormControl
        label={"Randomize options"}
        value={activeValue.randomize}
        onUpdate={(value) => {
          update({ randomize: value });
        }}
      />

      <CheckboxFormControl
        label={"All of the above"}
        value={activeValue.allOfTheAbove}
        onUpdate={(value) => {
          update({ allOfTheAbove: value });
        }}
      />

      <CheckboxFormControl
        label={"None of the above"}
        value={activeValue.noneOfTheAbove}
        onUpdate={(value) => {
          update({ noneOfTheAbove: value });
        }}
      />

      <CheckboxFormControl
        label={"Other specify"}
        value={activeValue.otherSpecify}
        onUpdate={(value) => {
          update({ otherSpecify: value });
        }}
      />

      <CheckboxFormControl
        label={"Respondents must specify"}
        value={activeValue.respondentsMustSpecify}
        onUpdate={(value) => {
          update({ respondentsMustSpecify: value });
        }}
      />

      <div className="InputChoice-response-id-table PropertiesPanel-FormControl">
        <div className="InputChoice-response-id-table-row">
          <div className="InputChoice-response-id-table-header">Lables</div>
          <div className="InputChoice-response-id-table-header">
            Response ID
          </div>
        </div>

        {activeValue.values.map((value) => {
          return (
            <div className="InputChoice-response-id-table-row" key={value.id}>
              <div className="InputChoice-response-id-table-cell">
                {value.content.map((c) => toText(c)).join("")}
              </div>

              <TextFormControl
                className="InputChoice-response-id-table-cell"
                value={value.coding}
                onUpdate={(responseId) => {
                  updateResponseIds(value.id, responseId);
                }}
              />
            </div>
          );
        })}
      </div>

      <NumberFormControl
        label={"Minimum number of responses"}
        value={activeValue.choiceSelectionRangeMin}
        min={InputChoiceSelectionRangeMin.minimum}
        max={InputChoiceSelectionRangeMin.maximum}
        onUpdate={(value) => {
          update({ choiceSelectionRangeMin: value });
        }}
        disabled={
          controlType === InputChoiceControlType.singleVertical ||
          controlType === InputChoiceControlType.singleHorizontal
        }
      />

      <NumberFormControl
        label={"Maximum number of responses"}
        value={activeValue.choiceSelectionRangeMax}
        min={InputChoiceSelectionRangeMax.minimum}
        max={InputChoiceSelectionRangeMax.maximum}
        onUpdate={(value) => {
          update({ choiceSelectionRangeMax: value });
        }}
        disabled={
          controlType === InputChoiceControlType.singleVertical ||
          controlType === InputChoiceControlType.singleHorizontal
        }
      />

      <CheckboxFormControl
        label={"Required"}
        value={activeValue.required}
        onUpdate={(value) => {
          update({ required: value });
        }}
      />
    </div>
  );
};
