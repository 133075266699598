import Chart, {
  BarControllerDatasetOptions,
  ChartOptions
} from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Node, Schema } from "prosemirror-model";
import { Decoration, EditorView, NodeView } from "prosemirror-view";
import { Editor } from "../../../editor";
import { WidthResizerNodeView } from "../../../editor/plugins/width-resizer";
import { UnreachableCaseError } from "../../../util";
import { AlignmentType } from "../../alignment";
import { DocumentSkinState } from "../../document-skin/plugin/document-skin-picker";
import { InputStyleObserver } from "../../document-skin/util";
import { ColorSchemeObserver } from "../../color-scheme/util";
import { GetTranslationFn, LanguageObserver } from "../../localization/util";
import {
  BarChartIndexAxis,
  BarChartSegment,
  BarChartInputValueType,
  BarChartLegend,
  BarChartStyle
} from "../schema";
import {
  capitalizeFirstLetter,
  DEFAULT_SPACE_BETWEEN_TICKS_HORIZONTAL_MODE,
  getRandomValue,
  mapBarStyleToPX,
  mapDomStyleToPX,
  optionsHorizontal,
  optionsVertical,
  SPACE_BETWEEN_TICKS_HORIZONTAL_MODE_WITH_IMG,
  mapColorPaletteTypeToLabelColor,
  mapColorPaletteTypeToLineColor,
  MAX_COUNT_MARGIN_WEIGHT,
  PERCENTAGE_MAX_RANGE,
  LinearAxisConstraints,
  getRandomValueFromId,
  mapColorToRgba,
  COUNT_MAX_RANGE,
  colorSet
} from "../util";
import { ColorPaletteType } from "../../color-scheme/types";

export class BarChartNodeView<S extends Schema> implements NodeView<S> {
  dom: HTMLElement;

  private barChartContainer: HTMLElement;
  private barChartInfoContainer: HTMLElement;
  private barChartAreaContainer: HTMLElement;
  private barChartCanvasContainer: HTMLElement;
  private barChartXAxisContainer: HTMLElement;
  private barChartYAxisContainer: HTMLElement;
  private chart: Chart;
  private emptyState: HTMLElement;
  private emptyText: HTMLElement;
  private legend: HTMLElement;
  private footer: HTMLElement;
  private footerSkippedCount: HTMLElement;
  private footerHiddenCount: HTMLElement;
  private mockedLabel: HTMLElement;
  private skippedValue: number;
  private hiddenValue: number;
  private currentSegments: BarChartSegment[];
  private currentInputValueType: BarChartInputValueType | null;
  private resizer: WidthResizerNodeView<S>;
  private languageObserver: LanguageObserver<S>;
  private inputStyleObserver: InputStyleObserver<S>;
  private colorSchemeObserver: ColorSchemeObserver<S>;

  private values: number[] = [];

  private horizontalModeYAxisHeight = 0;
  private chartXAxisBottom = 0;
  private xAxisContainerPaddingLeft = 0;
  private isUsingImage = false;
  private hasImageUrlsChanged: boolean;
  private currentSchemeType: ColorPaletteType;

  private dataLabels: string[] = [];

  plugins = [
    ChartDataLabels,
    {
      id: "afterDraw",
      afterDraw: () => {
        // Wait for graph to be drawn to get values from it
        this.getXAxisLeftPadding();
        this.getYAxisPadding();
      }
    }
  ];

  private updatedOptions: ChartOptions<"bar"> | any | null = null;
  private borderRadius: number = 0;

  private verticalModeYAxisConstraints: LinearAxisConstraints = {
    max: 100,
    stepSize: 10
  };

  private horizontalModeXAxisConstraints: LinearAxisConstraints = {
    max: 100,
    stepSize: 10
  };

  constructor(
    private node: Node<S>,
    private view: EditorView<S>,
    getPos: () => number
  ) {
    this.barChartContainer = document.createElement("div");
    this.barChartContainer.className = "bar-chart-container";
    this.barChartContainer.contentEditable = "false";
    this.barChartContainer.classList.add(node.attrs.type);

    this.barChartInfoContainer = document.createElement("div");
    this.barChartInfoContainer.classList.add("bar-chart-info-container");
    this.barChartInfoContainer.contentEditable = "false";

    this.barChartAreaContainer = document.createElement("div");
    this.barChartAreaContainer.className = "bar-chart-area-container";
    this.barChartAreaContainer.contentEditable = "false";

    this.barChartCanvasContainer = document.createElement("div");
    this.barChartCanvasContainer.classList.add("bar-chart-canvas-container");
    this.barChartCanvasContainer.contentEditable = "false";

    this.barChartXAxisContainer = document.createElement("div");
    this.barChartXAxisContainer.className = "bar-chart-xaxis-container";
    this.barChartXAxisContainer.contentEditable = "false";

    this.barChartYAxisContainer = document.createElement("div");
    this.barChartYAxisContainer.className = "bar-chart-yaxis-container";
    this.barChartYAxisContainer.contentEditable = "false";

    this.emptyText = document.createElement("span");
    this.emptyText.className = "bar-chart-empty-text";

    this.skippedValue = getRandomValue(10);
    this.hiddenValue = getRandomValue(10);

    this.languageObserver = new LanguageObserver(view, (getTranslation) => {
      this.updateTexts(getTranslation);
    });

    this.inputStyleObserver = new InputStyleObserver(view, (getInputStyle) => {
      this.updateRadius(getInputStyle);
      this.chart.update();
    });

    this.colorSchemeObserver = new ColorSchemeObserver(
      view,
      (getColorScheme) => {
        if (getColorScheme) {
          const type = getColorScheme.palette.type;
          this.updateColorScheme(type);
        }
        this.chart.update();
      }
    );

    const getTranslation = this.languageObserver.getTranslation;
    const getInputStyle = this.inputStyleObserver.getInputStyle;
    const getColorScheme = this.colorSchemeObserver.getColorScheme;

    const container = document.createElement("bar-chart");
    container.style.display = "block";

    this.emptyState = document.createElement("div");
    this.emptyState.className = "bar-chart-empty-state";
    this.emptyState.contentEditable = "false";

    this.footer = document.createElement("div");
    this.footer.className = "bar-chart-footer";
    this.footer.contentEditable = "false";

    this.footerSkippedCount = document.createElement("span");
    this.footerSkippedCount.className = "bar-chart-footer-text";
    this.footerSkippedCount.contentEditable = "false";

    this.footerHiddenCount = document.createElement("span");
    this.footerHiddenCount.className = "bar-chart-footer-text";
    this.footerHiddenCount.contentEditable = "false";

    this.mockedLabel = document.createElement("span");
    this.mockedLabel.className = "bar-chart-mocked-label";
    this.mockedLabel.contentEditable = "false";

    this.legend = document.createElement("div");
    this.legend.className = "bar-chart-legends";
    this.legend.contentEditable = "false";

    const segments = node.attrs.segments as BarChartSegment[];
    const showInputLabel = node.attrs.showInputLabel;
    const showInputValue = node.attrs.showInputValue;
    const showSkippedCount = node.attrs.showSkippedCount;
    const showHiddenCount = node.attrs.showHiddenCount;
    const legend = node.attrs.legend;
    const alignment = node.attrs.alignment;
    const style = node.attrs.style;
    const showDataLabel = node.attrs.showDataLabel;
    const inputValueType = node.attrs.inputValueType;
    const inputId = node.attrs.inputId;
    const direction = node.attrs.type;

    this.dataLabels = this.mapSegmentsToLabels(segments);

    this.updatedOptions =
      direction === BarChartIndexAxis.vertical
        ? { ...optionsVertical }
        : { ...optionsHorizontal };

    this.currentInputValueType = null;

    this.values = this.mapSegmentsToValues(segments);

    const canvas = document.createElement("canvas");
    this.chart = new Chart(canvas, {
      type: "bar",
      data: {
        labels: this.mapSegmentsToLabels(segments),
        datasets: [
          {
            data: this.values,
            backgroundColor: this.mapSegmentsToBackgroundColors(segments),
            borderWidth: 1,
            borderSkipped: false,
            datalabels: {
              align: "end",
              anchor: "end",
              padding: {
                left: 5,
                top: 5
              }
            }
          }
        ]
      },
      plugins: this.plugins,
      options: { ...this.updatedOptions }
    });

    const emptyIcon = document.createElement("span");
    emptyIcon.className = "bx bx-chart-bar";

    this.resizer = new WidthResizerNodeView(
      container,
      this.node,
      this.view,
      getPos,
      25,
      100,
      (width) => {
        const editor = view as Editor<S>;
        editor.commands.updateBarChart.execute({
          width: width
        });
      }
    );

    this.emptyState.appendChild(emptyIcon);
    this.emptyState.appendChild(this.emptyText);
    this.footer.appendChild(this.footerSkippedCount);
    this.footer.appendChild(this.footerHiddenCount);
    container.appendChild(this.emptyState);
    container.appendChild(this.barChartContainer);
    container.appendChild(this.mockedLabel);
    this.dom = container;

    this.hasImageUrlsChanged = true;

    this.currentSchemeType = getColorScheme
      ? getColorScheme.palette.type
      : "light";

    this.determineIfUsingImage(segments);
    this.updateTexts(getTranslation);
    this.updateRadius(getInputStyle);
    this.updateColorScheme(this.currentSchemeType);
    this.updateBarStyle(style);
    this.updateFooterDisplay(showSkippedCount, showHiddenCount, inputId);
    this.updateDataLabelDisplay(showDataLabel);
    this.updateDisplay(inputId);
    this.updateLegend(legend, inputId, segments);
    this.updateAlignment(alignment);
    this.computeAxisConstraints(inputValueType);
    this.toggleAxisLabels(showInputLabel);
    this.updateYAxisLabelFormatter(showInputValue, inputValueType, direction);
    this.updateColorScheme(this.currentSchemeType);
    this.setAxisConstraints(direction);
    this.updateType(null, direction);
    this.setImagesStatusClass();
    this.updateAxis(segments);

    this.chart.config.options = { ...this.updatedOptions };

    this.currentInputValueType = inputValueType;

    this.barChartAreaContainer.appendChild(this.barChartCanvasContainer);
    this.barChartAreaContainer.appendChild(this.barChartXAxisContainer);
    this.barChartAreaContainer.appendChild(this.barChartYAxisContainer);
    this.barChartInfoContainer.appendChild(this.barChartAreaContainer);
    this.barChartContainer.appendChild(this.barChartInfoContainer);
    this.barChartContainer.appendChild(this.footer);

    setTimeout(() => {
      this.barChartCanvasContainer.appendChild(canvas);
    });

    this.currentSegments = [...segments];
    this.chart.resize();
    this.chart.update();
  }

  update(node: Node<S>, _decorations: Decoration[]): boolean {
    if (node.type !== this.node.type) {
      return false;
    }

    const previousType = this.node.attrs.type;

    this.node = node;
    this.resizer.update(node);

    const style = node.attrs.style;
    const inputValueType = node.attrs.inputValueType;
    const segments = node.attrs.segments as BarChartSegment[];
    const showInputLabel = node.attrs.showInputLabel;
    const showInputValue = node.attrs.showInputValue;
    const showDataLabel = node.attrs.showDataLabel;
    const showSkippedCount = node.attrs.showSkippedCount;
    const showHiddenCount = node.attrs.showHiddenCount;
    const inputId = node.attrs.inputId;
    const legend = node.attrs.legend;
    const alignment = node.attrs.alignment;
    const type = node.attrs.type;

    this.updatedOptions =
      type === BarChartIndexAxis.vertical
        ? { ...optionsVertical }
        : { ...optionsHorizontal };

    this.updateValues(segments);
    this.hasAnyDataChanged(segments);
    this.determineIfUsingImage(segments);
    this.setBarRadius();
    this.updateBarStyle(style);
    this.updateLabels(segments);
    this.updateBackgroundColors(segments);
    this.updateDataLabelDisplay(showDataLabel);
    this.updateDataLabelFormatter(inputValueType);
    this.updateLegend(legend, inputId, segments);
    this.updateYAxisLabelFormatter(showInputValue, inputValueType, type);
    this.updateAlignment(alignment);
    this.updateFooterDisplay(showSkippedCount, showHiddenCount, inputId);
    this.updateDisplay(inputId);
    this.toggleAxisLabels(showInputLabel);
    this.computeAxisConstraints(inputValueType);
    this.updateExistingAxisImageLabels();

    this.setAxisConstraints(type);
    this.updateColorScheme(this.currentSchemeType);
    this.updateType(previousType, type);
    this.setImagesStatusClass();
    this.updateAxis(segments);

    this.chart.config.options = { ...this.updatedOptions };

    this.chart.resize();
    this.chart.update();

    this.currentSegments = [...segments];
    this.currentInputValueType = inputValueType;

    return true;
  }

  private setAxisConstraints(type: BarChartIndexAxis) {
    if (type === BarChartIndexAxis.vertical) {
      this.updatedOptions.scales.y.ticks.stepSize = this.verticalModeYAxisConstraints.stepSize;
      this.updatedOptions.scales.y.max = this.verticalModeYAxisConstraints.max;
      this.updatedOptions.layout.padding.right = 0;
    } else {
      this.updatedOptions.scales.x.ticks.stepSize = this.horizontalModeXAxisConstraints.stepSize;
      this.updatedOptions.scales.x.max = this.horizontalModeXAxisConstraints.max;
      // when 100% we need to add padding to see the value
      this.updatedOptions.layout.padding.right = 40;
    }
  }

  private hasAnyDataChanged(segments: BarChartSegment[]) {
    this.hasImageUrlsChanged = false;

    if (this.currentSegments.length !== segments.length) {
      this.hasImageUrlsChanged = true;
    } else {
      this.currentSegments.forEach((cds: BarChartSegment, i: number) => {
        const s = segments[i];
        if (cds.imageUrl !== s.imageUrl) {
          this.hasImageUrlsChanged = true;
        }
      });
    }
  }

  private getYAxisPadding() {
    if (this.chart.scales.y.bottom === this.chartXAxisBottom) return;
    if (this.node.attrs.type === BarChartIndexAxis.vertical) return;

    this.chartXAxisBottom = this.chart.scales.y.bottom;
    const yAxisPadding = Math.trunc(
      this.horizontalModeYAxisHeight - this.chartXAxisBottom
    );

    this.dom.style.setProperty("--yaxis-padding-bottom", `${yAxisPadding}px`);
  }

  private getXAxisLeftPadding() {
    if (this.chart.scales.x.left === this.xAxisContainerPaddingLeft) return;
    if (this.node.attrs.type === BarChartIndexAxis.horizontal) return;

    this.xAxisContainerPaddingLeft = this.chart.scales.x.left;

    this.dom.style.setProperty(
      "--xaxis-container-padding-left",
      `${Math.trunc(this.xAxisContainerPaddingLeft)}px`
    );
  }

  private updateAxis(segments: BarChartSegment[]): void {
    if (!this.hasImageUrlsChanged) return;
    this.setAxis(
      segments,
      BarChartIndexAxis.vertical,
      this.barChartXAxisContainer
    );
    this.setMinHeightForHorizontalDirection();
    this.drawYAxis(BarChartIndexAxis.horizontal, segments);
  }

  private updateExistingAxisImageLabels() {
    const xAxisLabels = this.barChartXAxisContainer.childNodes;
    const yAxisLabels = this.barChartYAxisContainer.childNodes;

    if (xAxisLabels.length !== 0) {
      xAxisLabels.forEach((axisLabel, i) => {
        axisLabel.childNodes.forEach((c) => {
          const elt = c as Element;

          if (elt.className.includes("xaxis-label")) {
            elt.textContent = this.dataLabels[i];
          }
        });
      });
    }

    if (yAxisLabels.length !== 0) {
      yAxisLabels.forEach((axisLabel, i) => {
        axisLabel.childNodes.forEach((c) => {
          const elt = c as Element;

          if (elt.className.includes("yaxis-label")) {
            elt.textContent = this.dataLabels[i];
          }
        });
      });
    }
  }

  private setAxis(
    segments: BarChartSegment[],
    type: BarChartIndexAxis,
    axisContainer: HTMLElement
  ): void {
    this.clearAxis(axisContainer);
    const indexAxis = type === BarChartIndexAxis.horizontal ? "y" : "x";

    for (let i = 0; i < segments.length; i++) {
      const segment = segments[i];
      const axisTickContainer = document.createElement("div");
      const labelElement = this.createLabel(i, indexAxis);
      const imageUrl = segment.imageUrl;

      const imgContainer = document.createElement("div");
      imgContainer.classList.add(
        `bar-chart-${indexAxis}axis-tick-img-container`
      );

      if (this.isUsingImage) {
        if (imageUrl != null && imageUrl !== "") {
          const imgElement = document.createElement("img");
          imgElement.classList.add("img-fit");
          imgElement.src = imageUrl;
          imgContainer.appendChild(imgElement);
        }
        axisTickContainer.appendChild(imgContainer);
      }

      axisTickContainer.classList.add(
        `bar-chart-${indexAxis}axis-tick-container`
      );
      axisTickContainer.appendChild(labelElement);
      axisContainer.appendChild(axisTickContainer);
    }
  }

  private drawYAxis(type: BarChartIndexAxis, segments: BarChartSegment[]) {
    const tickContainerHeight =
      this.horizontalModeYAxisHeight /
      (segments.length !== 0 ? segments.length : 1);

    this.dom.style.setProperty(
      "--yaxis-tick-container-height",
      `${tickContainerHeight}px`
    );

    this.setAxis(segments, type, this.barChartYAxisContainer);
  }

  private setImagesStatusClass() {
    if (this.isUsingImage) {
      this.barChartContainer.classList.add("images");
      this.barChartContainer.classList.remove("no-images");
    } else {
      this.barChartContainer.classList.add("no-images");
      this.barChartContainer.classList.remove("images");
    }

    const canvasContainerHeight = this.isUsingImage ? 79 : 90;

    this.dom.style.setProperty(
      "--canvas-container-height-vertical-mode",
      `${canvasContainerHeight}%`
    );
  }

  private createLabel(index: number, indexAxis: "x" | "y") {
    const labelElement = document.createElement("label");
    labelElement.textContent = this.dataLabels[index];
    labelElement.classList.add(`${indexAxis}axis-label`);
    labelElement.contentEditable = "false";
    return labelElement;
  }

  private clearAxis(axisContainer: HTMLElement) {
    while (axisContainer.firstChild) {
      if (axisContainer.lastChild) {
        axisContainer.removeChild(axisContainer.lastChild);
      }
    }
  }

  private updateTexts(getTranslation: GetTranslationFn): void {
    this.emptyText.innerText = getTranslation("BAR_CHART.NAME");
    this.footerSkippedCount.innerText = `${this.skippedValue} ${
      this.skippedValue > 1
        ? getTranslation("BAR_CHART_SKIPPED_PLURAL")
        : getTranslation("BAR_CHART_SKIPPED_SINGULAR")
    }`;
    this.footerHiddenCount.innerText = `${this.hiddenValue} ${
      this.hiddenValue > 1
        ? getTranslation("BAR_CHART_HIDDEN_PLURAL")
        : getTranslation("BAR_CHART_HIDDEN_SINGULAR")
    }`;
    this.mockedLabel.innerText = getTranslation("BAR_CHART.MOCKED_LABEL");
  }

  private updateType(
    previousType: BarChartIndexAxis | null,
    type: BarChartIndexAxis
  ): void {
    if (previousType === type) return;

    if (type === BarChartIndexAxis.vertical) {
      this.barChartContainer.classList.remove("horizontal");
      this.barChartContainer.classList.add("vertical");
      this.barChartXAxisContainer.classList.remove("hide");
      this.barChartYAxisContainer.classList.add("hide");
    } else {
      this.barChartContainer.classList.remove("vertical");
      this.barChartContainer.classList.add("horizontal");
      this.barChartXAxisContainer.classList.add("hide");
      this.barChartYAxisContainer.classList.remove("hide");
    }
  }

  private setMinHeightForHorizontalDirection(): void {
    let cellSize = this.isUsingImage
      ? SPACE_BETWEEN_TICKS_HORIZONTAL_MODE_WITH_IMG
      : DEFAULT_SPACE_BETWEEN_TICKS_HORIZONTAL_MODE;
    const length = this.values.length !== 0 ? this.values.length : 1;

    this.dom.style.setProperty(
      "--horizontal-bar-chart-container-height",
      `${cellSize * length}px`
    );

    this.horizontalModeYAxisHeight = cellSize * length;
  }

  private updateBarStyle(style: BarChartStyle): void {
    const newStyle = mapBarStyleToPX(style);
    const datasetsOptions = this.chart.data
      .datasets as BarControllerDatasetOptions[];
    datasetsOptions.forEach((datasetOption) => {
      datasetOption.barThickness = newStyle;
    });
  }

  private determineIfUsingImage(segments: BarChartSegment[]): void {
    this.isUsingImage = false;
    if (segments.find((s) => s.imageUrl != null && s.imageUrl !== "") != null) {
      this.isUsingImage = true;
    }
  }

  private updateFooterDisplay(
    showSkippedCount: boolean,
    showHiddenCount: boolean,
    inputId: string
  ): void {
    if (!inputId || (!showSkippedCount && !showHiddenCount)) {
      this.footer.setAttribute("style", "display:none");
    } else {
      this.footer.removeAttribute("style");
    }

    if (inputId !== null && showSkippedCount) {
      this.footerSkippedCount.removeAttribute("style");
    } else {
      this.footerSkippedCount.setAttribute("style", "display:none");
    }

    if (inputId !== null && showHiddenCount) {
      this.footerHiddenCount.removeAttribute("style");
    } else {
      this.footerHiddenCount.setAttribute("style", "display:none");
    }
  }

  private updateDataLabelDisplay(showDataLabel: boolean): void {
    this.chart.data.datasets.forEach((dataset) => {
      const datalabels = dataset.datalabels;

      if (datalabels) {
        datalabels.display = showDataLabel;
      }
    });
  }

  private updateDataLabelFormatter(type: BarChartInputValueType): void {
    this.chart.data.datasets.forEach((dataset) => {
      const datalabels = dataset.datalabels;

      if (datalabels) {
        datalabels.formatter = (value, _content) => {
          switch (type) {
            case BarChartInputValueType.count:
              return value;

            case BarChartInputValueType.percentage:
              return Math.round(value) + "%";

            default:
              throw new UnreachableCaseError(type);
          }
        };
      }
    });
  }

  private updateDisplay(inputId: string): void {
    if (inputId == null) {
      this.emptyState.removeAttribute("style");
      this.barChartContainer.setAttribute("style", "display:none;");
      this.mockedLabel.setAttribute("style", "display:none;");
    } else {
      this.emptyState.setAttribute("style", "display:none;");
      this.barChartContainer.removeAttribute("style");
      this.mockedLabel.removeAttribute("style");
    }
  }

  private toggleAxisLabels(showInputLabel: boolean): void {
    if (showInputLabel) {
      this.barChartContainer.classList.remove("hidden-labels");
    } else {
      this.barChartContainer.classList.add("hidden-labels");
    }
  }

  private updateYAxisLabelFormatter(
    showInputLabel: boolean,
    inputValueType: BarChartInputValueType,
    type: BarChartIndexAxis
  ): void {
    if (!this.updatedOptions) return;

    if (showInputLabel) {
      const cbk =
        inputValueType === BarChartInputValueType.count
          ? (val: string | number) => val
          : (val: string | number) => val + "%";

      if (type === BarChartIndexAxis.vertical) {
        this.updatedOptions.scales!.y!.ticks!.callback = cbk;
        this.updatedOptions.scales!.y!.ticks!.display = true;
      } else {
        this.updatedOptions.scales!.x!.ticks!.callback = cbk;
        this.updatedOptions.scales!.x!.ticks!.display = true;
      }
    }

    if (!showInputLabel) {
      if (type === BarChartIndexAxis.vertical) {
        this.updatedOptions.scales!.y!.ticks!.display = false;
      } else {
        this.updatedOptions.scales!.x!.ticks!.display = false;
      }
    }
  }

  private updateRadius(getInputStyle: DocumentSkinState | null): void {
    if (getInputStyle) {
      if (this.updatedOptions == null) return;
      this.borderRadius = mapDomStyleToPX(getInputStyle.documentSkin.id);
      this.updatedOptions.elements = {
        bar: {
          borderRadius: this.borderRadius
        }
      };
      this.chart.config.options = { ...this.updatedOptions };
      this.chart.update();
    }
  }

  private updateColorScheme(colorSchemeType: ColorPaletteType): void {
    this.currentSchemeType = colorSchemeType;
    const labelColor = mapColorPaletteTypeToLabelColor(colorSchemeType);
    const labelRgbaColor = labelColor.rgbaCode;
    this.chart.data.datasets.forEach((dataset) => {
      const dataLabels = dataset.datalabels;

      if (dataLabels) {
        dataLabels.color = labelRgbaColor;
      }
    });

    const lineColor = mapColorPaletteTypeToLineColor(colorSchemeType);
    const lineRgbaColor = lineColor.rgbaCode;
    this.updatedOptions.scales.y.ticks.color = labelRgbaColor;
    this.updatedOptions.scales.y.grid.color = lineRgbaColor;
    this.updatedOptions.scales.x.ticks.color = labelRgbaColor;
    this.updatedOptions.scales.x.grid.color = lineRgbaColor;
    this.updatedOptions.scales.x.grid.borderColor = lineRgbaColor;

    this.chart.config.options = { ...this.updatedOptions };
  }

  private setBarRadius(): void {
    if (this.updatedOptions == null) return;
    this.updatedOptions.elements = {
      bar: {
        borderRadius: this.borderRadius
      }
    };
  }

  private updateLegend(
    legend: BarChartLegend,
    inputId: string,
    segments: BarChartSegment[]
  ): void {
    const legendType = !inputId ? BarChartLegend.none : legend;
    this.dom.setAttribute("data-legend", legendType);

    while (this.legend.firstChild) {
      this.legend.removeChild(this.legend.firstChild);
    }

    segments.forEach((s, i) => {
      const node = document.createElement("div");
      const nodeTag = document.createElement("div");
      const nodeText = document.createElement("span");

      node.className = "bar-chart-legend";

      nodeTag.className = "bar-chart-legend-tag";
      nodeTag.setAttribute(
        "style",
        `background-color:${mapColorToRgba(
          s.color ? s.color : colorSet[i % colorSet.length].color
        )}`
      );

      nodeText.className = "bar-chart-legend-text";
      nodeText.innerText = `${s.label ? capitalizeFirstLetter(s.label) : ""}`;

      node.appendChild(nodeTag);
      node.appendChild(nodeText);

      this.legend.appendChild(node);
    });

    const chartInfoContainerChildren = this.barChartInfoContainer.children;

    for (let i = 0; i < chartInfoContainerChildren.length; i++) {
      if (
        chartInfoContainerChildren[i].className.includes("bar-chart-legends")
      ) {
        const oldLegend = this.barChartInfoContainer.children[i];
        this.barChartInfoContainer.removeChild(oldLegend);
      }
    }

    this.barChartInfoContainer.appendChild(this.legend);
  }

  private updateAlignment(alignment: AlignmentType): void {
    if (alignment != null) {
      this.dom.setAttribute("data-alignment", alignment);
    } else {
      this.dom.removeAttribute("data-alignment");
    }
  }

  private updateLabels(segments: BarChartSegment[]): void {
    this.chart.data.labels = this.mapSegmentsToLabels(segments);
    this.dataLabels = this.mapSegmentsToLabels(segments);
  }

  private isDifferentDataLength(segments: BarChartSegment[]): boolean {
    return this.currentSegments.length !== segments.length;
  }

  private updateValues(segments: BarChartSegment[]): void {
    if (this.isDifferentDataLength(segments)) {
      this.values = this.mapSegmentsToValues(segments);

      this.chart.data.datasets.forEach((dataset) => {
        dataset.data = this.values;
      });

      this.clearAxis(this.barChartXAxisContainer);
      this.clearAxis(this.barChartYAxisContainer);
    }
  }

  private computeAxisConstraints(inputValueType: BarChartInputValueType) {
    if (this.values.length === 0) return;

    if (this.currentInputValueType !== inputValueType) {
      let stepSize = 0;

      const max = this.values.reduce(
        (previous: number, current: number): number => {
          if (current > previous) return current;
          else return previous;
        },
        -1
      );

      let yScaleMax = 100;
      const yMax = Math.round(max * MAX_COUNT_MARGIN_WEIGHT);
      switch (inputValueType) {
        case BarChartInputValueType.count:
          for (const v of COUNT_MAX_RANGE) {
            if (yMax < v) {
              stepSize = v / 10;
              break;
            }
          }
          // no specs above 1000
          if (stepSize === 0) stepSize = yMax / 11;
          const steps = Math.trunc(max / stepSize);
          yScaleMax = (steps + 1) * stepSize;
          break;
        case BarChartInputValueType.percentage:
          if (yMax >= 100) {
            stepSize = 10;
            yScaleMax = 100;
          }
          for (const v of PERCENTAGE_MAX_RANGE) {
            if (yMax < v) {
              stepSize = v / 10;
              yScaleMax = v;
              break;
            }
          }
          break;
        default:
          throw new UnreachableCaseError(inputValueType);
      }

      this.verticalModeYAxisConstraints = { max: yScaleMax, stepSize };
      this.horizontalModeXAxisConstraints = { max: yScaleMax, stepSize };
    }
  }

  private updateBackgroundColors(segments: BarChartSegment[]): void {
    if (this.currentSegments.length !== segments.length) {
      this.chart.data.datasets.forEach((dataset) => {
        dataset.backgroundColor = this.mapSegmentsToBackgroundColors(segments);
      });
    }
  }

  private mapSegmentsToLabels(segments: BarChartSegment[]): string[] {
    return segments.map((s, index) => {
      return s.label != null ? s.label : `{Segment ${index + 1}}`;
    });
  }

  private mapSegmentsToValues(segments: BarChartSegment[]): number[] {
    return segments.map((s) => getRandomValueFromId(s.id));
  }

  private mapSegmentsToBackgroundColors(segments: BarChartSegment[]): string[] {
    return segments.map((s, i) =>
      mapColorToRgba(s.color ? s.color : colorSet[i % colorSet.length].color)
    );
  }

  ignoreMutation(
    mutation:
      | MutationRecord
      | {
          type: "selection";
          target: Element;
        }
  ): boolean {
    const resizeIgnoreMutation = this.resizer.ignoreMutation(mutation);
    const canvasIgnoreMutation = mutation.target === this.chart.canvas;
    const chartIgnoreMutation = mutation.target === this.barChartContainer;
    const chartCanvasContainerIgnoreMutation =
      mutation.target === this.barChartCanvasContainer;
    const barChartXAxisContainerIgnoreMutation =
      mutation.target === this.barChartXAxisContainer;
    const barChartYAxisContainerIgnoreMutation =
      mutation.target === this.barChartYAxisContainer;
    const imageMutation =
      mutation.type === "attributes" && mutation.target.nodeName === "IMG";

    return [
      resizeIgnoreMutation,
      canvasIgnoreMutation,
      chartIgnoreMutation,
      chartCanvasContainerIgnoreMutation,
      barChartXAxisContainerIgnoreMutation,
      barChartYAxisContainerIgnoreMutation,
      imageMutation
    ].some((x) => x);
  }
}
