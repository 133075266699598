import { Node, Schema } from "prosemirror-model";
import { Plugin } from "prosemirror-state";
import {
  CommandConfiguration,
  CommandConfigurations,
  CommandFn,
  Extension,
  NodeConfig
} from "../../editor";
import {
  dropInsertPlugin,
  executeAtPos,
  isEnableAtPos
} from "../../editor/plugins/drop-insert-plugin";
import { emitNotification } from "../../editor/plugins/notification";
import {
  applyQuestionTitle,
  questionTitleCacheKey,
  questionTitleCachePlugin,
  questionTitlePredicate
} from "../../editor/plugins/question-title-cache";
import { getIdGenerator } from "../../extensions/node-identifier";
import { isQuestionTitleAutoCreation } from "../../extensions/question-title/plugins/question-title-autocreation-flag";
import { canInsert, isInsideGrid, isMac } from "../../util";
import { insertBlock } from "../../util/transforms";
import {
  getQuestionTitleActiveValue,
  QuestionTitleActiveValue,
  updateQuestionTitleText
} from "../question-title";
import { InputDateTimeNode } from "./input-datetime-node";
import { nodeViewPlugin } from "./input-datetime-node-view";
import { InputDateTimeControlType, InputDateTimeSchema } from "./schema";
import {
  checkInRangeDefaultDateValue,
  checkInRangeDefaultTimeValue,
  focusedInputDateTime,
  parseInputDateTime,
  updateWidth
} from "./util";

interface InsertInputDateTimeCommandProps {
  controlType: InputDateTimeControlType;
}

export interface UpdateInputDateTimeCommandProps {
  controlType?: InputDateTimeControlType;
  width?: number;
  questionTitleText?: string;
  description?: string;
  coding?: string;
  defaultDate?: string | null;
  defaultTime?: string | null;
  isDefaultNow?: boolean;
  watermark?: string;
  dateFormat?: string;
  timeFormat?: string;
  minimumDate?: string | null;
  maximumDate?: string | null;
  minimumTime?: string | null;
  maximumTime?: string | null;
  required?: boolean;
}

export interface InputDateTimeActiveValue {
  controlType: InputDateTimeControlType;
  width: number;
  questionTitle: QuestionTitleActiveValue;
  description: string;
  coding: string;
  defaultDate: string | null;
  defaultTime: string;
  isDefaultNow: boolean;
  watermark: string;
  dateFormat: string;
  timeFormat: string;
  minimumDate: string | null;
  maximumDate: string | null;
  minimumTime: string;
  maximumTime: string;
  required: boolean;
  widthDisabled: boolean;
}

const questionTitleKey = questionTitleCacheKey();
export class InputDateTime implements Extension<InputDateTimeSchema> {
  get name(): string {
    return "inputDateTime";
  }

  get nodes(): NodeConfig[] {
    return [new InputDateTimeNode()];
  }

  plugins(schema: InputDateTimeSchema): Plugin[] {
    return [
      nodeViewPlugin(),
      dropInsertPlugin((view, data, posAtCoords) => {
        if (data.type !== "inputDateTime") {
          return false;
        }

        let controlType: InputDateTimeControlType;
        switch (data.subType) {
          case InputDateTimeControlType.date:
            controlType = InputDateTimeControlType.date;
            break;

          case InputDateTimeControlType.time:
            controlType = InputDateTimeControlType.time;
            break;

          default:
            controlType = InputDateTimeControlType.date;
            break;
        }

        const command = this.insertInputDateTimeCommand(schema);

        const isEnabled = isEnableAtPos(command, view, posAtCoords);
        if (
          !isEnabled({
            controlType: controlType
          })
        ) {
          emitNotification(view.state, {
            type: "warning",
            message: "drop.insert.invalid-location"
          });
        } else {
          executeAtPos(
            command.execute({
              controlType: controlType
            }),
            view,
            posAtCoords
          );
        }

        return true;
      }),
      questionTitleCachePlugin(
        schema.nodes.inputDateTime,
        "INPUT_DATETIME.DEFAULT_QUESTION_TITLE",
        questionTitlePredicate,
        applyQuestionTitle,
        questionTitleKey
      )
    ];
  }

  commands(
    schema: InputDateTimeSchema
  ): CommandConfigurations<InputDateTimeSchema> {
    return {
      insertInputDateTime: this.insertInputDateTimeCommand(schema),
      updateInputDateTime: this.updateInputDateTimeCommand(schema)
    };
  }

  private insertInputDateTimeCommand(
    schema: InputDateTimeSchema
  ): CommandConfiguration<
    InputDateTimeSchema,
    InsertInputDateTimeCommandProps,
    undefined
  > {
    return {
      isActive: () => {
        return false;
      },
      isEnabled: () => {
        return insertInputDateTime(schema, InputDateTimeControlType.date);
      },
      execute: (props) => {
        if (props == null) {
          throw new Error(
            `To insert an input date time, InsertInputDateTimeCommandProps needs to be provided.`
          );
        }
        const { controlType } = props;

        if (controlType == null) {
          throw new Error(
            `To insert an input date time, control type need to be provided.`
          );
        }
        return insertInputDateTime(schema, controlType);
      },
      shortcuts: {
        [isMac() ? "Ctrl-m" : "Alt-m"]: {
          controlType: InputDateTimeControlType.date
        }
      }
    };
  }

  private updateInputDateTimeCommand(
    _schema: InputDateTimeSchema
  ): CommandConfiguration<
    InputDateTimeSchema,
    UpdateInputDateTimeCommandProps,
    InputDateTimeActiveValue | undefined
  > {
    return {
      isActive: () => {
        return (state) => {
          return focusedInputDateTime(state) != null;
        };
      },
      isEnabled: () => {
        return (state) => {
          return focusedInputDateTime(state) != null;
        };
      },
      execute: (props) => {
        if (props == null) {
          throw new Error(
            `To update an input datetime, UpdateInputDateTimeCommandProps needs to be provided.`
          );
        }

        return (state, dispatch) => {
          const focused = focusedInputDateTime(state);
          return updateInputDateTime(props, focused)(state, dispatch);
        };
      },
      activeValue: () => {
        return (state) => {
          const focused = focusedInputDateTime(state);
          if (!focused) {
            return undefined;
          }

          const widthDisabled = isInsideGrid(state, focused);
          return {
            controlType: focused.node.attrs.controlType,
            width: widthDisabled ? 100 : focused.node.attrs.width,
            questionTitle: getQuestionTitleActiveValue(state, focused.node),
            description: focused.node.attrs.description,
            coding: focused.node.attrs.coding,
            defaultDate: focused.node.attrs.defaultDate,
            defaultTime: focused.node.attrs.defaultTime,
            isDefaultNow: focused.node.attrs.isDefaultNow,
            watermark: focused.node.attrs.watermark,
            dateFormat: focused.node.attrs.dateFormat,
            timeFormat: focused.node.attrs.timeFormat,
            minimumDate: focused.node.attrs.minimumDate,
            maximumDate: focused.node.attrs.maximumDate,
            minimumTime: focused.node.attrs.minimumTime,
            maximumTime: focused.node.attrs.maximumTime,
            required: focused.node.attrs.required,
            widthDisabled: widthDisabled
          };
        };
      }
    };
  }
}

function insertInputDateTime(
  schema: InputDateTimeSchema,
  controlType: InputDateTimeControlType
): CommandFn<InputDateTimeSchema> {
  return (state, dispatch) => {
    if (!canInsert(schema.nodes.inputDateTime)(state)) {
      return false;
    } else {
      if (dispatch) {
        const inputDateTime = createInputDateTime(schema, controlType);
        if (inputDateTime == null) {
          return false;
        }

        let tr = state.tr;
        tr = insertBlock(
          tr,
          state.schema,
          inputDateTime,
          false,
          getIdGenerator(state),
          isQuestionTitleAutoCreation(state)
        );
        dispatch(tr);
      }
      return true;
    }
  };
}

function createInputDateTime(
  schema: InputDateTimeSchema,
  controlType: InputDateTimeControlType
): Node<InputDateTimeSchema> {
  const { inputDateTime } = schema.nodes;
  return inputDateTime.createChecked({
    controlType: controlType,
    ...(controlType === InputDateTimeControlType.time
      ? { timeFormat: "HH:mm" }
      : { dateFormat: "yyyy-MM-dd" })
  });
}

function updateInputDateTime<S extends Schema>(
  props: UpdateInputDateTimeCommandProps,
  inputDateTime: { node: Node<S>; pos: number } | undefined
): CommandFn<S> {
  return (state, dispatch) => {
    if (inputDateTime == null) {
      return false;
    }

    if (dispatch) {
      const { node, pos } = inputDateTime;

      let updatedAttrs = {
        ...node.attrs
      };

      if (props.controlType !== undefined) {
        updatedAttrs = { ...updatedAttrs, controlType: props.controlType };
      }

      if (props.questionTitleText !== undefined) {
        updatedAttrs = updateQuestionTitleText(
          updatedAttrs,
          props.questionTitleText
        );
      }

      if (props.description !== undefined) {
        updatedAttrs = { ...updatedAttrs, description: props.description };
      }

      if (props.coding !== undefined) {
        updatedAttrs = { ...updatedAttrs, coding: props.coding };
      }

      if (props.defaultDate !== undefined) {
        updatedAttrs = { ...updatedAttrs, defaultDate: props.defaultDate };
      }

      if (props.defaultTime !== undefined) {
        updatedAttrs = { ...updatedAttrs, defaultTime: props.defaultTime };
      }

      if (props.isDefaultNow !== undefined) {
        updatedAttrs = { ...updatedAttrs, isDefaultNow: props.isDefaultNow };
      }

      if (props.watermark !== undefined) {
        updatedAttrs = { ...updatedAttrs, watermark: props.watermark };
      }

      if (props.dateFormat !== undefined) {
        updatedAttrs = { ...updatedAttrs, dateFormat: props.dateFormat };
      }

      if (props.timeFormat !== undefined) {
        updatedAttrs = { ...updatedAttrs, timeFormat: props.timeFormat };
      }

      if (props.minimumDate !== undefined) {
        updatedAttrs = { ...updatedAttrs, minimumDate: props.minimumDate };
      }

      if (props.maximumDate !== undefined) {
        updatedAttrs = { ...updatedAttrs, maximumDate: props.maximumDate };
      }

      if (props.minimumTime !== undefined) {
        updatedAttrs = { ...updatedAttrs, minimumTime: props.minimumTime };
      }

      if (props.maximumTime !== undefined) {
        updatedAttrs = { ...updatedAttrs, maximumTime: props.maximumTime };
      }

      if (props.required !== undefined) {
        updatedAttrs = { ...updatedAttrs, required: props.required };
      }

      if (
        props.controlType != null &&
        props.controlType !== node.attrs.controlType
      ) {
        updatedAttrs = {
          ...updatedAttrs,
          ...(props.controlType === InputDateTimeControlType.time
            ? { timeFormat: "HH:mm" }
            : { dateFormat: "yyyy-MM-dd" })
        };
      }

      if (props.width != null && !isNaN(props.width)) {
        const updatedWidth = updateWidth(props.width);
        updatedAttrs = { ...updatedAttrs, width: updatedWidth };
      }

      if (node.attrs.controlType === InputDateTimeControlType.date) {
        if (
          props.defaultDate != null &&
          props.defaultDate !== node.attrs.defaultDate
        ) {
          const updatedDefaultDate = parseInputDateTime(
            InputDateTimeControlType.date,
            props.defaultDate,
            node.attrs.dateFormat
          );
          updatedAttrs = { ...updatedAttrs, defaultDate: updatedDefaultDate };
          if (
            updatedDefaultDate != null &&
            node.attrs.minimumDate != null &&
            node.attrs.minimumDate !== "" &&
            node.attrs.maximumDate != null &&
            node.attrs.maximumDate !== ""
          ) {
            const isInRange: boolean = checkInRangeDefaultDateValue(
              node.attrs.minimumDate,
              node.attrs.maximumDate,
              updatedDefaultDate
            );
            if (!isInRange) {
              updatedAttrs = { ...updatedAttrs, defaultDate: null };
            }
          }
        }

        if (
          props.minimumDate != null &&
          props.minimumDate !== node.attrs.minimumDate
        ) {
          const updatedMinDate = parseInputDateTime(
            InputDateTimeControlType.date,
            props.minimumDate,
            node.attrs.dateFormat
          );
          updatedAttrs = { ...updatedAttrs, minimumDate: updatedMinDate };
        }

        if (
          props.maximumDate != null &&
          props.maximumDate !== node.attrs.maximumDate
        ) {
          const updatedMaxDate = parseInputDateTime(
            InputDateTimeControlType.date,
            props.maximumDate,
            node.attrs.dateFormat
          );
          updatedAttrs = { ...updatedAttrs, maximumDate: updatedMaxDate };
        }
      } else if (node.attrs.controlType === InputDateTimeControlType.time) {
        if (
          props.defaultTime != null &&
          props.defaultTime !== node.attrs.defaultTime
        ) {
          const updatedDefaultTime = parseInputDateTime(
            InputDateTimeControlType.time,
            props.defaultTime,
            node.attrs.timeFormat
          );
          updatedAttrs = { ...updatedAttrs, defaultTime: updatedDefaultTime };
          if (
            updatedDefaultTime != null &&
            node.attrs.minimumTime != null &&
            node.attrs.minimumTime !== "" &&
            node.attrs.maximumTime != null &&
            node.attrs.maximumTime !== ""
          ) {
            const isInRange: boolean = checkInRangeDefaultTimeValue(
              node.attrs.minimumTime,
              node.attrs.maximumTime,
              updatedDefaultTime
            );
            if (!isInRange) {
              updatedAttrs = { ...updatedAttrs, defaultTime: null };
            }
          }
        }

        if (
          props.minimumTime != null &&
          props.minimumTime !== node.attrs.minimumTime
        ) {
          const updatedMinTime = parseInputDateTime(
            InputDateTimeControlType.time,
            props.minimumTime,
            node.attrs.timeFormat
          );
          updatedAttrs = { ...updatedAttrs, minimumTime: updatedMinTime };
        }

        if (
          props.maximumTime != null &&
          props.maximumTime !== node.attrs.maximumTime
        ) {
          const updatedMaxTime = parseInputDateTime(
            InputDateTimeControlType.time,
            props.maximumTime,
            node.attrs.timeFormat
          );
          updatedAttrs = { ...updatedAttrs, maximumTime: updatedMaxTime };
        }
      }

      updatedAttrs = { ...updatedAttrs };

      let tr = state.tr;
      tr = tr.setNodeMarkup(pos, undefined, updatedAttrs);

      dispatch(tr);
    }
    return true;
  };
}
