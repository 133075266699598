import { Node, NodeSpec } from "prosemirror-model";
import { DocumentBuilders, NodeConfig } from "../../../editor";
import { InputRankWidth, InputRankControlType } from "../schema";
import {
  setQuestionTitleAttrs,
  getQuestionTitleAttrs
} from "../../question-title";
import { AlignmentType } from "extensions/alignment";

export class InputRankNode implements NodeConfig {
  get name(): string {
    return "inputRank";
  }

  get spec(): NodeSpec {
    return {
      group: "block input",
      content: "inputRankSections{1} inputRankOptions{1}",
      draggable: true,
      selectable: true,
      focusable: true,
      allowGapCursor: true,
      allowIndentation: false,
      allowAlignment: ["left", "center", "right"],
      blockAlignment: true,
      isolating: true,
      attrs: {
        id: { default: null },
        controlType: { default: InputRankControlType.dragDrop },
        width: { default: InputRankWidth.default },
        questionTitleText: { default: null },
        questionTitleRefElementId: { default: new Array<string>() },
        description: { default: "" },
        coding: { default: "" },
        repeatableScale: { default: false },
        repeatableItemSections: { default: false },
        randomizeItems: { default: false },
        randomizeSections: { default: false },
        respondentsMustSpecify: { default: false },
        required: { default: false },
        minRankedItem: { default: null },
        maxRankedItem: { default: null },
        alignment: { default: null }
      },
      parseDOM: [
        {
          tag: "input-rank",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getAttrs(node as Element);
          }
        }
      ],
      toDOM(node) {
        return ["input-rank", setAttrs(node), 0];
      }
    };
  }

  get builders(): DocumentBuilders {
    return { inputRank: { nodeType: "inputRank" } };
  }
}

function getAttrs(element: Element): { [key: string]: any } {
  const attrs: {
    id?: string;
    controlType?: InputRankControlType;
    width?: number;
    questionTitleText?: string;
    questionTitleRefElementId?: string[];
    description?: string;
    coding?: string;
    repeatableScale?: boolean;
    repeatableItemSections?: boolean;
    randomizeItems?: boolean;
    randomizeSections?: boolean;
    respondentsMustSpecify?: boolean;
    required?: boolean;
    minRankedItem?: number;
    maxRankedItem?: number;
    alignment?: AlignmentType;
  } = { ...getQuestionTitleAttrs(element) };

  const id = element.getAttribute("id");
  if (id != null) {
    attrs.id = id;
  }

  const width = element.getAttribute("data-width");
  if (width != null) {
    attrs.width = parseInt(width, 10);
  }

  const description = element.getAttribute("data-description");
  if (description != null) {
    attrs.description = description;
  }

  const coding = element.getAttribute("data-coding");
  if (coding != null) {
    attrs.coding = coding;
  }

  const repeatableScale = element.getAttribute("data-repeatable-scale");
  if (repeatableScale != null) {
    attrs.repeatableScale = repeatableScale.toLowerCase() === "true";
  }

  const repeatableItemSections = element.getAttribute(
    "data-repeatable-item-sections"
  );
  if (repeatableItemSections != null) {
    attrs.repeatableItemSections =
      repeatableItemSections.toLowerCase() === "true";
  }

  const randomizeItems = element.getAttribute("data-randomize-items");
  if (randomizeItems != null) {
    attrs.randomizeItems = randomizeItems.toLowerCase() === "true";
  }

  const randomizeSections = element.getAttribute("data-randomize-sections");
  if (randomizeSections != null) {
    attrs.randomizeSections = randomizeSections.toLowerCase() === "true";
  }

  const respondentsMustSpecify = element.getAttribute(
    "data-respondents-must-specify"
  );
  if (respondentsMustSpecify != null) {
    attrs.respondentsMustSpecify =
      respondentsMustSpecify.toLowerCase() === "true";
  }

  const required = element.getAttribute("data-required");
  if (required != null) {
    attrs.required = required.toLowerCase() === "true";
  }

  const minRankedItem = element.getAttribute("data-min-ranked-item");
  if (minRankedItem != null) {
    attrs.minRankedItem = parseInt(minRankedItem, 10);
  }

  const maxRankedItem = element.getAttribute("data-max-ranked-item");
  if (maxRankedItem != null) {
    attrs.maxRankedItem = parseInt(maxRankedItem, 10);
  }

  const alignment = element.getAttribute("data-alignment") as AlignmentType;
  if (alignment != null) {
    switch (alignment) {
      case "center":
      case "left":
      case "right":
        attrs.alignment = alignment;
        break;
    }
  }

  return attrs;
}

function setAttrs(node: Node): { [key: string]: any } {
  return {
    id: node.attrs.id,
    "data-control-type": node.attrs.controlType,
    ...setQuestionTitleAttrs(node),
    "data-width": node.attrs.width,
    "data-description": node.attrs.description,
    "data-coding": node.attrs.coding,
    "data-repeatable-scale": node.attrs.repeatableScale,
    "data-repeatable-item-sections": node.attrs.repeatableItemSections,
    "data-randomize-items": node.attrs.randomizeItems,
    "data-randomize-sections": node.attrs.randomizeSections,
    "data-respondents-must-specify": node.attrs.respondentsMustSpecify,
    "data-required": node.attrs.required,
    "data-min-ranked-item": node.attrs.minRankedItem,
    "data-max-ranked-item": node.attrs.maxRankedItem,
    "data-alignment": node.attrs.alignment
  };
}
