import { Node, NodeType, Schema } from "prosemirror-model";
import { Decoration, EditorView, NodeView } from "prosemirror-view";
import { findParentNodeOfType } from "../../../util/selection";
import { InputRankControlType } from "../schema";

export class InputRankSectionsNodeView<S extends Schema>
  implements NodeView<S> {
  dom: HTMLElement;
  contentDOM: HTMLElement;
  constructor(private node: Node<S>, private view: EditorView<S>) {
    const sections = document.createElement("input-rank-sections");

    this.dom = sections;

    const { state } = this.view;

    const parent = findParentNodeOfType(
      state.schema.nodes.inputRank as NodeType<S>
    )(state.selection);

    if (
      parent &&
      parent.node.attrs.controlType === InputRankControlType.dropdown
    ) {
      const tableRow = document.createElement("tr");

      sections.appendChild(tableRow);
      this.contentDOM = tableRow;
    } else {
      this.contentDOM = sections;
    }
  }

  update(node: Node<S>, _decorations: Decoration[]): boolean {
    if (node.type !== this.node.type) {
      return false;
    }

    return true;
  }
}
