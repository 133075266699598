import { Node, NodeSpec } from "prosemirror-model";
import { DocumentBuilders, NodeConfig } from "../../editor/extension/schema";
import { toNumberValue } from "../../util";
import { AlignmentType } from "../alignment";
import {
  getQuestionTitleAttrs,
  setQuestionTitleAttrs
} from "../question-title";
import {
  InputFileDefaults,
  InputFileFormat,
  InputFileMaxSize,
  InputFileWidth
} from "./schema";

function getInputFileAttrs(element: Element): { [key: string]: any } {
  const attrs: {
    id?: string;
    format?: InputFileFormat;
    width?: number;
    questionTitleText?: string;
    questionTitleRefElementId?: string[];
    description?: string;
    coding?: string;
    maxSize?: number;
    minFiles?: number;
    maxFiles?: number;
    required?: boolean;
    imageMinWidth?: number;
    imageMinHeight?: number;
    alignment?: AlignmentType;
  } = { ...getQuestionTitleAttrs(element) };

  const id = element.getAttribute("id");
  if (id != null) {
    attrs.id = id;
  }

  const format = element.getAttribute("data-format") as InputFileFormat;
  if (format != null) {
    switch (format) {
      case InputFileFormat.document:
      case InputFileFormat.image:
      case InputFileFormat.sound:
      case InputFileFormat.video:
        attrs.format = format;
        break;
      default:
        break;
    }
  }

  const width = toNumberValue(element.getAttribute("data-width"), 0);
  if (width != null) {
    attrs.width = width;
  }

  const description = element.getAttribute("data-description");
  if (description != null) {
    attrs.description = description;
  }

  const coding = element.getAttribute("data-coding");
  if (coding != null) {
    attrs.coding = coding;
  }

  const maxSize = toNumberValue(element.getAttribute("data-max-size"), 0);
  if (maxSize != null) {
    attrs.maxSize = maxSize;
  }

  const minFiles = toNumberValue(element.getAttribute("data-min-files"), 0);
  if (minFiles != null) {
    attrs.minFiles = minFiles;
  }

  const maxFiles = toNumberValue(element.getAttribute("data-max-files"), 0);
  if (maxFiles != null) {
    attrs.maxFiles = maxFiles;
  }

  const required = element.getAttribute("data-required");
  if (required != null) {
    attrs.required = required.toLowerCase() === "true";
  }

  const imgMinWidth = toNumberValue(
    element.getAttribute("data-image-min-width"),
    0
  );
  if (imgMinWidth != null) {
    attrs.imageMinWidth = imgMinWidth;
  }

  const imgMinHeight = toNumberValue(
    element.getAttribute("data-image-min-height"),
    0
  );
  if (imgMinHeight != null) {
    attrs.imageMinHeight = imgMinHeight;
  }

  const alignment = element.getAttribute("data-alignment") as AlignmentType;
  if (alignment != null) {
    switch (alignment) {
      case "center":
      case "left":
      case "right":
        attrs.alignment = alignment;
        break;
      default:
        break;
    }
  }

  return attrs;
}

function setInputFileAttrs(node: Node): { [key: string]: string } {
  return {
    id: node.attrs.id,
    ...setQuestionTitleAttrs(node),
    "data-format": node.attrs.format,
    "data-width": node.attrs.width,
    "data-description": node.attrs.description,
    "data-coding": node.attrs.coding,
    "data-max-size": node.attrs.maxSize,
    "data-min-files": node.attrs.minFiles,
    "data-max-files": node.attrs.maxFiles,
    "data-required": node.attrs.required,
    "data-image-min-width": node.attrs.imageMinWidth,
    "data-image-min-height": node.attrs.imageMinHeight,
    "data-alignment": node.attrs.alignment
  };
}

export class InputFileNode implements NodeConfig {
  get name(): string {
    return "inputFile";
  }

  get spec(): NodeSpec {
    return {
      group: "block input gridInput",
      draggable: true,
      selectable: true,
      focusable: true,
      allowGapCursor: true,
      allowIndentation: false,
      allowAlignment: ["left", "center", "right"],
      blockAlignment: true,
      attrs: {
        id: { default: null },
        format: { default: InputFileFormat.document },
        width: { default: InputFileWidth.default },
        questionTitleText: { default: null },
        questionTitleRefElementId: { default: new Array<string>() },
        description: { default: null },
        coding: { default: null },
        maxSize: { default: InputFileMaxSize.default },
        minFiles: { default: InputFileDefaults.MinFiles },
        maxFiles: { default: InputFileDefaults.MaxFiles },
        required: { default: false },
        imageMinWidth: { default: InputFileDefaults.ImageMinWidth },
        imageMinHeight: { default: InputFileDefaults.ImageMinHeight },
        alignment: { default: null }
      },
      parseDOM: [
        {
          tag: "input-file",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getInputFileAttrs(node as Element);
          }
        },
        {
          tag: '[data-input="file"]',
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getInputFileAttrs(node as Element);
          }
        }
      ],
      toDOM(node) {
        return ["input-file", setInputFileAttrs(node)];
      },
      applyBlueprint(
        attrs: Record<string, any>,
        blueprint: Record<string, any>
      ) {
        const updated = { ...attrs };

        updated.required = blueprint.required;
        updated.maxSize = blueprint.maxSize;
        updated.format = blueprint.format;
        updated.minFiles = blueprint.minFiles;
        updated.maxFiles = blueprint.maxFiles;
        updated.imageMinWidth = blueprint.imageMinWidth;
        updated.imageMinHeight = blueprint.imageMinHeight;

        return updated;
      }
    };
  }

  get builders(): DocumentBuilders {
    return { inputFile: { nodeType: "inputFile" } };
  }
}
