import { Schema } from "prosemirror-model";
import { Plugin, PluginKey } from "prosemirror-state";
import { Decoration, DecorationAttrs, DecorationSet } from "prosemirror-view";
import { CommandFn } from "../../../editor";
import { getTranslation } from "../../localization";
import {
  addScaleLabel,
  enableNotApplicable,
  removeScaleLabel
} from "../commands";
import { CONTROLS_CLASS } from "../node-views/input-scale-label-node-view";
import { focusedInputScaleLabels } from "../util";

interface ScaleLabelControls {
  controls: false | { opened: boolean; index: number };
}

const key = new PluginKey<ScaleLabelControls>("scaleLabelControls");

const ACTIONS_CLASS = "ProseMirror-scale-labels-actions";
const DROPDOWN_TOGGLE_CLASS = "ProseMirror-dropdown-toggle";
const DROPDOWN_CONTENT_CLASS = "ProseMirror-dropdown-content";
const DROPDOWN_ITEM_CLASS = "ProseMirror-dropdown-item";

export function scaleLabelControls() {
  const setControls = <S extends Schema>(index: number): CommandFn<S> => {
    return (state, dispatch) => {
      if (dispatch) {
        let tr = state.tr;
        tr = tr.setMeta(key, {
          setControls: { index: index }
        });

        dispatch(tr);
      }
      return true;
    };
  };

  const setOpened = <S extends Schema>(opened: boolean): CommandFn<S> => {
    return (state, dispatch) => {
      if (dispatch) {
        let tr = state.tr;
        tr = tr.setMeta(key, {
          setOpened: { opened: !opened }
        });

        dispatch(tr);
      }
      return true;
    };
  };

  return new Plugin<ScaleLabelControls>({
    key: key,
    state: {
      init(_config, _state) {
        return { controls: false };
      },
      apply(tr, value, _oldState, newState) {
        const focused = focusedInputScaleLabels(newState);
        if (focused == null) {
          return { controls: false };
        } else {
          const $pos = newState.doc.resolve(focused.pos);
          if (!$pos.parent.type.spec.group?.split(" ").includes("headerCell")) {
            return { controls: false };
          }

          const action = tr.getMeta(key);
          if (action != null && action.setControls != null) {
            const { index } = action.setControls as {
              index: number;
            };
            if (value.controls !== false) {
              if (value.controls.index === index) {
                return { controls: false };
              } else {
                return {
                  controls: {
                    index: index,
                    opened: false
                  }
                };
              }
            } else {
              return {
                controls: {
                  index: index,
                  opened: false
                }
              };
            }
          }

          const controls = value.controls;
          if (controls !== false) {
            if (action != null && action.setOpened != null) {
              const { opened } = action.setOpened as {
                opened: boolean;
              };
              return {
                controls: {
                  index: controls.index,
                  opened: opened
                }
              };
            }

            return value;
          }

          return value;
        }
      }
    },
    props: {
      decorations(state) {
        const translation = getTranslation(state);
        const focused = focusedInputScaleLabels(state);

        if (focused == null) {
          return DecorationSet.empty;
        }

        const $pos = state.doc.resolve(focused.pos);
        if (!$pos.parent.type.spec.group?.split(" ").includes("headerCell")) {
          return DecorationSet.empty;
        }

        const value = this.getState(state);
        const controls = value.controls;

        const { doc, schema } = state;
        const { node, pos } = focused;

        const decorations = new Array<Decoration>();

        const start = pos + 1;
        node.forEach((child, offset, index) => {
          const attrs: DecorationAttrs = {
            "data-index": `${index}`
          };

          const classes = new Array<string>();
          if (controls !== false && controls.index === index) {
            classes.push("active");
          }

          if (classes.length > 0) {
            attrs.class = classes.join(" ");
          }

          const decorationPos = start + offset;
          const decoration = Decoration.node(
            decorationPos,
            decorationPos + child.nodeSize,
            attrs
          );

          decorations.push(decoration);

          if (controls !== false && controls.index === index) {
            const columnControl = Decoration.widget(decorationPos + 1, () => {
              const dropdown = document.createElement("div");
              dropdown.contentEditable = "false";
              dropdown.classList.add(ACTIONS_CLASS);
              dropdown.setAttribute("data-index", `${index}`);

              const dropdownToggle = document.createElement("button");
              dropdownToggle.contentEditable = "false";
              dropdownToggle.className = DROPDOWN_TOGGLE_CLASS;

              const triangle = document.createElement("span");
              triangle.contentEditable = "false";
              triangle.className = "triangle";

              dropdownToggle.appendChild(triangle);

              const dropdownContent = document.createElement("div");
              dropdownContent.contentEditable = "false";
              dropdownContent.className = DROPDOWN_CONTENT_CLASS;

              if (controls.opened === false) {
                dropdownContent.style.display = "none";
              }

              const actions = [
                {
                  label: translation("SCALE_LABELS.ACTIONS.ADD_BEFORE"),
                  action: "addBefore",
                  disabled: false
                },
                {
                  label: translation("SCALE_LABELS.ACTIONS.ADD_AFTER"),
                  action: "addAfter",
                  disabled:
                    child.type === schema.nodes.inputScaleLabelNotApplicable
                },
                {
                  label: translation("SCALE_LABELS.ACTIONS.DELETE"),
                  action: "delete",
                  disabled: false
                }
              ];

              if (index === node.childCount - 1) {
                if (child.type !== schema.nodes.inputScaleLabelNotApplicable) {
                  actions.push({
                    label: translation(
                      "SCALE_LABELS.ACTIONS.ENABLE_NOT_APPLICABLE"
                    ),
                    action: "enableNotApplicable",
                    disabled: false
                  });
                }
              }

              actions.forEach(({ label, action, disabled }) => {
                const dropdownItem = document.createElement("button");
                dropdownItem.contentEditable = "false";
                dropdownItem.disabled = disabled;
                dropdownItem.className = DROPDOWN_ITEM_CLASS;
                dropdownItem.textContent = label;
                dropdownItem.setAttribute("data-action", action);
                dropdownContent.appendChild(dropdownItem);
              });
              dropdown.appendChild(dropdownToggle);
              dropdown.appendChild(dropdownContent);

              return dropdown;
            });

            decorations.push(columnControl);
          }
        });

        return DecorationSet.create(doc, decorations);
      },
      handleDOMEvents: {
        mousedown(view, event) {
          let pluginState = key.getState(view.state);
          if (pluginState == null) {
            return false;
          }

          const target = event.target as Element;
          const targetIsTableControls =
            target.classList.contains(CONTROLS_CLASS) ||
            target.closest(`.${ACTIONS_CLASS}`) != null;

          if (targetIsTableControls) {
            event.preventDefault();
            return true;
          }

          return false;
        },
        click(view, event) {
          let pluginState = key.getState(view.state);
          if (pluginState == null) {
            return false;
          }

          const target = event.target as Element;
          const controls = target.closest<HTMLElement>(`.${CONTROLS_CLASS}`);
          const actions = target.closest<HTMLElement>(`.${ACTIONS_CLASS}`);

          if (controls != null) {
            const pos = parseInt(
              (controls.parentNode as HTMLElement).getAttribute("data-index")!,
              10
            );
            setControls(pos)(view.state, view.dispatch);
          } else if (pluginState.controls !== false && actions != null) {
            const { opened } = pluginState.controls;
            const dropdownItem = target.closest(`.${DROPDOWN_ITEM_CLASS}`);

            setOpened(opened)(view.state, view.dispatch);

            if (dropdownItem) {
              const index = parseInt(actions.getAttribute("data-index")!, 10);
              const action = dropdownItem.getAttribute("data-action");

              switch (action) {
                case "addBefore":
                  addScaleLabel({
                    index: index,
                    direction: "before"
                  })(view.state, view.dispatch);
                  break;

                case "addAfter":
                  addScaleLabel({
                    index: index,
                    direction: "after"
                  })(view.state, view.dispatch);
                  break;

                case "delete":
                  removeScaleLabel({
                    index: index
                  })(view.state, view.dispatch);
                  break;

                case "enableNotApplicable":
                  enableNotApplicable({
                    index: index
                  })(view.state, view.dispatch);
                  break;
              }
            }

            event.preventDefault();
            return true;
          }

          return false;
        }
      }
    }
  });
}
