import { NodeSpec, Schema } from "prosemirror-model";
import {
  EditorState,
  NodeSelection,
  Plugin,
  Selection
} from "prosemirror-state";
import { Decoration, DecorationSet, EditorView } from "prosemirror-view";
import {
  CommandConfiguration,
  CommandConfigurations,
  DocumentBuilders,
  Editor,
  Extension,
  NodeConfig
} from "../../editor";
import { emitNotification } from "../../editor/plugins/notification";
import { selectionFocusKey } from "../../editor/plugins/selection-focus";
import { canInsert } from "../../util/selection";
import { ImageNodeView } from "./image-node-view";
import { imageDataUrl, isImage } from "./util";

export enum ImageErrors {
  DropInvalid = "image.drop.invalid-file-type",
  PasteInvalid = "image.paste.invalid-file-type",
  InsertInvalid = "image.insert.invalid-file-type",
  InsertUploadFailed = "image.insert.upload-failed"
}

const MIN_WIDTH = 5;
const MAX_WIDTH = 100;

class ImageNode implements NodeConfig {
  get name(): string {
    return "image";
  }

  get spec(): NodeSpec {
    return {
      inline: true,
      attrs: {
        src: {},
        width: { default: null },
        alt: {
          default: null
        },
        redirectUrl: {
          default: null
        }
      },
      group: "inline",
      draggable: true,
      selectable: true,
      focusable: true,
      parseDOM: [
        {
          tag: "img[src]",
          getAttrs: (node) => {
            const element = node as HTMLImageElement;

            const src = element.getAttribute("src");
            const alt = element.getAttribute("alt");
            const width = element.style.width.includes("%")
              ? parseInt(element.style.width)
              : undefined;
            const redirectUrl = element.getAttribute("data-redirect-url");

            return {
              src: src,
              alt: alt,
              width: width,
              redirectUrl: redirectUrl
            };
          }
        }
      ],
      toDOM(node) {
        return [
          "img",
          {
            src: node.attrs.src,
            alt: node.attrs.alt,
            "data-redirect-url": node.attrs.redirectUrl,
            style: `width: ${node.attrs.width}%`
          }
        ];
      }
    };
  }

  get builders(): DocumentBuilders {
    return {
      img: { nodeType: "image", src: "https://test.com" }
    };
  }
}

type ImageSchema = Schema<"image", any>;

const imagePlaceholderPlugin = new Plugin<DecorationSet, ImageSchema>({
  state: {
    init() {
      return DecorationSet.empty;
    },
    apply(tr, set) {
      // Adjust decoration positions to changes made by the transaction
      set = set.map(tr.mapping, tr.doc);
      // See if the transaction adds or removes any placeholders
      const action = tr.getMeta(this);

      if (action && action.add) {
        const src = action.add.src;
        const img = () => {
          const container = document.createElement("div");
          container.style.display = "inline-block";
          container.style.position = "relative";
          container.style.lineHeight = "0";
          container.style.minWidth = `${MIN_WIDTH}%`;
          container.style.maxWidth = `${MAX_WIDTH}%`;
          container.style.opacity = "0.5";

          const img = document.createElement("img");
          img.src = src;
          img.style.width = "100%";
          img.style.paddingLeft = "4px";
          img.style.paddingRight = "4px";

          container.appendChild(img);

          return container;
        };

        const deco = Decoration.widget(action.add.pos, img, {
          id: action.add.id
        });
        set = set.add(tr.doc, [deco]);
      } else if (action && action.remove) {
        set = set.remove(
          set.find(undefined, undefined, (spec) => spec.id === action.remove.id)
        );
      }
      return set;
    }
  },
  props: {
    decorations(state) {
      return this.getState(state);
    }
  }
});

function findPlaceholder(state: EditorState<ImageSchema>, id: {}) {
  let decos = imagePlaceholderPlugin.getState(state);
  let found = decos.find(undefined, undefined, (spec) => spec.id === id);
  return found.length ? found[0].from : null;
}

class ImagePlugin extends Plugin<null, ImageSchema> {
  constructor() {
    super({
      props: {
        nodeViews: {
          image: (node, view, getPos) => {
            return new ImageNodeView(
              node,
              view,
              getPos as () => number,
              MIN_WIDTH,
              MAX_WIDTH
            );
          }
        },
        handleDOMEvents: {
          drop(view, event) {
            const dragEvent = event as DragEvent;
            const { dataTransfer } = dragEvent;
            const onError = () => {
              emitNotification(view.state, {
                type: "error",
                message: ImageErrors.DropInvalid
              });
            };

            const posAtCoords = view.posAtCoords({
              left: event.clientX,
              top: event.clientY
            });

            return ImagePlugin.onEvent(
              dataTransfer,
              view,
              onError,
              event,
              posAtCoords
            );
          },
          paste(view, event) {
            const pasteEvent = event as ClipboardEvent;
            const { clipboardData } = pasteEvent;
            const onError = () => {
              emitNotification(view.state, {
                type: "error",
                message: ImageErrors.PasteInvalid
              });
            };

            return ImagePlugin.onEvent(clipboardData, view, onError, event);
          }
        }
      }
    });
  }

  private static onEvent(
    dataTransfer: DataTransfer | null,
    view: EditorView<ImageSchema>,
    error: () => void,
    event: Event,
    posAtCoords?: { pos: number; inside: number } | null
  ) {
    if (dataTransfer == null) {
      return false;
    }

    // word for mac has images in the clipboard when pasting.
    // check that the clipboard data only has plain text and files type.
    const isOnlyFile = dataTransfer.types.every((type) =>
      ["text/plain", "Files"].includes(type)
    );

    if (!isOnlyFile) {
      return false;
    }

    const files = dataTransfer.files;
    const hasFiles = files.length > 0;

    if (!hasFiles) {
      return false;
    }

    event.preventDefault();

    const images = Array.from(files).filter((file) => isImage(file));

    if (images.length === 0) {
      error();
      return false;
    }

    if (posAtCoords != null) {
      let tr = view.state.tr;
      tr = tr.setSelection(Selection.near(tr.doc.resolve(posAtCoords.pos)));
      view.dispatch(tr);
    }

    const editor = view as Editor<ImageSchema>;

    images.forEach((file) => {
      editor.commands.insertImage.execute({ file: file });
    });

    return true;
  }
}

export interface InsertImageProps {
  file: File;
}

export interface ImageActiveValue {
  src: string;
  width: number | null;
  alt: string | null;
  redirectUrl: string | null;
  disableRedirect: boolean;
}

export interface UpdateImageCommandProps {
  alt?: string;
  width?: number;
  redirectUrl?: string;
}

export class Image implements Extension<ImageSchema> {
  constructor(private uploadHandler: (file: File) => Promise<string>) {}

  get name() {
    return "image";
  }

  get nodes() {
    return [new ImageNode()];
  }

  plugins(): Plugin[] {
    return [imagePlaceholderPlugin, new ImagePlugin()];
  }

  commands(schema: ImageSchema): CommandConfigurations<ImageSchema> {
    return {
      insertImage: this.insertImageCommand(schema),
      updateImage: this.updateImageCommand(schema)
    };
  }

  private insertImageCommand(
    schema: ImageSchema
  ): CommandConfiguration<ImageSchema, InsertImageProps, undefined> {
    return {
      isActive: () => false,
      isEnabled: () => {
        return (state) => {
          const focused = focusedInlineNode(state);
          if (focused != null) {
            return true;
          } else {
            return canInsert(schema.nodes.image)(state);
          }
        };
      },
      execute: (props) => {
        return (state, dispatch, view) => {
          if (props == null) {
            throw new Error(
              `To insert an image, InsertImageProps needs to be provided.`
            );
          }

          const { file } = props;

          if (file == null) {
            throw new Error(
              `To insert an image, file needs to be provided for InsertImageProps.`
            );
          }

          if (!isImage(file)) {
            emitNotification(state, {
              type: "error",
              message: ImageErrors.InsertInvalid
            });
            return false;
          }

          if (dispatch && view) {
            // A fresh object to act as the ID for this upload
            let id = {};

            // Replace the selection with a placeholder
            let tr = state.tr;
            if (!tr.selection.empty) {
              tr.deleteSelection();
            }

            imageDataUrl(file)
              .then(async (src) => {
                tr.setMeta(imagePlaceholderPlugin, {
                  add: {
                    id,
                    src: src,
                    pos: tr.selection.from
                  }
                });
                dispatch(tr);

                const url = await this.uploadHandler(file);

                let pos = findPlaceholder(view.state, id);
                // If the content around the placeholder has been deleted, drop
                // the image
                if (pos == null) {
                  return;
                }
                // Otherwise, insert it at the placeholder's position, and remove
                // the placeholder
                let insertImageTransaction = view.state.tr;
                insertImageTransaction = insertImageTransaction.replaceWith(
                  pos,
                  pos,
                  view.state.schema.nodes.image.create({
                    src: url
                  })
                );
                insertImageTransaction = insertImageTransaction.setSelection(
                  new NodeSelection(insertImageTransaction.doc.resolve(pos))
                );
                insertImageTransaction = insertImageTransaction.setMeta(
                  imagePlaceholderPlugin,
                  { remove: { id } }
                );

                dispatch(insertImageTransaction);
              })
              .catch(() => {
                // On failure, just clean up the placeholder
                dispatch(
                  tr.setMeta(imagePlaceholderPlugin, { remove: { id } })
                );

                emitNotification(state, {
                  type: "error",
                  message: ImageErrors.InsertUploadFailed
                });
              });
          }

          return true;
        };
      }
    };
  }

  private updateImageCommand(
    _schema: ImageSchema
  ): CommandConfiguration<
    ImageSchema,
    UpdateImageCommandProps,
    ImageActiveValue | undefined
  > {
    return {
      isActive: () => {
        return (state) => {
          return focusedImage(state) != null;
        };
      },
      isEnabled: () => {
        return (state) => {
          return focusedImage(state) != null;
        };
      },
      execute: (props) => {
        return (state, dispatch) => {
          if (props == null) {
            throw new Error(
              `To update an image, UpdateImageCommandProps needs to be provided.`
            );
          }

          const focused = focusedImage(state);
          if (!focused) {
            return false;
          }

          if (dispatch) {
            const { node, pos } = focused;
            const updatedAttrs = { ...node.attrs };

            if (props?.alt != null) {
              updatedAttrs.alt = props.alt.length === 0 ? null : props.alt;
            }

            if (props?.width != null && !isNaN(props?.width)) {
              updatedAttrs.width = props.width;
            }

            if (props?.redirectUrl != null) {
              updatedAttrs.redirectUrl =
                props.redirectUrl.length === 0 ? null : props.redirectUrl;
            }

            let tr = state.tr;
            tr = tr.setNodeMarkup(pos, undefined, updatedAttrs);
            tr = tr.setSelection(new NodeSelection(tr.doc.resolve(pos)));

            dispatch(tr);
          }

          return true;
        };
      },
      activeValue: () => {
        return (state) => {
          const focused = focusedImage(state);
          if (!focused) {
            return undefined;
          }

          const { node, pos } = focused;
          const $pos = state.doc.resolve(pos);

          const disableImageRedirect =
            $pos.parent.type.spec.disableImageRedirect === true;

          return {
            src: node.attrs.src,
            alt: node.attrs.alt,
            width: node.attrs.width,
            redirectUrl: node.attrs.redirectUrl,
            disableRedirect: disableImageRedirect
          };
        };
      }
    };
  }
}

function focusedInlineNode(state: EditorState<ImageSchema>) {
  const { schema } = state;

  const focused = selectionFocusKey.getState(state);
  if (
    focused != null &&
    focused.node.isInline &&
    focused.node.type !== schema.nodes.image
  ) {
    return focused;
  } else {
    return undefined;
  }
}

function focusedImage(state: EditorState<ImageSchema>) {
  const { schema } = state;

  const focused = selectionFocusKey.getState(state);
  if (focused != null && focused.node.type === schema.nodes.image) {
    return focused;
  } else {
    return undefined;
  }
}
