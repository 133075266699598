import {
  Command,
  Editor as BlueXEditor,
  Schema,
  VideoActiveValue
} from "@blue-x/editor";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import ToolbarDropdown, {
  ToolbarDropdownToggle,
  useToolbarDropdown
} from "../ToolbarDropdown";
import ToolbarIcon from "../ToolbarIcon";
import ToolbarTooltip from "../ToolbarTooltip";
import "./Video.css";

interface VideoPropertiesPanelProps {
  editor: BlueXEditor<Schema>;
}

function limit(value: number, min: number, max: number): number {
  return Math.min(Math.max(Math.floor(value), min), max);
}

export const VideoPropertiesPanel: React.FC<VideoPropertiesPanelProps> = (
  props
) => {
  const { editor } = props;
  const { commands } = editor;

  const activeValue: VideoActiveValue = useMemo(() => {
    return commands.updateVideo.activeValue();
  }, [commands]);

  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(activeValue.width);
  }, [activeValue, setWidth]);

  const updateWidth = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const min = parseInt(event.target.min);
      const max = parseInt(event.target.max);
      const value = event.target.valueAsNumber;

      if (!isNaN(value)) {
        setWidth(limit(value, min, max));
      }
    },
    [setWidth]
  );

  const updateCommand = useCallback(() => {
    commands.updateVideo.execute({ width: width }, false);
  }, [commands, width]);

  const src = useMemo(() => {
    return activeValue.src;
  }, [activeValue]);

  return (
    <div className="VideoPropertiesPanel">
      <label>Video</label>
      <input type="text" placeholder="Src" value={src} disabled={true} />
      <input
        type="number"
        min="5"
        max="100"
        step="1"
        placeholder="Width"
        value={width}
        onChange={updateWidth}
        onBlur={updateCommand}
      />
    </div>
  );
};

interface InsertVideoProps {
  command: Command;
}

const InsertVideo: React.FC<InsertVideoProps> = (props) => {
  const { command } = props;
  const dropdown = useToolbarDropdown();

  const [url, setUrl] = useState("");

  const urlChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setUrl(event.target.value);
    },
    [setUrl]
  );

  const insertUrl = useCallback(() => {
    command.execute({ url: url });

    const { opened, setOpened } = dropdown;
    setOpened(!opened);
  }, [url, command, dropdown]);

  const insertUrlDisabled = useMemo(() => {
    return url == null || url.length < 1;
  }, [url]);

  return (
    <div className="InsertVideo">
      <div>
        <input
          type="text"
          placeholder="url"
          onChange={urlChange}
          value={url}
        ></input>
        <button onClick={insertUrl} disabled={insertUrlDisabled}>
          Insert
        </button>
      </div>
    </div>
  );
};

interface ToolbarFileButtonProps {
  editor: BlueXEditor<Schema>;
}

export const ToolbarVideoButton: React.FC<ToolbarFileButtonProps> = (props) => {
  const { editor } = props;
  const { commands } = editor;

  return (
    <ToolbarDropdown
      toggle={
        <ToolbarTooltip title="Video" subtitle="Add a video.">
          <ToolbarDropdownToggle
            active={commands.insertVideo?.isActive()}
            disabled={!commands.insertVideo?.isEnabled()}
          >
            <ToolbarIcon icon="video" />
          </ToolbarDropdownToggle>
        </ToolbarTooltip>
      }
      content={<InsertVideo command={commands.insertVideo} />}
    />
  );
};
