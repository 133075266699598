import { Node, Schema } from "prosemirror-model";
import { AllSelection, EditorState, Transaction } from "prosemirror-state";
import { DecorationSet } from "prosemirror-view";

export function removeDecorations<S extends Schema>(
  decorationSet: DecorationSet<S>,
  from: number,
  to: number
): DecorationSet<S> {
  const toRemove = decorationSet.find(from, to).filter((decoration) => {
    return decoration.from === from && decoration.to === to;
  });

  return decorationSet.remove(toRemove);
}

export function initDecorations<S extends Schema>(
  state: EditorState<S>,
  init: (
    doc: Node<S>,
    start: number,
    end: number,
    decorationSet: DecorationSet<S>
  ) => DecorationSet<S>
): DecorationSet<S> {
  const { doc } = state;
  const all = new AllSelection(doc);
  return init(doc, all.from, all.to, DecorationSet.empty);
}

export function updateDecorations<S extends Schema>(
  tr: Transaction<S>,
  decorationSet: DecorationSet<S>,
  update: (
    doc: Node<S>,
    start: number,
    end: number,
    decorationSet: DecorationSet<S>
  ) => DecorationSet<S>
): DecorationSet<S> {
  //TODO: review mapping of decorations after an append transaction
  if (tr.docChanged) {
    try {
      let updatedDecorationSet = decorationSet.map(tr.mapping, tr.doc);

      const all = new AllSelection(tr.doc);

      tr.steps.forEach((step) => {
        step.getMap().forEach((_oldStart, _oldEnd, newStart, newEnd) => {
          const isInsideDoc = newStart >= all.from && newEnd <= all.to;
          if (isInsideDoc) {
            updatedDecorationSet = update(
              tr.doc,
              newStart,
              newEnd,
              updatedDecorationSet
            );
          }
        });
      });

      return updatedDecorationSet;
    } catch {
      const { doc } = tr;
      const all = new AllSelection(doc);
      return update(doc, all.from, all.to, DecorationSet.empty);
    }
  } else {
    return decorationSet;
  }
}
