export enum Side {
  LEFT = "left",
  RIGHT = "right"
}

export function isLeftCursor(side: Side): side is Side.LEFT {
  return side === Side.LEFT;
}

export function isRightCursor(side: Side): side is Side.RIGHT {
  return side === Side.RIGHT;
}
