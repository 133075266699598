import { Node, Schema } from "prosemirror-model";
import { Plugin, PluginKey } from "prosemirror-state";
import { Decoration, DecorationSet, EditorView } from "prosemirror-view";
import {
  getLetterFromIndex,
  IdGenerator,
  UnreachableCaseError
} from "../../../util";
import {
  isSelectionRemoveButton,
  selectionOverlay
} from "../selection-overlay";
import {
  decorationForTarget,
  getNodeForTarget,
  isSelectionInTargetOverlay
} from "../selection-target";
import { RangeSelection } from "../types";
import { Block } from "./block";
import { BlockRange } from "./block-range";
import { BlockSelection } from "./block-selection";
import { BlockTemplate } from "./block-template";
import { TemplateRangeNamingStrategy } from "./types";

export interface StartSelectionAction {
  type: "start";
  rangeNamingStrategy: TemplateRangeNamingStrategy;
  includeChoices: boolean;
  includeHeaderFooterRow: boolean;
}

export interface ReplaceSelectionAction {
  type: "replace";
  selection: BlockSelection;
  emit: boolean;
}

export interface UpdateSequenceAction {
  type: "sequence";
  action: "start" | "focus" | "end";
  block?: Block;
  emit: boolean;
}

export interface RemoveRangeAction {
  type: "remove";
  id: string;
  emit: boolean;
}

export interface EndSelectionAction {
  type: "end";
}

const CLASS_NAMES = {
  Selector: "ProseMirror-block-selector"
};

interface BlockSelectorState {
  rangeNamingStrategy: TemplateRangeNamingStrategy;
  includeChoices: boolean;
  includeHeaderFooterRow: boolean;
  template: BlockTemplate;
  targets: Decoration[];
  selected: Decoration[];
  isCreatingSequence: boolean;
}

export const blockSelectorKey = new PluginKey<
  BlockSelectorState | null,
  Schema
>("blockSelector");

export function blockSelector(
  onChange: (ranges: RangeSelection[]) => void,
  idGenerator: IdGenerator
) {
  return new Plugin({
    key: blockSelectorKey,
    state: {
      init() {
        return null;
      },
      apply(tr, value, _oldState, newState) {
        const { schema } = newState;

        const meta = tr.getMeta(blockSelectorKey) as
          | StartSelectionAction
          | UpdateSequenceAction
          | RemoveRangeAction
          | ReplaceSelectionAction
          | EndSelectionAction
          | undefined;

        if (meta == null) {
          return value;
        }

        switch (meta.type) {
          case "start":
            const {
              rangeNamingStrategy,
              includeChoices,
              includeHeaderFooterRow
            } = meta;
            const targets = decorationsForStart(
              tr.doc,
              schema,
              includeChoices,
              includeHeaderFooterRow
            );

            return {
              rangeNamingStrategy: rangeNamingStrategy,
              includeChoices: includeChoices,
              includeHeaderFooterRow: includeHeaderFooterRow,
              template: new BlockTemplate({
                root: tr.doc,
                selection: null,
                sequence: null,
                sequenceIdGenerator: idGenerator
              }),
              targets: targets,
              selected: [],
              isCreatingSequence: false
            };

          case "sequence":
            if (value != null) {
              const { template, rangeNamingStrategy } = value;

              switch (meta.action) {
                case "start":
                case "focus":
                case "end": {
                  const updatedTemplate = executeAction(
                    template,
                    meta.action,
                    meta.block
                  );

                  const updatedSelected = decorationsForSelectionRanges(
                    updatedTemplate.selection,
                    rangeNamingStrategy
                  );

                  if (meta.emit) {
                    onChange(
                      updatedTemplate.selection.ranges.map((r) => r.toJSON())
                    );
                  }

                  return {
                    ...value,
                    template: updatedTemplate,
                    selected: updatedSelected,
                    isCreatingSequence: meta.action === "end" ? false : true
                  };
                }

                default:
                  throw new UnreachableCaseError(meta.action);
              }
            } else {
              return null;
            }

          case "remove":
            if (value != null) {
              const { template, rangeNamingStrategy } = value;

              const updatedTemplate = template.removeSelectionRangeById(
                meta.id
              );
              const updatedSelected = decorationsForSelectionRanges(
                updatedTemplate.selection,
                rangeNamingStrategy
              );

              if (meta.emit) {
                onChange(
                  updatedTemplate.selection.ranges.map((r) => r.toJSON())
                );
              }

              return {
                ...value,
                template: updatedTemplate,
                selected: updatedSelected
              };
            } else {
              return null;
            }

          case "replace":
            if (value != null) {
              const { template, rangeNamingStrategy } = value;

              const updatedTemplate = template.replaceSelection(meta.selection);
              const updatedSelected = decorationsForSelectionRanges(
                updatedTemplate.selection,
                rangeNamingStrategy
              );

              if (meta.emit) {
                onChange(
                  updatedTemplate.selection.ranges.map((r) => r.toJSON())
                );
              }

              return {
                ...value,
                template: updatedTemplate,
                selected: updatedSelected
              };
            } else {
              return null;
            }

          case "end":
            return null;

          default:
            return value;
        }
      }
    },
    props: {
      decorations(state) {
        const value = this.getState(state);
        if (value == null) {
          return null;
        }

        const { doc } = state;
        const { targets, selected } = value;

        return DecorationSet.create(doc, targets.concat(selected));
      },
      attributes(state) {
        const value = this.getState(state);
        if (value == null) {
          return null;
        }

        let attributes: Record<string, string> = { "data-selector": "block" };

        if (value.isCreatingSequence) {
          attributes = {
            ...attributes,
            class: "ProseMirror-block-selector-dragging"
          };
        }

        return attributes;
      },
      handleDOMEvents: {
        mousedown(view, event) {
          const state = this.getState(view.state);
          if (state == null) {
            return false;
          }

          if (isSelectionRemoveButton(event.target as HTMLElement)) {
            return false;
          }

          if (!isSelectionInTargetOverlay(event.target as HTMLElement)) {
            return false;
          }

          return handleMouseDown(view, event as MouseEvent, state);
        }
      }
    }
  });
}

function decorationForNode(from: number, to: number): Decoration {
  return decorationForTarget(from, to, CLASS_NAMES.Selector);
}

function rangeLabelForIndex(
  index: number,
  rangeNamingStrategy: TemplateRangeNamingStrategy
): string {
  switch (rangeNamingStrategy) {
    case "alphabetic":
      return getLetterFromIndex(index);

    case "numeric":
      return `${index + 1}`;

    default:
      throw new UnreachableCaseError(rangeNamingStrategy);
  }
}

function decorationsForSelectionRanges(
  selection: BlockSelection,
  rangeNamingStrategy: TemplateRangeNamingStrategy
): Decoration[] {
  return selection.ranges.reduce((acc, r, index) => {
    const decoration = decorationForSelectionRange(
      r,
      rangeLabelForIndex(index, rangeNamingStrategy)
    );
    return decoration == null ? acc : acc.concat(decoration);
  }, new Array<Decoration>());
}

function getRectForElement(node: HTMLElement): DOMRect {
  if (node.nodeType === node.ELEMENT_NODE) {
    return node.getBoundingClientRect();
  } else {
    return getRectForElement(node.parentNode as HTMLElement);
  }
}

function getWidthAndHeight(
  start: HTMLElement,
  end: HTMLElement | null
): { width: number; height: number } {
  const startRect = getRectForElement(start);
  const startWidth = startRect.width;
  const startHeight = start.offsetHeight;

  if (end != null && start !== end) {
    const endRect = getRectForElement(end);
    return {
      width: Math.max(startRect.width, endRect.width),
      height: end.offsetTop - start.offsetTop + end.offsetHeight
    };
  } else {
    return {
      width: startWidth,
      height: startHeight
    };
  }
}

function decorationForSelectionRange(
  range: BlockRange,
  label: string
): Decoration | null {
  const { start, end } = range;
  if (start == null) {
    return null;
  }

  return Decoration.widget(start.pos, (view, getPos) => {
    const pos = getPos();
    const startNode = view.nodeDOM(pos)! as HTMLElement;

    const top = startNode.offsetTop;
    const left = startNode.offsetLeft;

    const { width, height } = getWidthAndHeight(
      startNode,
      end != null ? (view.nodeDOM(end.pos)! as HTMLElement) : null
    );

    const onClick = (id: string) => {
      const action: RemoveRangeAction = {
        type: "remove",
        id: id,
        emit: true
      };
      view.dispatch(view.state.tr.setMeta(blockSelectorKey, action));
    };

    return selectionOverlay(
      CLASS_NAMES.Selector,
      { id: range.id, label: label },
      width,
      height,
      top,
      left,
      onClick
    );
  });
}

function decorationsForStart(
  doc: Node,
  schema: Schema,
  includeChoices: boolean,
  includeHeaderFooterRow: boolean
): Decoration[] {
  const targets = new Array<Decoration>();

  doc.descendants((node, pos, parent) => {
    if (parent.type === schema.nodes.table) {
      if (node.type === schema.nodes.tableRow) {
        targets.push(decorationForNode(pos, pos + node.nodeSize));
      } else if (
        includeHeaderFooterRow &&
        [schema.nodes.tableHeaderRow, schema.nodes.tableFooterRow].includes(
          node.type
        )
      ) {
        targets.push(decorationForNode(pos, pos + node.nodeSize));
      }

      return false;
    }

    targets.push(decorationForNode(pos, pos + node.nodeSize));

    if (
      includeChoices &&
      [schema.nodes.inputChoice, schema.nodes.inputScale].includes(node.type)
    ) {
      return;
    }

    if (node.type === schema.nodes.table) {
      return;
    }

    return false;
  });

  return targets;
}

function executeAction(
  template: BlockTemplate,
  action: "start" | "focus" | "end",
  block: Block | undefined
): BlockTemplate {
  switch (action) {
    case "start":
      return block != null ? template.beginSequence(block) : template;
    case "focus":
      return block != null ? template.sequenceFocusOn(block) : template;
    case "end":
      return template.endSequence();
    default:
      throw new UnreachableCaseError(action);
  }
}

const LEFT_BUTTON = 0;

function handleMouseDown(
  view: EditorView,
  event: MouseEvent,
  state: BlockSelectorState
): boolean {
  if (state == null) {
    return false;
  }

  if (event.button !== LEFT_BUTTON) {
    return false;
  }

  const down = getNodeForTarget(view, event.target as HTMLElement);
  const start: UpdateSequenceAction = {
    type: "sequence",
    action: "start",
    block: down,
    emit: false
  };
  view.dispatch(view.state.tr.setMeta(blockSelectorKey, start));

  function finish(_event: MouseEvent) {
    window.removeEventListener("mouseup", finish);
    window.removeEventListener("mousemove", move);

    const end: UpdateSequenceAction = {
      type: "sequence",
      action: "end",
      emit: true
    };
    view.dispatch(view.state.tr.setMeta(blockSelectorKey, end));
  }

  function move(event: MouseEvent) {
    if (event.button !== LEFT_BUTTON) {
      return finish(event);
    }

    const state = blockSelectorKey.getState(view.state);
    if (state == null) {
      return;
    }

    const parent = state.template.sequence?.anchor;
    const target = getNodeForTarget(view, event.target as HTMLElement, parent);
    if (target == null) {
      return;
    }

    const focus: UpdateSequenceAction = {
      type: "sequence",
      action: "focus",
      block: target,
      emit: false
    };
    view.dispatch(view.state.tr.setMeta(blockSelectorKey, focus));
  }

  window.addEventListener("mouseup", finish);
  window.addEventListener("mousemove", move);
  event.preventDefault();

  return true;
}
