import { Node, NodeType, Schema } from "prosemirror-model";
import { Decoration, EditorView, NodeView } from "prosemirror-view";
import { AlignmentType } from "../../../extensions/alignment";
import { findParentNodeOfType, ZeroWidthSpace } from "../../../util";

export class InputRankOptionNodeView<S extends Schema> implements NodeView<S> {
  dom: HTMLElement;
  contentDOM: HTMLElement;

  constructor(
    private node: Node<S>,
    private view: EditorView<S>,
    _decorations: Decoration[]
  ) {
    const option = document.createElement("input-rank-option");

    const { state } = this.view;

    const parent = findParentNodeOfType(
      state.schema.nodes.inputRank as NodeType<S>
    )(state.selection);

    const { bullet, content } = inputRankOptionBuilder();

    option.appendChild(bullet);
    option.appendChild(content);

    this.dom = option;
    this.contentDOM = content;

    if (parent) {
      const sections = parent.node.child(0);

      sections.forEach((section) => {
        const { dropdownContainer } = inputRankOptionBuilder();
        if (section.type !== state.schema.nodes.inputRankSectionEmpty) {
          this.dom.appendChild(dropdownContainer);
        }
      });
    }

    this.updateId(node);
    this.updateAlignment(node.attrs.alignment);
  }

  update(node: Node<S>, _decorations: Decoration[]): boolean {
    if (node.type !== this.node.type) {
      return false;
    }

    const { state } = this.view;

    const parent = findParentNodeOfType<S>(
      state.schema.nodes.inputRank as NodeType<S>
    )(state.selection);

    this.dom.childNodes.forEach((child) => {
      if (child.nodeName === "INPUT-RANK-DROPDOWN-CONTAINER") {
        this.dom.removeChild(child);
      }
    });

    if (parent) {
      const sections = parent.node.child(0);

      sections.forEach((section) => {
        const { dropdownContainer } = inputRankOptionBuilder();
        if (section.type !== state.schema.nodes.inputRankSectionEmpty) {
          this.dom.appendChild(dropdownContainer);
        }
      });
    }

    this.updateId(node);
    this.updateAlignment(node.attrs.alignment);

    return true;
  }

  private updateId(node: Node<S>): void {
    this.dom.id = node.attrs.id;
  }

  private updateAlignment(alignment: AlignmentType): void {
    if (alignment != null) {
      this.dom.setAttribute("data-alignment", alignment);
    } else {
      this.dom.removeAttribute("data-alignment");
    }
  }
}

export class InputRankOptionOtherSpecifyNodeView<S extends Schema>
  implements NodeView<S> {
  dom: HTMLElement;
  contentDOM: HTMLElement;

  constructor(private node: Node<S>) {
    const container = document.createElement("input-rank-option-other-specify");
    const {
      bullet,
      content,
      otherContainer,
      inputText
    } = inputRankOptionOtherSpecifyBuilder();

    this.dom = container;

    container.appendChild(bullet);
    otherContainer.appendChild(content);
    otherContainer.appendChild(inputText);
    container.appendChild(otherContainer);

    this.contentDOM = content;
    this.updateId(node);
    this.updateAlignment(node.attrs.alignment);
  }

  update(node: Node<S>, _decorations: Decoration[]): boolean {
    if (node.type !== this.node.type) {
      return false;
    }

    this.updateId(node);
    this.updateAlignment(node.attrs.alignment);
    return true;
  }

  private updateId(node: Node<S>): void {
    this.dom.id = node.attrs.id;
  }

  private updateAlignment(alignment: AlignmentType): void {
    if (alignment != null) {
      this.dom.setAttribute("data-alignment", alignment);
    } else {
      this.dom.removeAttribute("data-alignment");
    }
  }
}

function inputRankOptionBuilder(): {
  bullet: HTMLElement;
  content: HTMLElement;
  dropdownContainer: HTMLElement;
} {
  const bullet = document.createElement("div");
  bullet.className = "bullet";
  bullet.contentEditable = "false";
  bullet.appendChild(document.createTextNode(ZeroWidthSpace));

  const content = document.createElement("div");
  content.className = "content";

  const dropdownContainer = document.createElement(
    "input-rank-dropdown-container"
  );
  dropdownContainer.contentEditable = "false";

  const dropdown = document.createElement("input-rank-dropdown");
  const caret = document.createElement("span");
  caret.className = "bx bx-caret";

  dropdown.appendChild(caret);
  dropdownContainer.appendChild(dropdown);

  return { bullet, content, dropdownContainer };
}

function inputRankOptionOtherSpecifyBuilder(): {
  bullet: HTMLElement;
  content: HTMLElement;
  otherContainer: HTMLElement;
  inputText: HTMLElement;
} {
  const bullet = document.createElement("div");
  bullet.className = "bullet";
  bullet.contentEditable = "false";
  bullet.appendChild(document.createTextNode(ZeroWidthSpace));

  const content = document.createElement("div");
  content.className = "content";

  const otherContainer = document.createElement("div");
  otherContainer.className = "other-container";

  const inputText = document.createElement("div");
  inputText.className = "inputText";
  inputText.contentEditable = "false";

  return { bullet, content, otherContainer, inputText };
}
