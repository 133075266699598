import { Fragment, Schema } from "prosemirror-model";
import { Plugin, TextSelection, Transaction } from "prosemirror-state";
import { findChildren, posFromEvent } from "../../../util";
import { findNodePos } from "../../../util/transforms";
import { getTranslation } from "../../localization/util";
import { getIdGenerator } from "../../node-identifier/node-identifier";
import { InputRankControlType, InputRankSchema } from "../schema";
import { createInputRankWithContent } from "../util";

export function dropPlugin<S extends Schema>(schema: InputRankSchema) {
  return new Plugin<boolean, S>({
    props: {
      handleDrop(view, event, slice) {
        const dropEvent = event as DragEvent;

        const target = posFromEvent(dropEvent, view, slice);
        if (target == null) {
          return false;
        }

        const { state } = view;

        const $pos = state.doc.resolve(target);

        if ($pos.parent.type === schema.nodes.inputRankSection) {
          const inputRankFromSlice = slice.content.firstChild;

          if (inputRankFromSlice == null) {
            return false;
          }

          if (inputRankFromSlice.type !== schema.nodes.inputRank) {
            return false;
          }

          const sectionsNode = findChildren(inputRankFromSlice, (node) => {
            return node.type === schema.nodes.inputRankSections;
          });

          const optionsNode = findChildren(inputRankFromSlice, (node) => {
            return node.type === schema.nodes.inputRankOptions;
          });

          if (sectionsNode.length === 0 && optionsNode.length === 0) {
            return false;
          } else {
            const inputRankFromSlice = slice.content.firstChild;

            if (inputRankFromSlice == null) {
              return false;
            }

            if (inputRankFromSlice.type !== schema.nodes.inputRank) {
              return false;
            }

            const sections = findChildren(inputRankFromSlice, (node) => {
              return node.type === schema.nodes.inputRankSection;
            });

            const options = findChildren(inputRankFromSlice, (node) => {
              return (
                node.type === schema.nodes.inputRankOption ||
                node.type === schema.nodes.inputRankOptionOtherSpecify ||
                node.type === schema.nodes.inputRankOptionSelectAll
              );
            });

            let fragments = new Array<Fragment<InputRankSchema>>();
            sections.forEach((section) => {
              fragments.push(section.node.content);
            });

            options.forEach((option) => {
              fragments.push(option.node.content);
            });

            let tr = state.tr as Transaction;

            let size = 0;
            fragments.reverse().forEach((f) => {
              tr = tr.insert($pos.pos, f);
              size += f.size;
            });
            const { $from, $to } = tr.selection;

            tr = tr.setSelection(
              TextSelection.near(tr.doc.resolve($pos.pos + size))
            );
            tr = tr.deleteRange($from.pos, $to.pos);

            view.dispatch(tr);
            return true;
          }
        }

        if ($pos.parent.type !== schema.nodes.paragraph) {
          return false;
        }

        let hasOnlyRank = true;

        slice.content.forEach((node) => {
          if (node.type !== schema.nodes.inputRank) {
            hasOnlyRank = false;
          }
        });

        if (!hasOnlyRank) {
          return false;
        } else {
          const inputRankFromSlice = slice.content.firstChild;

          if (inputRankFromSlice == null) {
            return false;
          }

          if (inputRankFromSlice.type !== schema.nodes.inputRank) {
            return false;
          }

          const options = findChildren(inputRankFromSlice, (node) => {
            return (
              node.type === schema.nodes.inputRankOption ||
              node.type === schema.nodes.inputRankOptionOtherSpecify ||
              node.type === schema.nodes.inputRankOptionSelectAll
            );
          });

          let nodes = options.map((nodeWithPos) => {
            return nodeWithPos.node;
          });

          const node = createInputRankWithContent(
            schema,
            InputRankControlType.dragDrop,
            getTranslation(state),
            getIdGenerator(state),
            nodes
          );

          let tr = state.tr as Transaction;

          tr = tr.setSelection(TextSelection.near($pos));
          tr = tr.replaceSelectionWith(node, false);

          const $insertPos = findNodePos(tr.doc, tr.selection.from, node);
          if ($insertPos != null) {
            tr = tr.setSelection(
              TextSelection.near(
                tr.doc.resolve($insertPos.pos + node.nodeSize - 3)
              )
            );
          }

          view.dispatch(tr);

          return true;
        }
      }
    }
  });
}
