import { ChangeSet } from "prosemirror-changeset";
import { Node, NodeType, Schema } from "prosemirror-model";
import {
  EditorState,
  NodeSelection,
  Plugin,
  PluginKey,
  Transaction
} from "prosemirror-state";
import { getTranslation } from "../../../extensions/localization";
import { findChildren } from "../../../util/nodes";

export interface QuestionTitleCache {
  titleKey: string;
  count: number;
}

function isEmpty(value: string | null): boolean {
  if (value == null) {
    return true;
  } else {
    return value.length === 0;
  }
}

export function questionTitleCacheKey() {
  return new PluginKey<QuestionTitleCache, Schema>();
}

export const questionTitlePredicate = (node: Node) => {
  return (
    node.type.spec.attrs?.questionTitleText != null &&
    isEmpty(node.attrs.questionTitleText)
  );
};

export const applyQuestionTitle = (node: Node, name: string) => {
  return {
    ...node.attrs,
    questionTitleText: name
  };
};

export const namePredicate = (node: Node) => {
  return node.type.spec.attrs?.name != null && isEmpty(node.attrs.name);
};

export const applyName = (node: Node, name: string) => {
  return {
    ...node.attrs,
    name: name
  };
};

export function questionTitleCachePlugin<S extends Schema>(
  type: NodeType<S>,
  translationKey: string,
  predicate: (node: Node<S>) => boolean,
  apply: (node: Node<S>, name: string) => { [key: string]: any },
  key: PluginKey<QuestionTitleCache, S>
) {
  return new Plugin<QuestionTitleCache, S>({
    key: key,
    state: {
      init(_, state) {
        const inputs = findChildren(state.doc, (node) => node.type === type);
        return { titleKey: translationKey, count: inputs.length };
      },
      apply(tr, value) {
        const meta = tr.getMeta(key) as { count: number } | undefined;

        if (meta === undefined) {
          return value;
        }

        return { ...value, count: meta.count };
      }
    },
    appendTransaction(
      transactions: Array<Transaction<S>>,
      oldState: EditorState<S>,
      newState: EditorState<S>
    ) {
      if (!transactions.find((tr) => tr.docChanged)) {
        return;
      }

      if (transactions.length === 0) {
        return;
      }

      const cache = key.getState(newState);
      if (cache == null) {
        return;
      }

      const translation = getTranslation(newState);

      let changeset = ChangeSet.create(oldState.doc);

      transactions.forEach((tr) => {
        changeset = changeset.addSteps(tr.doc, tr.mapping.maps);
      });

      if (changeset.changes.length === 0) {
        return;
      }

      let tr = newState.tr;
      const nodeSelection =
        tr.selection instanceof NodeSelection ? tr.selection.from : null;

      let count = cache.count;

      changeset.changes.forEach((change) => {
        if (change.inserted.length > 0) {
          const { fromB, toB } = change;

          newState.doc.nodesBetween(fromB, toB, (node, pos) => {
            if (node.type === type && predicate(node)) {
              const name = translation(cache.titleKey, {
                count: `${count + 1}`
              });
              tr = tr.setNodeMarkup(
                pos,
                undefined,
                apply(node, name),
                node.marks
              );
              count = count + 1;
            }
          });
        }
      });

      if (count !== cache.count) {
        tr = tr.setMeta(key, { count: count });
      }

      if (nodeSelection != null) {
        tr = tr.setSelection(new NodeSelection(tr.doc.resolve(nodeSelection)));
      }

      return tr;
    }
  });
}
