import { Node, NodeSpec, Fragment, DOMParser } from "prosemirror-model";
import { DocumentBuilders, NodeConfig } from "../../../editor";
import { InputScaleSchema } from "../schema";

export class InputScaleLabelsNode implements NodeConfig {
  get name(): string {
    return "inputScaleLabels";
  }

  get spec(): NodeSpec {
    return {
      group: "gridHeaderInput",
      content: "inputScaleLabel* inputScaleLabelNotApplicable{0,1}",
      draggable: true,
      selectable: true,
      focusable: false,
      allowGapCursor: true,
      allowIndentation: false,
      allowAlignment: ["left", "center", "right"],
      blockAlignment: true,
      isolating: true,
      attrs: { id: { default: null } },
      parseDOM: [
        {
          tag: "input-scale-labels",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getAttrs(node as Element);
          }
        },
        {
          tag: "[data-smart-table-likert-labels]",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getAttrs(node as Element);
          },
          getContent: (node, schema) => {
            return legacyValues(node as Element, schema);
          }
        }
      ],
      toDOM(node) {
        return ["input-scale-labels", setAttrs(node), 0];
      }
    };
  }

  get builders(): DocumentBuilders {
    return { inputScaleLabels: { nodeType: "inputScaleLabels" } };
  }
}

function getAttrs(element: Element): { [key: string]: any } {
  const attrs: {
    id?: string;
  } = {};

  const id = element.getAttribute("id");
  if (id != null) {
    attrs.id = id;
  }

  return attrs;
}

function setAttrs(node: Node): { [key: string]: any } {
  const id = node.attrs.id as string;

  return {
    id: id
  };
}

function legacyValues(element: Element, schema: InputScaleSchema): Fragment {
  const parser = DOMParser.fromSchema(schema);
  const options = { topNode: schema.nodes.inputScaleLabels.create() };
  const node = parser.parse(element, options);
  const fragment = node.content;

  let updated = Fragment.from(fragment);

  const parentId = element.getAttribute("id");
  if (parentId != null) {
    fragment.forEach((node, _offset, index) => {
      if (
        [
          schema.nodes.inputScaleLabel,
          schema.nodes.inputScaleLabelNotApplicable
        ].includes(node.type)
      ) {
        updated = updated.replaceChild(
          index,
          node.type.create(
            { ...node.attrs, id: `${parentId}-${node.attrs.id}` },
            node.content,
            node.marks
          )
        );
      }
    });
  }

  return updated;
}
