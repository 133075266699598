import format from "date-fns/format";
import parse from "date-fns/parse";
import { EditorState } from "prosemirror-state";
import { Focus, selectionFocusKey } from "../../editor/plugins/selection-focus";
import {
  inputDateAvailableFormats,
  InputDateTimeControlType,
  InputDateTimeFormat,
  InputDateTimeSchema,
  InputDateTimeWidth,
  inputTimeAvailableFormats
} from "./schema";

export function updateWidth(width: number): number {
  if (width < InputDateTimeWidth.minimum) {
    return InputDateTimeWidth.minimum;
  } else if (width > InputDateTimeWidth.maximum) {
    return InputDateTimeWidth.maximum;
  }
  return width;
}

export function focusedInputDateTime(
  state: EditorState<InputDateTimeSchema>
): Focus | undefined {
  const { schema } = state;

  const focused = selectionFocusKey.getState(state);
  if (focused != null && focused.node.type === schema.nodes.inputDateTime) {
    return focused;
  } else {
    return undefined;
  }
}

export function checkInRangeDefaultDateValue(
  minDateStr: string,
  maxDateStr: string,
  defaultDateStr: string
): boolean {
  let isInRange = false;
  const minDate = new Date(minDateStr).getTime();
  const maxDate = new Date(maxDateStr).getTime();
  const defaultDate = new Date(defaultDateStr).getTime();
  if (defaultDate >= minDate && defaultDate <= maxDate) {
    isInRange = true;
  }
  return isInRange;
}

export function checkInRangeDefaultTimeValue(
  minTimeStr: string,
  maxTimeStr: string,
  defaultTimeStr: string
): boolean {
  let isInRange = false;
  const minDate = new Date(
    99,
    0,
    1,
    parseInt(minTimeStr.substring(0, 2)),
    parseInt(minTimeStr.substring(3, 5))
  ).getTime();
  const maxDate = new Date(
    99,
    0,
    1,
    parseInt(maxTimeStr.substring(0, 2)),
    parseInt(maxTimeStr.substring(3, 5))
  ).getTime();
  const defaultDate = new Date(
    99,
    0,
    1,
    parseInt(defaultTimeStr.substring(0, 2)),
    parseInt(defaultTimeStr.substring(3, 5))
  ).getTime();
  if (defaultDate >= minDate && defaultDate <= maxDate) {
    isInRange = true;
  }
  return isInRange;
}

function getFormat(
  controlType: InputDateTimeControlType,
  formatId: string
): string {
  if (controlType === InputDateTimeControlType.date) {
    const formatObj = findDateTimeFormatObj(
      formatId,
      inputDateAvailableFormats
    );
    return formatObj?.format ?? "yyyy-MM-dd'T'HH:mm";
  } else if (controlType === InputDateTimeControlType.time) {
    const formatObj = findDateTimeFormatObj(
      formatId,
      inputTimeAvailableFormats
    );
    return formatObj?.format ?? "HH:mm";
  } else {
    return "yyyy-MM-dd'T'HH:mm";
  }
}

function isValidDate(date: Date): boolean {
  return date instanceof Date && !isNaN(date.getTime());
}

function parseDateTime(
  controlType: InputDateTimeControlType,
  dateStr: string,
  formatId: string
): Date | undefined {
  const formatStr = getFormat(controlType, formatId);
  try {
    const parsed = parse(dateStr, formatStr, new Date());
    if (isValidDate(parsed)) {
      return parsed;
    } else {
      if (controlType === InputDateTimeControlType.date) {
        const parsed = parse(dateStr, "yyyy-MM-dd'T'HH:mm", new Date());
        if (isValidDate(parsed)) {
          return parsed;
        } else {
          return undefined;
        }
      } else if (controlType === InputDateTimeControlType.time) {
        const parsed = parse(dateStr, "HH:mm", new Date());
        if (isValidDate(parsed)) {
          return parsed;
        } else {
          return undefined;
        }
      } else {
        return undefined;
      }
    }
  } catch {
    return undefined;
  }
}

export function formatDateTime(
  controlType: InputDateTimeControlType,
  dateStr: string,
  formatId: string
): string | undefined {
  const formatStr = getFormat(controlType, formatId);
  try {
    if (controlType === InputDateTimeControlType.time) {
      const parsed = parseDateTime(controlType, dateStr, formatId);
      if (parsed != null) {
        return format(parsed, formatStr);
      } else {
        return undefined;
      }
    } else {
      const date = new Date(dateStr);
      return format(date, formatStr);
    }
  } catch {
    return undefined;
  }
}

export function parseInputDateTime(
  controlType: InputDateTimeControlType,
  dateStr: string,
  formatId: string
): string | null {
  const parsed = parseDateTime(controlType, dateStr, formatId);
  if (parsed != null) {
    try {
      if (controlType === InputDateTimeControlType.date) {
        return format(parsed, "yyyy-MM-dd'T'HH:mm");
      } else if (controlType === InputDateTimeControlType.time) {
        return format(parsed, "HH:mm");
      } else {
        return null;
      }
    } catch {
      return null;
    }
  } else {
    return null;
  }
}

function findDateTimeFormatObj(
  formatID: string,
  arr: InputDateTimeFormat[]
): InputDateTimeFormat | null {
  const obj = arr.find((format) => format.id === formatID) || null;
  return obj;
}
