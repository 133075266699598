import { PluginKey } from "prosemirror-state";
import { Focus } from "../../../editor/plugins/selection-focus";

export interface PluginState {
  opened: boolean;
  focused: Focus;
}

export const inputChoiceNodeViewKey = new PluginKey<PluginState | null>(
  "inputChoiceNodeView"
);
