import { Fragment, Node, Schema } from "prosemirror-model";
import { Selection } from "prosemirror-state";
import { CommandFn } from "../../editor";
import { getTranslation } from "../localization";
import { InputScaleSchema } from "./schema";
import { focusedInputScaleLabels } from "./util";

export function addScaleLabel(props: {
  index: number;
  direction: "before" | "after";
}): CommandFn<Schema> {
  return (state, dispatch) => {
    const focused = focusedInputScaleLabels(state);
    if (focused == null) {
      return false;
    }

    if (dispatch) {
      const { schema } = state;
      const { node, pos } = focused;

      const children = new Array<Node<InputScaleSchema>>();
      let newSelection: number | null = null;

      const start = pos + 1;
      node.forEach((child, offset, index) => {
        if (index !== props.index) {
          children.push(child);
        } else {
          if (props.direction === "before") {
            const newLabel = schema.nodes.inputScaleLabel.createAndFill()!;
            children.push(newLabel);
            newSelection = start + offset;
          }

          children.push(child);

          if (props.direction === "after") {
            const newLabel = schema.nodes.inputScaleLabel.createAndFill()!;
            children.push(newLabel);
            newSelection = start + offset + child.nodeSize;
          }
        }
      });

      let tr = state.tr;
      tr = tr.replaceWith(
        pos,
        pos + node.nodeSize,
        node.copy(Fragment.from(children))
      );
      if (children.length > 0 && newSelection != null) {
        tr = tr.setSelection(Selection.near(tr.doc.resolve(newSelection)));
      }

      dispatch(tr);
    }

    return true;
  };
}

export function removeScaleLabel(props: { index: number }): CommandFn<Schema> {
  return (state, dispatch) => {
    const focused = focusedInputScaleLabels(state);
    if (focused == null) {
      return false;
    }

    if (dispatch) {
      const { node, pos } = focused;
      const nextSelectedIndex =
        props.index === node.childCount - 1 ? props.index - 1 : props.index;

      const children = new Array<Node<InputScaleSchema>>();
      let newSelection: number | null = null;

      const start = pos + 1;
      node.forEach((child, offset, index) => {
        if (index !== props.index) {
          children.push(child);
        }
        if (nextSelectedIndex === index) {
          newSelection = start + offset;
        }
      });

      let tr = state.tr;
      tr = tr.replaceWith(
        pos,
        pos + node.nodeSize,
        node.copy(Fragment.from(children))
      );
      if (children.length > 0 && newSelection != null) {
        tr = tr.setSelection(Selection.near(tr.doc.resolve(newSelection)));
      }

      dispatch(tr);
    }

    return true;
  };
}

export function enableNotApplicable(props: {
  index: number;
}): CommandFn<Schema> {
  return (state, dispatch) => {
    const focused = focusedInputScaleLabels(state);
    if (focused == null) {
      return false;
    }

    if (dispatch) {
      const translation = getTranslation(state);
      const { schema } = state;
      const { node, pos } = focused;

      const children = new Array<Node<InputScaleSchema>>();
      let newSelection: number | null = null;

      const start = pos + 1;
      node.forEach((child, offset, index) => {
        if (index !== props.index) {
          children.push(child);
        } else {
          children.push(child);

          const newLabel = schema.nodes.inputScaleLabelNotApplicable.createAndFill(
            { id: null },
            schema.text(translation("INPUT_LIKERT.NOT_APPLICABLE"))
          )!;
          children.push(newLabel);
          newSelection = start + offset + child.nodeSize;
        }
      });

      let tr = state.tr;
      tr = tr.replaceWith(
        pos,
        pos + node.nodeSize,
        node.copy(Fragment.from(children))
      );
      if (children.length > 0 && newSelection != null) {
        tr = tr.setSelection(Selection.near(tr.doc.resolve(newSelection)));
      }

      dispatch(tr);
    }

    return true;
  };
}
