import { splitBlock } from "prosemirror-commands";
import {
  AttributeSpec,
  ContentMatch,
  Node,
  NodeType,
  ResolvedPos,
  Schema
} from "prosemirror-model";
import {
  AllSelection,
  EditorState,
  NodeSelection,
  TextSelection,
  Transaction
} from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { canReplaceWithNode, isEmptySelectionAtStart } from "../util";
import { GapCursor, Side } from "./plugins/gap-cursor";

function defaultBlockAt<S extends Schema>(
  match: ContentMatch
): NodeType<S> | null {
  for (let i = 0; i < match.edgeCount; i++) {
    let { type } = match.edge(i);
    if (type.isTextblock && !type.hasRequiredAttrs()) {
      return type;
    }
  }
  return null;
}

// :: (EditorState, ?(tr: Transaction)) → bool
// If a block node is selected, create an empty paragraph before (if
// it is its parent's first child) or after it.
export function createParagraphNearWithGapCursor<S extends Schema>(
  state: EditorState<S>,
  dispatch?: (tr: Transaction<S>) => void
): boolean {
  let sel = state.selection,
    { $from, $to } = sel;
  if (
    sel instanceof AllSelection ||
    $from.parent.inlineContent ||
    $to.parent.inlineContent
  ) {
    return false;
  }

  let type = defaultBlockAt<S>($to.parent.contentMatchAt($to.indexAfter()));
  if (!type || !type.isTextblock) {
    return false;
  }

  if (dispatch) {
    let side = (!$from.parentOffset && $to.index() < $to.parent.childCount
      ? $from
      : $to
    ).pos;
    let tr = state.tr;
    tr = tr.insert(side, type.createAndFill()!);
    if (
      sel instanceof GapCursor &&
      sel.side === Side.LEFT &&
      sel.nodePos != null
    ) {
      tr = tr.setSelection(
        new GapCursor(tr.doc.resolve(tr.mapping.map(sel.nodePos)), sel.side)
      );
    } else {
      tr = tr.setSelection(TextSelection.create(tr.doc, side + 1));
    }
    tr = tr.scrollIntoView();

    dispatch(tr);
  }
  return true;
}

function adjustIds<S extends Schema>(
  tr: Transaction<S>,
  state: EditorState<S>
): Transaction<S> {
  if (
    isEmptySelectionAtStart(state) &&
    state.selection.$from.node().content.size !== 0
  ) {
    const { selection } = state;
    const { $to } = selection;
    const nodeToSplit = $to.node();

    tr = tr.setNodeMarkup(
      $to.before(),
      nodeToSplit.type,
      {
        ...nodeToSplit.attrs,
        id: null
      },
      nodeToSplit.marks
    );

    const { $from } = tr.selection;
    const nodeThatWasSplit = $from.node();

    tr = tr.setNodeMarkup(
      $from.before(),
      nodeThatWasSplit.type,
      {
        ...nodeThatWasSplit.attrs,
        id: nodeToSplit.attrs.id
      },
      nodeThatWasSplit.marks
    );
  }

  return tr;
}

export function fixSplit<S extends Schema>(
  tr: Transaction<S>,
  state: EditorState<S>,
  dispatch: (tr: Transaction<S>) => void
): void {
  tr = adjustIds(tr, state);

  const { selection } = state;
  const { $to } = selection;
  const { parent } = $to;

  const keptAttrs = Object.entries(
    parent.type.spec.attrs == null ? {} : parent.type.spec.attrs
  )
    .filter(
      ([key, value]: [string, AttributeSpec & { keepOnSplit?: boolean }]) => {
        return value.keepOnSplit === true || key === "id";
      }
    )
    .map(([key, _value]) => {
      return key;
    });

  if (keptAttrs.length > 0) {
    const blockAttrs = Object.entries($to.node().attrs).reduce(
      (acc, [key, value]) => {
        if (keptAttrs.includes(key)) {
          acc[key] = value;
          return acc;
        } else {
          return acc;
        }
      },
      {} as { [key: string]: any }
    );

    const { $from } = tr.selection;
    const node = $from.node();

    tr = tr.setNodeMarkup($from.before(), node.type, {
      ...blockAttrs
    });
  }

  let textMarks =
    state.storedMarks ||
    (state.selection.$to.parentOffset && state.selection.$from.marks()) ||
    [];

  if (textMarks.length > 0) {
    tr = tr.ensureMarks(textMarks);
  }

  dispatch(tr);
}

export function splitBlockPreservingMarksAndAttrs<S extends Schema>(
  state: EditorState<S>,
  dispatch?: (tr: Transaction<S>) => void
) {
  return splitBlock(
    state,
    dispatch &&
      ((tr) => {
        fixSplit(tr, state, dispatch);
      })
  );
}

export function replaceBlockWithParagraph<S extends Schema>(
  state: EditorState<S>,
  dispatch?: (tr: Transaction<S>) => void
) {
  const { selection, schema } = state;
  if (selection instanceof NodeSelection) {
    const { $from } = selection;
    if (
      selection.node.isBlock &&
      canReplaceWithNode($from, schema.nodes.paragraph) &&
      dispatch
    ) {
      let tr = state.tr;
      tr = tr.replaceWith(
        selection.from,
        selection.to,
        schema.nodes.paragraph.create() as Node<S>
      );
      tr = tr.scrollIntoView();
      dispatch(tr);
      return true;
    }
  }

  return false;
}

function findCutBefore<S extends Schema>(
  $pos: ResolvedPos<S>
): ResolvedPos<S> | null {
  if (!$pos.parent.type.spec.isolating)
    for (let i = $pos.depth - 1; i >= 0; i--) {
      if ($pos.index(i) > 0) {
        return $pos.doc.resolve($pos.before(i + 1));
      }
      if ($pos.node(i).type.spec.isolating) {
        break;
      }
    }
  return null;
}

export function joinBackwardForNodeSelection<S extends Schema>(
  state: EditorState<S>,
  dispatch?: (tr: Transaction<S>) => void,
  view?: EditorView<S>
): boolean {
  let { $head, empty } = state.selection;
  let $cut: ResolvedPos<S> | null = $head;
  if (!empty) {
    return false;
  }

  if ($head.parent.isTextblock) {
    if (
      view ? !view.endOfTextblock("backward", state) : $head.parentOffset > 0
    ) {
      return false;
    }
    $cut = findCutBefore($head);
  }

  if ($cut) {
    let node = $cut.nodeBefore;
    if (!node || !NodeSelection.isSelectable(node)) {
      return false;
    }

    if (dispatch) {
      let tr = state.tr;
      if ($head.parent.content.size === 0) {
        tr = tr.deleteRange($head.before(), $head.after());
      }
      tr = tr.setSelection(
        NodeSelection.create(tr.doc, tr.mapping.map($cut.pos - node.nodeSize))
      );
      tr = tr.scrollIntoView();

      dispatch(tr);

      return true;
    }
  }

  return false;
}

function findCutAfter<S extends Schema>(
  $pos: ResolvedPos<S>
): ResolvedPos<S> | null {
  if (!$pos.parent.type.spec.isolating)
    for (let i = $pos.depth - 1; i >= 0; i--) {
      let parent = $pos.node(i);
      if ($pos.index(i) + 1 < parent.childCount) {
        return $pos.doc.resolve($pos.after(i + 1));
      }
      if (parent.type.spec.isolating) {
        break;
      }
    }
  return null;
}

export function joinForwardForNodeSelection<S extends Schema>(
  state: EditorState<S>,
  dispatch?: (tr: Transaction<S>) => void,
  view?: EditorView<S>
): boolean {
  let { $head, empty } = state.selection;
  let $cut: ResolvedPos<S> | null = $head;
  if (!empty) {
    return false;
  }
  if ($head.parent.isTextblock) {
    if (
      view
        ? !view.endOfTextblock("forward", state)
        : $head.parentOffset < $head.parent.content.size
    ) {
      return false;
    }
    $cut = findCutAfter($head);
  }

  if ($cut) {
    let node = $cut.nodeAfter;
    if (!node || !NodeSelection.isSelectable(node)) {
      return false;
    }

    if (dispatch) {
      let tr = state.tr;
      if ($head.parent.content.size === 0) {
        tr = tr.deleteRange($head.before(), $head.after());
      }
      tr = tr.setSelection(
        NodeSelection.create(tr.doc, tr.mapping.map($cut.pos))
      );
      tr = tr.scrollIntoView();

      dispatch(tr);

      return true;
    }
  }

  return false;
}
