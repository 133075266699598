import { Node, Schema } from "prosemirror-model";
import { Decoration, EditorView } from "prosemirror-view";
import { Editor } from "../../editor";
import { ActionControlButton } from "../../editor/plugins/action-controls";
import { selectionFocusKey } from "../../editor/plugins/selection-focus";
import { hasQuestionTitles, nodeHasQuestionTitleBinding } from "./utils";

export class QuestionTitleBindingButton<S extends Schema> {
  private button: ActionControlButton;
  private onClick: () => void;

  get dom(): HTMLElement {
    return this.button.dom;
  }

  constructor(
    private view: EditorView<S>,
    private node: Node<S>,
    decorations: Decoration[]
  ) {
    this.onClick = this.startBinding.bind(this);

    this.button = new ActionControlButton(
      view,
      { icon: "add-question", title: "ACTION_BUTTONS.ADD_BINDING.TITLE" },
      false,
      () => {
        this.onClick();
      }
    );

    this.updateButton(node, decorations);
  }

  update(node: Node<S>, decorations: Decoration[]): boolean {
    if (node.type !== this.node.type) {
      return false;
    }

    this.node = node;
    this.updateButton(node, decorations);

    return true;
  }

  destroy(): void {
    this.button.destroy();
  }

  ignoreMutation(
    mutation:
      | MutationRecord
      | {
          type: "selection";
          target: Element;
        }
  ): boolean {
    return this.button.ignoreMutation(mutation);
  }

  private updateButton(node: Node<S>, decorations: Decoration[]): void {
    if (
      nodeHasQuestionTitleBinding(node) &&
      hasQuestionTitles(this.view.state.doc, node)
    ) {
      this.button.setIcon({
        icon: "remove-question",
        title: "ACTION_BUTTONS.REMOVE_BINDING.TITLE"
      });
      this.button.setActive(true);
      this.onClick = this.unlink.bind(this);
    } else {
      const questionTitleBinding = getQuestionTitleBindingFromDecorations(
        decorations
      );

      this.button.setIcon(
        questionTitleBinding
          ? { icon: "ban", title: "ACTION_BUTTONS.CANCEL_BINDING.TITLE" }
          : {
              icon: "add-question",
              title: "ACTION_BUTTONS.ADD_BINDING.TITLE"
            }
      );
      this.button.setActive(false);
      this.onClick = questionTitleBinding
        ? this.cancelBinding.bind(this)
        : this.startBinding.bind(this);
    }
  }

  private startBinding(): void {
    const editor = this.view as Editor<S>;
    const focused = selectionFocusKey.getState(editor.state);

    if (focused != null && focused.node.type === this.node.type) {
      const { node, pos } = focused;
      editor.commands.startQuestionTitleBinding.execute({
        target: node,
        pos: pos
      });
    }
  }

  private cancelBinding(): void {
    const editor = this.view as Editor<S>;

    editor.commands.cancelQuestionTitleBinding.execute();
  }

  private unlink(): void {
    const editor = this.view as Editor<S>;

    editor.commands.unlinkQuestionTitle.execute();
  }
}

function getQuestionTitleBindingFromDecorations(
  decorations: Decoration[]
): boolean {
  const found = decorations.find(
    (decoration) => decoration.spec.type === "question-title-binding"
  );

  if (found == null) {
    return false;
  } else {
    return true;
  }
}
