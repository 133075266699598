import {
  Editor as BlueXEditor,
  InputNumberActiveValue,
  InputNumberControlType,
  InputNumberLabelPosition,
  InputNumberOfDecimals,
  InputNumberWidth,
  Schema
} from "@blue-x/editor";
import React, { useCallback, useMemo } from "react";
import {
  CheckboxFormControl,
  NumberFormControl,
  QuestionTitleFormControl,
  SelectFormControl,
  TextFormControl
} from "../ProportiesPanelControls";
import "./InputNumber.css";

interface InputNumberPropertiesPanelProps {
  editor: BlueXEditor<Schema>;
}

export const InputNumberPropertiesPanel: React.FC<InputNumberPropertiesPanelProps> = (
  props
) => {
  const { editor } = props;
  const { commands } = editor;

  const activeValue: InputNumberActiveValue = useMemo(() => {
    return commands.updateInputNumber.activeValue();
  }, [commands]);

  const update = useCallback(
    (value) => {
      commands.updateInputNumber.execute(value, false);
    },
    [commands]
  );

  return (
    <div className="InputNumberPropertiesPanel">
      <SelectFormControl
        label={"Type"}
        value={activeValue.controlType}
        onUpdate={(value) => {
          update({ controlType: value });
        }}
      >
        <option value={InputNumberControlType.spinbox}>Spin box</option>
        <option value={InputNumberControlType.slider}>Slider</option>
      </SelectFormControl>

      <SelectFormControl
        label={"Label"}
        value={activeValue.labelPosition}
        onUpdate={(value) => {
          update({ labelPosition: value });
        }}
      >
        <option value={InputNumberLabelPosition.top}>Top</option>
        <option value={InputNumberLabelPosition.bottom}>Bottom</option>
        <option value={InputNumberLabelPosition.none}>None</option>
      </SelectFormControl>

      <NumberFormControl
        label={"Width (%)"}
        value={activeValue.width}
        step={1}
        min={InputNumberWidth.minimum}
        max={InputNumberWidth.maximum}
        onUpdate={(value) => {
          update({ width: value });
        }}
      />

      <QuestionTitleFormControl
        editor={editor}
        label={"Question title*"}
        value={activeValue.questionTitle}
      />

      <TextFormControl
        label={"Description"}
        value={activeValue.description}
        onUpdate={(value) => {
          update({ description: value });
        }}
      />

      <TextFormControl
        label={"Question ID"}
        value={activeValue.coding}
        onUpdate={(value) => {
          update({ coding: value });
        }}
      />

      <NumberFormControl
        label={"Default value"}
        step={1}
        value={activeValue.defaultValue}
        onUpdate={(value) => {
          update({ defaultValue: value });
        }}
      />

      <NumberFormControl
        label={"Increment"}
        step={activeValue.increment ? activeValue.increment : 1}
        value={activeValue.step}
        onUpdate={(value) => {
          update({ step: value });
        }}
      />

      <TextFormControl
        label={"Prefix"}
        value={activeValue.prefix}
        onUpdate={(value) => {
          update({ prefix: value });
        }}
      />

      <TextFormControl
        label={"Suffix"}
        value={activeValue.suffix}
        onUpdate={(value) => {
          update({ suffix: value });
        }}
      />

      <NumberFormControl
        label={"Number of Decimal"}
        value={activeValue.numberOfDecimals}
        step={1}
        min={InputNumberOfDecimals.minimum}
        max={InputNumberOfDecimals.maximum}
        onUpdate={(value) => {
          update({ numberOfDecimals: value });
        }}
      />

      <NumberFormControl
        label={"Minimum"}
        step={1}
        value={activeValue.minValue}
        onUpdate={(value) => {
          update({ minValue: value });
        }}
      />

      <NumberFormControl
        label={"Maximum"}
        step={1}
        value={activeValue.maxValue}
        onUpdate={(value) => {
          update({ maxValue: value });
        }}
      />

      <CheckboxFormControl
        label={"Required"}
        value={activeValue.required}
        onUpdate={(value) => {
          update({ required: value });
        }}
      />
    </div>
  );
};
