import { Node, Schema } from "prosemirror-model";
import { Decoration, EditorView, NodeView } from "prosemirror-view";
import { GetTranslationFn, LanguageObserver } from "../../localization";

export class TextVariableNodeView<S extends Schema> implements NodeView<S> {
  dom: HTMLElement;
  private displayedValue: HTMLElement;

  private languageObserver: LanguageObserver<S>;
  private variableNotFound: boolean;

  constructor(
    private node: Node<S>,
    view: EditorView<S>,
    _getPos: () => number,
    decorations: Decoration[]
  ) {
    this.languageObserver = new LanguageObserver(view, (getTranslation) => {
      this.updateDisplayedValue(
        this.node,
        this.variableNotFound,
        getTranslation
      );
    });

    const container = document.createElement("text-variable");
    container.classList.add("ProseMirror-inline");

    const startMarker = document.createElement("span");
    startMarker.innerText = "{";
    startMarker.contentEditable = "false";

    const content = document.createElement("text-variable-content");

    const endMarker = document.createElement("span");
    endMarker.innerText = "}";
    endMarker.contentEditable = "false";

    container.appendChild(startMarker);
    container.appendChild(content);
    container.appendChild(endMarker);

    this.dom = container;
    this.displayedValue = content;

    const getTranslation = this.languageObserver.getTranslation;
    this.variableNotFound = this.getVariableNotFound(decorations);

    this.updateDisplayedValue(node, this.variableNotFound, getTranslation);
  }

  update(node: Node<S>, decorations: Decoration[]): boolean {
    if (node.type !== this.node.type) {
      return false;
    }

    this.node = node;

    const getTranslation = this.languageObserver.getTranslation;
    this.variableNotFound = this.getVariableNotFound(decorations);

    this.updateDisplayedValue(node, this.variableNotFound, getTranslation);

    return true;
  }

  destroy() {
    this.languageObserver.destroy();
  }

  private updateDisplayedValue(
    node: Node<S>,
    variableNotFound: boolean,
    getTranslation: GetTranslationFn
  ): void {
    if (variableNotFound) {
      this.displayedValue.innerText = getTranslation("VARIABLES.NOT_FOUND");
    } else {
      this.displayedValue.innerText = node.attrs.displayedValue;
    }
  }

  private getVariableNotFound(decorations: Decoration[]): boolean {
    const variableNotFound = decorations.find(
      (decoration) => decoration.spec.type === "variableNotFound"
    );

    return variableNotFound != null;
  }
}
