import { keymap } from "prosemirror-keymap";
import { Schema } from "prosemirror-model";
import { EditorState, Plugin, Transaction } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { isEqual, isMac } from "../../util";

export type CommandFn<S extends Schema> = (
  state: EditorState<S>,
  dispatch?: (tr: Transaction<S>) => void,
  view?: EditorView<S>
) => boolean;

type CommandExecution<S extends Schema, U extends Record<string, any>> = (
  props?: U,
  shouldFocus?: boolean
) => CommandFn<S>;

type CommandState<S extends Schema> =
  | ((state: EditorState<S>) => boolean)
  | boolean;

type CommandValue<S extends Schema, T> = (state: EditorState<S>) => T;

export interface CommandConfiguration<
  S extends Schema,
  U extends Record<string, any>,
  T
> {
  isActive: (props?: U) => CommandState<S>;
  isEnabled: (props?: U) => CommandState<S>;
  execute: CommandExecution<S, U>;
  activeValue?: (props?: U) => CommandValue<S, T>;
  shortcuts?: Record<string, U | undefined>;
  requiresEditable?: boolean;
}

export type CommandConfigurations<S extends Schema, U = any, T = any> = Record<
  string,
  CommandConfiguration<S, U, T>
>;

export interface Command<
  U extends Record<string, any> = Record<string, any>,
  T = any
> {
  isActive: (props?: U) => boolean;
  isEnabled: (props?: U) => boolean;
  execute: (props?: U, shouldFocus?: boolean) => boolean;
  activeValue: (props?: U) => T | undefined;
  shortcut: (props?: U) => string | undefined;
}

export type Commands = Record<string, Command>;

export interface ExtensionCommands<S extends Schema> {
  commands?: (schema: S) => CommandConfigurations<S, any>;
}

function normalizeKeyName(name: string): string {
  const mac = isMac();

  const parts = name.split(/-(?!$)/);
  let result = parts[parts.length - 1];

  let alt: boolean = false;
  let ctrl: boolean = false;
  let shift: boolean = false;
  let meta: boolean = false;

  for (let i = 0; i < parts.length - 1; i++) {
    let mod = parts[i];
    if (/^(cmd|meta|m)$/i.test(mod)) {
      meta = true;
    } else if (/^a(lt)?$/i.test(mod)) {
      alt = true;
    } else if (/^(c|ctrl|control)$/i.test(mod)) {
      ctrl = true;
    } else if (/^s(hift)?$/i.test(mod)) {
      shift = true;
    } else if (/^mod$/i.test(mod)) {
      if (mac) {
        meta = true;
      } else {
        ctrl = true;
      }
    } else {
      throw new Error("Unrecognized modifier name: " + mod);
    }
  }

  if (alt) {
    result = "Alt-" + result;
  }

  if (ctrl) {
    result = "Ctrl-" + result;
  }

  if (meta) {
    result = "Meta-" + result;
  }

  if (shift) {
    result = "Shift-" + result;
  }

  return result;
}

export function commandIsEnabled<S extends Schema>(
  command: CommandConfiguration<S, any, any>,
  state: EditorState<S>,
  editable: boolean
): (attrs?: Record<string, any>) => boolean {
  const requiresEditable =
    command.requiresEditable == null ? true : command.requiresEditable;

  const isEnabled = (attrs?: Record<string, any>) => {
    const isEnabled = command.isEnabled(attrs);
    const isEditable = requiresEditable === true ? editable : true;
    if (typeof isEnabled === "boolean") {
      return isEnabled && isEditable;
    } else {
      return isEnabled(state) && isEditable;
    }
  };

  return isEnabled;
}

export function extensionCommands<S extends Schema>(
  extensions: ExtensionCommands<S>[],
  view: EditorView<S>
): Commands {
  const apply = (cb: CommandFn<S>, shouldFocus: boolean) => {
    if (shouldFocus) {
      view.focus();
    }
    return cb(view.state, view.dispatch, view);
  };

  return extensions.reduce((acc, elem) => {
    if (elem.commands) {
      const commands = Object.entries(
        elem.commands.bind(elem)(view.state.schema)
      ).reduce((acc, [key, command]) => {
        const commands: Commands = {};

        const isActive = (attrs?: Record<string, any>) => {
          const isActive = command.isActive(attrs);
          if (typeof isActive === "boolean") {
            return isActive;
          } else {
            return isActive(view.state);
          }
        };

        const isEnabled = commandIsEnabled(command, view.state, view.editable);

        const commandFn = (
          attrs?: Record<string, any>,
          shouldFocus?: boolean
        ) => {
          return command.execute(attrs, shouldFocus);
        };

        const execute = (
          attrs?: Record<string, any>,
          shouldFocus?: boolean
        ) => {
          if (isEnabled(attrs)) {
            return apply(
              commandFn(attrs),
              shouldFocus == null ? true : shouldFocus
            );
          } else {
            return false;
          }
        };

        const activeValue = (attrs?: Record<string, any>) => {
          if (command.activeValue) {
            return command.activeValue(attrs)(view.state);
          } else {
            return undefined;
          }
        };

        const shortcut = (attrs?: Record<string, any>) => {
          const safeShortcuts = command.shortcuts ? command.shortcuts : {};
          const entries = Object.entries(safeShortcuts);
          const shortcut = entries.find(([_, value]) => {
            return isEqual(attrs, value);
          });

          if (shortcut) {
            return normalizeKeyName(shortcut[0]);
          } else {
            return undefined;
          }
        };

        commands[key] = {
          isActive: isActive,
          isEnabled: isEnabled,
          execute: execute,
          activeValue: activeValue,
          shortcut: shortcut
        };
        return {
          ...acc,
          ...commands
        };
      }, {} as Commands);
      return { ...acc, ...commands };
    } else {
      return acc;
    }
  }, {} as Commands);
}

export function extensionShortcuts<S extends Schema>(
  extensions: ExtensionCommands<S>[],
  schema: S
): Plugin[] {
  return extensions.reduce((acc, elem) => {
    const extension = elem.commands;
    if (extension) {
      const commands = extension.bind(elem)(schema);
      const shortcuts = Object.values(commands).reduce((acc, command) => {
        if (command.shortcuts != null) {
          const item = {} as Record<string, CommandFn<S>>;
          Object.entries(command.shortcuts).forEach(
            ([shortcut, parameters]) => {
              item[shortcut] =
                parameters == null
                  ? command.execute()
                  : command.execute(parameters);
            }
          );

          return { ...acc, ...item };
        } else {
          return acc;
        }
      }, {} as Record<string, CommandFn<S>>);

      const hasShortcuts = Object.values(shortcuts).length > 0;
      if (hasShortcuts) {
        return acc.concat(keymap(shortcuts));
      } else {
        return acc;
      }
    } else {
      return acc;
    }
  }, new Array<Plugin>());
}
