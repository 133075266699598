import { Node, NodeSpec } from "prosemirror-model";
import { BASE_PRIORITY, DocumentBuilders, NodeConfig } from "../../../editor";

const rowAttrs = {
  id: { default: null }
};

export class TableRowNode implements NodeConfig {
  get name(): string {
    return "tableRow";
  }

  get spec(): NodeSpec {
    return {
      content: "cell+",
      tableRole: "row",
      attrs: rowAttrs,
      parseDOM: [
        {
          tag: "tr",
          getAttrs(node) {
            if (typeof node === "string") {
              return false;
            }

            const element = node as HTMLElement;
            return getAttrs(element);
          },
          priority: BASE_PRIORITY - 1
        },
        {
          tag: "tr[data-section='items']",
          getAttrs(node) {
            if (typeof node === "string") {
              return false;
            }

            const element = node as HTMLElement;
            return getAttrs(element);
          }
        },
        {
          tag: "div[data-section='items'] > div",
          context: "table/",
          getAttrs(node) {
            if (typeof node === "string") {
              return false;
            }

            return getAttrs(node as HTMLElement);
          }
        }
      ],
      toDOM(node) {
        return ["tr", setAttrs(node, "items"), 0];
      }
    };
  }

  get builders(): DocumentBuilders {
    return { tr: { nodeType: "tableRow" } };
  }
}

export class TableHeaderRowNode implements NodeConfig {
  get name(): string {
    return "tableHeaderRow";
  }

  get spec(): NodeSpec {
    return {
      content: "headerCell+",
      tableRole: "row",
      attrs: rowAttrs,
      parseDOM: [
        {
          tag: "tr[data-section='headers']",
          getAttrs(node) {
            if (typeof node === "string") {
              return false;
            }

            const element = node as HTMLElement;
            return getAttrs(element);
          }
        },
        {
          tag: "div[data-section='headers'] > div",
          context: "table/",
          getAttrs(node) {
            if (typeof node === "string") {
              return false;
            }

            return getAttrs(node as HTMLElement);
          }
        }
      ],
      toDOM(node) {
        return ["tr", setAttrs(node, "headers"), 0];
      }
    };
  }

  get builders(): DocumentBuilders {
    return { tableHeaderRow: { nodeType: "tableHeaderRow" } };
  }
}

export class TableFooterRowNode implements NodeConfig {
  get name(): string {
    return "tableFooterRow";
  }

  get spec(): NodeSpec {
    return {
      content: "footerCell+",
      tableRole: "row",
      attrs: rowAttrs,
      parseDOM: [
        {
          tag: "tr[data-section='footers']",
          getAttrs(node) {
            if (typeof node === "string") {
              return false;
            }

            const element = node as HTMLElement;
            return getAttrs(element);
          }
        },
        {
          tag: "div[data-section='footers'] > div",
          context: "table/",
          getAttrs(node) {
            if (typeof node === "string") {
              return false;
            }

            return getAttrs(node as HTMLElement);
          }
        }
      ],
      toDOM(node) {
        return ["tr", setAttrs(node, "footers"), 0];
      }
    };
  }

  get builders(): DocumentBuilders {
    return { tableFooterRow: { nodeType: "tableFooterRow" } };
  }
}

function getAttrs(element: Element): { [key: string]: any } {
  const attrs: {
    id?: string;
  } = {};

  const id = element.getAttribute("id");
  if (id != null) {
    attrs.id = id;
  }

  return attrs;
}

function setAttrs(
  node: Node,
  type: "headers" | "items" | "footers"
): { [key: string]: any } {
  const id = node.attrs.id as string;
  return { id: id, "data-section": type };
}
