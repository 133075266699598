import { Node, ResolvedPos, Schema } from "prosemirror-model";
import { EditorState } from "prosemirror-state";
import { Rect, TableMap } from "prosemirror-tables";
import { Focus, selectionFocusKey } from "../../editor/plugins/selection-focus";
import { findParent, NodeWithPos, UnreachableCaseError } from "../../util";
import { GetTranslationFn } from "../localization";
import {
  ConstantSumLogicDirection,
  ConstantSumSchema,
  ConstantSumType
} from "./schema";

export function createConstantSum(
  schema: ConstantSumSchema,
  direction: ConstantSumLogicDirection,
  type: ConstantSumType,
  getTranslation: GetTranslationFn
): Node<ConstantSumSchema> {
  const { constantSum } = schema.nodes;

  const text =
    type === ConstantSumType.sum
      ? getTranslation("INPUT_CONSTANT_SUM.TOTAL")
      : getTranslation("INPUT_CONSTANT_SUM.REMAINDER");

  return constantSum.createChecked(
    {
      direction: direction
    },
    text.length === 0 ? [] : schema.text(text)
  );
}

export function focusedConstantSum(
  state: EditorState<ConstantSumSchema>
): Focus | undefined {
  const { schema } = state;
  const focused = selectionFocusKey.getState(state);

  if (focused != null && focused.node.type === schema.nodes.constantSum) {
    return focused;
  } else {
    return undefined;
  }
}

export function isInTableCell<S extends Schema>(
  state: EditorState<S>
): boolean {
  const { selection } = state;
  const { $from } = selection;
  const node = $from.node();

  const isTableCell = (n: Node<S>) => {
    return (
      n.type.spec.tableRole === "cell" ||
      n.type.spec.tableRole === "header_cell"
    );
  };
  const isCell = (n: Node<S>) => {
    return n.type.spec.tableRole === "cell";
  };

  if (isTableCell(node)) {
    return isCell(node);
  } else {
    const parent = findParent($from, isTableCell);
    if (parent == null) {
      return false;
    }

    return isCell(parent.node);
  }
}

interface ConstantSumAssociation<S extends Schema> extends NodeWithPos<S> {
  constantSum: boolean;
}

export function getAssociationsForConstantSum(
  state: EditorState<ConstantSumSchema>
): ConstantSumAssociation<ConstantSumSchema>[] {
  const constantSum: Focus | undefined = focusedConstantSum(state);
  if (constantSum == null) {
    return [];
  }

  const { doc } = state;
  const { node, pos } = constantSum;
  const $pos = doc.resolve(pos);

  const table = findParent($pos, (n) => n.type.spec.tableRole === "table");
  const cell = findParent(
    $pos,
    (n) =>
      n.type.spec.tableRole === "cell" ||
      n.type.spec.tableRole === "header_cell"
  );

  if (table != null && cell != null) {
    const direction = node.attrs.direction as ConstantSumLogicDirection;
    const map = TableMap.get(table.node);
    const constantSumCellPos = cell.pos - table.start;
    const constantSumCellRect = map.findCell(constantSumCellPos);
    const rect = getCellsForCell(constantSumCellRect, map, direction);

    const cells = new Array<ConstantSumAssociation<ConstantSumSchema>>();
    map.cellsInRect(rect).forEach((cellPos) => {
      const pos = cellPos + table.start;
      const node = doc.nodeAt(pos);
      if (node != null) {
        cells.push({ pos: pos, node: node, constantSum: pos === cell.pos });
      }
    });

    return cells;
  } else {
    return [];
  }
}

function getCellsForCell(
  cell: Rect,
  map: TableMap,
  direction: ConstantSumLogicDirection
): Rect {
  switch (direction) {
    case ConstantSumLogicDirection.horizontal:
      return {
        top: cell.top,
        bottom: cell.bottom,
        left: 0,
        right: map.width
      };

    case ConstantSumLogicDirection.vertical:
      return {
        top: 0,
        bottom: map.height,
        left: cell.left,
        right: cell.right
      };

    default:
      throw new UnreachableCaseError(direction);
  }
}

export function containsConstantSum<S extends Schema>(
  $pos: ResolvedPos<S>,
  schema: S
): boolean {
  const node = $pos.node();

  const isTableCell = (n: Node<S>) => {
    return (
      n.type.spec.tableRole === "cell" ||
      n.type.spec.tableRole === "header_cell"
    );
  };

  const hasConstantSum = (n: Node<S>) => {
    for (let i = 0; i < n.childCount; ++i) {
      const child = n.child(i);
      if (child.type === schema.nodes.constantSum) {
        return true;
      }
    }

    return false;
  };

  if (isTableCell(node)) {
    return hasConstantSum(node);
  } else {
    const parent = findParent($pos, isTableCell);
    if (parent == null) {
      return false;
    }

    return hasConstantSum(parent.node);
  }
}
