import { Fragment, Slice } from "prosemirror-model";
import { Plugin, TextSelection, Transaction } from "prosemirror-state";
import { findChildren } from "../../../util";
import { InputRankSchema } from "../schema";
import {
  tryInsertRankIntoSection,
  tryInsertParagraphIntoSection,
  focusedInputRank
} from "../util";

export function pastePlugin(schema: InputRankSchema) {
  return new Plugin<null, InputRankSchema>({
    props: {
      handlePaste(view, _event, slice) {
        const { state, dispatch } = view;
        const focusedRank = focusedInputRank(state);
        if (focusedRank == null) {
          return false;
        }

        if (!isPastingValues(slice, schema)) {
          let tr = state.tr;

          const { $from } = tr.selection;

          const node = $from.node();

          if (!node) {
            return false;
          }

          if (node.type === schema.nodes.inputRankOptionOtherSpecify) {
            let fragments = new Array<Fragment<InputRankSchema>>();
            slice.content.forEach((n) => {
              if (n.type === schema.nodes.paragraph) {
                fragments.push(n.content);
              }
            });

            tr = tr.deleteSelection();
            fragments.reverse().forEach((f) => {
              tr = tr.insert($from.pos, f);
            });

            dispatch(tr);
          } else if (node.type === schema.nodes.inputRankSection) {
            const insertParagraph = tryInsertParagraphIntoSection(
              slice,
              schema
            );
            const insertRank = tryInsertRankIntoSection(slice, schema);

            if (!insertParagraph && !insertRank) {
              return false;
            }

            if (insertRank) {
              const inputRankFromSlice = slice.content.firstChild;

              if (inputRankFromSlice == null) {
                return false;
              }

              if (inputRankFromSlice.type !== schema.nodes.inputRank) {
                return false;
              }

              const sections = findChildren(inputRankFromSlice, (node) => {
                return node.type === schema.nodes.inputRankSection;
              });

              const options = findChildren(inputRankFromSlice, (node) => {
                return (
                  node.type === schema.nodes.inputRankOption ||
                  node.type === schema.nodes.inputRankOptionOtherSpecify ||
                  node.type === schema.nodes.inputRankOptionSelectAll
                );
              });

              let fragments = new Array<Fragment<InputRankSchema>>();
              sections.forEach((section) => {
                fragments.push(section.node.content);
              });

              options.forEach((option) => {
                fragments.push(option.node.content);
              });

              let tr = state.tr as Transaction;

              let size = 0;
              fragments.reverse().forEach((f) => {
                tr = tr.insert($from.pos, f);
                size += f.size;
              });

              tr = tr.setSelection(
                TextSelection.near(tr.doc.resolve($from.pos + size))
              );

              view.dispatch(tr);
            } else if (insertParagraph) {
              let fragments = new Array<Fragment<InputRankSchema>>();
              slice.content.forEach((sliceNode, _o, index) => {
                const hardBreak = schema.nodes.hardBreak.create();
                if (index > 0) {
                  fragments.push(Fragment.from(hardBreak));
                }
                fragments.push(sliceNode.content);
              });

              tr = tr.deleteSelection();
              fragments.reverse().forEach((f) => {
                tr = tr.insert($from.pos, f);
              });

              dispatch(tr);
            }
          } else if (node.type === schema.nodes.inputRankOption) {
            const insertRank = tryInsertRankIntoSection(slice, schema);

            if (!insertRank) {
              return false;
            }

            const inputRankFromSlice = slice.content.firstChild;

            if (inputRankFromSlice == null) {
              return false;
            }

            if (inputRankFromSlice.type !== schema.nodes.inputRank) {
              return false;
            }

            const sections = findChildren(inputRankFromSlice, (node) => {
              return node.type === schema.nodes.inputRankSection;
            });

            const options = findChildren(inputRankFromSlice, (node) => {
              return (
                node.type === schema.nodes.inputRankOption ||
                node.type === schema.nodes.inputRankOptionOtherSpecify ||
                node.type === schema.nodes.inputRankOptionSelectAll
              );
            });

            if (sections.length > 0 && options.length > 0) {
              let fragments = new Array<Fragment<InputRankSchema>>();
              sections.forEach((section) => {
                fragments.push(section.node.content);
              });

              options.forEach((option) => {
                fragments.push(option.node.content);
              });

              let tr = state.tr as Transaction;

              let size = 0;
              fragments.reverse().forEach((f) => {
                tr = tr.insert($from.pos, f);
                size += f.size;
              });

              tr = tr.setSelection(
                TextSelection.near(tr.doc.resolve($from.pos + size))
              );

              view.dispatch(tr);
            } else {
              return false;
            }
          } else {
            return false;
          }

          return true;
        }

        if (dispatch) {
          let tr = state.tr;
          tr = tr.deleteSelection();

          const { selection } = tr;
          const { empty, $from } = selection;
          const isAtStart = empty && $from.parentOffset === 0;

          if (isAtStart) {
            const pos = $from.before($from.depth);
            tr = tr.insert(pos, slice.content);
          } else {
            tr = tr.insert($from.pos, slice.content);
          }

          dispatch(tr);
        }
        return true;
      }
    }
  });
}

function isPastingValues(
  slice: Slice<InputRankSchema>,
  schema: InputRankSchema
): boolean {
  const firstChild = slice.content.firstChild;
  const lastChild = slice.content.lastChild;

  if (firstChild != null && lastChild != null) {
    return (
      firstChild.type === schema.nodes.inputRankOption &&
      lastChild.type === schema.nodes.inputRankOption
    );
  } else {
    return false;
  }
}
