import { Schema } from "prosemirror-model";
import { EditorState, Plugin, PluginKey } from "prosemirror-state";

interface QuestionTitleAutocreationState {
  isActive: boolean;
}

export const questionAutocreationFlagKey = new PluginKey<
  QuestionTitleAutocreationState,
  Schema
>();

export function questionAutoCreationFlag(
  shouldAddQuestionTitleOnInsert: boolean
) {
  return new Plugin<QuestionTitleAutocreationState, Schema>({
    key: questionAutocreationFlagKey,
    state: {
      init() {
        return {
          isActive: shouldAddQuestionTitleOnInsert
        };
      },
      apply(tr, value) {
        const meta = tr.getMeta(questionAutocreationFlagKey) as
          | { isActive: boolean }
          | undefined;

        if (meta === undefined) {
          return value;
        }

        return { isActive: meta.isActive };
      }
    }
  });
}

export function isQuestionTitleAutoCreation(
  state: EditorState<Schema>
): boolean {
  const cache = questionAutocreationFlagKey.getState(state);

  return cache?.isActive ?? false;
}
