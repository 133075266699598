import { Node, Schema, Slice } from "prosemirror-model";
import { Plugin } from "prosemirror-state";
import { EditorDropNotification } from "../../../editor/plugins/drop";
import { emitNotification } from "../../../editor/plugins/notification";
import { EditorPasteNotification } from "../../../editor/plugins/paste";
import { findNode, posFromEvent } from "../../../util";
import { containsConstantSum } from "../util";

function sliceHasConstantSum<S extends Schema>(
  slice: Slice<S>,
  schema: S
): boolean {
  const constantSum = findNode(
    slice.content,
    (n) => n.type === schema.nodes.constantSum
  );

  if (constantSum != null) {
    let start = slice.openStart;
    let startNode = slice.content.firstChild;
    let startNodeParent: Node<S> | null | undefined = null;

    for (let i = 0; i < start; ++i) {
      startNodeParent = startNode;
      startNode = startNode?.firstChild;
    }

    let end = slice.openEnd;
    let endNode = slice.content.lastChild;
    let endNodeParent: Node<S> | null | undefined = null;

    for (let i = 0; i < end; ++i) {
      endNodeParent = endNode;
      endNode = endNode?.lastChild;
    }

    if (
      startNodeParent != null &&
      endNodeParent != null &&
      startNodeParent.type === schema.nodes.constantSum
    ) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

export function pasteDropPlugin(): Plugin {
  return new Plugin({
    props: {
      handlePaste(view, _event, slice) {
        const { state } = view;
        const { selection, schema } = state;
        const { $from } = selection;

        if (
          containsConstantSum($from, schema) &&
          sliceHasConstantSum(slice, schema)
        ) {
          emitNotification(view.state, {
            type: "warning",
            message: EditorPasteNotification.PasteNotAllowed
          });
          return true;
        }

        return false;
      },
      handleDrop(view, event, slice) {
        const dropEvent = event as DragEvent;

        const target = posFromEvent(dropEvent, view, slice);
        if (target != null) {
          const { state } = view;
          const { doc, schema } = state;
          const $pos = doc.resolve(target);

          if (
            containsConstantSum($pos, schema) &&
            sliceHasConstantSum(slice, schema)
          ) {
            emitNotification(view.state, {
              type: "warning",
              message: EditorDropNotification.DropNotAllowed
            });
            return true;
          }
        }

        return false;
      }
    }
  });
}
