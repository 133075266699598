import { Schema } from "prosemirror-model";

export type InputFileSchema = Schema<"inputFile", any>;

export enum InputFileFormat {
  document = "document",
  image = "image",
  sound = "sound",
  video = "video"
}

export interface InputFileDefaultVals {
  FileFormat: InputFileFormat;
  MinFiles: number;
  MaxFiles: number;
  ImageMinWidth: number;
  ImageMinHeight: number;
}

export enum InputFileWidth {
  default = 100,
  minimum = 25,
  maximum = 100
}

export enum InputFileMaxSize {
  default = 5,
  minimum = 1,
  maximum = 50
}

export const InputFileDefaults: InputFileDefaultVals = {
  FileFormat: InputFileFormat.document,
  MinFiles: 1,
  MaxFiles: 1,
  ImageMinWidth: 640,
  ImageMinHeight: 400
};
