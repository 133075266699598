import { Decoration, EditorView } from "prosemirror-view";
import { Schema } from "prosemirror-model";
import { ColorPalette } from "./types";
import {
  ColorSchemeState,
  colorPaletteKey
} from "./plugin/color-scheme-picker";
import { EditorState } from "prosemirror-state";

export function getColorSchemeFromDecorations(
  decorations: Decoration[]
): ColorPalette | null {
  const found = decorations.find(
    (decoration) => decoration.spec.type === "color-scheme"
  );

  if (found == null) {
    return null;
  } else {
    return null;
  }
}
interface DOMObserver {
  start(): void;
  stop(): void;
}

interface DOMObserverView extends EditorView {
  domObserver: DOMObserver;
}
export class ColorSchemeObserver<S extends Schema> {
  private observer: MutationObserver;

  private _getColorScheme: ColorSchemeState | null;
  get getColorScheme(): ColorSchemeState | null {
    return this._getColorScheme;
  }

  constructor(
    view: EditorView<S>,
    onChange: (getColorScheme: ColorSchemeState | null) => void
  ) {
    this.observer = new MutationObserver((mutations) => {
      for (let mutation of mutations) {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "data-palette-type"
        ) {
          this._getColorScheme = getColorScheme(view.state);
          (view as DOMObserverView).domObserver.stop();
          onChange(this._getColorScheme);
          (view as DOMObserverView).domObserver.start();
        }
      }
    });

    this._getColorScheme = getColorScheme(view.state);

    this.observer.observe(view.dom, {
      attributes: true,
      attributeFilter: ["data-palette-type"]
    });
  }

  destroy() {
    this.observer.disconnect();
  }
}

export function getColorScheme<S extends Schema>(
  state: EditorState<S>
): ColorSchemeState | null {
  const documentColorPalette = colorPaletteKey.getState(state);
  if (documentColorPalette == null) {
    console.error(`No document color palette found.`);
    return null;
  }

  return documentColorPalette;
}
