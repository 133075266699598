import { Schema, Slice } from "prosemirror-model";
import { Plugin, PluginKey, TextSelection } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { EditorDropNotification } from "../../../editor/plugins/drop";
import { emitNotification } from "../../../editor/plugins/notification";
import { EditorPasteNotification } from "../../../editor/plugins/paste";
import {
  findChildren,
  findChildrenByType,
  focusedQuestionTitleAtPos,
  posFromEvent
} from "../../../util";

const key = new PluginKey("question-title-insertion-restriction");

export function questionTitleInsertionRestriction() {
  return new Plugin({
    key,
    props: {
      handlePaste(view, _event, slice) {
        const { state } = view;
        const { doc, selection } = state;
        const { $from, $to } = selection;

        if (focusedQuestionTitleAtPos(doc, $from, $to) != null) {
          if (isTablePaste(slice, state.schema)) {
            pasteCellsIntoQuestionTitle(slice, view);
            return true;
          }

          if (!isInsertionAllowedInQuestionTitle(slice)) {
            emitNotification(view.state, {
              type: "warning",
              message: EditorPasteNotification.PasteNotAllowed
            });
            return true;
          }
        }

        return false;
      },
      handleDrop(view, _event, slice) {
        const dropEvent = _event as DragEvent;
        const target = posFromEvent(dropEvent, view, slice);

        if (target == null) {
          emitNotification(view.state, {
            type: "warning",
            message: EditorDropNotification.DropNotAllowed
          });
          return true;
        }

        const { state } = view;
        const { doc } = state;
        const $pos = view.state.doc.resolve(target);

        if (focusedQuestionTitleAtPos(doc, $pos, $pos) != null) {
          if (!isInsertionAllowedInQuestionTitle(slice)) {
            emitNotification(view.state, {
              type: "warning",
              message: EditorDropNotification.DropNotAllowed
            });
            return true;
          }
        }

        return false;
      }
    }
  });
}

function isInsertionAllowedInQuestionTitle(slice: Slice<any>) {
  let isInsertionAllowedInQuestionTitle = false;
  if (slice.content.childCount === 1) {
    slice.content.descendants((node) => {
      if (node.isTextblock || node.isInline) {
        isInsertionAllowedInQuestionTitle = true;
        return true;
      }
      isInsertionAllowedInQuestionTitle = false;
      return false;
    });
  }
  return isInsertionAllowedInQuestionTitle;
}

function isTablePaste(slice: Slice<any>, schema: Schema): boolean {
  const childCount = slice.content.childCount;

  if (childCount === 1) {
    const firstChild = slice.content.firstChild;
    if (firstChild) {
      return firstChild.type === schema.nodes.table;
    }
  }

  return false;
}

function pasteCellsIntoQuestionTitle(
  slice: Slice<any>,
  view: EditorView
): void {
  const { schema } = view.state;
  const firstChild = slice.content.firstChild;

  if (firstChild) {
    let cells = findChildren(firstChild, (node) => {
      return (
        node.type === schema.nodes.tableHeader ||
        node.type === schema.nodes.tableCell ||
        node.type === schema.nodes.tableFooter
      );
    });

    if (cells.length === 1) {
      const cellNode = cells[0].node;
      const paragraphs = findChildrenByType(firstChild, schema.nodes.paragraph);
      const hasOnlyParagraphs = cellNode.childCount === paragraphs.length;

      if (hasOnlyParagraphs && cellNode.childCount === 1) {
        let tr = view.state.tr;
        const { $from } = tr.selection;
        const node = $from.node();
        const paragraph = paragraphs[0];
        let expectedNode = node.copy(paragraph.node.content);
        const pos = $from.pos + expectedNode.nodeSize - 1;

        expectedNode.attrs.id = node.attrs.id;

        tr = tr.replaceSelectionWith(expectedNode);
        tr = tr.setSelection(TextSelection.near(tr.doc.resolve(pos - 1)));

        view.dispatch(tr);
      } else {
        emitNotification(view.state, {
          type: "warning",
          message: EditorDropNotification.DropNotAllowed
        });
      }
    }
  }
}
