import { Node } from "prosemirror-model";
import { Plugin, TextSelection, Transaction } from "prosemirror-state";
import {
  CommandConfiguration,
  CommandConfigurations,
  CommandFn,
  Extension,
  NodeConfig
} from "../../editor";
import {
  dropInsertPlugin,
  executeAtPos,
  isEnableAtPos
} from "../../editor/plugins/drop-insert-plugin";
import { emitNotification } from "../../editor/plugins/notification";
import {
  applyName,
  namePredicate,
  questionTitleCacheKey,
  questionTitleCachePlugin
} from "../../editor/plugins/question-title-cache";
import { Focus } from "../../editor/plugins/selection-focus/plugin";
import { insertBlock, isMac } from "../../util";
import { findChildren, findNode } from "../../util/nodes";
import { canInsert, focusedQuestionTitle } from "../../util/selection";
import { getLetterFromIndex } from "../../util/string";
import { getIdGenerator } from "../node-identifier";
import { updateName } from "../question-title";
import { questionTitleBindingKey } from "../question-title/plugins/question-title-binding";
import { customAreaNodeViewPlugin } from "./custom-area-node-view";
import { CustomAreaContentNode, CustomAreaNode } from "./nodes";
import { jumpHiddenAreas, pastePlugin } from "./plugins";
import { CustomAreaSchema, CustomAreaVariant } from "./schema";
import {
  createContentVariant,
  createCustomArea,
  focusedCustomArea
} from "./util";

interface InsertCustomAreaProps {
  areas: number;
}

interface UpdateCustomAreaProps {
  description?: string | null;
  name?: string | null;
  defaultVariantId?: string;
}

export interface UpdateCustomAreaActiveValue {
  description: string | null;
  name: string | null;
  defaultVariantId: string | null;
  variants: CustomAreaVariant[];
}

interface ContentVariantSelectionProps {
  id: string;
  focused: Focus;
}
interface ContentVariantNavigationProps {
  focused: Focus;
}

interface UpdateCustomAreaDefaultVariantProps {
  defaultVariantId: string;
}

export interface Area {
  id: string;
  label: string;
}

const questionTitleKey = questionTitleCacheKey();

export class CustomArea implements Extension<CustomAreaSchema> {
  get name(): string {
    return "customArea";
  }

  get nodes(): NodeConfig[] {
    return [new CustomAreaNode(), new CustomAreaContentNode()];
  }

  plugins(schema: CustomAreaSchema): Plugin[] {
    return [
      dropInsertPlugin((view, data, posAtCoords) => {
        if (data.type !== "customArea") {
          return false;
        }

        const command = this.insertCustomAreaCommand(schema);

        const isEnabled = isEnableAtPos(command, view, posAtCoords);
        if (
          !isEnabled({
            areas: 2
          })
        ) {
          emitNotification(view.state, {
            type: "warning",
            message: "drop.insert.invalid-location"
          });
        } else {
          executeAtPos(
            command.execute({
              areas: 2
            }),
            view,
            posAtCoords
          );
        }

        return true;
      }),
      questionTitleCachePlugin(
        schema.nodes.customArea,
        "CUSTOM_AREA.DEFAULT_NAME",
        namePredicate,
        applyName,
        questionTitleKey
      ),
      customAreaNodeViewPlugin(),
      jumpHiddenAreas(),
      pastePlugin(schema)
    ];
  }

  commands(schema: CustomAreaSchema): CommandConfigurations<CustomAreaSchema> {
    return {
      insertCustomArea: this.insertCustomAreaCommand(schema),
      updateCustomArea: this.updateCustomAreaCommand(schema),
      addArea: this.addAreaCommand(schema),
      deleteArea: this.removeAreaCommand(schema),
      updateDefaultArea: this.updateDefaultArea(schema),
      navigateBefore: this.navigateBeforeCommand(schema),
      navigateAfter: this.navigateAfterCommand(schema),
      selectArea: this.selectAreaCommand(schema)
    };
  }

  private insertCustomAreaCommand(
    schema: CustomAreaSchema
  ): CommandConfiguration<CustomAreaSchema, InsertCustomAreaProps, undefined> {
    return {
      isActive: () => {
        return false;
      },
      isEnabled: () => {
        return insertCustomArea(schema, 1);
      },
      execute: (props) => {
        if (props == null) {
          throw new Error(
            `To insert a custom area, InsertCustomAreaProps needs to be provided.`
          );
        }

        const { areas } = props;

        if (areas == null) {
          throw new Error(
            `To insert a custom area, areas need to be provided for InsertCustomAreaProps.`
          );
        }

        return insertCustomArea(schema, areas);
      },
      shortcuts: { [isMac() ? "Ctrl-a" : "Alt-a"]: { areas: 2 } }
    };
  }

  private updateCustomAreaCommand(
    schema: CustomAreaSchema
  ): CommandConfiguration<
    CustomAreaSchema,
    UpdateCustomAreaProps,
    UpdateCustomAreaActiveValue | undefined
  > {
    return {
      isActive: () => {
        return (state) => {
          const isFocused = focusedCustomArea(state) != null;
          if (isFocused) {
            const focused = focusedQuestionTitle(state);
            return focused != null ? false : true;
          } else {
            return false;
          }
        };
      },
      isEnabled: () => {
        return (state) => {
          return focusedCustomArea(state) != null;
        };
      },
      execute: (props) => {
        if (props == null) {
          throw new Error(
            `To update a custom area, UpdateCustomAreaProps needs to be provided.`
          );
        }

        return (state, dispatch) => {
          const focused = focusedCustomArea(state);

          return updateCustomArea(props, focused)(state, dispatch);
        };
      },
      activeValue: () => {
        return (state) => {
          const focused = focusedCustomArea(state);
          if (!focused) {
            return undefined;
          }

          const { node } = focused;

          const variants = new Array<CustomAreaVariant>();
          node.content.forEach((child, _off, index) => {
            variants.push({
              id: child.attrs.id,
              label: getLetterFromIndex(index)
            });
          });

          return {
            description: node.attrs.description,
            name: node.attrs.name,
            defaultVariantId: getDefaultVariantId(node, schema),
            variants: variants
          };
        };
      }
    };
  }

  private updateDefaultArea(
    schema: CustomAreaSchema
  ): CommandConfiguration<
    CustomAreaSchema,
    UpdateCustomAreaDefaultVariantProps,
    undefined
  > {
    return {
      isActive: () => {
        return (state) => {
          return focusedCustomArea(state) != null;
        };
      },
      isEnabled: () => {
        return (state) => {
          return focusedCustomArea(state) != null;
        };
      },
      execute: (props) => {
        return (state, dispatch) => {
          const focused = focusedCustomArea(state);

          if (!focused) {
            return false;
          }

          if (props) {
            const { defaultVariantId } = props;

            if (defaultVariantId == null) {
              throw new Error(
                `To update default area, default variant id need to be provided for UpdateCustomAreaDefaultVariantProps.`
              );
            }
            let child = findChildren(focused.node, (node) => {
              return (
                node.type === schema.nodes.contentVariant &&
                node.attrs.active === true
              );
            });
            if (!child) {
              return false;
            }

            return updateCustomArea(
              { defaultVariantId: defaultVariantId },
              focused
            )(state, dispatch);
          } else {
            let child = findChildren(focused.node, (node) => {
              return (
                node.type === schema.nodes.contentVariant &&
                node.attrs.active === true
              );
            });
            if (!child) {
              return false;
            }

            let defaultVariantId = child[0].node.attrs.id;

            return updateCustomArea(
              { defaultVariantId: defaultVariantId },
              focused
            )(state, dispatch);
          }
        };
      }
    };
  }

  private addAreaCommand(
    schema: CustomAreaSchema
  ): CommandConfiguration<CustomAreaSchema, {}, undefined> {
    return {
      isActive: () => {
        return (state) => {
          return focusedCustomArea(state) != null;
        };
      },
      isEnabled: () => {
        return (state) => {
          return focusedCustomArea(state) != null;
        };
      },
      execute: () => {
        return (state, dispatch) => {
          const focused = focusedCustomArea(state);

          if (!focused) {
            return false;
          }

          if (dispatch) {
            let tr = state.tr;

            let child = findChildren(focused.node, (node) => {
              return (
                node.type === schema.nodes.contentVariant &&
                node.attrs.active === true
              );
            });
            if (!child) {
              return false;
            }

            child.forEach((c) => {
              const attrs = { ...c.node.attrs };
              const updatedAttrs = {
                ...attrs,
                active: false
              };

              tr = tr.setNodeMarkup(
                focused.pos + 1 + c.pos,
                undefined,
                updatedAttrs
              );
            });

            const newNode = createContentVariant(schema, true, false);
            tr = tr.insert(focused.pos + focused.node.nodeSize - 1, newNode);

            const resolved = tr.doc.resolve(
              focused.pos + focused.node.nodeSize - 1
            );
            tr = tr.setSelection(TextSelection.near(resolved));
            dispatch(tr);
          }

          return true;
        };
      }
    };
  }

  private removeAreaCommand(
    schema: CustomAreaSchema
  ): CommandConfiguration<CustomAreaSchema, {}, undefined> {
    return {
      isActive: () => {
        return (state) => {
          return focusedCustomArea(state) != null;
        };
      },
      isEnabled: () => {
        return (state) => {
          return focusedCustomArea(state) != null;
        };
      },
      execute: () => {
        return (state, dispatch) => {
          const focused = focusedCustomArea(state);

          if (!focused) {
            return false;
          }

          if (dispatch) {
            let tr = state.tr;

            let child = findChildren(focused.node, (node) => {
              return (
                node.type === schema.nodes.contentVariant &&
                node.attrs.active === true
              );
            });
            if (!child) {
              return false;
            }

            child.forEach((c) => {
              const from = focused.pos + 1 + c.pos;
              const to = from + c.node.nodeSize;

              const $from = state.doc.resolve(from);
              const $to = state.doc.resolve(to);

              const nodeBefore = $from.nodeBefore;
              const nodeAfter = $to.nodeAfter;

              if (nodeBefore) {
                const index = $from.index();
                const attrs = {
                  ...nodeBefore.attrs
                };
                const updatedAttrs = {
                  ...attrs,
                  active: true,
                  default: c.node.attrs.default === true ? true : attrs.default
                };
                const posAtPreviousIndex = $from.posAtIndex(index - 1);
                tr = tr.setNodeMarkup(
                  posAtPreviousIndex,
                  undefined,
                  updatedAttrs
                );
                const resolved = tr.doc.resolve(posAtPreviousIndex + 1);
                tr = tr.setSelection(TextSelection.near(resolved));
                tr = tr.delete(from, to);
              } else if (nodeAfter) {
                const index = $to.index();
                const attrs = {
                  ...nodeAfter.attrs
                };
                const updatedAttrs = {
                  ...attrs,
                  active: true,
                  default: c.node.attrs.default === true ? true : attrs.default
                };
                const posAtNextIndex = $to.posAtIndex(index);
                tr = tr.setNodeMarkup(posAtNextIndex, undefined, updatedAttrs);
                const resolved = tr.doc.resolve(posAtNextIndex + 1);
                tr = tr.setSelection(TextSelection.near(resolved));
                tr = tr.delete(from, to);
              } else {
                tr = tr.delete(
                  focused.pos,
                  focused.pos + focused.node.nodeSize
                );
              }
            });

            dispatch(tr);
          }

          return true;
        };
      }
    };
  }

  private navigateBeforeCommand(
    schema: CustomAreaSchema
  ): CommandConfiguration<
    CustomAreaSchema,
    ContentVariantNavigationProps,
    undefined
  > {
    return {
      isActive: () => {
        return (state) => {
          return focusedCustomArea(state) != null;
        };
      },
      isEnabled: () => {
        return (state) => {
          const isBindingQuestionTitle = questionTitleBindingKey.getState(
            state
          );
          if (isBindingQuestionTitle != null) {
            return false;
          }

          const focused = focusedCustomArea(state);

          if (focused) {
            const child = findNode(focused.node, (node) => {
              return (
                node.type === schema.nodes.contentVariant &&
                node.attrs.active === true
              );
            });

            if (!child) {
              return false;
            }

            const from = focused.pos + 1 + child.pos;

            const $from = state.doc.resolve(from);

            const nodeBefore = $from.nodeBefore;

            return nodeBefore ? true : false;
          }

          return true;
        };
      },
      execute: (props) => {
        if (props == null) {
          throw new Error(
            `To navigate, ContentVariantNavigationProps needs to be provided.`
          );
        }

        const { focused } = props;

        if (!focused) {
          throw new Error(
            `To navigate, focused need to be provided for ContentVariantNavigationProps.`
          );
        }

        return (state, dispatch) => {
          if (dispatch) {
            let tr = state.tr;

            const child = findNode(focused.node, (node) => {
              return (
                node.type === schema.nodes.contentVariant &&
                node.attrs.active === true
              );
            });

            if (!child) {
              return false;
            }

            const from = focused.pos + 1 + child.pos;
            const $from = state.doc.resolve(from);

            const nodeBefore = $from.nodeBefore;
            if (nodeBefore != null) {
              tr = setActive(tr, schema, focused, nodeBefore.attrs.id);
            }

            dispatch(tr);
          }

          return true;
        };
      },
      requiresEditable: false
    };
  }

  private navigateAfterCommand(
    schema: CustomAreaSchema
  ): CommandConfiguration<
    CustomAreaSchema,
    ContentVariantNavigationProps,
    undefined
  > {
    return {
      isActive: () => {
        return (state) => {
          return focusedCustomArea(state) != null;
        };
      },
      isEnabled: () => {
        return (state) => {
          const isBindingQuestionTitle = questionTitleBindingKey.getState(
            state
          );
          if (isBindingQuestionTitle != null) {
            return false;
          }

          const focused = focusedCustomArea(state);

          if (focused) {
            const child = findNode(focused.node, (node) => {
              return (
                node.type === schema.nodes.contentVariant &&
                node.attrs.active === true
              );
            });

            if (!child) {
              return false;
            }

            const from = focused.pos + 1 + child.pos;
            const to = from + child.node.nodeSize;

            const $to = state.doc.resolve(to);

            const nodeAfter = $to.nodeAfter;

            return nodeAfter ? true : false;
          }

          return true;
        };
      },
      execute: (props) => {
        if (props == null) {
          throw new Error(
            `To navigate, ContentVariantNavigationProps needs to be provided.`
          );
        }

        const { focused } = props;

        if (!focused) {
          throw new Error(
            `To navigate, focused need to be provided for ContentVariantNavigationProps.`
          );
        }

        return (state, dispatch) => {
          if (dispatch) {
            let tr = state.tr;

            const child = findNode(focused.node, (node) => {
              return (
                node.type === schema.nodes.contentVariant &&
                node.attrs.active === true
              );
            });

            if (!child) {
              return false;
            }

            const from = focused.pos + 1 + child.pos;
            const to = from + child.node.nodeSize;

            const $to = state.doc.resolve(to);
            const nodeAfter = $to.nodeAfter;

            if (nodeAfter != null) {
              tr = setActive(tr, schema, focused, nodeAfter.attrs.id);
            }

            dispatch(tr);
          }

          return true;
        };
      },
      requiresEditable: false
    };
  }

  private selectAreaCommand(
    schema: CustomAreaSchema
  ): CommandConfiguration<
    CustomAreaSchema,
    ContentVariantSelectionProps,
    undefined
  > {
    return {
      isActive: () => {
        return (state) => {
          return focusedCustomArea(state) != null;
        };
      },
      isEnabled: () => {
        return (state) => {
          const isBindingQuestionTitle = questionTitleBindingKey.getState(
            state
          );
          if (isBindingQuestionTitle != null) {
            return false;
          }

          return true;
        };
      },
      execute: (props) => {
        if (props == null) {
          throw new Error(
            `To select a variant, ContentVariantSelectionProps needs to be provided.`
          );
        }

        const { id, focused } = props;

        if (id == null) {
          throw new Error(
            `To select a variant, id need to be provided for ContentVariantSelectionProps.`
          );
        }

        if (!focused) {
          throw new Error(
            `To select a variant, focused need to be provided for ContentVariantSelectionProps.`
          );
        }

        return (state, dispatch) => {
          if (dispatch) {
            let tr = state.tr;
            tr = setActive(tr, schema, focused, id);

            dispatch(tr);
          }

          return true;
        };
      },
      requiresEditable: false
    };
  }
}

function insertCustomArea(
  schema: CustomAreaSchema,
  areas: number
): CommandFn<CustomAreaSchema> {
  return (state, dispatch) => {
    if (!canInsert(schema.nodes.customArea)(state)) {
      return false;
    } else {
      if (dispatch) {
        const customArea = createCustomArea(schema, areas);

        if (customArea == null) {
          return false;
        }

        let tr = state.tr;
        tr = insertBlock(
          tr,
          state.schema,
          customArea,
          true,
          getIdGenerator(state)
        );

        dispatch(tr);
      }
      return true;
    }
  };
}

function updateCustomArea(
  props: UpdateCustomAreaProps,
  customArea: { node: Node<CustomAreaSchema>; pos: number } | undefined
): CommandFn<CustomAreaSchema> {
  return (state, dispatch) => {
    const { schema } = state;

    if (customArea == null) {
      return false;
    }

    if (dispatch) {
      const { node, pos } = customArea;
      let attrs = { ...node.attrs };

      if (props.description !== undefined) {
        attrs = { ...attrs, description: props.description };
      }

      if (props.name !== undefined) {
        attrs = updateName(attrs, props.name);
      }

      let tr = state.tr;
      tr = tr.setNodeMarkup(pos, undefined, attrs);

      if (props.defaultVariantId !== undefined) {
        const defaultVariantId = getDefaultVariantId(node, schema);
        if (defaultVariantId !== props.defaultVariantId) {
          tr = setDefault(tr, schema, customArea, props.defaultVariantId);
        }
      }

      dispatch(tr);
    }
    return true;
  };
}

export function setActive(
  tr: Transaction,
  schema: CustomAreaSchema,
  focus: Focus,
  id: string
): Transaction<CustomAreaSchema> {
  const { node, pos } = focus;
  let variants = findChildren(node, (node) => {
    return node.type === schema.nodes.contentVariant;
  });

  if (!variants) {
    return tr;
  }

  variants.forEach((variant) => {
    const from = pos + 1 + variant.pos;
    const $from = tr.doc.resolve(from);

    const index = $from.index();
    const updatedAttrs = {
      ...variant.node.attrs,
      active: variant.node.attrs.id === id
    };

    tr = tr.setNodeMarkup($from.posAtIndex(index), undefined, updatedAttrs);

    if (updatedAttrs.active) {
      const resolvedPos = tr.doc.resolve($from.posAtIndex(index));
      tr = tr.setSelection(TextSelection.near(resolvedPos));
    }
  });

  return tr;
}

function setDefault(
  tr: Transaction<CustomAreaSchema>,
  schema: CustomAreaSchema,
  focus: Focus,
  id: string
): Transaction<CustomAreaSchema> {
  const { node, pos } = focus;
  let variants = findChildren(node, (node) => {
    return node.type === schema.nodes.contentVariant;
  });

  if (!variants) {
    return tr;
  }

  variants.forEach((variant) => {
    const from = pos + 1 + variant.pos;
    const $from = tr.doc.resolve(from);

    const index = $from.index();
    const updatedAttrs = {
      ...variant.node.attrs,
      active: variant.node.attrs.id === id,
      default: variant.node.attrs.id === id
    };

    tr = tr.setNodeMarkup($from.posAtIndex(index), undefined, updatedAttrs);
    if (updatedAttrs.active) {
      const resolvedPos = tr.doc.resolve($from.posAtIndex(index));
      tr = tr.setSelection(TextSelection.near(resolvedPos));
    }
  });

  return tr;
}

function getDefaultVariantId(
  node: Node<CustomAreaSchema>,
  schema: CustomAreaSchema
): string | null {
  const variants = findChildren(node, (node) => {
    return (
      node.type === schema.nodes.contentVariant && node.attrs.default === true
    );
  });

  if (variants == null) {
    return null;
  }

  if (variants.length === 0) {
    return null;
  }

  const { node: defaultVariant } = variants[0];

  return defaultVariant.attrs.id;
}
