import {
  Editor as BlueXEditor,
  InputRankActiveValue,
  InputRankControlType,
  InputRankWidth,
  Schema
} from "@blue-x/editor";
import React, { useCallback, useMemo } from "react";
import {
  CheckboxFormControl,
  NumberFormControl,
  QuestionTitleFormControl,
  SelectFormControl,
  TextFormControl
} from "../ProportiesPanelControls";
import "./InputRank.css";

interface InputRankPropertiesPanelProps {
  editor: BlueXEditor<Schema>;
}

export const InputRankPropertiesPanel: React.FC<InputRankPropertiesPanelProps> = (
  props
) => {
  const { editor } = props;
  const { commands } = editor;

  const update = useCallback(
    (value) => {
      commands.updateInputRank.execute(value, false);
    },
    [commands]
  );

  const activeValue: InputRankActiveValue = useMemo(() => {
    return commands.updateInputRank.activeValue();
  }, [commands]);

  const controlType = activeValue.controlType;
  const sectionCount = activeValue.sectionCount;

  let minWidth = 25;
  switch (sectionCount) {
    case 1:
      minWidth = 25;
      break;

    case 2:
      minWidth = 50;
      break;

    case 3:
      minWidth = 75;
      break;

    default:
      minWidth = 100;
      break;
  }

  return (
    <div className="InputRankPropertiesPanel">
      <div className="InputRank-label">Display</div>
      <SelectFormControl
        label={"Type"}
        value={controlType}
        onUpdate={(value) => {
          update({ controlType: value });
        }}
      >
        <option value={InputRankControlType.dragDrop}>Drag and drop</option>
      </SelectFormControl>

      <NumberFormControl
        label={"Width"}
        value={activeValue.width < minWidth ? minWidth : activeValue.width}
        min={minWidth}
        max={InputRankWidth.maximum}
        step={1}
        disabled={sectionCount >= 4}
        onUpdate={(value) => {
          update({ width: value });
        }}
      />
      <hr></hr>
      <div className="InputRank-label">Settings</div>

      <QuestionTitleFormControl
        editor={editor}
        label={"Question title*"}
        value={activeValue.questionTitle}
      />

      <TextFormControl
        label={"Description"}
        value={activeValue.description}
        onUpdate={(value) => {
          update({ description: value });
        }}
      />

      <TextFormControl
        label={"Question ID"}
        value={activeValue.coding}
        onUpdate={(value) => {
          update({ coding: value });
        }}
      />

      <CheckboxFormControl
        label={"Allow repeat rank value"}
        value={activeValue.repeatableScale}
        disabled={controlType === InputRankControlType.dragDrop}
        onUpdate={(value) => {
          update({ repeatableScale: value });
        }}
      />

      <CheckboxFormControl
        label={"Allow repeat item in different sections"}
        value={activeValue.repeatableItemSections}
        onUpdate={(value) => {
          update({ repeatableItemSections: value });
        }}
      />

      <CheckboxFormControl
        label={"Randomize items"}
        value={activeValue.randomizeItems}
        onUpdate={(value) => {
          update({ randomizeItems: value });
        }}
      />

      <CheckboxFormControl
        label={"Randomize sections"}
        value={activeValue.randomizeSections}
        onUpdate={(value) => {
          update({ randomizeSections: value });
        }}
      />

      <CheckboxFormControl
        label={"Select all"}
        value={activeValue.selectAll}
        disabled={controlType === InputRankControlType.dropdown}
        onUpdate={(value) => {
          update({ selectAll: value });
        }}
      />

      <CheckboxFormControl
        label={"Other specify"}
        value={activeValue.otherSpecify}
        onUpdate={(value) => {
          update({ otherSpecify: value });
        }}
      />

      <CheckboxFormControl
        label={"Respondents must specify"}
        value={activeValue.respondentsMustSpecify}
        onUpdate={(value) => {
          update({ respondentsMustSpecify: value });
        }}
      />

      <hr></hr>
      <div className="InputRank-label">Validation</div>

      <CheckboxFormControl
        label={"Required"}
        value={activeValue.required}
        onUpdate={(value) => {
          update({ required: value });
        }}
      />

      <NumberFormControl
        label={"Minimum number of ranked items"}
        value={activeValue.minRankedItem}
        max={activeValue.maxRankedItem}
        step={1}
        onUpdate={(value) => {
          update({ minRankedItem: value });
        }}
      />

      <NumberFormControl
        label={"Maximum number of ranked items"}
        value={activeValue.maxRankedItem}
        min={activeValue.minRankedItem}
        step={1}
        onUpdate={(value) => {
          update({ maxRankedItem: value });
        }}
      />
    </div>
  );
};
