export const en = {
  "ACTION_BUTTONS.ADD_AREA.TITLE": "Add",
  "ACTION_BUTTONS.ADD_BINDING.TITLE": "Add question title",
  "ACTION_BUTTONS.CANCEL_BINDING.TITLE": "Cancel",
  "ACTION_BUTTONS.REMOVE_AREA.TITLE": "Remove",
  "ACTION_BUTTONS.REMOVE_BINDING.TITLE": "Remove question title",
  "ACTION_BUTTONS.REQUIRED.TITLE": "Toggle required",
  "ACTION_BUTTONS.SET_AS_DEFAULT.TITLE": "Set as default",
  "BAR_CHART.MOCKED_LABEL": "Mocked data",
  "BAR_CHART.NAME": "Column chart",
  BAR_CHART_HIDDEN_PLURAL: "respondents didn’t see this question",
  BAR_CHART_HIDDEN_SINGULAR: "respondent didn’t see this question",
  BAR_CHART_SKIPPED_PLURAL: "respondents skipped this question",
  BAR_CHART_SKIPPED_SINGULAR: "respondent skipped this question",
  "CONSTANT_SUM.MATRIX.ITEM": "Untitled item",
  "CUSTOM_AREA.DEFAULT_NAME": "Custom area {{count}}",
  "CUSTOM_GRID.COLUMN_ACTIONS.ADD_AFTER": "Insert column after",
  "CUSTOM_GRID.COLUMN_ACTIONS.ADD_BEFORE": "Insert column before",
  "CUSTOM_GRID.COLUMN_ACTIONS.DELETE": "Delete column",
  "CUSTOM_GRID.DEFAULT_NAME": "Custom grid {{count}}",
  "CUSTOM_GRID.ROW_ACTIONS.ADD_AFTER": "Insert row after",
  "CUSTOM_GRID.ROW_ACTIONS.ADD_BEFORE": "Insert row before",
  "CUSTOM_GRID.ROW_ACTIONS.DELETE": "Delete row",
  "INPUT_CHOICE.ALL_OF_THE_ABOVE_CHOICE": "All of the above",
  "INPUT_CHOICE.DEFAULT_QUESTION_TITLE": "Choice question {{count}}",
  "INPUT_CHOICE.NONE_OF_THE_ABOVE_CHOICE": "None of the above",
  "INPUT_CHOICE.OTHER_SPECIFY_CHOICE": "Other",
  "INPUT_CONSTANT_SUM.DEFAULT_FUNCTION_TITLE": "Function component {{count}}",
  "INPUT_CONSTANT_SUM.ITEM_CHOICE": "Item {{number}}",
  "INPUT_CONSTANT_SUM.OTHER_SPECIFY_CHOICE": "Other",
  "INPUT_CONSTANT_SUM.REMAINDER": "Remainder",
  "INPUT_CONSTANT_SUM.TOTAL": "Total",
  "INPUT_DATETIME.DATE_FORMATS.DD_MM_YYYY": "dd-MM-yyyy",
  "INPUT_DATETIME.DATE_FORMATS.DD_MM_YYYY_HH_MM_24H": "dd-MM-yyyy HH:mm (24h)",
  "INPUT_DATETIME.DATE_FORMATS.DD_MM_YYYY_HH_MM_AM_PM":
    "dd-MM-yyyy hh:mm (AM/PM)",
  "INPUT_DATETIME.DATE_FORMATS.MM_DD_YYYY": "MM-dd-yyyy",
  "INPUT_DATETIME.DATE_FORMATS.MM_DD_YYYY_HH_MM_24H": "MM-dd-yyyy HH:mm (24h)",
  "INPUT_DATETIME.DATE_FORMATS.MM_DD_YYYY_HH_MM_AM_PM":
    "MM-dd-yyyy hh:mm (AM/PM)",
  "INPUT_DATETIME.DATE_FORMATS.YYYY_MM_DD": "yyyy-MM-dd",
  "INPUT_DATETIME.DATE_FORMATS.YYYY_MM_DD_HH_MM_24H": "yyyy-MM-dd HH:mm (24h)",
  "INPUT_DATETIME.DATE_FORMATS.YYYY_MM_DD_HH_MM_AM_PM":
    "yyyy-MM-dd hh:mm (AM/PM)",
  "INPUT_DATETIME.DEFAULT_QUESTION_TITLE": "Date question {{count}}",
  "INPUT_FILE.AUDIO_INSTRUCTION_PLURAL":
    "Drop audio files here or click to upload.",
  "INPUT_FILE.AUDIO_INSTRUCTION_SINGULAR":
    "Drop audio file here or click to upload.",
  "INPUT_FILE.DEFAULT_QUESTION_TITLE": "Media response question {{count}}",
  "INPUT_FILE.FILE_INSTRUCTION_PLURAL": "Drop files here or click to upload.",
  "INPUT_FILE.FILE_INSTRUCTION_SINGULAR": "Drop file here or click to upload.",
  "INPUT_FILE.IMAGE_INSTRUCTION_PLURAL":
    "Drop image files here or click to upload.",
  "INPUT_FILE.IMAGE_INSTRUCTION_SINGULAR":
    "Drop image file here or click to upload.",
  "INPUT_FILE.INVALID_VALUE": "invalid value",
  "INPUT_FILE.MAX_FILE_SIZE": "Maximum file size: {{fileSize}}.",
  "INPUT_FILE.MIN_HEIGHT": "Minimum height: {{minHeight}}px",
  "INPUT_FILE.MIN_WIDTH": "Minimum width: {{minWidth}}px",
  "INPUT_FILE.MIN_WIDTH_AND_MIN_HEIGHT":
    "Minimum width: {{minWidth}}px - Minimum height: {{minHeight}}px",
  "INPUT_FILE.VIDEO_INSTRUCTION_PLURAL":
    "Drop video files here or click to upload.",
  "INPUT_FILE.VIDEO_INSTRUCTION_SINGULAR":
    "Drop video file here or click to upload.",
  "INPUT_LIKERT.AFFECT_ON_X.0": "No affect",
  "INPUT_LIKERT.AFFECT_ON_X.1": "Minor affect",
  "INPUT_LIKERT.AFFECT_ON_X.2": "Neutral",
  "INPUT_LIKERT.AFFECT_ON_X.3": "Moderate affect",
  "INPUT_LIKERT.AFFECT_ON_X.4": "Major affect",
  "INPUT_LIKERT.AMOUNT_OF_USE.0": "Never use",
  "INPUT_LIKERT.AMOUNT_OF_USE.1": "Almost never",
  "INPUT_LIKERT.AMOUNT_OF_USE.2": "Occasionally/Sometimes",
  "INPUT_LIKERT.AMOUNT_OF_USE.3": "Almost every time",
  "INPUT_LIKERT.AMOUNT_OF_USE.4": "Frequently use",
  "INPUT_LIKERT.BARRIERS.0": "Not a barrier",
  "INPUT_LIKERT.BARRIERS.1": "Somewhat of a barrier",
  "INPUT_LIKERT.BARRIERS.2": "Moderate barrier",
  "INPUT_LIKERT.BARRIERS.3": "Extreme barrier",
  "INPUT_LIKERT.COMPARISON_OF_TWO_PRODUCTS.0": "Much worse",
  "INPUT_LIKERT.COMPARISON_OF_TWO_PRODUCTS.1": "Somewhat worse",
  "INPUT_LIKERT.COMPARISON_OF_TWO_PRODUCTS.2": "About the same",
  "INPUT_LIKERT.COMPARISON_OF_TWO_PRODUCTS.3": "Somewhat better",
  "INPUT_LIKERT.COMPARISON_OF_TWO_PRODUCTS.4": "Much better",
  "INPUT_LIKERT.DEFAULT_QUESTION_TITLE": "Scale question {{count}}",
  "INPUT_LIKERT.FREQUENCY.0": "Never",
  "INPUT_LIKERT.FREQUENCY.1": "Rarely",
  "INPUT_LIKERT.FREQUENCY.2": "Occasionally",
  "INPUT_LIKERT.FREQUENCY.3": "A moderate amount",
  "INPUT_LIKERT.FREQUENCY.4": "A great deal",
  "INPUT_LIKERT.FREQUENCY_5_POINT.0": "Never",
  "INPUT_LIKERT.FREQUENCY_5_POINT.1": "Rarely",
  "INPUT_LIKERT.FREQUENCY_5_POINT.2": "Sometimes",
  "INPUT_LIKERT.FREQUENCY_5_POINT.3": "Often",
  "INPUT_LIKERT.FREQUENCY_5_POINT.4": "Always",
  "INPUT_LIKERT.FREQUENCY_7_POINT.0": "Never",
  "INPUT_LIKERT.FREQUENCY_7_POINT.1":
    "Rarely, in less than 10% of the chances when I could have",
  "INPUT_LIKERT.FREQUENCY_7_POINT.2":
    "Occasionally, in about 30% of the chances when I could have",
  "INPUT_LIKERT.FREQUENCY_7_POINT.3":
    "Sometimes, in about 50% of the chances when I could have",
  "INPUT_LIKERT.FREQUENCY_7_POINT.4":
    "Frequently, in about 70% of the chances when I could have",
  "INPUT_LIKERT.FREQUENCY_7_POINT.5":
    "Usually, in about 90% of the chances I could have",
  "INPUT_LIKERT.FREQUENCY_7_POINT.6": "Every time",
  "INPUT_LIKERT.FREQUENCY_OF_USE.0": "Never",
  "INPUT_LIKERT.FREQUENCY_OF_USE.1": "Almost never",
  "INPUT_LIKERT.FREQUENCY_OF_USE.2": "Occasionally/Sometimes",
  "INPUT_LIKERT.FREQUENCY_OF_USE.3": "Almost every time",
  "INPUT_LIKERT.FREQUENCY_OF_USE.4": "Every time",
  "INPUT_LIKERT.GOOD_BAD.0": "Very negative",
  "INPUT_LIKERT.GOOD_BAD.1": "Neutral",
  "INPUT_LIKERT.GOOD_BAD.2": "Very positive",
  "INPUT_LIKERT.KNOWLEDGE_OF_ACTION.0": "Never true",
  "INPUT_LIKERT.KNOWLEDGE_OF_ACTION.1": "Rarely true",
  "INPUT_LIKERT.KNOWLEDGE_OF_ACTION.2": "Sometimes but infrequently true",
  "INPUT_LIKERT.KNOWLEDGE_OF_ACTION.3": "Neutral",
  "INPUT_LIKERT.KNOWLEDGE_OF_ACTION.4": "Sometimes true",
  "INPUT_LIKERT.KNOWLEDGE_OF_ACTION.5": "Usually true",
  "INPUT_LIKERT.KNOWLEDGE_OF_ACTION.6": "Always true",
  "INPUT_LIKERT.LEVEL_OF_ACCEPTABILITY.0": "Totally unacceptable",
  "INPUT_LIKERT.LEVEL_OF_ACCEPTABILITY.1": "Unacceptable",
  "INPUT_LIKERT.LEVEL_OF_ACCEPTABILITY.2": "Slightly unacceptable",
  "INPUT_LIKERT.LEVEL_OF_ACCEPTABILITY.3": "Neutral",
  "INPUT_LIKERT.LEVEL_OF_ACCEPTABILITY.4": "Slightly acceptable",
  "INPUT_LIKERT.LEVEL_OF_ACCEPTABILITY.5": "Acceptable",
  "INPUT_LIKERT.LEVEL_OF_ACCEPTABILITY.6": "Perfectly Acceptable",
  "INPUT_LIKERT.LEVEL_OF_AGREEMENT.0": "Strongly disagree",
  "INPUT_LIKERT.LEVEL_OF_AGREEMENT.1": "Disagree",
  "INPUT_LIKERT.LEVEL_OF_AGREEMENT.2": "Somewhat disagree",
  "INPUT_LIKERT.LEVEL_OF_AGREEMENT.3": "Neither agree nor disagree",
  "INPUT_LIKERT.LEVEL_OF_AGREEMENT.4": "Somewhat agree",
  "INPUT_LIKERT.LEVEL_OF_AGREEMENT.5": "Agree",
  "INPUT_LIKERT.LEVEL_OF_AGREEMENT.6": "Strongly agree",
  "INPUT_LIKERT.LEVEL_OF_AGREEMENT_2.0": "Strongly disagree",
  "INPUT_LIKERT.LEVEL_OF_AGREEMENT_2.1": "Disagree",
  "INPUT_LIKERT.LEVEL_OF_AGREEMENT_2.2": "Neither agree nor disagree",
  "INPUT_LIKERT.LEVEL_OF_AGREEMENT_2.3": "Agree",
  "INPUT_LIKERT.LEVEL_OF_AGREEMENT_2.4": "Strongly agree",
  "INPUT_LIKERT.LEVEL_OF_APPROPRIATENESS.0": "Absolutely inappropriate",
  "INPUT_LIKERT.LEVEL_OF_APPROPRIATENESS.1": "Inappropriate",
  "INPUT_LIKERT.LEVEL_OF_APPROPRIATENESS.2": "Slightly inappropriate",
  "INPUT_LIKERT.LEVEL_OF_APPROPRIATENESS.3": "Neutral",
  "INPUT_LIKERT.LEVEL_OF_APPROPRIATENESS.4": "Slightly appropriate",
  "INPUT_LIKERT.LEVEL_OF_APPROPRIATENESS.5": "Appropriate",
  "INPUT_LIKERT.LEVEL_OF_APPROPRIATENESS.6": "Absolutely appropriate",
  "INPUT_LIKERT.LEVEL_OF_AWARENESS.0": "Not at all aware",
  "INPUT_LIKERT.LEVEL_OF_AWARENESS.1": "Slightly aware",
  "INPUT_LIKERT.LEVEL_OF_AWARENESS.2": "Somewhat aware",
  "INPUT_LIKERT.LEVEL_OF_AWARENESS.3": "Moderately aware",
  "INPUT_LIKERT.LEVEL_OF_AWARENESS.4": "Extremely aware",
  "INPUT_LIKERT.LEVEL_OF_CONCERN.0": "Not at all concerned",
  "INPUT_LIKERT.LEVEL_OF_CONCERN.1": "Slightly concerned",
  "INPUT_LIKERT.LEVEL_OF_CONCERN.2": "Somewhat concerned",
  "INPUT_LIKERT.LEVEL_OF_CONCERN.3": "Moderately concerned",
  "INPUT_LIKERT.LEVEL_OF_CONCERN.4": "Extremely concerned",
  "INPUT_LIKERT.LEVEL_OF_CONSIDERATION.0": "Would not consider",
  "INPUT_LIKERT.LEVEL_OF_CONSIDERATION.1": "Might or might not consider",
  "INPUT_LIKERT.LEVEL_OF_CONSIDERATION.2": "Definitely consider",
  "INPUT_LIKERT.LEVEL_OF_DESIRABILITY.0": "Very undesirable",
  "INPUT_LIKERT.LEVEL_OF_DESIRABILITY.1": "Undesirable",
  "INPUT_LIKERT.LEVEL_OF_DESIRABILITY.2": "Neutral",
  "INPUT_LIKERT.LEVEL_OF_DESIRABILITY.3": "Desirable",
  "INPUT_LIKERT.LEVEL_OF_DESIRABILITY.4": "Very desirable",
  "INPUT_LIKERT.LEVEL_OF_DETRACTION.0": "Detracted very little",
  "INPUT_LIKERT.LEVEL_OF_DETRACTION.1": "Neutral",
  "INPUT_LIKERT.LEVEL_OF_DETRACTION.2": "Detracted very much",
  "INPUT_LIKERT.LEVEL_OF_DIFFICULTY.0": "Very difficult",
  "INPUT_LIKERT.LEVEL_OF_DIFFICULTY.1": "Difficult",
  "INPUT_LIKERT.LEVEL_OF_DIFFICULTY.2": "Neutral",
  "INPUT_LIKERT.LEVEL_OF_DIFFICULTY.3": "Easy",
  "INPUT_LIKERT.LEVEL_OF_DIFFICULTY.4": "Very easy",
  "INPUT_LIKERT.LEVEL_OF_FAMILIARITY.0": "Not at all familiar",
  "INPUT_LIKERT.LEVEL_OF_FAMILIARITY.1": "Slightly familiar",
  "INPUT_LIKERT.LEVEL_OF_FAMILIARITY.2": "Somewhat familiar",
  "INPUT_LIKERT.LEVEL_OF_FAMILIARITY.3": "Moderately familiar",
  "INPUT_LIKERT.LEVEL_OF_FAMILIARITY.4": "Extremely familiar",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE.0": "Not at all important",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE.1": "Low importance",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE.2": "Slightly important",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE.3": "Neutral",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE.4": "Moderately important",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE.5": "Very important",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE.6": "Extremely important",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE_NUMERIC_5_POINT.0": "1",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE_NUMERIC_5_POINT.1": "2",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE_NUMERIC_5_POINT.2": "3",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE_NUMERIC_5_POINT.3": "4",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE_NUMERIC_5_POINT.4": "5",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE_NUMERIC_7_POINT.0": "1",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE_NUMERIC_7_POINT.1": "2",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE_NUMERIC_7_POINT.2": "3",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE_NUMERIC_7_POINT.3": "4",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE_NUMERIC_7_POINT.4": "5",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE_NUMERIC_7_POINT.5": "6",
  "INPUT_LIKERT.LEVEL_OF_IMPORTANCE_NUMERIC_7_POINT.6": "7",
  "INPUT_LIKERT.LEVEL_OF_INFLUENCE.0": "Not at all influential",
  "INPUT_LIKERT.LEVEL_OF_INFLUENCE.1": "Slightly influential",
  "INPUT_LIKERT.LEVEL_OF_INFLUENCE.2": "Somewhat influential",
  "INPUT_LIKERT.LEVEL_OF_INFLUENCE.3": "Very influential",
  "INPUT_LIKERT.LEVEL_OF_INFLUENCE.4": "Extremely influential",
  "INPUT_LIKERT.LEVEL_OF_PARTICIPATION.0": "No, and not considered",
  "INPUT_LIKERT.LEVEL_OF_PARTICIPATION.1": "No, but considered",
  "INPUT_LIKERT.LEVEL_OF_PARTICIPATION.2": "Yes",
  "INPUT_LIKERT.LEVEL_OF_PROBABILITY.0": "Not probable",
  "INPUT_LIKERT.LEVEL_OF_PROBABILITY.1": "Somewhat improbable",
  "INPUT_LIKERT.LEVEL_OF_PROBABILITY.2": "Neutral",
  "INPUT_LIKERT.LEVEL_OF_PROBABILITY.3": "Somewhat probable",
  "INPUT_LIKERT.LEVEL_OF_PROBABILITY.4": "Very probable",
  "INPUT_LIKERT.LEVEL_OF_PROBLEM.0": "Not at all a problem",
  "INPUT_LIKERT.LEVEL_OF_PROBLEM.1": "Minor problem",
  "INPUT_LIKERT.LEVEL_OF_PROBLEM.2": "Moderate problem",
  "INPUT_LIKERT.LEVEL_OF_PROBLEM.3": "Serious problem",
  "INPUT_LIKERT.LEVEL_OF_QUALITY_5_POINT.0": "Poor",
  "INPUT_LIKERT.LEVEL_OF_QUALITY_5_POINT.1": "Fair",
  "INPUT_LIKERT.LEVEL_OF_QUALITY_5_POINT.2": "Good",
  "INPUT_LIKERT.LEVEL_OF_QUALITY_5_POINT.3": "Very good",
  "INPUT_LIKERT.LEVEL_OF_QUALITY_5_POINT.4": "Excellent",
  "INPUT_LIKERT.LEVEL_OF_RESPONSIBILITY.0": "Not at all responsible",
  "INPUT_LIKERT.LEVEL_OF_RESPONSIBILITY.1": "Somewhat responsible",
  "INPUT_LIKERT.LEVEL_OF_RESPONSIBILITY.2": "Mostly responsible",
  "INPUT_LIKERT.LEVEL_OF_RESPONSIBILITY.3": "Completely responsible",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_5_POINT.0": "Very dissatisfied",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_5_POINT.1": "Dissatisfied",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_5_POINT.2": "Unsure",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_5_POINT.3": "Satisfied",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_5_POINT.4": "Very satisfied",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_5_POINT_2.0": "Not at all satisfied",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_5_POINT_2.1": "Slightly satisfied",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_5_POINT_2.2": "Moderately satisfied",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_5_POINT_2.3": "Very satisfied",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_5_POINT_2.4": "Extremely satisfied",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_7_POINT.0": "Completely dissatisfied",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_7_POINT.1": "Mostly dissatisfied",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_7_POINT.2": "Somewhat dissatisfied",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_7_POINT.3":
    "Neither satisfied nor dissatisfied",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_7_POINT.4": "Somewhat satisfied",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_7_POINT.5": "Mostly satisfied",
  "INPUT_LIKERT.LEVEL_OF_SATISFACTION_7_POINT.6": "Completely satisfied",
  "INPUT_LIKERT.LEVEL_OF_SUPPORT_OPPOSITION.0": "Strongly oppose",
  "INPUT_LIKERT.LEVEL_OF_SUPPORT_OPPOSITION.1": "Somewhat oppose",
  "INPUT_LIKERT.LEVEL_OF_SUPPORT_OPPOSITION.2": "Neutral",
  "INPUT_LIKERT.LEVEL_OF_SUPPORT_OPPOSITION.3": "Somewhat favor",
  "INPUT_LIKERT.LEVEL_OF_SUPPORT_OPPOSITION.4": "Strongly favor",
  "INPUT_LIKERT.LIKELIHOOD.0": "Extremely unlikely",
  "INPUT_LIKERT.LIKELIHOOD.1": "Unlikely",
  "INPUT_LIKERT.LIKELIHOOD.2": "Neutral",
  "INPUT_LIKERT.LIKELIHOOD.3": "Likely",
  "INPUT_LIKERT.LIKELIHOOD.4": "Extremely likely",
  "INPUT_LIKERT.MY_BELIEFS.0": "Very untrue of what I believe",
  "INPUT_LIKERT.MY_BELIEFS.1": "Untrue of what I believe",
  "INPUT_LIKERT.MY_BELIEFS.2": "Somewhat untrue of what I believe",
  "INPUT_LIKERT.MY_BELIEFS.3": "Neutral",
  "INPUT_LIKERT.MY_BELIEFS.4": "Somewhat true of what I believe",
  "INPUT_LIKERT.MY_BELIEFS.5": "True of what I believe",
  "INPUT_LIKERT.MY_BELIEFS.6": "Very true of what I believe",
  "INPUT_LIKERT.NOT_APPLICABLE": "N/A",
  "INPUT_LIKERT.PRIORITY.0": "Not a priority",
  "INPUT_LIKERT.PRIORITY.1": "Low priority",
  "INPUT_LIKERT.PRIORITY.2": "Somewhat priority",
  "INPUT_LIKERT.PRIORITY.3": "Neutral",
  "INPUT_LIKERT.PRIORITY.4": "Moderate Priority",
  "INPUT_LIKERT.PRIORITY.5": "High priority",
  "INPUT_LIKERT.PRIORITY.6": "Essential priority",
  "INPUT_LIKERT.PRIORITY_LEVEL.0": "Not a priority",
  "INPUT_LIKERT.PRIORITY_LEVEL.1": "Low priority",
  "INPUT_LIKERT.PRIORITY_LEVEL.2": "Medium priority",
  "INPUT_LIKERT.PRIORITY_LEVEL.3": "High priority",
  "INPUT_LIKERT.PRIORITY_LEVEL.4": "Essential",
  "INPUT_LIKERT.REFLECT_ME.0": "Very untrue of me",
  "INPUT_LIKERT.REFLECT_ME.1": "Untrue of me",
  "INPUT_LIKERT.REFLECT_ME.2": "Somewhat untrue of me",
  "INPUT_LIKERT.REFLECT_ME.3": "Neutral",
  "INPUT_LIKERT.REFLECT_ME.4": "Somewhat true of me",
  "INPUT_LIKERT.REFLECT_ME.5": "True of me",
  "INPUT_LIKERT.REFLECT_ME.6": "Very true of me",
  "INPUT_MESSAGES.INVALID_DATE": "Invalid date",
  "INPUT_MESSAGES.INVALID_EMAIL": "Invalid email",
  "INPUT_MESSAGES.INVALID_NUMBER": "Invalid number",
  "INPUT_MESSAGES.INVALID_PHONE_NUMBER": "Invalid phone number",
  "INPUT_MESSAGES.INVALID_POSTAL_CODE": "Invalid postal code (Canada)",
  "INPUT_MESSAGES.INVALID_TEXT_FORMAT": "Invalid format",
  "INPUT_MESSAGES.INVALID_TIME": "Invalid time",
  "INPUT_MESSAGES.INVALID_ZIP_CODE": "Invalid zip code (USA)",
  "INPUT_MESSAGES.REQUIRED": "Required",
  "INPUT_NUMBER.DEFAULT_QUESTION_TITLE": "Numeric question {{count}}",
  "INPUT_NUMBER.NOT_SELECTED": "Not&lt;br&gt;Selected",
  "INPUT_RANK.SECTION_DROPDOWN.ADD_AFTER": "Insert section after",
  "INPUT_RANK.SECTION_DROPDOWN.ADD_BEFORE": "Insert section before",
  "INPUT_RANK.SECTION_DROPDOWN.DELETE": "Delete section",
  "INPUT_RANKING.DEFAULT_QUESTION_TITLE": "Ranking question {{count}}",
  "INPUT_RANKING.ITEM_CHOICE": "Item {{number}}",
  "INPUT_RANKING.OTHER_SPECIFY_CHOICE": "Other",
  "INPUT_RANKING.SECTION": "Untitled section",
  "INPUT_RANKING.SELECT_ALL": "Select all",
  "INPUT_TEXT.DEFAULT_QUESTION_TITLE": "Comment question {{count}}",
  "PAGE_BREAK.ENABLE_NEXT.ALL_ANSWERED.TOOLTIP":
    "Navigate to next page when all questions are answered",
  "PAGE_BREAK.ENABLE_NEXT.ALWAYS.TOOLTIP":
    "Navigate to next page without restriction",
  "PAGE_BREAK.ENABLE_NEXT.MINIMUM_ANSWERED.TOOLTIP":
    "Navigate to next page when minimum number of questions are answered",
  "PAGE_BREAK.ENABLE_NEXT.REQUIRED_ANSWERED.TOOLTIP":
    "Navigate to next page when all the answers are valid",
  "PAGE_BREAK.NEXT_BUTTON": "Next",
  "PAGE_BREAK.PAGE_NUMBER": "Page break (PAGE {{current}} OF {{total}})",
  "PAGE_BREAK.PREVIOUS_BUTTON": "Previous",
  "PAGE_BREAK.SUBMIT_BUTTON": "Submit",
  "QUESTION_TITLE.PLACEHOLDER": "Enter the question title here",
  "SCALE_LABELS.ACTIONS.ADD_AFTER": "Add value after",
  "SCALE_LABELS.ACTIONS.ADD_BEFORE": "Add value before",
  "SCALE_LABELS.ACTIONS.DELETE": "Delete value",
  "SCALE_LABELS.ACTIONS.ENABLE_NOT_APPLICABLE": "Enable not applicable",
  "VARIABLES.NOT_FOUND": "variable not found",
  "VARIABLES.QUESTION_NOT_FOUND":
    "The question is no longer valid, please choose a different one or no question will be displayed in the email."
};
