import { InputRule } from "prosemirror-inputrules";
import { NodeSpec, Schema } from "prosemirror-model";
import { Plugin } from "prosemirror-state";
import {
  CommandConfiguration,
  CommandConfigurations,
  DocumentBuilders,
  Extension,
  KeyMap,
  NodeConfig
} from "../../editor";
import {
  backspaceCommand,
  deleteCommand,
  enterCommand,
  joiningListInputRule,
  toggleList,
  wrappingListInputRule
} from "./commands";
import { ListItemNode, ListItemPlugin } from "./list-item";
import { isListActive, isListEnabled } from "./util";

function getAttrs(
  value: Node | string
): { [key: string]: any } | false | null | undefined {
  if (typeof value === "string") {
    return false;
  }

  const element = value as HTMLElement;
  const attrs: {
    id?: string;
  } = {};

  const id = element.getAttribute("id");
  if (id != null) {
    attrs.id = id;
  }

  return attrs;
}

class OrderedListNode implements NodeConfig {
  get name(): string {
    return "orderedList";
  }

  get spec(): NodeSpec {
    return {
      group: "block rootBlock",
      content: "listItem+",
      selectable: false,
      attrs: {
        id: { default: null }
      },
      parseDOM: [
        {
          tag: "ol",
          getAttrs: getAttrs
        }
      ],
      toDOM(node) {
        return ["ol", { id: node.attrs.id }, 0];
      }
    };
  }

  get builders(): DocumentBuilders {
    return {
      ol: { nodeType: "orderedList" }
    };
  }
}

type OrderedListSchema = Schema<"orderedList" | "listItem", any>;

export class OrderedList implements Extension<OrderedListSchema> {
  get name(): string {
    return "orderedList";
  }

  get nodes(): NodeConfig[] {
    return [new OrderedListNode(), new ListItemNode()];
  }

  commands(
    schema: OrderedListSchema
  ): CommandConfigurations<OrderedListSchema> {
    return {
      orderedList: this.orderedListCommand(schema)
    };
  }

  keymaps(_schema: OrderedListSchema): KeyMap<OrderedListSchema> {
    return {
      Enter: enterCommand,
      Backspace: backspaceCommand,
      Delete: deleteCommand
    };
  }

  inputRules(schema: OrderedListSchema): InputRule<OrderedListSchema>[] {
    return [
      wrappingListInputRule(/^\s*(1)\.\s$/, schema.nodes.orderedList),
      joiningListInputRule(
        /^\s*(\d+)\.\s$/,
        schema.nodes.orderedList,
        undefined,
        (match, node) => {
          return node.childCount + 1 === parseInt(match[1], 10);
        }
      )
    ];
  }

  plugins(): Plugin<OrderedListSchema>[] {
    return [new ListItemPlugin()];
  }

  private orderedListCommand(
    schema: OrderedListSchema
  ): CommandConfiguration<OrderedListSchema, {}, undefined> {
    return {
      isActive: () => isListActive(schema.nodes.orderedList),
      isEnabled: () => isListEnabled(schema.nodes.orderedList),
      execute: () => toggleList(schema.nodes.orderedList)
    };
  }
}
