import { Node, Schema } from "prosemirror-model";
import { EditorState, Plugin } from "prosemirror-state";
import {
  CommandConfiguration,
  CommandConfigurations,
  CommandFn,
  Extension,
  NodeConfig
} from "../../editor";
import {
  dropInsertPlugin,
  executeAtPos,
  isEnableAtPos
} from "../../editor/plugins/drop-insert-plugin";
import { emitNotification } from "../../editor/plugins/notification";
import {
  applyQuestionTitle,
  questionTitleCacheKey,
  questionTitleCachePlugin,
  questionTitlePredicate
} from "../../editor/plugins/question-title-cache";
import { selectionFocusKey } from "../../editor/plugins/selection-focus";
import { getIdGenerator } from "../../extensions/node-identifier";
import { isQuestionTitleAutoCreation } from "../../extensions/question-title/plugins/question-title-autocreation-flag";
import { canInsert, isInsideGrid, isMac } from "../../util";
import { insertBlock } from "../../util/transforms";
import {
  getQuestionTitleActiveValue,
  QuestionTitleActiveValue,
  updateQuestionTitleText
} from "../question-title";
import { InputFileNode } from "./input-file-node";
import { nodeViewPlugin } from "./input-file-node-view";
import {
  InputFileDefaults,
  InputFileFormat,
  InputFileMaxSize,
  InputFileSchema,
  InputFileWidth
} from "./schema";

interface InsertInputFileCommandProps {
  format: InputFileFormat;
}

export interface UpdateInputFileCommandProps {
  format?: InputFileFormat;
  width?: number;
  questionTitleText?: string;
  description?: string;
  coding?: string;
  maxSize?: number;
  minFiles?: number;
  maxFiles?: number;
  required?: boolean;
  imageMinWidth?: number;
  imageMinHeight?: number;
}

export interface InputFileActiveValue {
  format: InputFileFormat;
  width: number;
  questionTitle: QuestionTitleActiveValue;
  description: string;
  coding: string;
  maxSize: number;
  minFiles: number;
  maxFiles: number;
  required: boolean;
  imageMinWidth: number;
  imageMinHeight: number;
  widthDisabled: boolean;
}

const questionTitleKey = questionTitleCacheKey();
export class InputFile implements Extension<InputFileSchema> {
  get name(): string {
    return "inputFile";
  }

  get nodes(): NodeConfig[] {
    return [new InputFileNode()];
  }

  plugins(schema: InputFileSchema): Plugin[] {
    return [
      nodeViewPlugin(),
      dropInsertPlugin((view, data, posAtCoords) => {
        if (data.type !== "inputFile") {
          return false;
        }

        let format: InputFileFormat;
        switch (data.subType) {
          case InputFileFormat.document:
            format = InputFileFormat.document;
            break;

          case InputFileFormat.image:
            format = InputFileFormat.image;
            break;

          case InputFileFormat.sound:
            format = InputFileFormat.sound;
            break;

          case InputFileFormat.video:
            format = InputFileFormat.video;
            break;

          default:
            format = InputFileFormat.document;
            break;
        }

        const command = this.insertInputFileCommand(schema);

        const isEnabled = isEnableAtPos(command, view, posAtCoords);
        if (
          !isEnabled({
            format: format
          })
        ) {
          emitNotification(view.state, {
            type: "warning",
            message: "drop.insert.invalid-location"
          });
        } else {
          executeAtPos(
            command.execute({
              format: format
            }),
            view,
            posAtCoords
          );
        }

        return true;
      }),
      questionTitleCachePlugin(
        schema.nodes.inputFile,
        "INPUT_FILE.DEFAULT_QUESTION_TITLE",
        questionTitlePredicate,
        applyQuestionTitle,
        questionTitleKey
      )
    ];
  }

  commands(schema: InputFileSchema): CommandConfigurations<InputFileSchema> {
    return {
      insertInputFile: this.insertInputFileCommand(schema),
      updateInputFile: this.updateInputFileCommand(schema)
    };
  }

  private insertInputFileCommand(
    schema: InputFileSchema
  ): CommandConfiguration<
    InputFileSchema,
    InsertInputFileCommandProps,
    undefined
  > {
    return {
      isActive: () => {
        return false;
      },
      isEnabled: () => {
        return insertInputFile(schema, InputFileFormat.document);
      },
      execute: (props) => {
        if (props == null) {
          throw new Error(
            `To insert an input file, InsertInputFileCommandProps needs to be provided.`
          );
        }
        const { format } = props;

        if (format == null) {
          throw new Error(`To insert an input file, type need to be provided.`);
        }
        return insertInputFile(schema, format);
      },
      shortcuts: {
        [isMac() ? "Ctrl-f" : "Alt-f"]: { format: InputFileFormat.document }
      }
    };
  }

  private updateInputFileCommand(
    _schema: InputFileSchema
  ): CommandConfiguration<
    InputFileSchema,
    UpdateInputFileCommandProps,
    InputFileActiveValue | undefined
  > {
    return {
      isActive: () => {
        return (state) => {
          return focusedInputFile(state) != null;
        };
      },
      isEnabled: () => {
        return (state) => {
          return focusedInputFile(state) != null;
        };
      },
      execute: (props) => {
        if (props == null) {
          throw new Error(
            `To update an input file, UpdateInputFileCommandProps needs to be provided.`
          );
        }

        return (state, dispatch) => {
          const focused = focusedInputFile(state);
          return updateInputFile(props, focused)(state, dispatch);
        };
      },
      activeValue: () => {
        return (state) => {
          const focused = focusedInputFile(state);
          if (!focused) {
            return undefined;
          }

          const widthDisabled = isInsideGrid(state, focused);

          return {
            format: focused.node.attrs.format,
            width: widthDisabled ? 100 : focused.node.attrs.width,
            questionTitle: getQuestionTitleActiveValue(state, focused.node),
            description: focused.node.attrs.description,
            coding: focused.node.attrs.coding,
            maxSize: focused.node.attrs.maxSize,
            minFiles: focused.node.attrs.minFiles,
            maxFiles: focused.node.attrs.maxFiles,
            required: focused.node.attrs.required,
            imageMinWidth: focused.node.attrs.imageMinWidth,
            imageMinHeight: focused.node.attrs.imageMinHeight,
            widthDisabled: widthDisabled
          };
        };
      }
    };
  }
}

function insertInputFile(
  schema: InputFileSchema,
  format: InputFileFormat
): CommandFn<InputFileSchema> {
  return (state, dispatch) => {
    if (!canInsert(schema.nodes.inputFile)(state)) {
      return false;
    } else {
      if (dispatch) {
        const inputFile = createInputFile(schema, format);
        if (inputFile == null) {
          return false;
        }

        let tr = state.tr;
        tr = insertBlock(
          tr,
          state.schema,
          inputFile,
          false,
          getIdGenerator(state),
          isQuestionTitleAutoCreation(state)
        );
        dispatch(tr);
      }
      return true;
    }
  };
}

function createInputFile(
  schema: InputFileSchema,
  format: InputFileFormat
): Node<InputFileSchema> {
  const { inputFile } = schema.nodes;
  return inputFile.createChecked({
    format: format,
    ...(format === InputFileFormat.image
      ? {
          imageMinWidth: InputFileDefaults.ImageMinWidth,
          imageMinHeight: InputFileDefaults.ImageMinHeight
        }
      : null)
  });
}

function focusedInputFile(state: EditorState<InputFileSchema>) {
  const { schema } = state;

  const focused = selectionFocusKey.getState(state);
  if (focused != null && focused.node.type === schema.nodes.inputFile) {
    return focused;
  } else {
    return undefined;
  }
}

function updateInputFile<S extends Schema>(
  props: UpdateInputFileCommandProps,
  inputFile: { node: Node<S>; pos: number } | undefined
): CommandFn<S> {
  return (state, dispatch) => {
    if (inputFile == null) {
      return false;
    }

    if (dispatch) {
      const { node, pos } = inputFile;

      let updatedAttrs = {
        ...node.attrs
      };

      if (props.format !== undefined) {
        updatedAttrs = { ...updatedAttrs, format: props.format };
      }

      if (props.questionTitleText !== undefined) {
        updatedAttrs = updateQuestionTitleText(
          updatedAttrs,
          props.questionTitleText
        );
      }

      if (props.description !== undefined) {
        updatedAttrs = { ...updatedAttrs, description: props.description };
      }

      if (props.coding !== undefined) {
        updatedAttrs = { ...updatedAttrs, coding: props.coding };
      }

      if (props.maxSize !== undefined) {
        updatedAttrs = {
          ...updatedAttrs,
          maxSize: Math.min(
            Math.max(props.maxSize, InputFileMaxSize.minimum),
            InputFileMaxSize.maximum
          )
        };
      }

      if (props.minFiles !== undefined) {
        updatedAttrs = { ...updatedAttrs, minFiles: props.minFiles };
      }

      if (props.maxFiles !== undefined) {
        updatedAttrs = { ...updatedAttrs, maxFiles: props.maxFiles };
      }

      if (props.required !== undefined) {
        updatedAttrs = { ...updatedAttrs, required: props.required };
      }

      if (props.imageMinWidth !== undefined) {
        updatedAttrs = { ...updatedAttrs, imageMinWidth: props.imageMinWidth };
      }

      if (props.imageMinHeight !== undefined) {
        updatedAttrs = {
          ...updatedAttrs,
          imageMinHeight: props.imageMinHeight
        };
      }

      if (
        props.format != null &&
        props.format !== node.attrs.format &&
        props.format === "image"
      ) {
        updatedAttrs = {
          ...updatedAttrs,
          format: InputFileFormat.image,
          imageMinWidth: InputFileDefaults.ImageMinWidth,
          imageMinHeight: InputFileDefaults.ImageMinHeight
        };
      }

      if (props.width != null && !isNaN(props.width)) {
        updatedAttrs = {
          ...updatedAttrs,
          width: Math.min(
            Math.max(props.width, InputFileWidth.minimum),
            InputFileWidth.maximum
          )
        };
      }

      updatedAttrs = { ...updatedAttrs };

      let tr = state.tr;
      tr = tr.setNodeMarkup(pos, undefined, updatedAttrs);

      dispatch(tr);
    }
    return true;
  };
}
