import { Node, Schema } from "prosemirror-model";
import { Decoration, EditorView, NodeView } from "prosemirror-view";
import { Editor } from "../../../editor";
import { WidthResizerNodeView } from "../../../editor/plugins/width-resizer";
import { AlignmentType } from "../../alignment";
import { updateColumns } from "../plugins/column-resizing";
import { CustomGridWidth } from "../schema";
import { resizeGrid } from "../util";

declare class ResizeObserver {
  constructor(callback: (entries: any) => void);
  observe(target: Element): void;
  unobserve(target: Element): void;
  disconnect(): void;
}

export class TableNodeView<S extends Schema> implements NodeView<S> {
  dom: HTMLElement;
  contentDOM: HTMLElement;

  private resizer: WidthResizerNodeView<S>;

  private table: HTMLTableElement;
  private colgroup: HTMLElement;

  private resizeObserver = new ResizeObserver((entries) => resizeGrid(entries));

  constructor(
    private node: Node<S>,
    view: EditorView<S>,
    getPos: () => number
  ) {
    const container = document.createElement("custom-grid");

    this.resizer = new WidthResizerNodeView(
      container,
      node,
      view,
      getPos,
      CustomGridWidth.minimum,
      CustomGridWidth.maximum,
      (width) => {
        const editor = view as Editor<S>;
        editor.commands.updateGrid.execute({
          width: width
        });
      }
    );

    const table = document.createElement("table");
    const tableBody = document.createElement("tbody");
    const colgroup = document.createElement("colgroup");

    table.appendChild(colgroup);
    table.appendChild(tableBody);

    container.appendChild(table);

    this.dom = container;
    this.contentDOM = tableBody;
    this.table = table;
    this.colgroup = colgroup;

    this.resizeObserver.observe(this.dom);

    updateColumns(node, this.colgroup, this.table);

    this.updateHeader(node);
    this.updateFooter(node);
    this.updateManualRepeat(node);
    this.updateTableBorders(node);
    this.updateAlignment(node.attrs.alignment);
    this.updateRepeatGrid(node.attrs.repeatGrid);
  }

  ignoreMutation(
    mutation:
      | MutationRecord
      | {
          type: "selection";
          target: Element;
        }
  ): boolean {
    const resizeIgnoreMutation = this.resizer.ignoreMutation(mutation);
    const columnReszing =
      mutation.type === "attributes" &&
      (mutation.target === this.table ||
        this.colgroup.contains(mutation.target));

    return resizeIgnoreMutation || columnReszing;
  }

  update(node: Node<S>, _decorations: Decoration[]): boolean {
    if (node.type !== this.node.type) {
      return false;
    }

    updateColumns(node, this.colgroup, this.table);

    this.resizer.update(node);
    this.updateHeader(node);
    this.updateFooter(node);
    this.updateManualRepeat(node);
    this.updateTableBorders(node);
    this.updateAlignment(node.attrs.alignment);
    this.updateRepeatGrid(node.attrs.repeatGrid);

    return true;
  }

  private updateAlignment(alignment: AlignmentType): void {
    if (alignment != null) {
      this.dom.setAttribute("data-alignment", alignment);
    } else {
      this.dom.removeAttribute("data-alignment");
    }
  }

  updateHeader(node: Node<S>): void {
    this.dom.setAttribute(
      "data-show-header",
      node.attrs.showHeader === true ? "true" : "false"
    );
  }

  updateFooter(node: Node<S>): void {
    this.dom.setAttribute(
      "data-show-footer",
      node.attrs.showFooter === true ? "true" : "false"
    );
  }

  updateManualRepeat(node: Node<S>): void {
    this.dom.setAttribute(
      "data-last-row-manual-repeat",
      node.attrs.lastRowManualRepeat === true ? "true" : "false"
    );
  }

  updateTableBorders(node: Node<S>): void {
    this.dom.setAttribute(
      "data-border-bottom",
      node.attrs.borders.bottom === true ? "true" : "false"
    );
    this.dom.setAttribute(
      "data-border-top",
      node.attrs.borders.top === true ? "true" : "false"
    );
    this.dom.setAttribute(
      "data-border-left",
      node.attrs.borders.left === true ? "true" : "false"
    );
    this.dom.setAttribute(
      "data-border-right",
      node.attrs.borders.right === true ? "true" : "false"
    );
    this.dom.setAttribute(
      "data-border-inside-horizontal",
      node.attrs.borders["inside-horizontal"] === true ? "true" : "false"
    );
    this.dom.setAttribute(
      "data-border-inside-vertical",
      node.attrs.borders["inside-vertical"] === true ? "true" : "false"
    );
  }

  updateRepeatGrid(repeatGrid: boolean): void {
    this.dom.setAttribute(
      "data-repeat-grid",
      repeatGrid === true ? "true" : "false"
    );
  }
}
