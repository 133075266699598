import { Schema } from "prosemirror-model";
import { Plugin } from "prosemirror-state";
import {
  CommandConfiguration,
  CommandConfigurations,
  Extension
} from "../../editor/extension";
import {
  documentSkinKey,
  documentSkinPicker
} from "./plugin/document-skin-picker";
import { Skin, SkinId } from "./types";

export interface ChangeDocumentSkinCommandProps {
  skinId: string;
}

export interface ChangeSkinActiveValue {
  documentSkin: Skin;
}

export class DocumentSkin implements Extension<Schema> {
  constructor(private skins: Skin[], private defaultSkinId: SkinId) {}

  get name(): string {
    return "documentSkin";
  }

  plugins(): Plugin[] {
    return [documentSkinPicker(this.skins, this.defaultSkinId)];
  }

  commands(schema: Schema): CommandConfigurations<Schema> {
    return {
      changeDocumentSkin: this.changeDocumentSkinCommand(schema)
    };
  }

  private changeDocumentSkinCommand(
    _schema: Schema
  ): CommandConfiguration<
    Schema,
    ChangeDocumentSkinCommandProps,
    ChangeSkinActiveValue | undefined
  > {
    return {
      isActive: () => {
        return false;
      },
      isEnabled: () => {
        return true;
      },
      execute: (props) => {
        if (props == null) {
          throw new Error(
            `To change document skin, ChangeDocumentSkinCommandProps needs to be provided.`
          );
        }

        return (state, dispatch) => {
          if (dispatch) {
            let tr = state.tr;
            tr = tr.setMeta(documentSkinKey, { skinId: props.skinId });
            dispatch(tr);
          }

          return true;
        };
      },
      activeValue: () => {
        return (state) => {
          const documentSkin = documentSkinKey.getState(state);
          if (documentSkin == null) {
            return undefined;
          }
          return documentSkin;
        };
      },
      requiresEditable: false
    };
  }
}
