import {
  Command,
  Editor as BlueXEditor,
  ImageActiveValue,
  ImageErrors,
  imageWidthInView,
  Schema
} from "@blue-x/editor";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import ToolbarDropdown, {
  ToolbarDropdownToggle,
  useToolbarDropdown
} from "../ToolbarDropdown";
import ToolbarIcon from "../ToolbarIcon";
import ToolbarTooltip from "../ToolbarTooltip";
import "./Image.css";

interface ImagePropertiesPanelProps {
  editor: BlueXEditor<Schema>;
}

function limit(value: number, min: number, max: number): number {
  return Math.min(Math.max(Math.floor(value), min), max);
}

export const ImagePropertiesPanel: React.FC<ImagePropertiesPanelProps> = (
  props
) => {
  const { editor } = props;
  const { commands } = editor;

  const activeValue: ImageActiveValue = useMemo(() => {
    return commands.updateImage.activeValue();
  }, [commands]);

  const [alt, setAlt] = useState("");
  const [width, setWidth] = useState(0);
  const [redirectUrl, setRedirectUrl] = useState("");
  const [disableRedirect, setDisableRedirect] = useState(false);

  useEffect(() => {
    setAlt(activeValue.alt == null ? "" : activeValue.alt);
    if (activeValue.width == null) {
      imageWidthInView(editor, activeValue.src, 5, 100)
        .then((width) => {
          setWidth(width);
        })
        .catch(() => {});
    } else {
      setWidth(activeValue.width);
    }
    setRedirectUrl(
      activeValue.redirectUrl == null ? "" : activeValue.redirectUrl
    );

    setDisableRedirect(activeValue.disableRedirect);
  }, [
    activeValue,
    setAlt,
    setWidth,
    setRedirectUrl,
    setDisableRedirect,
    editor
  ]);

  const updateAlt = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAlt(event.target.value);
    },
    [setAlt]
  );

  const updateWidth = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const min = parseInt(event.target.min);
      const max = parseInt(event.target.max);
      const value = event.target.valueAsNumber;

      if (!isNaN(value)) {
        setWidth(limit(value, min, max));
      }
    },
    [setWidth]
  );

  const updateRedirectUrl = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRedirectUrl(event.target.value);
    },
    [setRedirectUrl]
  );

  const updateCommand = useCallback(() => {
    commands.updateImage.execute(
      { alt: alt, width: width, redirectUrl: redirectUrl },
      false
    );
  }, [commands, alt, width, redirectUrl]);

  const src = useMemo(() => {
    return activeValue.src;
  }, [activeValue]);

  return (
    <div className="ImagePropertiesPanel">
      <label>Image</label>
      <input type="text" placeholder="Src" value={src} disabled={true} />
      <input
        type="text"
        placeholder="Alt"
        value={alt}
        onChange={updateAlt}
        onBlur={updateCommand}
      />
      <input
        type="number"
        min="5"
        max="100"
        step="1"
        placeholder="Width"
        value={width}
        onChange={updateWidth}
        onBlur={updateCommand}
      />
      {!disableRedirect && (
        <input
          type="text"
          placeholder="Redirect url"
          value={redirectUrl}
          onChange={updateRedirectUrl}
          onBlur={updateCommand}
        />
      )}
    </div>
  );
};

interface InsertImageProps {
  command: Command;
}

const InsertImage: React.FC<InsertImageProps> = (props) => {
  const { command } = props;
  const dropdown = useToolbarDropdown();

  const [file, setFile] = useState<File>();
  const filePicked = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (files != null && files.length > 0) {
        const file = files[0];
        setFile(file);
      }
    },
    [setFile]
  );

  const insertFile = useCallback(() => {
    command.execute({ file: file });
    const { opened, setOpened } = dropdown;
    setOpened(!opened);
  }, [file, command, dropdown]);

  const insertFileDisabled = useMemo(() => {
    return file == null;
  }, [file]);

  const [section, setSection] = useState<"upload" | "url">("upload");

  const [url, setUrl] = useState("");

  const urlChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setUrl(event.target.value);
    },
    [setUrl]
  );
  const [isUploading, setIsUpload] = useState<boolean>(false);

  const insertUrl = useCallback(() => {
    setIsUpload(true);

    fetch(`https://api.gateway.dev.bluexsurveydev.com/files?uri=${url}`, {
      method: "GET"
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(ImageErrors.InsertInvalid);
        }

        const blob = await response.blob();
        const file = new File([blob], "", { type: "image/png" });

        command.execute({ file: file });

        setIsUpload(false);
        const { opened, setOpened } = dropdown;
        setOpened(!opened);
      })
      .catch((reason) => {
        alert(reason);

        setIsUpload(false);
        const { opened, setOpened } = dropdown;
        setOpened(!opened);
      });
  }, [url, command, dropdown, setIsUpload]);

  const insertUrlDisabled = useMemo(() => {
    return url == null || url.length < 1 || isUploading;
  }, [url, isUploading]);

  return (
    <div className="InsertImage">
      <div className="InsertImage-section-picker">
        <button
          className={section === "upload" ? "active" : undefined}
          onClick={() => setSection("upload")}
        >
          Upload
        </button>
        <button
          className={section === "url" ? "active" : undefined}
          onClick={() => setSection("url")}
        >
          Url
        </button>
      </div>
      {section === "upload" ? (
        <div>
          <input type="file" onChange={filePicked}></input>
          <button onClick={insertFile} disabled={insertFileDisabled}>
            Insert
          </button>
        </div>
      ) : undefined}
      {section === "url" ? (
        <div>
          <input
            type="text"
            placeholder="url"
            onChange={urlChange}
            value={url}
          ></input>
          <button onClick={insertUrl} disabled={insertUrlDisabled}>
            Insert
          </button>
          {isUploading ? (
            <i className={`bx bx-spinner-line bx-fw bx-pulse`}></i>
          ) : null}
        </div>
      ) : undefined}
    </div>
  );
};

interface ToolbarFileButtonProps {
  editor: BlueXEditor<Schema>;
}

export const ToolbarImageButton: React.FC<ToolbarFileButtonProps> = (props) => {
  const { editor } = props;
  const { commands } = editor;

  return (
    <ToolbarDropdown
      toggle={
        <ToolbarTooltip title="Image" subtitle="Add an image.">
          <ToolbarDropdownToggle
            active={commands.insertImage?.isActive()}
            disabled={!commands.insertImage?.isEnabled()}
          >
            <ToolbarIcon icon="picture" />
          </ToolbarDropdownToggle>
        </ToolbarTooltip>
      }
      content={<InsertImage command={commands.insertImage} />}
    />
  );
};
