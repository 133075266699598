import { AlignmentType } from "extensions/alignment";
import { Node, NodeSpec } from "prosemirror-model";
import { DocumentBuilders, NodeConfig } from "../../../editor";

const BaseSpec: NodeSpec = {
  content: "(inline|hardBreak)*",
  draggable: false,
  focusable: false,
  selectable: false,
  allowGapCursor: false,
  allowIndentation: false,
  allowAlignment: ["left", "center", "right", "justify"],
  defaultAlignment: () => "left",
  attrs: {
    id: { default: null },
    alignment: { default: null }
  }
};

const EmptyBaseSpec: NodeSpec = {
  content: "inline*",
  atom: true,
  focusable: false,
  selectable: false
};

export class InputRankSectionNode implements NodeConfig {
  get name(): string {
    return "inputRankSection";
  }

  get spec(): NodeSpec {
    return {
      ...BaseSpec,
      isolating: true,
      parseDOM: [
        {
          tag: "input-rank-section",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getAttrs(node as Element);
          }
        }
      ],
      toDOM(node) {
        return ["input-rank-section", setAttrs(node), 0];
      }
    };
  }

  get builders(): DocumentBuilders {
    return { inputRankSection: { nodeType: "inputRankSection" } };
  }
}

export class InputRankSectionEmptyNode implements NodeConfig {
  get name(): string {
    return "inputRankSectionEmpty";
  }

  get spec(): NodeSpec {
    return {
      ...{
        ...EmptyBaseSpec
      },
      isolating: true,
      parseDOM: [
        {
          tag: "input-rank-section-empty"
        }
      ],
      toDOM() {
        return ["input-rank-section-empty", 0];
      }
    };
  }

  get builders(): DocumentBuilders {
    return {
      inputRankSectionEmpty: { nodeType: "inputRankSectionEmpty", id: null }
    };
  }
}

function getAttrs(element: Element): { [key: string]: any } {
  const attrs: {
    id?: string;
    alignment?: AlignmentType;
  } = {};

  const id = element.getAttribute("id");
  if (id != null) {
    attrs.id = id;
  }

  const alignment = element.getAttribute("data-alignment") as AlignmentType;
  if (alignment != null) {
    switch (alignment) {
      case "center":
      case "left":
      case "right":
      case "justify":
        attrs.alignment = alignment;
        break;
    }
  }

  return attrs;
}
function setAttrs(node: Node): { [key: string]: string | null } {
  return {
    id: node.attrs.id,
    "data-alignment": node.attrs.alignment
  };
}
