import { Node, Schema } from "prosemirror-model";

export type BarChartSchema = Schema<"barChart" | "barChartConvas", any>;

export enum BarChartWidth {
  default = 100,
  minimum = 25,
  maximum = 100
}

export interface InsertBarChartProps {
  questionType: BarChartQuestionType | null;
  type: BarChartIndexAxis;
}

export enum BarChartQuestionType {
  choice = "inputChoice",
  scale = "inputScale",
  number = "inputNumber",
  dateTime = "inputDateTime",
  constantSum = "constantSum",
  ranking = "inputRank"
}

export enum BarChartIndexAxis {
  horizontal = "horizontal",
  vertical = "vertical"
}

export enum BarChartStyle {
  small = "small",
  medium = "medium",
  large = "large"
}

export enum BarChartInputValueType {
  count = "count",
  percentage = "percentage"
}

export enum BarChartLegend {
  none = "none",
  top = "top",
  right = "right",
  bottom = "bottom",
  left = "left"
}

export interface SegmentColor {
  red: number;
  green: number;
  blue: number;
  alpha: number;
}

export interface BarChartSegmentModeProps {
  mode: BarChartSegmentMode;
  node: Node;
}

export enum BarChartSegmentMode {
  automatic = "automatic",
  manual = "manual"
}

export enum BarChartSegmentType {
  range = "range",
  items = "items"
}

export interface BarChartSegmentRange {
  min: number | string | null;
  max: number | string | null;
}
export interface BarChartSegment {
  id: string;
  type: BarChartSegmentType;
  color: SegmentColor | null;
  items: string[] | number[] | null;
  range: BarChartSegmentRange | null;
  label?: string;
  imageUrl?: string;
}

export interface RemoveSegmentProps {
  segmentId: string;
}

export interface UpdateBarChartProps {
  surveyId?: string;
  node?: Node;
  type?: BarChartIndexAxis;
  style?: BarChartStyle;
  segmentTypeProps?: BarChartSegmentTypeProps;
  sectionId?: string;
  option?: BarChartItem;
  optionLabel?: string;
  rankLabel?: string;
  rank?: number;
  segmentModeProps?: BarChartSegmentModeProps;
  segments?: BarChartSegment[];
  showInputLabel?: boolean;
  inputValueType?: BarChartInputValueType;
  showInputValue?: boolean;
  width?: number;
  showDataLabel?: boolean;
  showSkippedCount?: boolean;
  showHiddenCount?: boolean;
  legend?: BarChartLegend;
  filters?: JSON;
}

export interface BarChartActiveValue {
  surveyId: string | null;
  inputId: string | null;
  rankConfiguration: BarChartRankConfiguration;
  questionType: BarChartQuestionType | null;
  type: BarChartIndexAxis;
  style: BarChartStyle;
  segmentMode: BarChartSegmentMode;
  segments: BarChartSegment[];
  showInputLabel: boolean;
  inputValueType: BarChartInputValueType;
  showInputValue: boolean;
  width: number;
  showDataLabel: boolean;
  showSkippedCount: boolean;
  showHiddenCount: boolean;
  legend: BarChartLegend;
  widthDisabled: boolean;
  filters: JSON | null;
}

export interface BarChartRankConfiguration {
  segmentType: BarChartRankConfigurationSegmentType;
  sectionId: string;
  option: {
    id: string;
    label: string;
  };
  rank: {
    id: number;
    label: string;
  };
  optionId: string; //remove
  optionLabel: string; //remove
}

export interface BarChartSegmentTypeProps {
  type: BarChartRankConfigurationSegmentType;
  options: BarChartItem[];
}

export enum BarChartRankConfigurationSegmentType {
  item = "item",
  rank = "rank"
}

export interface BarChartItem {
  id: string;
  label: string;
}
