import React from "react";
import "./ToolbarIcon.css";

interface Props {
  icon: string;
}

const ToolbarIcon: React.FC<Props> = (props) => {
  const { icon } = props;
  return <i className={`ToolbarIcon bx bx-${icon}`}></i>;
};

export default ToolbarIcon;
