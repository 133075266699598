export function pointsToPixels(point: number): number {
  return round(point * 1.3333333333333333, 0.5);
}

export function pixelToPoints(pixel: number): number {
  return round(pixel / 1.3333333333333333, 0.5);
}

export function round(value: number, nearest?: number) {
  const step = nearest == null ? 1.0 : nearest;
  const stepMultiplier = 1.0 / step;
  return Math.round(value * stepMultiplier) / stepMultiplier;
}
