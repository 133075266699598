import { DOMParser, Fragment, NodeSpec } from "prosemirror-model";
import { DocumentBuilders, NodeConfig } from "../../../editor/extension/schema";
import { PageBreakSchema } from "../schema";

const BaseSpec: NodeSpec = {
  content: "(text|hardBreak|image|variable)*",
  marks: "text-style",
  isolating: true,
  selectable: false,
  constrainSelection: true
};

export class PreviousPageBreakButtonNode implements NodeConfig {
  get name(): string {
    return "previousPageBreakButton";
  }

  get spec(): NodeSpec {
    return {
      ...BaseSpec,
      parseDOM: [
        {
          tag: "previous-page-break-button"
        },
        {
          tag: "[data-nav-button]",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return isRole(node as Element, "previous");
          },
          getContent: (node, schema) => {
            return toUpperCaseText(node, schema);
          }
        }
      ],
      toDOM() {
        return ["previous-page-break-button", 0];
      }
    };
  }

  get builders(): DocumentBuilders {
    return {
      previousPageBreakButton: {
        nodeType: "previousPageBreakButton"
      }
    };
  }
}

export class NextPageBreakButtonNode implements NodeConfig {
  get name(): string {
    return "nextPageBreakButton";
  }

  get spec(): NodeSpec {
    return {
      ...BaseSpec,
      parseDOM: [
        {
          tag: "next-page-break-button"
        },
        {
          tag: "[data-nav-button]",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return isRole(node as Element, "next");
          },
          getContent: (node, schema) => {
            return toUpperCaseText(node, schema);
          }
        }
      ],
      toDOM() {
        return ["next-page-break-button", 0];
      }
    };
  }

  get builders(): DocumentBuilders {
    return {
      nextPageBreakButton: {
        nodeType: "nextPageBreakButton"
      }
    };
  }
}

export class SubmitPageBreakButtonNode implements NodeConfig {
  get name(): string {
    return "submitPageBreakButton";
  }

  get spec(): NodeSpec {
    return {
      ...BaseSpec,
      parseDOM: [
        {
          tag: "submit-page-break-button"
        },
        {
          tag: "[data-nav-button]",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return isRole(node as Element, "submit");
          },
          getContent: (node, schema) => {
            return toUpperCaseText(node, schema);
          }
        }
      ],
      toDOM() {
        return ["submit-page-break-button", 0];
      }
    };
  }

  get builders(): DocumentBuilders {
    return {
      submitPageBreakButton: {
        nodeType: "submitPageBreakButton"
      }
    };
  }
}

function isRole(element: Element, value: string): null | false {
  const role = element.getAttribute("data-nav-role");

  if (role !== value) {
    return false;
  }

  return null;
}

function toUpperCaseText(node: Node, schema: PageBreakSchema): Fragment {
  const parser = DOMParser.fromSchema(schema);
  const slice = parser.parseSlice(node);
  const fragment = slice.content;

  let updated = Fragment.from(fragment);

  fragment.forEach((node, _offset, index) => {
    if (node.type === schema.nodes.text) {
      const text = node.text;
      if (text != null) {
        updated = updated.replaceChild(
          index,
          schema.text(text.toUpperCase(), node.marks)
        );
      }
    }
  });

  return updated;
}
