import { AttributeSpec, NodeSpec, Schema } from "prosemirror-model";
import { Plugin } from "prosemirror-state";
import { DocumentBuilders, NodeConfig } from "../../editor";
import { AlignmentType, getAlignment } from "../alignment";
import { ListItemView } from "./list-item-node-view";

function getAttrs(element: HTMLElement): { [key: string]: any } {
  const attrs: { id?: string; alignment?: AlignmentType } = {};

  const id = element.getAttribute("id");
  if (id != null) {
    attrs.id = id;
  }

  const alignment = getAlignment(element.style.textAlign);
  if (alignment != null) {
    attrs.alignment = alignment;
  }

  return attrs;
}

export class ListItemNode implements NodeConfig {
  get name(): string {
    return "listItem";
  }

  get spec(): NodeSpec {
    return {
      content: "paragraph+",
      defining: true,
      selectable: false,
      attrs: {
        id: { default: null },
        alignment: { default: null, keepOnSplit: true } as AttributeSpec & {
          keepOnSplit?: boolean;
        }
      },
      parseDOM: [
        {
          tag: "li",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getAttrs(node as HTMLElement);
          }
        }
      ],
      toDOM(node) {
        return [
          "li",
          { id: node.attrs.id, "data-alignment": node.attrs.alignment },
          0
        ];
      }
    };
  }

  get builders(): DocumentBuilders {
    return {
      li: { nodeType: "listItem" }
    };
  }
}

type ListItemSchema = Schema<"listItem" | any>;

export class ListItemPlugin extends Plugin<ListItemSchema> {
  constructor() {
    super({
      props: {
        nodeViews: {
          listItem: (node, view) => {
            return new ListItemView(node, view);
          }
        }
      }
    });
  }
}
