import { Node, Schema } from "prosemirror-model";
import { Selection } from "prosemirror-state";
import {
  CommandConfiguration,
  CommandConfigurations
} from "../../../editor/extension";
import { editableTransaction } from "../../../editor/plugins/editable";
import { findNode } from "../../../util";
import { setActive } from "../../custom-area";
import {
  ConfigurationItemNodes,
  EndSelectionAction,
  forkSelectorKey,
  ReplaceSelectionAction,
  StartSelectionAction
} from "./plugin";
import { ConfigurationItem } from "./types";

export interface UpdateSelectionCommandProps {
  items: ConfigurationItem[];
}

export function commands(schema: Schema): CommandConfigurations<Schema> {
  return {
    startForkSelector: startForkSelectorCommand(schema),
    updateForkSelector: updateForkSelectorCommand(schema),
    endForkSelector: endForkSelectorCommand(schema)
  };
}

function startForkSelectorCommand(
  _schema: Schema
): CommandConfiguration<Schema, {}, null> {
  return {
    isActive: () => {
      return false;
    },
    isEnabled: () => {
      return true;
    },
    execute: () => {
      return (state, dispatch) => {
        if (dispatch) {
          let tr = state.tr;

          const action: StartSelectionAction = {
            type: "start"
          };
          tr = tr.setSelection(Selection.near(tr.doc.resolve(0)));
          tr = editableTransaction(tr, { editable: false, focusable: false });
          tr = tr.setMeta(forkSelectorKey, action);

          dispatch(tr);
        }
        return true;
      };
    },
    requiresEditable: false
  };
}

function updateForkSelectorCommand(
  schema: Schema
): CommandConfiguration<Schema, UpdateSelectionCommandProps, null> {
  return {
    isActive: () => {
      return false;
    },
    isEnabled: () => {
      return true;
    },
    execute: (props) => {
      if (props == null) {
        throw new Error(
          "To start an input selector `UpdateSelectionCommandProps` must be passed."
        );
      }

      return (state, dispatch, view) => {
        if (dispatch && view) {
          const { items } = props;

          const configurations = customAreaForConfigurations(
            state.doc,
            schema,
            items
          );
          const action: ReplaceSelectionAction = {
            type: "replace",
            items: configurations,
            emit: false
          };

          let setActiveTr = view.state.tr;
          configurations.forEach((c) => {
            setActiveTr = setActive(
              setActiveTr,
              schema,
              c.area,
              c.variant.node.attrs.id
            );
          });
          setActiveTr.setMeta("addToHistory", false);

          dispatch(setActiveTr);

          let tr = view.state.tr;
          tr = tr.setMeta(forkSelectorKey, action);

          dispatch(tr);
        }
        return true;
      };
    },
    requiresEditable: false
  };
}

function endForkSelectorCommand(
  _schema: Schema
): CommandConfiguration<Schema, {}, null> {
  return {
    isActive: () => {
      return false;
    },
    isEnabled: () => {
      return true;
    },
    execute: () => {
      return (state, dispatch) => {
        if (dispatch) {
          let tr = state.tr;

          const action: EndSelectionAction = { type: "end" };
          tr = tr.setMeta(forkSelectorKey, action);
          tr = editableTransaction(tr, { editable: true });

          dispatch(tr);
        }
        return true;
      };
    },
    requiresEditable: false
  };
}

function customAreaForConfigurations(
  doc: Node<Schema>,
  schema: Schema,
  items: ConfigurationItem[]
): ConfigurationItemNodes[] {
  const targets = new Array<ConfigurationItemNodes>();

  doc.descendants((node, pos) => {
    if (node.type === schema.nodes.customArea) {
      const areaId = node.attrs?.id as string;
      const item = items.find((i) => i.areaId === areaId);

      if (item != null) {
        const variant = findNode(node, (n) => {
          const variantId = n.attrs?.id as string;
          if (item.activeVariantId === variantId) {
            return true;
          } else {
            return false;
          }
        });
        if (variant != null) {
          targets.push({
            area: { node: node, pos: pos },
            variant: { node: variant.node, pos: pos + variant.pos + 1 }
          });
        }
      }

      return false;
    }

    return;
  });

  return targets;
}
