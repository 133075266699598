export function limit(value: number, min: number, max: number): number {
  return Math.min(Math.max(Math.floor(value), min), max);
}

export function widthAsPercent(element: number, parent: number): number {
  return (element / parent) * 100;
}

/**
 * We have a couple of nodes that require us to compute style
 * on different elements, ideally all nodes should be able to
 * compute the appropriate styles based on their wrapper.
 */
const nestedCases: Record<string, string> = {};
export const computeNestedStyle = (dom: HTMLElement) => {
  const foundKey = Object.keys(nestedCases).find((className) =>
    dom.classList.contains(className)
  );
  const nestedSelector = foundKey && nestedCases[foundKey];

  if (nestedSelector) {
    const nestedElement = dom.querySelector(nestedSelector);
    if (nestedElement) {
      return window.getComputedStyle(nestedElement);
    }
  }

  return undefined;
};

export const measureHeight = (style: CSSStyleDeclaration) => {
  return measureValue(style, [
    "height",
    "padding-top",
    "padding-bottom",
    "border-top-width",
    "border-bottom-width"
  ]);
};

export const measureWidth = (style: CSSStyleDeclaration) => {
  return measureValue(style, [
    "width",
    "padding-left",
    "padding-right",
    "border-left-width",
    "border-right-width"
  ]);
};

export const measureValue = (
  style: CSSStyleDeclaration,
  measureValues: Array<string>
) => {
  const [base, ...contentBoxValues] = measureValues;
  const measures = [style.getPropertyValue(base)];

  const boxSizing = style.getPropertyValue("box-sizing");
  if (boxSizing === "content-box") {
    contentBoxValues.forEach((value) => {
      measures.push(style.getPropertyValue(value));
    });
  }

  let result = 0;
  for (let i = 0; i < measures.length; i++) {
    result += parseFloat(measures[i]);
  }
  return result;
};
