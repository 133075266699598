import { Node, Schema } from "prosemirror-model";
import { Decoration, EditorView, NodeView } from "prosemirror-view";
import { Editor } from "../../../editor";
import {
  ActionControlButton,
  ActionControls
} from "../../../editor/plugins/action-controls";
import { scrollTrackingKey } from "../../../editor/plugins/scroll-tracking";
import { selectionFocusKey } from "../../../editor/plugins/selection-focus";
import { ValidationMessagesNodeView } from "../../../editor/plugins/validation-messages";
import { WidthResizerNodeView } from "../../../editor/plugins/width-resizer";
import {
  scrollParent,
  UnreachableCaseError,
  ZeroWidthSpace
} from "../../../util";
import { AlignmentType } from "../../alignment";
import { GetTranslationFn, LanguageObserver } from "../../localization";
import { QuestionTitleBindingButton } from "../../question-title";
import {
  InputScaleControlType,
  InputScaleOrientation,
  InputScaleWidth
} from "../schema";

export class InputScaleNodeView<S extends Schema> implements NodeView<S> {
  dom: HTMLElement;
  contentDOM: HTMLElement;

  private languageObserver: LanguageObserver<S>;

  private inputContainer: HTMLElement;
  private onScroll: () => void;

  private resizer: WidthResizerNodeView<S>;
  private validationMessages: ValidationMessagesNodeView;
  private actionControls: ActionControls;
  private requiredButton: ActionControlButton;
  private questionTitleBindingButton: QuestionTitleBindingButton<S>;

  constructor(
    private node: Node<S>,
    private view: EditorView<S>,
    getPos: () => number,
    decorations: Decoration[]
  ) {
    this.languageObserver = new LanguageObserver(view, (getTranslation) => {
      this.updateRequired(this.node.attrs.required, getTranslation);
    });

    const container = document.createElement("input-scale");

    this.resizer = new WidthResizerNodeView(
      container,
      node,
      view,
      getPos,
      InputScaleWidth.minimum,
      InputScaleWidth.maximum,
      (width) => {
        const editor = view as Editor<S>;
        editor.commands.updateInputScale.execute({
          width: width
        });
      }
    );

    this.validationMessages = new ValidationMessagesNodeView();

    container.appendChild(this.validationMessages.dom);

    this.dom = container;
    this.contentDOM = document.createElement("input-scale-values");

    this.inputContainer = this.renderControlType(node.attrs.controlType);

    const requiredButton = new ActionControlButton(
      view,
      { icon: "asterisk", title: "ACTION_BUTTONS.REQUIRED.TITLE" },
      false,
      () => {
        const editor = view as Editor<S>;
        const focused = selectionFocusKey.getState(editor.state);

        if (focused != null && focused.node.type === this.node.type) {
          const { node } = focused;
          const required = !node.attrs.required;
          editor.commands.updateInputScale.execute({ required: required });
        }
      }
    );

    const questionTitleBindingButton = new QuestionTitleBindingButton(
      view,
      node,
      decorations
    );

    const actionControls = new ActionControls([
      requiredButton,
      questionTitleBindingButton
    ]);
    container.appendChild(actionControls.dom);

    this.actionControls = actionControls;
    this.requiredButton = requiredButton;
    this.questionTitleBindingButton = questionTitleBindingButton;

    const getTranslation = this.languageObserver.getTranslation;

    this.updateId(node);
    this.updateControlType(node.attrs.controlType);
    this.updateRequired(node.attrs.required, getTranslation);
    this.updateAlignment(node.attrs.alignment);
    this.updateOrientation(node.attrs.orientation);
    this.updateMaximunHeight(node.attrs.rows, node.attrs.controlType);
    this.updateQuestionTitle(node, decorations);
    this.updateDropdownPosition(node.attrs.controlType);

    this.onScroll = () => {
      this.updateDropdownPosition(node.attrs.controlType);
    };

    const scrollTracking = scrollTrackingKey.getState(view.state);
    scrollTracking?.subscribe(this.onScroll);
  }

  update(node: Node<S>, decorations: Decoration[]): boolean {
    if (node.type !== this.node.type) {
      return false;
    }

    const controlTypeHasChanged =
      this.node.attrs.controlType !== node.attrs.controlType;

    this.node = node;

    const getTranslation = this.languageObserver.getTranslation;

    this.resizer.update(node);

    if (controlTypeHasChanged) {
      this.inputContainer = this.renderControlType(node.attrs.controlType);
    }

    this.updateId(node);
    this.updateControlType(node.attrs.controlType);
    this.updateRequired(node.attrs.required, getTranslation);
    this.updateAlignment(node.attrs.alignment);
    this.updateOrientation(node.attrs.orientation);
    this.updateMaximunHeight(node.attrs.rows, node.attrs.controlType);
    this.updateQuestionTitle(node, decorations);

    if (controlTypeHasChanged) {
      this.updateDropdownPosition(node.attrs.controlType);
    }

    return true;
  }

  ignoreMutation(
    mutation:
      | MutationRecord
      | {
          type: "selection";
          target: Element;
        }
  ): boolean {
    const resizeIgnoreMutation = this.resizer.ignoreMutation(mutation);
    const validationMessagesIgnoreMutation = this.validationMessages.ignoreMutation(
      mutation
    );
    const requiredButtonIgnoreMutation = this.requiredButton.ignoreMutation(
      mutation
    );
    const questionTitleBindingButtonIgnoreMutation = this.questionTitleBindingButton.ignoreMutation(
      mutation
    );

    const ignoreStyle =
      mutation.type === "attributes" &&
      mutation.attributeName === "style" &&
      mutation.target === this.contentDOM;

    const ignoreDropdownPosition =
      mutation.type === "attributes" &&
      mutation.attributeName === "data-dropdown-position" &&
      mutation.target === this.dom;

    return [
      resizeIgnoreMutation,
      validationMessagesIgnoreMutation,
      requiredButtonIgnoreMutation,
      questionTitleBindingButtonIgnoreMutation,
      ignoreStyle,
      ignoreDropdownPosition
    ].some((x) => x);
  }

  destroy() {
    const scrollTracking = scrollTrackingKey.getState(this.view.state);
    scrollTracking?.unsubscribe(this.onScroll);
    this.languageObserver.destroy();
    this.actionControls.destroy();
  }

  private updateId(node: Node<S>): void {
    this.dom.id = node.attrs.id;
  }

  private updateControlType(controlType: InputScaleControlType): void {
    switch (controlType) {
      case InputScaleControlType.labelsInRow:
        this.dom.setAttribute("data-control-type", "labels-in-row");
        break;
      case InputScaleControlType.dropdown:
        this.dom.setAttribute("data-control-type", "dropdown");
        break;
      case InputScaleControlType.listbox:
        this.dom.setAttribute("data-control-type", "listbox");
        break;
      default:
        throw new UnreachableCaseError(controlType);
    }
  }

  private updateRequired(
    required: boolean,
    getTranslation: GetTranslationFn
  ): void {
    this.validationMessages.setRequired(required, false, getTranslation);
    this.requiredButton.setActive(required);
  }

  private updateAlignment(alignment: AlignmentType): void {
    if (alignment != null) {
      this.dom.setAttribute("data-alignment", alignment);
    } else {
      this.dom.removeAttribute("data-alignment");
    }
  }

  private updateOrientation(orientation: InputScaleOrientation): void {
    switch (orientation) {
      case InputScaleOrientation.bottom:
        this.dom.setAttribute("data-orientation", "bottom");
        break;
      case InputScaleOrientation.boxed:
        this.dom.setAttribute("data-orientation", "boxed");
        break;
      case InputScaleOrientation.top:
        this.dom.setAttribute("data-orientation", "top");
        break;
      default:
        throw new UnreachableCaseError(orientation);
    }
  }

  private updateMaximunHeight(
    rows: number,
    controlType: InputScaleControlType
  ): void {
    const rowHeight = 36;
    switch (controlType) {
      case InputScaleControlType.labelsInRow:
        this.contentDOM.style.maxHeight = "";
        break;
      case InputScaleControlType.dropdown:
        this.contentDOM.style.maxHeight = `${5 * rowHeight + 2}px`;
        break;
      case InputScaleControlType.listbox:
        this.contentDOM.style.maxHeight = `${rows * rowHeight + 2}px`;
        break;
      default:
        throw new UnreachableCaseError(controlType);
    }
  }

  private renderControlType(controlType: InputScaleControlType): HTMLElement {
    const inputContainer = document.createElement("div");
    inputContainer.className = "input-container";

    switch (controlType) {
      case InputScaleControlType.labelsInRow:
        inputContainer.appendChild(this.contentDOM);
        break;
      case InputScaleControlType.dropdown:
        const dropdownToggle = document.createElement(
          "input-scale-dropdown-toggle"
        );
        dropdownToggle.contentEditable = "false";

        const text = document.createElement("input-scale-dropdown-toggle-text");
        text.appendChild(document.createTextNode(ZeroWidthSpace));

        const carretButton = document.createElement(
          "input-scale-dropdown-toggle-carret"
        );

        const carret = document.createElement("span");
        carret.className = "bx bx-caret-down";
        carretButton.appendChild(carret);

        dropdownToggle.appendChild(text);
        dropdownToggle.appendChild(carretButton);

        inputContainer.appendChild(dropdownToggle);
        inputContainer.appendChild(this.contentDOM);
        break;
      case InputScaleControlType.listbox:
        inputContainer.appendChild(this.contentDOM);
        break;
      default:
        throw new UnreachableCaseError(controlType);
    }

    if (this.inputContainer != null) {
      this.dom.replaceChild(inputContainer, this.inputContainer);
    } else {
      this.dom.appendChild(inputContainer);
    }

    return inputContainer;
  }

  private updateDropdownPosition(controlType: InputScaleControlType): void {
    if (controlType === InputScaleControlType.dropdown) {
      const position = positionDropdown(this.dom, this.contentDOM);
      this.dom.setAttribute("data-dropdown-position", position);
    } else {
      this.dom.removeAttribute("data-dropdown-position");
    }
  }

  private updateQuestionTitle(node: Node<S>, decorations: Decoration[]): void {
    this.questionTitleBindingButton.update(node, decorations);
  }
}

function positionDropdown(
  dom: HTMLElement,
  contentDOM: HTMLElement
): "top" | "bottom" {
  contentDOM.style.zIndex = "-1";
  contentDOM.style.display = "initial";

  const scrollingContainer = scrollParent(dom);

  const scrollingContainerRect = scrollingContainer.getBoundingClientRect();
  const scrollingContainerHeight = scrollingContainerRect.height;

  const domContentRect = dom.getBoundingClientRect();
  const dropdownOffset = 4;

  const dropdownContentRect = contentDOM.getBoundingClientRect();
  const scrollOffset =
    domContentRect.bottom +
    dropdownOffset +
    dropdownContentRect.height -
    scrollingContainerRect.top;

  contentDOM.style.zIndex = "";
  contentDOM.style.display = "";

  if (scrollOffset >= scrollingContainerHeight) {
    return "top";
  } else {
    return "bottom";
  }
}
