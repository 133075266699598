import { Node, Schema } from "prosemirror-model";
import { EditorView, NodeView } from "prosemirror-view";
import { pointsToPixels, ZeroWidthSpace } from "../../util";
import { TextFontKey } from "../text-font";

export class ListItemView<S extends Schema> implements NodeView<S> {
  dom: HTMLElement;
  contentDOM: HTMLElement;
  private marker: HTMLElement;

  constructor(private node: Node<S>, private view: EditorView<S>) {
    const container = document.createElement("li");

    const marker = document.createElement("list-item-marker");
    marker.contentEditable = "false";
    marker.appendChild(document.createTextNode(ZeroWidthSpace));

    const content = document.createElement("list-item-content");

    container.appendChild(marker);
    container.appendChild(content);

    this.dom = container;
    this.contentDOM = content;
    this.marker = marker;

    this.updateId(node);
    this.setStylesForNode(node);
  }

  update(node: Node<S>): boolean {
    if (node.type !== this.node.type) {
      return false;
    }

    this.updateId(node);
    this.setStylesForNode(node);

    return true;
  }

  private updateId(node: Node<S>): void {
    this.dom.id = node.attrs.id;
  }

  private setStyleForListItem(node: Node<S>): void {
    this.dom.removeAttribute("data-alignment");

    const alignment = node.attrs.alignment;
    if (alignment != null) {
      this.dom.setAttribute("data-alignment", alignment);
    } else {
      this.dom.removeAttribute("data-alignment");
    }
  }

  private setStylesForMarker(node: Node<S>): void {
    const style = this.marker.style;
    style.setProperty("--list-item-font-weight", null);
    style.setProperty("--list-item-color", null);
    style.setProperty("--list-item-background-color", null);
    style.setProperty("--list-item-font-size", null);
    style.setProperty("--list-item-font-style", null);
    style.setProperty("--list-item-text-decoration", null);
    style.setProperty("--list-item-font-family", null);

    const textNode = node.childCount > 0 ? node.nodeAt(1) : null;
    if (textNode == null) {
      return;
    }

    const marks = textNode.marks;
    const schema = this.view.state.schema;

    marks.forEach((mark) => {
      if (mark.type === schema.marks.bold) {
        style.setProperty("--list-item-font-weight", "bolder");
      }

      if (mark.type === schema.marks.italic) {
        style.setProperty("--list-item-font-style", "italic");
      }

      if (mark.type === schema.marks.strikethrough) {
        style.setProperty("--list-item-text-decoration", "line-through");
      }

      if (mark.type === schema.marks.textColor) {
        style.setProperty("--list-item-color", mark.attrs.color);
      }

      if (mark.type === schema.marks.textBackgroundColor) {
        style.setProperty("--list-item-background-color", mark.attrs.color);
      }

      if (mark.type === schema.marks.textSize) {
        style.setProperty(
          "--list-item-font-size",
          `${pointsToPixels(mark.attrs.size)}px`
        );
      }

      if (mark.type === schema.marks.textFont) {
        const fonts = TextFontKey.getState(this.view.state);
        const font = fonts?.find((font) => font.name === mark.attrs.family);
        if (font != null) {
          style.setProperty("--list-item-font-family", font.css);
        }
      }
    });
  }

  private setStylesForNode(node: Node<S>): void {
    this.setStyleForListItem(node);
    this.setStylesForMarker(node);
  }
}
