import {
  Editor as BlueXEditor,
  Schema,
  UpdateCustomAreaActiveValue
} from "@blue-x/editor";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SelectFormControl } from "../../components/ProportiesPanelControls";
import ToolbarDropdown, {
  ToolbarDropdownToggle,
  useToolbarDropdown
} from "../ToolbarDropdown";
import ToolbarIcon from "../ToolbarIcon";
import ToolbarTooltip from "../ToolbarTooltip";
import "./CustomArea.css";

interface ToolbarCustomAreaButtonProps {
  editor: BlueXEditor<Schema>;
}

const ToolbarCustomAreaDropdown: React.FC<ToolbarCustomAreaButtonProps> = (
  props
) => {
  const { editor } = props;
  const { commands } = editor;
  const dropdown = useToolbarDropdown();

  const [areas, setAreas] = useState(3);

  const updateAreas = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.valueAsNumber;

      if (!isNaN(value)) {
        setAreas(value);
      }
    },
    [setAreas]
  );

  const insertCustomArea = useCallback(() => {
    commands.insertCustomArea.execute({ areas: areas });
    const { opened, setOpened } = dropdown;
    setOpened(!opened);
  }, [commands, areas, dropdown]);

  const insertCustomAreaDisabled = useMemo(() => {
    if (areas <= 0) {
      return true;
    } else {
      return !commands.insertCustomArea?.isEnabled();
    }
  }, [commands, areas]);

  return (
    <div className="ToolbarCustomAreaSizePicker">
      <input
        type="number"
        placeholder="areas"
        value={areas}
        onChange={updateAreas}
      />
      <button onClick={insertCustomArea} disabled={insertCustomAreaDisabled}>
        Insert
      </button>
    </div>
  );
};

export const ToolbarCustomAreaButton: React.FC<ToolbarCustomAreaButtonProps> = (
  props
) => {
  const { editor } = props;
  const { commands } = editor;

  return (
    <ToolbarDropdown
      toggle={
        <ToolbarTooltip
          title="Custom area"
          subtitle="Insert a custom area."
          shortcut={commands.insertCustomArea?.shortcut({ areas: 2 })}
        >
          <ToolbarDropdownToggle
            active={commands.insertCustomArea?.isActive()}
            disabled={!commands.insertCustomArea?.isEnabled()}
            drag={{
              event: "insert",
              type: "customArea"
            }}
          >
            <ToolbarIcon icon="smart-area" />
          </ToolbarDropdownToggle>
        </ToolbarTooltip>
      }
      content={<ToolbarCustomAreaDropdown editor={editor} />}
    />
  );
};

export const CustomAreaPropertiesPanel: React.FC<ToolbarCustomAreaButtonProps> = (
  props
) => {
  const { editor } = props;
  const { commands } = editor;

  const activeValue: UpdateCustomAreaActiveValue = useMemo(() => {
    return commands.updateCustomArea.activeValue();
  }, [commands]);

  const [description, setDescription] = useState<string | "">("");
  const [name, setName] = useState<string | "">("");

  useEffect(() => {
    setDescription(
      activeValue.description == null ? "" : activeValue.description
    );
    setName(activeValue.name == null ? "" : activeValue.name);
  }, [activeValue, setDescription, setName]);

  const updateNameCommand = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      commands.updateCustomArea.execute({ name: value }, false);
    },
    [commands]
  );

  const updateDescriptionCommand = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const value = event.target.value;
      commands.updateCustomArea.execute({ description: value }, false);
    },
    [commands]
  );

  const updateDefaultVariantIdCommand = useCallback(
    (value) => {
      commands.updateDefaultArea.execute(value, false);
    },
    [commands]
  );

  return (
    <div className="PageBreakPropertiesPanel">
      <label>Area name</label>
      <input type="text" defaultValue={name} onBlur={updateNameCommand} />

      <label>Description</label>
      <textarea
        rows={3}
        cols={40}
        defaultValue={description}
        onBlur={updateDescriptionCommand}
      />

      <SelectFormControl
        label={"Default Area"}
        value={activeValue.defaultVariantId}
        onUpdate={(value) => {
          updateDefaultVariantIdCommand({ defaultVariantId: value });
        }}
      >
        {activeValue.variants.map((value) => {
          return (
            <option value={value.id} key={value.id}>
              {value.label}
            </option>
          );
        })}
      </SelectFormControl>
    </div>
  );
};
