import { Schema } from "prosemirror-model";
import { EditorState, Plugin, PluginKey, Transaction } from "prosemirror-state";
import { InputScaleSchema } from "../schema";

const scalesCacheKey = new PluginKey<string | null, InputScaleSchema>(
  "scalesCache"
);

export function scaleCachePlugin() {
  return new Plugin<string | null, Schema>({
    key: scalesCacheKey,
    state: {
      init() {
        return null;
      },
      apply(tr, value) {
        const meta = tr.getMeta(scalesCacheKey) as string | null | undefined;

        if (meta === undefined) {
          return value;
        }

        return meta;
      }
    }
  });
}

export function getCachedScaleId(
  state: EditorState<InputScaleSchema>
): string | null {
  const scaleId = scalesCacheKey.getState(state);

  return scaleId == null ? null : scaleId;
}

export function setCachedScaleId(
  tr: Transaction<InputScaleSchema>,
  scaleId: string
): Transaction<InputScaleSchema> {
  return tr.setMeta(scalesCacheKey, scaleId);
}
