import { Editor as BlueXEditor, Schema } from "@blue-x/editor";
import React from "react";
import {
  ConstantSumPropertiesPanel,
  CustomAreaPropertiesPanel,
  ImagePropertiesPanel,
  InputDateTimePropertiesPanel,
  InputFilePropertiesPanel,
  InputNumberPropertiesPanel,
  InputRankPropertiesPanel,
  InputScalePropertiesPanel,
  InputTextPropertiesPanel,
  LinkPropertiesPanel,
  PageBreakPropertiesPanel,
  QuestionTitlePropertiesPanel,
  VariablePropertiesPanel,
  VideoPropertiesPanel
} from "./extensions";
import { BarChartPropertiesPanel } from "./extensions/BarChart";
import { CustomGridPropertiesPanel } from "./extensions/CustomGrid";
import { InputChoicePropertiesPanel } from "./extensions/InputChoice";
import "./PropertiesPanel.css";

interface Props {
  editor: BlueXEditor<Schema>;
}

const PropertiesPanel: React.FC<Props> = (props) => {
  const { editor } = props;
  const { commands } = editor;

  return (
    <>
      <div className="PropertiesPanel-Header">Properties Panel</div>
      <div className="PropertiesPanel-Content">
        {commands.updateLink?.isActive() ? (
          <LinkPropertiesPanel editor={editor}></LinkPropertiesPanel>
        ) : null}

        {commands.updateImage?.isActive() ? (
          <ImagePropertiesPanel editor={editor}></ImagePropertiesPanel>
        ) : null}

        {commands.updateVideo?.isActive() ? (
          <VideoPropertiesPanel editor={editor}></VideoPropertiesPanel>
        ) : null}

        {commands.updateVariable?.isActive() ? (
          <VariablePropertiesPanel editor={editor}></VariablePropertiesPanel>
        ) : null}

        {commands.updatePageBreak?.isActive() ? (
          <PageBreakPropertiesPanel editor={editor}></PageBreakPropertiesPanel>
        ) : null}

        {commands.updateCustomArea?.isActive() ? (
          <CustomAreaPropertiesPanel
            editor={editor}
          ></CustomAreaPropertiesPanel>
        ) : null}

        {commands.updateInputNumber?.isActive() ? (
          <InputNumberPropertiesPanel
            editor={editor}
          ></InputNumberPropertiesPanel>
        ) : null}

        {commands.updateInputText?.isActive() ? (
          <InputTextPropertiesPanel editor={editor}></InputTextPropertiesPanel>
        ) : null}

        {commands.updateInputScale?.isActive() ? (
          <InputScalePropertiesPanel
            editor={editor}
          ></InputScalePropertiesPanel>
        ) : null}

        {commands.updateInputFile?.isActive() ? (
          <InputFilePropertiesPanel editor={editor}></InputFilePropertiesPanel>
        ) : null}

        {commands.updateInputDateTime?.isActive() ? (
          <InputDateTimePropertiesPanel
            editor={editor}
          ></InputDateTimePropertiesPanel>
        ) : null}

        {commands.updateInputRank?.isActive() ? (
          <InputRankPropertiesPanel editor={editor}></InputRankPropertiesPanel>
        ) : null}

        {commands.updateInputChoice?.isActive() ? (
          <InputChoicePropertiesPanel
            editor={editor}
          ></InputChoicePropertiesPanel>
        ) : null}

        {commands.updatedConstantSum?.isActive() ? (
          <ConstantSumPropertiesPanel
            editor={editor}
          ></ConstantSumPropertiesPanel>
        ) : null}

        {commands.updateGrid?.isActive() ? (
          <CustomGridPropertiesPanel
            editor={editor}
          ></CustomGridPropertiesPanel>
        ) : null}

        {commands.updateQuestionTitleBindings?.isActive() ? (
          <QuestionTitlePropertiesPanel
            editor={editor}
          ></QuestionTitlePropertiesPanel>
        ) : null}

        {commands.updateBarChart?.isActive() ? (
          <BarChartPropertiesPanel editor={editor}></BarChartPropertiesPanel>
        ) : null}
      </div>
    </>
  );
};

export default PropertiesPanel;
