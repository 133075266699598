export function replaceAll(value: string, search: string, replace: string) {
  return value.split(search).join(replace);
}

export function getLetterFromIndex(index: number): string {
  let temp;
  let letter = "";
  index = index + 1;
  while (index > 0) {
    temp = (index - 1) % 26;
    letter = String.fromCharCode(temp + 65) + letter;
    index = (index - temp - 1) / 26;
  }
  return letter;
}
