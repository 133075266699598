import { NodeSpec } from "prosemirror-model";
import { DocumentBuilders, NodeConfig } from "../../../editor";

export class InputRankOptionsNode implements NodeConfig {
  get name(): string {
    return "inputRankOptions";
  }

  get spec(): NodeSpec {
    return {
      content:
        "inputRankOptionSelectAll{0,1} inputRankOption* inputRankOptionOtherSpecify{0,1}",
      isolating: true,
      selectable: false,
      focusable: false,
      parseDOM: [
        {
          tag: "input-rank-options"
        }
      ],
      toDOM() {
        return ["input-rank-options", 0];
      }
    };
  }

  get builders(): DocumentBuilders {
    return { inputRankOptions: { nodeType: "inputRankOptions" } };
  }
}
