import { Schema } from "prosemirror-model";
import { Plugin, PluginKey } from "prosemirror-state";
import { Skin, SkinId } from "../types";

export interface DocumentSkinState {
  documentSkin: Skin;
}

export const documentSkinKey = new PluginKey<DocumentSkinState, Schema>();

export function documentSkinPicker(
  documentSkins: Skin[],
  defaultDocumentSkinId: SkinId
) {
  const getSkinForId = (id: string) => {
    return documentSkins.find((skin) => skin.id === id);
  };

  const defaultDocumentSkin = getSkinForId(defaultDocumentSkinId);
  if (defaultDocumentSkin == null) {
    throw new Error(
      `Default document skin must be provided to ${documentSkinPicker.name}`
    );
  }

  return new Plugin<DocumentSkinState, Schema>({
    key: documentSkinKey,
    state: {
      init() {
        return {
          documentSkin: defaultDocumentSkin
        };
      },
      apply(tr, value) {
        const meta = tr.getMeta(documentSkinKey) as
          | { skinId: SkinId }
          | undefined;

        if (meta == null) {
          return value;
        }

        const { skinId } = meta;
        if (skinId == null) {
          return value;
        }

        const documentSkin = getSkinForId(skinId);

        return {
          ...value,
          documentSkin:
            documentSkin == null ? defaultDocumentSkin : documentSkin
        };
      }
    },
    props: {
      attributes(state) {
        const { documentSkin } = this.getState(state);
        const skinId: string =
          documentSkin && documentSkin.id ? documentSkin.id : "default";

        return {
          "data-input-style": skinId
        };
      }
    }
  });
}
