import { BlockRange } from "./block-range";

export class BlockSelection {
  private readonly _ranges: BlockRange[];

  constructor(ranges: BlockRange[] = []) {
    this._ranges = (ranges || []).slice();
  }

  get ranges(): BlockRange[] {
    return this._ranges.slice();
  }

  isEmpty(): boolean {
    return this._ranges.length === 0;
  }

  rangeOfId(id: string): BlockRange | undefined {
    return this._ranges.find((r) => r.id === id);
  }

  addRange(range: BlockRange): BlockSelection {
    return range.isEmpty()
      ? this
      : new BlockSelection(this.ranges.concat(range));
  }

  removeRange(toRemove: BlockRange): BlockSelection {
    return new BlockSelection(this.ranges.filter((r) => r.id !== toRemove.id));
  }
}
