import { DOMOutputSpecArray, Node, NodeSpec } from "prosemirror-model";
import { DocumentBuilders, NodeConfig } from "../../editor/extension/schema";
import { toNumberValue } from "../../util";
import { AlignmentType } from "../alignment";
import {
  getQuestionTitleAttrs,
  setQuestionTitleAttrs
} from "../question-title";
import {
  InputNumberControlType,
  InputNumberLabelPosition,
  InputNumberOfDecimals,
  InputNumberWidth
} from "./schema";

function getAttrs(element: HTMLElement): Partial<{ (key: string): any }> {
  const attrs: {
    id?: string;
    required?: boolean;
    questionTitleText?: string;
    questionTitleRefElementId?: string[];
    prefix?: string;
    suffix?: string;
    defaultValue?: number;
    coding?: string;
    minValue?: number;
    maxValue?: number;
    step?: number;
    description?: string;
    controlType?: InputNumberControlType;
    labelPosition?: InputNumberLabelPosition;
    width?: number;
    numberOfDecimals?: number;
    alignment?: AlignmentType;
  } = { ...getQuestionTitleAttrs(element) };

  const id = element.getAttribute("id");
  if (id != null) {
    attrs.id = id;
  }

  const required = element.getAttribute("data-required");
  if (required != null) {
    attrs.required = required.toLowerCase() === "true";
  }

  const prefix = element.getAttribute("data-prefix");
  if (prefix != null) {
    attrs.prefix = prefix;
  }

  const suffix = element.getAttribute("data-suffix");
  if (suffix != null) {
    attrs.suffix = suffix;
  }

  const numberOfDecimals = toNumberValue(
    element.getAttribute("data-number-of-decimals"),
    0
  );
  if (numberOfDecimals != null) {
    attrs.numberOfDecimals = numberOfDecimals;
  }

  const defaultValue = toNumberValue(
    element.getAttribute("data-default-value"),
    null
  );
  if (defaultValue != null) {
    attrs.defaultValue = defaultValue;
  }

  const coding = element.getAttribute("data-coding");
  if (coding != null) {
    attrs.coding = coding;
  }

  const minValue = toNumberValue(element.getAttribute("data-min-value"), null);
  if (minValue != null) {
    attrs.minValue = minValue;
  }

  const maxValue = toNumberValue(element.getAttribute("data-max-value"), null);
  if (maxValue != null) {
    attrs.maxValue = maxValue;
  }

  const step = toNumberValue(element.getAttribute("data-step"), null);
  if (step != null) {
    attrs.step = step;
  }

  const description = element.getAttribute("data-description");
  if (description != null) {
    attrs.description = description;
  }

  const controlType = element.getAttribute(
    "data-control-type"
  ) as InputNumberControlType;
  if (controlType != null) {
    switch (controlType) {
      case InputNumberControlType.slider:
      case InputNumberControlType.spinbox:
        attrs.controlType = controlType;
        break;
      default:
        break;
    }
  }

  const labelPosition = element.getAttribute(
    "data-label-position"
  ) as InputNumberLabelPosition;
  if (labelPosition != null) {
    switch (labelPosition) {
      case InputNumberLabelPosition.bottom:
      case InputNumberLabelPosition.none:
      case InputNumberLabelPosition.top:
        attrs.labelPosition = labelPosition;
        break;
      default:
        break;
    }
  }

  const width = toNumberValue(element.getAttribute("data-width"), 0);
  if (width != null) {
    attrs.width = width;
  }

  const alignment = element.getAttribute("data-alignment") as AlignmentType;
  if (alignment != null) {
    switch (alignment) {
      case "center":
      case "left":
      case "right":
        attrs.alignment = alignment;
        break;
      default:
        break;
    }
  }

  return attrs;
}

function setAttrs(node: Node): { [key: string]: string } {
  return {
    id: node.attrs.id,
    ...setQuestionTitleAttrs(node),
    "data-required": node.attrs.required,
    "data-default-value": node.attrs.defaultValue,
    "data-coding": node.attrs.coding,
    "data-min-value": node.attrs.minValue,
    "data-max-value": node.attrs.maxValue,
    "data-step": node.attrs.step,
    "data-description": node.attrs.description,
    "data-control-type": node.attrs.controlType,
    "data-label-position": node.attrs.labelPosition,
    "data-prefix": node.attrs.prefix,
    "data-suffix": node.attrs.suffix,
    "data-number-of-decimals": node.attrs.numberOfDecimals,
    "data-width": node.attrs.width,
    "data-alignment": node.attrs.alignment
  };
}

export class InputNumberNode implements NodeConfig {
  get name(): string {
    return "inputNumber";
  }

  get spec(): NodeSpec {
    return {
      group: "block input gridInput",
      draggable: true,
      selectable: true,
      focusable: true,
      allowGapCursor: true,
      allowIndentation: false,
      allowAlignment: ["left", "center", "right"],
      blockAlignment: true,
      attrs: {
        id: { default: null },
        alignment: { default: null },
        required: { default: false },
        questionTitleText: { default: null },
        questionTitleRefElementId: { default: new Array<string>() },
        defaultValue: { default: null },
        coding: { default: "" },
        minValue: { default: 0 },
        maxValue: { default: 100 },
        step: { default: null },
        description: { default: "" },
        controlType: { default: InputNumberControlType.spinbox },
        width: { default: InputNumberWidth.default },
        numberOfDecimals: { default: InputNumberOfDecimals.minimum },
        labelPosition: { default: InputNumberLabelPosition.top },
        prefix: { default: null },
        suffix: { default: null }
      },
      parseDOM: [
        {
          tag: "input-number",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getAttrs(node as HTMLElement);
          }
        },
        {
          tag: "[data-input='number']",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getAttrs(node as HTMLElement);
          }
        }
      ],
      toDOM(node): DOMOutputSpecArray {
        return ["input-number", setAttrs(node)];
      },
      applyBlueprint(
        attrs: Record<string, any>,
        blueprint: Record<string, any>
      ) {
        const updated = { ...attrs };

        updated.required = blueprint.required;
        updated.defaultValue = blueprint.defaultValue;
        updated.controlType = blueprint.controlType;
        updated.labelPosition = blueprint.labelPosition;
        updated.minValue = blueprint.minValue;
        updated.maxValue = blueprint.maxValue;
        updated.numberOfDecimals = blueprint.numberOfDecimals;
        updated.prefix = blueprint.prefix;
        updated.suffix = blueprint.suffix;
        updated.step = blueprint.step;

        return updated;
      }
    };
  }

  get builders(): DocumentBuilders {
    return { inputNumber: { nodeType: "inputNumber" } };
  }
}
