import { DocumentBuilders, NodeConfig } from "editor";
import { DOMOutputSpecArray, Node, NodeSpec } from "prosemirror-model";
import { toNumberValue } from "../../../util/number";
import {
  ConstantSumLogicDirection,
  ConstantSumNumberOfDecimals,
  ConstantSumType
} from "../schema";

export class ConstantSumNode implements NodeConfig {
  get name(): string {
    return "constantSum";
  }

  get spec(): NodeSpec {
    return {
      group: "gridFunction",
      content: "text*",
      isolating: true,
      draggable: true,
      selectable: true,
      focusable: true,
      allowGapCursor: true,
      allowIndentation: false,
      blockAlignment: false,
      attrs: {
        id: { default: null },
        name: { default: null },
        type: { default: ConstantSumType.sum },
        direction: { default: ConstantSumLogicDirection.horizontal },
        required: { default: false },
        minValue: { default: null },
        maxValue: { default: null },
        numberOfDecimals: { default: 0 },
        prefix: { default: null },
        suffix: { default: null }
      },
      parseDOM: [
        {
          tag: "constant-sum",
          getAttrs: (node) => {
            if (typeof node === "string") {
              return false;
            }
            return getAttrs(node as HTMLElement);
          }
        }
      ],
      toDOM(node): DOMOutputSpecArray {
        return ["constant-sum", setAttrs(node), 0];
      },
      applyBlueprint(
        attrs: Record<string, any>,
        blueprint: Record<string, any>
      ) {
        const updated = { ...attrs };

        updated.type = blueprint.type;
        updated.direction = blueprint.direction;
        updated.required = blueprint.required;
        updated.minValue = blueprint.minValue;
        updated.maxValue = blueprint.maxValue;
        updated.numberOfDecimals = blueprint.numberOfDecimals;
        updated.prefix = blueprint.prefix;
        updated.suffix = blueprint.suffix;

        return updated;
      }
    };
  }

  get builders(): DocumentBuilders {
    return { constantSum: { nodeType: "constantSum" } };
  }
}

function getAttrs(element: HTMLElement): Partial<{ (key: string): any }> {
  const attrs: {
    id?: string;
    type?: ConstantSumType;
    direction?: ConstantSumLogicDirection;
    required?: boolean;
    name?: string;
    prefix?: string;
    suffix?: string;
    minValue?: number;
    maxValue?: number;
    numberOfDecimals?: number;
  } = {};

  const id = element.getAttribute("id");
  if (id != null) {
    attrs.id = id;
  }

  const name = element.getAttribute("data-name");
  if (name != null) {
    attrs.name = name;
  }

  const type = element.getAttribute("data-type") as ConstantSumType;
  if (type != null) {
    switch (type) {
      case ConstantSumType.sum:
      case ConstantSumType.distribution:
        attrs.type = type;
        break;
      default:
        break;
    }
  }

  const direction = element.getAttribute(
    "data-direction"
  ) as ConstantSumLogicDirection;
  if (direction != null) {
    switch (direction) {
      case ConstantSumLogicDirection.horizontal:
      case ConstantSumLogicDirection.vertical:
        attrs.direction = direction;
        break;
      default:
        break;
    }
  }

  const required = element.getAttribute("data-required");
  if (required != null) {
    attrs.required = required.toLowerCase() === "true";
  }

  const minValue = toNumberValue(element.getAttribute("data-min-value"), null);
  if (minValue != null) {
    attrs.minValue = minValue;
  }

  const maxValue = toNumberValue(element.getAttribute("data-max-value"), null);
  if (maxValue != null) {
    attrs.maxValue = maxValue;
  }

  const numberOfDecimals = toNumberValue(
    element.getAttribute("data-number-of-decimals"),
    null
  );

  if (
    numberOfDecimals != null &&
    numberOfDecimals >= ConstantSumNumberOfDecimals.minimum &&
    numberOfDecimals <= ConstantSumNumberOfDecimals.maximum
  ) {
    attrs.numberOfDecimals = numberOfDecimals;
  }

  const prefix = element.getAttribute("data-prefix");
  if (prefix != null) {
    attrs.prefix = prefix;
  }

  const suffix = element.getAttribute("data-suffix");
  if (suffix != null) {
    attrs.suffix = suffix;
  }

  return attrs;
}

function setAttrs(node: Node): { [key: string]: string } {
  return {
    id: node.attrs.id,
    "data-name": node.attrs.name,
    "data-type": node.attrs.type,
    "data-direction": node.attrs.direction,
    "data-required": node.attrs.required,
    "data-min-value": node.attrs.minValue,
    "data-max-value": node.attrs.maxValue,
    "data-number-of-decimals": node.attrs.numberOfDecimals,
    "data-prefix": node.attrs.prefix,
    "data-suffix": node.attrs.suffix
  };
}
