import { Node } from "prosemirror-model";
import { EditorState } from "prosemirror-state";
import { Focus, selectionFocusKey } from "../../editor/plugins/selection-focus";
import { InputNumberControlType, InputNumberSchema } from "./schema";

export function createInputNumber(
  schema: InputNumberSchema,
  controlType: InputNumberControlType
): Node<InputNumberSchema> {
  const { inputNumber } = schema.nodes;

  return inputNumber.createChecked({
    controlType: controlType,
    ...(controlType === InputNumberControlType.slider ? { step: 1 } : {})
  });
}

export function focusedInputNumber(
  state: EditorState<InputNumberSchema>
): Focus | undefined {
  const { schema } = state;

  const focused = selectionFocusKey.getState(state);
  if (
    focused != null &&
    [schema.nodes.inputNumber].includes(focused.node.type)
  ) {
    return focused;
  } else {
    return undefined;
  }
}
