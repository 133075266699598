import { NodeSpec, Schema } from "prosemirror-model";
import { DocumentBuilders, Extension, NodeConfig } from "../../editor";

class TextNode implements NodeConfig {
  get name(): string {
    return "text";
  }

  get spec(): NodeSpec {
    return {
      group: "inline"
    };
  }

  get builders(): DocumentBuilders {
    return { text: { nodeType: "text" } };
  }
}

type TextSchema = Schema<"text", any>;

export class Text implements Extension<TextSchema> {
  get name(): string {
    return "text";
  }

  get nodes(): NodeConfig[] {
    return [new TextNode()];
  }
}
