import { Command, DragEventData, setDropInsertData } from "@blue-x/editor";
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from "react";
import ToolbarButton from "./ToolbarButton";
import "./ToolbarDropdown.css";

export const ToolbarDropdownContext = createContext<{
  opened: boolean;
  setOpened: (opened: boolean) => void;
}>({ opened: false, setOpened: (_: boolean) => {} });

export const useToolbarDropdown = () => useContext(ToolbarDropdownContext);

function lightOrDark(value?: string): "light" | "dark" {
  if (!value) {
    return "light";
  }

  const matches = value.replace("#", "").match(/.{2}/g);
  if (matches && matches.length >= 3) {
    const [red, green, blue] = matches;
    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    const r = parseInt(red, 16);
    const g = parseInt(green, 16);
    const b = parseInt(blue, 16);
    const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {
      return "light";
    } else {
      return "dark";
    }
  } else {
    return "light";
  }
}

interface ToolbarDropdownToggleProps {
  color?: string;
  active?: boolean;
  disabled?: boolean;
  drag?: DragEventData;
}

export const ToolbarDropdownToggle: React.FC<ToolbarDropdownToggleProps> = (
  props
) => {
  const { color, children, disabled, active, drag } = props;
  const dropdown = useToolbarDropdown();

  const click = useCallback(() => {
    const { opened, setOpened } = dropdown;
    setOpened(!opened);
  }, [dropdown]);

  const style = lightOrDark(color);

  const draggable = useMemo(() => {
    return drag != null;
  }, [drag]);

  const dragstart = useCallback(
    (event: React.DragEvent) => {
      if (drag != null) {
        setDropInsertData(event.dataTransfer, drag);
      }
    },
    [drag]
  );

  return (
    <button
      className={
        active
          ? `ToolbarDropdown-button ToolbarDropdown-style-${style} active`
          : `ToolbarDropdown-button ToolbarDropdown-style-${style}`
      }
      onClick={click}
      style={color ? { backgroundColor: color } : undefined}
      disabled={disabled}
      draggable={draggable}
      onDragStart={dragstart}
    >
      {children}
    </button>
  );
};

interface ToolbarDropdownListProps {
  command: Command;
  options: any[];
  attribute: (option: any) => Record<string, any>;
  title?: (option: any) => string;
}

export const ToolbarDropdownList: React.FC<ToolbarDropdownListProps> = (
  props
) => {
  const { command, options, attribute, title } = props;

  return (
    <>
      {options.map((option) => {
        return (
          <ToolbarButton
            key={option}
            command={command}
            attributes={attribute(option)}
            shouldCloseDropdown={true}
          >
            {title ? title(option) : option}
          </ToolbarButton>
        );
      })}
    </>
  );
};

interface ToolbarDropdownProps {
  toggle: React.ReactNode;
  content: React.ReactNode;
}

export const ToolbarDropdown: React.FC<ToolbarDropdownProps> = (props) => {
  const { toggle, content } = props;
  const [opened, setOpened] = useState(false);

  return (
    <ToolbarDropdownContext.Provider
      value={{ opened: opened, setOpened: setOpened }}
    >
      <div className="ToolbarDropdown">
        {toggle}
        {opened ? (
          <div className="ToolbarDropdown-content">{content}</div>
        ) : undefined}
      </div>
    </ToolbarDropdownContext.Provider>
  );
};

export default ToolbarDropdown;
