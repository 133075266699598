import { Node, Schema } from "prosemirror-model";
import { EditorState } from "prosemirror-state";
import { UnreachableCaseError } from "../../util";
import { GetTranslationFn } from "../localization";
import { PageBreakSchema } from "./schema";

type PageBreakButtonRole = "previous" | "next" | "submit";
export enum PageBreakNotifications {
  UpdateSuccessSingular = "page-break.update-success.singular",
  UpdateSuccessPlural = "page-break.update-success.plural",
  NoUpdate = "page-break.no-update"
}

export function createPageBreak(
  schema: PageBreakSchema,
  getTranslation: GetTranslationFn
): Node<PageBreakSchema> {
  const { pageBreak } = schema.nodes;

  const previousButton = createButtonOfRole(schema, "previous", getTranslation);
  const nextButton = createButtonOfRole(schema, "next", getTranslation);
  const submitButton = createButtonOfRole(schema, "submit", getTranslation);

  return pageBreak.createChecked(null, [
    previousButton,
    nextButton,
    submitButton
  ]);
}

function createButtonOfRole(
  schema: PageBreakSchema,
  role: PageBreakButtonRole,
  getTranslation: GetTranslationFn
): Node<PageBreakSchema> {
  const {
    previousPageBreakButton,
    nextPageBreakButton,
    submitPageBreakButton
  } = schema.nodes;

  switch (role) {
    case "previous":
      return previousPageBreakButton.createChecked(null, [
        schema.text(getButtonText(role, getTranslation))
      ]);

    case "next":
      return nextPageBreakButton.createChecked(null, [
        schema.text(getButtonText(role, getTranslation))
      ]);

    case "submit":
      return submitPageBreakButton.createChecked(null, [
        schema.text(getButtonText(role, getTranslation))
      ]);

    default:
      throw new UnreachableCaseError(role);
  }
}

function getButtonText(
  role: PageBreakButtonRole,
  getTranslation: GetTranslationFn
) {
  switch (role) {
    case "previous":
      return getTranslation("PAGE_BREAK.PREVIOUS_BUTTON").toUpperCase();

    case "next":
      return getTranslation("PAGE_BREAK.NEXT_BUTTON").toUpperCase();

    case "submit":
      return getTranslation("PAGE_BREAK.SUBMIT_BUTTON").toUpperCase();

    default:
      throw new UnreachableCaseError(role);
  }
}

export function getPageBreakNodes<S extends Schema>(): (
  state: EditorState<S>
) => { nodes: { node: Node; pos: number }[]; count: number } {
  return (state) => {
    const { doc, schema } = state;

    let nodes = new Array<{ node: Node<S>; pos: number }>();
    let count = 0;
    doc.descendants((node, pos) => {
      if (node.type === schema.nodes.pageBreak) {
        nodes.push({ node, pos });
        return false;
      }
      return;
    });

    return { nodes, count };
  };
}
