import { Node, Schema } from "prosemirror-model";
import { Decoration, EditorView, NodeView } from "prosemirror-view";
import { AlignmentType } from "../../../extensions/alignment";
import { ContentNodeWithPos, findParent } from "../../../util";
import { InputRankControlType } from "../schema";

export class InputRankSectionNodeView<S extends Schema> implements NodeView<S> {
  dom: HTMLElement;
  contentDOM: HTMLElement;
  addButton: HTMLElement;

  constructor(
    private node: Node<S>,
    private view: EditorView<S>,
    private getPos: () => number
  ) {
    const { state } = this.view;
    const parent = findParent(state.doc.resolve(this.getPos()), (node) => {
      return node.type === state.schema.nodes.inputRank;
    });

    const { section, content, addButton } = this.renderSections(parent);

    this.dom = section;
    this.contentDOM = content;
    this.addButton = addButton;

    if (
      parent &&
      parent.node.attrs.controlType === InputRankControlType.dropdown &&
      this.dom.contains(this.addButton)
    ) {
      this.dom.removeChild(this.addButton);
    } else if (
      parent &&
      parent.node.attrs.controlType === InputRankControlType.dragDrop &&
      !this.dom.contains(this.addButton)
    ) {
      const { addButton } = inputRankSectionBuilder();
      this.dom.appendChild(addButton);
      this.addButton = addButton;
    }

    this.updateId(node);
    this.updateAlignment(node.attrs.alignment);
  }

  update(node: Node<S>, _decorations: Decoration[]): boolean {
    if (node.type !== this.node.type) {
      return false;
    }

    const { state } = this.view;

    const parent = findParent(state.doc.resolve(this.getPos()), (node) => {
      return node.type === state.schema.nodes.inputRank;
    });

    if (
      parent &&
      parent.node.attrs.controlType === InputRankControlType.dropdown &&
      this.dom.contains(this.addButton)
    ) {
      this.dom.removeChild(this.addButton);
    } else if (
      parent &&
      parent.node.attrs.controlType === InputRankControlType.dragDrop &&
      !this.dom.contains(this.addButton)
    ) {
      const { addButton } = inputRankSectionBuilder();
      this.dom.appendChild(addButton);
      this.addButton = addButton;
    }

    this.updateId(node);
    this.updateAlignment(node.attrs.alignment);

    return true;
  }

  private updateId(node: Node<S>): void {
    this.dom.id = node.attrs.id;
  }

  private updateAlignment(alignment: AlignmentType): void {
    if (alignment != null) {
      this.dom.setAttribute("data-alignment", alignment);
    } else {
      this.dom.removeAttribute("data-alignment");
    }
  }

  private renderSections(
    inputRank: ContentNodeWithPos<S> | undefined
  ): {
    content: HTMLElement;
    section: HTMLElement;
    addButton: HTMLElement;
  } {
    const section = document.createElement("input-rank-section");
    const { content, addButton } = inputRankSectionBuilder();

    section.appendChild(content);
    if (
      inputRank &&
      inputRank.node.attrs.controlType === InputRankControlType.dragDrop
    ) {
      section.appendChild(addButton);
    }

    return { content, section, addButton };
  }
}

export class InputRankSectionEmptyNodeView<S extends Schema>
  implements NodeView<S> {
  dom: HTMLElement;
  constructor() {
    const section = document.createElement("input-rank-section-empty");
    section.contentEditable = "false";

    this.dom = section;
  }
}

function inputRankSectionBuilder(): {
  content: HTMLElement;
  addButton: HTMLElement;
} {
  const content = document.createElement("div");
  content.className = "content";

  const addButton = document.createElement("span");
  addButton.contentEditable = "false";
  addButton.classList.add("input-rank-button");
  const plusSpanEl = document.createElement("span");
  plusSpanEl.contentEditable = "false";
  plusSpanEl.classList.add("bx", "bx-plus");
  addButton.appendChild(plusSpanEl);

  return { content, addButton };
}
