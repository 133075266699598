import { MarkSpec, Schema } from "prosemirror-model";
import {
  CommandConfiguration,
  CommandConfigurations,
  DocumentBuilders,
  Extension,
  MarkConfig
} from "../../editor";
import { textMarkIsActive, textMarkIsEnabled, toggleMark } from "../../util";

class UnderlineMark implements MarkConfig {
  get name(): string {
    return "underline";
  }

  get spec(): MarkSpec {
    return {
      group: "text-style",
      parseDOM: [
        {
          tag: "u"
        },
        {
          style: "text-decoration",
          getAttrs: (value) =>
            typeof value === "string" && value === "underline" && null
        }
      ],
      toDOM() {
        return ["u", 0];
      }
    };
  }

  get builders(): DocumentBuilders {
    return { underline: { markType: "underline" } };
  }
}

type UnderlineSchema = Schema<any, "underline">;

export class Underline implements Extension<UnderlineSchema> {
  get name(): string {
    return "underline";
  }

  get marks(): MarkConfig[] {
    return [new UnderlineMark()];
  }

  commands(schema: UnderlineSchema): CommandConfigurations<UnderlineSchema> {
    return {
      underline: this.underlineCommand(schema)
    };
  }

  private underlineCommand(
    schema: UnderlineSchema
  ): CommandConfiguration<UnderlineSchema, {}, undefined> {
    return {
      isActive: () => textMarkIsActive(schema.marks.underline),
      isEnabled: () => textMarkIsEnabled(schema.marks.underline),
      execute: () => toggleMark(schema.marks.underline),
      shortcuts: {
        "Mod-u": undefined
      }
    };
  }
}
