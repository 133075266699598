import { Node, Schema } from "prosemirror-model";
import { getLetterFromIndex } from "../../util";

export interface CustomAreaToolbarView {
  element: HTMLElement;
  updateIndicator: (name: string, isDefault: boolean) => void;
  updatePagination: (node: Node<Schema>) => void;
}

export function customAreaToolbarView(
  isActive: (variant: Node<Schema>) => boolean,
  navigateBefore: () => void,
  navigateAfter: () => void,
  navigateToId: (id: string) => void
): CustomAreaToolbarView {
  const toolbar = document.createElement("custom-area-toolbar");
  toolbar.contentEditable = "false";

  const indicator = document.createElement("custom-area-indicator");
  indicator.contentEditable = "false";

  const pagination = document.createElement("custom-area-pagination");
  pagination.contentEditable = "false";

  const previous = document.createElement("custom-area-arrow-previous");
  previous.className = "bx bx-chevron";

  const next = document.createElement("custom-area-arrow-next");
  next.className = "bx bx-chevron";

  toolbar.appendChild(indicator);
  toolbar.appendChild(pagination);

  let indicatorName: string;
  let indicatorIsDefault: boolean;
  let paginationCount: number;
  let paginationActiveIndex: number;

  return {
    element: toolbar,
    updateIndicator: (name: string, isDefault: boolean) => {
      const shouldUpdate =
        indicatorName !== name || indicatorIsDefault !== isDefault;

      if (!shouldUpdate) {
        return;
      }

      indicator.innerText = `${name} ${isDefault ? "(default)" : ""}`;

      indicatorName = name;
      indicatorIsDefault = isDefault;
    },
    updatePagination: (node: Node<Schema>) => {
      const variants = node.content;

      let activeIndex = 0;

      variants.forEach((variant, _off, index) => {
        if (isActive(variant)) {
          activeIndex = index;
          return;
        }
      });

      const shouldUpdate =
        activeIndex !== paginationActiveIndex ||
        variants.childCount !== paginationCount;

      if (!shouldUpdate) {
        return;
      }

      while (pagination.firstChild) {
        pagination.removeChild(pagination.firstChild);
      }

      if (
        variants.childCount <= 9 ||
        activeIndex < 4 ||
        activeIndex > variants.childCount - 7
      ) {
        let separatorAlreadyAdd = false;
        variants.forEach((variant, _off, index) => {
          const paginationButton = document.createElement(
            "custom-area-pagination-button"
          );

          paginationButton.contentEditable = "false";
          const letter = getLetterFromIndex(index);
          paginationButton.innerText = letter;
          paginationButton.onmousedown = (e: MouseEvent) => {
            e.preventDefault();
          };
          paginationButton.onclick = () => {
            navigateToId(variant.attrs.id);
          };

          const paginationSeparator = document.createElement(
            "custom-area-pagination-separator"
          );

          paginationSeparator.innerText = "...";

          if (index === 0) {
            if (isActive(variant)) {
              previous.setAttribute("disabled", "");
            } else {
              previous.removeAttribute("disabled");
            }
            previous.onmousedown = (e: MouseEvent) => {
              e.preventDefault();
            };
            previous.onclick = () => {
              const disabled = previous.getAttribute("disabled");
              if (disabled === null) {
                navigateBefore();
              }
            };
            pagination.appendChild(previous);
          }

          if (isActive(variant)) {
            paginationButton.classList.add("active");
          }

          if (variants.childCount <= 9) {
            pagination.appendChild(paginationButton);
          } else {
            if (
              (activeIndex < 4 &&
                (index < 4 || index > variants.childCount - 5)) ||
              (activeIndex > variants.childCount - 7 &&
                (index === 0 || index > variants.childCount - 7))
            ) {
              pagination.appendChild(paginationButton);
            } else if (!separatorAlreadyAdd) {
              pagination.appendChild(paginationSeparator);
              separatorAlreadyAdd = true;
            }
          }

          if (index === node.childCount - 1) {
            if (isActive(variant)) {
              next.setAttribute("disabled", "");
            } else {
              next.removeAttribute("disabled");
            }
            next.onmousedown = (e: MouseEvent) => {
              e.preventDefault();
            };
            next.onclick = () => {
              const disabled = next.getAttribute("disabled");
              if (disabled === null) {
                navigateAfter();
              }
            };
            pagination.appendChild(next);
          }
        });
      } else {
        let firstSeparatorAlreadyAdd = false;
        let secondSeparatorAlreadyAdd = false;
        variants.forEach((variant, _off, index) => {
          const paginationButton = document.createElement(
            "custom-area-pagination-button"
          );

          paginationButton.contentEditable = "false";
          const letter = getLetterFromIndex(index);
          paginationButton.innerText = letter;
          paginationButton.onmousedown = (e: MouseEvent) => {
            e.preventDefault();
          };
          paginationButton.onclick = () => {
            navigateToId(variant.attrs.id);
          };

          if (index === 0) {
            if (isActive(variant)) {
              previous.setAttribute("disabled", "");
            } else {
              previous.removeAttribute("disabled");
            }
            previous.onmousedown = (e: MouseEvent) => {
              e.preventDefault();
            };
            previous.onclick = () => {
              const disabled = previous.getAttribute("disabled");
              if (disabled === null) {
                navigateBefore();
              }
            };
            pagination.appendChild(previous);
          }

          if (isActive(variant)) {
            paginationButton.classList.add("active");
          }

          if (
            index === 0 ||
            (index >= activeIndex && index <= activeIndex + 4) ||
            index === variants.childCount - 1
          ) {
            pagination.appendChild(paginationButton);
          } else if (!firstSeparatorAlreadyAdd) {
            const paginationSeparator = document.createElement(
              "custom-area-pagination-separator"
            );

            paginationSeparator.innerText = "...";

            pagination.appendChild(paginationSeparator);
            firstSeparatorAlreadyAdd = true;
          } else if (
            !secondSeparatorAlreadyAdd &&
            index === variants.childCount - 2
          ) {
            const paginationSeparator = document.createElement(
              "custom-area-pagination-separator"
            );

            paginationSeparator.innerText = "...";

            pagination.appendChild(paginationSeparator);
            secondSeparatorAlreadyAdd = true;
          }

          if (index === node.childCount - 1) {
            if (isActive(variant)) {
              next.setAttribute("disabled", "");
            } else {
              next.removeAttribute("disabled");
            }
            next.onmousedown = (e: MouseEvent) => {
              e.preventDefault();
            };
            next.onclick = () => {
              const disabled = next.getAttribute("disabled");
              if (disabled === null) {
                navigateAfter();
              }
            };
            pagination.appendChild(next);
          }
        });
      }

      paginationCount = variants.childCount;
      paginationActiveIndex = activeIndex;
    }
  };
}
