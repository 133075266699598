import { Editor as BlueXEditor, Schema } from "@blue-x/editor";
import {
  CheckboxFormControl,
  NumberFormControl,
  SelectFormControl,
  TextFormControl
} from "../ProportiesPanelControls";
import "./CustomGrid.css";

import React, { useCallback, useMemo, useState } from "react";

interface CustomGridPropertiesPanelProps {
  editor: BlueXEditor<Schema>;
}

interface GridBorderType {
  top: boolean;
  bottom: boolean;
  left: boolean;
  right: boolean;
  "inside-horizontal": boolean;
  "inside-vertical": boolean;
}

enum GridBorderOptionsValue {
  all = "All borders",
  bottom = "Bottom border",
  top = "Top border",
  left = "Left border",
  right = "Right border",
  noBorder = "No border",
  outside = "Outside borders",
  inside = "Inside borders",
  "inside-horizontal" = "Inside horizontal border",
  "inside-vertical" = "Inside vertical border"
}

export const CustomGridPropertiesPanel: React.FC<CustomGridPropertiesPanelProps> = (
  props
) => {
  const { editor } = props;
  const { commands } = editor;

  const [borderDisplayValue, setBorderDisplayValue] = useState<string>("");

  const activeValue: any = useMemo(() => {
    return commands.updateGrid.activeValue();
  }, [commands]);

  const createNewBorderWith = useCallback(
    (value: string) => {
      let newBorders = { ...activeValue.borders };

      if (value === GridBorderOptionsValue.all) {
        for (const [key] of Object.entries(newBorders)) {
          newBorders[key] = true;
        }
      } else if (value === GridBorderOptionsValue.noBorder) {
        for (const [key] of Object.entries(newBorders)) {
          newBorders[key] = false;
        }
      } else {
        if (value === GridBorderOptionsValue.outside) {
          if (
            newBorders.top &&
            newBorders.bottom &&
            newBorders.left &&
            newBorders.right
          ) {
            newBorders.top = false;
            newBorders.bottom = false;
            newBorders.left = false;
            newBorders.right = false;
          }
          newBorders.top = true;
          newBorders.bottom = true;
          newBorders.left = true;
          newBorders.right = true;
        } else if (value === GridBorderOptionsValue.inside) {
          if (
            newBorders["inside-horizontal"] &&
            newBorders["inside-vertical"]
          ) {
            newBorders["inside-horizontal"] = false;
            newBorders["inside-vertical"] = false;
          }
          newBorders["inside-horizontal"] = true;
          newBorders["inside-vertical"] = true;
        } else {
          if (value === GridBorderOptionsValue.top) {
            newBorders.top = !newBorders.top;
          }
          if (value === GridBorderOptionsValue.bottom) {
            newBorders.bottom = !newBorders.bottom;
          }
          if (value === GridBorderOptionsValue.left) {
            newBorders.left = !newBorders.left;
          }
          if (value === GridBorderOptionsValue.right) {
            newBorders.right = !newBorders.right;
          }
          if (value === GridBorderOptionsValue["inside-horizontal"]) {
            newBorders["inside-horizontal"] = !newBorders["inside-horizontal"];
          }
          if (value === GridBorderOptionsValue["inside-vertical"]) {
            newBorders["inside-vertical"] = !newBorders["inside-vertical"];
          }
        }
      }
      return newBorders;
    },
    [activeValue]
  );

  const getBorderSelectValue = useCallback((objValues: GridBorderType) => {
    const appendBorderValues = (currentValue: string, strToAppend: string) => {
      return currentValue.length > 0 ? `, ${strToAppend}` : strToAppend;
    };

    if (
      objValues.top &&
      objValues.bottom &&
      objValues.left &&
      objValues.right &&
      !objValues["inside-vertical"] &&
      !objValues["inside-horizontal"]
    ) {
      return GridBorderOptionsValue.outside;
    } else if (
      !objValues.top &&
      !objValues.bottom &&
      !objValues.left &&
      !objValues.right &&
      objValues["inside-vertical"] &&
      objValues["inside-horizontal"]
    ) {
      return GridBorderOptionsValue.inside;
    } else if (
      objValues.top &&
      objValues.bottom &&
      objValues.left &&
      objValues.right &&
      objValues["inside-vertical"] &&
      objValues["inside-horizontal"]
    ) {
      return GridBorderOptionsValue.all;
    } else if (
      !objValues.top &&
      !objValues.bottom &&
      !objValues.left &&
      !objValues.right &&
      !objValues["inside-vertical"] &&
      !objValues["inside-horizontal"]
    ) {
      return GridBorderOptionsValue.noBorder;
    } else {
      let combinedValues = "";
      if (objValues.top) {
        combinedValues += appendBorderValues(
          combinedValues,
          GridBorderOptionsValue.top
        );
      }
      if (objValues.bottom) {
        combinedValues += appendBorderValues(
          combinedValues,
          GridBorderOptionsValue.bottom
        );
      }
      if (objValues.left) {
        combinedValues += appendBorderValues(
          combinedValues,
          GridBorderOptionsValue.left
        );
      }
      if (objValues.right) {
        combinedValues += appendBorderValues(
          combinedValues,
          GridBorderOptionsValue.right
        );
      }
      if (objValues["inside-horizontal"]) {
        combinedValues += appendBorderValues(
          combinedValues,
          GridBorderOptionsValue["inside-horizontal"]
        );
      }
      if (objValues["inside-vertical"]) {
        combinedValues += appendBorderValues(
          combinedValues,
          GridBorderOptionsValue["inside-vertical"]
        );
      }
      return combinedValues;
    }
  }, []);

  const update = useCallback(
    (value) => {
      if (Object.keys(value)[0] === "borders") {
        const newBorder: GridBorderType = createNewBorderWith(value.borders);
        setBorderDisplayValue(getBorderSelectValue(newBorder));
        commands.updateGrid.execute({ borders: newBorder }, false);
      } else {
        commands.updateGrid.execute(value, false);
      }
    },
    [commands, createNewBorderWith, getBorderSelectValue]
  );

  const getOptionStyle = (value: GridBorderType, expectedValue: string) => {
    if (expectedValue === GridBorderOptionsValue.all) {
      for (let key in value) {
        if (value[key] === false) return false;
      }
    } else if (expectedValue === GridBorderOptionsValue.noBorder) {
      for (let key in value) {
        if (value[key] === true) return false;
      }
    } else if (expectedValue === GridBorderOptionsValue.outside) {
      return value.top && value.bottom && value.left && value.right;
    } else if (expectedValue === GridBorderOptionsValue.inside) {
      return value["inside-horizontal"] && value["inside-vertical"];
    }
    return true;
  };

  return (
    <div className="CustomGridPropertiesPanel">
      <NumberFormControl
        label={"Width (%)"}
        value={activeValue.width}
        step={1}
        min={25}
        max={100}
        onUpdate={(value) => {
          update({ width: value });
        }}
      />

      <SelectFormControl
        label={"Borders"}
        value={borderDisplayValue}
        onUpdate={(value) => {
          update({ borders: value });
        }}
      >
        <option value={borderDisplayValue} hidden>
          {borderDisplayValue
            ? borderDisplayValue
            : getBorderSelectValue(activeValue.borders)}
        </option>
        <option
          className={activeValue.borders.bottom ? "selected-option" : ""}
          value={GridBorderOptionsValue.bottom}
        >
          {GridBorderOptionsValue.bottom}
        </option>
        <option
          className={activeValue.borders.top ? "selected-option" : ""}
          value={GridBorderOptionsValue.top}
        >
          {GridBorderOptionsValue.top}
        </option>
        <option
          className={activeValue.borders.left ? "selected-option" : ""}
          value={GridBorderOptionsValue.left}
        >
          {GridBorderOptionsValue.left}
        </option>
        <option
          className={activeValue.borders.right ? "selected-option" : ""}
          value={GridBorderOptionsValue.right}
        >
          {GridBorderOptionsValue.right}
        </option>
        <option
          className={
            getOptionStyle(activeValue.borders, GridBorderOptionsValue.noBorder)
              ? "selected-option"
              : ""
          }
          value={GridBorderOptionsValue.noBorder}
        >
          {GridBorderOptionsValue.noBorder}
        </option>
        <option
          className={
            getOptionStyle(activeValue.borders, GridBorderOptionsValue.all)
              ? "selected-option"
              : ""
          }
          value={GridBorderOptionsValue.all}
        >
          {GridBorderOptionsValue.all}
        </option>
        <option
          className={
            getOptionStyle(activeValue.borders, GridBorderOptionsValue.outside)
              ? "selected-option"
              : ""
          }
          value={GridBorderOptionsValue.outside}
        >
          {GridBorderOptionsValue.outside}
        </option>
        <option
          className={
            getOptionStyle(activeValue.borders, GridBorderOptionsValue.inside)
              ? "selected-option"
              : ""
          }
          value={GridBorderOptionsValue.inside}
        >
          {GridBorderOptionsValue.inside}
        </option>
        <option
          className={
            activeValue.borders["inside-horizontal"] ? "selected-option" : ""
          }
          value={GridBorderOptionsValue["inside-horizontal"]}
        >
          {GridBorderOptionsValue["inside-horizontal"]}
        </option>
        <option
          className={
            activeValue.borders["inside-vertical"] ? "selected-option" : ""
          }
          value={GridBorderOptionsValue["inside-vertical"]}
        >
          {GridBorderOptionsValue["inside-vertical"]}
        </option>
      </SelectFormControl>

      <TextFormControl
        label={"Grid name"}
        value={activeValue.name}
        onUpdate={(value) => {
          update({ name: value });
        }}
      />

      <TextFormControl
        label={"Description"}
        value={activeValue.description}
        onUpdate={(value) => {
          update({ description: value });
        }}
      />

      <CheckboxFormControl
        label={"Randomize rows"}
        value={activeValue.randomization}
        onUpdate={(value: boolean) => {
          update({ randomization: value });
        }}
      />
      <CheckboxFormControl
        label={"Show header"}
        value={activeValue.showHeader}
        onUpdate={(value: boolean) => {
          update({ showHeader: value });
        }}
      />
      <CheckboxFormControl
        label={"Show footer"}
        value={activeValue.showFooter}
        onUpdate={(value: boolean) => {
          update({ showFooter: value });
        }}
      />
      <CheckboxFormControl
        label={"Repeat column elements"}
        value={activeValue.repeatGrid}
        onUpdate={(value: boolean) => {
          update({ repeatGrid: value });
        }}
      />
      <CheckboxFormControl
        label={"Responsive"}
        value={activeValue.responsive}
        onUpdate={(value: boolean) => {
          update({ responsive: value });
        }}
      />
      <CheckboxFormControl
        label={"Allow respondent to repeat last row"}
        value={activeValue.lastRowManualRepeat}
        onUpdate={(value: boolean) => {
          update({ lastRowManualRepeat: value });
        }}
      />
      <CheckboxFormControl
        label={"Sticky header"}
        value={activeValue.stickyHeader}
        disabled={!activeValue.showHeader}
        onUpdate={(value: boolean) => {
          update({ stickyHeader: value && activeValue.showHeader });
        }}
      />
      {activeValue.hasRepeatErrors ? (
        <div style={{ color: "red" }}>
          Function component will encounter an error if last row is repeated by
          respondent as vertical function refers to itself.
        </div>
      ) : null}
    </div>
  );
};
