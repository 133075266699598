import { AttributeSpec, NodeSpec, Schema } from "prosemirror-model";
import { Plugin } from "prosemirror-state";
import {
  BASE_PRIORITY,
  CommandConfiguration,
  CommandConfigurations,
  CommandFn,
  DocumentBuilders,
  Extension,
  NodeConfig
} from "../../editor";
import { blockIsActive, setBlockType } from "../../util";
import { AlignmentType, getAlignment } from "../alignment";
import { getIndentation, getIndentationPX } from "../indentation";
import { paragraphNodeViewPlugin } from "./paragraph-node-view";

class ParagraphNode implements NodeConfig {
  get name(): string {
    return "paragraph";
  }

  get spec(): NodeSpec {
    return {
      content: "(inline|hardBreak)*",
      group: "block rootBlock",
      defining: true,
      selectable: false,
      supportsQuestionTitle: true,
      attrs: {
        id: { default: null },
        alignment: { default: null, keepOnSplit: true } as AttributeSpec & {
          keepOnSplit?: boolean;
        },
        indentation: { default: null, keepOnSplit: true } as AttributeSpec & {
          keepOnSplit?: boolean;
        }
      },
      parseDOM: [
        {
          tag: "p",
          getAttrs: (value) => {
            if (typeof value === "string") {
              return false;
            }

            const element = value as HTMLElement;
            const attrs: {
              id?: string;
              alignment?: AlignmentType;
              indentation?: number;
            } = {};

            const id = element.getAttribute("id");
            if (id != null) {
              attrs.id = id;
            }

            const alignment = getAlignment(element.style.textAlign);
            if (alignment != null) {
              attrs.alignment = alignment;
            }

            const indentation = getIndentation(element.style.marginLeft);
            if (indentation != null) {
              attrs.indentation = indentation;
            }

            return attrs;
          }
        }
      ],
      toDOM(node) {
        let styles: string[] = [];
        if (node.attrs.alignment != null) {
          styles = styles.concat(`text-align: ${node.attrs.alignment}`);
        }
        if (node.attrs.indentation != null) {
          styles = styles.concat(
            `margin-left: ${getIndentationPX(node.attrs.indentation)}px`
          );
        }

        const attrs =
          styles.length > 0
            ? { id: node.attrs.id, style: styles.join("; ") }
            : { id: node.attrs.id };

        return ["p", attrs, 0];
      }
    };
  }

  get priority(): number {
    return BASE_PRIORITY + 1;
  }

  get builders(): DocumentBuilders {
    return { p: { nodeType: "paragraph" } };
  }
}

type ParagraphSchema = Schema<"paragraph", any>;

export class Paragraph implements Extension<ParagraphSchema> {
  get name(): string {
    return "paragraph";
  }

  get nodes(): NodeConfig[] {
    return [new ParagraphNode()];
  }

  plugins(): Plugin[] {
    return [paragraphNodeViewPlugin()];
  }

  commands(schema: ParagraphSchema): CommandConfigurations<ParagraphSchema> {
    return {
      paragraph: this.paragraphCommand(schema)
    };
  }

  private paragraphCommand(
    schema: ParagraphSchema
  ): CommandConfiguration<ParagraphSchema, {}, null> {
    return {
      isActive: () => blockIsActive(schema.nodes.paragraph),
      isEnabled: () => {
        return (state) => {
          const isActive = blockIsActive(schema.nodes.paragraph)(state);
          const canSetBlockType = setParagraph(schema)(state);
          return isActive || canSetBlockType;
        };
      },
      execute: () => setParagraph(schema)
    };
  }
}

function setParagraph(schema: Schema): CommandFn<Schema> {
  return setBlockType(schema.nodes.paragraph, undefined, (node, attrs) => {
    return { ...attrs, id: node.attrs.id };
  });
}
