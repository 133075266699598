import { Node, Slice } from "prosemirror-model";
import { EditorState } from "prosemirror-state";
import { selectionFocusKey } from "../../editor/plugins/selection-focus";
import { GetTranslationFn } from "../../extensions/localization/util";
import { IdGenerator, UnreachableCaseError } from "../../util";
import { InputRankControlType, InputRankSchema } from "./schema";

export function focusedInputRank(state: EditorState<InputRankSchema>) {
  const { schema } = state;
  const focused = selectionFocusKey.getState(state);
  if (focused != null && focused.node.type === schema.nodes.inputRank) {
    return focused;
  } else {
    return undefined;
  }
}

export function focusedInputRankSection(state: EditorState<InputRankSchema>) {
  const { schema } = state;
  const focused = selectionFocusKey.getState(state);
  if (focused != null && focused.node.type === schema.nodes.inputRankSection) {
    return focused;
  } else {
    return undefined;
  }
}

export function createInputRank(
  schema: InputRankSchema,
  controlType: InputRankControlType,
  getTranslation: GetTranslationFn,
  idGenerator: IdGenerator
): Node<InputRankSchema> {
  const { inputRank } = schema.nodes;

  const contents: Node<InputRankSchema>[] = [];

  const sections = createInputSections(
    schema,
    controlType,
    getTranslation,
    idGenerator
  );
  const options = createInputOptions(schema);

  contents.push(sections, options);
  return inputRank.createChecked({ controlType: controlType }, contents);
}

export function createInputSections(
  schema: InputRankSchema,
  controlType: InputRankControlType,
  getTranslation: GetTranslationFn,
  idGenerator: IdGenerator
): Node<InputRankSchema> {
  const { inputRankSections } = schema.nodes;

  const section = createInputSection(
    schema,
    false,
    getTranslation,
    idGenerator
  );

  switch (controlType) {
    case InputRankControlType.dragDrop:
      return inputRankSections.createChecked(undefined, [section])!;

    case InputRankControlType.dropdown:
      const sectionEmpty = createInputSection(schema, true, getTranslation);
      return inputRankSections.createChecked(undefined, [
        sectionEmpty,
        section
      ])!;
    default:
      throw new UnreachableCaseError(controlType);
  }
}

export function createInputSection(
  schema: InputRankSchema,
  empty: boolean,
  getTranslation: GetTranslationFn,
  idGenerator?: IdGenerator
): Node<InputRankSchema> {
  const { inputRankSection, inputRankSectionEmpty } = schema.nodes;
  const id = idGenerator ? idGenerator.generateId() : null;

  if (empty) {
    return inputRankSectionEmpty.create({ id: null })!;
  } else {
    return inputRankSection.createChecked(
      { id },
      schema.text(getTranslation("INPUT_RANKING.SECTION"))
    )!;
  }
}

export function createInputOptions(
  schema: InputRankSchema
): Node<InputRankSchema> {
  const { inputRankOptions, inputRankOption } = schema.nodes;

  return inputRankOptions.createChecked(
    undefined,
    inputRankOption.createChecked({ id: null })
  )!;
}

export function createInputRankWithContent(
  schema: InputRankSchema,
  controlType: InputRankControlType,
  getTranslation: GetTranslationFn,
  idGenerator: IdGenerator,
  nodes: Node[]
): Node<InputRankSchema> {
  const { inputRank } = schema.nodes;

  const contents: Node<InputRankSchema>[] = [];

  const sections = createInputSections(
    schema,
    controlType,
    getTranslation,
    idGenerator
  );

  const options = createInputOptionsWithContent(schema, nodes);

  contents.push(sections, options);
  return inputRank.createChecked({ controlType: controlType }, contents);
}

export function createInputOptionsWithContent(
  schema: InputRankSchema,
  nodes: Node[]
): Node<InputRankSchema> {
  const {
    inputRankOptions,
    inputRankOption,
    inputRankOptionSelectAll,
    inputRankOptionOtherSpecify
  } = schema.nodes;

  const options: Node[] = [];

  nodes.forEach((node) => {
    switch (node.type) {
      case schema.nodes.inputRankOption:
        options.push(inputRankOption.createChecked({ id: null }, node.content));
        break;

      case schema.nodes.inputRankOptionSelectAll:
        options.push(
          inputRankOptionSelectAll.createChecked({ id: null }, node.content)
        );
        break;

      case schema.nodes.inputRankOptionOtherSpecify:
        options.push(
          inputRankOptionOtherSpecify.createChecked({ id: null }, node.content)
        );
        break;
      default:
        break;
    }
  });

  return inputRankOptions.createChecked(undefined, options)!;
}

export function createInputRankOptionSelectAll(
  schema: InputRankSchema,
  getTranslation: GetTranslationFn
): Node<InputRankSchema> {
  const { inputRankOptionSelectAll } = schema.nodes;

  return inputRankOptionSelectAll.createChecked(
    { id: null },
    schema.text(getTranslation("INPUT_RANKING.SELECT_ALL"))
  )!;
}

export function createInputRankOptionOtherSpecify(
  schema: InputRankSchema,
  getTranslation: GetTranslationFn
): Node<InputRankSchema> {
  const { inputRankOptionOtherSpecify } = schema.nodes;

  return inputRankOptionOtherSpecify.create(
    { id: null },
    schema.text(getTranslation("INPUT_RANKING.OTHER_SPECIFY_CHOICE"))
  )!;
}

export function isSpecialRankOption(
  node: Node<InputRankSchema>,
  schema: InputRankSchema
) {
  return [
    schema.nodes.inputRankOptionSelectAll,
    schema.nodes.inputRankOptionOtherSpecify
  ].includes(node.type);
}

export function tryInsertParagraphIntoSection(
  slice: Slice<InputRankSchema>,
  schema: InputRankSchema
): boolean {
  let canInsertIntoRank = true;

  slice.content.forEach((n) => {
    if (canInsertIntoRank) {
      canInsertIntoRank = n.type === schema.nodes.paragraph;
    }
  });

  return canInsertIntoRank;
}

export function tryInsertRankIntoSection(
  slice: Slice<InputRankSchema>,
  schema: InputRankSchema
): boolean {
  let canInsertIntoRank = true;

  slice.content.forEach((n) => {
    if (canInsertIntoRank) {
      canInsertIntoRank = n.type === schema.nodes.inputRank;
    }
  });

  return canInsertIntoRank;
}
