import { Node, Schema } from "prosemirror-model";
import { Plugin } from "prosemirror-state";
import { EditorView, NodeView } from "prosemirror-view";
import { getIndentationPX } from "../indentation";
import { GetTranslationFn, LanguageObserver } from "../localization";

export function paragraphNodeViewPlugin() {
  return new Plugin({
    props: {
      nodeViews: {
        paragraph: (node, view, _getPos) => {
          return new ParagraphNodeView(node, view);
        }
      }
    }
  });
}

class ParagraphNodeView<S extends Schema> implements NodeView<S> {
  dom: HTMLElement;
  contentDOM: HTMLElement;

  private languageObserver: LanguageObserver<S>;

  constructor(private node: Node<S>, view: EditorView<S>) {
    this.languageObserver = new LanguageObserver(view, (getTranslation) => {
      this.updatePlaceholderCaption(getTranslation);
    });

    const p = document.createElement("p");

    this.dom = p;
    this.contentDOM = p;

    const getTranslation = this.languageObserver.getTranslation;

    this.updateId(node);
    this.updateAlignment(node);
    this.updateIndentation(node);
    this.updatePlaceholderCaption(getTranslation);
  }

  update(node: Node<S>): boolean {
    if (node.type !== this.node.type) {
      return false;
    }

    const getTranslation = this.languageObserver.getTranslation;

    this.updateId(node);
    this.updateAlignment(node);
    this.updateIndentation(node);
    this.updatePlaceholderCaption(getTranslation);

    return true;
  }

  ignoreMutation(
    mutation:
      | MutationRecord
      | {
          type: "selection";
          target: Element;
        }
  ): boolean {
    if (
      mutation.target === this.dom &&
      mutation.type === "attributes" &&
      mutation.attributeName === "data-placeholder"
    ) {
      return true;
    }

    return false;
  }

  destroy() {
    this.languageObserver.destroy();
  }

  private updateId(node: Node<S>): void {
    this.dom.id = node.attrs.id;
  }

  private updateAlignment(node: Node<S>): void {
    if (node.attrs.alignment != null) {
      this.dom.style.textAlign = node.attrs.alignment;
    } else {
      this.dom.style.textAlign = "";
    }
  }

  private updateIndentation(node: Node<S>): void {
    if (node.attrs.indentation != null) {
      this.dom.style.marginLeft = `${getIndentationPX(
        node.attrs.indentation
      )}px`;
    } else {
      this.dom.style.marginLeft = "";
    }
  }

  private updatePlaceholderCaption(getTranslation: GetTranslationFn): void {
    this.dom.setAttribute(
      "data-placeholder",
      getTranslation("QUESTION_TITLE.PLACEHOLDER")
    );
  }
}
